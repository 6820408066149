import { Axios } from "../Axios";

export const checkChildEmail = async (childEmailList) => {
  const res = await Axios.post("/users/child-email", {
    childEmail: childEmailList,
  });
  return res.data;
};

export const authenticate = async (email, password) => {
  const api = "/auth/token";
  const res = await Axios.post(api, {
    email,
    password,
  });
  return res.data;
};

export const sendOtp = async (phoneNumber, userId) => {
  const res = await Axios.post("/auth/send-otp", { phoneNumber, userId });

  return res.data;
};

export const verifyOtp = async (otp, userId) => {
  const res = await Axios.post("/auth/verify-otp", { otp, userId });
  return res.data;
};

export const tutorRegister = async ({
  answer1,
  answer2,
  answer3,
  agencyId,
  languageOneId,
  languageOneLevel,
  languageTwoId,
  languageTwoLevel,
  languageThreeId,
  languageThreeLevel,
  agreeWithTutorPolicy = true,
}) => {
  return (
    await Axios.post("/register/tutor", {
      answer1,
      answer2,
      answer3,
      agencyId,
      languageOneId,
      languageOneLevel,
      languageTwoId,
      languageTwoLevel,
      languageThreeId,
      languageThreeLevel,
      agreeWithTutorPolicy,
    })
  ).data;
};
