import { Axios } from "../Axios";

export const CreateLiveLesson = async (
  title,
  dateString,
  startTime,
  activeTab,
  studentId,
) => {
  const res = await Axios.post("lessons/Lesson", {
    title,
    lessonMode: activeTab,
    assignedTo: studentId,
    lessonDate: dateString,
    startTime,
    placeId: 2,
  });

  return res.data;
};
export const updateLessonStatus = async (lessonId, statusId, points) => {
  const res = await Axios.put("lessons/change-status", {
    lessonId,
    statusId,
    points,
  });

  return res.data;
};
export const getCancellationReasons = async () => {
  const res = await Axios.get("/lessons/CancelReason");
  return res.data;
};

export const cancelLesson = async (
  lessonId,
  cancelReasonId,
  otherCancelReason,
) => {
  let reasonId = otherCancelReason ? 41 : cancelReasonId;
  const res = await Axios.put("/lessons/cancel-lesson", {
    lessonId,
    reasonId,
    otherCancelReason,
  });
  return res.data;
};
