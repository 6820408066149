import React, { useContext, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import "./home.scss";
import "./profile.scss";
import "./media.scss";
import "./games.scss";
import "./loginModal/login.scss";
import "./messages/messages.scss";
import "./myactivities.scss";
import "./gamestabs.scss";
import "./gamesflowphotos.scss";
import "./selectedgames.scss";
import "./gamequiz.scss";
import { Container, Row, Form } from "react-bootstrap";
import logo from "../assets/images/logo.png";
import backorange from "../assets/images/backorange.svg";
import { Link } from "react-router-dom";
import FooterEducator from "./layout/Footer/FooterEducator";
import { useEffect } from "react";
import {
  getSingalActivities,
  getSingalActivitiesGroup,
} from "../service/apis/activities";
import { sendAnswer } from "../service/apis/tasks";
import { HeaderDashboard } from "./layout/HeaderDashboard";
import SuccessModal from "./dialogs/SuccessModal";
import { tastStatus } from "../constants/app";
import { SnackbarContext } from "../contexts/Snackbar";

function PersonalTaskAnswer() {
  const params = useParams();
  const [task, setTask] = useState({});
  const [taskId, setTaskId] = useState();
  const [answer, setAnswer] = useState("");
  const [showModal, setShowModal] = useState(false);
  const { acId, id } = params;
  const { openSnackbar } = useContext(SnackbarContext);

  const getDingal = async () => {
    // if(groupId === "1"){
    const response = await getSingalActivities(acId, id);
    console.log("response1", response);
    setTask(response);
    setTaskId(response.taskId);
    // }else{
    //   const response = await getSingalActivitiesGroup(acId,id)
    //   setTask(response)
    //   setTaskId(response.taskId)
    // }
  };

  useEffect(() => {
    getDingal();
  }, [acId, id]);

  const sendAnswerd = async () => {
    try {
      if (answer.length === 0)
        return openSnackbar("Please enter your answer", "error");
      const response = await sendAnswer(answer, id);
      console.log(response);

      if (response) {
        getDingal();
        setShowModal(true);
        setAnswer("");
      }
    } catch (e) {
      openSnackbar("Something went wrong", "error");
      console.log(e);
    }
  };

  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderDashboard />

      {/* end header */}

      <div className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs">
        <div className="games-photosynths gamequiz-main perosnoal-task">
          <Container>
            <div className="quiz-game-popup">
              <div className="current-lesson-main ">
                <div className="current-lesson-contain">
                  <span className="back-btndrop">
                    <Link to="/MyActivities" className="backbtn-lg">
                      <img src={backorange} />
                    </Link>
                  </span>
                  <div className="right-iconbox-quizz">
                    <ul>
                      {/* <li>
                        <Link
                          to="#"
                          className="editbtn-lgnew"
                          onClick={() => setAddgroupstep2ModalShow(true)}
                        >
                          <img src={darkorangeplan} />
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="editbtn-lgnew"
                        onClick={() => setAddgroupstep3ModalShow(true)}
                        >
                          <img src={editorangelg} />
                        </Link>
                      </li>*/}
                    </ul>
                  </div>

                  <div className="current-lesson-topbr">
                    <div className="medm-tex orng-clr">
                      {task?.activityName}
                    </div>
                    <h1>{task?.taskTitle}</h1>
                    <div className="lesson-status-list quiz-gamelession">
                      <ul className="lesson-listing-curnt">
                        <li>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Mode:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              {task.mode || "Active"}
                            </span>
                          </div>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Status:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              {task.status}
                            </span>
                          </div>
                        </li>
                        <li>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Created:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              {task?.creationDate?.slice(0, 10)}
                            </span>
                          </div>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Deadline:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              {task?.deadline?.slice(0, 10)}
                            </span>
                          </div>
                        </li>
                        <li>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Total points:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              {task.points}
                            </span>
                          </div>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Earned points:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              {task.earnedPoints}
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="personal-tsk-main">
                <div className="persnl-taskiner">
                  <div className="task-content">
                    <p>
                      <strong>Description: </strong>
                      {task.taskDescription}
                    </p>
                  </div>

                  {task.comment && (
                    <div className="task-content">
                      <p>
                        <strong>Revision: </strong>
                        {task.comment}
                      </p>
                    </div>
                  )}
                  {task.assignedByRole !== "Parent" && (
                    <div className="task-content">
                      <p>
                        <strong>Answer: </strong>
                        {task.answer === null
                          ? "Not answered yet"
                          : task.answer}
                      </p>
                    </div>
                  )}

                  {task.status !== "Done" && task.status !== "Completed" && (
                    <div className="number-points-frm">
                      <div className="points-iner-nw">
                        {task.assignedByRole !== "Parent" && (
                          <h5>Provide Your Answer</h5>
                        )}
                        <Row>
                          {task.assignedByRole !== "Parent" && (
                            <div className="col-6 col-md-9 one">
                              <div className="points-num-bx">
                                <Form.Group className="subsform-fild">
                                  <Form.Control
                                    as="textarea"
                                    style={{
                                      height: "170px",
                                      paddingTop: "20px",
                                    }}
                                    value={answer}
                                    onChange={(e) => setAnswer(e.target.value)}
                                    placeholder="Start typing..."
                                  />
                                </Form.Group>
                              </div>
                            </div>
                          )}
                          <div className="col-6 col-md-3 two">
                            <div className="save-points">
                              <div className="btn-week">
                                <Link
                                  to="#"
                                  onClick={() => sendAnswerd()}
                                  className="btnweak-bx"
                                >
                                  <span className="btn-title">
                                    {task.assignedByRole !== "Parent"
                                      ? "Submit"
                                      : "Mark as done"}
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </Row>
                      </div>
                    </div>
                  )}
                  {/* show button that says answer already sent */}
                  {task.status === "Done" && (
                    <Link to="#" className="btnweak-bx submit-already-btn">
                      <span className="btn-title">
                        Answer submitted already!
                      </span>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </Container>
        </div>

        {/* footer start */}
        <FooterEducator logo={logo} />
        {/* footer end */}
      </div>

      {/* start modal my student */}
      {showModal && (
        <SuccessModal
          modalTitle={"Answer sent successfully"}
          onClose={setShowModal}
        />
      )}
      {/* start game save step1 */}

      {/* end Add a Member Group step2 */}

      {/* end modal my student */}

      {/* start select studen popup */}

      {/* end select studen popup */}
    </div>
  );
}

export default PersonalTaskAnswer;
