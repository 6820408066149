import React from "react";

const TimerComponent = ({
  remainingDays,
  remainingHours,
  remainingMinutes,
}) => {
  const [days, setDays] = React.useState(0);
  const [hours, setHours] = React.useState(0);
  const [minutes, setMinutes] = React.useState(0);

  React.useEffect(() => {
    if (!remainingDays && !remainingHours && !remainingMinutes) return;
    setDays(Math.abs(remainingDays));
    setHours(Math.abs(remainingHours));
    setMinutes(Math.abs(remainingMinutes));
  }, [remainingDays, remainingHours, remainingMinutes]);

  React.useEffect(() => {
    if (!remainingDays && !remainingHours && remainingMinutes) return;
    if (days === 0 && hours === 0 && minutes === 0) return;

    // decrease remaining minutes by 1 every minute
    const interval = setInterval(() => {
      setMinutes((minutes) => minutes - 1);
    }, 60000);
    // decrease remaining hours when remaining minutes is 0
    if (minutes === 0) {
      setHours((hours) => hours - 1);
      setMinutes(59);
    }
    // decrease remaining days when remaining hours is 0
    if (hours === 0 && minutes === 0) {
      setDays((days) => days - 1);
      setHours(23);
      setMinutes(59);
    }

    return () => clearInterval(interval);
  }, [days, hours, minutes, remainingDays]);

  console.log("rendering");
  return (
    <ul className="newlesson-listclk">
      <li>
        <div className="clock-time">
          <div className="timenum">{days}</div>
          <div className="time-days">days</div>
        </div>
      </li>
      <li>
        <div className="clock-time">
          <div className="timenum">{hours}</div>
          <div className="time-days">hours</div>
        </div>
      </li>
      <li>
        <div className="clock-time">
          <div className="timenum">{minutes}</div>
          <div className="time-days">minutes</div>
        </div>
      </li>
    </ul>
  );
};

export default TimerComponent;
