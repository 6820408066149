import {
  Box,
  Button,
  Checkbox,
  Dialog,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Modal,
  Rating,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import PersonIcon from "@mui/icons-material/Person";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { HeaderDashboard } from "../../layout/HeaderDashboard";
import WorkIcon from "@mui/icons-material/Work";
import CircleIcon from "@mui/icons-material/Circle";
import SchoolIcon from "@mui/icons-material/School";
import CloseIcon from "@mui/icons-material/Close";

import { StyledYellowButton } from "../../utils/Buttons";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import logo from "../../../assets/images/logo.png";
import SearchIcon from "@mui/icons-material/Search";
import Footer from "../../layout/Footer/Footer";
import { useHistory } from "react-router";
import { getCities, getCountries } from "../../../service/apis/common";
import { FriendsDialog } from "./FriendsDialog";
import AddIcon from "@mui/icons-material/Add";
import { DialogBox } from "../../utils/DialogBox";
import { StudentAPI } from "../../../service/apis/student";
const connectionsData = [
  {
    name: "Lion Amari",
    yearsOfStudy: 7,
    points: "13 823",
    location: "India, Mumbai",
    games: 637,
    rating: 84,
  },
  {
    name: "Lion Amari",
    yearsOfStudy: 7,
    points: "13 823",
    location: "India, Mumbai",
    games: 637,
    rating: 84,
  },
  {
    name: "Lion Amari",
    yearsOfStudy: 7,
    points: "13 823",
    location: "India, Mumbai",
    games: 637,
    rating: 84,
  },
  {
    name: "Lion Amari",
    yearsOfStudy: 7,
    points: "13 823",
    location: "India, Mumbai",
    games: 637,
    rating: 84,
  },
  {
    name: "Lion Amari",
    yearsOfStudy: 7,
    points: "13 823",
    location: "India, Mumbai",
    games: 637,
    rating: 84,
  },
];

const subjects = [
  {
    name: "Maths",
    id: 0,
  },
  {
    name: "Physics",
    id: 1,
  },
  {
    name: "Literature",
    id: 2,
  },
  {
    name: "English",
    id: 3,
  },
  {
    name: "Chemistry",
    id: 4,
  },
  {
    name: "Geography",
    id: 5,
  },
];
export const MyFriends = () => {
  const history = useHistory();
  const [state, setstate] = useState({
    newFriendsDialog: { isOpen: false },
    isFriendsDialogOpen: false,
    addStudentConfirmationDialog: {
      isOpen: false,
      studentName: "",
    },
  });

  const newFriendsDialogHandler = () => {
    setstate((prevState) => ({
      ...prevState,
      newFriendsDialog: {
        ...prevState.newFriendsDialog,
        isOpen: !prevState.newFriendsDialog.isOpen,
      },
    }));
  };
  const addNewFriendConfirmation = {
    open: (studentName) => {
      setstate((prevState) => ({
        ...prevState,
        newFriendsDialog: { isOpen: false },
        addStudentConfirmationDialog: { isOpen: true, studentName },
      }));
    },
    close: () => {
      setstate((prevState) => ({
        ...prevState,
        addStudentConfirmationDialog: { isOpen: false },
      }));
    },
  };
  const FindNewFriendsDialog = () => {
    const [state, setstate] = useState({
      searchQuery: "",
      countries: [],
      cities: [],
      selectedCountry: { id: 1, name: "" },
      selectedCity: { id: 1, name: "" },
      location: {
        open: false,
        value: "Location",
        anchor: null,
        width: null,
      },
      subject: {
        open: false,
        value: "All subjects",
        anchor: null,
        width: null,
      },
    });

    const [searchedFriends, setsearchedFriends] = useState([]);

    const dropDown = {
      onClose: {
        location() {
          setstate((prevState) => ({
            ...prevState,
            location: { ...prevState.location, open: false, anchor: null },
          }));
        },
        subject() {
          setstate((prevState) => ({
            ...prevState,
            subject: { ...prevState.subject, open: false, anchor: null },
          }));
        },
      },
      onOpen: {
        location(e) {
          setstate((prevState) => ({
            ...prevState,
            location: {
              ...prevState.location,
              open: true,
              anchor: e.currentTarget,
              width: e.currentTarget?.clientWidth,
            },
          }));
        },
        subject(e) {
          setstate((prevState) => ({
            ...prevState,
            subject: {
              ...prevState.subject,
              open: true,
              anchor: e.currentTarget,
              width: e.currentTarget?.clientWidth,
            },
          }));
        },
      },
    };

    const fetchCountries = async () => {
      try {
        const res = await getCountries();
        setstate((prevState) => ({ ...prevState, countries: res }));
      } catch (err) {}
    };

    const selectCountry = async (countryId, countryName) => {
      setstate((prevState) => ({
        ...prevState,
        selectedCountry: { id: countryId, name: countryName },
      }));
      try {
        const res = await getCities(countryId);
        setstate((prevState) => ({ ...prevState, cities: res }));
      } catch (err) {}
    };

    const handleSearchStudents = async (query) => {
      setstate((prevState) => ({ ...prevState, searchQuery: query }));
      const res = await StudentAPI.searchFriends({
        searchKeyword: query,
      });
    };

    useEffect(() => {
      fetchCountries();
    }, []);

    const StudentCard = ({ student }) => {
      return (
        <Box
          sx={{
            cursor: "pointer",
            backgroundColor: "white",
            borderRadius: "1.2rem",
          }}
        >
          <Grid
            container
            alignItems={"center"}
            // justifyContent="space-between"
            columnSpacing={"1rem"}
          >
            <Grid item xs={1}>
              <PersonIcon
                sx={{
                  color: "white",
                  backgroundColor: "var(--purple)",
                  borderRadius: "50%",
                  height: "3rem",
                  width: "3rem",
                  p: "0.3rem",
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <Box sx={{}}>
                <Typography sx={{ fontWeight: "800", fontSize: "1rem" }}>
                  {student.name}
                </Typography>
                <Box sx={{ display: "flex", columnGap: "2rem" }}>
                  <Typography sx={{ color: "var(--purple)" }}>
                    {student.location}
                  </Typography>
                  <Typography sx={{ color: "var(--purple)" }}>
                    {student.yearsOfStudy + " years of study"}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "10px",
                    p: "0.2rem",
                    display: "flex",
                    alignItems: "center",
                    mr: "1rem",
                    justifyContent: "center",
                    backgroundColor: "var(--yellow)",
                  }}
                >
                  <img
                    style={{ height: "1.5rem" }}
                    alt="chat"
                    src="/images/icons/chat.png"
                  />
                </Box>
                <Box
                  onClick={() => {
                    addNewFriendConfirmation.open("Lion Amari");
                  }}
                  sx={{
                    mr: "1rem",
                    borderRadius: "10px",
                    p: "0.2rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "var(--purple-dark)",
                  }}
                >
                  <AddIcon sx={{ color: "white" }} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      );
    };

    return (
      <Modal
        sx={{ overflowY: "scroll" }}
        open={true}
        onClose={newFriendsDialogHandler}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100%",
            p: "1rem",
          }}
        >
          <Box
            sx={{
              backgroundColor: "white",
              maxWidth: "45rem",
              width: "90%",
              p: "1rem",
              borderRadius: "15px",
              position: "relative",
            }}
          >
            <CloseIcon
              onClick={newFriendsDialogHandler}
              sx={{
                cursor: "pointer",
                position: "absolute",
                top: "5px",
                right: "5px",
                color: "var(--purple-dark)",
              }}
            />

            <Typography
              sx={{
                mb: "0.5rem",
                color: "var(--orange)",
                fontSize: "1.3rem",
                fontWeight: "700",
              }}
            >
              Find friends
            </Typography>
            <Grid columnSpacing={"1rem"} mb="1rem" container>
              <Grid item xs>
                <input
                  value={state.searchQuery}
                  onChange={(e) => handleSearchStudents(e.target.value)}
                  placeholder="Start typing name"
                  style={{
                    backgroundColor: "#EDEDFF",
                    borderRadius: "10px",
                    width: "100%",
                    border: "none",
                    padding: "0.5rem",
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Box
                  aria-controls={"basic-menu"}
                  onClick={dropDown.onOpen.location}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    p: "0.5rem",
                    alignItems: "center",
                    width: "100%",
                    backgroundColor: "#EDEDFF",
                    borderRadius: "10px",
                  }}
                >
                  <Typography>{state.location.value}</Typography>
                  <ArrowDropDownIcon
                    sx={{
                      transform: state.location.open && "rotate(180deg)",
                      transition: "transform 0.2s",
                    }}
                  />
                </Box>
                <Menu
                  anchorEl={state.location.anchor}
                  open={state.location.open}
                  onClose={dropDown.onClose.location}
                >
                  <Box sx={{ width: state.location.width + "px", p: "0.5rem" }}>
                    <Select
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: "10rem",
                          },
                        },
                      }}
                      sx={{ backgroundColor: "#EDEDFF" }}
                      label="Country"
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={state.selectedCountry.id}
                      onChange={(e) => {
                        selectCountry(e.target.value);
                      }}
                    >
                      {state.countries.map((country, idx) => {
                        return (
                          <MenuItem
                            key={idx}
                            name={country.name}
                            value={country.id}
                          >
                            {country.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <Select
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: "10rem",
                          },
                        },
                      }}
                      sx={{ backgroundColor: "#EDEDFF" }}
                      label="City"
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={state.selectedCountry.id}
                      onChange={(e) => {
                        selectCountry(e.target.value);
                      }}
                    >
                      {state.cities.map((city, idx) => {
                        return (
                          <MenuItem key={idx} name={city.name} value={city.id}>
                            {city.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Box>
                </Menu>
              </Grid>
              <Grid item xs={3}>
                {/* All subjects */}
                <Box
                  aria-controls={"basic-menu"}
                  onClick={dropDown.onOpen.subject}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    p: "0.5rem",
                    alignItems: "center",
                    width: "100%",
                    backgroundColor: "#EDEDFF",
                    borderRadius: "10px",
                  }}
                >
                  <Typography>{state.subject.value}</Typography>
                  <ArrowDropDownIcon
                    sx={{
                      transform: state.subject.open && "rotate(180deg)",
                      transition: "transform 0.2s",
                    }}
                  />
                </Box>

                <Menu
                  anchorEl={state.subject.anchor}
                  open={state.subject.open}
                  onClose={dropDown.onClose.subject}
                >
                  <Box sx={{ width: state.subject.width + "px", p: "0.5rem" }}>
                    {subjects.map((sub) => {
                      return (
                        <Box
                          key={sub.id}
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <Checkbox />
                          <Typography>{sub.name}</Typography>
                        </Box>
                      );
                    })}
                  </Box>
                </Menu>
              </Grid>
            </Grid>
            <Stack
              sx={{
                height: "20rem",

                overflowY: "scroll",
                pb: "1rem",
              }}
            >
              {searchedFriends.map((student, idx, arr) => {
                return (
                  <>
                    <StudentCard key={idx} student={student} />
                    {idx !== arr.length - 1 && <Divider sx={{ my: "1rem" }} />}
                  </>
                );
              })}
            </Stack>
          </Box>
        </Box>
      </Modal>
    );
  };

  return (
    <>
      {state.newFriendsDialog.isOpen && <FindNewFriendsDialog />}
      {state.isFriendsDialogOpen && (
        <FriendsDialog
          onClose={() =>
            setstate((prevState) => ({
              ...prevState,
              isFriendsDialogOpen: false,
            }))
          }
        />
      )}

      {state.addStudentConfirmationDialog.isOpen && (
        <DialogBox close={() => {}} isOpen={true}>
          <Box
            sx={{ position: "relative", minWidth: "20rem", minHeight: "7rem" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.3rem",
                  fontWeight: "800",
                  color: "var(--purple-dark)",
                }}
              >
                Do you want to add{" "}
                {state.addStudentConfirmationDialog.studentName}
              </Typography>
              <Typography
                sx={{
                  fontSize: "1.3rem",
                  fontWeight: "800",
                  color: "var(--purple-dark)",
                }}
              >
                to friends
              </Typography>
            </Box>
            <Grid container sx={{ position: "absolute" }}>
              <Grid item xs={6}>
                <Box sx={{ width: "70%" }}>
                  <StyledYellowButton
                    title={"Yes"}
                    btnHandler={() => {
                      addNewFriendConfirmation.close();
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ width: "70%" }}>
                  <StyledYellowButton
                    title={"Cancel"}
                    btnHandler={() => {
                      addNewFriendConfirmation.close();
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogBox>
      )}

      <Grid columnSpacing={"1rem"} alignItems="center" container>
        <Grid item md={8}>
          <input
            placeholder="Start typing name"
            style={{
              backgroundColor: "#EDEDFF",
              borderRadius: "10px",
              width: "100%",
              border: "none",
              padding: "0.5rem",
              paddingRight: "30px",
            }}
          />
          <SearchIcon
            sx={{
              color: "var(--purple)",
              position: "absolute",
              right: "5px",
              top: "7px",
            }}
          />
        </Grid>

        <Grid item md={2}>
          <Box sx={{ width: "90%" }}>
            <StyledYellowButton
              title={"Filter"}
              btnHandler={() => {
                setstate((prevState) => ({
                  ...prevState,
                  isFriendsDialogOpen: true,
                }));
              }}
            />
          </Box>
        </Grid>
        <Grid item md={2}>
          <Box width={"90%"}>
            <StyledYellowButton
              title={"Find new friends"}
              btnHandler={newFriendsDialogHandler}
            />
          </Box>
        </Grid>
      </Grid>

      <Typography
        sx={{
          color: "var(--orange)",
          fontSize: "1.4rem",
          fontWeight: "600",
          m: "2rem 0 1rem",
        }}
      >
        892 tutors
      </Typography>

      <Box sx={{ paddingBottom: "5rem" }}>
        {connectionsData.map((tutor, idx) => {
          return (
            <Box
              onClick={() => history.push("/student/friendDetails")}
              key={idx}
              sx={{
                cursor: "pointer",
                p: "1.4rem",
                mb: "1rem",
                backgroundColor: "white",
                borderRadius: "1.2rem",
              }}
            >
              <Grid
                container
                alignItems={"center"}
                // justifyContent="space-between"
                columnSpacing={"1rem"}
              >
                <Grid item md={1}>
                  <PersonIcon
                    sx={{
                      color: "white",
                      backgroundColor: "var(--purple)",
                      borderRadius: "50%",
                      height: "3rem",
                      width: "3rem",
                      p: "0.3rem",
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <Box sx={{}}>
                    <Typography sx={{ fontWeight: "800", fontSize: "1rem" }}>
                      {tutor.name}
                    </Typography>
                    <Box sx={{ display: "flex", columnGap: "2rem" }}>
                      <Typography sx={{ color: "var(--purple)" }}>
                        {tutor.location}
                      </Typography>
                      <Typography sx={{ color: "var(--purple)" }}>
                        {tutor.yearsOfStudy + " years of study"}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item md justifySelf={"center"}>
                  <Stack flexDirection="row" columnGap={"2rem"}>
                    <Stack alignItems="center">
                      <Typography
                        sx={{
                          color: "var(--purple-dark)",
                          fontSize: "1.3rem",
                          fontWeight: "800",
                        }}
                      >
                        {tutor.points}
                      </Typography>
                      <Typography
                        sx={{
                          textAlign: "center",
                          color: "var(--purple)",
                          backgroundColor: "var(--yellow)",
                          borderRadius: "8px",
                          p: "0.2rem",
                        }}
                      >
                        points
                      </Typography>
                    </Stack>
                    <Stack alignItems="center">
                      <Typography
                        sx={{
                          color: "var(--purple-dark)",
                          fontSize: "1.3rem",
                          fontWeight: "800",
                        }}
                      >
                        {tutor.games}
                      </Typography>
                      <Typography
                        sx={{
                          textAlign: "center",
                          color: "var(--purple)",
                          backgroundColor: "var(--yellow)",
                          borderRadius: "8px",
                          p: "0.2rem",
                        }}
                      >
                        games
                      </Typography>
                    </Stack>
                    <Stack alignItems="center">
                      <Typography
                        sx={{
                          color: "var(--purple-dark)",
                          fontSize: "1.3rem",
                          fontWeight: "800",
                        }}
                      >
                        {tutor.rating + "th"}
                      </Typography>
                      <Typography
                        sx={{
                          textAlign: "center",
                          color: "var(--purple)",
                          backgroundColor: "var(--yellow)",
                          borderRadius: "8px",
                          p: "0.2rem",
                        }}
                      >
                        in the rating
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item md={2}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      columnGap: "0.7rem",
                    }}
                  >
                    <FavoriteBorderIcon
                      sx={{
                        backgroundColor: "var(--purple-dark)",
                        p: "0.4rem",
                        fontSize: "2rem",
                        color: "white",
                        borderRadius: "10px",
                      }}
                    />
                    <Box
                      sx={{
                        borderRadius: "10px",
                        p: "0.2rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "var(--yellow)",
                      }}
                    >
                      <img
                        style={{ height: "1.5rem" }}
                        alt="chat"
                        src="/images/icons/chat.png"
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          );
        })}
      </Box>
    </>
  );
};
