import React, { useEffect, useState } from "react";
import "../../home.scss";
import "../../profile.scss";
import "../../media.scss";
import "../../games.scss";
import "../../loginModal/login.scss";
import "../../messages/messages.scss";
import "../../myactivities.scss";
import "../../gamestabs.scss";
import "../../gamesflowphotos.scss";
import { Container, Row, Form } from "react-bootstrap";

import logo from "../../../assets/images/logo.png";
import FooterEducator from "../../layout/Footer/FooterEducator";
import TopSearchBar from "../TopSearchBar";

function Gamesflowphotos({
  closeModal,
  data,
  selectedGame,
  type,
  submitHandler,
}) {
  const [grade, setGrade] = useState("0");
  const [textSearch, setTextSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedId, setSelectedId] = useState("");

  useEffect(() => {
    let searchReseult = data?.filter(
      (game) =>
        game.categoryName
          ?.toLowerCase()
          .trim()
          .includes(textSearch.toLowerCase()) ||
        game.title?.toLowerCase().trim().includes(textSearch.toLowerCase()),
    );
    if (grade !== "0") {
      searchReseult = searchReseult?.filter(
        (game) => +game.studentGrade === +grade,
      );
    }
    setFilteredData(searchReseult);
  }, [grade, textSearch, data]);

  const handleSelect = (item) => {
    // if smae id then unselect
    if (selectedId === item.id) {
      setSelectedId("");
    } else setSelectedId(item.id);
  };

  let btnText =
    type === "minigame"
      ? "Add game to selected gameflow"
      : "Add material to selected gameflow";
  return (
    <div className="home-wrapper">
      <div className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs">
        <div className="gamestabs-main gamesflow-popup-bxx">
          <div className="flownew-gamesbx">
            <Container>
              <div className="Minigames-bx">
                <div className="minigames-cards">
                  <div className="close-flowgame">
                    <div className="btnclose-flowgm" onClick={closeModal}>
                      <span aria-hidden="true">×</span>
                    </div>
                  </div>
                  <div className="heading-top-flowgames">
                    <h4>
                      Select the minigames to add to gameflow Photo synthesis{" "}
                    </h4>
                  </div>
                  <TopSearchBar
                    textSearch={textSearch}
                    setTextSearch={setTextSearch}
                    selectedGrade={grade}
                    setSelectedGrade={setGrade}
                    btnText={btnText}
                    btnClickHandler={() =>
                      submitHandler(selectedId, selectedGame)
                    }
                  />
                  <div className="inner-cardquizz">
                    <div className="inner-bodyquiz">
                      <div className="quiz-body-content">
                        <div className="quiz-bdy-content-bx">
                          <h6>{selectedGame?.gameType}</h6>
                          <h3>{selectedGame?.title}</h3>
                          <h5>{selectedGame?.category}</h5>
                          <h4>
                            {selectedGame?.studentGrade}
                            {selectedGame?.studentGrade == 1
                              ? "st "
                              : selectedGame?.studentGrade == 2
                              ? "nd "
                              : selectedGame?.studentGrade == 3
                              ? "rd "
                              : "th "}
                            year of study
                          </h4>
                          <p>{selectedGame.description}</p>
                          <div className="points-btn">
                            <span className="totalpoints-btn">
                              {selectedGame.points} points
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-quizgames gamesflw-cardchek">
                    <Row>
                      {filteredData &&
                        filteredData?.map((item, index) => {
                          return (
                            <div className="col-md-3 onecard" key={index}>
                              <div
                                className="inner-cardquizz"
                                onClick={() => {
                                  handleSelect(item);
                                }}
                              >
                                <div className="custm-check gameflow-check">
                                  <span
                                    className="checkmark"
                                    style={{
                                      backgroundColor:
                                        +selectedId === +item.id && "#Ff7a00",
                                    }}
                                  ></span>
                                  <div className="inner-bodyquiz">
                                    <div className="quiz-body-content gameflw-contbx">
                                      <div className="quiz-bdy-content-bx">
                                        <h3>{item.title}</h3>
                                        <div className="type-material-iner">
                                          {item?.gameTypes
                                            ?.split(",")
                                            .slice(0, 7)
                                            .map((gameType, i) => {
                                              return <p key={i}>{gameType}</p>;
                                            })}
                                          {item?.gameTypes?.split(",").length >
                                            7 && <p>...</p>}
                                        </div>
                                        <h5>{item.category}</h5>
                                        <h4>
                                          {item.studentGrade}
                                          {item.studentGrade == 1
                                            ? "st "
                                            : item.studentGrade == 2
                                            ? "nd "
                                            : item.studentGrade == 3
                                            ? "rd "
                                            : "th "}
                                          year of study
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </Row>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>

        <FooterEducator logo={logo} />
      </div>
    </div>
  );
}

export default Gamesflowphotos;
