import React from "react";
import "./home.scss";
import "./profile.scss";
import "./media.scss";
import "./games.scss";
import "./myactivities.scss";
import "./Currentlesson.scss";
import "./newlesson.scss";
import { Container } from "react-bootstrap";

import logo from "../assets/images/logo.png";
import longarrowbk from "../assets/images/long-backarow.svg";

import { Link } from "react-router-dom";
import Footer from "./layout/Footer/Footer";
import { HeaderDashboard } from "./layout/HeaderDashboard";

function Newlesson() {
  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderDashboard />
      {/* end header */}

      <div className="games-dashboard-main dashboard-bgnew blur-bgg">
        <div className="current-lesson-main newlesson-mainn">
          <Container>
            <div className="current-lesson-contain ">
              <div className="backbtn">
                <Link to="#" className="backbtnn-icnn">
                  <img src={longarrowbk} className="img-fluid" />
                </Link>
              </div>
              <div className="current-lesson-topbr">
                <div className="medm-tex orng-clr">Live lesson</div>
                <h1>
                  Title of the lesson for useful searching something about
                  something
                </h1>
              </div>

              <div className="time-newlesn">
                <div className="newtime-custome">
                  <div className="newtime-lesson-box">
                    <div className="lesson-status-list">
                      <ul className="lesson-listing-curnt">
                        <li>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Time:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              May 19, 11.00 am
                            </span>
                          </div>
                        </li>
                        <li>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Status:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              New
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="lessontime-bottom">
                    <div className="lessonnew-cardbx">
                      <div className="newlesson-top">
                        <div className="bnewleson-heading">
                          Time to the lesson
                        </div>
                      </div>
                      <div className="newlesson-clock">
                        <ul className="newlesson-listclk">
                          <li>
                            <div className="clock-time">
                              <div className="timenum">5</div>
                              <div className="time-days">days</div>
                            </div>
                          </li>
                          <li>
                            <div className="clock-time">
                              <div className="timenum">14</div>
                              <div className="time-days">hours</div>
                            </div>
                          </li>
                          <li>
                            <div className="clock-time">
                              <div className="timenum">37</div>
                              <div className="time-days">minutes</div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="btn-list-lesson">
                        <ul className="btn-listing-lesson">
                          <li>
                            <div className="btn-buy">
                              <div className="btn-week">
                                <a href="#" className="btnweak-bx">
                                  <span className="btn-title">
                                    Chat with the educator
                                  </span>
                                </a>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="btn-buy">
                              <div className="btn-week">
                                <a href="#" className="btnweak-bx">
                                  <span className="btn-title">
                                    Cancel the lesson
                                  </span>
                                </a>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="newlesson-shape"></div>
                    </div>
                  </div>

                  <div className="check-lesson">
                    <div className="lesson-checknw">
                      <Link to="#" className="prev-lesson-chk">
                        Check the previous lesson
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>

        {/* footer start */}
        <Footer logo={logo} />
        {/* footer end */}
      </div>
    </div>
  );
}

export default Newlesson;
