import { Button, Container, Form, Modal, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import FooterEducator from "../../layout/Footer/FooterEducator";
import HeaderGames from "../../layout/Header/HeaderGames";
import logo from "../../../assets/images/logo.png";
import { useContext, useEffect, useState } from "react";
import { disabledBtnProps } from "../../../utils/util";
import { CustomSnackbar, useSnackState } from "../../../utils/useSnackState";
import { Box, Pagination, Typography } from "@mui/material";
import { getAllGamesList } from "../../../service/apis/games";
import { activitiesSendMultiGame } from "../../../service/apis/activities";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import DeadlineMpdal from "../../dialogs/DeadlineMpdal";
import { convertDateToString } from "../../../utils/helperFunctions";
import { NotificationContext } from "../../../contexts/Notification";

export const SelectMyGames = ({ history }) => {
  const [selectedGames, setselectedGames] = useState([]);
  const [games, setGames] = useState([]);
  const [allgames, setAllGames] = useState({
    myMinigames: [],
    adminMinigames: [],
    myGameflows: [],
  });

  const [searchData, setSearchData] = useState([]);
  const [seletetGameType, setseletetGameType] = useState(1);
  const [snackProps, open] = useSnackState();
  const [deadline, setdeadline] = useState(new Date());
  const [search, setSearch] = useState("");
  const [grade, setGrade] = useState("0");
  const [modalgamesavedstep2Show, setgamesavedstep2ModalShow] = useState(false);
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(8);
  const [filalData, setFinalData] = useState(games?.slice(0, postsPerPage));
  const [currentTab, setCurrentTab] = useState(1);
  const { showNotification } = useContext(NotificationContext);

  const handleAddSelectedGames = () => {
    if (selectedGames.length === 0) {
      open("Select atleast 1 game", "error");
      return;
    } else {
      console.log("selectedGames", selectedGames);
      setgamesavedstep2ModalShow(true);
    }
  };

  const data = [
    {
      id: 0,
      title: "My Gameflows",
    },

    {
      id: 1,
      title: "My Games",
    },
    {
      id: 2,
      title: "Neithedu Games",
    },
  ];

  const handleSelectGame = (game) => {
    const isSelected = Boolean(
      selectedGames.find((grpStud) => grpStud.id === game.id),
    );
    if (isSelected) {
      setselectedGames(selectedGames.filter((item) => item.id !== game.id));
    } else {
      setselectedGames((pre) => [...pre, game]);
    }
  };

  const setGameData = (id) => {
    if (id === 0) {
      setGames(allgames.myGameflows || []);
    } else if (id === 1) {
      setGames(allgames.myMinigames || []);
    } else if (id === 2) {
      setGames(allgames.adminMinigames || []);
    }
    setseletetGameType(id);
  };

  const grades = (() => {
    const grades = [...Array(13).keys()];
    grades.splice(0, 1);

    return grades;
  })();
  const queryClient = useQueryClient();

  const sendActivite = async () => {
    console.log("selectedGamesToSend", selectedGames);
    // get query params of studentId or groupId

    const query = new URLSearchParams(location.search);
    const studentId = query.get("studentId");
    const groupId = query.get("groupId");

    const deadlineDate = convertDateToString(deadline);

    const res = await activitiesSendMultiGame(
      currentUser.applicationUser.id,
      studentId ? studentId : groupId,
      studentId ? false : true,
      selectedGames,
      deadlineDate,
      1,
    );
    if (res) {
      await queryClient.invalidateQueries({
        queryKey: [
          "activities",
          studentId ? studentId : groupId,
          currentUser.applicationUser.id,
        ],
      });
      setgamesavedstep2ModalShow(false);

      showNotification("Game sent successfully");
      setTimeout(() => {
        history.push(`/educator?studentId=${studentId}`);
      }, 2000);
    }
  };

  // filter data here
  useEffect(() => {
    console.log("games", games);
    let gamesData = games;
    // grade filteration
    if (grade !== "0") {
      gamesData = gamesData.filter((game) => game.studentGrade === grade);
    }

    const filteredGames = gamesData.filter(
      (game) =>
        game.gameType?.toLowerCase().trim().includes(search.toLowerCase()) ||
        game.title?.toLowerCase().trim().includes(search.toLowerCase()) ||
        game.category?.toLowerCase().trim().includes(search.toLowerCase()) ||
        game.description?.toLowerCase().trim().includes(search.toLowerCase()),
    );
    setSearchData(filteredGames);
  }, [search, games, grade]);

  useEffect(() => {
    const lastPostIndex = currentPage * postsPerPage;
    const firstPostIndex = lastPostIndex - postsPerPage;
    const currentPosts = searchData?.slice(firstPostIndex, lastPostIndex);
    setFinalData(currentPosts);
  }, [currentPage, searchData]);

  const handelChange = (e, p) => {
    setCurrentPage(p);
  };
  const query = new URLSearchParams(location.search);

  const { data: allGamesData, isLoading: allGamesLoading } = useQuery({
    queryKey: [
      "allGames",
      query.get("studentId") ? query.get("studentId") : query.get("groupId"),
      query.get("groupId") ? true : false,
    ],
    queryFn: async () => {
      const query = new URLSearchParams(location.search);
      const studentId = query.get("studentId");
      const groupId = query.get("groupId");
      let isGroup = groupId ? true : false;
      let assignToId = studentId ? studentId : groupId;
      return await getAllGamesList(assignToId, isGroup);
    },
    enabled: !!query,
  });

  useEffect(() => {
    if (allGamesData && !allGamesLoading) {
      setAllGames(allGamesData);
      // setGames(allGamesData?.myMinigames);
      let _games;
      if (currentTab === 0) {
        _games = allGamesData?.myGameflows;
      }
      if (currentTab === 1) {
        _games = allGamesData?.myMinigames;
      }
      if (currentTab === 2) {
        _games = allGamesData?.adminMinigames;
      }
      setGames(_games);
    }
  }, [allGamesData, allGamesLoading, currentTab]);

  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderGames />
      {/* end header */}
      <CustomSnackbar {...snackProps} />
      <div className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs">
        <div className="gamestabs-main gamesflow-popup-bxx">
          <div className="flownew-gamesbx">
            <Container>
              <div className="Minigames-bx mb_tab">
                <div className="minigames-cards">
                  <div className="close-flowgame">
                    <Link to="/educator" className="btnclose-flowgm">
                      <span aria-hidden="true">×</span>
                    </Link>
                  </div>
                  <div className="heading-top-flowgames">
                    <h4>
                      Select the minigames to add to gameflow Photo synthesis{" "}
                    </h4>
                  </div>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                      justifyContent: "center",
                      mt: "1rem",
                    }}
                  >
                    {data.map((item, i) => {
                      return (
                        <Typography
                          variant="h6"
                          onClick={() => {
                            setGameData(item.id);
                            setCurrentTab(item.id);
                            setCurrentPage(1);
                          }}
                          key={i}
                          sx={{
                            fontWeight: "700",
                            cursor: "pointer",

                            borderBottom:
                              item.id === seletetGameType
                                ? "3px solid #ff7a00"
                                : "3px solid transprent",
                          }}
                        >
                          {item.title}
                        </Typography>
                      );
                    })}
                  </Box>
                  <div className="games-topserch-bar">
                    <Form>
                      <Row className="align-center">
                        <div className="col-md-3 one">
                          <div className="list-study">
                            <Form.Control
                              as="select"
                              value={grade}
                              onChange={(e) => setGrade(e.target.value)}
                            >
                              <option value="0">All years of study</option>
                              {grades.map((val, index) => {
                                return (
                                  <option
                                    value={val}
                                    key={index}
                                  >{`${val}th year of study`}</option>
                                );
                              })}
                            </Form.Control>
                          </div>
                        </div>
                        <div className="col-md-6 two">
                          <div className="serch-bx-games">
                            <div className="serchfiled-activities">
                              <Form.Control
                                type="text"
                                placeholder="Start typing title, subject or any other keyword "
                                className="formbox"
                                onChange={(e) => {
                                  setSearch(e.target.value);
                                }}
                              />
                              <Button type="button" className="seachbtn">
                                <i className="fas fa-search"></i>
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 three">
                          <div className="createnew-games">
                            <div className="game-opn-create">
                              {selectedGames.length === 0 ? (
                                <div
                                  onClick={handleAddSelectedGames}
                                  style={{
                                    ...disabledBtnProps,
                                  }}
                                  className="btnweak-bx gamestbs-btn"
                                >
                                  Add selected games
                                </div>
                              ) : (
                                <div
                                  onClick={() => handleAddSelectedGames()}
                                  className="btnweak-bx gamestbs-btn"
                                >
                                  Add selected games
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </Row>
                    </Form>
                  </div>
                  {allGamesData?.length === 0 ? (
                    <Container maxwidth="sm">
                      <Box
                        sx={{
                          mt: "1rem",
                          width: "100%",
                          backgroundColor: "white",
                          borderRadius: "20px",
                          p: "1rem",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "var(--purple-dark)",
                            fontWeight: "600",
                            fontSize: "1.5rem",
                            mb: "1.5rem",
                            mt: "1rem",
                          }}
                        >
                          You haven't created any games yet.{" "}
                          <span
                            onClick={() => history.push("/GamesTabs")}
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                          >
                            Click here to goto games tab
                          </span>
                        </Typography>
                      </Box>
                    </Container>
                  ) : (
                    <>
                      {filalData.length === 0 ? (
                        <p>No game found!</p>
                      ) : (
                        <div className="card-quizgames gamesflw-cardchek">
                          <Row>
                            {filalData?.map((game, idx) => {
                              const isSelected = Boolean(
                                selectedGames.find(
                                  (grpStud) => grpStud.id === game.id,
                                ),
                              );
                              return (
                                <div className="col-md-3 onecard" key={idx}>
                                  <div className="inner-cardquizz">
                                    <label className="custm-check gameflow-check">
                                      <Form.Control
                                        onChange={() => handleSelectGame(game)}
                                        type="checkbox"
                                        checked={isSelected}
                                      />
                                      <span className="checkmark"></span>
                                      <div className="inner-bodyquiz">
                                        <div className="quiz-body-content gameflw-contbx">
                                          <div className="quiz-bdy-content-bx">
                                            <h6>{game.gameType}</h6>
                                            <h3>{game.title}</h3>
                                            <div className="type-material-iner">
                                              {/* {game.activities
                                            .slice(0, 4)
                                            .map((activity) => (
                                              <p> {activity} </p>
                                            ))} */}

                                              {game.description}

                                              {/* {game.activities.length > 4 && (
                                            <p>...</p>
                                          )} */}
                                            </div>

                                            <span className="totalpoints-btn">
                                              {game.points} points
                                            </span>

                                            <h5>{game.category}</h5>
                                            <h4>
                                              {game.studentGrade}th year of
                                              study
                                            </h4>
                                          </div>
                                        </div>
                                      </div>
                                    </label>
                                  </div>
                                </div>
                              );
                            })}

                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Pagination
                                count={Math.ceil(
                                  searchData.length / postsPerPage,
                                )}
                                color="primary"
                                onChange={handelChange}
                                page={currentPage}
                              />
                            </Box>
                          </Row>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </Container>
          </div>
        </div>

        {/* footer start */}
        <FooterEducator logo={logo} />
        {/* footer end */}

        {/* end Add a Member Group step2 */}

        <DeadlineMpdal
          open={modalgamesavedstep2Show}
          closeModal={() => {
            setgamesavedstep2ModalShow(false);
          }}
          snackProps={snackProps}
          deadline={deadline}
          setdeadline={setdeadline}
          submitHandler={() => sendActivite()}
          openSnack={open}
        />

        {/* end modal my student */}
      </div>
    </div>
  );
};
