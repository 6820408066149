import {
  Box,
  // Button,
  // Checkbox,
  // Grid,
  // Menu,
  // MenuItem,
  // Rating,
  // Select,
  Stack,
  // TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
// import PersonIcon from "@mui/icons-material/Person";
// import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { HeaderDashboard } from "../../layout/HeaderDashboard";
// import WorkIcon from "@mui/icons-material/Work";
import CircleIcon from "@mui/icons-material/Circle";
// import SchoolIcon from "@mui/icons-material/School";
// import { StyledYellowButton } from "../../utils/Buttons";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import logo from "../../../assets/images/logo.png";
// import SearchIcon from "@mui/icons-material/Search";
import Footer from "../../layout/Footer/Footer";
import { useHistory } from "react-router";
// import { getCities, getCountries } from "../../../service/apis/common";
import { MyFriends } from "./MyFriends";
import { Requests } from "./Requests";
import { NeitheduRatings } from "./NeitheduRatings";

const connectionsData = [
  {
    name: "Lion Amari",
    yearsOfStudy: 7,
    points: "13 823",
    location: "India, Mumbai",
    games: 637,
    rating: 84,
  },
  {
    name: "Lion Amari",
    yearsOfStudy: 7,
    points: "13 823",
    location: "India, Mumbai",
    games: 637,
    rating: 84,
  },
  {
    name: "Lion Amari",
    yearsOfStudy: 7,
    points: "13 823",
    location: "India, Mumbai",
    games: 637,
    rating: 84,
  },
  {
    name: "Lion Amari",
    yearsOfStudy: 7,
    points: "13 823",
    location: "India, Mumbai",
    games: 637,
    rating: 84,
  },
  {
    name: "Lion Amari",
    yearsOfStudy: 7,
    points: "13 823",
    location: "India, Mumbai",
    games: 637,
    rating: 84,
  },
];

const tabs = {
  MY_FRIENDS: "MY_FRIENDS",
  REQUESTS: "REQUESTS",
  NEITHEDU_RATING: "NEITHEDU_RATING",
};

export const MyConnections = () => {
  // const history = useHistory();
  const [state, setstate] = useState({
    currentTab: tabs.MY_FRIENDS,
  });

  const CustomTab = ({ tabName, currentTab, children }) => {
    const isSel = tabName === currentTab;
    return (
      <Box
        onClick={() =>
          setstate((prevState) => ({ ...prevState, currentTab: tabName }))
        }
        sx={{
          zIndex: "2",
          cursor: "pointer",
          minWidth: "8rem",
          display: "flex",
          justifyContent: "center",
          color: "white",
          pb: "0.3rem",
          fontWeight: isSel ? "700" : "400",
          fontSize: "1.1rem",
          borderBottom: isSel ? "4px solid var(--orange)" : "none",
        }}
      >
        {children}
      </Box>
    );
  };

  return (
    <div className="home-wrapper">
      <HeaderDashboard />
      <div
        style={{ minHeight: "100vh" }}
        className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs"
      >
        <Container style={{ textAlign: "start" }}>
          <Box
            sx={{
              color: "white",
              display: "flex",
              columnGap: "0.5rem",
              alignItems: "center",
            }}
          >
            <Typography fontSize="0.7rem">Home</Typography>
            <CircleIcon sx={{ fontSize: "0.5rem" }} />
            <Typography fontSize="0.7rem">Tutors</Typography>
          </Box>

          <Typography
            sx={{
              color: "white",
              fontSize: "2rem",
              fontWeight: "700",
              mt: "2rem",
            }}
          >
            My connections
          </Typography>
          <Stack
            columnGap={"2rem"}
            flexDirection={"row"}
            sx={{ color: "white", transition: "all 1s" }}
            justifyContent={"center"}
          >
            <CustomTab currentTab={state.currentTab} tabName={tabs.REQUESTS}>
              <Typography fontWeight={"inherit"} fontSize="inherit">
                Requests
              </Typography>
            </CustomTab>
            <CustomTab currentTab={state.currentTab} tabName={tabs.MY_FRIENDS}>
              <Typography fontWeight={"inherit"} fontSize="inherit">
                My friends
              </Typography>
            </CustomTab>
            <CustomTab
              currentTab={state.currentTab}
              tabName={tabs.NEITHEDU_RATING}
            >
              <Typography fontWeight={"inherit"} fontSize="inherit">
                Neithedu rating
              </Typography>
            </CustomTab>
          </Stack>
          <Box
            sx={{
              mx: "auto",
              mb: "2rem",
              width: "90%",
              height: "1px",
              backgroundColor: "rgba(255,255,255,0.4)",
              position: "relative",
              top: "-2px",
            }}
          ></Box>

          {state.currentTab === tabs.MY_FRIENDS && <MyFriends />}
          {state.currentTab === tabs.REQUESTS && <Requests />}
          {state.currentTab === tabs.NEITHEDU_RATING && <NeitheduRatings />}
        </Container>

        <Footer logo={logo} />
      </div>
    </div>
  );
};
