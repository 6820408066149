import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import PersonIcon from "@mui/icons-material/Person";
import { useHistory } from "react-router";

const data = [
  {
    name: "Lion Amari",
    yearsOfStudy: 7,
    points: "13 823",
    location: "India, Mumbai",
    games: 637,
    rating: 84,
    badges: 12,
  },
  {
    name: "Lion Amari",
    yearsOfStudy: 7,
    points: "13 823",
    location: "India, Mumbai",
    games: 637,
    rating: 84,
    badges: 12,
  },
  {
    name: "Lion Amari",
    yearsOfStudy: 7,
    points: "13 823",
    location: "India, Mumbai",
    games: 637,
    rating: 84,
    badges: 12,
  },
  {
    name: "Lion Amari",
    yearsOfStudy: 7,
    points: "13 823",
    location: "India, Mumbai",
    games: 637,
    rating: 84,
    badges: 12,
  },
  {
    name: "Lion Amari",
    yearsOfStudy: 7,
    points: "13 823",
    location: "India, Mumbai",
    games: 637,
    rating: 84,
    badges: 12,
  },
  {
    name: "Lion Amari",
    yearsOfStudy: 7,
    points: "13 823",
    location: "India, Mumbai",
    games: 637,
    rating: 84,
    badges: 12,
  },
];
export const NeitheduRatings = () => {
  const history = useHistory();
  const StudentCard = ({ student }) => {
    return (
      <Box
        onClick={() => history.push("/student/friendDetails")}
        sx={{
          cursor: "pointer",
          p: "1.4rem",
          mb: "1rem",
          backgroundColor: "white",
          borderRadius: "1.2rem",
        }}
      >
        <Grid container alignItems={"center"} columnSpacing={"1rem"}>
          <Grid item md={1}>
            <Stack flexDirection="row" sx={{ color: "var(--orange)" }}>
              <Typography
                sx={{
                  verticalAlign: "bottom",
                  fontSize: "1.8rem",
                  fontWeight: "700",
                }}
              >
                {student.rank}
              </Typography>
              <Typography
                sx={{
                  verticalAlign: "bottom",
                  fontSize: "1.4rem",
                  fontWeigth: "600",
                }}
              >
                {student.rank == 1
                  ? "st"
                  : student.rank == 2
                  ? "nd"
                  : student.rank == 3
                  ? "rd"
                  : "th"}
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={1}>
            <PersonIcon
              sx={{
                color: "white",
                backgroundColor: "var(--purple)",
                borderRadius: "50%",
                height: "3rem",
                width: "3rem",
                p: "0.3rem",
              }}
            />
          </Grid>
          <Grid item md={3}>
            <Box sx={{}}>
              <Typography sx={{ fontWeight: "800", fontSize: "1rem" }}>
                {student.name}
              </Typography>
              <Box sx={{ display: "flex", columnGap: "2rem" }}>
                <Typography sx={{ color: "var(--purple)" }}>
                  {student.location}
                </Typography>
                <Typography sx={{ color: "var(--purple)" }}>
                  {student.yearsOfStudy + " years of study"}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item md>
            <Stack
              flexDirection="row"
              width={"100%"}
              justifyContent="end"
              columnGap={"4rem"}
            >
              <Stack alignItems="center">
                <Typography
                  sx={{
                    color: "var(--purple-dark)",
                    fontSize: "1.3rem",
                    fontWeight: "800",
                  }}
                >
                  {student.points}
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "var(--purple)",
                    backgroundColor: "var(--yellow)",
                    borderRadius: "8px",
                    p: "0.2rem",
                  }}
                >
                  points
                </Typography>
              </Stack>
              <Stack alignItems="center">
                <Typography
                  sx={{
                    color: "var(--purple-dark)",
                    fontSize: "1.3rem",
                    fontWeight: "800",
                  }}
                >
                  {student.games}
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "var(--purple)",
                    backgroundColor: "var(--yellow)",
                    borderRadius: "8px",
                    p: "0.2rem",
                  }}
                >
                  games
                </Typography>
              </Stack>
              <Stack alignItems="center">
                <Typography
                  sx={{
                    color: "var(--purple-dark)",
                    fontSize: "1.3rem",
                    fontWeight: "800",
                  }}
                >
                  {student.rating + "th"}
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "var(--purple)",
                    backgroundColor: "var(--yellow)",
                    borderRadius: "8px",
                    p: "0.2rem",
                  }}
                >
                  in the rating
                </Typography>
              </Stack>
              <Stack alignItems="center">
                <Typography
                  sx={{
                    color: "var(--purple-dark)",
                    fontSize: "1.3rem",
                    fontWeight: "800",
                  }}
                >
                  {student.badges}
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "var(--purple)",
                    backgroundColor: "var(--yellow)",
                    borderRadius: "8px",
                    p: "0.2rem",
                  }}
                >
                  badges
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    );
  };
  return (
    <>
      <Stack
        flexDirection={"row"}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Stack flexDirection="row">
          <Typography
            sx={{
              color: "var(--orange)",
              fontSize: "1.4rem",
              fontWeight: "600",
              m: "2rem 0 1rem",
            }}
          >
            23 923 students
          </Typography>
        </Stack>
        <Box
          sx={{
            borderRadius: "10px",
            backgroundColor: "rgba(255,255,255,0.1)",
            p: "0.4rem 1rem",
          }}
        >
          <Typography sx={{ color: "white", fontWeight: "200" }}>
            Me in the rating
          </Typography>
        </Box>
      </Stack>
      <Box>
        {data.map((student, idx) => {
          return <StudentCard student={{ ...student, rank: idx + 1 }} />;
        })}
      </Box>
    </>
  );
};
