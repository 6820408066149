import React from "react";
import "../../home.scss";
import "../../profile.scss";
import "../../media.scss";
import "../../games.scss";
import "../../loginModal/login.scss";
import "../../messages/messages.scss";
import "../../myactivities.scss";
import "../../gamestabs.scss";
import "../../gamesflowphotos.scss";
import "../../selectedgames.scss";
import "../../gamequiz.scss";
import {
  // Navbar,
  // Nav,
  // NavDropdown,
  Container,
  // Dropdown,
  // br,
  Row,
  // Accordion,
  // Card,
  Button,
  Form,
  Modal,
  // Select,
  // Sonnet,
} from "react-bootstrap";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import CloseIcon from "@mui/icons-material/Close";

import DoneIcon from "@mui/icons-material/Done";
import logo from "../../../assets/images/logo.png";
import sendIcon from "../../../assets/images/icons/sendicon.svg";
// import gamesgalery1 from "../assets/images/games-galery1.jpg";
// import gamesgalery2 from "../assets/images/games-galery2.jpg";
// import chaticon from "../assets/images/chat-icon.svg";
// import batchorange from "../assets/images/batch-orange.svg";
// import palinblue from "../assets/images/palin-blue.svg";
// import mike from "../assets/images/mike.svg";
// import attachemtnblue from "../assets/images/attachemtn-blue.svg";
// import downarrowblue from "../assets/images/down-arrow-blue.svg";
// import dotthree from "../assets/images/dotthree.svg";
// import addbtnround from "../assets/images/add-btn.svg";
// import darkblueplan from "../assets/images/darkblue-plan.svg";
import backorange from "../../../assets/images/backorange.svg";
import editorangelg from "../../../assets/images/editlg-orange.svg";
// import darkorangeplan from "../assets/images/darkorange-plan.svg";

// import Slider from "react-slick";
// import SliderCenter from "../../Slider";
import { Link } from "react-router-dom";
import FooterEducator from "../../layout/Footer/FooterEducator";
// import Addgroup from "../../Addgroup";
// import Addpupil from "../../Addpupil";
// import SendInvite from "../../SendInvite";
// import Groupmember from "../../Groupmember";
import HeaderGames from "../../layout/Header/HeaderGames";
import SendRevision from "../../SendRevision";
import Selectstudent from "../../Selectstudent";
import { Box, Grid, Stack, Typography, Divider } from "@mui/material";
import {
  newGameSubjects,
  newGameTopics,
  typesOfGames,
} from "../../../service/apis/games";

function SaveButton() {
  return (
    <div className="number-points-frm ">
      <div className="points-iner-nw">
        <Row>
          <div className="col-12 col-md-5 oneup">
            <div className="numheading">
              <h5>Number of points for the answer</h5>
            </div>
          </div>
          <div className="col-6 col-md-3 one">
            <div className="points-num-bx">
              <Form.Group className="subsform-fild">
                <Form.Control type="text" placeholder="20" />
              </Form.Group>
            </div>
          </div>
          <div className="col-6 col-md-4 two">
            <div className="save-points">
              <div className="btn-week">
                <Link to="#" className="btnweak-bx">
                  <span className="btn-title">Save</span>
                </Link>
              </div>
            </div>
          </div>
        </Row>
      </div>
    </div>
  );
}

const questionsData = [
  {
    questionType: "Fill-in-the-Blank",
    question:
      "Question text Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
    selected: null,
  },
  {
    questionType: "Fill-in-the-Blank",
    question:
      "Question text Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",

    selected: null,
  },
  {
    questionType: "Fill-in-the-Blank",
    question:
      "Question text Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
    selected: true,
  },
  {
    questionType: "Fill-in-the-Blank",
    question:
      "Question text Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
    selected: null,
  },
  {
    questionType: "Fill-in-the-Blank",
    question:
      "Question text Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",

    selected: false,
  },
  {
    questionType: "Fill-in-the-Blank",
    question:
      "Question text Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
    selected: null,
  },
  {
    questionType: "Fill-in-the-Blank",
    question:
      "Question text Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do?",
    answer: (
      <Stack flexDirection="column">
        <Typography>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam
        </Typography>
        <SaveButton />
      </Stack>
    ),
    selected: null,
  },
];

function EditGame({ onClose }) {
  const [newGameFormData, setnewGameFormData] = React.useState({
    title:
      "Cellular Life and Genetics, Title of the game that is located in Neithedu",
    gameTypes: [],
    subjects: [],
    topics: [],
    selectedGameType: "Quiz",
    selectedSubject: "",
    selectedTopic: "",
    description: "",
  });
  const fetchGamesTypes = async () => {
    try {
      const res = await typesOfGames();
      setnewGameFormData((prevState) => ({
        ...prevState,
        gameTypes: res.data.data,
        selectedGameType: res.data.data?.length > 0 ? res.data.data[0].id : "",
      }));
    } catch (e) {}
  };
  const fetchGameSubjects = async () => {
    try {
      const res = await newGameSubjects();
      setnewGameFormData((prevState) => ({
        ...prevState,
        subjects: res.data.data,
        selectedSubject: res.data.data?.length > 0 ? res.data.data[0].id : "",
      }));
    } catch (e) {}
  };
  const fetchGameTopics = async () => {
    try {
      const res = await newGameTopics(newGameFormData.selectedSubject);
      setnewGameFormData((state) => ({
        ...state,
        topics: res.data.data,
        selectedTopic: res.data.data?.length > 0 ? res.data.data[0].id : "",
      }));
    } catch (e) {}
  };

  const saveGame = () => {
    onClose();
  };

  React.useEffect(() => {
    fetchGameTopics();
  }, [newGameFormData.selectedSubject]);
  React.useEffect(() => {
    fetchGamesTypes();
    fetchGameTopics();
    fetchGameSubjects();
  }, []);
  return (
    <Modal
      className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
      // {...props}
      show={true}
      onHide={() => {
        return false;
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="login-modal-mainbx groupmbr-step2 mystudent-games">
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>New game</h4>
          </Modal.Title>
          <button type="button" onClick={onClose} className="close">
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="login-modl-form">
            <div className="loginform-bx">
              <Form.Group className="subsform-fild">
                <div className="newgames-createbx">
                  <Row>
                    <div className="col-md-12">
                      <Form.Label>Title of the game</Form.Label>
                      <Form.Control
                        value={newGameFormData.title}
                        onChange={(e) =>
                          setnewGameFormData((state) => ({
                            ...state,
                            title: e.target.value,
                          }))
                        }
                        type="text"
                        placeholder="Help your mother"
                      ></Form.Control>
                    </div>
                    <div className="col-md-12">
                      <Form.Label>Type of the game</Form.Label>
                      <Form.Control
                        onChange={(e) => {
                          setnewGameFormData((prevState) => ({
                            ...prevState,
                            selectedGameType: e.target.value,
                          }));
                        }}
                        value={newGameFormData.selectedGameType}
                        as="select"
                      >
                        {newGameFormData.gameTypes.map((type, i) => {
                          return (
                            <option key={i} value={type.id}>
                              {type.name}
                            </option>
                          );
                        })}{" "}
                      </Form.Control>
                    </div>
                    <div className="col-md-12">
                      <Form.Label>Subject</Form.Label>
                      <Form.Control
                        onChange={(e) =>
                          setnewGameFormData((prevState) => ({
                            ...prevState,
                            selectedSubject: e.target.value,
                          }))
                        }
                        value={newGameFormData.selectedSubject}
                        as="select"
                      >
                        {newGameFormData.subjects.map((subject, i) => {
                          return (
                            <option key={i} value={subject.id}>
                              {subject.name}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </div>
                    <div className="col-md-12">
                      <Form.Label>Topics</Form.Label>
                      <Form.Control
                        as="select"
                        onChange={(e) =>
                          setnewGameFormData((prevState) => ({
                            ...prevState,
                            selectedTopic: e.target.value,
                          }))
                        }
                        value={newGameFormData.selectedTopic}
                      >
                        {newGameFormData.topics.map((topic, i) => {
                          return (
                            <option key={i} value={topic.id}>
                              {topic.name}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </div>
                    {/* <div className="col-md-12">
                    <Form.Label>For children</Form.Label>
                    <Form.Control as="select">
                      <option value="2">7th year of study</option>
                    </Form.Control>
                  </div>
                  <div className="col-md-12">
                    <Form.Label>
                      How many points students can earn for the game
                    </Form.Label>
                  </div>
                  <div className="col-md-4 std-point-frm">
                    <Form.Control type="text" placeholder="10" />
                  </div>
                  <div className="col-md-4 std-point-frm std-point-label">
                    <Form.Label>points for</Form.Label>
                  </div>
                  <div className="col-md-4 std-point-frm">
                    <Form.Control as="select">
                      <option value="2">1 question</option>
                    </Form.Control>
                  </div> */}
                    <div className="col-md-12">
                      <Form.Label>Description (optional)</Form.Label>
                      <Form.Control
                        value={newGameFormData.description}
                        onChange={(e) =>
                          setnewGameFormData((state) => ({
                            ...state,
                            description: e.target.value,
                          }))
                        }
                        as="textarea"
                        placeholder="Add some text that only you will see with any notes, descriptions etc"
                      />
                    </div>
                  </Row>
                </div>
              </Form.Group>

              <div className="btnlogin-main">
                <Box onClick={saveGame} className="btnlogin btnweak-bx">
                  Save and go to questions
                </Box>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
}
function GameflowNotchecked() {
  const [show, setShow] = React.useState("1");

  const [breadMenuShow, setBreadMenuShow] = React.useState(false);
  /* start modal */
  const [modalShow, setModalShow] = React.useState(false);
  /* end modal */

  const [editGamePopupOpen, seteditGamePopupOpen] = React.useState(false);

  /* startsave gamed step1 */
  const [modalgamesavedstep1Show, setgamesavedstep1ModalShow] =
    React.useState(false);

  /* end save gamned step1 */

  /* start send revision popup */
  const [modalpersonaltskstep1Show, setpersonaltskstep1ModalShow] =
    React.useState(false);

  /* end send revision popup */

  /* start select student */
  const [modalAddgroupstep2Show, setAddgroupstep2ModalShow] =
    React.useState(false);

  /* end select student */

  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderGames setModalShow={(value) => setModalShow(value)} />
      {/* end header */}

      {editGamePopupOpen && (
        <EditGame onClose={() => seteditGamePopupOpen(false)} />
      )}

      <div className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs">
        <div className="games-photosynths gamequiz-main">
          <Container>
            <div className="quiz-game-popup">
              <div className="current-lesson-main ">
                <div className="current-lesson-contain">
                  <span className="back-btndrop">
                    <Link to="educator" className="backbtn-lg">
                      <img src={backorange} />
                    </Link>
                  </span>
                  <div className="right-iconbox-quizz">
                    <ul>
                      <li>
                        <Link
                          to="#"
                          className="editbtn-lgnew"
                          onClick={() => setAddgroupstep2ModalShow(true)}
                        >
                          <img src={sendIcon} />
                        </Link>
                      </li>
                      <li>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => seteditGamePopupOpen(true)}
                          className="editbtn-lgnew"
                        >
                          <img src={editorangelg} />
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="current-lesson-topbr">
                    <div className="medm-tex orng-clr">Neithedu Game: Quiz</div>
                    <div
                      style={{
                        marginTop: "1rem",
                        padding: "1rem 2rem",
                        borderRadius: "20px",
                        backgroundColor: "rgba(255,255,255,0.1)",
                      }}
                    >
                      <h1>
                        Cellular Life and Genetics, Title of the game that is
                        located in Neithedu
                      </h1>
                      <div className="lesson-status-list quiz-gamelession">
                        <ul className="lesson-listing-curnt">
                          <li>
                            <div className="lesson-status-detail">
                              <span className="medm-tex orng-clr onelist">
                                Mode:
                              </span>
                              <span className="medm-tex orng-clr twolist">
                                Active
                              </span>
                            </div>
                            <div className="lesson-status-detail">
                              <span className="medm-tex orng-clr onelist">
                                Status:
                              </span>
                              <span className="medm-tex orng-clr twolist">
                                Not checked
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className="lesson-status-detail">
                              <span className="medm-tex orng-clr onelist">
                                Deadline:
                              </span>
                              <span className="medm-tex orng-clr twolist">
                                2 Sep 2021
                              </span>
                            </div>
                            <div className="lesson-status-detail">
                              <span className="medm-tex orng-clr onelist">
                                Created:
                              </span>
                              <span className="medm-tex orng-clr twolist">
                                16 Aug 2021
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className="lesson-status-detail">
                              <span className="medm-tex orng-clr onelist">
                                Points:
                              </span>
                              <span className="medm-tex orng-clr twolist">
                                10 for question
                              </span>
                            </div>
                            <div className="lesson-status-detail">
                              <span className="medm-tex orng-clr onelist">
                                Total points:
                              </span>
                              <span className="medm-tex orng-clr twolist">
                                150 points
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Box
                sx={{
                  backgroundColor: "white",
                  borderRadius: "20px",
                  p: "1rem",
                  mt: "2rem",
                  position: "relative",
                  pt: "0.1rem",
                }}
              >
                {questionsData.map((question, idx) => {
                  const isSelected = false;
                  return (
                    <>
                      <Grid container my="1rem" alignItems="center" key={idx}>
                        <Grid item xs={3}>
                          <Box
                            sx={{
                              backgroundColor: isSelected
                                ? "var(--purple-dark)"
                                : "#EDEDFF",
                              borderRadius: "10px",
                              p: "1rem",
                            }}
                          >
                            <Stack flexDirection="row">
                              <Typography
                                sx={{
                                  fontSize: "0.9rem",
                                  color: isSelected
                                    ? "var(--orange)"
                                    : "var(--purple-dark)",
                                  fontWeight: "700",
                                }}
                              >
                                Question {idx + 1}
                              </Typography>
                              <Typography
                                ml="1rem"
                                sx={{
                                  color: isSelected
                                    ? "var(--yellow)"
                                    : "var(--grey-light)",
                                  fontSize: "0.9rem",
                                }}
                              >
                                {question.questionType}
                              </Typography>
                            </Stack>
                            <Typography
                              sx={{ color: isSelected ? "white" : "black" }}
                              textAlign="start"
                              mt="1rem"
                              fontSize="0.9rem"
                            >
                              {question.question}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={7}>
                          <Box p={"1rem"}>
                            <Typography sx={{ textAlign: "start" }}>
                              {question.answer}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          sx={{ display: "flex", justifyContent: "end" }}
                        >
                          <Stack rowGap={"2px"}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "2.5rem",
                                width: "2.5rem",
                                borderTopRightRadius: "10px",
                                borderTopLeftRadius: "10px",
                                backgroundColor:
                                  question.selected === true
                                    ? "var(--purple-dark)"
                                    : "#EDECFF",
                                color:
                                  question.selected === true
                                    ? "#EDECFF"
                                    : "var(--purple)",
                              }}
                            >
                              <DoneIcon color="inherit" />
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "2.5rem",
                                width: "2.5rem",
                                borderBottomRightRadius: "10px",
                                borderBottomLeftRadius: "10px",
                                backgroundColor:
                                  question.selected === false
                                    ? "var(--purple-dark)"
                                    : "#EDECFF",
                                color:
                                  question.selected === false
                                    ? "#EDECFF"
                                    : "var(--purple)",
                              }}
                            >
                              <CloseIcon sx={{ color: "inherit" }} />
                            </Box>
                          </Stack>
                        </Grid>
                      </Grid>
                      {idx !== questionsData.length - 1 && <Divider />}
                    </>
                  );
                })}
                <div
                  style={{ position: "absolute", bottom: "-15px", left: "30%" }}
                  className="send-revision"
                >
                  <ul className="send-revision-list">
                    <li>
                      <div className="btn-week">
                        <Link
                          to="#"
                          className="btnweak-bx"
                          onClick={() => setpersonaltskstep1ModalShow(true)}
                        >
                          <span className="btn-title">Send for revision</span>
                        </Link>
                      </div>

                      {/* start send revison popup */}
                      {modalpersonaltskstep1Show && (
                        <SendRevision
                          modalpersonaltskstep1Show={modalpersonaltskstep1Show}
                          setpersonaltskstep1ModalShow={(value) =>
                            setpersonaltskstep1ModalShow(value)
                          }
                        />
                      )}

                      {/* end send revison popup */}
                    </li>
                    <li>
                      <div className="btn-week">
                        <Link to="#" className="btnweak-bx">
                          <span className="btn-title">Complete</span>
                        </Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </Box>
            </div>
          </Container>
        </div>

        {/* footer start */}
        <FooterEducator logo={logo} />
        {/* footer end */}
      </div>

      {/* start modal my student */}

      {/* start game save step1 */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        // {...props}
        show={modalgamesavedstep1Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2 mystudent-games">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>The game is saved</h4>
              <h5>Send the game to my students</h5>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setgamesavedstep1ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="serch-inner-group">
                    <div className="serchfiled-activities">
                      <Form.Control
                        type="text"
                        placeholder="Start typing name or year of study"
                        className="formbox"
                      />
                      <Button type="button" className="seachbtn">
                        <i className="fas fa-search"></i>
                      </Button>
                    </div>
                  </div>

                  <div className="newpupil-list-bx">
                    <Form.Group className="subsform-fild child-tex-fld height-max-flow">
                      <div className="child-boxnew">
                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Aaradhya Rayasam</h5>
                                  <h6>India, Mumbai 1 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Chandrashekhar Kalvakuntla</h5>
                                  <h6>India, Mumbai 3 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>7F Class</h5>
                                  <h6>Hamut Kapit, Kemal Mara and 12 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Additional group</h5>
                                  <h6>Laska Doe, Hamut Kapit and 4 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>8A Class for exams</h5>
                                  <h6>Hamut Kapit, Laska Doe and 8 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Chandrashekhar Kalvakuntla</h5>
                                  <h6>India, Mumbai 3 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </Form.Group>

                <div className="student-btn-savedgame">
                  <div className="btnlogin-main">
                    <button type="submit" className="btnlogin btnweak-bx">
                      Send to selected students
                    </button>
                  </div>
                </div>
                <div className="btnlogin-main">
                  <button type="submit" className="btnlogin btnweak-bx">
                    Later
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end Add a Member Group step2 */}

      {/* end modal my student */}

      {/* start select studen popup */}

      <Selectstudent
        modalAddgroupstep2Show={modalAddgroupstep2Show}
        setAddgroupstep2ModalShow={(value) => setAddgroupstep2ModalShow(value)}
      />

      {/* end select studen popup */}
    </div>
  );
}

export default GameflowNotchecked;
