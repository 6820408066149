import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import {
  getGameCategories,
  getGameTopics,
} from "../../../service/apis/categories";
import { CustomSnackbar, useSnackState } from "../../../utils/useSnackState";
import { getGrades } from "../../../service/apis/games";
import { useQuery } from "@tanstack/react-query";

const GameFlowModal = ({
  open,
  closeModal,
  submitHandler,
  editModal,
  newGame,
  isNewGame,
  modalTitle,
  footerText,
  GameTypes,
  title,
  description,
  isGameFlowEditing,
}) => {
  const [formInput, setFormInput] = useState({
    title: title || "",
    selectedSubject: "",
    topicId: "",
    studentGrade: "",
    description: description || "",
    selectedGameType: GameTypes[0].id,
  });

  console.log("formInput", formInput);
  const [snackProps, snackOpen] = useSnackState();

  const { isLoading: subjectsLoading, data: subjectsData } = useQuery({
    queryKey: ["subjects"],
    queryFn: async () => {
      const subjects = await getGameCategories();
      return subjects.rows;
    },
  });

  const { data: gradesData } = useQuery({
    queryKey: ["grades"],
    queryFn: async () => {
      const grades = await getGrades();
      return grades;
    },
  });

  const { data: topicsData } = useQuery({
    queryKey: ["topics", formInput.selectedSubject],
    queryFn: async () => {
      const topics = await getGameTopics(formInput.selectedSubject);
      return topics.rows;
    },
    enabled: !!formInput.selectedSubject,
  });
  console.log("__topicsData", topicsData);

  useEffect(() => {
    if (!formInput.selectedSubject && subjectsData?.length > 0) {
      setFormInput((state) => ({
        ...state,
        selectedSubject: subjectsData[0]?.id,
      }));
    }
    if (!formInput.topicId && topicsData?.length > 0) {
      setFormInput((state) => ({
        ...state,
        topicId: topicsData[0]?.id,
      }));
    }
    if (!formInput.studentGrade && gradesData?.length > 0) {
      setFormInput((state) => ({
        ...state,
        studentGrade: gradesData[0],
      }));
    }
  }, [subjectsData, topicsData, gradesData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      newGame &&
      (!formInput?.title?.trim() ||
        !formInput.selectedGameType ||
        !formInput.topicId ||
        !formInput.studentGrade ||
        !formInput.selectedSubject ||
        !formInput.description?.trim())
    ) {
      snackOpen("Please fill all fields", "error");
      return;
    }

    if (
      !newGame &&
      (!formInput.title?.trim() ||
        !formInput.selectedSubject ||
        !formInput.topicId ||
        !formInput.description?.trim() ||
        !formInput.studentGrade)
    ) {
      snackOpen("Please fill all fields", "error");
      return;
    }

    let updatedData = {
      ...formInput,
      selectedGameType: GameTypes.find(
        (type) => type.id === formInput.selectedGameType,
      ),

      selectedSubject: subjectsData.find(
        (subject) => subject.id === +formInput.selectedSubject,
      ),
      topicId: topicsData.find((topic) => +topic.id === +formInput.topicId),
    };
    console.log("updatedData", updatedData);

    let dataToSubmit = {
      ...updatedData,
      categoryId: updatedData.selectedSubject,
      templateGameflowId: editModal || 0,
    };

    submitHandler(dataToSubmit);
  };

  return (
    <Modal
      className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
      show={open}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <CustomSnackbar {...snackProps} />
      <div className="login-modal-mainbx groupmbr-step2 mystudent-games">
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {modalTitle ? (
              <h4>{modalTitle}</h4>
            ) : (
              <h4>
                {editModal
                  ? "Edit template gameflow"
                  : isNewGame
                  ? "Create a minigame"
                  : "New gameflow"}
              </h4>
            )}
          </Modal.Title>
          <button type="button" onClick={() => closeModal()} className="close">
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="login-modl-form">
            <div className="loginform-bx">
              <Form.Group className="subsform-fild">
                <div className="newgames-createbx">
                  <div className="frm-gameflw-nw">
                    <Form.Label>Title of the game</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Help your mother"
                      value={formInput.title}
                      onChange={(e) =>
                        setFormInput((state) => ({
                          ...state,
                          title: e.target.value,
                        }))
                      }
                    ></Form.Control>
                  </div>

                  {newGame && (
                    <div className="col-md-12">
                      <Form.Label>Type of the game</Form.Label>
                      <Form.Control
                        onChange={(e) => {
                          setFormInput((prevState) => ({
                            ...prevState,
                            selectedGameType: e.target.value,
                          }));
                        }}
                        value={formInput.selectedGameType}
                        as="select"
                        disabled={isGameFlowEditing}
                      >
                        {GameTypes.map((type) => {
                          return (
                            <option key={type.id} value={type.id}>
                              {type.name}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </div>
                  )}

                  <div className="col-md-12">
                    <Form.Label>Subject</Form.Label>
                    <Form.Control
                      value={formInput.selectedSubject}
                      onChange={(e) =>
                        setFormInput((state) => ({
                          ...state,
                          selectedSubject: e.target.value,
                          topicId: "",
                        }))
                      }
                      as="select"
                      disabled={isGameFlowEditing}
                    >
                      {!subjectsLoading &&
                        subjectsData?.map((subject) => {
                          return (
                            <option key={subject.id} value={subject.id}>
                              {subject.name}
                            </option>
                          );
                        })}
                    </Form.Control>
                  </div>
                  <div className="col-md-12">
                    <Form.Label>Topics</Form.Label>
                    <Form.Control
                      as="select"
                      value={formInput.topicId}
                      disabled={isGameFlowEditing}
                      onChange={(e) =>
                        setFormInput((state) => ({
                          ...state,
                          topicId: e.target.value,
                        }))
                      }
                    >
                      {topicsData?.map((topic) => {
                        return (
                          <option key={topic.id} value={topic.id}>
                            {topic.topicName}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </div>

                  <div className="frm-gameflw-nw">
                    <Form.Label>For children</Form.Label>
                    <Form.Control
                      as="select"
                      value={formInput.studentGrade}
                      disabled={isGameFlowEditing}
                      onChange={(e) =>
                        setFormInput((state) => ({
                          ...state,
                          studentGrade: e.target.value,
                        }))
                      }
                    >
                      {gradesData?.map((val, index) => {
                        return (
                          <option
                            value={val}
                            key={index}
                          >{`${val}th year of study`}</option>
                        );
                      })}
                    </Form.Control>
                  </div>

                  <div className="frm-gameflw-nw">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Add some text that only you will see with any notes, descriptions etc"
                      value={formInput.description}
                      onChange={(e) =>
                        setFormInput((state) => ({
                          ...state,
                          description: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
              </Form.Group>

              <div className="btnlogin-main">
                <p className="btnlogin btnweak-bx" onClick={handleSubmit}>
                  {isNewGame
                    ? "Save and go to question"
                    : footerText
                    ? footerText
                    : "Save and go to flow"}
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default GameFlowModal;
