import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Box, Grid } from "@mui/material";
import { StyledYellowButton } from "../../utils/Buttons";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export function ChartResult({ questionnaireResult, nextScreen }) {
  const [totalPoints, graphPoints, letterPoints] = questionnaireResult;
  const labels = [
    graphPoints.graph1 > 0 ? "P" : "T",
    graphPoints.graph2 > 0 ? "S" : "I",
    graphPoints.graph3 ? "Vi" : "Ve",
    graphPoints.graph4 ? "Sq" : "G",
  ];

  const data = {
    labels,
    datasets: [
      {
        data: [
          Math.abs(graphPoints.graph1),
          Math.abs(graphPoints.graph2),
          Math.abs(graphPoints.graph3),
          Math.abs(graphPoints.graph4),
        ],
        backgroundColor: ["#6A64E6", "#FFC700", "#4FE7E7", "#FF7A00"],
      },
    ],
  };

  const ChartItem = () => {
    return (
      <Box
        sx={{
          backgroundColor: "var(--white)",
          padding: "1rem",
          width: "100%",
          borderRadius: "10px",
        }}
      >
        <Bar
          options={{
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
              title: {
                display: true,
                text: "Result",
              },
            },
            scales: {
              y: {
                min: 0,
                max: 28,
                ticks: {
                  stepSize: 3,
                  callback: function (value, index, ticks) {
                    if (value === 6) return "Neutral";
                    if (value === 15) return "Moderate";
                    if (value === 24) return "Strong";
                    // if ([9, 18, 28].includes(value)) return value;
                    return "";
                  },
                },
                grid: {
                  color: "white",
                },
              },
              x: {
                grid: {
                  color: "white",
                },
              },
            },
          }}
          data={data}
        />
      </Box>
    );
  };
  return (
    <Grid
      container
      sx={{
        position: "relative",
        backgroundColor: "var(--white)",
        borderRadius: "10px",
        maxWidth: "60rem",
        minHeight: "30rem",
        m: "3rem auto 10rem auto",
        p: "1rem",
      }}
    >
      <Grid item md={8} sm={12}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          <ChartItem />
        </Box>
      </Grid>
      <Grid sx={{ position: "relative" }} item md={4} sm={12}>
        <Box
          sx={{
            fontSize: "0.9rem",
            padding: "0.8rem",
            position: "absolute",
            bottom: "-3rem",
            left: "20%",
          }}
        >
          <StyledYellowButton title={"Next"} btnHandler={nextScreen} />
        </Box>
      </Grid>
    </Grid>
  );
}
