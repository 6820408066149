import React, { useEffect, useState } from "react";
import "../home.scss";
import "../profile.scss";
import "../media.scss";
import "../games.scss";
import "../loginModal/login.scss";
import { Container, Row, Button, Form } from "react-bootstrap";
import logo from "../../assets/images/logo.png";
import gamesgalery2 from "../../assets/images/games-galery2.jpg";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Footer from "../layout/Footer/Footer";
import { HeaderDashboard } from "../layout/HeaderDashboard";
import { getGamesByTopicId, getGamesByCatId } from "../../service/apis/games";
import Skeleton from "@mui/material/Skeleton";
import { useAppState } from "../../overmind";
import { LocalStorage } from "../../service/LocalStorage";
import { getGameUrl } from "../../utils/helperFunctions";

function Games() {
  const location = useLocation();

  const [topicId, settopicId] = useState(location?.state?.topicId || 1);
  const [catId, setcatId] = useState(location?.state?.catId || 1);
  const [gamesList, setgamesList] = useState([]);
  const [gamesLoaded, setgamesLoaded] = useState(false);
  const [search, setsearch] = useState("");
  const [filteredGames, setfilteredGames] = useState([]);
  const info = useAppState();
  const [tokenID, setTokenId] = useState(info?.userDetail?.token?.value);

  useEffect(async () => {
    const fetchedToken = await LocalStorage.getAuthToken();
    setTokenId(fetchedToken);
  }, []);

  // console.log(topicId, "topicid");
  // console.log(cate)
  useEffect(() => {
    fetchGames();
    setgamesLoaded(false);
  }, [topicId, catId]);

  useEffect(() => {
    filterGames();
  }, [search, gamesList]);

  const isTopic = location?.state?.isTopic || undefined;
  let name;

  if (isTopic) {
    name = location?.state.topic.name;
  } else {
    name = location?.state?.catName;
  }

  const fetchGames = async () => {
    let res;
    if (isTopic) {
      res = await getGamesByTopicId(topicId);
      console.log(res, "123games");
    } else {
      res = await getGamesByCatId(catId);
      console.log(res, "123cat");
    }
    let gamesarray = [...res.minigamesGameFlows];
    setgamesList(gamesarray);
    setgamesLoaded(true);
  };

  const filterGames = async () => {
    console.log("gamesList", gamesList);
    if (search?.length > 0) {
      let newgamesarray = gamesList.filter(
        (g) =>
          g.title.toLowerCase().includes(search.toLowerCase()) ||
          g.description.toLowerCase().includes(search.toLowerCase()) ||
          g.gameType.toLowerCase().includes(search.toLowerCase()),
      );
      setfilteredGames(newgamesarray);
    } else {
      setfilteredGames(gamesList);
    }
  };
  // console.log("component rendered", search);

  console.log("filteredGameslist", filteredGames);
  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderDashboard />
      {/* end header */}

      <div className="games-dashboard-main dashboard-bgnew blur-bgg">
        <div className="games-topbread">
          <Container>
            <div className="diversity-box">
              <div className="title-box-diver" style={{ paddingBottom: "0" }}>
                {name || "neithedu games"}
              </div>
              <div className="flownew-gamesbx">
                <div
                  className="games-topserch-bar"
                  style={{ paddingTop: "1rem" }}
                >
                  <Form>
                    <Row className="align-center">
                      <div className="col-md-12">
                        <div className="serch-bx-games">
                          <div className="serchfiled-activities">
                            <Form.Control
                              type="text"
                              placeholder="Search"
                              disabled={!gamesLoaded}
                              value={search}
                              onChange={(e) => setsearch(e.target.value)}
                              className="formbox"
                            />
                            <Button type="button" className="seachbtn">
                              {search === null || search === "" ? (
                                <i className="fas fa-search"></i>
                              ) : (
                                <i
                                  onClick={() => setsearch("")}
                                  className="fas fa-times"
                                ></i>
                              )}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </Form>
                </div>
              </div>
              <div className="games-cards-diver">
                <Row>
                  {gamesLoaded ? (
                    filteredGames.length > 0 ? (
                      filteredGames.map((g, i) => (
                        <div className="col-md-4 col-lg-3 one" key={i}>
                          <a
                            href={getGameUrl(g.gameURL, tokenID)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div className="games-cardnew">
                              <div className="cards-games-pic">
                                <div className="game-pic">
                                  <img
                                    src={g.imageURL || gamesgalery2}
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="shape-gamesnew"></div>
                              </div>
                              <div className="games-content-box">
                                <h4>{g.title}</h4>
                                <p>{g.description}</p>
                                <button className="points-btn skybtn">
                                  {g.points || 0} points
                                </button>
                              </div>
                            </div>
                          </a>
                          {/* </Link> */}
                        </div>
                      ))
                    ) : (
                      <div
                        className="Game_Not_found"
                        style={{ marginTop: "150px" }}
                      >
                        <div className="">
                          <div className="list-counter">
                            <h3>No games to show</h3>
                            <span className="shapebx-count"></span>
                          </div>
                        </div>
                      </div>
                    )
                  ) : (
                    [1, 2, 3, 4].map((g, i) => (
                      <div className="col-md-4 col-lg-3 one" key={i}>
                        <Link to={"#"}>
                          {/* <Link
                          to={g.gameURL}
                          className="game-outerbx-poup"
                          onClick={() => {
                            setModalGamesShow(true);
                          }}
                        > */}
                          <div className="games-cardnew">
                            <div className="cards-games-pic">
                              <div className="game-pic">
                                <Skeleton variant="rounded" height={160} />
                              </div>
                              <div className="shape-gamesnew"></div>
                            </div>
                            <div className="games-content-box">
                              <Skeleton
                                variant="text"
                                sx={{ fontSize: "1rem" }}
                              />
                              <Skeleton
                                variant="text"
                                sx={{ fontSize: "1rem" }}
                              />
                            </div>
                          </div>
                        </Link>
                        {/* </Link> */}
                      </div>
                    ))
                  )}
                </Row>
              </div>
            </div>
          </Container>
        </div>

        {/* footer start */}
        <Footer logo={logo} />
        {/* footer end */}
      </div>
    </div>
  );
}

export default Games;
