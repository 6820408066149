import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { getMilestones, getPoints } from "../../service/apis/common";
import { filterArray } from "../../utils/filterArray";
import gift from "../../assets/images/gift.svg";
import { GiftsStepper } from "../dialogs/GiftsStepper";
import { PersonalRewardsGiftsStepper } from "../dialogs/PersonalRewardGiftStepper";
import plus from "../../assets/images/plus.svg";
import { Link, useHistory, useLocation } from "react-router-dom";
import count1 from "../../assets/images/count1.svg";
import count2 from "../../assets/images/count2.svg";
import Loading from "../Loader";
export const MobileViewHeader = () => {
  const [isLoading, setisLoading] = useState(false);

  const [isDataFetched, setIsDataFetched] = useState(false);
  const [rewardPoints, setRewardPoints] = useState("");
  const [communityArray, setCommunityArray] = useState([]);
  const [personalArray, setPersonalArray] = useState([]);
  const [openGifts, setopenGifts] = useState(false);
  const [personalOpenGifts, setPersonalOpenGifts] = useState(false);
  const getPointsData = async () => {
    try {
      setisLoading(true);
      const response = await getPoints();

      setRewardPoints(response);
      setisLoading(false);
    } catch (error) {}
  };

  const rewardPointsData = useMemo(() => rewardPoints, [rewardPoints]);

  const getMilestonesData = async () => {
    try {
      const response = await getMilestones();

      var communityArray1 = filterArray(response, 1);
      var persoanlArray1 = filterArray(response, 2);
      setPersonalArray(persoanlArray1);
      setCommunityArray(communityArray1);
    } catch (error) {}
  };

  const handleOpenGifts = (e) => {
    e.preventDefault();
    setopenGifts(true);
  };
  const handlePersonalGifts = (e) => {
    e.preventDefault();
    setPersonalOpenGifts(true);
  };

  useEffect(() => {
    getMilestonesData();
    if (!isDataFetched) {
      getPointsData();
      setIsDataFetched(true);
    }
  }, []);

  return (
    <div>
      {openGifts && (
        <GiftsStepper
          communityArray={communityArray}
          // rewardPoints.totalPointsForAllStudents
          communityRewardPoints={rewardPointsData.totalPointsForAllStudents}
          //   sliderData={sliderData}
          closeHandler={() => setopenGifts(false)}
        />
      )}
      {personalOpenGifts && (
        <PersonalRewardsGiftsStepper
          personalArray={personalArray}
          personalRewardPoints={rewardPointsData.totalPointsForLoggedInUser}
          //   sliderData={sliderData}
          closeHandler={() => setPersonalOpenGifts(false)}
        />
      )}{" "}
      <div className="filter-bartop">
        <div className="filterbar-container">
          <div className="filterbar-list">
            <ul className="header-btn-top profile-menubar">
              <li className="btn-bx-get mobile-show-only">
                <div className="count-bar">
                  {isLoading ? (
                    <Loading />
                  ) : (
                    rewardPointsData.totalPointsForLoggedInUser
                  )}
                </div>
              </li>
              {/* =============== */}
              <li className="btn-bx-get mobile-show-only">
                <div onClick={handleOpenGifts} className="gift-ico">
                  <img alt="" src={gift} className="img-fluid" />
                </div>
              </li>

              <li className="btn-bx-get mobile-show-only">
                <div className="count-bar">
                  {isLoading ? (
                    <Loading />
                  ) : (
                    rewardPointsData.totalPointsForAllStudents
                  )}
                </div>
              </li>
              {/* ========== */}
              <li className="btn-bx-get mobile-show-only">
                <div onClick={handlePersonalGifts} className="gift-ico">
                  <img alt="" src={gift} className="img-fluid" />
                </div>
              </li>

              {/* <li className="btn-bx-get mobile-show-only">
            <div className="count-bar">0</div>
          </li> */}
              <li className="btn-bx-get mobile-show-only">
                <Link to="#" className="gift-ico">
                  {" "}
                  <img src={plus} className="img-fluid" />
                </Link>
              </li>
              {/* <li className="btn-bx-get mobile-show-only">
            <Link to="#" className="gift-ico">
              {" "}
              <img src={gift} className="img-fluid" />
            </Link>
          </li> */}
              <li className="btn-bx-get mobile-show-only">
                <Link to="#" className="gift-ico">
                  {" "}
                  <img src={count1} className="img-fluid" />
                </Link>
              </li>
              <li className="btn-bx-get mobile-show-only">
                <Link to="#" className="gift-ico">
                  {" "}
                  <img src={count2} className="img-fluid" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
