import React from "react";
import { Link } from "react-router-dom";

const ActivityNotification = ({ activity, linkToRedirect, isGroup }) => {
  let extractedObject = {};
  console.log("activity", activity);

  Object.entries(activity).forEach(([key, value]) => {
    if (key === "totalChecked" && value !== 0) {
      extractedObject[key] = value;
    }
    if (key === "totalCompleted" && value !== 0) {
      extractedObject[key] = value;
    }
    if (key === "totalInProgress" && value !== 0) {
      extractedObject[key] = value;
    }
    if (key === "totalInRevision" && value !== 0) {
      extractedObject[key] = value;
    }
    if (key === "totalLate" && value !== 0) {
      extractedObject[key] = value;
    }
    if (key === "totalNew" && value !== 0) {
      extractedObject[key] = value;
    }
    if (key === "totalUnchecked" && value !== 0) {
      extractedObject[key] = value;
    }
  });

  // select 3 lowest values from object and display them as status in assignment card

  extractedObject = Object.entries(extractedObject)
    .sort(([, a], [, b]) => a - b)
    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

  let statusToShow = TastStatus[activity.status];
  if (isGroup) {
    if (
      ((((activity.totalChecked === 0 && activity.totalCompleted) === 0 &&
        activity.totalInProgress) === 0 && activity.totalInRevision) === 0 &&
        activity.totalLate) === 0
    ) {
      statusToShow = "New";
    } else {
      statusToShow = Object.keys(extractedObject)
        .slice(0, 3)
        .map((key) => TastStatus[key])
        .join(", ");
    }

    // totalChecked;
    // totalCompleted;
    // totalInProgress;
    // totalInRevision;
    // totalLate;
    // totalNew;
    // totalUnchecked;

    // go over these properties in object and select 3 that are lowest number and display them as status in assignment card
  }
  return (
    <div
      className={`lesson-hovrbx ${
        +activity?.status === 7 && "completed-activity-notification"
      }`}
    >
      <Link to={linkToRedirect} className="newlesson-card">
        <div className="sesson-listmain">
          <div className="live-lesson-main">
            <div className="custom-row">
              <div className="lesson-leftbx">
                <span className="medm-tex purple-clr">
                  {activity?.activityTypeName}
                  {activity?.activityTypeName !== "My Gameflows" &&
                    activity?.activityTypeName !== "Personal Task" && (
                      <span> : </span>
                    )}

                  {activity?.activityTypeName === "My Materials" && (
                    <>
                      {activity?.noOfAudios > 0 && (
                        <span>
                          {activity?.noOfAudios} audio
                          {activity?.noOfAudios > 1 ? "s" : ""},{" "}
                        </span>
                      )}
                      {activity?.noOfImages > 0 && (
                        <span>
                          {activity?.noOfImages} image
                          {activity?.noOfImages > 1 ? "s" : ""},{" "}
                        </span>
                      )}
                      {activity?.noOfPdf > 0 && (
                        <span>
                          {activity?.noOfPdf} pdf
                          {activity?.noOfPdf > 1 ? "s" : ""},{" "}
                        </span>
                      )}
                      {activity?.noOfVideos > 0 && (
                        <span>
                          {activity?.noOfVideos} video
                          {activity?.noOfVideos > 1 ? "s" : ""},{" "}
                        </span>
                      )}
                    </>
                  )}
                  {activity?.activityTypeName === "My Materials" &&
                    activity?.noOfAudios === 0 &&
                    activity?.noOfImages === 0 &&
                    activity?.noOfPdf === 0 &&
                    activity?.noOfVideos === 0 &&
                    "No Material"}
                  {activity.activityTypeName === "Neithedu Games" && (
                    <span>{activity.gameTypeName} </span>
                  )}
                  {activity.activityTypeName === "My Games" && (
                    <span>{activity.gameTypeName} </span>
                  )}
                  {activity.activityTypeName === "My Gameflows" && (
                    <span>{activity.gameTypeName} </span>
                  )}
                </span>
              </div>
              <div className="lesson-rightbx">
                <span className="medm-tex date-bx">
                  {activity?.creationDate}
                </span>
              </div>
            </div>
          </div>
          <div className="title-main">{activity.title}</div>
          <div className="lesson-list-bottom">
            <div className="custom-row">
              <div className="left-boxleson">
                <ul className="list-point-lesson">
                  {+activity.activityTypeId !== 5 &&
                    +activity.activityTypeId !== 6 && (
                      <li>
                        <span className="medm-tex orng-clr">
                          {activity.points} points
                        </span>
                      </li>
                    )}
                  <li>
                    {+activity?.status === 7 ? (
                      <span className="medm-tex orng-clr">
                        {/* time in the format of DD-MM-YYYY */}
                        Done on {activity?.completionDate}
                      </span>
                    ) : (
                      <span className="medm-tex orng-clr">
                        Due{" "}
                        {activity?.activityTypeId === 8
                          ? activity.lessonDate
                          : isGroup
                          ? activity.deadline
                          : activity?.dueDate}
                      </span>
                    )}
                  </li>
                  <li>
                    <span className="medm-tex sky-clr">{statusToShow}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ActivityNotification;
const TastStatus = {
  0: "Testing",
  1: "New",
  2: "InProgress",
  3: "Done",
  4: "Checking",
  5: "Checked",
  6: "InRevision",
  7: "Completed",
  8: "Cancelled",
  9: "MissedDeadline",
  10: "CancelledByStudent",
  11: "CancelledByTutor",
  12: "AboutToStart",
  13: "Pending",
  14: "Late",
};
