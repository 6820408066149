import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import gamesgalery1 from "../../assets/images/games-galery1.jpg";
import { useQuery } from "@tanstack/react-query";
import { getActiveGames } from "../../service/apis/student";
import Slider from "react-slick";
import { getGameUrl } from "../../utils/helperFunctions";
import { LocalStorage } from "../../service/LocalStorage";
import { useAppState } from "../../overmind";

const ActiveGames = () => {
  // getActiveGames
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const userId = currentUser?.applicationUser?.id;

  const { data: activeGames } = useQuery({
    queryKey: ["activeGames", userId],
    queryFn: async () => await getActiveGames(userId),
    enabled: !!userId,
  });
  console.log(activeGames, "activeGames");
  const allGames = activeGames?.activeGameFlows
    ? [...activeGames?.activeGameFlows, ...activeGames?.activeGames]
    : [];
  console.log(allGames, "allGames");
  //   shuffle allGames
  const shuffle = (array) => {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  };
  allGames && shuffle(allGames);
  const info = useAppState();
  const [tokenID, setTokenId] = useState(info?.userDetail?.token?.value);

  useEffect(async () => {
    const fetchedToken = await LocalStorage.getAuthToken();
    setTokenId(fetchedToken);
  }, []);
  return (
    <>
      {allGames.length > 0 && (
        <Grid item xs={6}>
          <Typography
            textAlign={"start"}
            mb="1rem"
            sx={{ fontSize: "1.2rem", fontWeight: "700", color: "white" }}
          >
            Active games
          </Typography>

          <Slider {...settings} className="active-games-carousel">
            {console.log(allGames, "allGames")}
            {allGames?.map((game) => (
              <a
                className="games-cards-diver"
                key={game?.id}
                href={getGameUrl(game?.gameURL, tokenID)}
                target="_blank"
                rel="noreferrer"
              >
                <div className="game-outerbx-poup" style={{ height: "25rem" }}>
                  <div className="games-cardnew" style={{ height: "100%" }}>
                    <div className="cards-games-pic">
                      <div className="game-pic">
                        <img
                          src={
                            game?.imageURL && game.imageURL !== "null"
                              ? game.imageURL
                              : gamesgalery1
                          }
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="shape-gamesnew"></div>
                    </div>
                    <div className="games-content-box">
                      <h4>{game?.title}</h4>
                      <p>{game?.description}</p>
                      <button className="points-btn skybtn">
                        {game?.totalPoints} points
                      </button>
                    </div>
                  </div>
                </div>
              </a>
            ))}
          </Slider>
        </Grid>
      )}
    </>
  );
};

export default ActiveGames;

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
};
