import { useEffect, useState } from "react";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "../../home.scss";
import "../../profile.scss";
import "../../media.scss";
import "../../games.scss";
import "../../loginModal/login.scss";
import "../../messages/messages.scss";
import "../../myactivities.scss";
import "../../gamestabs.scss";
import "../../gamesflowphotos.scss";
import "../../selectedgames.scss";
import "../../questionone.scss";
import "../../creatingmaterial.scss";
import "../../Currentlesson.scss";
import { Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import logo from "../../../assets/images/logo.png";
import backorange from "../../../assets/images/backorange.svg";

import { Link } from "react-router-dom";
import FooterEducator from "../../layout/Footer/FooterEducator";
import { HeaderDashboard } from "../../layout/HeaderDashboard";
import { Box, Typography } from "@mui/material";
import { DialogBox } from "../../utils/DialogBox";
import { StyledPurpleButton, StyledYellowButton } from "../../utils/Buttons";
import { useParams } from "react-router-dom";
import { getSingalActivities } from "../../../service/apis/activities";
import { cancelLesson, updateLessonStatus } from "../../../service/apis/lesson";
import {
  addOffsetToTime,
  combineDateTime,
  convert12to24HrFormat,
  convertDateToObject,
  convertmsToDHM,
} from "../../../utils/helperFunctions";
import { cancelLessonDialogs } from "../../LiveLessonDetail";
import CancelDialog from "./CancelDialog";
import ReasonDialog from "./ReasonDialog";
import ConfirmCancel from "./ConfirmCancel";
import RefundDialog from "./RefundDialog";
import RefundApllicationSentDialog from "./RefundApllicationSentDialog";

const dialogs = {
  CONFIRMED: "CONFIRMED",
  REFUND: "REFUND",
  REFUND_APPLIED: "REFUND_APPLIED",
};

function AcceptLiveLesson() {
  const [lessonDetails, setLessonDetails] = useState(null);

  const [timeToAcceptAlreadyPassed, setTimeToAcceptAlreadyPassed] =
    useState(false);
  const [timeToTheLesson, setTimeToTheLesson] = useState(0);

  const [timerValues, setTimerValues] = useState({
    remainingDays: 0,
    remainingHours: 0,
    remainingMinutes: 0,
  });

  const [modalType, setModalType] = useState(null);

  const params = useParams();

  const { activityTypeId, activityId } = params;

  const getActivityDetails = async () => {
    try {
      const response = await getSingalActivities(
        activityTypeId,
        activityId,
        false,
      );
      response.creationTime = convert12to24HrFormat(response.creationTime);

      //TODO: Remove these
      // response.startTime = "12:25:00 PM";
      // response.lessonDate = "02-10-2023";
      // response.status = "AboutToStart";

      setLessonDetails(response);

      const _currentDateTime = new Date();

      let createdAt = combineDateTime(
        response.creationDate,
        response.creationTime,
      );
      // creation time is in utc time, change it to current timezone
      createdAt = addOffsetToTime(createdAt);

      const lessonStartAt = combineDateTime(
        response.lessonDate,
        response.startTime,
      );
      // last time of acceptance is night of the day before the lesson
      const _lastTimeOfAcceptance = new Date(
        convertDateToObject(response.lessonDate).getTime() - 1,
      );
      const _isLessonCreatedOnSameDay =
        _currentDateTime.getDate() === lessonStartAt.getDate();

      // first, lets check if the last time to accept has passed, means lesson day has started
      const _isLessonDayArrived = _currentDateTime > _lastTimeOfAcceptance;

      // now, check if 3 days has passed since the lesson was created

      const is3DaysPassed =
        _currentDateTime - createdAt > 3 * 24 * 60 * 60 * 1000;

      // if lesson is created on the same day, we need to check if 4 hours has passed since the lesson was created, status is Pending
      const timeBetweenNowAndLessonStart = lessonStartAt - _currentDateTime;
      setTimeToTheLesson(timeBetweenNowAndLessonStart);
      console.log(
        "___timeBetweenNowAndLessonStart",
        timeBetweenNowAndLessonStart,
      );
      console.log("___currentDateTime", _currentDateTime);

      const timeBetweenNowAndNightBeforeLessonStart =
        _lastTimeOfAcceptance - _currentDateTime;

      const timePassedSinceCreation = _currentDateTime - createdAt;

      const daysPassedSinceCreation = Math.floor(
        timePassedSinceCreation / (1000 * 60 * 60 * 24),
      );

      // if day beore lesson start hasnt finished and 3 days has not passed since the lesson was created, and response.status is Pending, then the
      // student can accept the lesson

      // New: if lesson isnt created on same day, and 3 days havent passed, and day of lesson isnt reached

      if (
        !_isLessonCreatedOnSameDay &&
        !is3DaysPassed &&
        !_isLessonDayArrived &&
        response?.status === "Pending"
      ) {
        console.log("__if block");
        setTimeToAcceptAlreadyPassed(false);
        let timeLeftToAccept;
        // if no day has passed since creation, and 3 or more days are remaining until night before lesson start, then student has 3 days - time passed since creation to accept the lesson
        if (
          daysPassedSinceCreation === 0 &&
          timeBetweenNowAndNightBeforeLessonStart > 3 * 24 * 60 * 60 * 1000
        ) {
          timeLeftToAccept = 3 * 24 * 60 * 60 * 1000 - timePassedSinceCreation;
        }
        // if no day has passed since creation and less than 3 days are remaining until night before lesson start,
        // then student has remaining time until night before lesson start to accept the lesson
        else if (
          daysPassedSinceCreation === 0 &&
          timeBetweenNowAndNightBeforeLessonStart < 3 * 24 * 60 * 60 * 1000
        ) {
          timeLeftToAccept = timeBetweenNowAndNightBeforeLessonStart;
        }
        // if 1 day has passed since creation, and 2 or more days are remaining until night before lesson start,
        // then student has 2 days to accept the lesson
        else if (
          daysPassedSinceCreation === 1 &&
          timeBetweenNowAndNightBeforeLessonStart > 2 * 24 * 60 * 60 * 1000
        ) {
          timeLeftToAccept = 2 * 24 * 60 * 60 * 1000 - timePassedSinceCreation;
        }
        // if 1 day has passed since creation, and less than 2 days are remaining until night before lesson start, then student has
        // remaining time until night before lesson start to accept the lesson
        else if (
          daysPassedSinceCreation === 1 &&
          timeBetweenNowAndNightBeforeLessonStart < 2 * 24 * 60 * 60 * 1000
        ) {
          timeLeftToAccept = timeBetweenNowAndNightBeforeLessonStart;
        }
        // if 2 days has passed since creation, and 1 or more days are remaining until night before lesson start, then student has 1 day to accept the lesson
        else if (
          daysPassedSinceCreation === 2 &&
          timeBetweenNowAndNightBeforeLessonStart > 1 * 24 * 60 * 60 * 1000
        ) {
          timeLeftToAccept = 1 * 24 * 60 * 60 * 1000 - timePassedSinceCreation;
        }
        // if 2 days has passed since creation, and less than 1 day is remaining until night before lesson start, then student has remaining time
        // until night before lesson start to accept the lesson
        else if (
          daysPassedSinceCreation === 2 &&
          timeBetweenNowAndNightBeforeLessonStart < 1 * 24 * 60 * 60 * 1000
        ) {
          timeLeftToAccept = timeBetweenNowAndNightBeforeLessonStart;
        }

        // if 3 days has passed since creation, then student has no time left to accept the lesson
        else if (daysPassedSinceCreation > 3) {
          timeLeftToAccept = 0;
        }

        console.log("timeLeftToAccept", timeLeftToAccept);

        const { days, hours, minutes } = convertmsToDHM(timeLeftToAccept);

        setTimerValues({
          remainingDays: days,
          remainingHours: hours,
          remainingMinutes: minutes,
        });
      } else if (
        _isLessonCreatedOnSameDay &&
        response?.status === "Pending" &&
        timeBetweenNowAndLessonStart > 4 * 60 * 60 * 1000
      ) {
        console.log("__else_if block");
        // if lesson created on the same day, and more than 4 hours remaining until lesson start,
        //  student can accept it within startTime - 4 hours
        setTimeToAcceptAlreadyPassed(false);
        const timeRemaining = timeBetweenNowAndLessonStart - 4 * 60 * 60 * 1000;

        const { days, hours, minutes } = convertmsToDHM(timeRemaining);

        setTimerValues({
          remainingDays: days,
          remainingHours: hours,
          remainingMinutes: minutes,
        });
      } else if (lessonDetails?.status === "MissedDeadline") {
        console.log("__else_if block");
        setTimeToAcceptAlreadyPassed(true);
        setTimerValues({
          remainingDays: 0,
          remainingHours: 0,
          remainingMinutes: 0,
        });
      } else {
        console.log("__else block");

        const { days, hours, minutes } = convertmsToDHM(
          timeBetweenNowAndLessonStart,
        );
        setTimerValues({
          remainingDays: timeBetweenNowAndLessonStart > 0 ? days : 0,
          remainingHours: timeBetweenNowAndLessonStart > 0 ? hours : 0,
          remainingMinutes: timeBetweenNowAndLessonStart > 0 ? minutes : 0,
        });
      }

      console.log("Response : ", response);
    } catch (err) {
      console.log("Error : ", err);
    }
  };

  useEffect(() => {
    getActivityDetails();
  }, []);

  /* end edit lession */

  const ConfirmedDialog = () => {
    return (
      <DialogBox isOpen={true} close={() => {}}>
        <Box sx={{ textAlign: "center", m: "2rem" }}>
          <Typography
            sx={{
              color: "var(--purple-dark)",
              fontWeight: "800",
              fontSize: "1.3rem",
              mb: "1rem",
            }}
          >
            Live lesson is confirmed{" "}
          </Typography>
          <StyledPurpleButton
            btnHandler={() => {
              setModalType(null);
            }}
            title={"Thanks"}
          />
        </Box>
      </DialogBox>
    );
  };

  const confirmLessonHandler = async () => {
    try {
      const response = await updateLessonStatus(lessonDetails?.lessonId, 1, 0);
      console.log("Response : ", response);
      getActivityDetails();
      setModalType(dialogs.CONFIRMED);
    } catch (err) {
      console.log("Error : ", err);
    }
  };

  let headerText;
  if (lessonDetails?.status === "MissedDeadline") {
    headerText = "Lesson is cancelled because of no response";
  } else if (
    lessonDetails?.status === "Pending" &&
    !timeToAcceptAlreadyPassed
  ) {
    headerText = "You must accept lesson in";
  } else if (lessonDetails?.status === "New") {
    headerText = "Lesson will start in";
  } else if (lessonDetails?.status === "InProgress") {
    headerText = "Lesson in progress";
  }

  const checkIfCancellable = () => {
    // if status is Pending, lesson can be cancelled until the last time of acceptance
    // if status is New, lesson can be cancelled 24 hours before the lesson start
    if (!timeToAcceptAlreadyPassed && lessonDetails?.status === "Pending")
      return true;
    else if (lessonDetails?.status === "New") {
      // if status is nww, lesson can be cancelled 24 hours before the lesson start
      const _currentDateTime = new Date();
      const lessonStartAt = combineDateTime(
        lessonDetails.lessonDate,
        lessonDetails.startTime,
      );
      const timeBetweenNowAndLessonStart = lessonStartAt - _currentDateTime;
      if (timeBetweenNowAndLessonStart > 24 * 60 * 60 * 1000) return true;
      else return false;
    } else return false;
  };

  const history = useHistory();

  const cancelFn = async (cancelReasonId, otherCancelReason) => {
    try {
      const res = await cancelLesson(
        lessonDetails.lessonId,
        cancelReasonId,
        otherCancelReason,
      );
      console.log("res", res);
      await updateLessonStatus(lessonDetails.lessonId, 10, 0);
      setModalType(cancelLessonDialogs.CANCELCONFIRM);

      getActivityDetails();
    } catch (err) {
      console.log("err", err);
    }
  };

  const returnMoneyHandler = async () => {
    // call the refund api here
    setModalType(dialogs.REFUND_APPLIED);
  };

  const getMessageWithBtnScreen = () => {
    if (
      lessonDetails?.status === "MissedDeadline" ||
      lessonDetails?.status === "Cancelled by Student" ||
      lessonDetails?.status === "Cancelled by Tutor"
    ) {
      return (
        <div className="missedDeadlineBox">
          <div className="inner-box">
            <h1>You can discuss new lesson with the educator</h1>
            <Link to="/chat" className="btn">
              <div className="btn-buy">
                <div className="btn-week">
                  <a href="#" className="btnweak-bx">
                    <span className="btn-title">Chat with tutor</span>
                  </a>
                </div>
              </div>
            </Link>
          </div>
        </div>
      );
    } else if (lessonDetails.status === "AboutToStart") {
      // 10 mins passed since lesson start time
      return (
        <div className="missedDeadlineBox">
          <div className="inner-box">
            <h1>Waiting for tutor to start the lesson</h1>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "1rem",
              }}
            >
              <Link to="/games" className="btn">
                <div className="btn-buy">
                  <div className="btn-week">
                    <a href="#" className="btnweak-bx">
                      <span className="btn-title">Chat with tutor</span>
                    </a>
                  </div>
                </div>
              </Link>
              {/* show this button if 10 mins are passed since start time */}
              {timeToTheLesson + 10 * 60 * 1000 < 0 && (
                <div
                  onClick={() => {
                    setModalType(dialogs.REFUND);
                  }}
                  className="btn"
                >
                  <div className="btn-buy">
                    <div className="btn-week">
                      <a href="#" className="btnweak-bx">
                        <span className="btn-title">Tutor hasn't arrived</span>
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  };
  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderDashboard />
      {modalType === cancelLessonDialogs.CANCEL && (
        <CancelDialog
          setModalType={setModalType}
          status={lessonDetails?.status}
          role="student"
        />
      )}
      {modalType === cancelLessonDialogs.REASON && (
        <ReasonDialog
          setModalType={setModalType}
          cancelLessonHandler={cancelFn}
        />
      )}
      {modalType === cancelLessonDialogs.CANCELCONFIRM && (
        <ConfirmCancel setModalType={setModalType} />
      )}
      {modalType === dialogs.CONFIRMED && <ConfirmedDialog />}
      {modalType === dialogs.REFUND && (
        <RefundDialog
          returnMoneyHandler={returnMoneyHandler}
          setModalType={setModalType}
        />
      )}

      {modalType === dialogs.REFUND_APPLIED && (
        <RefundApllicationSentDialog setModalType={setModalType} />
      )}

      <div className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs">
        <div className="cureent-lession-bx completelession-main">
          <div className="current-lesson-main ">
            <Container>
              <div className="current-lesson-contain">
                <div
                  className="backbtn cursor-pointer"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <div className="backbtnn-icnn">
                    <img src={backorange} alt="" />
                  </div>
                </div>
                <div className="current-lesson-topbr">
                  <div className="medm-tex orng-clr">
                    {lessonDetails?.lessonMode}
                  </div>
                  <h1>{lessonDetails?.title}</h1>
                  <div className="lesson-status-list">
                    <ul className="lesson-listing-curnt">
                      <li>
                        <div className="lesson-status-detail">
                          <span className="medm-tex orng-clr onelist">
                            Time:{" "}
                          </span>
                          <span className="medm-tex orng-clr twolist">
                            {lessonDetails?.startTime?.substring(
                              0,
                              lessonDetails?.startTime?.length - 6,
                            )}
                            {lessonDetails?.startTime?.substring(
                              lessonDetails?.startTime?.length - 2,
                            )}{" "}
                            {lessonDetails?.lessonDate}
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="lesson-status-detail">
                          <span className="medm-tex orng-clr onelist">
                            Status:{" "}
                          </span>
                          <span className="medm-tex orng-clr twolist">
                            {lessonDetails?.status}
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="lesson-status-detail">
                          <span className="medm-tex orng-clr onelist">
                            Points:{" "}
                          </span>
                          <span className="medm-tex orng-clr twolist">
                            {lessonDetails?.givenPoints}
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
        <div className="lession-time-card">
          <Container>
            {lessonDetails?.status === "MissedDeadline" ||
            lessonDetails?.status === "Cancelled by Student" ||
            lessonDetails?.status === "Cancelled by Tutor" ||
            lessonDetails?.status === "AboutToStart" ? (
              getMessageWithBtnScreen()
            ) : (
              <div className="lession-card-timebx">
                <div className="lessontime-bottom">
                  <div className="lessonnew-cardbx">
                    <div className="newlesson-top">
                      <div className="bnewleson-heading">{headerText}</div>
                    </div>
                    <div className="newlesson-clock">
                      <ul className="newlesson-listclk">
                        <li>
                          <div className="clock-time">
                            <div className="timenum">
                              {timerValues.remainingDays}
                            </div>
                            <div className="time-days">days</div>
                          </div>
                        </li>
                        <li>
                          <div className="clock-time">
                            <div className="timenum">
                              {timerValues.remainingHours}
                            </div>
                            <div className="time-days">hours</div>
                          </div>
                        </li>
                        <li>
                          <div className="clock-time">
                            <div className="timenum">
                              {timerValues.remainingMinutes}
                            </div>
                            <div className="time-days">minutes</div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="btn-list-lesson">
                      <ul className="btn-listing-lesson">
                        {!timeToAcceptAlreadyPassed &&
                        lessonDetails?.status === "Pending" ? (
                          <li>
                            <div className="btn-buy">
                              <div className="btn-week">
                                <p
                                  className="btnweak-bx"
                                  style={{
                                    backgroundColor:
                                      timeToAcceptAlreadyPassed && "#ceccc7",
                                    cursor: "pointer",
                                  }}
                                >
                                  <span
                                    className="btn-title"
                                    onClick={() => confirmLessonHandler()}
                                  >
                                    Confirm Lesson
                                  </span>
                                </p>
                              </div>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="btn-buy">
                              <div className="btn-week">
                                <p className="btnweak-bx">
                                  <span className="btn-title">
                                    Chat with educator
                                  </span>
                                </p>
                              </div>
                            </div>
                          </li>
                        )}
                        {/* if status is "Tutor is Waiting" then Join Lesson button will show, otherwise cancel lesson will show */}
                        {lessonDetails?.status === "Tutor is Waiting" ||
                        lessonDetails?.status === "InProgress" ? (
                          <li className="whitebtn-tx">
                            <div
                              className="btn-buy"
                              onClick={async () => {
                                try {
                                  // call the api to change status to InProgress
                                  updateLessonStatus(
                                    lessonDetails?.lessonId,
                                    2,
                                    0,
                                  );
                                  var user = localStorage.getItem("user");
                                  var userInfo = JSON.parse(user);
                                  let username =
                                    userInfo?.applicationUser?.name;
                                  let link = `/test-lesson?roomId=${lessonDetails.lessonId}&name=${username}&isTeacher=false&activityId=${lessonDetails.id}`;
                                  history.push(link);
                                } catch (err) {}
                              }}
                            >
                              <div className="btn-week">
                                <p
                                  style={{
                                    cursor: "pointer",
                                    marginBottom: "0",
                                  }}
                                  className="btnweak-bx"
                                >
                                  <span className="btn-title">Join Now</span>
                                </p>
                              </div>
                            </div>
                          </li>
                        ) : (
                          <li className="whitebtn-tx">
                            <div
                              className="btn-buy"
                              onClick={() => {
                                if (!checkIfCancellable()) return;
                                setModalType(cancelLessonDialogs.CANCEL);
                              }}
                            >
                              <div className="btn-week">
                                <p
                                  style={{
                                    backgroundColor:
                                      !checkIfCancellable() && "#ceccc7",
                                    cursor: checkIfCancellable() && "pointer",
                                    marginBottom: "0",
                                  }}
                                  className="btnweak-bx"
                                >
                                  <span className="btn-title">
                                    Cancel lesson
                                  </span>
                                </p>
                              </div>
                            </div>
                          </li>
                        )}
                      </ul>
                    </div>
                    <div className="newlesson-shape"></div>
                  </div>
                </div>
              </div>
            )}
          </Container>
        </div>
        <FooterEducator logo={logo} />
      </div>
    </div>
  );
}

export default AcceptLiveLesson;
