import React, { useState } from "react";
import "../home.scss";
import "../teachers.scss";
import "../blog/blog.scss";
import "../contact.scss";
import "../media.scss";
import { Container, Row, Form, Modal } from "react-bootstrap";
import logo from "../../assets/images/logo.png";

import girl6 from "../../assets/images/girl6.png";

import { HeaderDashboard } from "../layout/HeaderDashboard";
import { useFormik } from "formik";
import { postDate } from "../../service/AllApi";
import { Helmet } from "react-helmet";
import { HomeFooter } from "../layout/HomeFooter";

function Contact() {
  const [confirmDialog, setconfirmDialog] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
      phone: "",
    },
    validate: (values) => {
      const errors = {};

      if (!values.name && values.name === "") errors.name = "Required";
      if (!values.phone && values.phone === "") errors.phone = "Required";
      if (!values.email && values.email === "") errors.email = "Required";
      if (!values.message && values.message === "") errors.message = "Required";
      return errors;
    },
    onSubmit: async (values) => {
      try {
        await postDate("ContactUs", {
          name: values.name,
          email: values.email,
          phone: values.phone,
          message: values.message,
        });
        formik.resetForm();
        setconfirmDialog(true);
      } catch (err) {}
    },
  });

  return (
    <div className="home-wrapper">
      <Helmet>
        <title>Contact Us - Best App for Children's Education, Neithedu</title>
        <meta
          name="description"
          content="If you have any question regarding our apps, we are here for all your queries and comments. For more details, just drop us a line!"
        />
      </Helmet>{" "}
      <div className="inner-page-topbar">
        <div className="custome-container">
          <HeaderDashboard />
        </div>
      </div>
      <div className="testimaonial-section contact-page">
        <div className="contact-main">
          <Container>
            <div className="contact-bxx">
              <div className="heading-top">
                <h4 className="title-medium">How can we help you?</h4>
              </div>
              <div className="subtitle">
                <p>
                  We’re here for all your queries and comments. For more details
                  just drop us a line!
                </p>
              </div>

              <div className="form-contact">
                <div className="girl-sidepic">
                  <img src={girl6} className="img-fluid" />
                </div>
                <div className="contact-detail">
                  <Form className="contfrm">
                    <Row>
                      <div className="col-md-6 one">
                        <Form.Group className="subsform-fild">
                          <Form.Control
                            className={
                              formik.touched.name && formik.errors.name
                                ? "error-box"
                                : ""
                            }
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            type="text"
                            onBlur={formik.handleBlur}
                            placeholder="Name"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6 one">
                        <Form.Group className="subsform-fild">
                          <Form.Control
                            className={
                              formik.touched.phone && formik.errors.phone
                                ? "error-box"
                                : ""
                            }
                            name="phone"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            type="text"
                            onBlur={formik.handleBlur}
                            placeholder="Phone Number"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-12 one">
                        <Form.Group className="subsform-fild">
                          <Form.Control
                            className={
                              formik.touched.email && formik.errors.email
                                ? "error-box"
                                : ""
                            }
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            type="email"
                            onBlur={formik.handleBlur}
                            placeholder="Email"
                          />
                        </Form.Group>
                      </div>

                      <div className="col-md-12 one">
                        <Form.Group className="subsform-fild">
                          <Form.Control
                            className={
                              formik.touched.message && formik.errors.message
                                ? "error-box"
                                : ""
                            }
                            name="message"
                            onChange={formik.handleChange}
                            value={formik.values.message}
                            as="textarea"
                            onBlur={formik.handleBlur}
                            placeholder="Your message"
                          />
                        </Form.Group>
                      </div>
                    </Row>

                    <div className="send-messsage">
                      <div className="btn-buy tutor-becm">
                        <div className="btn-week">
                          <button
                            onClick={() => formik.handleSubmit()}
                            type="button"
                            className="btnweak-bx"
                          >
                            <span className="btn-title">Send a message</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </Container>
        </div>

        <HomeFooter />
      </div>
    </div>
  );
}

export default Contact;
