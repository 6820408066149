import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "../../home.scss";
import "../../profile.scss";
import "../../media.scss";
import "../../games.scss";
import "../../loginModal/login.scss";
import { Form, Modal, Row } from "react-bootstrap";
import flag2 from "../../../assets/images/flag2.svg";
import { postDate } from "../../../service/AllApi";
import axios from "axios";
import { backEnd } from "../../../env";
import {
  checkChildEmail,
  sendOtp,
  verifyOtp,
} from "../../../service/apis/registration";
import { ConfirmationDialog } from "../../registration/ConfirmationDialog";
import { CustomSnackbar, useSnackState } from "../../../utils/useSnackState";
import { validateEmail } from "../../../service/commanFunction";
import { disabledBtnProps } from "../../../utils/util";

const ParentSignUp = ({
  setModalSignupShow,
  modalParentShow,
  setParentModalShow,
  setModalShow,
}) => {
  const [snackProps, snackOpen] = useSnackState();
  const [modalParentstep1Show, setParentstep1ModalShow] = useState(false);
  const [modalParentstep2Show, setParentstep2ModalShow] = useState(false);
  const [modalParentstep3Show, setParentstep3ModalShow] = useState(false);
  const [modalParentstep4Show, setParentstep4ModalShow] = useState(false);
  const [modalParentstep5Show, setParentstep5ModalShow] = useState(false);
  const [modalParentstep6Show, setParentstep6ModalShow] = useState(false);
  const [modalParentstep7Show, setParentstep7ModalShow] = useState(false);
  const [modalParentstep8Show, setParentstep8ModalShow] = useState(false);
  const [modalParentstep9Show, setParentstep9ModalShow] = useState(false);
  const [name, setName] = useState({ first: "", last: "" });
  const [otp, setotp] = useState("");
  const [childList, setChildList] = useState([]);
  const [childEmail, setChildEmail] = useState(null);
  const [values, setValues] = useState({
    userId: "",
    fullName: "",
    userName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
    childEmail: [],
  });
  const [errors, setErrors] = useState({
    fullName: false,
    userName: false,
    email: false,
    password: false,
    confirmPassword: false,
    phoneNumber: false,
    childEmail: false,
  });

  const [isPasswordVisible, setisPasswordVisible] = useState({
    create: false,
    repeat: false,
  });
  const changePasswordVisibility = (field) =>
    setisPasswordVisible((state) => ({ ...state, [field]: !state[field] }));

  const removeChildEmail = (index) => {
    setValues((state) => {
      const emails = state.childEmail.filter((val, idx) => {
        return idx !== index;
      });
      return { ...state, childEmail: emails };
    });
  };

  const handleSignUp = async () => {
    if (values.phoneNumber.substring(3).length !== 10) {
      snackOpen("Mobile number should be 10 digits", "error");
      return;
    }
    try {
      const payload = {
        firstName: name.first,
        lastName: name.last,
        userName: values.email,
        email: values.email,
        password: values.password,
        confirmPassword: values.confirmPassword,
        phoneNumber: values.phoneNumber,
        childEmails: values.childEmail,
      };

      const response = await postDate("register/parent", payload);
      setValues((state) => ({ ...state, userId: response.data.userId }));
      await sendOtp(values.phoneNumber, response.data.userId);
      setParentstep8ModalShow(true);
      setParentstep7ModalShow(false);
    } catch (err) {}
  };
  const checkMail = async () => {
    if (childEmail === "" || !childEmail) {
      setErrors((state) => ({ ...state, childEmail: true }));
      return;
    }

    if (!(await validateEmail(childEmail))) {
      setErrors((state) => ({ ...state, childEmail: true }));
      snackOpen("Enter valid email", "error");

      return;
    }
    try {
      const emailLists = await checkChildEmail([childEmail]);
      console.log("emailLists", emailLists);
      if (emailLists[0].toLowerCase() !== childEmail.toLowerCase()) {
        setErrors((state) => ({ ...state, childEmail: true }));
        snackOpen("There's no child with this email Id", "error");

        return;
      }
      setValues((state) => ({
        ...state,
        childEmail: [...state.childEmail, childEmail],
      }));
      setChildEmail("");
      changeModel(2);
    } catch (err) {
      setErrors((state) => ({ ...state, childEmail: true }));
      return;
    }
  };
  // const checkMail = async (i) => {
  //   try {
  //     if (values.password === "" || values.confirmPassword === "") return false;
  //     const payload = {
  //       childEmail: [childEmail],
  //     };
  //     const res = await postDate("auth/child/emailchecklist", payload);
  //     setValues({
  //       ...values,
  //       childEmail: [...values.childEmail, childEmail],
  //     });
  //     setChildList([...childList, ...res.data?.emailLists]);
  //     setParentstep4ModalShow(i === 2 ? true : modalParentstep4Show);
  //     setParentstep3ModalShow(i === 2 ? false : modalParentstep3Show);
  //     setParentstep2ModalShow(i === 1 ? true : modalParentstep2Show);
  //     setParentstep1ModalShow(i === 1 ? false : modalParentstep1Show);
  //   } catch (err) {
  //     //console.log("parent checkMail error :", err.message || err);
  //   }
  // };
  const changeModel = useCallback((step) => {
    try {
      switch (step) {
        case 0:
          setParentstep1ModalShow(false);
          setParentModalShow(true);
          break;
        case 1:
          setParentstep1ModalShow(true);
          setParentModalShow(false);
          setParentstep2ModalShow(false);
          break;
        case 2:
          setParentstep2ModalShow(true);
          setParentstep1ModalShow(false);
          setParentstep3ModalShow(false);
          break;
        case 3:
          setParentstep3ModalShow(true);
          setParentstep2ModalShow(false);
          setParentstep4ModalShow(false);
          break;
        case 4:
          setParentstep4ModalShow(true);
          setParentstep3ModalShow(false);
          setParentstep5ModalShow(false);
          break;
        case 5:
          setParentstep5ModalShow(true);
          setParentstep4ModalShow(false);
          setParentstep6ModalShow(false);
          break;
        case 6:
          setParentstep7ModalShow(false);
          setParentstep6ModalShow(true);
          setParentstep5ModalShow(false);
          break;
        case 7:
          setParentstep6ModalShow(false);
          setParentstep7ModalShow(true);
          setParentstep8ModalShow(false);
          break;
        case 8:
          setParentstep7ModalShow(false);
          setParentstep8ModalShow(true);
          break;
        default:
          setParentModalShow(false);
          setParentstep1ModalShow(false);
          setParentstep2ModalShow(false);
          setParentstep3ModalShow(false);
          setParentstep4ModalShow(false);
          setParentstep5ModalShow(false);
          setParentstep6ModalShow(false);
          setModalSignupShow(true);
          break;
      }
    } catch (err) {
      //console.log("\n error in changeModel:", err.message || err);
    }
  });

  const confirmOtp = async () => {
    try {
      await verifyOtp(otp, values.userId);
      setParentstep8ModalShow(false);
      setParentstep9ModalShow(true);
      // setModalShow(true);
    } catch (err) {}
  };

  const step1FetchData = useCallback(async () => {
    const haveErrors = {
      first: false,
      last: false,
    };
    if (!name.first || name.first === "" || name.first === undefined) {
      haveErrors.first = true;
    }
    if (!name.last || name.last === "" || name.last === undefined) {
      haveErrors.last = true;
    }
    if (haveErrors.first || haveErrors.last) {
      setErrors({
        ...errors,
        ...haveErrors,
      });
      return;
    }
    setValues({ ...values, fullName: name.first + " " + name.last });
    changeModel(1);
  });
  return (
    <div className="signup-wrapper">
      <Modal
        className="loginmodal student-login-main parent-login"
        show={modalParentShow}
        onHide={() => {
          setParentModalShow(false);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header closeButton>
            <div className="btnback">
              <Link
                to="#"
                onClick={() => changeModel(-1)}
                className="backbtn-icn"
              >
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Sign up as a Parent</h4>
              <p>We are Neithedu team!</p>
              <p>Please introduce yourself</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <Form.Label>Your first name</Form.Label>
                  <Form.Control
                    type="name"
                    value={name.first}
                    onChange={(e) => {
                      if (!/^[a-zA-Z]*$/g.test(e.target.value)) {
                        return false;
                      }
                      setErrors({ ...errors, first: false });
                      setName({ ...name, first: e.target.value });
                    }}
                    className={errors.first ? "error-box" : ""}
                    placeholder="Your first name"
                  />
                </Form.Group>

                <Form.Group className="subsform-fild">
                  <Form.Label>Your last name</Form.Label>
                  <Form.Control
                    type="name"
                    value={name.last}
                    onChange={(e) => {
                      if (!/^[a-zA-Z]*$/g.test(e.target.value)) {
                        return false;
                      }
                      setErrors({ ...errors, last: false });
                      setName({ ...name, last: e.target.value });
                    }}
                    className={errors.last ? "error-box" : ""}
                    placeholder="Your last name"
                  />
                </Form.Group>

                <div className="btnlogin-main">
                  <button
                    style={
                      name.first && name.last ? {} : { ...disabledBtnProps }
                    }
                    type="submit"
                    className="btnlogin btnweak-bx"
                    onClick={() => {
                      step1FetchData();
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="count-barr">
            <div className="counting-barful">
              <div className="linebar-tex">1/6</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>

      {/* start parent signup step1 */}
      <Modal
        className="loginmodal student-login-main parent-signup-step1"
        // {...props}
        show={modalParentstep1Show}
        onHide={() => {
          changeModel(-1);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <CustomSnackbar {...snackProps} />

        <div className="login-modal-mainbx">
          <Modal.Header closeButton>
            <div className="btnback">
              <Link
                to="#"
                onClick={() => changeModel(0)}
                className="backbtn-icn"
              >
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Sign up as a parent</h4>
              <p>
                Nice to meet you, {values.fullName}! Add your children in
                Neithedu. You should have at least one child registered as a
                student
              </p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <Form.Label>Email of my child in Neithedu is</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="example@email.com"
                    value={childEmail}
                    onChange={(e) => {
                      setErrors((state) => ({ ...state, childEmail: false }));
                      setChildEmail(e.target.value);
                    }}
                    className={errors.childEmail ? "error-box" : ""}
                  />
                </Form.Group>

                <div className="btnlogin-main">
                  <button
                    style={childEmail ? {} : { ...disabledBtnProps }}
                    type="submit"
                    className="btnlogin btnweak-bx"
                    onClick={() => {
                      checkMail(1);
                      // changeModel(2);
                    }}
                  >
                    Check this email
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="count-barr parent-count-step2">
            <div className="counting-barful">
              <div className="linebar-tex">2/5</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>

      {/* end parent signup step1 */}

      {/* start parent signup step2 */}

      <Modal
        className="loginmodal student-login-main parent-signup-step2 mychild-frm"
        // {...props}
        show={modalParentstep2Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <CustomSnackbar {...snackProps} />

        <div className="login-modal-mainbx">
          <Modal.Header closeButton>
            <div className="btnback">
              <Link
                to="#"
                onClick={() => changeModel(1)}
                className="backbtn-icn"
              >
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Sign up as a parent</h4>
              <p>Please, check found account</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild child-tex-fld">
                  <Form.Label>My child is</Form.Label>
                  <div className="child-boxnew">
                    <div className="child-account">
                      <div className="activ-detail-list">
                        {values.childEmail.map((e, index) => {
                          return (
                            <div key={index} className="active-detail-row mb-2">
                              <div className="activiti-prof-icon">
                                <span className="profile-icon-box">
                                  <i className="fas fa-user"></i>
                                </span>
                              </div>
                              <div className="activiti-center-detail">
                                <h5>{e}</h5>
                                {/* <h6>India, Mumbai 10 y.o.</h6> */}
                              </div>
                              <div className="activiti-count-mesg">
                                <button
                                  onClick={() => removeChildEmail(index)}
                                  className="count-num-activiti"
                                >
                                  <i className="fas fa-times"></i>
                                </button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Form.Group>

                <div className="have-account">
                  <Link
                    className="title-med"
                    to="#"
                    onClick={() => {
                      setParentstep3ModalShow(true);
                      setParentstep2ModalShow(false);
                    }}
                  >
                    Add one more child
                  </Link>
                </div>

                <div className="btnlogin-main">
                  <button
                    type="submit"
                    className="btnlogin btnweak-bx"
                    onClick={() => {
                      if (values.childEmail.length <= 0) {
                        snackOpen("Add atleast one child", "error");
                        return;
                      }
                      setParentstep5ModalShow(true);
                      setParentstep2ModalShow(false);
                    }}
                  >
                    Add to my children and continue sign up
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="count-barr parent-count-step2">
            <div className="counting-barful">
              <div className="linebar-tex">2/5</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>

      {/* start parent signup step2 */}

      {/* start parent signup step7 */}
      <Modal
        className="loginmodal student-login-main parent-signup-step7"
        // {...props}
        show={modalParentstep3Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header closeButton>
            <div className="btnback">
              <Link
                to="#"
                onClick={() => changeModel(2)}
                className="backbtn-icn"
              >
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Sign up as a parent</h4>
              <p>
                Please, add one more child. You can add all your children after
                registration
              </p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <Form.Label>Email of my child in Neithedu is</Form.Label>
                  <Form.Control
                    type="email"
                    className={errors.childEmail ? "error-box" : ""}
                    placeholder="child_email@example.com"
                    value={childEmail}
                    onChange={(e) => {
                      setErrors((state) => ({ ...state, childEmail: false }));
                      setChildEmail(e.target.value);
                    }}
                  />
                </Form.Group>

                <div className="btnlogin-main">
                  <button
                    type="submit"
                    className="btnlogin btnweak-bx"
                    onClick={() => {
                      checkMail(2);
                    }}
                  >
                    Check this email
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="count-barr parent-count-step2">
            <div className="counting-barful">
              <div className="linebar-tex">2/5</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        className="loginmodal student-login-main parent-signup-step8 parent-signup-step2 mychild-frm"
        show={modalParentstep4Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header closeButton>
            <div className="btnback">
              <Link
                to="#"
                onClick={() => changeModel(3)}
                className="backbtn-icn"
              >
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Sign up as a parent</h4>
              <p>Please, check found account</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild child-tex-fld">
                  <Form.Label>My children are</Form.Label>
                  <div className="child-boxnew">
                    {childList?.map((x) => {
                      return (
                        <div className="child-account" key={x}>
                          <div className="activ-detail-list">
                            <div className="active-detail-row">
                              <div className="activiti-prof-icon">
                                <span className="profile-icon-box">
                                  <i className="fas fa-user"></i>
                                </span>
                              </div>
                              <div className="activiti-center-detail">
                                <h5>{x.email}</h5>
                              </div>
                              <div className="activiti-count-mesg">
                                <button className="count-num-activiti">
                                  <i className="fas fa-times"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Form.Group>

                <div className="have-account">
                  <Link
                    className="title-med"
                    to="#"
                    onClick={() => {
                      setParentstep3ModalShow(true);
                    }}
                  >
                    Add one more child
                  </Link>
                </div>

                <div className="btnlogin-main">
                  <button
                    type="submit"
                    className="btnlogin btnweak-bx"
                    onClick={() => {
                      setParentstep5ModalShow(true);
                      setParentstep4ModalShow(false);
                    }}
                  >
                    Add to my children and continue sign up
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="count-barr parent-count-step2">
            <div className="counting-barful">
              <div className="linebar-tex">2/5</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        className="loginmodal student-login-main parent-signup-step3"
        // {...props}
        show={modalParentstep5Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <CustomSnackbar {...snackProps} />

        <div className="login-modal-mainbx">
          <Modal.Header closeButton>
            <div className="btnback">
              <Link
                to="#"
                onClick={() => changeModel(4)}
                className="backbtn-icn"
              >
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Sign up as a parent</h4>
              <p>
                Please, put your email. Don’t worry, we will send just the most
                important information!
              </p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <Form.Label>My email is</Form.Label>
                  <Form.Control
                    value={values.email}
                    type="email"
                    placeholder="example@email.com"
                    onChange={(e) => {
                      setValues({
                        ...values,
                        email: e.target.value,
                        userName: e.target.value,
                      });
                    }}
                  />
                </Form.Group>

                <div className="btnlogin-main">
                  <button
                    type="submit"
                    style={values.email ? {} : { ...disabledBtnProps }}
                    className="btnlogin btnweak-bx"
                    onClick={async () => {
                      if (!(await validateEmail(values.email))) {
                        snackOpen("Invalid email", "error");
                        return;
                      }
                      setParentstep6ModalShow(true);
                      setParentstep5ModalShow(false);
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="count-barr parent-count-step3">
            <div className="counting-barful">
              <div className="linebar-tex">3/5</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Password Dialog */}
      <Modal
        className="loginmodal student-login-main parent-signup-step6"
        show={modalParentstep6Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <CustomSnackbar {...snackProps} />

        <div className="login-modal-mainbx">
          <Modal.Header closeButton>
            <div className="btnback">
              <Link
                to="#"
                onClick={() => changeModel(5)}
                className="backbtn-icn"
              >
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Sign up as a parent</h4>
              <p>
                Create the password to enter to your account with at least 6
                signs
              </p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <Form.Label>Create a password</Form.Label>
                  <div className="passwd-fild" style={{ position: "relative" }}>
                    <Form.Control
                      type={isPasswordVisible.create ? "text" : "password"}
                      placeholder="**********"
                      onChange={(e) =>
                        setValues({
                          ...values,
                          password: e.target.value,
                        })
                      }
                      value={values.password}
                    />
                    <span
                      style={{
                        top: "13px",
                        right: "10px",
                        position: "absolute",
                      }}
                      onClick={() => changePasswordVisibility("create")}
                      className="icon-eye cursor-pointer"
                    >
                      <i
                        className="fas fa-eye"
                        style={{
                          color: isPasswordVisible.create
                            ? "var(--purple)"
                            : "black",
                        }}
                      ></i>
                    </span>
                  </div>
                </Form.Group>

                <Form.Group className="subsform-fild">
                  <Form.Label>Repeat a password</Form.Label>
                  <div className="passwd-fild" style={{ position: "relative" }}>
                    <Form.Control
                      type={isPasswordVisible.repeat ? "text" : "password"}
                      placeholder="**********"
                      onChange={(e) =>
                        setValues({
                          ...values,
                          confirmPassword: e.target.value,
                        })
                      }
                      value={values.confirmPassword}
                    />
                    <span
                      style={{
                        position: "absolute",
                        top: "13px",
                        right: "10px",
                      }}
                      onClick={() => changePasswordVisibility("repeat")}
                      className="icon-eye"
                    >
                      <i
                        className="fas fa-eye"
                        style={{
                          color: isPasswordVisible.repeat
                            ? "var(--purple)"
                            : "black",
                        }}
                      ></i>
                    </span>
                  </div>
                </Form.Group>

                <div className="btnlogin-main">
                  <button
                    style={
                      values.password && values.confirmPassword
                        ? {}
                        : { ...disabledBtnProps }
                    }
                    onClick={() => {
                      const pw = values.password;
                      const cpw = values.confirmPassword;
                      if (!pw.trim() || !cpw.trim()) {
                        snackOpen(
                          "Please fill all the fields (remove spaces if entered)",
                          "error",
                        );
                        return;
                      }
                      if (pw !== cpw) {
                        snackOpen("Passwords not matched", "error");
                        return;
                      }
                      if (pw.length < 6) {
                        snackOpen(
                          "Password should be atleast 6 characters",
                          "error",
                        );
                        return;
                      }
                      setParentstep7ModalShow(true);
                      setParentstep6ModalShow(false);
                    }}
                    className="btnlogin btnweak-bx"
                  >
                    Save the password
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="count-barr parent-count-step5">
            <div className="counting-barful">
              <div className="linebar-tex">5/5</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        className="loginmodal student-login-main parent-signup-step4 student-signup-step4"
        // {...props}
        show={modalParentstep7Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <CustomSnackbar {...snackProps} />

        <div className="login-modal-mainbx">
          <Modal.Header closeButton>
            <div className="btnback">
              <Link
                to="#"
                onClick={() => changeModel(6)}
                className="backbtn-icn"
              >
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Sign up as a parent</h4>
              <p>
                You should confirm your mobile phone in order to save your
                account from being hacked
              </p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <Form.Label>My mobile phone is</Form.Label>
                  <PhoneInput
                    international
                    defaultCountry="IN"
                    placeholder="+375 22 123 45 67"
                    value={values.phoneNumber}
                    onChange={(e) => {
                      setErrors({
                        ...errors,
                        phoneNumber: false,
                      });
                      setValues({
                        ...values,
                        phoneNumber: e,
                      });
                    }}
                    className={errors.phoneNumber ? "error-box" : ""}
                  />
                </Form.Group>

                <div className="btnlogin-main">
                  <button
                    style={values.phoneNumber ? {} : { ...disabledBtnProps }}
                    type="submit"
                    className="btnlogin btnweak-bx"
                    onClick={() => {
                      handleSignUp();
                    }}
                  >
                    Send a verification code
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="count-barr parent-count-step4">
            <div className="counting-barful">
              <div className="linebar-tex">4/5</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        className="loginmodal student-login-main parent-signup-step5 student-signup-step5"
        show={modalParentstep8Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header closeButton>
            <div className="btnback">
              <Link
                to="#"
                onClick={() => changeModel(7)}
                className="backbtn-icn"
              >
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Sign up as a parent</h4>
              <p>
                You should confirm your mobile phone in order to save your
                account from being hacked
              </p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <Form.Label>My mobile phone is</Form.Label>
                  <div className="phone-bar-form">
                    <div className="country-cd">
                      <img src={flag2} className="img-fluid" />
                    </div>
                    <div className="number-fld">
                      <Form.Group className="subsform-fild">
                        <Form.Control
                          type="text"
                          disabled
                          value={values.phoneNumber}
                          placeholder="+375 22 123 45 67"
                        />
                      </Form.Group>

                      <div className="sendagain-btn">
                        <Link
                          onClick={() =>
                            sendOtp(values.phoneNumber, values.userId)
                          }
                          to="#"
                        >
                          Send again
                        </Link>
                      </div>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className="subsform-fild">
                  <Form.Label>Verification code</Form.Label>
                  <Form.Control
                    value={otp}
                    onChange={(e) => setotp(e.target.value)}
                    type="text"
                    placeholder="Enter here"
                  />
                </Form.Group>

                <div className="btnlogin-main">
                  <button
                    style={otp ? {} : { ...disabledBtnProps }}
                    type="submit"
                    className="btnlogin btnweak-bx"
                    onClick={() => {
                      confirmOtp();
                    }}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="count-barr parent-count-step4">
            <div className="counting-barful">
              <div className="linebar-tex">4/5</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>
      <ConfirmationDialog
        isOpen={modalParentstep9Show}
        closeDialog={() => {
          // backClick();
        }}
        signInScreenHandler={() => {
          setParentstep9ModalShow(false);
          setModalShow(true);
        }}
      />
    </div>
  );
};

export default ParentSignUp;
