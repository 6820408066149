import { Box, Grid, Slider, styled, Typography } from "@mui/material";
import { CloseButton, StyledYellowButton } from "../../utils/Buttons";

import questionnaireHomeImage from "../../../assets/images/questionnaireHome.png";

const HomeImage = styled("img")({
  borderRadius: "0px 0px 0px 20px",
  width: "100%",
  height: "100%",
  objectFit: "contain",
});

export const Home = ({
  sliderValues,
  sliderRange,
  closeQuestionnaire,
  changeDialog,
  dialogs,
}) => {
  return (
    <Grid
      container
      sx={{
        position: "relative",
        backgroundColor: "var(--white)",
        borderRadius: "10px",
        maxWidth: "60rem",
        m: "3rem auto 10rem auto",
        p: "1rem",
      }}
    >
      <CloseButton closeHandler={closeQuestionnaire} />
      <Grid item md={6} xs={12}>
        <HomeImage src={questionnaireHomeImage} alt="studying" />
      </Grid>
      <Grid sx={{ position: "relative", p: "1.5rem" }} item md={6} xs={12}>
        <Typography
          mt="1rem"
          mb="0.5rem"
          sx={{ fontSize: "1.6rem", fontWeight: "800" }}
          color="var(--purple-dark)"
        >
          How to choose correct answer
        </Typography>
        <Typography
          mb={"0.5rem"}
          sx={{ color: "var(--black)", fontWeight: "500" }}
          fontSize="0.8rem"
          variant="body"
        >
          You will find a series of questions that might be used to describe
          your learning style. Presented in each item.
        </Typography>
        <Typography
          my={"0.5rem"}
          variant="h6"
          fontWeight={600}
          color="var(--purple-dark)"
        >
          Let's start!
        </Typography>
        <Typography
          fontSize="0.8rem"
          sx={{ color: "var(--black)", fontWeight: "500" }}
          variant="body"
        >
          You see the question bellow you want to choose that you it suit you
          100% you should push slider to the right. You will find a series of
          questions that might be used to describe your learning style.
          Presented in each item a bipolar and next text with explanation of the
          rule
        </Typography>
        <Typography
          mt={"0.9rem"}
          variant="h6"
          sx={{ color: "var(--grey-light)", fontSize: "1rem" }}
        >
          Question
        </Typography>
        <Typography
          mb={"0.5rem"}
          variant="h6"
          fontSize={"1rem"}
          fontWeight={600}
          color="var(--purple-dark)"
        >
          I better understand and remember lessons that are presentes using
        </Typography>
        <Slider
          track={false}
          // name={question.question_no}
          // onChange={(e, val) => {
          //   sliderHandler(e.target.name, val);
          // }}
          // value={state.questionPoints[question.question_no]}
          sx={{ color: "var(--purple)" }}
          aria-label="Restricted values"
          defaultValue={0}
          valueLabelFormat={(e) => e.label}
          step={null}
          valueLabelDisplay="off"
          marks={sliderValues}
          max={sliderRange}
          min={-sliderRange}
        />
        <Box sx={{ height: "2rem" }}>{/* Some text */}</Box>
        <Box
          sx={{
            fontSize: "0.9rem",
            padding: "0.8rem",
            position: "absolute",
            bottom: "-3rem",
            left: "15%",
          }}
        >
          <StyledYellowButton
            title={" Confirm the answer and continue!"}
            btnHandler={() => changeDialog(dialogs.GO_TO_QUESTIONNAIRE_DIALOG)}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
