export const allSyllabus = [
  { id: 1, name: "Maths" },
  { id: 2, name: "History" },
  { id: 3, name: "English" },
  { id: 4, name: "Physics" },
  { id: 5, name: "Biology" },
  { id: 6, name: "Chemistry" },
  { id: 7, name: "Literature" },
  { id: 8, name: "Astronomy" },
  { id: 9, name: "Geography" },
];
export const AllWorkExperience = [
  { id: 2, name: "more than 2 years" },
  { id: 5, name: "more than 5 years" },
  { id: 10, name: "more than 10 years" },
];

export const userRoleId = {
  ADMIN: 1,
  SUBADMIN: 2,
  EDUCATOR: 3,
  PARENT: 4,
  STUDENT: 5,
  TUTOR: 6,
  SUPPORTBUDDY: 7,
  COACH: 8,
};
export const userRole = {
  ADMIN: 1,
  SUBADMIN: 2,
  EDUCATOR: "Educator",
  PARENT: "Parent",
  STUDENT: "Student",
  TUTOR: "Tutor",
  SUPPORTBUDDY: 7,
  COACH: 8,
};

export const genders = {
  MALE: 1,
  FEMALE: 2,
};

export const materialType = {
  all: 1,
  video: 2,
  text: 3,
  document: 4,
  audio: 5,
  image: 6,
  link: 7,
};
