import React, { useContext, useEffect, useState } from "react";
import "../../home.scss";
import "../../profile.scss";
import "../../media.scss";
import "../../games.scss";
import "../../loginModal/login.scss";
import "../../myactivities.scss";
import "../../Parentsaccount.scss";
import { Container, Dropdown, Row, Button, Form, Modal } from "react-bootstrap";
import { Tabs, TabList } from "react-tabs";
import ActivityNotification from "../../educator/activity/ActivityNotification";

import logo from "../../../assets/images/logo.png";

import { Link } from "react-router-dom";
import Headerparents from "../../layout/Header/Headerparents";
import FooterParent from "../../layout/Footer/FooterParent";
import Addmychild from "../../Addmychild";
import DatePicker from "react-datepicker";
import {
  getAllActivitiesstudent,
  getParentdAllActivities,
} from "../../../service/apis/activities";
import { addParentTask } from "../../../service/apis/tasks";
import { CustomSnackbar, useSnackState } from "../../../utils/useSnackState";
import { convertDateToString } from "../../../utils/helperFunctions";
import { NotificationContext } from "../../../contexts/Notification";

function ParentAccount() {
  const [modalpersonaltskstep1Show, setpersonaltskstep1ModalShow] =
    React.useState(false);
  const [openAddMyChildModal, setOpenAddMyChildModal] = React.useState(false);
  const [titleOptions, setTitleOptions] = useState(taskTitles);
  const [snackProps, snackOpen] = useSnackState();

  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [querySearch, setquerySearch] = useState("");
  const [tpd, settpd] = useState(1);
  const [tasktitle, setTasktitle] = useState("");
  const [state, setState] = useState({
    activities: [],
    studentsgroups: [],
    selectedItem: {
      item: null,
    },
  });

  const [fromData, setfromData] = useState({
    title: "Do home work",
    description: "",
    points: 20,
    pointsStatus: 1,
    dueDate: new Date(),
  });

  const { showNotification } = useContext(NotificationContext);

  const submittasktitle = (item) => {
    settpd(item.id);
    if (item.id === 3) {
      setfromData((state) => ({ ...state, title: tasktitle }));
    } else {
      setfromData((state) => ({ ...state, title: item.title }));
    }
  };

  const getAcitived = async () => {
    const responce = await getParentdAllActivities();
    console.log("responce", responce);
    setState((state) => ({ ...state, studentsgroups: responce }));
    localStorage.setItem("viewData", JSON.stringify(state));
  };

  const taskTitleHandler = (value) => {
    setTasktitle(value);
    if (tpd === 3) setfromData((state) => ({ ...state, title: value }));
  };

  useEffect(() => {
    const educatorData = JSON.parse(localStorage.getItem("viewData"));
    setState((state) => ({
      ...state,
      ...educatorData,
    }));
    getAcitived();
  }, []);

  const searchedStudentsGroups = state.studentsgroups.filter((stugrp) => {
    return stugrp.name
      ?.toLowerCase()
      .trim()
      .includes(querySearch.toLowerCase());
  });

  const selectItemHandler = (item) => {
    const educatorData = JSON.parse(localStorage.getItem("viewData"));
    localStorage.setItem(
      "viewData",
      JSON.stringify({
        ...educatorData,
        selectedItem: { item: item },
      }),
    );
    setState((state) => ({ ...state, selectedItem: { item: item } }));
  };

  const fetchActivities = async (curUserId, id) => {
    const activities = await getAllActivitiesstudent(id, curUserId);

    setState((state) => {
      return {
        ...state,
        activities: activities,
      };
    });
  };

  const sendParentActivites = async (forminputs) => {
    try {
      const res = await addParentTask(
        forminputs,
        currentUser.applicationUser.id,
        state.selectedItem.item.id,
        false,
      );
      if (res) {
        fetchActivities(
          currentUser.applicationUser.id,
          state.selectedItem.item?.id,
        );
        showNotification("Task added successfully!");

        setTimeout(() => {
          setpersonaltskstep1ModalShow(false);
          setfromData({
            title: "Do home work",
            description: "",
            points: 20,
            pointsStatus: 1,
            dueDate: new Date(),
          });
        }, 2000);
      }
    } catch (error) {
      snackOpen("Something went wrong!", "error");
    }
  };

  useEffect(() => {
    if (state.selectedItem.item) {
      fetchActivities(
        currentUser.applicationUser.id,
        state.selectedItem.item?.id,
      );
    }
  }, [state.selectedItem.item]);

  const sendTaskHandler = () => {
    // if description, deadline and points does not exist, show error
    if (
      fromData.title.length < 3 ||
      fromData.description.length < 3 ||
      fromData.dueDate === "" ||
      fromData.points === ""
    ) {
      snackOpen("Please fill all the fields!", "error");
      return;
    }
    // if ;oints are negative, show error
    if (fromData.points < 0) {
      snackOpen("Points cannot be negative!", "error");
      return;
    }
    // state.activities has the activity that we are trying to add, so we need to check if the title, description, date and points are same as the one we are trying to add
    let isSame = state.activities.some(
      (activity) =>
        activity.title === fromData.title &&
        activity.description === fromData.description &&
        activity.dueDate === convertDateToString(fromData.dueDate) &&
        activity.points === parseInt(fromData.points),
    );
    console.log("isSame", isSame);
    if (isSame) {
      snackOpen("Task already exists!", "error");
      return;
    }

    sendParentActivites({
      ...fromData,
      dueDate: convertDateToString(fromData.dueDate),
    });
  };

  useEffect(() => {
    // take all the titles from state.activities and put them in titleOptions
    let titles = [...titleOptions];
    state.activities.forEach((activity, index) => {
      let isSame = titles.some((title) => title.title === activity.title);
      if (!isSame) {
        titles.push({ title: activity.title, id: index + 4 });
      }
    });
    setTitleOptions(titles);
  }, [state.activities]);

  console.log("state", state);

  return (
    <>
      <div className="home-wrapper">
        {/* start header */}
        <Headerparents />
        {/* end header */}

        <div className="games-dashboard-main dashboard-bgnew blur-bgg educator-home">
          <div className="games-topbread">
            <Container>
              <div className="diversity-box">
                <div className="myactivites-main">
                  <div className="myactivites-contain">
                    <Tabs>
                      <div className="activies-row">
                        <div className="left-pannel">
                          <div className="leftbx-activites">
                            <div className="acti-left-detail">
                              <div className="puil-mainbx">
                                <div className="add-pupil">
                                  <div className="pupil-dropdwn">
                                    <Dropdown className="dropdown-main dropmenu-custlistt">
                                      <Dropdown.Toggle
                                        variant="success"
                                        id="dropdown-basic"
                                        className="dropdown-btnn"
                                        onClick={() =>
                                          setOpenAddMyChildModal(true)
                                        }
                                      >
                                        <i className="fas fa-plus"></i>
                                      </Dropdown.Toggle>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="serchfiled-activities">
                                  <Form.Control
                                    type="text"
                                    placeholder="Search"
                                    className="formbox"
                                    value={querySearch}
                                    onChange={(e) =>
                                      setquerySearch(String(e.target.value))
                                    }
                                  />
                                  <Button type="button" className="seachbtn">
                                    <i className="fas fa-search"></i>
                                  </Button>
                                </div>
                              </div>
                              <div className="activities-list-left">
                                {searchedStudentsGroups.length === 0 && (
                                  <div className="no-data-found">
                                    No child exists
                                  </div>
                                )}
                                <TabList className="tabslist-activiti">
                                  {searchedStudentsGroups.map((item, i) => {
                                    return (
                                      <div
                                        key={i}
                                        className="tabslist-activiti-tab"
                                      >
                                        <div
                                          onClick={() =>
                                            selectItemHandler(item)
                                          }
                                          className="activ-detail-list"
                                        >
                                          <div className="active-detail-row">
                                            <div className="activiti-prof-icon">
                                              <span className="profile-icon-box">
                                                <i className="fas fa-user"></i>
                                              </span>
                                            </div>
                                            <div className="activiti-center-detail">
                                              <h5>{item.name}</h5>
                                              <h6>
                                                {item.numberOfActivities}{" "}
                                                activity
                                              </h6>
                                            </div>
                                            <div className="activiti-count-mesg">
                                              <span className="count-num-activiti">
                                                {item.numberOfActivities}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </TabList>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="right-panel">
                          <div className="activities-rightbx">
                            <div className="tabslist-content-act tabone">
                              <div className="myacti-listtabs educator-lsttabs-new">
                                <div className="chat-headerbar">
                                  <div className="chat-topbar-list">
                                    <div className="activ-detail-list">
                                      <div className="active-detail-row">
                                        {state.selectedItem.item ? (
                                          <>
                                            <div className="activiti-prof-icon">
                                              <span className="profile-icon-box">
                                                <i className="fas fa-user"></i>
                                              </span>
                                            </div>
                                            <div className="activiti-center-detail">
                                              <h5>
                                                {state.selectedItem.item?.name}
                                              </h5>

                                              <h6>
                                                {
                                                  state.selectedItem.item
                                                    ?.numberOfActivities
                                                }{" "}
                                                activities in progress
                                              </h6>
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            <div className="activiti-prof-icon">
                                              <span className="profile-icon-box"></span>
                                            </div>
                                            <div className="activiti-center-detail">
                                              <h5>Select A Item</h5>
                                            </div>
                                          </>
                                        )}
                                        <div className="activiti-count-mesg">
                                          <div className="chat-right-droplist">
                                            {state.selectedItem.item && (
                                              <>
                                                <ul className="list-chatbx">
                                                  <li className="chat-dropright educ-acvt">
                                                    <div className="droplist-right-btn">
                                                      <Dropdown className="dropdown-main">
                                                        <Dropdown.Toggle
                                                          className="dropdown-btnn addactv-btndrp"
                                                          onClick={() =>
                                                            setpersonaltskstep1ModalShow(
                                                              true,
                                                            )
                                                          }
                                                        >
                                                          <span className="actvty-icn">
                                                            <i className="fas fa-plus"></i>
                                                          </span>{" "}
                                                          Add a task
                                                        </Dropdown.Toggle>
                                                      </Dropdown>
                                                    </div>
                                                  </li>

                                                  <li className="chat-dropright edu-lst-drp">
                                                    <div className="droplist-right-btn">
                                                      <Dropdown className="dropdown-main">
                                                        <Dropdown.Toggle
                                                          variant="success"
                                                          id="dropdown-basic"
                                                          className="dropdown-btnn"
                                                        >
                                                          <i className="fas fa-ellipsis-v"></i>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu className="dropdown-btn-item">
                                                          <Dropdown.Item href="#">
                                                            <span className="rightarrow-icon">
                                                              <i className="fas fa-angle-right"></i>
                                                            </span>{" "}
                                                            All files
                                                          </Dropdown.Item>
                                                          <Dropdown.Item href="#">
                                                            <span className="rightarrow-icon">
                                                              <i className="fas fa-angle-right"></i>
                                                            </span>{" "}
                                                            Notifications
                                                          </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                      </Dropdown>
                                                    </div>
                                                  </li>
                                                </ul>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="live-lesson-listbtm">
                                  {state.activities?.map((item, i) => {
                                    return (
                                      <ActivityNotification
                                        activity={item}
                                        linkToRedirect={`/PersonalTask/${item.activityTypeId}/${item.id}`}
                                        isGroup={false}
                                        key={i}
                                      />
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tabs>
                  </div>
                </div>
              </div>
            </Container>
          </div>

          {/* footer start */}
          <FooterParent logo={logo} />
          {/* footer end */}
        </div>
      </div>

      {openAddMyChildModal && (
        <Addmychild
          open={openAddMyChildModal}
          setOpen={(value) => setOpenAddMyChildModal(value)}
        />
      )}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        show={modalpersonaltskstep1Show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <CustomSnackbar {...snackProps} />
        <div className="login-modal-mainbx groupmbr-step2 mystudent-games persltask-form">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>
                New personal task to <br></br> {state.selectedItem.item?.name}
              </h4>
            </Modal.Title>
            <button
              type="button"
              onClick={() => {
                setpersonaltskstep1ModalShow(false);
                setfromData({
                  title: "Do home work",
                  description: "",
                  points: 20,
                  pointsStatus: 1,
                  dueDate: "",
                });
              }}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="newgames-createbx">
                    <Row>
                      <div className="col-md-12">
                        <Form.Label>Choose type of a task</Form.Label>
                        <div className="tasktype">
                          <ul className="learner-listing-games taskpurple">
                            {titleOptions.map((item, i) => {
                              return (
                                <li>
                                  <button
                                    onClick={() => submittasktitle(item)}
                                    className={`btn-learner-lsit ${
                                      item.id === tpd ? "active" : ""
                                    }`}
                                    style={{ padding: "5px 23px" }}
                                  >
                                    {item.title}
                                  </button>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>

                      {tpd === 3 && (
                        <div className="col-md-12">
                          <Form.Label>Title of the task</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Help your mother"
                            value={tasktitle}
                            onChange={(e) => taskTitleHandler(e.target.value)}
                          ></Form.Control>
                        </div>
                      )}

                      <div className="col-md-12">
                        <Form.Label>Description </Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Describe your task"
                          value={fromData.description}
                          onChange={(e) =>
                            setfromData((state) => ({
                              ...state,
                              description: e.target.value,
                            }))
                          }
                        />
                      </div>

                      <div className="col-md-12">
                        <Form.Label>Deadline</Form.Label>
                        <div className="datepick-frm datepicker-purple">
                          <Form.Group className="subsform-fild">
                            <DatePicker
                              selected={fromData.dueDate}
                              minDate={new Date() - 1}
                              dateFormat="dd-MM-yyyy"
                              className="dateformat"
                              onChange={(date) =>
                                setfromData((state) => ({
                                  ...state,
                                  dueDate: date,
                                }))
                              }
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <Form.Label>
                          How many points child can earn for the task
                        </Form.Label>
                      </div>

                      <div className="col-md-12 std-point-frm">
                        <Form.Control
                          type="number"
                          placeholder="20"
                          className="texblck"
                          value={fromData.points}
                          style={{ color: "#6a64e6" }}
                          onChange={(e) =>
                            setfromData((state) => ({
                              ...state,
                              points: e.target.value,
                            }))
                          }
                        />
                      </div>
                    </Row>
                  </div>
                </Form.Group>

                <div className="btnlogin-main taskbtn-uppr">
                  <Link
                    to="#"
                    className="btnlogin btnweak-bx"
                    onClick={sendTaskHandler}
                  >
                    Send the task to {state.selectedItem.item?.name}
                  </Link>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}

export default ParentAccount;
const taskTitles = [
  {
    title: "Do home work",
    id: 1,
  },
  {
    title: "Do house work",
    id: 2,
  },
  {
    title: "Custom task",
    id: 3,
  },
];
