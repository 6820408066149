import React, { useEffect } from "react";
import "./home.scss";
import "./profile.scss";
import "./media.scss";
import "./games.scss";
import "./loginModal/login.scss";
import "./messages/messages.scss";
import "./myactivities.scss";
import "./gamestabs.scss";
import "./gamesflowphotos.scss";
import "./selectedgames.scss";
import "./questionone.scss";
import "./creatingmaterial.scss";
import "./Currentlesson.scss";
import { Container, Button, Form, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";

import logo from "../assets/images/logo.png";
import backorange from "../assets/images/backorange.svg";
import { Link } from "react-router-dom";
import FooterEducator from "./layout/Footer/FooterEducator";
import HeaderGames from "./layout/Header/HeaderGames";
import { TextField } from "@mui/material";
import { getSingalActivities } from "../service/apis/activities";
import TimerComponent from "./TimerComponent";
import {
  addOffsetToTime,
  combineDateTime,
  convert12to24HrFormat,
  convertDateToObject,
  convertmsToDHM,
} from "../utils/helperFunctions";
import CancelDialog from "./student/liveLesson/CancelDialog";
import ReasonDialog from "./student/liveLesson/ReasonDialog";
import ConfirmCancel from "./student/liveLesson/ConfirmCancel";
import { updateLessonStatus } from "../service/apis/lesson";
import { useHistory } from "react-router-dom";

export const cancelLessonDialogs = {
  CANCEL: "CANCEL",
  REASON: "REASON",
  CANCELCONFIRM: "CANCELCONFIRM",
};

function LiveLessonDetail() {
  const [lessonDetail, setLessonDetail] = React.useState({
    title: "",
    dateOfLesson: "",
    timeOfLesson: "",
    commentForStudent: "",
    otherReasons: "",
  });

  const [modalgamesavedstep1Show, setgamesavedstep1ModalShow] =
    React.useState(false);

  /* start create a new games*/
  const [modalgamecreatestep1Show, setgamecreatestep1ModalShow] =
    React.useState(false);

  /* end create a new games */

  /* start session create*/
  const [modalsessioncreatestep1Show, setsessioncreatestep1ModalShow] =
    React.useState(false);

  /* end session create */

  /* start session cancel*/

  /* end session cancel */

  /* start edit lession*/
  const [modalsessioncreatestep3Show, setsessioncreatestep3ModalShow] =
    React.useState(false);
  const [timeToAcceptAlreadyPassed, setTimeToAcceptAlreadyPassed] =
    React.useState(false);

  const [timerValues, setTimerValues] = React.useState({
    remainingDays: 0,
    remainingHours: 0,
    remainingMinutes: 0,
  });
  const [timeToTheLesson, setTimeToTheLesson] = React.useState(0);
  const [modalType, setModalType] = React.useState(null);

  /* end edit lession */
  const params = useParams();
  const { activityTypeId, activityId } = params;

  const history = useHistory();

  const getActivityDetails = async () => {
    try {
      const response = await getSingalActivities(
        activityTypeId,
        activityId,
        false,
      );

      // response.creationTime is 24 hour format, we need to convert it to 12 hour format
      response.creationTime = convert12to24HrFormat(response.creationTime);

      // response.status = "AboutToStart"; // for testing, TODO:Remove this line
      // response.lessonDate = "03-10-2023"; //TODO:Remove this line
      // response.startTime = "10:12:00 PM"; // TODO remove this line

      setLessonDetail(response);

      let _currentDateTime = new Date();

      let createdAt = combineDateTime(
        response.creationDate,
        response.creationTime,
      );
      // creation time is in utc time, need to change to current time zone
      createdAt = addOffsetToTime(createdAt);

      // createdAt = new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000); // for testing, TODO:Remove this line
      console.log("createdAt", createdAt);

      const lessonStartAt = combineDateTime(
        response.lessonDate,
        response.startTime,
      );
      // _currentDateTime = new Date(lessonStartAt.getTime() - 60 * 60 * 1000); // for testing, TODO:Remove this line
      // last time of acceptance is night of the day before the lesson
      const _lastTimeOfAcceptance = new Date(
        convertDateToObject(response.lessonDate).getTime() - 1,
      );
      const _isLessonCreatedOnSameDay =
        _currentDateTime.getDate() === lessonStartAt.getDate();

      // first, lets check if the last time to accept has passed, means lesson day has started
      const _isLessonDayArrived = _currentDateTime > _lastTimeOfAcceptance;

      // now, check if 3 days has passed since the lesson was created

      const is3DaysPassed =
        _currentDateTime - createdAt > 3 * 24 * 60 * 60 * 1000;

      // if lesson is created on the same day, we need to check if 4 hours has passed since the lesson was created, status is Pending
      const timeBetweenNowAndLessonStart = lessonStartAt - _currentDateTime;

      const timeBetweenNowAndNightBeforeLessonStart =
        _lastTimeOfAcceptance - _currentDateTime;

      const timePassedSinceCreation = _currentDateTime - createdAt;

      const daysPassedSinceCreation = Math.floor(
        timePassedSinceCreation / (1000 * 60 * 60 * 24),
      );

      setTimeToTheLesson(timeBetweenNowAndLessonStart);

      // if day beore lesson start hasnt finished and 3 days has not passed since the lesson was created, and response.status is Pending, then the
      // student can accept the lesson

      // New: if lesson isnt created on same day, and 3 days havent passed, and day of lesson isnt reached

      if (
        !_isLessonCreatedOnSameDay &&
        !is3DaysPassed &&
        !_isLessonDayArrived &&
        response.status === "Pending"
      ) {
        console.log("__if block");
        setTimeToAcceptAlreadyPassed(false);
        let timeLeftToAccept;
        // if no day has passed since creation, and 3 or more days are remaining until night before lesson start, then student has 3 days - time passed since creation to accept the lesson
        if (
          daysPassedSinceCreation === 0 &&
          timeBetweenNowAndNightBeforeLessonStart > 3 * 24 * 60 * 60 * 1000
        ) {
          timeLeftToAccept = 3 * 24 * 60 * 60 * 1000 - timePassedSinceCreation;
        }
        // if no day has passed since creation and less than 3 days are remaining until night before lesson start,
        // then student has remaining time until night before lesson start to accept the lesson
        else if (
          daysPassedSinceCreation === 0 &&
          timeBetweenNowAndNightBeforeLessonStart < 3 * 24 * 60 * 60 * 1000
        ) {
          timeLeftToAccept = timeBetweenNowAndNightBeforeLessonStart;
        }
        // if 1 day has passed since creation, and 2 or more days are remaining until night before lesson start,
        // then student has 2 days to accept the lesson
        else if (
          daysPassedSinceCreation === 1 &&
          timeBetweenNowAndNightBeforeLessonStart > 2 * 24 * 60 * 60 * 1000
        ) {
          timeLeftToAccept = 2 * 24 * 60 * 60 * 1000 - timePassedSinceCreation;
        }
        // if 1 day has passed since creation, and less than 2 days are remaining until night before lesson start, then student has
        // remaining time until night before lesson start to accept the lesson
        else if (
          daysPassedSinceCreation === 1 &&
          timeBetweenNowAndNightBeforeLessonStart < 2 * 24 * 60 * 60 * 1000
        ) {
          timeLeftToAccept = timeBetweenNowAndNightBeforeLessonStart;
        }
        // if 2 days has passed since creation, and 1 or more days are remaining until night before lesson start, then student has 1 day to accept the lesson
        else if (
          daysPassedSinceCreation === 2 &&
          timeBetweenNowAndNightBeforeLessonStart > 1 * 24 * 60 * 60 * 1000
        ) {
          timeLeftToAccept = 1 * 24 * 60 * 60 * 1000 - timePassedSinceCreation;
        }
        // if 2 days has passed since creation, and less than 1 day is remaining until night before lesson start, then student has remaining time
        // until night before lesson start to accept the lesson
        else if (
          daysPassedSinceCreation === 2 &&
          timeBetweenNowAndNightBeforeLessonStart < 1 * 24 * 60 * 60 * 1000
        ) {
          timeLeftToAccept = timeBetweenNowAndNightBeforeLessonStart;
        }

        // if 3 days has passed since creation, then student has no time left to accept the lesson
        else if (daysPassedSinceCreation > 3) {
          timeLeftToAccept = 0;
        }

        console.log("timeLeftToAccept", timeLeftToAccept);

        const { days, hours, minutes } = convertmsToDHM(timeLeftToAccept);

        setTimerValues({
          remainingDays: days,
          remainingHours: hours,
          remainingMinutes: minutes,
        });
      } else if (
        _isLessonCreatedOnSameDay &&
        response.status === "Pending" &&
        timeBetweenNowAndLessonStart > 4 * 60 * 60 * 1000
      ) {
        console.log("__else_if block");
        // if lesson created on the same day, and more than 4 hours remaining until lesson start,
        //  student can accept it within startTime - 4 hours
        setTimeToAcceptAlreadyPassed(false);
        const timeRemaining = timeBetweenNowAndLessonStart - 4 * 60 * 60 * 1000;

        const { days, hours, minutes } = convertmsToDHM(timeRemaining);

        setTimerValues({
          remainingDays: days,
          remainingHours: hours,
          remainingMinutes: minutes,
        });
      } else if (response.status === "MissedDeadline") {
        console.log("__else_if block");
        setTimeToAcceptAlreadyPassed(true);
        setTimerValues({
          remainingDays: 0,
          remainingHours: 0,
          remainingMinutes: 0,
        });
      } else {
        console.log("__else block");
        const { days, hours, minutes } = convertmsToDHM(
          timeBetweenNowAndLessonStart,
        );

        setTimerValues({
          remainingDays: timeBetweenNowAndLessonStart > 0 ? days : 0,
          remainingHours: timeBetweenNowAndLessonStart > 0 ? hours : 0,
          remainingMinutes: timeBetweenNowAndLessonStart > 0 ? minutes : 0,
        });
      }

      console.log("Response : ", response);
    } catch (err) {
      console.log("Error : ", err);
    }
  };
  useEffect(() => {
    getActivityDetails();
  }, []);
  console.log("timerValues", timerValues);

  let headerText;
  if (lessonDetail.status === "MissedDeadline") {
    headerText = "Lesson is cancelled because of no response";
  } else if (lessonDetail.status === "Pending" && !timeToAcceptAlreadyPassed) {
    headerText = "Student should accept lesson in";
  } else if (lessonDetail.status === "New") {
    headerText = "Lesson will start in";
  } else if (lessonDetail.status === "InProgress") {
    headerText = "Lesson in progress";
  }

  const cancelLessonHandler = () => {
    setModalType(cancelLessonDialogs.CANCEL);
  };

  const cancelFn = async (cancelReasonId, otherCancelReason) => {
    try {
      await updateLessonStatus(lessonDetail.lessonId, 11, 0);
      setModalType(cancelLessonDialogs.CANCELCONFIRM);

      getActivityDetails();
    } catch (err) {
      console.log("err", err);
    }
  };

  var user = localStorage.getItem("user");
  var userInfo = JSON.parse(user);

  let currentUserName = userInfo.applicationUser.name;

  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderGames />
      {/* end header */}

      {modalType === cancelLessonDialogs.CANCEL && (
        <CancelDialog
          setModalType={setModalType}
          isImpactSmall={timeToTheLesson > 24 * 60 * 60 * 1000}
          status={lessonDetail?.status}
          cancelLessonHandler={cancelFn}
          role="educator"
        />
      )}
      {modalType === cancelLessonDialogs.REASON && (
        <ReasonDialog
          setModalType={setModalType}
          cancelLessonHandler={cancelFn}
          role="educator"
        />
      )}
      {modalType === cancelLessonDialogs.CANCELCONFIRM && (
        <ConfirmCancel setModalType={setModalType} role="educator" />
      )}

      <div className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs">
        <div className="cureent-lession-bx completelession-main">
          <div className="current-lesson-main ">
            <Container>
              <div className="current-lesson-contain">
                <div className="backbtn">
                  <Link to="/educator" className="backbtnn-icnn">
                    <img src={backorange} alt="" />
                  </Link>
                </div>
                <div className="current-lesson-topbr">
                  <div className="medm-tex orng-clr">
                    {lessonDetail?.lessonMode}
                  </div>
                  <h1>{lessonDetail?.title}</h1>
                  <div className="lesson-status-list">
                    <ul className="lesson-listing-curnt">
                      <li>
                        <div className="lesson-status-detail">
                          <span className="medm-tex orng-clr onelist">
                            Time:{" "}
                          </span>
                          <span className="medm-tex orng-clr twolist">
                            {lessonDetail?.startTime?.substring(
                              0,
                              lessonDetail?.startTime?.length - 6,
                            )}
                            {lessonDetail?.startTime?.substring(
                              lessonDetail?.startTime?.length - 2,
                            )}{" "}
                            {lessonDetail?.lessonDate}
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="lesson-status-detail">
                          <span className="medm-tex orng-clr onelist">
                            Status:{" "}
                          </span>
                          <span className="medm-tex orng-clr twolist">
                            {lessonDetail?.status}
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="lesson-status-detail">
                          <span className="medm-tex orng-clr onelist">
                            Rate:{" "}
                          </span>
                          <span className="medm-tex orng-clr twolist">
                            ${lessonDetail?.rate}
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Container>
          </div>
          <div className="lession-time-card">
            <Container>
              {lessonDetail.status !== "CancelledByStudent" &&
                lessonDetail.status !== "MissedDeadline" &&
                lessonDetail.status !== "Cancelled by Tutor" && (
                  <div className="lession-card-timebx">
                    <div className="lessontime-bottom">
                      <div className="lessonnew-cardbx">
                        <div className="newlesson-top">
                          <div className="bnewleson-heading">{headerText}</div>
                        </div>
                        <div className="newlesson-clock">
                          <TimerComponent
                            remainingDays={timerValues?.remainingDays}
                            remainingHours={timerValues?.remainingHours}
                            remainingMinutes={timerValues?.remainingMinutes}
                          />
                        </div>
                        <div className="btn-list-lesson">
                          <ul className="btn-listing-lesson">
                            <li>
                              <div className="btn-buy">
                                <div className="btn-week">
                                  <a href="#" className="btnweak-bx">
                                    <span
                                      className="btn-title"
                                      onClick={() =>
                                        setgamecreatestep1ModalShow(true)
                                      }
                                    >
                                      Edit the lesson
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </li>

                            {lessonDetail.status === "AboutToStart" ? (
                              <li className="whitebtn-tx">
                                <div className="btn-buy">
                                  <div className="btn-week">
                                    <p
                                      className="btnweak-bx"
                                      onClick={async () => {
                                        // call api to update status to waiting for student
                                        try {
                                          await updateLessonStatus(
                                            lessonDetail.lessonId,
                                            15,
                                            0,
                                          );

                                          let link = `/test-lesson?roomId=${lessonDetail.lessonId}&name=${currentUserName}&isTeacher=true&activityId=${lessonDetail.id}`;
                                          history.push(link);
                                        } catch (err) {
                                          console.log("err", err);
                                        }
                                      }}
                                    >
                                      <span className="btn-title">
                                        Start the lesson
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </li>
                            ) : (
                              <li className="whitebtn-tx">
                                <div className="btn-buy">
                                  <div className="btn-week">
                                    <p
                                      className="btnweak-bx"
                                      onClick={cancelLessonHandler}
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    >
                                      <span className="btn-title">
                                        Cancel the lesson
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </li>
                            )}
                          </ul>
                        </div>
                        <div className="newlesson-shape"></div>
                      </div>
                    </div>
                  </div>
                )}

              {(lessonDetail.status === "CancelledByStudent" ||
                lessonDetail.status === "MissedDeadline" ||
                lessonDetail.status === "Cancelled by Tutor") && (
                <div className="lessonCancelled">
                  <ul className="btn-listing-lesson">
                    <li>
                      <div className="btn-buy">
                        <div className="btn-week">
                          <p className="btnweak-bx">
                            <span className="btn-title">
                              Create new live lesson
                            </span>
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="whitebtn-tx">
                      <div className="btn-buy">
                        <div className="btn-week">
                          <p
                            className="btnweak-bx"
                            style={{
                              backgroundColor: "#632CAF",
                              cursor: "pointer",
                              color: "white",
                            }}
                          >
                            <span className="btn-title">
                              Chat with a student
                            </span>
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              )}
            </Container>
          </div>

          <div className="pagination-lession">
            <Container>
              <div className="pagination-lession-inner">
                <ul>
                  <li className="one">
                    <Link to="#" className="pagination-btn">
                      <span className="iconn">
                        <i className="fas fa-angle-left"></i>
                      </span>
                      <span className="tex-lesn">Previous lesson</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </Container>
          </div>
        </div>

        {/* footer start */}
        <FooterEducator logo={logo} />
        {/* footer end */}
      </div>

      {/* start modal my student */}

      {/* start game save step1 */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        // {...props}
        show={modalgamesavedstep1Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2 mystudent-games">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>The game is saved</h4>
              <h5>Send the game to my students</h5>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setgamesavedstep1ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="serch-inner-group">
                    <div className="serchfiled-activities">
                      <Form.Control
                        type="text"
                        placeholder="Start typing name or year of study"
                        className="formbox"
                      />
                      <Button type="button" className="seachbtn">
                        <i className="fas fa-search"></i>
                      </Button>
                    </div>
                  </div>

                  <div className="newpupil-list-bx">
                    <Form.Group className="subsform-fild child-tex-fld height-max-flow">
                      <div className="child-boxnew">
                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Aaradhya Rayasam</h5>
                                  <h6>India, Mumbai 1 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Chandrashekhar Kalvakuntla</h5>
                                  <h6>India, Mumbai 3 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>7F Class</h5>
                                  <h6>Hamut Kapit, Kemal Mara and 12 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Additional group</h5>
                                  <h6>Laska Doe, Hamut Kapit and 4 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>8A Class for exams</h5>
                                  <h6>Hamut Kapit, Laska Doe and 8 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Chandrashekhar Kalvakuntla</h5>
                                  <h6>India, Mumbai 3 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </Form.Group>

                <div className="student-btn-savedgame">
                  <div className="btnlogin-main">
                    <button type="submit" className="btnlogin btnweak-bx">
                      Send to selected students
                    </button>
                  </div>
                </div>
                <div className="btnlogin-main">
                  <button type="submit" className="btnlogin btnweak-bx">
                    Later
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end Add a Member Group step2 */}

      {/* end modal my student */}

      {/* start create a new games */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        // {...props}
        show={modalgamecreatestep1Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2 mystudent-games edit-livelessn">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Edit the live lesson</h4>
              <p>
                Student should confirm changes in the lesson before it will
                update
              </p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setgamecreatestep1ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="newgames-createbx">
                    <div className="frm-gameflw-nw">
                      <Form.Label>
                        Title of the lesson for useful searching someth...
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Type the topic or what you will do"
                      ></Form.Control>
                    </div>

                    <div className="frm-gameflw-nw">
                      <Form.Label>Date of the lesson</Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="2 Sep 2021"
                      ></Form.Control>
                    </div>
                    <div className="frm-gameflw-nw">
                      <Form.Label>Time of the lesson</Form.Label>
                      <TextField
                        fullWidth
                        id="time"
                        type="time"
                        // onChange={(e) => {
                        //   //console.log(e.target.value);
                        //   setstate((state) => ({
                        //     ...state,
                        //     timeOfLesson: e.target.value,
                        //   }));
                        // }}
                        // value={state.timeOfLesson}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                        sx={{
                          input: {
                            color: "var(--purple)",
                            fontWeight: "600",
                          },
                          backgroundColor: "#F0EFFC",
                          borderRadius: "10px",
                          fieldset: {
                            border: "none",
                          },
                        }}
                      />
                    </div>
                    <div className="frm-gameflw-nw">
                      <Form.Label>Comment for student</Form.Label>
                      <Form.Control
                        placeholder="Type here"
                        as="textarea"
                      ></Form.Control>
                    </div>
                    {/* <div
                      className="frm-gameflw-nw"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                        marginTop: "1rem",
                        rowGap: "1rem",
                      }}
                    >
                      <label>Other reasons</label>
                      <textarea
                        style={{
                          resize: "none",
                          width: "100%",
                          padding: "0.3rem",
                          borderRadius: "5px",
                        }}
                        rows={4}
                        placeholder="Type here"
                      />
                    </div> */}
                  </div>
                </Form.Group>

                <div className="btnlogin-main">
                  <Link
                    to="#"
                    className="btnlogin btnweak-bx"
                    onClick={() => {
                      setsessioncreatestep1ModalShow(true);
                      setgamecreatestep1ModalShow(false);
                    }}
                  >
                    Edit the lesson
                  </Link>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end create a new games */}

      {/* start create a new games */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        // {...props}
        show={modalsessioncreatestep1Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2 mystudent-games session-created">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Live lesson was edited</h4>
              <p>
                Notice that the student should confirm changes in the lesson.
                Changes without confirmation will be cancelled in 3 days
              </p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setsessioncreatestep1ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <div className="btnlogin-main">
                  <Link
                    to="#"
                    className="btnlogin btnweak-bx"
                    onClick={() => setsessioncreatestep1ModalShow(false)}
                  >
                    Thanks, i understood
                  </Link>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end create a new games */}

      {/* start create a new games */}

      {/* end create a new games */}

      {/* start lession edit */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        // {...props}
        show={modalsessioncreatestep3Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2 mystudent-games session-created">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Live lesson was cancelled</h4>
              <p>
                Student will be notified about cancellation of the lesson. Your
                rating will be revised.
              </p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setsessioncreatestep3ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <div className="btnlogin-main">
                  <Link to="/educator" className="btnlogin btnweak-bx">
                    I understood
                  </Link>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end lession edit */}
    </div>
  );
}

export default LiveLessonDetail;
