import React, { useEffect } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Typography } from "@mui/material";

const TestGame = () => {
  const { unityProvider, isLoaded, loadingProgression, sendMessage } =
    useUnityContext({
      loaderUrl: "buildUnity/WebGL-Crosswords.loader.js",
      dataUrl: "buildUnity/WebGL-Crosswords.data.unityweb",
      frameworkUrl: "buildUnity/WebGL-Crosswords.framework.js.unityweb",
      codeUrl: "buildUnity/WebGL-Crosswords.wasm.unityweb",
    });

  // get query params
  const urlParams = new URLSearchParams(window.location.search);
  const isGame = urlParams.get("isGame");
  const Gametype = urlParams.get("GameType");
  const flow = urlParams.get("flow");
  const Token = urlParams.get("Token");
  const CrosswordLevel = urlParams.get("CrosswordLevel");
  const Role = urlParams.get("Role");
  const ActivityDetails = urlParams.get("ActivityDetails");

  const jsonObjectString = JSON.stringify({
    isGame: isGame,
    GameType: Gametype,
    flow: flow,
    CrosswordLevel: CrosswordLevel,
    Role: Role,
    ActivityDetails: ActivityDetails,
    Token: Token,
  });
  console.log("jsonObjectString", jsonObjectString);
  // if game is loaded, send message to unity
  useEffect(() => {
    if (isLoaded) {
      sendMessage("GameSessionManager", "HandleReactMessage", jsonObjectString);
    }
  }, [isLoaded]);

  console.log("loadingProgression", loadingProgression);

  return (
    <div>
      {loadingProgression !== 1 && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <CircularProgress
            variant="determinate"
            value={loadingProgression * 100}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="caption"
              component="div"
              color="text.secondary"
            >
              {`${Math.round(loadingProgression * 100)}%`}
            </Typography>
          </Box>
        </Box>
      )}

      <Unity
        unityProvider={unityProvider}
        style={{
          width: "100vw",
          height: "100vh",
        }}
      />
    </div>
  );
};

export default TestGame;
