import { IconButton } from "@mui/material";
import React from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "./personalRewardInfo.scss";
import { useHistory } from "react-router";

export default function PersonalRewardInfo() {

  const history = useHistory();

  return (
    <div className="d-flex justify-content-between personal-reaward-info">
      <div>
        <IconButton onClick={()=> history.goBack()}>
          <KeyboardBackspaceIcon fontSize="large" sx={{ color: "#FF7A00" }} />
        </IconButton>
      </div>
      <div className="text-white">
        <h1 className="heading">Personal reward system</h1>
        <p className="details">
          We present gifs for points that you collected! You can see the number
          of your points on <br /> the header in the right side. When number
          reaches next level you receive gifts from us! <br /> So it’s in your
          hands! Earn more points in games and receive more gifts!
        </p>
        <div className="current-points">
          Current personal points <strong>12</strong>
        </div>
      </div>
      <div />
    </div>
  );
}
