import React from "react";
import "./home.scss";
import "./profile.scss";
import "./media.scss";
import "./games.scss";
import "./loginModal/login.scss";
import "./messages/messages.scss";
import "./myactivities.scss";
import "./gamestabs.scss";
import "./gamesflowphotos.scss";
import "./selectedgames.scss";
import {
  Container,
  Button,
  Form,
  Modal,
} from "react-bootstrap";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import logo from "../assets/images/logo.png";
// import gamesgalery1 from "../assets/images/games-galery1.jpg";
// import gamesgalery2 from "../assets/images/games-galery2.jpg";
// import chaticon from "../assets/images/chat-icon.svg";
// import batchorange from "../assets/images/batch-orange.svg";
// import palinblue from "../assets/images/palin-blue.svg";
// import mike from "../assets/images/mike.svg";
// import attachemtnblue from "../assets/images/attachemtn-blue.svg";
// import downarrowblue from "../assets/images/down-arrow-blue.svg";
// import dotthree from "../assets/images/dotthree.svg";
// import addbtnround from "../assets/images/add-btn.svg";
// import darkblueplan from "../assets/images/darkblue-plan.svg";
import backorange from "../assets/images/backorange.svg";
import editorangelg from "../assets/images/editlg-orange.svg";

// import Slider from "react-slick";
// import SliderCenter from "./Slider";
import { Link } from "react-router-dom";
import FooterEducator from "./layout/Footer/FooterEducator";
// import Addgroup from "./Addgroup";
// import Addpupil from "./Addpupil";
// import SendInvite from "./SendInvite";
// import Groupmember from "./Groupmember";
import HeaderGames from "./layout/Header/HeaderGames";

function Selectedgames() {
  // const [show, setShow] = React.useState("1");

  // const [breadMenuShow, setBreadMenuShow] = React.useState(false);
  /* start modal */
  const [modalShow, setModalShow] = React.useState(false);
  /* end modal */

  /* startsave gamed step1 */
  const [modalgamesavedstep1Show, setgamesavedstep1ModalShow] =
    React.useState(false);

  /* end save gamned step1 */

  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderGames setModalShow={(value) => setModalShow(value)} />
      {/* end header */}

      <div className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs">
        <div className="games-photosynths gamesflowtwo">
          <Container>
            <div className="games-photosth-main">
              <div className="games-photsth-header">
                <h1>Photosynthesis</h1>
                <Link to="Gamesflowphotos" className="backbtn-lg">
                  <img src={backorange} alt=""/>
                </Link>
                <Link to="#" className="editbtn-lg">
                  <img src={editorangelg} alt=""/>
                </Link>
              </div>
              <div className="photosty-games-crd">
                <div className="photosty-inercrd">
                  <div className="iner-photosth-conent">
                    <p>
                      Description of the game lorem ipsum dolor sit amet,
                      consectetur adipiscing elit, sed do eiusmod tempor
                      incididunt ut labore et dolore magna aliqua. Ut enim ad
                      minim veniam, quis nostrud exercitation ullamco laboris
                      nisi ut aliquip ex ea commodo consequat. Duis aute irure
                      dolor in reprehenderit in voluptate velit esse.
                    </p>
                  </div>
                  <div className="gameflow-photosth-play odd-num-photosth">
                    <div className="flow-listview">
                      <div className="flowlisting-view">
                        <ul className="quizflow-list">
                          <li className="active">
                            <div className="flowgame-phtos-card">
                              <div className="listin-quizflow">
                                <div className="quixlok">
                                  <span className="lockquiz-icon"></span>
                                </div>
                              </div>
                              <div className="flw-quiz-content">
                                <div className="sml-tex">Quiz</div>
                                <div className="medm-tex">Flowers</div>
                              </div>
                            </div>
                          </li>

                          <li className="active">
                            <div className="flowgame-phtos-card">
                              <div className="flw-quiz-content">
                                <div className="sml-tex">Crossword</div>
                                <div className="medm-tex">
                                  Photosynthesis <br></br> and flat plants
                                </div>
                              </div>
                              <div className="listin-quizflow">
                                <div className="quixlok">
                                  <span className="lockquiz-icon"></span>
                                </div>
                              </div>
                            </div>
                          </li>

                          <li className="active">
                            <div className="flowgame-phtos-card">
                              <div className="listin-quizflow">
                                <div className="quixlok">
                                  <span className="lockquiz-icon"></span>
                                </div>
                              </div>
                              <div className="flw-quiz-content">
                                <div className="sml-tex">Wordsearch</div>
                                <div className="medm-tex">
                                  South Africa and Photosynthesis
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="active">
                            <div className="flowgame-phtos-card">
                              <div className="flw-quiz-content">
                                <div className="sml-tex">Crossword</div>
                                <div className="medm-tex">
                                  Elements takes part in the process{" "}
                                </div>
                              </div>
                              <div className="listin-quizflow">
                                <div className="quixlok">
                                  <span className="lockquiz-icon"></span>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="active">
                            <div className="flowgame-phtos-card">
                              <div className="listin-quizflow">
                                <div className="quixlok">
                                  <span className="lockquiz-icon"></span>
                                </div>
                              </div>
                              <div className="flw-quiz-content">
                                <div className="sml-tex">Quiz</div>
                                <div className="medm-tex">
                                  General test, topic Photosynthesis{" "}
                                </div>
                              </div>
                            </div>
                          </li>

                          <li>
                            <div className="listin-quizflow">
                              <div className="quixlok">
                                <span className="lockquiz-icon">
                                  <i className="fas fa-plus"></i>
                                </span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>

                      <div className="flowgame-btnn">
                        <ul className="gameflw-save">
                          <li>
                            <div className="btn-buy gamestart-btnn">
                              <div className="btn-week">
                                <a href="#" className="btnweak-bx">
                                  <span className="btn-title">Preview</span>
                                </a>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="btn-buy gamestart-btnn">
                              <div className="btn-week">
                                <a href="#" className="btnweak-bx">
                                  <span
                                    className="btn-title"
                                    onClick={() =>
                                      setgamesavedstep1ModalShow(true)
                                    }
                                  >
                                    Save the gameflow
                                  </span>
                                </a>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>

        {/* footer start */}
        <FooterEducator logo={logo} />
        {/* footer end */}
      </div>

      {/* start modal my student */}

      {/* start game save step1 */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        // {...props}
        show={modalgamesavedstep1Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2 mystudent-games">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>The game is saved</h4>
              <h5>Send the game to my students</h5>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setgamesavedstep1ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="serch-inner-group">
                    <div className="serchfiled-activities">
                      <Form.Control
                        type="text"
                        placeholder="Start typing name or year of study"
                        className="formbox"
                      />
                      <Button type="button" className="seachbtn">
                        <i className="fas fa-search"></i>
                      </Button>
                    </div>
                  </div>

                  <div className="newpupil-list-bx">
                    <Form.Group className="subsform-fild child-tex-fld height-max-flow">
                      <div className="child-boxnew">
                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Aaradhya Rayasam</h5>
                                  <h6>India, Mumbai 1 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Chandrashekhar Kalvakuntla</h5>
                                  <h6>India, Mumbai 3 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>7F Class</h5>
                                  <h6>Hamut Kapit, Kemal Mara and 12 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Additional group</h5>
                                  <h6>Laska Doe, Hamut Kapit and 4 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>8A Class for exams</h5>
                                  <h6>Hamut Kapit, Laska Doe and 8 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Chandrashekhar Kalvakuntla</h5>
                                  <h6>India, Mumbai 3 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </Form.Group>

                <div className="student-btn-savedgame">
                  <div className="btnlogin-main">
                    <button type="submit" className="btnlogin btnweak-bx">
                      Send to selected students
                    </button>
                  </div>
                </div>
                <div className="btnlogin-main">
                  <button type="submit" className="btnlogin btnweak-bx">
                    Later
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end Add a Member Group step2 */}

      {/* end modal my student */}
    </div>
  );
}

export default Selectedgames;
