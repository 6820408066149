import React, { useContext, useEffect, useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./loginModal/login.scss";
import {
  getEducatorGroups,
  getEducatorStudents,
} from "../service/apis/educator";
import { testSendOne } from "../service/apis/activities";
import { NotificationContext } from "../contexts/Notification";

const Selectstudent = ({
  setAddgroupstep2ModalShow,
  modalAddgroupstep2Show,
  formData,
}) => {
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [students, setStudents] = useState([]);
  const [search, setSearch] = useState("");
  const [state, setstate] = useState({
    addMembersSearchQuery: "",
    selectedStudents: [],
    unselectedStudents: [],
  });

  const { showNotification } = useContext(NotificationContext);

  console.log(state.selectedStudents);
  useEffect(() => {
    const getEducatorStusent = async () => {
      const response = await getEducatorStudents();
      await getEducatorGroups();
      setStudents(response);
    };
    getEducatorStusent();
  }, []);

  const selectStudent = (studentId) => {
    if (state.selectedStudents.includes(studentId)) {
      setstate((state) => ({
        ...state,
        selectedStudents: state.selectedStudents.filter(
          (id) => id !== studentId,
        ),
      }));
    } else {
      setstate((state) => ({
        ...state,
        selectedStudents: [...state.selectedStudents, studentId],
      }));
    }
  };

  console.log(currentUser);
  const sendMitilelpall = async () => {
    if (state.selectedStudents.length !== 0) {
      const responce = await testSendOne(formData, state.selectedStudents);
      if (responce) {
        setAddgroupstep2ModalShow(false);
        showNotification("Activity successfully sent");
      }
    }
  };

  // console.log(students)
  // console.log(state.selectedStudents)

  return (
    <div className="add-pupil-mainbx">
      {/* start Add a Groupmember step2 */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        show={modalAddgroupstep2Show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Title of the game</h4>
              <p>Send to my students</p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setAddgroupstep2ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="serch-inner-group">
                    <div className="serchfiled-activities">
                      <Form.Control
                        type="text"
                        placeholder="Start typing name of study"
                        className="formbox"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <Button type="button" className="seachbtn">
                        <i className="fas fa-search"></i>
                      </Button>
                    </div>
                  </div>

                  <div className="newpupil-list-bx">
                    <Form.Group className="subsform-fild child-tex-fld height-max-flow">
                      <div className="child-boxnew">
                        {students
                          .filter((item) => {
                            return search.toLocaleLowerCase() === ""
                              ? item
                              : item.name.toLocaleLowerCase().includes(search);
                          })
                          .map((student, i) => {
                            const isSelected = Boolean(
                              state.selectedStudents.find(
                                (grpStud) => grpStud === student.studentId,
                              ),
                            );
                            return (
                              <div key={i} className="child-account">
                                <div className="activ-detail-list">
                                  <label className="custm-check">
                                    <Form.Control
                                      checked={isSelected}
                                      onChange={() => {
                                        selectStudent(student.studentId);
                                      }}
                                      type="checkbox"
                                    />
                                    <span className="checkmark"></span>
                                    <div className="active-detail-row">
                                      <div className="activiti-prof-icon">
                                        <span className="profile-icon-box">
                                          <i className="fas fa-user"></i>
                                        </span>
                                      </div>
                                      <div className="activiti-center-detail">
                                        <h5>{student.name}</h5>
                                        <h6>
                                          {student.location}{" "}
                                          {student?.yearsOfStudy?.slice(0, 1)}{" "}
                                          year of study
                                        </h6>
                                      </div>
                                    </div>
                                  </label>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </Form.Group>
                  </div>
                </Form.Group>

                <div className="btnlogin-main">
                  <Link
                    to="#"
                    onClick={() => sendMitilelpall()}
                    className="btnlogin btnweak-bx"
                  >
                    Send to selected students
                  </Link>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      {/* end Add a Member Group step2 */}
    </div>
  );
};

export default Selectstudent;
