import React from "react";
import "./home.scss";
import "./profile.scss";
import "./media.scss";
import "./games.scss";
import "./loginModal/login.scss";
import "./messages/messages.scss";
import "./myactivities.scss";
import "./gamestabs.scss";
import "./gamesflowphotos.scss";
import "./selectedgames.scss";
import "./questionone.scss";
import "./creatingmaterial.scss";
import "./Currentlesson.scss";
import {
  // Navbar,
  // Nav,
  // NavDropdown,
  Container,
  // Dropdown,
  // br,
  // Row,
  // Accordion,
  // Card,
  Button,
  Form,
  Modal,
  // Select,
  // Sonnet,
} from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import logo from "../assets/images/logo.png";
// import gamesgalery1 from "../assets/images/games-galery1.jpg";
// import gamesgalery2 from "../assets/images/games-galery2.jpg";
// import chaticon from "../assets/images/chat-icon.svg";
// import batchorange from "../assets/images/batch-orange.svg";
// import palinblue from "../assets/images/palin-blue.svg";
// import mike from "../assets/images/mike.svg";
// import attachemtnblue from "../assets/images/attachemtn-blue.svg";
// import downarrowblue from "../assets/images/down-arrow-blue.svg";
// import dotthree from "../assets/images/dotthree.svg";
// import addbtnround from "../assets/images/add-btn.svg";
// import darkblueplan from "../assets/images/darkblue-plan.svg";
import backorange from "../assets/images/backorange.svg";
// import editorangelg from "../assets/images/editlg-orange.svg";
// import placeholder from "../assets/images/placeholder.jpg";
// import lessonpic1 from "../assets/images/lesson-pic1.jpg";
// import lessonpic2 from "../assets/images/lesson-pic2.jpg";
// import lessonpic3 from "../assets/images/lesson-pic3.jpg";
// import lessonpic4 from "../assets/images/lesson-pic4.jpg";
// import lessonpic5 from "../assets/images/lesson-pic5.jpg";
// import attachment from "../assets/images/attachement.svg";
// import plain from "../assets/images/plain.svg";
// import playvd from "../assets/images/play-vd.png";

// import Slider from "react-slick";
// import SliderCenter from "./Slider";
import { Link } from "react-router-dom";
import FooterEducator from "./layout/Footer/FooterEducator";
// import Addgroup from "./Addgroup";
// import Addpupil from "./Addpupil";
// import SendInvite from "./SendInvite";
// import Groupmember from "./Groupmember";
import HeaderGames from "./layout/Header/HeaderGames";
import { CreateLiveLessonModal } from "./liveLessons/dialogs/CreateLiveLesson";

function Newlivelession() {
  // const [show, setShow] = React.useState("1");

  // const [breadMenuShow, setBreadMenuShow] = React.useState(false);
  /* start modal */
  const [modalShow, setModalShow] = React.useState(false);
  /* end modal */

  /* startsave gamed step1 */
  const [modalgamesavedstep1Show, setgamesavedstep1ModalShow] =
    React.useState(false);

  /* end save gamned step1 */

  /* start create a new games*/
  const [modalgamecreatestep1Show, setgamecreatestep1ModalShow] =
    React.useState(false);

  /* end create a new games */

  /* start session create*/
  const [modalsessioncreatestep1Show, setsessioncreatestep1ModalShow] =
    React.useState(false);

  /* end session create */

  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderGames setModalShow={(value) => setModalShow(value)} />
      {/* end header */}

      <div className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs">
        <div className="cureent-lession-bx completelession-main">
          <div className="current-lesson-main ">
            <Container>
              <div className="current-lesson-contain">
                <div className="backbtn">
                  <Link to="educator" className="backbtnn-icnn">
                    <img src={backorange} alt="" />
                  </Link>
                </div>
                <div className="current-lesson-topbr">
                  <div className="medm-tex orng-clr">Live lesson</div>
                  <h1>Law basics. Beginner concepts</h1>
                  <div className="lesson-status-list">
                    <ul className="lesson-listing-curnt">
                      <li>
                        <div className="lesson-status-detail">
                          <span className="medm-tex orng-clr onelist">
                            Time:{" "}
                          </span>
                          <span className="medm-tex orng-clr twolist">
                            Oct 25, 2020
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="lesson-status-detail">
                          <span className="medm-tex orng-clr onelist">
                            Status:{" "}
                          </span>
                          <span className="medm-tex orng-clr twolist">
                            Cancelled by student
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="lesson-status-detail">
                          <span className="medm-tex orng-clr onelist">
                            Earned:{" "}
                          </span>
                          <span className="medm-tex orng-clr twolist">$0</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Container>
          </div>

          <div className="lession-create-card">
            <Container>
              <div className="livelession-btn">
                <div className="btnlession-live">
                  <div className="btn-week">
                    <Link
                      to="#"
                      className="btnweak-bx"
                      onClick={() => setgamecreatestep1ModalShow(true)}
                    >
                      <span className="btn-title">Create new live lesson</span>
                    </Link>
                  </div>
                </div>

                <div className="btnlession-live chatbtn-two">
                  <div className="btn-week">
                    <Link to="Studentchat" className="btnweak-bx">
                      <span className="btn-title">Chat with a student</span>
                    </Link>
                  </div>
                </div>
              </div>
            </Container>
          </div>

          <div className="pagination-lession">
            <Container>
              <div className="pagination-lession-inner">
                <ul>
                  <li className="one">
                    <Link to="#" className="pagination-btn">
                      <span className="iconn">
                        <i className="fas fa-angle-left"></i>
                      </span>
                      <span className="tex-lesn">Previous lesson</span>
                    </Link>
                  </li>
                  <li className="two">
                    <Link to="#" className="pagination-btn">
                      <span className="tex-lesn">Next lesson</span>{" "}
                      <span className="iconn">
                        <i className="fas fa-angle-right"></i>
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </Container>
          </div>
        </div>

        {/* footer start */}
        <FooterEducator logo={logo} />
        {/* footer end */}
      </div>

      {/* start modal my student */}

      {/* start game save step1 */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        // {...props}
        show={modalgamesavedstep1Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2 mystudent-games">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>The game is saved</h4>
              <h5>Send the game to my students</h5>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setgamesavedstep1ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="serch-inner-group">
                    <div className="serchfiled-activities">
                      <Form.Control
                        type="text"
                        placeholder="Start typing name or year of study"
                        className="formbox"
                      />
                      <Button type="button" className="seachbtn">
                        <i className="fas fa-search"></i>
                      </Button>
                    </div>
                  </div>

                  <div className="newpupil-list-bx">
                    <Form.Group className="subsform-fild child-tex-fld height-max-flow">
                      <div className="child-boxnew">
                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Aaradhya Rayasam</h5>
                                  <h6>India, Mumbai 1 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Chandrashekhar Kalvakuntla</h5>
                                  <h6>India, Mumbai 3 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>7F Class</h5>
                                  <h6>Hamut Kapit, Kemal Mara and 12 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Additional group</h5>
                                  <h6>Laska Doe, Hamut Kapit and 4 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>8A Class for exams</h5>
                                  <h6>Hamut Kapit, Laska Doe and 8 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Chandrashekhar Kalvakuntla</h5>
                                  <h6>India, Mumbai 3 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </Form.Group>

                <div className="student-btn-savedgame">
                  <div className="btnlogin-main">
                    <button type="submit" className="btnlogin btnweak-bx">
                      Send to selected students
                    </button>
                  </div>
                </div>
                <div className="btnlogin-main">
                  <button type="submit" className="btnlogin btnweak-bx">
                    Later
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end Add a Member Group step2 */}

      {/* end modal my student */}

      {/* start create a new games */}
      {modalgamecreatestep1Show && (
        <CreateLiveLessonModal
          closeHandler={() => setgamecreatestep1ModalShow(false)}
          createBtnHandler={() => {
            setsessioncreatestep1ModalShow(true);
            setgamecreatestep1ModalShow(false);
          }}
        />
      )}

      {/* end create a new games */}

      {/* start create a new games */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        // {...props}
        show={modalsessioncreatestep1Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2 mystudent-games session-created">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Live lesson was created</h4>
              <p>
                Notice that the student should confirm the lesson. Lessons
                without confirmation aren’t active and will be cancelled in 3
                days
              </p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setsessioncreatestep1ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <div className="btnlogin-main">
                  <Link to="LiveLessonDetail" className="btnlogin btnweak-bx">
                    Thanks, i understood
                  </Link>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end create a new games */}
    </div>
  );
}

export default Newlivelession;
