import React from "react";
import "./article.scss";
import {
  Navbar,
  Nav,
  Container,
  Dropdown,
  Row,
  Form,
  Spinner,
} from "react-bootstrap";
import logo from "../../assets/images/logo.png";
import like from "../../assets/images/thumb.svg";
import share from "../../assets/images/share.svg";
import facebook from "../../assets/images/facebook.svg";
import linkedin from "../../assets/images/linkedin.svg";
import google from "../../assets/images/google-plus.svg";
import user from "../../assets/images/img-vector.svg";
import userimg from "../../assets/images/user-img.png";
import tutorail1 from "../../assets/images/tutorail1.png";
import { Link, useParams, useHistory } from "react-router-dom";
import { getBlogDetail } from "../../service/AllApi";
function Article() {
  const [isLoading, setisLoading] = React.useState(false);
  const [detail, setDetail] = React.useState({});
  const history = useHistory();
  const params = useParams();
  React.useEffect(() => {
    const fetch = async () => {
      setisLoading(true);
      const result = await getBlogDetail({ id: params?.id });
      setisLoading(false);
      setDetail(result);
    };
    fetch();
  }, []);
  return (
    <div className="article-wrapper">
      <div className="inner-page-topbar">
        <div className="custome-container">
          <div className="header-holder navbar-top">
            <Navbar bg="light" expand="lg">
              <Navbar.Brand href="/">
                <img src={logo} className="img-fluid" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />

              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <li>
                    {" "}
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/tutor">Teachers</Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/blog" className="active">
                      Blog
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/contact">Contact</Link>
                  </li>
                  <div className="mobileview-show btn-getstarted">
                    <div className="btn-bx-get">
                      <a href="#" className="get-started">
                        <span className="btntex">Get started</span>{" "}
                      </a>
                    </div>
                  </div>
                </Nav>
              </Navbar.Collapse>

              <ul className="header-btn-top">
                <li className="btn-bx-get desktop-show">
                  <a href="#" className="get-started">
                    <span className="btntex">Get started</span>{" "}
                  </a>
                </li>
                <li className="btn-login-bx">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="login-btn"
                    >
                      <i className="fas fa-user"></i>
                    </Dropdown.Toggle>
                  </Dropdown>
                </li>
              </ul>
            </Navbar>
          </div>
        </div>
      </div>
      {isLoading ? (
        <Spinner animation="border" variant="warning" />
      ) : detail ? (
        <div className="articlesection">
          <Container>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb common-navs">
                <li
                  onClick={() => history.push("/")}
                  style={{ cursor: "pointer" }}
                  className="breadcrumb-item"
                >
                  <Link to="#">Home</Link>
                </li>
                <li
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push("/blog")}
                  className="breadcrumb-item"
                >
                  <Link to="#">Blog</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {detail.title}
                </li>
              </ol>
            </nav>

            <div className="article-details">
              <Row>
                <div className="col-md-4">
                  <div className="article-leftpic">
                    <div className="picard">
                      <img src={detail?.image} className="img-fluid" />
                    </div>
                  </div>
                </div>

                <div className="col-md-8">
                  <div className="article-text">
                    <span className="small-tag">for {detail?.keywords}</span>
                    <div className="title-main">{detail?.title}</div>
                    <div className="date-section">
                      {new Date(detail?.createdOn).toDateString()}
                    </div>
                    <p
                      className="small-tex"
                      dangerouslySetInnerHTML={{
                        __html: `${detail?.description}`,
                      }}
                    ></p>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="article-section-two">
                    <p
                      className="small-tex"
                      dangerouslySetInnerHTML={{
                        __html: `${detail?.description?.substr(900)}...`,
                      }}
                    ></p>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="share-bar">
                    <ul>
                      <li>
                        {" "}
                        <span className="icon-img">
                          {" "}
                          <img src={like} className="img-fluid" />{" "}
                        </span>{" "}
                        <span>38</span>{" "}
                      </li>
                      <li>
                        {" "}
                        <span className="icon-img">
                          {" "}
                          <img src={share} className="img-fluid" />{" "}
                        </span>{" "}
                        <span>Share</span>{" "}
                      </li>
                      <li>
                        <div className="social-media">
                          <Link to="#">
                            <img src={google} className="img-fluid" />
                          </Link>
                          <Link to="#">
                            <img src={facebook} className="img-fluid" />
                          </Link>
                          <Link to="#">
                            <img src={linkedin} className="img-fluid" />
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </Row>
            </div>

            <div className="comment-box-main">
              <div className="comment-box">
                <div className="heading-text">Heading the second small</div>
                <div className="type-comment">
                  <div className="in-user">
                    <img src={user} className="img-fluid" />
                  </div>

                  <div className="textbox-holder">
                    <Form.Control
                      type="text"
                      placeholder="Add a comment..."
                      className="type-txt"
                    />
                    <div className="post-check">
                      <p>
                        {" "}
                        <Form.Check type="checkbox" label="Check me out" /> Post
                        to Facebook{" "}
                      </p>
                      <p className="align-right">
                        Posting as <strong>Terry Rossire</strong>{" "}
                        <Link to="#">(Change)</Link>
                      </p>
                    </div>
                  </div>

                  <div className="button-holder">
                    {" "}
                    <button type="button">Comment</button>{" "}
                  </div>
                </div>

                <div className="user-comment-section">
                  <div className="user-img">
                    {" "}
                    <img src={userimg} className="img-fluid" />
                  </div>
                  <div className="user-content">
                    <div className="user-name">
                      Terry Rossire <span>UCLA</span>{" "}
                    </div>
                    <p className="comment-text">
                      Vis at prompta comprehensam, quas forensibus ea qui.
                    </p>
                    <div className="like-section">
                      <ul>
                        <li>
                          <Link to="#">Like</Link>
                        </li>
                        <li>
                          <Link to="#">Replay</Link>
                        </li>
                        <li>2 minutes ago</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      ) : (
        "Error to fetch blog"
      )}

      <div className="testimaonial-section subscribe-blog">
        <Container>
          <div className="interest-section">
            <div className="intersting-main">
              <div className="heading-top">
                <h4 className="title-medium">
                  It might be interesting for you
                </h4>
              </div>

              <div className="intersting-card">
                <Row>
                  <div className="col-md-6 one">
                    <div className="cardsection-int">
                      <div className="custom-row">
                        <div className="left-cardint">
                          <div className="cardpic-int">
                            <img src={tutorail1} className="img-fluid" />
                          </div>
                        </div>
                        <div className="right-cardint">
                          <div className="inti-content">
                            <div className="tagbtn">
                              <span className="tagname">for Tutors</span>
                            </div>
                            <h4>Amazing title of the article in the blog</h4>
                            <p className="small-tex">
                              Short text in the beging of the main article text
                              to
                            </p>
                            <div className="date-inti">October 05, 2020</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 one">
                    <div className="cardsection-int">
                      <div className="custom-row">
                        <div className="left-cardint">
                          <div className="cardpic-int">
                            <img src={tutorail1} className="img-fluid" />
                          </div>
                        </div>
                        <div className="right-cardint">
                          <div className="inti-content">
                            <div className="tagbtn">
                              <span className="tagname">for Tutors</span>
                            </div>
                            <h4>Amazing title of the article in the blog</h4>
                            <p className="small-tex">
                              Short text in the beging of the main article text
                              to
                            </p>
                            <div className="date-inti">October 05, 2020</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
            </div>
          </div>
        </Container>

        <div className="subs-blog">
          <Container>
            <div className="subscribe-bxx">
              <div className="communty-box">
                <div className="communty-section">
                  <div className="heading-top">
                    <h4 className="title-medium">
                      Subscribe to our blog now pot
                    </h4>
                  </div>
                  <div className="communtiy-content">
                    <p className="small-tex">
                      We guarantee antispam! Only the most interesting materials
                      and news! And discounts!
                    </p>

                    <div className="subs-form">
                      <Form>
                        <div className="custom-row">
                          <div className="leftfiel-subs">
                            <div className="subsform">
                              <Form.Group
                                className="subsform-fild"
                                controlId="formBasicEmail"
                              >
                                <Form.Control
                                  type="email"
                                  placeholder="Enter email"
                                />
                              </Form.Group>
                            </div>
                          </div>

                          <div className="right-subbtn">
                            <div className="btn-buy tutor-becm">
                              <div className="btn-week">
                                <a href="#" className="btnweak-bx">
                                  <span className="btn-title">Subscribe</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>

        <div className="footer-main">
          <div className="custome-container">
            <div className="footer-section">
              <Row>
                <div className="col-md-5 one">
                  <div className="foter-logo">
                    <Link to="/">
                      <img src={logo} className="img-fluid" />
                    </Link>
                  </div>
                </div>
                <div className="col-md-7 two">
                  <div className="foter-list">
                    <div className="foter-listnew">
                      <ul>
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>
                          <Link to="#">How it works</Link>
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <Link to="#">Plans</Link>
                        </li>
                        <li>
                          <Link to="#">Games</Link>
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <Link to="#">For parents</Link>
                        </li>
                        <li>
                          <Link to="/tutor">For teachers</Link>
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <Link to="/blog">Blog</Link>
                        </li>
                        <li>
                          <Link to="/contact">Contacts</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Row>
            </div>

            <div className="foter-copywrite">
              <ul>
                <li>1999-2021 Neithedu.</li>
                <li>All Rights Reserved</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Article;
