import React from "react";

import {
  // Navbar,
  // Nav,
  // NavDropdown,
  Container,
  // Dropdown,
  // br,
  // Row,
  Button,
  Form,
  Modal,
} from "react-bootstrap";
import DoneIcon from "@mui/icons-material/Done";
import logo from "../../../assets/images/logo.png";
import backorange from "../../../assets/images/backorange.svg";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import FooterEducator from "../../layout/Footer/FooterEducator";
// import Addgroup from "../../Addgroup";
// import Addpupil from "../../Addpupil";
// import SendInvite from "../../SendInvite";
// import Groupmember from "../../Groupmember";
import HeaderGames from "../../layout/Header/HeaderGames";
import SendRevision from "../../SendRevision";
import Selectstudent from "../../Selectstudent";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";

const questionsData = [
  {
    questionType: "Open question",
    question:
      "Question text Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
    selected: null,
  },
  {
    questionType: "True/False",
    question:
      "Question text Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do?",
    answer: "true",
    selected: null,
  },
  {
    questionType: "Multiple Choice",
    question:
      "Question text Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
    selected: true,
  },
  {
    questionType: "Matching",
    question:
      "Question text Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
    selected: null,
  },
  {
    questionType: "True/False",
    question:
      "Question text Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do?",
    answer: "true",
    selected: false,
  },
  {
    questionType: "Multiple Choice",
    question:
      "Question text Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
    selected: null,
  },
  {
    questionType: "Open question",
    question:
      "Question text Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
    selected: null,
  },
];
function QuizNotChecked() {
  // const [show, setShow] = React.useState("1");
  const [state, setstate] = React.useState({
    mode: "Active",
    status: "Not checked, In revision",
    sent: "22 Nov, 2021 12:06am",
    autoChecked: 3,
    manualChecked: 0,
    needToCheck: 4,
    selectedQuestion: 4,
  });
  // const [breadMenuShow, setBreadMenuShow] = React.useState(false);
  /* start modal */
  const [modalShow, setModalShow] = React.useState(false);
  /* end modal */

  /* startsave gamed step1 */
  const [modalgamesavedstep1Show, setgamesavedstep1ModalShow] =
    React.useState(false);

  /* end save gamned step1 */

  /* start send revision popup */
  const [modalpersonaltskstep1Show, setpersonaltskstep1ModalShow] =
    React.useState(false);

  /* end send revision popup */

  /* start select student */
  const [modalAddgroupstep2Show, setAddgroupstep2ModalShow] =
    React.useState(false);

  /* end select student */

  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderGames setModalShow={(value) => setModalShow(value)} />
      {/* end header */}

      <div className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs">
        <div className="games-photosynths gamequiz-main">
          <Container>
            <div className="quiz-game-popup">
              <div className="current-lesson-main ">
                <div className="current-lesson-contain">
                  <span className="back-btndrop">
                    <Link to="/educator/quizStatus" className="backbtn-lg">
                      <img src={backorange} />
                    </Link>
                  </span>
                </div>
              </div>

              <Box>
                <Typography
                  sx={{ color: "white", fontSize: "1.6rem", fontWeight: "700" }}
                >
                  Name of the student
                </Typography>
                <Grid
                  container
                  // justifyContent={"center"}
                  sx={{
                    mx: "auto",
                    maxWidth: "60rem",
                    p: "2rem",
                    color: "white",
                    borderRadius: "1.2rem",
                    backgroundColor: "rgba(255,255,255,0.1)",
                  }}
                >
                  <Grid item xs={5.5}>
                    <Typography>
                      Mode:{" "}
                      <b style={{ color: "var(--orange)" }}>{state.mode}</b>
                    </Typography>
                    <Typography>
                      Status:{" "}
                      <b style={{ color: "var(--orange)" }}>{state.status}</b>
                    </Typography>
                    <Typography>
                      Sent:{" "}
                      <b style={{ color: "var(--orange)" }}>{state.sent}</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Divider
                      style={{ height: "100%", width: "3px" }}
                      orientation="vertical"
                      flexItem
                    />
                  </Grid>
                  <Grid item xs={5.5}>
                    <Typography>
                      Automatically checked:
                      <b style={{ color: "var(--orange)" }}>
                        {state.autoChecked + " questions"}
                      </b>
                    </Typography>
                    <Typography>
                      Manually checked:{" "}
                      <b style={{ color: "var(--orange)" }}>
                        {state.status + " questions"}
                      </b>
                    </Typography>
                    <Typography>
                      Need to check:{" "}
                      <b style={{ color: "var(--orange)" }}>
                        {state.sent + " questions"}
                      </b>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <Box
                sx={{
                  backgroundColor: "white",
                  borderRadius: "20px",
                  p: "1rem",
                  mt: "2rem",
                  position: "relative",
                  pt: "0.1rem",
                }}
              >
                {questionsData.map((question, idx) => {
                  const isSelected = idx === state.selectedQuestion;
                  return (
                    <div key={idx}>
                      <Grid container my="1rem" alignItems="center" key={idx}>
                        <Grid item xs={3}>
                          <Box
                            sx={{
                              backgroundColor: isSelected
                                ? "var(--purple-dark)"
                                : "#EDEDFF",
                              borderRadius: "10px",
                              p: "1rem",
                            }}
                          >
                            <Stack flexDirection="row">
                              <Typography
                                sx={{
                                  fontSize: "0.9rem",
                                  color: isSelected
                                    ? "var(--orange)"
                                    : "var(--purple-dark)",
                                  fontWeight: "700",
                                }}
                              >
                                Question {idx + 1}
                              </Typography>
                              <Typography
                                ml="1rem"
                                sx={{
                                  color: isSelected
                                    ? "var(--yellow)"
                                    : "var(--grey-light)",
                                  fontSize: "0.9rem",
                                }}
                              >
                                {question.questionType}
                              </Typography>
                            </Stack>
                            <Typography
                              sx={{ color: isSelected ? "white" : "black" }}
                              textAlign="start"
                              mt="1rem"
                              fontSize="0.9rem"
                            >
                              {question.question}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={7}>
                          <Box p={"1rem"}>
                            <Typography sx={{ textAlign: "start" }}>
                              {question.answer}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          sx={{ display: "flex", justifyContent: "end" }}
                        >
                          <Stack rowGap={"2px"}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "2.5rem",
                                width: "2.5rem",
                                borderTopRightRadius: "10px",
                                borderTopLeftRadius: "10px",
                                backgroundColor:
                                  question.selected === true
                                    ? "var(--purple-dark)"
                                    : "#EDECFF",
                                color:
                                  question.selected === true
                                    ? "#EDECFF"
                                    : "var(--purple)",
                              }}
                            >
                              <DoneIcon color="inherit" />
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "2.5rem",
                                width: "2.5rem",
                                borderBottomRightRadius: "10px",
                                borderBottomLeftRadius: "10px",
                                backgroundColor:
                                  question.selected === false
                                    ? "var(--purple-dark)"
                                    : "#EDECFF",
                                color:
                                  question.selected === false
                                    ? "#EDECFF"
                                    : "var(--purple)",
                              }}
                            >
                              <CloseIcon sx={{ color: "inherit" }} />
                            </Box>
                          </Stack>
                        </Grid>
                      </Grid>
                      {idx !== questionsData.length - 1 && <Divider />}
                    </div>
                  );
                })}
                <div
                  style={{ position: "absolute", bottom: "-15px", left: "30%" }}
                  className="send-revision"
                >
                  <ul className="send-revision-list">
                    <li>
                      <div className="btn-week">
                        <Link
                          to="#"
                          className="btnweak-bx"
                          onClick={() => setpersonaltskstep1ModalShow(true)}
                        >
                          <span className="btn-title">Send for revision</span>
                        </Link>
                      </div>

                      {/* start send revison popup */}

                      <SendRevision
                        modalpersonaltskstep1Show={modalpersonaltskstep1Show}
                        setpersonaltskstep1ModalShow={(value) =>
                          setpersonaltskstep1ModalShow(value)
                        }
                      />

                      {/* end send revison popup */}
                    </li>
                    <li>
                      <div className="btn-week">
                        <Link to="#" className="btnweak-bx">
                          <span className="btn-title">Complete</span>
                        </Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </Box>
            </div>
          </Container>
        </div>

        {/* footer start */}
        <FooterEducator logo={logo} />
        {/* footer end */}
      </div>

      {/* start modal my student */}

      {/* start game save step1 */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        // {...props}
        show={modalgamesavedstep1Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2 mystudent-games">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>The game is saved</h4>
              <h5>Send the game to my students</h5>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setgamesavedstep1ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="serch-inner-group">
                    <div className="serchfiled-activities">
                      <Form.Control
                        type="text"
                        placeholder="Start typing name or year of study"
                        className="formbox"
                      />
                      <Button type="button" className="seachbtn">
                        <i className="fas fa-search"></i>
                      </Button>
                    </div>
                  </div>

                  <div className="newpupil-list-bx">
                    <Form.Group className="subsform-fild child-tex-fld height-max-flow">
                      <div className="child-boxnew">
                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Aaradhya Rayasam</h5>
                                  <h6>India, Mumbai 1 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Chandrashekhar Kalvakuntla</h5>
                                  <h6>India, Mumbai 3 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>7F Class</h5>
                                  <h6>Hamut Kapit, Kemal Mara and 12 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Additional group</h5>
                                  <h6>Laska Doe, Hamut Kapit and 4 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>8A Class for exams</h5>
                                  <h6>Hamut Kapit, Laska Doe and 8 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Chandrashekhar Kalvakuntla</h5>
                                  <h6>India, Mumbai 3 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </Form.Group>

                <div className="student-btn-savedgame">
                  <div className="btnlogin-main">
                    <button type="submit" className="btnlogin btnweak-bx">
                      Send to selected students
                    </button>
                  </div>
                </div>
                <div className="btnlogin-main">
                  <button type="submit" className="btnlogin btnweak-bx">
                    Later
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end Add a Member Group step2 */}

      {/* end modal my student */}

      {/* start select studen popup */}

      <Selectstudent
        modalAddgroupstep2Show={modalAddgroupstep2Show}
        setAddgroupstep2ModalShow={(value) => setAddgroupstep2ModalShow(value)}
      />

      {/* end select studen popup */}
    </div>
  );
}

export default QuizNotChecked;
