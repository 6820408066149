import { Box, Typography } from "@mui/material";
import {
  CloseButton,
  StyledPurpleButton,
  StyledYellowButton,
} from "../../utils/Buttons";
import { DialogBox } from "../../utils/DialogBox";
import { cancelLessonDialogs } from "../../LiveLessonDetail";

const CancelDialog = ({
  setModalType,
  status,
  isImpactSmall,
  role,
  cancelLessonHandler,
}) => {
  let details = "";
  if (role === "educator") {
    if (status === "Pending") {
      <></>;
    } else {
      if (isImpactSmall) {
        details = (
          <>
            <p>
              Cancellation of the lesson affects to rating decrease and your
              membership on <span className="deco-tex">Community pot</span>
            </p>
            <p>
              We recommend to discuss possibility to change the date / time with
              the student .{" "}
            </p>
          </>
        );
      } else {
        details = (
          <>
            {" "}
            <p>
              Cancellation of the lesson now will affect your rating greatly and
              your membership on <span className="deco-tex">Community pot</span>
            </p>
            <p>
              We recommend to discuss possibility to change the date / time with
              the student .
            </p>
          </>
        );
      }
    }
  } else {
    details = (
      <Typography sx={{ color: "var(--black)" }}>
        Note, if date/time, topic, method of the lesson doesn’t suit you, you
        can discuss changing with your educator without cancellation. In this
        case educator changes data and this lesson will be provided when and how
        it is convenient for you.
      </Typography>
    );
  }
  return (
    <DialogBox isOpen={true}>
      <CloseButton closeHandler={() => setModalType(null)} />
      <Box sx={{ textAlign: "center", m: "2rem" }}>
        <Typography
          sx={{
            color: "var(--purple-dark)",
            fontWeight: "800",
            fontSize: "1.3rem",
            mb: "1rem",
          }}
        >
          Are you sure that you want to cancel the live lesson?
        </Typography>
        {details}
        <Box
          my="1rem"
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "85%",
            rowGap: "1rem",
          }}
        >
          <StyledYellowButton
            title={
              role === "educator"
                ? "Chat with the student"
                : "Chat with the educator"
            }
          />
          <StyledPurpleButton
            btnHandler={() => {
              if (role === "educator") {
                cancelLessonHandler();
              } else setModalType(cancelLessonDialogs.REASON);
            }}
            title={"I am sure, cancel the lesson"}
          />
        </Box>
      </Box>
    </DialogBox>
  );
};
export default CancelDialog;
