import { useState } from "react";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "../../home.scss";
import "../../profile.scss";
import "../../media.scss";
import "../../games.scss";
import "../../loginModal/login.scss";
import "../../messages/messages.scss";
import "../../myactivities.scss";
import "../../gamestabs.scss";
import "../../gamesflowphotos.scss";
import "../../selectedgames.scss";
import "../../questionone.scss";
import "../../creatingmaterial.scss";
import "../../Currentlesson.scss";
import {
  // Navbar,
  // Nav,
  // NavDropdown,
  Container,
  // Dropdown,
  // br,
  // Row,
  // Accordion,
  // Card,
  // Button,
  // Form,
  // Modal,
  // Select,
  // Sonnet,
} from "react-bootstrap";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import logo from "../../../assets/images/logo.png";
import backorange from "../../../assets/images/backorange.svg";

import { Link } from "react-router-dom";
import FooterEducator from "../../layout/Footer/FooterEducator";

// import HeaderGames from "../../layout/Header/HeaderGames";
import { HeaderDashboard } from "../../layout/HeaderDashboard";
import { monthNames } from "../../../utils/util";
import {
  Box,
  // CardActions,
  // FormControl,
  Grid,
  // ListItemButton,
  // TextField,
  Typography,
} from "@mui/material";
import { DialogBox } from "../../utils/DialogBox";
import {
  // CloseButton,
  StyledPurpleButton,
  StyledYellowButton,
} from "../../utils/Buttons";

const dateFormat = (time) => {
  const d = new Date(time);
  return `${monthNames[d.getMonth()]} ${d.getDate()}, ${d.toLocaleString(
    "en-US",
    { hour: "numeric", hour12: true },
  )}`;
};

const dialogs = {
  CONFIRMED: "CONFIRMED",
  CANCELLED: "CANCELLED",
};
function ConfirmLiveLesson() {
  const [state, setstate] = useState({
    time: Date.now(),
    status: "Pending",
    newTime: Date.now(),
    newTopic:
      "Another title of the lesson for useful searching something about something",
    chatMessage:
      "Unfortunatly i am not able to have this class this time, can we please chage it? The topic is changed as we duscussed",
    dialog: null,
  });

  const changeDialog = (dialog) => {
    setstate((state) => ({ ...state, dialog }));
  };

  /* end edit lession */

  const ConfirmedDialog = () => {
    return (
      <DialogBox isOpen={true} close={() => {}}>
        <Box sx={{ textAlign: "center", m: "2rem" }}>
          <Typography
            sx={{
              color: "var(--purple-dark)",
              fontWeight: "800",
              fontSize: "1.3rem",
              mb: "1rem",
            }}
          >
            Live lesson is confirmed{" "}
          </Typography>
          <StyledPurpleButton
            btnHandler={() => changeDialog(null)}
            title={"Thanks"}
          />
        </Box>
      </DialogBox>
    );
  };

  const CancelledDialog = () => {
    return (
      <DialogBox isOpen={true}>
        <Box sx={{ textAlign: "center", m: "2rem" }}>
          <Typography
            sx={{
              color: "var(--purple-dark)",
              fontWeight: "800",
              fontSize: "1.3rem",
              mb: "1rem",
            }}
          >
            Live lesson is cancelled{" "}
          </Typography>
          <Typography sx={{ color: "var(--black)" }}>
            Educator will be notified about cancellation. You can discuss
            details in chat{" "}
          </Typography>
          <Box
            my="1rem"
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "85%",
              rowGap: "1rem",
            }}
          >
            <StyledYellowButton
              btnHandler={() => {}}
              title={"Chat with the educator"}
            />
            <StyledPurpleButton
              btnHandler={() => changeDialog(dialogs.REASON)}
              title={"Close"}
            />
          </Box>
        </Box>
      </DialogBox>
    );
  };

  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderDashboard />

      {state.dialog === dialogs.CONFIRMED && <ConfirmedDialog />}
      {state.dialog === dialogs.CANCELLED && <CancelledDialog />}

      <div className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs">
        <div className="cureent-lession-bx completelession-main">
          <div className="current-lesson-main ">
            <Container>
              <div className="current-lesson-contain">
                <div className="backbtn">
                  <Link to="Newlivelession" className="backbtnn-icnn">
                    <img src={backorange} alt=""/>
                  </Link>
                </div>
                <div className="current-lesson-topbr">
                  <div className="medm-tex orng-clr">Live lesson</div>
                  <h1>
                    Title of the lesson for useful searching something about
                    something
                  </h1>
                  <div className="lesson-status-list">
                    <ul className="lesson-listing-curnt">
                      <li>
                        <div className="lesson-status-detail">
                          <span className="medm-tex orng-clr onelist">
                            Time:{" "}
                          </span>
                          <span className="medm-tex orng-clr twolist">
                            {dateFormat(state.time)}
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="lesson-status-detail">
                          <span className="medm-tex orng-clr onelist">
                            Status:{" "}
                          </span>
                          <span className="medm-tex orng-clr twolist">
                            {state.status}
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Container>
          </div>
          <div className="lession-time-card">
            <Container>
              <Box sx={{ maxWidth: "39rem", mx: "auto" }}>
                <Box
                  sx={{
                    backgroundColor: "var(--white)",
                    p: "1rem 2rem",
                    borderRadius: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "center",
                      mb: "1rem",
                      fontWeight: "600",
                      fontSize: "1.2rem",
                      color: "var(--yellow)",
                    }}
                  >
                    !! Educator edited the time and topic of the lesson with
                    comment:
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "start",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "var(--black)",
                        fontWeight: "bold",
                        fontSize: "1.1rem",
                      }}
                    >
                      New topic:
                    </Typography>
                    <Typography
                      sx={{
                        mb: "1rem",
                        display: "block",
                        color: "var(--black)",
                        textAlign: "left",
                      }}
                    >
                      {state.newTopic}
                    </Typography>
                    <Typography
                      sx={{
                        color: "var(--black)",
                        fontWeight: "bold",
                        fontSize: "1.1rem",
                      }}
                    >
                      New time:
                    </Typography>
                    <Typography
                      sx={{
                        display: "block",
                        color: "var(--black)",
                        textAlign: "left",
                      }}
                    >
                      {new Date(state.time).toLocaleString()}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    mt: "2rem",
                    backgroundColor: "var(--white)",
                    p: "1rem 2rem",
                    borderRadius: "10px",
                  }}
                >
                  <Grid alignItems={"center"} columnSpacing={"1rem"} container>
                    <Grid item xs={2}>
                      <Box
                        sx={{
                          mx: "auto",
                          fontSize: "2rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "50%",
                          width: "55px",
                          height: "55px",
                          backgroundColor: "var(--orange)",
                          color: "var(--white)",
                        }}
                      >
                        <i className="fas fa-user"></i>
                      </Box>
                    </Grid>
                    <Grid item xs={7}>
                      <Box sx={{ textAlign: "start" }}>
                        <Typography sx={{ textAlign: "start" }}>
                          Unfortunatly i am not able to have this class this
                          time, can we please chage it? The topic is changed as
                          we duscussed
                        </Typography>
                        <Typography sx={{ color: "black" }}>
                          May 19, 2021 11.32pm
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <StyledYellowButton title={"Chat"} />
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    backgroundColor: "var(--purple)",
                    m: "3rem 3rem 4rem 3rem",
                    borderRadius: "20px",
                    p: "2rem",
                  }}
                >
                  <Box mt="0.5rem">
                    <Typography
                      sx={{ fontWeight: "600", color: "var(--yellow)" }}
                    >
                      You have to answer during 3 days after application.
                    </Typography>
                    <Typography sx={{ color: "var(--white)" }}>
                      If you don’t answer, changes will be confirmed.{" "}
                    </Typography>
                  </Box>
                  <Grid
                    justifyContent={"center"}
                    rowSpacing={"2rem"}
                    container
                    sx={{ mt: "2rem" }}
                  >
                    <Grid
                      sx={{ display: "grid", placeItems: "center" }}
                      item
                      xs={12}
                      sm={6}
                    >
                      <Box
                        sx={{
                          maxWidth: "12rem",
                        }}
                      >
                        <StyledPurpleButton
                          title="Confirm changes"
                          btnHandler={() => {
                            changeDialog(dialogs.CONFIRMED);
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid
                      sx={{ display: "grid", placeItems: "center" }}
                      item
                      xs={12}
                      sm={6}
                    >
                      <Box sx={{ maxWidth: "12rem" }}>
                        <StyledYellowButton
                          title="Cancel changes"
                          btnHandler={() => {
                            changeDialog(dialogs.CANCELLED);
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Container>
          </div>

          <Box
            sx={{
              mb: "2rem",
              color: "var(--yellow)",
              textDecoration: "underline",
              fontWeight: "700",
              fontSize: "1.2rem",
            }}
          >
            <a>Check the previous lesson</a>
          </Box>

          <div className="pagination-lession">
            <Container>
              <div className="pagination-lession-inner">
                <ul>
                  <li className="one">
                    <Link to="#" className="pagination-btn">
                      <span className="iconn">
                        <i className="fas fa-angle-left"></i>
                      </span>
                      <span className="tex-lesn">Previous lesson</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </Container>
          </div>
        </div>

        {/* footer start */}
        <FooterEducator logo={logo} />
        {/* footer end */}
      </div>

      {/* end lession edit */}
    </div>
  );
}

export default ConfirmLiveLesson;
