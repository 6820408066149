import * as React from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import timelineImage from "../../assets/images/insturction-slide1.jpg";
import "./vtimeline.scss";

const timelineData = [
  {
    progress: "100",
    title: "Magic potions",
    content:
      "Magic potions can give you free pass for a game, if you struggling on a game",
    img: timelineImage,
  },
  {
    progress: "500",
    title: "+500 points",
    content: "Your points doubles!",
    img: timelineImage,
  },
  {
    progress: "1k",
    title: "Badge 1K",
    content:
      "Badge with time that you spend on eanring 1K points appears on your page ",
    img: timelineImage,
  },
  {
    progress: "2k",
    title: "5 additional reward cards",
    content: "Your points doubles",
    img: timelineImage,
  },
  {
    progress: "3k",
    title: "Mystery box",
    content:
      "Badge with time that you spend on eanring 1K points appears on your page ",
    img: timelineImage,
  },
];

export default function VTimeline() {
  return (
    <div id="vtimeline">
      <VerticalTimeline
        layout="1-column-left"
        lineColor="#FFD300"
        className="vertical-timeline"
      >
        {/* <VerticalTimelineElement
          iconStyle={{
            width: 60,
            height: 60,
            marginLeft: "-1.2%",
            background: "#FFD300",
            color: "#000",
            boxShadow: "none",
            display: "grid",
            placeItems: "center",
          }}
          icon={<div>Start</div>}
        /> */}
        {timelineData.map((el, i) => (
          <VerticalTimelineElement
            key={i}
            className="vertical-timeline-element"
            textClassName="text-container"
            iconClassName="vertical-timeline-icon"
            icon={<div>{el.progress}</div>}
          >
            <div className="content-container">
              <img src={el.img} alt="" className="img" />
              <div className="content">
                <h3>{el.title}</h3>
                <p>{el.content}</p>
              </div>
            </div>
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </div>
  );
}
