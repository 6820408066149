import { createContext } from "react";
import { CustomSnackbar, useSnackState } from "../utils/useSnackState";

export const SnackbarContext = createContext({
  openSnackbar: () => {},
});

export const SnackbarProvider = ({ children }) => {
  const [snackProps, snackOpen] = useSnackState();
  const openSnackbar = (message, variant) => {
    snackOpen(message, variant);
  };
  const value = {
    openSnackbar,
  };

  return (
    <SnackbarContext.Provider value={value}>
      <CustomSnackbar {...snackProps} />
      {children}
    </SnackbarContext.Provider>
  );
};
