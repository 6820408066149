import React from "react";
import "./home.scss";
import "./profile.scss";
import "./media.scss";
import "./games.scss";
import "./myactivities.scss";
import "./Instruction.scss";
import logo from "../assets/images/logo.png";
import instslide1 from "../assets/images/insturction-slide1.jpg";
import Slider from "react-slick";
import Footer from "./layout/Footer/Footer";
import { HeaderDashboard } from "./layout/HeaderDashboard";

function Instruction() {
  // start slider
  const settingsthird = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: false,
    slidesToShow: 1,
    nav: false,
    // nextArrow: <img src={rightarrow} className="img-fluid" />,
    // prevArrow: <img src={leftarrow} className="img-fluid" />,
    slidesToScroll: 1,
  };

  return (
    <div className="home-wrapper">
      <HeaderDashboard />
      <div className="games-dashboard-main dashboard-bgnew blur-bgg">
        <div className="games-topbread instruction-section">
          <div className="contain-custm">
            <div className="instruction-slider">
              <div className="custom-row">
                <div className="leftbox-inst">
                  <div className="insturct-card">
                    <div className="slider-instruc-bxx">
                      <Slider {...settingsthird}>
                        <div className="sliderbox">
                          <div className="testi-mainsldr">
                            <div className="slider-cardbox-instruc">
                              <div className="slider-innercard">
                                <img src={instslide1} className="img-fluid" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="sliderbox">
                          <div className="testi-mainsldr">
                            <div className="slider-cardbox-instruc">
                              <div className="slider-innercard">
                                <img src={instslide1} className="img-fluid" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="sliderbox">
                          <div className="testi-mainsldr">
                            <div className="slider-cardbox-instruc">
                              <div className="slider-innercard">
                                <img src={instslide1} className="img-fluid" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Slider>
                    </div>
                  </div>
                </div>
                <div className="rightbox-inst">
                  <div className="quiz-card">
                    <div className="quizcard-content">
                      <h1>Quiz</h1>
                      <div className="content-detailquiz">
                        <p>
                          Text description how it works to the image in the left
                          side or video.{" "}
                        </p>
                        <p>You have 1 minute of time for every questions</p>
                      </div>
                    </div>
                  </div>

                  <div className="btn-game">
                    <div className="btn-buy">
                      <div className="btn-week">
                        <a href="#" className="btnweak-bx">
                          <span className="btn-title">Start the game</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* footer start */}
        <Footer logo={logo} />
        {/* footer end */}
      </div>
    </div>
  );
}

export default Instruction;
