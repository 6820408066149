import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import PersonIcon from "@mui/icons-material/Person";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

const data = {
  friendRequests: [
    {
      name: "Lion Amari",
      yearsOfStudy: 7,
      points: "13 823",
      location: "India, Mumbai",
      games: 637,
      rating: 84,
    },
    {
      name: "Lion Amari",
      yearsOfStudy: 7,
      points: "13 823",
      location: "India, Mumbai",
      games: 637,
      rating: 84,
    },
  ],
  suggestions: [
    {
      name: "Lion Amari",
      yearsOfStudy: 7,
      points: "13 823",
      location: "India, Mumbai",
      games: 637,
      rating: 84,
    },
    {
      name: "Lion Amari",
      yearsOfStudy: 7,
      points: "13 823",
      location: "India, Mumbai",
      games: 637,
      rating: 84,
    },
    {
      name: "Lion Amari",
      yearsOfStudy: 7,
      points: "13 823",
      location: "India, Mumbai",
      games: 637,
      rating: 84,
    },
    {
      name: "Lion Amari",
      yearsOfStudy: 7,
      points: "13 823",
      location: "India, Mumbai",
      games: 637,
      rating: 84,
    },
  ],
};
export const Requests = () => {
  const StudentCard = ({ student, isRequest }) => {
    return (
      <Box
        sx={{
          cursor: "pointer",
          p: "1.4rem",
          mb: "1rem",
          backgroundColor: "white",
          borderRadius: "1.2rem",
        }}
      >
        <Grid
          container
          alignItems={"center"}
          // justifyContent="space-between"
          columnSpacing={"1rem"}
        >
          <Grid item md={1}>
            <PersonIcon
              sx={{
                color: "white",
                backgroundColor: "var(--purple)",
                borderRadius: "50%",
                height: "3rem",
                width: "3rem",
                p: "0.3rem",
              }}
            />
          </Grid>
          <Grid item md={3}>
            <Box sx={{}}>
              <Typography sx={{ fontWeight: "800", fontSize: "1rem" }}>
                {student.name}
              </Typography>
              <Box sx={{ display: "flex", columnGap: "2rem" }}>
                <Typography sx={{ color: "var(--purple)" }}>
                  {student.location}
                </Typography>
                <Typography sx={{ color: "var(--purple)" }}>
                  {student.yearsOfStudy + " years of study"}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item md justifySelf={"center"}>
            <Stack flexDirection="row" columnGap={"2rem"}>
              <Stack alignItems="center">
                <Typography
                  sx={{
                    color: "var(--purple-dark)",
                    fontSize: "1.3rem",
                    fontWeight: "800",
                  }}
                >
                  {student.points}
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "var(--purple)",
                    backgroundColor: "var(--yellow)",
                    borderRadius: "8px",
                    p: "0.2rem",
                  }}
                >
                  points
                </Typography>
              </Stack>
              <Stack alignItems="center">
                <Typography
                  sx={{
                    color: "var(--purple-dark)",
                    fontSize: "1.3rem",
                    fontWeight: "800",
                  }}
                >
                  {student.games}
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "var(--purple)",
                    backgroundColor: "var(--yellow)",
                    borderRadius: "8px",
                    p: "0.2rem",
                  }}
                >
                  games
                </Typography>
              </Stack>
              <Stack alignItems="center">
                <Typography
                  sx={{
                    color: "var(--purple-dark)",
                    fontSize: "1.3rem",
                    fontWeight: "800",
                  }}
                >
                  {student.rating + "th"}
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "var(--purple)",
                    backgroundColor: "var(--yellow)",
                    borderRadius: "8px",
                    p: "0.2rem",
                  }}
                >
                  in the rating
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item md={2}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                columnGap: "0.7rem",
              }}
            >
              {!isRequest && (
                <FavoriteBorderIcon
                  sx={{
                    backgroundColor: "var(--purple-dark)",
                    p: "0.4rem",
                    fontSize: "2rem",
                    color: "white",
                    borderRadius: "10px",
                  }}
                />
              )}
              <Box
                sx={{
                  borderRadius: "10px",
                  p: "0.2rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "var(--yellow)",
                }}
              >
                <img
                  style={{ height: "1.5rem" }}
                  alt="chat"
                  src="/images/icons/chat.png"
                />
              </Box>
              {isRequest && (
                <>
                  <DoneIcon
                    sx={{
                      cursor: "pointer",
                      backgroundColor: "var(--purple-dark)",
                      p: "0.4rem",
                      fontSize: "2rem",
                      color: "white",
                      borderRadius: "10px",
                    }}
                  />
                  <CloseIcon
                    sx={{
                      cursor: "pointer",
                      backgroundColor: "var(--orange)",
                      p: "0.4rem",
                      fontSize: "2rem",
                      color: "white",
                      borderRadius: "10px",
                    }}
                  />
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  };
  return (
    <>
      <Typography
        sx={{
          color: "var(--orange)",
          fontSize: "1.4rem",
          fontWeight: "600",
          m: "2rem 0 1rem",
        }}
      >
        2 friend requests
      </Typography>

      <Box>
        {data.friendRequests.map((student, idx) => {
          return <StudentCard isRequest key={idx} student={student} />;
        })}
      </Box>

      <Typography
        sx={{
          color: "var(--orange)",
          fontSize: "1.4rem",
          fontWeight: "600",
          m: "2rem 0 1rem",
        }}
      >
        You can also know
      </Typography>

      <Box sx={{ paddingBottom: "5rem" }}>
        {data.suggestions.map((student, idx) => {
          return <StudentCard key={idx} student={student} />;
        })}
      </Box>
    </>
  );
};
