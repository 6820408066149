import { useEffect, useState } from "react";
import { DialogBox } from "../../utils/DialogBox";
import { CloseButton, StyledYellowButton } from "../../utils/Buttons";
import { Box, ListItemButton, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Card } from "react-bootstrap";
import { getCancellationReasons } from "../../../service/apis/lesson";

const ReasonDialog = ({ setModalType, cancelLessonHandler, role }) => {
  const [reasonsList, setReasonsList] = useState([]);

  const [otherReason, setotherReason] = useState({
    open: false,
    message: "",
  });
  const [reason, setreason] = useState({
    selected: null,
  });

  console.log("reason", reason);
  console.log("otherReason", otherReason);

  useEffect(() => {
    getCancellationReasons().then((res) => {
      // remove last 5 reasons
      let updatedReasonsList = [];
      res.forEach((element) => {
        if (element.cancelReasonId >= 41 && element.cancelReasonId <= 45) {
        } else {
          updatedReasonsList.push(element);
        }
      });
      setReasonsList(updatedReasonsList);
    });
  }, []);

  return (
    <DialogBox isOpen={true}>
      <CloseButton closeHandler={() => setModalType(null)} />
      <Box sx={{ textAlign: "center", m: "2rem" }}>
        <Typography
          sx={{
            color: "var(--purple-dark)",
            fontWeight: "800",
            fontSize: "1.3rem",
          }}
        >
          Choose a reason of cancellation
        </Typography>
        {role !== "educator" && (
          <Typography sx={{ color: "var(--black)", fontWeight: "500" }}>
            Educator will not see the reason that you choose
          </Typography>
        )}
        <div
          style={{
            maxHeight: "350px",
            overflowY: "scroll",
          }}
        >
          {reasonsList.map((e) => {
            const isSelected = reason.selected === e.cancelReasonId;
            return (
              <Box
                onClick={() => {
                  setotherReason({ open: false, message: "" });
                  setreason({ selected: e.cancelReasonId });
                }}
                key={e.cancelReasonId}
                sx={{
                  mt: "1rem",
                }}
              >
                <ListItemButton
                  sx={{
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    color: isSelected ? "#6a64e6" : "white",

                    backgroundColor: isSelected
                      ? "var(--yellow)"
                      : "var(--purple)",

                    "&:hover": {
                      backgroundColor: "#e8b709",
                      color: "#6a64e6",
                    },
                  }}
                >
                  <ArrowForwardIosIcon
                    sx={{
                      color: isSelected ? "var(--purple)" : "var(--yellow)",
                      mr: "1rem",
                    }}
                  />
                  <Typography sx={{ color: "inherit" }}>
                    {e.cancelReasonName}
                  </Typography>
                </ListItemButton>
              </Box>
            );
          })}

          {otherReason.open === false ? (
            <Box
              onClick={() => {
                setreason({ selected: null });
                setotherReason((state) => ({ ...state, open: true }));
              }}
              sx={{ mt: "1rem" }}
            >
              <Card>
                <ListItemButton
                  sx={{
                    backgroundColor: "var(--purple)",
                    display: "flex",
                    borderRadius: "10px",
                    alignItems: "center",
                  }}
                >
                  <ArrowForwardIosIcon
                    sx={{ color: "var(--yellow)", mr: "1rem" }}
                  />
                  <Typography
                    sx={{
                      color: "white",
                    }}
                  >
                    Other
                  </Typography>
                </ListItemButton>
              </Card>
            </Box>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                marginTop: "1rem",
                rowGap: "1rem",
              }}
            >
              <label>Other reasons</label>
              <textarea
                style={{
                  resize: "none",
                  width: "100%",
                  padding: "0.3rem",
                  borderRadius: "5px",
                }}
                rows={4}
                placeholder="Type here"
                onChange={(e) =>
                  setotherReason((state) => ({
                    ...state,
                    message: e.target.value,
                  }))
                }
                value={otherReason.message}
              />
            </div>
          )}
        </div>
        <Box
          sx={{
            width: "15rem",
            mx: "auto",
            display: "flex",
            mt: "1rem",
            justifyContent: "center",
          }}
        >
          <StyledYellowButton
            sx={{
              backgroundColor:
                reason.selected === null && otherReason.message === ""
                  ? "#c8cbce"
                  : "var(--yellow)",
              "&:hover": {
                backgroundColor:
                  reason.selected === null && otherReason.message === ""
                    ? "#c8cbce"
                    : "var(--yellow)",
              },
            }}
            btnHandler={() => {
              if (reason.selected === null && otherReason.message === "") {
                return;
              }
              cancelLessonHandler(
                reason.selected,
                otherReason.open ? otherReason.message : "",
              );
            }}
            title="Complete the cancellation"
          />
        </Box>
      </Box>
    </DialogBox>
  );
};
export default ReasonDialog;
