import { Dialog, Typography } from "@mui/material";
import { Box } from "@mui/material";
import React from "react";
import { DialogBox } from "../utils/DialogBox";
import CloseIcon from "@mui/icons-material/Close";

const SuccessModal = ({modalTitle, onClose }) => {
  return (
    <DialogBox isOpen={true}>
      <Box
        sx={{
          minHeight: "5rem",
          minWidth: "20rem",
          position: "relative",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <CloseIcon
          onClick={()=>onClose(false)}
          sx={{
            cursor: "pointer",
            color: "var(--purple-dark)",
            position: "absolute",
            top: "-5px",
            right: "0px",
          }}
        />
        <Typography
          sx={{
            textAlign: "center",
            color: "var(--purple-dark)",
            fontWeight: "700",
            fontSize: "1.4rem",
          }}
        >
          {modalTitle}
        </Typography>
      </Box>
    </DialogBox>
  );
};


export default SuccessModal;