import React from "react";
import "./home.scss";
import "./profile.scss";
import "./media.scss";
import "./games.scss";
import "./loginModal/login.scss";
import "./profile.scss";
import "./messages/messages.scss";
import "./myactivities.scss";
import "./gamestabs.scss";
import "./gamesflowphotos.scss";
import "./selectedgames.scss";
import "./Myprofile.scss";

import {
  // Navbar,
  // Nav,
  // NavDropdown,
  Container,
  // Dropdown,
  // br,
  Row,
  // Accordion,
  // Card,
  Button,
  Form,
  Modal,
  // Select,
  // Sonnet,
  Table,
} from "react-bootstrap";
import { Tabs } from "react-tabs";

import logo from "../assets/images/logo.png";
// import gamesgalery1 from "../assets/images/games-galery1.jpg";
// import gamesgalery2 from "../assets/images/games-galery2.jpg";
// import chaticon from "../assets/images/chat-icon.svg";
// import batchorange from "../assets/images/batch-orange.svg";
// import palinblue from "../assets/images/palin-blue.svg";
// import mike from "../assets/images/mike.svg";
// import attachemtnblue from "../assets/images/attachemtn-blue.svg";
// import downarrowblue from "../assets/images/down-arrow-blue.svg";
// import dotthree from "../assets/images/dotthree.svg";
// import addbtnround from "../assets/images/add-btn.svg";
// import darkblueplan from "../assets/images/darkblue-plan.svg";
// import backorange from "../assets/images/backorange.svg";
// import editorangelg from "../assets/images/editlg-orange.svg";
// import flag from "../assets/images/flag.svg";
import profilepic from "../assets/images/profile-img.png";
// import placeholder from "../assets/images/placeholder.jpg";

// import SliderCenter from "./Slider";
import { Link } from "react-router-dom";
import FooterEducator from "./layout/Footer/FooterEducator";
// import Addgroup from "./Addgroup";
// import Addpupil from "./Addpupil";
// import SendInvite from "./SendInvite";
// import Groupmember from "./Groupmember";
import HeaderGames from "./layout/Header/HeaderGames";
import Becometutornew from "./tutor/Becometutornew";
import Myprofilesidebar from "./Myprofilesidebar";
// import Box from "@mui/material/Box";
// import Slider from "@mui/material/Slider";

function Earnings() {
  // const [show, setShow] = React.useState("1");

  // const [breadMenuShow, setBreadMenuShow] = React.useState(false);
  /* start modal */
  const [modalShow, setModalShow] = React.useState(false);
  /* end modal */

  /* start becometutor */
  const [modalEducatorstep1Show, setEducatorstep1ModalShow] =
    React.useState(false);
  /* end becometutor */

  /* startsave gamed step1 */
  const [modalgamesavedstep1Show, setgamesavedstep1ModalShow] =
    React.useState(false);

  /* end save gamned step1 */

  /* start Tutor signup step6 */
  const [modalTutorstep7Show, setTutorstep7ModalShow] = React.useState(false);
  /* end Tutor signup step6 */
  /* start Tutor signup step2 */
  const [modalTutorstep2Show, setTutorstep2ModalShow] = React.useState(false);
  /* end Tutor signup step2 */

  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderGames setModalShow={(value) => setModalShow(value)} />
      {/* end header */}

      <div className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs">
        <div className="myprofile-main">
          <div className="profilesection">
            <Container>
              <div className="profilemain-card">
                <Tabs>
                  <Row>
                    <div className="col-lg-5">
                      <div className="profile-tabs">
                        <div className="profileshape-bg"></div>
                        <div className="profile-pic">
                          <div className="profile-image">
                            {" "}
                            <img src={profilepic} className="img-fluid" />{" "}
                            <div className="file-upnew">
                              <Form.Control
                                type="file"
                                className="brower-btn"
                                id="input-file"
                              />
                              <label
                                htmlFor="input-file"
                                className="profile-uplod-btn"
                              >
                                <i className="fas fa-camera"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="profile-lftsidee">
                          {/* start my profile sidebar */}
                          <Myprofilesidebar />
                          {/* end  my profile sidebar */}

                          <div className="profile-footer">
                            <button
                              type="submit"
                              className="btnlogin btnweak-bx"
                              onClick={() => {
                                setEducatorstep1ModalShow(true);
                              }}
                            >
                              Became a tutor
                            </button>
                            <Becometutornew
                              modalEducatorstep1Show={modalEducatorstep1Show}
                              setEducatorstep1ModalShow={(value) =>
                                setEducatorstep1ModalShow(value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="myprofile-right">
                        <div className="tab-heading">Earnings</div>
                        <div className="earnings-section">
                          <div className="earning-top">
                            <Row className="align-center">
                              <div className="col-lg-8 one">
                                <div className="earning-card">
                                  <ul>
                                    <li>
                                      <div className="earing-cardbx">
                                        <div className="iner-cardearbg">
                                          <h5>account</h5>
                                          <h4>$689</h4>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="earing-cardbx">
                                        <div className="iner-cardearbg">
                                          <h5>Income total</h5>
                                          <h4>$6 789</h4>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="earing-cardbx">
                                        <div className="iner-cardearbg">
                                          <h5>withdrawn</h5>
                                          <h4>$6 100</h4>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-lg-4 two">
                                <div className="withdraw">
                                  <Link to="#" className="btnlogin btnweak-bx">
                                    Withdraw
                                  </Link>
                                </div>
                              </div>
                            </Row>
                          </div>

                          <div className="table-main">
                            <div className="table-history">
                              <div className="table-form-top">
                                <Row>
                                  <div className="col-lg-5 one">
                                    <div className="tble-frm-left">
                                      <Form.Group className="subsform-fild">
                                        <Form.Control as="select">
                                          <option value="2">
                                            All transactions
                                          </option>
                                        </Form.Control>
                                      </Form.Group>
                                    </div>
                                  </div>
                                  <div className="col-lg-7 two">
                                    <ul className="formlist-date">
                                      <li>
                                        <div className="datepick-frm">
                                          <Form.Group className="subsform-fild">
                                            <Form.Control
                                              type="date"
                                              placeholder="From"
                                            />
                                          </Form.Group>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="datepick-frm">
                                          <Form.Group className="subsform-fild">
                                            <Form.Control
                                              type="date"
                                              placeholder="To"
                                            />
                                          </Form.Group>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </Row>
                              </div>
                              <div className="table-transaction">
                                <div className="iner-trasti">
                                  <div className="transction-row">
                                    <div className="lefttex">
                                      298 transactions
                                    </div>
                                    <div className="righttex">$6 789</div>
                                  </div>
                                </div>
                              </div>
                              <div className="table-inermain">
                                <div className="tablebx-main">
                                  <div className="tablescrl">
                                    <Table bordered hover responsive>
                                      <tbody>
                                        <tr>
                                          <td>
                                            Publication of{" "}
                                            <span className="textlink">
                                              material
                                            </span>
                                          </td>
                                          <td className="date-frmt">
                                            22 Sep, 21
                                          </td>
                                          <td className="date-frmt">
                                            11.56 pm
                                          </td>
                                          <td>$20</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            Live lesson with{" "}
                                            <span className="textlink">
                                              Anna Maria Lock
                                            </span>
                                          </td>
                                          <td className="date-frmt">
                                            19 Aug, 21
                                          </td>
                                          <td className="date-frmt">
                                            09.51 pm
                                          </td>
                                          <td>$10</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            Invited{" "}
                                            <span className="textlink">
                                              student
                                            </span>{" "}
                                            subscribed{" "}
                                          </td>
                                          <td className="date-frmt">
                                            29 May, 21
                                          </td>
                                          <td className="date-frmt">
                                            11.34 pm
                                          </td>
                                          <td>$5</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            Live lesson with{" "}
                                            <span className="textlink">
                                              Ronald Summit
                                            </span>
                                          </td>
                                          <td className="date-frmt">
                                            06 May, 21
                                          </td>
                                          <td className="date-frmt">
                                            10.47 am
                                          </td>
                                          <td>$15</td>
                                        </tr>
                                        <tr>
                                          <td>Community pot</td>
                                          <td className="date-frmt">
                                            25 Jan, 21
                                          </td>
                                          <td className="date-frmt">
                                            03.38 am
                                          </td>
                                          <td>$80</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            Live lesson with{" "}
                                            <span className="textlink">
                                              Anna Maria Lock
                                            </span>
                                          </td>
                                          <td className="date-frmt">
                                            09 Dec, 20
                                          </td>
                                          <td className="date-frmt">
                                            06.02 pm
                                          </td>
                                          <td>$15</td>
                                        </tr>

                                        <tr>
                                          <td>
                                            Invited{" "}
                                            <span className="textlink">
                                              student
                                            </span>{" "}
                                            subscribed{" "}
                                          </td>
                                          <td className="date-frmt">
                                            16 Nov, 20
                                          </td>
                                          <td className="date-frmt">
                                            10.48 pm
                                          </td>
                                          <td>$40</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            Live lesson with{" "}
                                            <span className="textlink">
                                              Anna Maria Lock
                                            </span>
                                          </td>
                                          <td className="date-frmt">
                                            27 Sep, 19
                                          </td>
                                          <td className="date-frmt">
                                            11.59 pm
                                          </td>
                                          <td>$15</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            Publication of{" "}
                                            <span className="textlink">
                                              material
                                            </span>
                                          </td>
                                          <td className="date-frmt">
                                            22 Sep, 21
                                          </td>
                                          <td className="date-frmt">
                                            11.56 pm
                                          </td>
                                          <td>$20</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            Live lesson with{" "}
                                            <span className="textlink">
                                              Anna Maria Lock
                                            </span>
                                          </td>
                                          <td className="date-frmt">
                                            19 Aug, 21
                                          </td>
                                          <td className="date-frmt">
                                            09.51 pm
                                          </td>
                                          <td>$10</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            Invited{" "}
                                            <span className="textlink">
                                              student
                                            </span>{" "}
                                            subscribed{" "}
                                          </td>
                                          <td className="date-frmt">
                                            29 May, 21
                                          </td>
                                          <td className="date-frmt">
                                            11.34 pm
                                          </td>
                                          <td>$5</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            Live lesson with{" "}
                                            <span className="textlink">
                                              Ronald Summit
                                            </span>
                                          </td>
                                          <td className="date-frmt">
                                            06 May, 21
                                          </td>
                                          <td className="date-frmt">
                                            10.47 am
                                          </td>
                                          <td>$15</td>
                                        </tr>
                                        <tr>
                                          <td>Community pot</td>
                                          <td className="date-frmt">
                                            25 Jan, 21
                                          </td>
                                          <td className="date-frmt">
                                            03.38 am
                                          </td>
                                          <td>$80</td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="loadbtn-more">
                            <div className="btn-week">
                              <Link className="btnweak-bx" to="#">
                                <span className="btn-title">Load more</span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </Tabs>
              </div>
            </Container>
          </div>
        </div>

        {/* footer start */}
        <FooterEducator logo={logo} />
        {/* footer end */}
      </div>

      {/* start modal my student */}

      {/* start game save step1 */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        // {...props}
        show={modalgamesavedstep1Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2 mystudent-games">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>The game is saved</h4>
              <h5>Send the game to my students</h5>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setgamesavedstep1ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="serch-inner-group">
                    <div className="serchfiled-activities">
                      <Form.Control
                        type="text"
                        placeholder="Start typing name or year of study"
                        className="formbox"
                      />
                      <Button type="button" className="seachbtn">
                        <i className="fas fa-search"></i>
                      </Button>
                    </div>
                  </div>

                  <div className="newpupil-list-bx">
                    <Form.Group className="subsform-fild child-tex-fld height-max-flow">
                      <div className="child-boxnew">
                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Aaradhya Rayasam</h5>
                                  <h6>India, Mumbai 1 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Chandrashekhar Kalvakuntla</h5>
                                  <h6>India, Mumbai 3 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>7F Class</h5>
                                  <h6>Hamut Kapit, Kemal Mara and 12 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Additional group</h5>
                                  <h6>Laska Doe, Hamut Kapit and 4 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>8A Class for exams</h5>
                                  <h6>Hamut Kapit, Laska Doe and 8 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Chandrashekhar Kalvakuntla</h5>
                                  <h6>India, Mumbai 3 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </Form.Group>

                <div className="student-btn-savedgame">
                  <div className="btnlogin-main">
                    <button type="submit" className="btnlogin btnweak-bx">
                      Send to selected students
                    </button>
                  </div>
                </div>
                <div className="btnlogin-main">
                  <button type="submit" className="btnlogin btnweak-bx">
                    Later
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end Add a Member Group step2 */}

      {/* end modal my student */}
    </div>
  );
}

export default Earnings;
