import { Box, Grid, Modal, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { StyledYellowButton } from "../utils/Buttons";
export const StudentTrial = ({ closeHandler }) => {
  const MiniCard = ({ children }) => (
    <Box
      sx={{
        borderRadius: "8px",
        heigth: "67px",
        width: "120px",
        backgroundColor: "rgba(255,255,255,0.1)",
        p: "0.5rem",
      }}
    >
      {children}
    </Box>
  );
  return (
    <Modal sx={{ overflowY: "scroll" }} open>
      <Box
        sx={{
          m: "1rem",
          minHeight: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            boxShadow: "0px 4px 10px rgba(0,0,0,0.15)",
            backgroundColor: "rgba(255,255,255, 0.5)",
            p: "0.5rem",
            borderRadius: "20px",
          }}
        >
          <Box
            sx={{
              fontFamily: "Poppins",
              opacity: "1",
              boxShadow: "0px 4px 10px rgba(0,0,0,0.15)",
              maxWidth: "50rem",
              position: "relative",
              width: "100%",
              p: "1rem",
              pt: "2rem",
              borderRadius: "12px",
              backgroundColor: "var(--purple-dark)",
            }}
          >
            <CloseIcon
              onClick={closeHandler}
              sx={{
                cursor: "pointer",
                color: "var(--orange)",
                position: "absolute",
                top: "10px",
                right: "10px",
              }}
            />

            <Typography
              sx={{
                color: "var(--orange)",
                fontWeight: "800",
                fontSize: "1.3rem",
              }}
            >
              Congratulations! You are in Neithedu now!
            </Typography>
            <Typography sx={{ color: "var(--white)" }}>
              During 7 days from your registration we present you the Trial
              period. What is available to you at this time?
            </Typography>

            <Typography
              sx={{ mt: "1rem", color: "var(--yellow)", fontWeight: "700" }}
            >
              Play as many games as you want!
            </Typography>
            <Typography sx={{ color: "var(--white)" }}>
              You have access to all educational games that we have in Neithedu.
              It's{" "}
            </Typography>
            <Box
              sx={{
                alignItems: "center",
                justifyContent: "space-between",
                mt: "1rem",
                flexWrap: "wrap",
                fontWeight: "300",
                display: "flex",
              }}
            >
              <MiniCard>
                <Typography
                  sx={{
                    fontWeight: "900",
                    fontSize: "1.6rem",
                    color: "var(--yellow)",
                  }}
                >
                  10+
                </Typography>
                <Typography sx={{ color: "white", fontSize: "10px" }}>
                  types of games
                </Typography>
              </MiniCard>
              <MiniCard>
                <Typography
                  sx={{
                    fontWeight: "900",
                    fontSize: "1.6rem",
                    color: "var(--yellow)",
                  }}
                >
                  200+
                </Typography>
                <Typography sx={{ color: "white", fontSize: "10px" }}>
                  types of lessons
                </Typography>
              </MiniCard>
              <MiniCard>
                <Typography
                  sx={{
                    fontWeight: "900",
                    fontSize: "1.6rem",
                    color: "var(--yellow)",
                  }}
                >
                  15
                </Typography>
                <Typography sx={{ color: "white", fontSize: "10px" }}>
                  school subjects
                </Typography>
              </MiniCard>
              <MiniCard>
                <Typography
                  sx={{
                    fontWeight: "900",
                    fontSize: "1.6rem",
                    color: "var(--yellow)",
                  }}
                >
                  400+
                </Typography>
                <Typography sx={{ color: "white", fontSize: "10px" }}>
                  educational games
                </Typography>
              </MiniCard>
              <Box sx={{ width: "9rem", mr: "1rem" }}>
                <StyledYellowButton
                  btnHandler={() => {}}
                  title={"Go to the game"}
                />
              </Box>
            </Box>

            <Typography
              sx={{
                color: "var(--yellow)",
                fontWeight: "800",
                fontSize: "1.1rem",
              }}
            >
              Start your studying with a tutor!
            </Typography>
            <Typography
              sx={{
                mt: "0.5rem",
                color: "white",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "0.04rem",
                fontFamily: "Poppins",
              }}
            >
              During 7 days you have access to one introductory lesson with a
              tutor for 30 min, where you will be able to become acquainted with
              a tutor, define your level of knowledge and discuss the program of
              studying. You can also invite your parent to the introductory
              lesson, where your parent can get know the tutor and discuss
              details{" "}
            </Typography>

            <Grid
              columnSpacing={"1rem"} 
              alignItems={"center"}
              container
              sx={{ mt: "2rem" }}
            >
              <Grid item sm={4}>
                <Typography
                  sx={{
                    color: "white",
                    fontSize: "12px",
                    lineHeight: "18px",
                    fontWeight: "400",
                  }}
                >
                  Please, choose a subject that you will learn
                </Typography>
                <input
                  placeholder="Location"
                  style={{
                    padding: "0.5rem",
                    width: "100%",
                    border: "none",
                    height: "46px",
                    backgroundColor: "#EDEDFF",
                    borderRadius: "10px",
                  }}
                />
              </Grid>
              <Grid item sm={4}>
                <Typography
                  sx={{
                    color: "white",
                    fontSize: "12px",
                    lineHeight: "18px",
                    fontWeight: "400",
                  }}
                >
                  Where do you want to meet with a tutor?{" "}
                </Typography>
                <input
                  placeholder="All subjects"
                  style={{
                    padding: "0.5rem",
                    width: "100%",
                    border: "none",
                    height: "46px",
                    backgroundColor: "#EDEDFF",
                    borderRadius: "10px",
                  }}
                />
              </Grid>
              <Grid item sm={4}>
                <Box sx={{ width: "80%" }}>
                  <StyledYellowButton title={"Find a tutor"} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
