import {
  Box,
  Divider,
  Grid,
  LinearProgress,
  Slider,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { CloseButton, StyledYellowButton } from "../../utils/Buttons";
import QuestionBg from "../../../assets/images/educator-questionnaire-box.svg";
import QuestionBg2 from "../../../assets/images/educator-questionnaire-box2.svg";
import { RearrangeAnswers } from "./RearrangeAnswers";
export const EducatorQuestions = ({
  state,
  changeScreen,
  closeQuestionnaire,
  pageHandlers,
  questionsList = [],
  handleQuestion,
  totalPages,
  currentPage,
  screens,
}) => {
  const [finishWarning, setfinishWarning] = useState(false);

  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: "var(--white)",
        borderRadius: "10px",
        maxWidth: "60rem",
        m: "0rem auto 10rem auto",
        p: "2rem 6rem",
      }}
    >
      <>
        <CloseButton closeHandler={closeQuestionnaire} />
        <Grid sx={{}} container rowSpacing="2rem" justifyContent="center">
          {questionsList
            .slice((currentPage - 1) * 4, (currentPage - 1) * 4 + 4)
            .map((question, index, arr) => {
              const questionNo = index + (currentPage - 1) * 4 + 1;
              return (
                <Grid item xs={6}>
                  <Box
                    sx={{
                      position: "relative",
                      mx: "auto",
                      mb: "0.7rem",
                      p: "2rem 3.4rem 1rem 1.8rem",
                      height: "14rem",
                      width: "100%",
                      textAlign: "center",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundImage: `url(${
                        index % 2 === 0 ? QuestionBg : QuestionBg2
                      })`,
                    }}
                    key={questionNo + " " + index}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "-3px",
                        left: "140px",
                        fontSize: "0.8rem",
                        color: "var(--orange)",
                        fontWeight: "600",
                      }}
                    >
                      Question {questionNo}
                    </div>
                    <RearrangeAnswers
                      dragEndHandler={(s, d) =>
                        handleQuestion(questionNo, s, d)
                      }
                      itemHeight="2rem"
                      answersList={questionsList[questionNo - 1]}
                    />
                  </Box>
                </Grid>
              );
            })}
        </Grid>
      </>
      {finishWarning && (
        <Grid item xs={12}>
          <Typography sx={{ color: "var(--orange)", marginTop: "1rem" }}>
            Please attempt all questions.
          </Typography>
        </Grid>
      )}
      <Grid mt={"1.3rem"} alignItems={"end"} columnSpacing={"1.5rem"} container>
        <Grid item xs={3}>
          {state.page !== 1 && (
            <StyledYellowButton
              title={"Previous question"}
              btnHandler={() => {
                pageHandlers.previousPage();
                setfinishWarning(false);
              }}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <Typography textAlign={"center"} mb="0.5rem">
            {currentPage}/{totalPages}
          </Typography>
          <LinearProgress
            variant="determinate"
            value={(currentPage / totalPages) * 100}
          />
        </Grid>
        <Grid item xs={3}>
          {state.page === totalPages ? (
            <>
              <StyledYellowButton
                title={"Finish"}
                btnHandler={() => {
                  changeScreen();
                }}
              />
            </>
          ) : (
            <Box width={"90%"}>
              <StyledYellowButton
                title={"Next questions"}
                btnHandler={() => {
                  pageHandlers.nextPage();
                  setfinishWarning(false);
                }}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
