import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Home } from "./Home";
import { useActions } from "../../../overmind";
import { questions } from "./data";
import { EducatorQuestions } from "./Questions";

const screens = {
  QUESTIONS: "QUESTIONS",
  HOME: "HOME",
  CHARTRESULT: "CHARTRESULT",
  RESULT: "RESULT",
};

const dialogs = {
  GO_TO_QUESTIONNAIRE_DIALOG: "GO_TO_QUESTIONNAIRE_DIALOG",
  CLOSE_CONFIRMATION_DIALOG: "CLOSE_CONFIRMATION_DIALOG",
};

export const Content = () => {
  const actions = useActions();
  const history = useHistory();
  const [state, setstate] = useState({
    page: 1,
    currentScreen: screens.HOME,
    currentDialog: null,
    questions: questions,
  });

  // Closes questionnnaire full screen dialog
  const closeQuestionnaire = () => {
    actions.openEducatorQuestionnaire(false);
  };

  const changeScreen = (screen) => {
    // Dialogs closed if opened while moving from screen to screen
    setstate((state) => ({
      ...state,
      currentScreen: screen,
      currentDialog: null,
    }));
  };

  const changeDialog = (dialog) => {
    // if dialog parameter is null - dialog closes
    setstate((state) => ({ ...state, currentDialog: dialog }));
  };

  const pageHandlers = {
    nextPage() {
      setstate((state) => ({ ...state, page: state.page + 1 }));
    },
    previousPage() {
      setstate((state) => ({ ...state, page: state.page - 1 }));
    },
  };

  const handleQuestion = (questionNo, sourceIdx, destIdx) => {
    setstate((prevState) => {
      const newques = prevState.questions;
      const que = newques[questionNo - 1];
      const temp = que[destIdx];
      que[destIdx] = que[sourceIdx];
      que[sourceIdx] = temp;
      newques[questionNo - 1] = que;
      return { ...prevState, questions: newques };
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: "var(--purple)",
        minHeight: "110vh",
        p: "1rem 3rem",
      }}
    >
      {/* SCREENS */}
      {state.currentScreen === screens.HOME && (
        <Home
          changeScreen={() => changeScreen(screens.QUESTIONS)}
          changeDialog={changeDialog}
          closeQuestionnaire={closeQuestionnaire}
          dialogs={dialogs}
        />
      )}

      {state.currentScreen === screens.QUESTIONS && (
        <EducatorQuestions
          changeScreen={() => {
            changeScreen(screens.CHARTRESULT);
          }}
          state={state}
          closeQuestionnaire={closeQuestionnaire}
          pageHandlers={pageHandlers}
          questionsList={questions}
          handleQuestion={handleQuestion}
          currentPage={state.page}
          totalPages={Math.ceil(questions.length / 4)}
          screens={screens}
        />
      )}
    </Box>
  );
};
