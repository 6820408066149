import { CircularProgress } from "@mui/material";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import { validateEmail } from "../../../service/commanFunction";
import { CustomSnackbar, useSnackState } from "../../../utils/useSnackState";

export function EmailRecovery({ handleClose, nextPopup }) {
  const [snackProps, open] = useSnackState();
  const [form, setform] = useState({
    email: "",
  });

  const error = {};
  const [isLoading, setisLoading] = useState(false);

  const handleSendLink = async () => {
    if (form.email.trim().length === 0 || !(await validateEmail(form.email))) {
      open("Invalid email", "error");
      return;
    }
    nextPopup();
  };
  const handleNotAccessToMail = async () => {};
  return (
    <Modal
      className="loginmodal"
      // {...props}
      show={true}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <CustomSnackbar {...snackProps} />
      <div className="login-modal-mainbx">
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>Account recovery</h4>
            <p>
              Enter your email that you put during the registration. We will
              send the link to recover
            </p>
          </Modal.Title>
          <button type="button" onClick={handleClose} className="close">
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="login-modl-form">
            <div className="loginform-bx">
              <Form.Group className="subsform-fild">
                <Form.Label>Your email is</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="example@email.com"
                  value={form.email}
                  onChange={(e) => setform({ ...form, email: e.target.value })}
                />
                {error.email && <span className="error">{error.email}</span>}
              </Form.Group>

              <div className="btnlogin-main">
                <button
                  style={{ fontSize: "0.8rem" }}
                  disabled={isLoading}
                  onClick={handleSendLink}
                  className="btnlogin btnweak-bx"
                >
                  {isLoading ? (
                    <CircularProgress
                      size={"1.5rem"}
                      sx={{ color: "var(--purple)" }}
                    />
                  ) : (
                    "Send the link"
                  )}
                </button>
              </div>
              <u
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  display: "block",
                  marginTop: "2.5rem",
                  marginBottom: "1rem",
                  color: "var(--orange)",
                }}
              >
                I don't have access to my email
              </u>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
}
