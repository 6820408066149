import React, { useEffect, useState } from "react";

import {
  // Navbar,
  // Nav,
  // NavDropdown,
  Container,
  // Dropdown,
  // br,
  // Row,
  // Accordion,
  // Card,
  Button,
  Form,
  Modal,
  // Select,
  // Sonnet,
} from "react-bootstrap";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PersonIcon from "@mui/icons-material/Person";

import logo from "../../../assets/images/logo.png";
// import gamesgalery1 from "../../../assets/images/games-galery1.jpg";
// import gamesgalery2 from "../../../assets/images/games-galery2.jpg";
// import chaticon from "../../../assets/images/chat-icon.svg";
// import batchorange from "../../../assets/images/batch-orange.svg";
// import palinblue from "../../../assets/images/palin-blue.svg";
// import mike from "../../../assets/images/mike.svg";
// import attachemtnblue from "../../../assets/images/attachemtn-blue.svg";
// import downarrowblue from "../../../assets/images/down-arrow-blue.svg";
// import dotthree from "../../../assets/images/dotthree.svg";
// import addbtnround from "../../../assets/images/add-btn.svg";
// import darkblueplan from "../../../assets/images/darkblue-plan.svg";
import backorange from "../../../assets/images/backorange.svg";
import editorangelg from "../../../assets/images/editlg-orange.svg";
import darkorangeplan from "../../../assets/images/darkorange-plan.svg";

// import Slider from "react-slick";
// import SliderCenter from "../../Slider";
import { Link, useParams } from "react-router-dom";
import FooterEducator from "../../layout/Footer/FooterEducator";
// import Addgroup from "../../Addgroup";
// import Addpupil from "../../Addpupil";
// import SendInvite from "../../SendInvite";
// import Groupmember from "../../Groupmember";
import HeaderGames from "../../layout/Header/HeaderGames";
// import SendRevision from "../../SendRevision";
import Selectstudent from "../../Selectstudent";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import {
  getSingalActivities,
  getSingalActivitiesGroup,
} from "../../../service/apis/activities";

function GroupTaskDetails() {
  // const [show, setShow] = React.useState("1");

  const taskId = useParams();
  const [task, setTask] = useState({});
  const [point, setPoint] = useState();
  const [title, settitle] = useState("");
  const [essence, setessence] = useState("");
  const [assignedById, setassignedById] = useState();
  const [deadline, setdeadline] = useState();
  const { acId, id, actyId } = taskId;
  const [formData, setFormdata] = useState({
    title: "",
    essence: "",
    assignedById: "",
    taskId: "",
    deadline,
    points: "",
    assignedToId: "",
  });

  console.log("taskk", task);
  // const [breadMenuShow, setBreadMenuShow] = React.useState(false);
  /* start modal */
  const [modalShow, setModalShow] = React.useState(false);
  /* end modal */

  /* startsave gamed step1 */
  const [modalgamesavedstep1Show, setgamesavedstep1ModalShow] =
    React.useState(false);

  /* end save gamned step1 */

  /* start send revision popup */
  // const [modalpersonaltskstep1Show, setpersonaltskstep1ModalShow] =
  //   React.useState(false);

  /* end send revision popup */

  /* start select student */
  const [modalAddgroupstep2Show, setAddgroupstep2ModalShow] =
    React.useState(false);

  /* end select student */

  const TastStatus = {
    0: "Testing",
    1: "New",
    2: "Revision",
    3: "Done",
    4: "Late",
  };

  // console.log(formData)
  useEffect(() => {
    const getDingal = async () => {
      const response = await getSingalActivitiesGroup(acId, id, actyId);
      console.log("response1", response);
      setTask(response);
      setPoint(response.points);
      settitle(response.taskTitle);
      setessence(response.taskDescription);
      setassignedById(response.assignedById);
      setdeadline(response.deadline);
      setFormdata({
        ...formData,
        title: response.taskTitle,
        essence: response.taskDescription,
        assignedById: response.assignedById,
        deadline: response.deadline,
        points: response.points,
        assignedToId: response.assignedToId,
        taskId: response.taskId,
      });
    };
    getDingal();
  }, [acId, id]);

  console.log(task);

  let studentsToMap = [];
  if (task?.activityName === "My Gameflows")
    studentsToMap = task.gameFlowGroupDetails;
  else if (task?.activityName === "Personal Task")
    studentsToMap = task.taskGroupDetails;
  else if (task?.activityName === "My Games")
    studentsToMap = task.minigameGroupDetails;
  else if (task?.activityName === "Neithedu Games") {
    studentsToMap = task?.minigameGroupDetails;
  } else {
    studentsToMap = task?.minigameGroupDetails;
  }

  console.log("studentsToMap", studentsToMap);

  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderGames setModalShow={(value) => setModalShow(value)} />
      {/* end header */}

      <div className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs">
        <div className="games-photosynths gamequiz-main perosnoal-task">
          <Container>
            <div className="quiz-game-popup">
              <div className="current-lesson-main ">
                <div className="current-lesson-contain">
                  <span className="back-btndrop">
                    <Link to="/educator" className="backbtn-lg">
                      <img src={backorange} />
                    </Link>
                  </span>
                  <div className="right-iconbox-quizz">
                    {/* <ul>
                      <li>
                        <Link
                          to="#"
                          className="editbtn-lgnew"
                          onClick={() => setAddgroupstep2ModalShow(true)}
                        >
                          <img src={darkorangeplan} />
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="editbtn-lgnew">
                          <img src={editorangelg} />
                        </Link>
                      </li>
                    </ul> */}
                  </div>

                  <div className="current-lesson-topbr">
                    <div className="medm-tex orng-clr">
                      <h5>
                        {task?.activityName}: {task?.gameType}
                      </h5>
                    </div>
                    <h1>{actyId === "7" ? task.taskTitle : task?.title}</h1>
                    <div className="lesson-status-list quiz-gamelession">
                      <ul className="lesson-listing-curnt">
                        <li>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Mode:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              {task.mode}
                            </span>
                          </div>
                          {task?.status && (
                            <div className="lesson-status-detail">
                              <span className="medm-tex orng-clr onelist">
                                Status:{" "}
                              </span>
                              <span className="medm-tex orng-clr twolist">
                                {task.status}
                              </span>
                            </div>
                          )}
                        </li>
                        <li>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Deadline:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              {task.deadline?.slice(0, 10)}
                            </span>
                          </div>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Created:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              {task.creationDate?.slice(0, 10)}
                            </span>
                          </div>
                        </li>
                        <li>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Total points:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              {task.points}
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {task.taskDescription && (
                <div className="personal-tsk-main">
                  <div className="persnl-taskiner">
                    <div className="task-content">
                      <p>
                        <strong>Description: </strong>
                        {task.taskDescription}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Container>
        </div>
        {console.log("studentsToMap", studentsToMap)}
        <Box
          sx={{
            backgroundColor: "white",
            mx: "3rem",
            mb: "5rem",
            p: "1rem",
            borderRadius: "1.2rem",
          }}
        >
          {studentsToMap?.map((stud, idx) => {
            console.log("studddd", stud);
            return (
              <Link
                to={`/educator/student-game-details/${actyId}/${stud.activityId}`}
                key={idx}
              >
                <Grid
                  container
                  columnSpacing={"1rem"}
                  key={idx}
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={4}
                    sx={{
                      backgroundColor: "#EDEDFF",
                      borderRadius: "1rem",
                      m: "0.4rem",
                      p: "0.4rem",
                    }}
                  >
                    <Stack
                      alignItems="center"
                      flexDirection="row"
                      columnGap={"1rem"}
                      ml="2rem"
                    >
                      <PersonIcon
                        sx={{
                          borderRadius: "50%",
                          color: "white",
                          backgroundColor: "var(--purple)",
                          p: "0.5rem",
                          fontSize: "2.5rem",
                        }}
                      />
                      <Typography>{stud.studentName}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={2} justifySelf="center">
                    <Box
                      sx={{
                        fontWeight: "700",
                        maxWidth: "9rem",
                        color: "white",
                        backgroundColor: "var(--orange)",
                        borderRadius: "0.7rem",
                        p: "0.1rem",
                        fontSize: "0.9rem",
                      }}
                    >
                      {stud.gameStatus}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <Typography textAlign="start">
                      Deadline: {stud.deadline}
                    </Typography>
                    {/* <div className="save-points">
                                <div className="btn-week">
                                  <Link to="#" className="btnweak-bx">
                                    <span className="btn-title">Send revision</span>
                                  </Link>
                                </div>
                              </div> */}
                  </Grid>
                </Grid>
                {/* {idx !== students.length - 1 && <Divider />} */}
              </Link>
            );
          })}
        </Box>
        {/* footer start */}
        <FooterEducator logo={logo} />
        {/* footer end */}
      </div>

      {/* start modal my student */}

      {/* start game save step1 */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        // {...props}
        show={modalgamesavedstep1Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2 mystudent-games">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>The game is saved</h4>
              <h5>Send the game to my students</h5>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setgamesavedstep1ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="serch-inner-group">
                    <div className="serchfiled-activities">
                      <Form.Control
                        type="text"
                        placeholder="Start typing name or year of study"
                        className="formbox"
                      />
                      <Button type="button" className="seachbtn">
                        <i className="fas fa-search"></i>
                      </Button>
                    </div>
                  </div>

                  <div className="newpupil-list-bx">
                    <Form.Group className="subsform-fild child-tex-fld height-max-flow">
                      <div className="child-boxnew">
                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Aaradhya Rayasam</h5>
                                  <h6>India, Mumbai 1 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Chandrashekhar Kalvakuntla</h5>
                                  <h6>India, Mumbai 3 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>7F Class</h5>
                                  <h6>Hamut Kapit, Kemal Mara and 12 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Additional group</h5>
                                  <h6>Laska Doe, Hamut Kapit and 4 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>8A Class for exams</h5>
                                  <h6>Hamut Kapit, Laska Doe and 8 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Chandrashekhar Kalvakuntla</h5>
                                  <h6>India, Mumbai 3 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </Form.Group>

                <div className="student-btn-savedgame">
                  <div className="btnlogin-main">
                    <button type="submit" className="btnlogin btnweak-bx">
                      Send to selected students
                    </button>
                  </div>
                </div>
                <div className="btnlogin-main">
                  <button type="submit" className="btnlogin btnweak-bx">
                    Later
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end Add a Member Group step2 */}

      {/* end modal my student */}

      {/* start select studen popup */}

      <Selectstudent
        modalAddgroupstep2Show={modalAddgroupstep2Show}
        setAddgroupstep2ModalShow={(value) => setAddgroupstep2ModalShow(value)}
      />

      {/* end select studen popup */}
    </div>
  );
}

export default GroupTaskDetails;
