import React, { useContext, useEffect, useState } from "react";
import "../../home.scss";
import "../../profile.scss";
import "../../media.scss";
import "../../games.scss";
import "../../loginModal/login.scss";
import "../../messages/messages.scss";
import "../../myactivities.scss";
import "../../gamestabs.scss";
import "../../gamesflowphotos.scss";
import "../../selectedgames.scss";
import { Pagination } from "@mui/material";
import { Container, Dropdown, Form } from "react-bootstrap";
import logo from "../../../assets/images/logo.png";
import backorange from "../../../assets/images/backorange.svg";
import editorangelg from "../../../assets/images/editlg-orange.svg";
import { Link, useLocation } from "react-router-dom";
import FooterEducator from "../../layout/Footer/FooterEducator";
import HeaderGames from "../../layout/Header/HeaderGames";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Box, Grid } from "@mui/material";
import {
  editGameflowDetails,
  editGameflowGames,
  getAllGamesList,
  getGameflowById,
} from "../../../service/apis/games";
import { CustomSnackbar, useSnackState } from "../../../utils/useSnackState";
import { getMaterialGamesList } from "../../../service/apis/materials";
import { SelectMyMaterail } from "../../educator/games/SelectMyMaterail";
import TopSearchBar from "../TopSearchBar";
import GameFlowModal from "./GameFlowModal";
import { NotificationContext } from "../../../contexts/Notification";

const screens = {
  SELECT_MY_GAMES: "SELECT_MY_GAMES",
  GAMEFLOW: "GAMEFLOW",
  MATERIAL: "MATERIAL",
};

function SelectGames({ addflowItems, setcurrentScreen, games: gamesData }) {
  let games = gamesData.map((game, i) => ({
    ...game,
    num: i,
  }));

  const [filteredData, setFilteredData] = useState(games);
  const [SearchData, setSearchData] = useState(games);
  const [selectedGames, setselectedGames] = useState([]);
  const [grade, setgrade] = useState("0");
  const [textSearch, settextSearch] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [postsPerPage] = useState(8);

  useEffect(() => {
    let searchReseult = games?.filter(
      (game) =>
        game.categoryName
          ?.toLowerCase()
          .trim()
          .includes(textSearch.toLowerCase()) ||
        game.title?.toLowerCase().trim().includes(textSearch.toLowerCase()),
    );
    if (grade !== "0") {
      searchReseult = searchReseult?.filter(
        (game) => game.studentGrade === grade,
      );
    }
    setSearchData(searchReseult);
    // setcurrentPage(1);
  }, [grade, textSearch]);

  // pagenation facutionality
  useEffect(() => {
    const lastPostIndex = currentPage * postsPerPage;
    const firstPostIndex = lastPostIndex - postsPerPage;
    const currentPosts = SearchData?.slice(firstPostIndex, lastPostIndex);
    setFilteredData(currentPosts);
  }, [SearchData, currentPage]);

  const handleOnGameSelect = (index) => {
    console.log("index", index);
    if (selectedGames.includes(index)) {
      setselectedGames((prevState) => prevState.filter((e) => e !== index));
    } else {
      setselectedGames((prevState) => [...prevState, index]);
    }
  };

  const handleAddSelectedGames = () => {
    // const items = selectedGames.map((gameidx) => {
    //   return {
    //     type: games[gameidx].gameType,
    //     title: games[gameidx].title,
    //     gameId: games[gameidx].id,
    //   };
    // });

    const selectedItems = games.filter((game) =>
      selectedGames.includes(game.num),
    );
    const items = selectedItems.map((game) => {
      return {
        type: game.gameType,
        title: game.title,
        gameId: game.id,
      };
    });

    addflowItems(items);
    setselectedGames([]);
    setcurrentScreen(screens.GAMEFLOW);
  };

  return (
    <Container>
      <div className="flownew-gamesbx">
        <div className="Minigames-bx">
          <div className="minigames-cards">
            <div className="close-flowgame">
              <div
                to="GamesTabs"
                className="btnclose-flowgm"
                onClick={() => {
                  setselectedGames([]);
                  setcurrentScreen(screens.GAMEFLOW);
                }}
              >
                <span aria-hidden="true">×</span>
              </div>
            </div>
            <div className="heading-top-flowgames">
              <h4>Select the minigames to add to gameflow Photo synthesis </h4>
            </div>
            <TopSearchBar
              selectedGrade={grade}
              btnClickHandler={handleAddSelectedGames}
              btnText={"Add selected games"}
              setSelectedGrade={setgrade}
              textSearch={textSearch}
              setTextSearch={settextSearch}
            />
            {console.log("selectedGames", selectedGames)}

            <Grid container spacing={2}>
              {filteredData?.map((game, idx) => {
                const isSelected =
                  selectedGames.find((e) => e === game?.num) !== undefined;

                return (
                  <Grid key={idx + " " + games.length} item xs={12} md={3}>
                    <div className="inner-cardquizz">
                      <label className="custm-check gameflow-check">
                        <Form.Control
                          onChange={() => {
                            handleOnGameSelect(game?.num);
                          }}
                          type="checkbox"
                          checked={isSelected}
                        />
                        <span className="checkmark"></span>
                        <div className="inner-bodyquiz">
                          <div className="top-list-quiz">
                            <div className="addlist-quiz">
                              <div className="quiz-listng-iner quiz-leftcard">
                                <div className="btn-listnew"></div>
                              </div>
                            </div>
                          </div>

                          <div className="quiz-body-content">
                            <div className="quiz-bdy-content-bx">
                              <h6>{game.gameType}</h6>
                              <h3>{game.title}</h3>
                              <h5>{game.subject}</h5>
                              <h4>{game.year}</h4>
                              <p>{game.desc}</p>
                              <div className="points-btn">
                                <span className="totalpoints-btn">
                                  {game.points} points
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </label>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Pagination
              count={Math.ceil(SearchData?.length / postsPerPage)}
              color="primary"
              onChange={(e, p) => setcurrentPage(p)}
            />
          </div>
        </div>
      </div>
    </Container>
  );
}
const dropdownOptions = {
  mygames: "My games",
  neithedu: "Neithedu",
  material: "Material",
};

function Gamesflowstwo() {
  const [currentScreen, setcurrentScreen] = useState(screens.GAMEFLOW);
  const [flowItems, setflowItems] = React.useState([]);
  const [dragItem, setdragItem] = useState(-1);
  const [details, setdetails] = useState({
    title: "",
    description: "",
    categoryName: "",
    gameflowId: "",
  });
  const [allGames, setAllGames] = useState({});
  const [selectedGameType, setselectedGameType] = useState(
    dropdownOptions.mygames,
  );

  const [snackProps, snackOpen] = useSnackState();

  const [isEditModalOpen, setisEditModalOpen] = useState(false);
  const { showNotification } = useContext(NotificationContext);

  const location = useLocation();

  const handleRemoveItem = (idx) => {
    let _flowItems = [...flowItems];
    _flowItems.splice(idx, 1);
    // map and set gamePosition
    _flowItems = _flowItems.map((item, index) => {
      return {
        ...item,
        gamePosition: index,
      };
    });
    setflowItems(_flowItems);
  };

  const addflowItems = (gamesArr) => {
    console.log("gamesArr", gamesArr);
    let updatedGames = [...flowItems, ...gamesArr];

    // check if duplicates exist
    const duplicatExists = updatedGames.some(
      (item, index, self) =>
        index !==
        self.findIndex(
          (t) =>
            t.title === item.title &&
            t.type === item.type &&
            t.gameId === item.gameId,
        ),
    );
    if (duplicatExists) {
      snackOpen("Duplicate games were not added", "error");
    }

    // remove duplicates
    updatedGames = updatedGames.filter(
      (item, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            t.title === item.title &&
            t.type === item.type &&
            t.gameId === item.gameId,
        ),
    );
    // map and set gamePosition
    updatedGames = updatedGames.map((item, index) => ({
      ...item,
      gamePosition: index,
    }));
    setflowItems(updatedGames);
  };

  const onDragStartHandler = (e) => {
    setdragItem(e.source.index);
  };

  const onDragEndHandler = async (e) => {
    if (!e.destination) return;
    setdragItem(-1);
    let _flowItems = [...flowItems];
    const temp = _flowItems[e.source.index];
    _flowItems[e.source.index] = flowItems[e.destination.index];
    _flowItems[e.destination.index] = temp;

    // map and set gamePosition
    _flowItems = _flowItems.map((item, index) => ({
      ...item,
      gamePosition: index,
    }));

    setflowItems(_flowItems);
  };

  useEffect(() => {
    fetchInitialData();
    fetchAllGamesAndMaterial();
  }, []);

  const fetchInitialData = async () => {
    const queryParams = new URLSearchParams(location.search);
    const gameflowId = queryParams.get("gameflowId");
    const gameflowDetails = await getGameflowById(gameflowId);
    console.log("gameflowDetails", gameflowDetails);
    setdetails(gameflowDetails);

    const gameFlowGames = [];
    gameflowDetails?.games.map((game, index) => {
      gameFlowGames.push({
        type: game.gameTypeName,
        title: game.title,
        gamePosition: index,
        gameId: game.gameId,
      });
    });

    let flowItemsArr = gameFlowGames;
    // sort by gamePosition from lowest to highest
    flowItemsArr = flowItemsArr.sort((a, b) => a.gamePosition - b.gamePosition);
    flowItemsArr = flowItemsArr.map((item, index) => ({
      ...item,
      gamePosition: index,
    }));
    console.log("gamesdetailss", gameFlowGames);
    setflowItems(flowItemsArr);
  };

  const saveGameFlowHandler = async () => {
    console.log("flowItemssaving", flowItems);
    try {
      let _games = flowItems.map((item) => {
        let _type;
        if (item.type) {
          if (item.type.toLowerCase() === "quiz") _type = 1;
          else if (item.type.toLowerCase() === "crossword") _type = 2;
          else if (item.type.toLowerCase() === "wordsearch") _type = 3;
          else if (item.type.toLowerCase() === "material") _type = 4;
        } else {
          _type = 4;
        }
        return {
          gamePosition: item.gamePosition,
          gameType: _type,
          gameId: item.gameId || item.id,
        };
      });
      console.log("details.game", _games, details.id);
      const editResult = await editGameflowGames(+details.id, _games);
      console.log("editResult", editResult);
      showNotification("Gameflow saved successfully");
    } catch (err) {
      console.log("saving the gameflow err", err);
      snackOpen("An error occured while saving the gameflow", "error");
    }
  };

  const fetchAllGamesAndMaterial = async () => {
    try {
      let res = await getAllGamesList();
      localStorage.setItem("gamesData", JSON.stringify(res));

      let allMaterial = await getMaterialGamesList();
      let neithEduMaterial = allMaterial.illumiyaMaterials;
      let adminMinigames = res.adminMinigames;
      let myMinigames = res.myMinigames;
      let material = neithEduMaterial;
      // sort by id with high to low
      adminMinigames.sort((a, b) => b.id - a.id);
      myMinigames.sort((a, b) => b.id - a.id);
      material.sort((a, b) => b.id - a.id);

      res.adminMinigames = adminMinigames;
      res.myMinigames = myMinigames;
      res.material = material;
      setAllGames(res);
    } catch (e) {}
  };

  const editGameflowDetailsHandler = async (data) => {
    try {
      await editGameflowDetails(details.id, data.title, data.description);
      showNotification("Gameflow details updated successfully");
      await fetchInitialData();
    } catch (e) {
      console.log("error while editing gameflow details", e);
    }
  };

  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderGames />
      {/* end header */}

      <div className="games-dashboard-main dashboard-bgnew min-h-screen-fill blur-bgg gamestabs">
        {currentScreen === screens.SELECT_MY_GAMES && (
          <SelectGames
            addflowItems={addflowItems}
            setcurrentScreen={setcurrentScreen}
            games={
              selectedGameType === dropdownOptions.mygames
                ? allGames.myMinigames
                : selectedGameType === dropdownOptions.neithedu
                ? allGames.adminMinigames
                : allGames.material
            }
          />
        )}
        {currentScreen === screens.GAMEFLOW && (
          <>
            <CustomSnackbar {...snackProps} />
            <div className="games-photosynths">
              <Container>
                <div className="games-photosth-main gamesflows-twobx">
                  <div className="games-photsth-header">
                    <h1>{details.title}</h1>
                    <Link to="GamesTabs" className="backbtn-lg">
                      <img src={backorange} alt="" />
                    </Link>
                    <div
                      className="editbtn-lg"
                      onClick={() => setisEditModalOpen(true)}
                    >
                      <img src={editorangelg} alt="" />
                    </div>
                  </div>
                  <div className="photosty-games-crd">
                    <div className="photosty-inercrd">
                      <div className="iner-photosth-conent">
                        <p>{details.description}</p>
                      </div>
                      <div className="gameflow-photosth-play">
                        <div className="flow-listview">
                          <div className="flowlisting-view">
                            <DragDropContext
                              onDragStart={onDragStartHandler}
                              onDragEnd={onDragEndHandler}
                            >
                              <Droppable
                                droppableId="gameflowcomp"
                                direction="horizontal"
                              >
                                {(provider) => (
                                  <ul
                                    {...provider.droppableProps}
                                    ref={provider.innerRef}
                                    className="quizflow-list"
                                  >
                                    {flowItems.map((game, idx, arr) => {
                                      return (
                                        <Draggable
                                          key={game.title + game.type + idx}
                                          draggableId={
                                            game.title + game.type + idx
                                          }
                                          index={idx}
                                        >
                                          {(provided) => (
                                            <li
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              ref={provided.innerRef}
                                              className="active"
                                            >
                                              {idx === arr.length - 1 ? (
                                                <Box
                                                  onClick={() =>
                                                    handleRemoveItem(idx)
                                                  }
                                                  sx={{
                                                    ":after": {
                                                      backgroundColor:
                                                        dragItem === idx
                                                          ? "transparent !important"
                                                          : "",
                                                    },
                                                  }}
                                                  className="listin-quizflow iconminus"
                                                >
                                                  <div className="quixlok">
                                                    <span className="lockquiz-icon">
                                                      <i className="fas fa-minus"></i>
                                                    </span>
                                                  </div>
                                                  <div className="flw-quiz-content">
                                                    <div className="sml-tex">
                                                      {game.type}{" "}
                                                    </div>
                                                    <div className="medm-tex">
                                                      {game.title}
                                                    </div>
                                                  </div>
                                                </Box>
                                              ) : (
                                                <Box
                                                  className="listin-quizflow"
                                                  sx={{
                                                    ":after": {
                                                      backgroundColor:
                                                        dragItem === idx
                                                          ? "transparent !important"
                                                          : "",
                                                    },
                                                  }}
                                                >
                                                  <div className="quixlok">
                                                    <span className="lockquiz-icon"></span>
                                                  </div>
                                                  <div className="flw-quiz-content">
                                                    <div className="sml-tex">
                                                      {game.type}
                                                    </div>
                                                    <div className="medm-tex">
                                                      {game.title}
                                                    </div>
                                                  </div>
                                                </Box>
                                              )}
                                            </li>
                                          )}
                                        </Draggable>
                                      );
                                    })}
                                    {provider.placeholder}

                                    <li>
                                      <div className="listin-quizflow">
                                        <div className="quixlok">
                                          <Dropdown className="dropdown-main dropmenu-custlistt">
                                            <Dropdown.Toggle
                                              variant="success"
                                              id="dropdown-basic"
                                              className="dropdown-btnn lockquiz-icon"
                                            >
                                              <i className="fas fa-plus"></i>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className="dropdown-btn-item customdrop-listmenu">
                                              <Dropdown.Item
                                                onClick={() => {
                                                  setcurrentScreen(
                                                    screens.SELECT_MY_GAMES,
                                                  );
                                                  setselectedGameType(
                                                    dropdownOptions.mygames,
                                                  );
                                                }}
                                              >
                                                <span className="rightarrow-icon">
                                                  <i className="fas fa-angle-right"></i>
                                                </span>{" "}
                                                My games
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  setcurrentScreen(
                                                    screens.SELECT_MY_GAMES,
                                                  );
                                                  setselectedGameType(
                                                    dropdownOptions.neithedu,
                                                  );
                                                }}
                                              >
                                                {" "}
                                                <span className="rightarrow-icon">
                                                  <i className="fas fa-angle-right"></i>
                                                </span>{" "}
                                                Neithedu games
                                              </Dropdown.Item>

                                              <Dropdown.Item
                                                onClick={() => {
                                                  setcurrentScreen(
                                                    screens.MATERIAL,
                                                  );
                                                }}
                                              >
                                                {" "}
                                                <span className="rightarrow-icon">
                                                  <i className="fas fa-angle-right"></i>
                                                </span>{" "}
                                                Materal
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                )}
                              </Droppable>
                            </DragDropContext>
                          </div>

                          <div className="flowgame-btnn">
                            <ul className="gameflw-save">
                              <li>
                                <div
                                  className="btn-buy gamestart-btnn"
                                  onClick={saveGameFlowHandler}
                                >
                                  <div className="btn-week">
                                    <div className="btnweak-bx">
                                      <span className="btn-title">
                                        Save Gameflow
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Container>
              {isEditModalOpen && (
                <GameFlowModal
                  open={isEditModalOpen}
                  closeModal={() => setisEditModalOpen(false)}
                  submitHandler={editGameflowDetailsHandler}
                  GameTypes={[
                    {
                      id: 1,
                      name: "Quiz",
                    },
                  ]}
                  modalTitle={"Edit Gameflow"}
                  footerText={"Save gameflow"}
                  title={details.title}
                  description={details.description}
                  isGameFlowEditing={true}
                />
              )}
            </div>

            {/* footer start */}
            <FooterEducator logo={logo} />
            {/* footer end */}
          </>
        )}
        {currentScreen === screens.MATERIAL && (
          <SelectMyMaterail
            type="modal"
            selectHandler={(items) => {
              console.log("items", items);
              addflowItems(items.selectedGames);
            }}
            closeHandler={() => setcurrentScreen(screens.GAMEFLOW)}
          />
        )}
      </div>
    </div>
  );
}

export default Gamesflowstwo;
