import "../styles/chat.css";
import { useState, useRef, useEffect } from "react";
import Send from "../images/new/send.svg";

function Chat(props) {
  const { toggleChat, sendChatMessage, messages } = props;
  const [formValue, setFormValue] = useState("");

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    messagesEndRef.current?.scrollIntoView();
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const sendMessage = async (e) => {
    e.preventDefault();

    disableAnimation();
    sendChatMessage(formValue);

    setFormValue("");
  };

  const disableAnimation = async () => {
    messages.map((element) => {
      element.animation = false;
    });
  };

  return (
    <div className="chat-wrapper">
      <header className="chat-header">
        <span className="close-icon" onClick={toggleChat}>
          X
        </span>
        <span>Chat Room</span>
      </header>

      <div className="messages-wrapper">
        {messages &&
          messages.map((msg, i) => {
            return (
              <div key={i}>
                {msg.animation ? (
                  <p className={msg.style}>
                    {msg.value}
                    <span>{msg.timeStamp}</span>
                  </p>
                ) : (
                  <p className={msg.style}>
                    {msg.value}
                    <span>{msg.timeStamp}</span>
                  </p>
                )}
              </div>
            );
          })}
        <div ref={messagesEndRef} />
      </div>

      <form onSubmit={sendMessage} className="chat-input">
        <input
          /*ref={inputRef}*/
          value={formValue}
          onChange={(e) => setFormValue(e.target.value)}
          placeholder="Enter text here"
        />
        {formValue && (
          <button disabled={!formValue} className="send-btn">
            <img src={Send} alt="" className="send-btn" />
          </button>
        )}
      </form>
    </div>
  );
}

export default Chat;
