import { Axios } from "../Axios";

export const addAssignTask = async (
  formData,
  assignedBy,
  assignedTo,
  group,
  typeId = 7,
) => {
  const res = await Axios.post("/tasks/add-assign-task", {
    title: formData.title,
    // typeId: typeId,
    description: formData.essence,
    activityModel: {
      assignedBy: assignedBy,
      assignedTo: assignedTo,
      isAssignedToGroup: group,
      points: formData.points,
      pointsStatus: 0,
      dueDate: formData.deadline,
    },
    // taskPoints: {
    //     status: 2
    // }
  });
  return res.data;
};

export const addParentTask = async (
  formData,
  assignedBy,
  assignedTo,
  group,
  typeId = 7,
) => {
  const res = await Axios.post("/tasks/parents/create", {
    title: formData.title,
    typeId: typeId,
    description: formData.description,
    activityModel: {
      // assignedBy: assignedBy,
      assignedTo: assignedTo,
      // isAssignedToGroup: group,
      points: formData.points,
      // pointsStatus:0,
      dueDate: formData.dueDate,
    },
  });
  return res.data;
};

export const MitalAssignTask = async (formData, ppp) => {
  const res = await Axios.post("/tasks/add-assign-task", {
    title: formData.title,
    typeId: 41,
    description: formData.essence,
    activityModel: {
      assignedBy: formData.assignedBy,
      assignedTo: ppp,
      isAssignedToGroup: false,
      points: formData.points,
      dueDate: formData.deadline,
    },
    taskPoints: {
      status: 0,
    },
  });

  // console.log("hello dsdsddsdsdsdsdsdsdsd" +ppp)

  return res.data;
};

export const updateTask = async (formData) => {
  const res = await Axios.put("/tasks", {
    title: formData.title,
    description: formData.essence,
    activity: {
      id: formData.id,
      points: formData.points,
      pointsStatus: 0,
      dueDate: formData.deadline,
    },
  });
  return res.data;
};

export const updateTaskPonit = async (formData, point) => {
  console.log("formDataupdate", formData);

  const res = await Axios.put("/tasks", {
    title: formData.title,
    description: formData.essence,
    activity: {
      id: formData.id,
      points: point,
      pointsStatus: 2,
      dueDate: new Date(formData.deadline),
    },
  });
  return res.data;
};

export const sendForRevision = async (taskId, comment, points, deadline) => {
  const res = await Axios.put("/tasks/educators/send-for-revision", {
    taskActivityId: taskId,
    comment: comment,
    revisionPoints: points,
    deadLine: deadline,
  });

  return res.data;
};

export const getStudentTask = async () => {
  const res = await Axios.get("/tasks/students/list");

  return res.data;
};

export const getSingalTask = async (id) => {
  const res = await Axios.get("/tasks/" + id);

  return res.data;
};

export const sendAnswer = async (answer, id) => {
  const res = await Axios.put("/tasks/students/update-status", {
    id: id,
    status: 3,
    answer: answer,
  });

  return res.data;
};

export const markOrCompleteEduOrParentTask = async (points, id) => {
  const res = await Axios.put("/tasks/educators/update-status", {
    id: Number(id),
    status: 7,
    earnedPoints: points,
  });
  return res.data;
};

export const markAsCompleteParentPersonalTask = async (id) => {
  const res = await Axios.put("/tasks/parents/update-status", {
    id: Number(id),
    status: 7,
  });
  return res.data;
};
