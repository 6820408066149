export function filterArray(array, num) {
  var arr1 = array.filter(function (el) {
    return el.milestoneType === num;
  });
  return arr1;
}

export function sortArray(array) {
  var arr2 = array.sort((a, b) => {
    return a.pointsThresholdValue - b.pointsThresholdValue;
  });
  return arr2;
}

export function activeMilestones(array, points) {
  var arr1 = array.slice(0, 7);

  let indicator = 0;
  let final_array = arr1.map((m, i) => {
    let obj = { ...m };
    if (m.pointsThresholdValue < points) {
      obj.value = m.pointsThresholdValue;
      obj.label = ` ${Number(m.pointsThresholdValue) / 1000}K`;
      obj.disabled = false;
      indicator = i;
    } else {
      obj.value = m.pointsThresholdValue;
      obj.label = ` ${Number(m.pointsThresholdValue) / 1000}K`;
      obj.disabled = true;
    }
    return obj;
  });
  // var arr1 = array.filter(function (el) {
  // return el.pointsThresholdValue <= points;
  // });
  // console.log(final_array, "<-- final_array");
  return final_array;
}

export function updatedPopupMilestones(array, points) {
  // console.log(array, "fullArray");
  var arr1 = array.slice(1, 9);
  // var arr1 = array.filter(function (el) {
  // return el.pointsThresholdValue <= points;
  // });
  return arr1;
}

export function filterSyllabusId(array, id) {
  var arr1 = array.filter(function (el) {
    return el.id === id;
  });
  return arr1;
}

export function filterArray2(array) {
  var arr1 = array.filter(function (el) {
    return el.milestoneType === 2;
  });
  return arr1;
}

export function filterCountryId(array, country) {
  var arr1 = array.filter(function (el) {
    return el.name === country;
  });
  return arr1;
}

export function filterCityId(array, city) {
  var arr1 = array.filter(function (el) {
    return el.name === city;
  });
  return arr1;
}
export function highestMilestonePoints(array) {
  var arr1 = [];
  array.map((a) => {
    arr1 = Math.max(a.pointsThresholdValue);
  });
  return arr1;
}

export function findTwoNumbers(nums, target) {
  // sort array
  nums.sort(function (a, b) {
    return a - b;
  });
  // find the index of target
  const index = nums.findIndex((num) => num >= target);
  if (index === 0) return [nums[0], nums[1]];
  if (index === nums.length - 1)
    return [nums[nums.length - 2], nums[nums.length - 1]];
  return [nums[index - 1], nums[index]];
}

export function greatestArray(array, num) {
  var arr1 = array.filter(function (el) {
    return el.pointsThresholdValue > num;
  });
  return arr1;
}

export function revealedMilestonesArray(array, num) {
  var arr1 = array.filter(function (el) {
    return num > el.pointsThresholdValue;
  });
  return arr1;
}

export function notRevealedMilestoneArray(array, num) {
  var arr1 = array.filter(function (el) {
    return num === el.pointsThresholdValue;
  });
  return arr1;
}

export function personalRewardnotRevealedMilestoneArray(array, num) {
  var arr1 = array.filter(function (el) {
    return el.pointsThresholdValue > num;
  });
  return arr1;
}
