import { Axios } from "../Axios";

const updateStudentProfile = async (
  firstName,
  lastName,
  countryId,
  cityId,
  genderId,
  languageOfAccountId,
  userId,
  gradeId,
  grade,
  // yearsOfStudy,
  // gradeId,
) => {
  console.log(firstName, "firstname");
  console.log(lastName, "lastName");
  console.log(countryId, "countryId");
  console.log(cityId, "cityId");
  console.log(genderId, "genderId");
  console.log(languageOfAccountId, "languageOfAccountId");
  console.log(gradeId, "gradeId");
  console.log(grade, " grade,");
  console.log(userId, "userId");

  return await Axios.post("/profile/update/student", {
    firstName,
    lastName,
    countryId,
    cityId,
    genderId,
    languageOfAccountId,

    userId,
    gradeId,
    grade,
  });
};

export const updateStudentSyllabus = async (syllabusId, studentId) => {
  // console.log(typeof syllabusId, typeof studentId, "42");
  return (
    await Axios.post("/update-student-on-first-time-login", {
      isFirstTimeLogin: false,
      syllabusId: syllabusId,
      studentId: studentId,
    })
  ).data;
};

const searchFriends = async ({ searchKeyword, city = 0, yearsOfStudy = 0 }) =>
  (
    await Axios.post("/friends/search-friend", {
      searchKeyword,
      city,
      yearsOfStudy,
    })
  ).data;

const sendFriendRequest = async (id) =>
  (await Axios.get("/friends/send-request/" + id)).data;
const getFriendRequests = async () =>
  (await Axios.get("/friends/get-requests")).data;
const friendDetails = async (id) =>
  (await Axios.get("/friends/get-student-details/" + id)).data;
const blockFriend = async (id) =>
  (await Axios.get("/friends/block/" + id)).data;
const rejectFriendRequest = async (id) =>
  (await Axios.get("/friends/accept-reject/" + id + "/false")).data;
const acceptFriendRequest = async (id) =>
  (await Axios.get("/friends/accept-reject/" + id + "/true")).data;
const topStudents = async () => (await Axios.get("/friends/top-student")).data;
export const getActiveGames = async (userId) =>
  (await Axios.get(`/games/active-games/${userId}`)).data;

export const StudentAPI = {
  updateStudentProfile,
  searchFriends,
  sendFriendRequest,
  getFriendRequests,
  friendDetails,
  blockFriend,
  rejectFriendRequest,
  acceptFriendRequest,
  topStudents,
};
