import { userRole } from "./constant";
import validator from "validator";

export function roleBasedRedirect(role, isFirstTimeLogin) {
  switch (role) {
    case userRole.STUDENT:
      if (isFirstTimeLogin) {
        return "/studentProfile";
      }
      return "/dashboard";
    case userRole.EDUCATOR:
      if (isFirstTimeLogin) {
        return "/Myprofile";
      } else return "/educator";
    case userRole.TUTOR:
      if (isFirstTimeLogin) {
        return "/Myprofile";
      }
      return "/Myprofile";
    case userRole.PARENT:
      return "/ParentAccount";
    default:
      return "/";
  }
}
export function settingsRedirect(roleId) {
  switch (roleId) {
    case userRole.STUDENT:
      return "/studentProfile";
    case userRole.EDUCATOR:
      return "/myProfile";

    default:
      return "/";
  }
}

export async function validateEmail(email) {
  return Promise.resolve(validator.isEmail(email));
}
