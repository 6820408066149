import React, { useContext, useEffect, useState } from "react";
import "../home.scss";
import "../profile.scss";
import "../media.scss";
import "../games.scss";
import "../loginModal/login.scss";
import "../myactivities.scss";
import AddIcon from "@mui/icons-material/Add";
import { Dropdown, Button, Form, Modal } from "react-bootstrap";
import { Tabs } from "react-tabs";
import logo from "../../assets/images/logo.png";
import batchorange from "../../assets/images/batch-orange.svg";
import CloseIcon from "@mui/icons-material/Close";

import { Link, useHistory, useLocation } from "react-router-dom";
import FooterEducator from "../layout/Footer/FooterEducator";
import Addgroup from "../Addgroup";
import Addpupil from "../Addpupil";
import SendInvite from "../SendInvite";
import Groupmember from "../Groupmember";
import HeaderTutor from "../layout/Header/HeaderTutor";
// import HeaderGames from "../layout/Header/HeaderGames";
import {
  addStudentsInGroup,
  addStudentToGroup,
  deleteEducatorGroup,
  deleteEducatorStudent,
  deleteEducatorStudentFromGroup,
  getEducatorGroups,
  getEducatorStudents,
  getEducatorStuGrp,
} from "../../service/apis/educator";
import {
  getGrpActivities,
  getAllActivitiesstudent,
  // getGrpAllActivities
} from "../../service/apis/activities";
import { CreateLiveLesson } from "../../service/apis/lesson";
import { Box, Container, Typography } from "@mui/material";
import { CreateLiveLessonModal } from "../liveLessons/dialogs/CreateLiveLesson";
import { LiveLessonCreated } from "../liveLessons/dialogs/LiveLessonCreated";
import {
  PersonalTaskDialogs,
  SelectStudents,
} from "../educator/personalTask/PersonalTaskDialogs";
import { CreateNewGameDialog } from "../educator/games/CreateGameDialog";
import {
  BadgesPopup,
  CreateCustomBadge,
  SendBadgeToGroupStudents,
} from "../educator/badges/BadgesPopup";
import { BuyBadges } from "../educator/badges/BuyBadges";
import { DialogBox } from "../utils/DialogBox";
import { StyledYellowButton } from "../utils/Buttons";
// import { StyledTextField } from "../utils/InputFields";
import { TempNotificationModal } from "../dialogs/TempNotificationModal";
import { useAppState } from "../../overmind";
import { userRole } from "../../service/constant";
import { CustomSnackbar, useSnackState } from "../../utils/useSnackState";
import { addAssignTask } from "../../service/apis/tasks";
import { useQuery } from "@tanstack/react-query";
import { NotificationContext } from "../../contexts/Notification";

const dialogs = {
  CREATE_NEW_GAME: "CREATE_NEW_GAME",
  CREATE_LIVE: "CREATE_LIVE",
  CREATED: "CREATED",
  SELECT_STUDENTS: "SELECT_STUDENTS",
  CREATE_PERSONAL_TASK: "CREATE_PERSONAL_TASK",
  BADGES: "BADGES",
  BUY_BADGES: "BUY_BADGES",
  CREATE_CUSTOM_BADGE: "CREATE_CUSTOM_BADGE",
  BADGE_SENT_CONFIMRMATION: "BADGE_SENT_CONFIMRMATION",
  SEND_BADGE_TO_GROUP_STUDENTS: "SEND_BADGE_TO_GROUP_STUDENTS",
  ACTIVITY_SENT: "ACTIVITY_SENT",
};

const TastStatus = {
  0: "Testing",
  1: "New",
  2: "Revision",
  3: "Done",
  4: "Late",
  7: "Completed",
};

const UserRole = {
  3: "Educator",
};

function Tutor() {
  const [snackProps, open] = useSnackState();
  const [modalAddgroupstep1Show, setAddgroupstep1ModalShow] = useState(false);
  const [modalEducatorstep1Show, setEducatorstep1ModalShow] = useState(false);
  const [modalinvitestep1Show, setinvitestep1ModalShow] = useState(false);
  const [modalgroupmemberstep1Show, setgroupmemberstep1ModalShow] =
    useState(false);
  const [modalAddgroupstep2Show, setAddgroupstep2ModalShow] = useState(false);
  const [modalgroupmemberstep3Show, setgroupmemberstep3ModalShow] =
    useState(false);

  /* start Add Memnber Group step3 */
  const [modalgroupmemberstep4Show, setgroupmemberstep4ModalShow] =
    useState(false);

  const [deleteStudentConfirmDialog, setdeleteStudentConfirmDialog] =
    useState(false);

  const [deleteGroupConfirmDialog, setdeleteGroupConfirmDialog] =
    useState(false);
  const [querySearch, setquerySearch] = useState("");

  const { showNotification } = useContext(NotificationContext);

  const {
    data: eductorStudentsAndGroups,
    isLoading: educatorStudentsAndGroupsLoading,
    refetch: refetchStudentsAndGroups,
  } = useQuery({
    queryKey: ["educatorStudentsGroups"],
    queryFn: async () => await getEducatorStuGrp(0, 0),
  });

  const [selectedStudentOrGroup, setSelectedStudentOrGroup] = useState(null);

  const { data: activitiesData, refetch: refetchActivities } = useQuery({
    queryKey: [
      "activities",
      selectedStudentOrGroup?.id,
      selectedStudentOrGroup?.educatorId,
    ],
    queryFn: async () => {
      if (!selectedStudentOrGroup) return [];
      if (selectedStudentOrGroup?.isGroup) {
        return await getGrpActivities(
          selectedStudentOrGroup?.id,
          selectedStudentOrGroup?.educatorId,
        );
      } else if (!selectedStudentOrGroup?.isGroup) {
        return await getAllActivitiesstudent(
          selectedStudentOrGroup?.id,
          selectedStudentOrGroup?.educatorId,
        );
      }
    },
    enabled: !!selectedStudentOrGroup,
  });

  const { data: groupsDetails, refetch: refetchGroupsDetails } = useQuery({
    queryKey: ["groupsDetails"],
    queryFn: async () => await getEducatorGroups(),
  });

  const createBtnHandler = async (e) => {
    console.log("Lesson created");

    try {
      const response = await CreateLiveLesson("This is first lesson");

      console.log("Response : ", response);
      setOpenDialog(dialogs.CREATED);
    } catch (err) {
      console.log("Error : ", err.message);
    }
  };

  const [openDialog, setOpenDialog] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const studentId = query.get("studentId");

  useEffect(() => {
    console.log("studentId", studentId);
    if (!eductorStudentsAndGroups || !eductorStudentsAndGroups[0]) return;

    if (studentId === selectedStudentOrGroup?.id) return;
    if (studentId) {
      const selectedStudent = eductorStudentsAndGroups?.find(
        (student) => student.id == studentId,
      );
      if (selectedStudent) setSelectedStudentOrGroup(selectedStudent);
      else {
        setSelectedStudentOrGroup(eductorStudentsAndGroups[0]);
        history.push({
          pathname: location.pathname,
          search: `?studentId=${eductorStudentsAndGroups[0]?.id}`,
        });
      }
    } else {
      setSelectedStudentOrGroup(eductorStudentsAndGroups[0]);
      history.push({
        pathname: location.pathname,
        search: `?studentId=${eductorStudentsAndGroups[0].id}`,
      });
    }
  }, [eductorStudentsAndGroups, location]);

  const history = useHistory();

  const addStudents = async ({
    selectedStudents,
    selectedGroupId,
    selectedGroupName,
  }) => {
    await addStudentToGroup(
      selectedStudents,
      selectedGroupId,
      selectedGroupName,
    );
    refetchStudentsAndGroups();
  };

  const addStusInGroup = async (grpId, stus) => {
    await addStudentsInGroup(grpId, stus);
  };

  const AddAssignTask = async (formData, curUserId, assignedTo, group) => {
    const res = await addAssignTask(formData, curUserId, assignedTo, group);
    return res;
  };

  const deleteStudentFromGroup = async (groupId, studentId) => {
    try {
      await deleteEducatorStudentFromGroup(groupId, studentId);
      await refetchGroupsDetails();
    } catch (err) {}
  };

  const deleteStudentHandler = async () => {
    try {
      await deleteEducatorStudent(selectedStudentOrGroup.id);
      await refetchStudentsAndGroups();
    } catch (err) {}
  };

  const deleteGroupHandler = async () => {
    try {
      await deleteEducatorGroup(selectedStudentOrGroup.id);
      refetchStudentsAndGroups();
    } catch (err) {}
  };

  const searchedStudentsAndGroups = eductorStudentsAndGroups?.filter(
    (stugrp) => {
      return stugrp.name
        ?.toLowerCase()
        .trim()
        .includes(querySearch.toLowerCase());
    },
  );

  const createPersonalTaskHandler = async (formData) => {
    if (
      !formData.title ||
      !formData.essence ||
      !formData.points ||
      !formData.deadline
    )
      return;

    const taskId = await AddAssignTask(
      formData,
      selectedStudentOrGroup.educatorId,
      selectedStudentOrGroup.id,
      selectedStudentOrGroup.isGroup,
    );
    if (taskId) {
      await refetchActivities();
    }
    showNotification("Task sent successfully");
  };

  const studentsInGroup = selectedStudentOrGroup?.isGroup
    ? groupsDetails?.find((group) => group.id === selectedStudentOrGroup?.id)
        ?.details
    : [];

  console.log("studentsInGroup", studentsInGroup);
  return (
    <div className="home-wrapper">
      <CustomSnackbar {...snackProps} />
      {/* start header */}
      <HeaderTutor />
      {/* end header */}
      {openDialog && openDialog === dialogs.CREATE_LIVE && (
        <CreateLiveLessonModal
          closeHandler={() => setOpenDialog("")}
          dialogs={dialogs}
          setOpenDialog={setOpenDialog}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      )}

      {openDialog && openDialog === dialogs.CREATED && (
        <LiveLessonCreated
          closeHandler={() => setOpenDialog("")}
          activeTab={activeTab}
        />
      )}

      {openDialog && openDialog === dialogs.CREATE_PERSONAL_TASK && (
        <PersonalTaskDialogs
          sendHandler={createPersonalTaskHandler}
          name={selectedStudentOrGroup.name}
          closeHandler={() => setOpenDialog("")}
        />
      )}

      {openDialog && openDialog === dialogs.SELECT_STUDENTS && (
        <SelectStudents
          closeHandler={() => setOpenDialog("")}
          sendToSelectedStudentsHandler={() => {
            showNotification("Activity successfully sent");
          }}
          studentsAndGroups={eductorStudentsAndGroups}
        />
      )}
      {openDialog && openDialog === dialogs.CREATE_NEW_GAME && (
        <CreateNewGameDialog closeHandler={() => setOpenDialog(null)} />
      )}
      {openDialog && openDialog === dialogs.BADGES && (
        <BadgesPopup
          sendSelectedBadgesHandler={
            selectedStudentOrGroup?.isGroup
              ? () => setOpenDialog(dialogs.SEND_BADGE_TO_GROUP_STUDENTS)
              : () => setOpenDialog(dialogs.BADGE_SENT_CONFIMRMATION)
          }
          openCustomBadge={() => setOpenDialog(dialogs.CREATE_CUSTOM_BADGE)}
          openBuyBadges={() => setOpenDialog(dialogs.BUY_BADGES)}
          closeHandler={() => setOpenDialog("")}
        />
      )}
      {openDialog && openDialog === dialogs.BUY_BADGES && (
        <BuyBadges closeHandler={() => setOpenDialog("")} />
      )}
      {openDialog && openDialog === dialogs.CREATE_CUSTOM_BADGE && (
        <CreateCustomBadge
          openBadges={() => setOpenDialog(dialogs.BADGES)}
          closeHandler={() => setOpenDialog("")}
        />
      )}
      {openDialog && openDialog === dialogs.SEND_BADGE_TO_GROUP_STUDENTS && (
        <SendBadgeToGroupStudents
          confirmHandler={() => setOpenDialog(dialogs.BADGE_SENT_CONFIMRMATION)}
          backBtnHandler={() => setOpenDialog(dialogs.BADGES)}
          pupilsList={studentsInGroup}
          closeHandler={() => setOpenDialog(null)}
        />
      )}
      {openDialog && openDialog === dialogs.BADGE_SENT_CONFIMRMATION && (
        <DialogBox isOpen={true}>
          <Box
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              py: "1rem",
            }}
          >
            <CloseIcon
              onClick={() => setOpenDialog(null)}
              sx={{
                color: "var(--orange)",
                top: "0px",
                right: "5px",
                cursor: "pointer",
                position: "absolute",
              }}
            />
            <Typography
              mb="1rem"
              sx={{
                color: "var(--purple-dark)",
                fontSize: "1.5rem",
                fontWeight: "700",
              }}
            >
              Badges are sent
            </Typography>
            <Typography>
              All selected students received the badges and 30 points{" "}
            </Typography>
          </Box>
        </DialogBox>
      )}

      <div
        style={{ minHeight: "100vh" }}
        className="games-dashboard-main dashboard-bgnew blur-bgg educator-home"
      >
        <div className="games-topbread">
          {eductorStudentsAndGroups?.length === 0 ? (
            <Container maxWidth="sm">
              <Box
                sx={{
                  mb: "5rem",
                  width: "100%",
                  backgroundColor: "white",
                  borderRadius: "20px",
                  p: "1rem",
                }}
              >
                <Typography
                  sx={{
                    color: "var(--purple-dark)",
                    fontWeight: "600",
                    fontSize: "1.5rem",
                    mb: "1.5rem",
                  }}
                >
                  You haven't added any students yet
                </Typography>
                <Box sx={{ width: "40%", mx: "auto" }}>
                  <StyledYellowButton
                    title={"Add a pupil"}
                    btnHandler={() => setEducatorstep1ModalShow(true)}
                  />
                </Box>
              </Box>
            </Container>
          ) : (
            <Container>
              <div className="diversity-box">
                <div className="myactivites-main">
                  <div className="myactivites-contain">
                    <Tabs>
                      <div className="activies-row">
                        <div className="left-pannel">
                          <div className="leftbx-activites">
                            <div className="acti-left-detail">
                              <div className="puil-mainbx">
                                <div className="add-pupil">
                                  <div className="pupil-dropdwn">
                                    <Dropdown className="dropdown-main dropmenu-custlistt">
                                      <Dropdown.Toggle
                                        variant="success"
                                        id="dropdown-basic"
                                        className="dropdown-btnn"
                                      >
                                        <i className="fas fa-plus"></i>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu className="dropdown-btn-item customdrop-listmenu">
                                        <Dropdown.Item
                                          href="#"
                                          onClick={() => {
                                            setEducatorstep1ModalShow(true);
                                          }}
                                        >
                                          <span className="rightarrow-icon">
                                            <i className="fas fa-angle-right"></i>
                                          </span>{" "}
                                          Add a pupil
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={() => {
                                            setAddgroupstep1ModalShow(true);
                                          }}
                                        >
                                          <span className="rightarrow-icon">
                                            <i className="fas fa-angle-right"></i>
                                          </span>{" "}
                                          Add a new group
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={() => {
                                            setinvitestep1ModalShow(true);
                                          }}
                                        >
                                          <span className="rightarrow-icon">
                                            <i className="fas fa-angle-right"></i>
                                          </span>{" "}
                                          Invite to Neithedu
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="serchfiled-activities">
                                  <Form.Control
                                    value={querySearch}
                                    onChange={(e) =>
                                      setquerySearch(String(e.target.value))
                                    }
                                    type="text"
                                    placeholder="Search"
                                    className="formbox"
                                  />
                                  <Button type="button" className="seachbtn">
                                    <i className="fas fa-search"></i>
                                  </Button>
                                </div>
                              </div>

                              <div className="activities-list-left">
                                <div className="tabslist-activiti">
                                  {!educatorStudentsAndGroupsLoading &&
                                    searchedStudentsAndGroups?.length === 0 && (
                                      <p>No student or group found</p>
                                    )}
                                  {!educatorStudentsAndGroupsLoading &&
                                    searchedStudentsAndGroups &&
                                    searchedStudentsAndGroups.map(
                                      (stugrp, i) => {
                                        return (
                                          <div
                                            onClick={() => {
                                              setSelectedStudentOrGroup(stugrp);
                                              refetchActivities();
                                              history.push(
                                                `/educator?studentId=${stugrp?.id}`,
                                              );
                                            }}
                                            key={i}
                                            className="tabslist-activiti-tab tabtwo"
                                          >
                                            <div className="activ-detail-list">
                                              <div className="active-detail-row">
                                                <div className="activiti-prof-icon">
                                                  <span className="profile-icon-box">
                                                    {stugrp?.isGroup ? (
                                                      <i className="fa fa-users"></i>
                                                    ) : (
                                                      <i className="fa fa-user"></i>
                                                    )}
                                                  </span>
                                                </div>
                                                <div className="activiti-center-detail">
                                                  <h5>{stugrp.name}</h5>
                                                  <h6>
                                                    {stugrp.numberOfActivities}{" "}
                                                    activities
                                                  </h6>
                                                </div>
                                                <div className="activiti-count-mesg">
                                                  <span className="count-num-activiti">
                                                    {stugrp.numberOfActivities}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      },
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="right-panel">
                          <div className="activities-rightbx">
                            {/* activity detail */}
                            {eductorStudentsAndGroups?.length > 0 && (
                              <div className="tabslist-content-act tabpanelone">
                                <div className="myacti-listtabs educator-lsttabs-new">
                                  <div className="chat-headerbar">
                                    <div className="chat-topbar-list">
                                      <div className="activ-detail-list">
                                        <div className="active-detail-row">
                                          <div className="activiti-prof-icon">
                                            {selectedStudentOrGroup?.isGroup ? (
                                              <span className="profile-icon-box">
                                                <i className="fa fa-users"></i>
                                              </span>
                                            ) : (
                                              <span className="profile-icon-box">
                                                <i className="fas fa-user"></i>
                                              </span>
                                            )}
                                          </div>
                                          <div className="activiti-center-detail">
                                            <h5>
                                              {selectedStudentOrGroup?.name}
                                            </h5>
                                            {selectedStudentOrGroup?.isGroup && (
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <span
                                                  className="group-member grpmember-btn"
                                                  onClick={() => {
                                                    setgroupmemberstep1ModalShow(
                                                      true,
                                                    );
                                                  }}
                                                >
                                                  {studentsInGroup?.length}{" "}
                                                  members
                                                </span>
                                                <div className="member-bxnw">
                                                  <span className="addbtn-meber">
                                                    <button
                                                      className="addmbr-btn-sml"
                                                      onClick={() => {
                                                        setAddgroupstep2ModalShow(
                                                          true,
                                                        );
                                                        setgroupmemberstep1ModalShow(
                                                          false,
                                                        );
                                                      }}
                                                    >
                                                      <i className="fas fa-plus"></i>
                                                    </button>
                                                  </span>
                                                </div>
                                              </Box>
                                            )}
                                            <h6 style={{ marginTop: "0.5rem" }}>
                                              {activitiesData?.length || 0}{" "}
                                              activities in progress
                                            </h6>
                                          </div>
                                          <div className="activiti-count-mesg">
                                            <div className="chat-right-droplist">
                                              <ul className="list-chatbx">
                                                <li
                                                  style={{
                                                    paddingRight: "0px",
                                                    marginRight: "1rem",
                                                  }}
                                                  className="chat-dropright educ-acvt"
                                                >
                                                  <div className="droplist-right-btn">
                                                    <Dropdown className="dropdown-main">
                                                      <Dropdown.Toggle
                                                        variant="success"
                                                        id="dropdown-basic"
                                                        className="dropdown-btnn addactv-btndrp"
                                                        style={{
                                                          display: "flex",
                                                          alignItems: "center",
                                                          justifyContent:
                                                            "center",
                                                        }}
                                                      >
                                                        <Box
                                                          sx={{
                                                            display: "flex",
                                                            columnGap: "0.5rem",
                                                            p: "0.7rem",
                                                            color: "white",
                                                            borderRadius:
                                                              "10px",
                                                            alignItems:
                                                              "center",
                                                            justifyContent:
                                                              "center",
                                                          }}
                                                        >
                                                          <AddIcon />
                                                          <Typography
                                                            sx={{
                                                              fontWeight: "700",
                                                              fontSize: "1rem",
                                                            }}
                                                          >
                                                            Add an activity
                                                          </Typography>
                                                        </Box>
                                                      </Dropdown.Toggle>

                                                      <Dropdown.Menu
                                                        style={{
                                                          marginTop: "1rem",
                                                        }}
                                                        className="dropdown-btn-item"
                                                      >
                                                        <Dropdown.Item
                                                          onClick={() => {
                                                            history.push(
                                                              `/educator/selectMyGames?${
                                                                selectedStudentOrGroup?.isGroup
                                                                  ? "groupId"
                                                                  : "studentId"
                                                              }=${
                                                                selectedStudentOrGroup?.id
                                                              }`,
                                                            );
                                                          }}
                                                        >
                                                          <span className="rightarrow-icon">
                                                            <i className="fas fa-angle-right"></i>
                                                          </span>{" "}
                                                          Game
                                                        </Dropdown.Item>

                                                        <Dropdown.Item
                                                          onClick={() => {
                                                            setOpenDialog(
                                                              dialogs.CREATE_PERSONAL_TASK,
                                                            );
                                                          }}
                                                        >
                                                          <span className="rightarrow-icon">
                                                            <i className="fas fa-angle-right"></i>
                                                          </span>{" "}
                                                          Personal task
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                          onClick={() =>
                                                            history.push(
                                                              `/educator/selectMyMaterials?${
                                                                selectedStudentOrGroup?.isGroup
                                                                  ? "groupId"
                                                                  : "studentId"
                                                              }=${
                                                                selectedStudentOrGroup?.id
                                                              }`,
                                                            )
                                                          }
                                                        >
                                                          <span className="rightarrow-icon">
                                                            <i className="fas fa-angle-right"></i>
                                                          </span>{" "}
                                                          Material
                                                        </Dropdown.Item>

                                                        <Dropdown.Item>
                                                          <div
                                                            onClick={() =>
                                                              setOpenDialog(
                                                                dialogs.CREATE_LIVE,
                                                              )
                                                            }
                                                          >
                                                            <span className="rightarrow-icon">
                                                              <i className="fas fa-angle-right"></i>
                                                            </span>{" "}
                                                            Live Lesson
                                                          </div>
                                                        </Dropdown.Item>
                                                        {/* )} */}
                                                      </Dropdown.Menu>
                                                    </Dropdown>
                                                  </div>
                                                </li>

                                                <li
                                                  style={{ cursor: "pointer" }}
                                                  className="chat-iconn edu-badg-lst"
                                                >
                                                  <div className="chatbtn">
                                                    <div
                                                      onClick={() =>
                                                        setOpenDialog(
                                                          dialogs.BADGES,
                                                        )
                                                      }
                                                      className="chatbtn-new"
                                                    >
                                                      {" "}
                                                      <img
                                                        alt="badge"
                                                        style={{
                                                          width: "60%",
                                                          marginRight: "0.8rem",
                                                        }}
                                                        src={batchorange}
                                                        className="img-fluid"
                                                      />
                                                    </div>
                                                  </div>
                                                </li>
                                                <li className="chat-dropright edu-lst-drp">
                                                  <div className="droplist-right-btn">
                                                    <Dropdown className="dropdown-main">
                                                      <Dropdown.Toggle
                                                        variant="success"
                                                        id="dropdown-basic"
                                                        className="dropdown-btnn"
                                                      >
                                                        <i className="fas fa-ellipsis-v"></i>
                                                      </Dropdown.Toggle>

                                                      <Dropdown.Menu className="dropdown-btn-item">
                                                        <Dropdown.Item href="#">
                                                          <span className="rightarrow-icon">
                                                            <i className="fas fa-angle-right"></i>
                                                          </span>{" "}
                                                          All files
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                          <span className="rightarrow-icon">
                                                            <i className="fas fa-angle-right"></i>
                                                          </span>{" "}
                                                          Notifications
                                                        </Dropdown.Item>
                                                        {!selectedStudentOrGroup?.isGroup ? (
                                                          <Dropdown.Item
                                                            onClick={() => {
                                                              setdeleteStudentConfirmDialog(
                                                                true,
                                                              );
                                                            }}
                                                          >
                                                            <span className="rightarrow-icon">
                                                              <i className="fas fa-angle-right"></i>
                                                            </span>{" "}
                                                            Delete Student
                                                          </Dropdown.Item>
                                                        ) : (
                                                          <Dropdown.Item
                                                            onClick={() => {
                                                              setdeleteGroupConfirmDialog(
                                                                true,
                                                              );
                                                            }}
                                                          >
                                                            <span className="rightarrow-icon">
                                                              <i className="fas fa-angle-right"></i>
                                                            </span>{" "}
                                                            Delete Group
                                                          </Dropdown.Item>
                                                        )}
                                                      </Dropdown.Menu>
                                                    </Dropdown>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="live-lesson-listbtm">
                                    {activitiesData &&
                                      activitiesData.map((activity, index) => {
                                        let linkToRedirect = "";
                                        if (!selectedStudentOrGroup?.isGroup) {
                                          if (activity.activityTypeId === 7) {
                                            linkToRedirect = `/PersonalTask/${activity.activityTypeId}/${activity.id}`;
                                          } else {
                                            linkToRedirect = `/educator/student-game-details/${activity.activityTypeId}/${activity.id}`;
                                          }
                                        } else {
                                          linkToRedirect = `/educator/groupTaskDetails/${activity.assignedTo}/${activity.activityToPerformId}/${activity.activityTypeId}`;
                                        }

                                        return (
                                          <div
                                            className="lesson-hovrbx"
                                            key={index}
                                          >
                                            <Link
                                              to={linkToRedirect}
                                              className="newlesson-card"
                                            >
                                              <div className="sesson-listmain">
                                                <div className="live-lesson-main">
                                                  <div className="custom-row">
                                                    <div className="lesson-leftbx">
                                                      <span className="medm-tex purple-clr">
                                                        {
                                                          activity?.activityTypeName
                                                        }
                                                        :{" "}
                                                        {activity?.gameTypeName}
                                                        {/* {TitleTask[activity.activityTypeId]} */}
                                                      </span>
                                                    </div>
                                                    <div className="lesson-rightbx">
                                                      <span className="medm-tex date-bx">
                                                        {
                                                          activity.completionDate
                                                        }{" "}
                                                        {activity.creationDate}
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="title-main">
                                                  {activity.title}
                                                </div>
                                                <div className="lesson-list-bottom">
                                                  <div className="custom-row">
                                                    <div className="left-boxleson">
                                                      <ul className="list-point-lesson">
                                                        <li>
                                                          <span className="medm-tex orng-clr">
                                                            {activity.points}{" "}
                                                            points
                                                          </span>
                                                        </li>
                                                        <li>
                                                          <span className="medm-tex orng-clr">
                                                            Due{" "}
                                                            {activity.dueDate}{" "}
                                                            {activity.deadline}
                                                          </span>
                                                        </li>
                                                        <li>
                                                          <span className="medm-tex sky-clr">
                                                            {
                                                              TastStatus[
                                                                activity.status
                                                              ]
                                                            }
                                                          </span>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </Link>
                                          </div>
                                        );
                                      })}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Tabs>
                  </div>
                </div>
              </div>
            </Container>
          )}
        </div>

        {/* footer start */}
        <FooterEducator logo={logo} />
        {/* footer end */}
      </div>

      {/* start add pupil modal */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        show={deleteStudentConfirmDialog || deleteGroupConfirmDialog}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Do you want to delete {selectedStudentOrGroup?.name}?</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="btn-delet-bx">
                    <ul>
                      <li>
                        {!selectedStudentOrGroup?.isGroup ? (
                          <button
                            className="delet-ys btn-grup-class btnweak-bx cancel-btn"
                            type="submit"
                            onClick={() => {
                              setdeleteStudentConfirmDialog(false);
                            }}
                          >
                            Cancel
                          </button>
                        ) : (
                          <button
                            className="delet-ys btn-grup-class btnweak-bx cancel-btn"
                            type="submit"
                            onClick={() => {
                              // console.log("_++++");
                              setdeleteGroupConfirmDialog(false);
                            }}
                          >
                            Cancel
                          </button>
                        )}
                      </li>
                      <li>
                        {!selectedStudentOrGroup?.isGroup ? (
                          <button
                            className="delet-no btn-grup-class btnweak-bx delbtn"
                            type="submit"
                            onClick={async () => {
                              await deleteStudentHandler();
                              open("Deleted a student", "success");
                              setdeleteStudentConfirmDialog(false);
                              await refetchStudentsAndGroups();
                            }}
                          >
                            Delete
                          </button>
                        ) : (
                          <button
                            className="delet-no btn-grup-class btnweak-bx delbtn"
                            type="submit"
                            onClick={async () => {
                              await deleteGroupHandler();
                              showNotification("Group deleted successfully");
                              setdeleteGroupConfirmDialog(false);
                              await refetchStudentsAndGroups();
                            }}
                          >
                            Delete
                          </button>
                        )}
                      </li>
                    </ul>
                  </div>
                </Form.Group>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      <Addpupil
        openSnack={open} //fn to open snack
        modalEducatorstep1Show={modalEducatorstep1Show} //open
        closeModal={
          () => setEducatorstep1ModalShow(false) //close
        }
        refetchStudentsAndGroups={refetchStudentsAndGroups}
      />

      {/* start Add a Group step1 */}

      <Addgroup
        refetchStudentsAndGroups={refetchStudentsAndGroups}
        modalAddgroupstep1Show={modalAddgroupstep1Show}
        setAddgroupstep1ModalShow={(value) => setAddgroupstep1ModalShow(value)}
      />

      {/* end Add a Group step1 */}

      {/* start Send Invite step1 */}

      <SendInvite
        modalinvitestep1Show={modalinvitestep1Show}
        setinvitestep1ModalShow={(value) => setinvitestep1ModalShow(value)}
      />

      {/* end Send Invite step1 */}

      {(modalgroupmemberstep1Show ||
        modalAddgroupstep2Show ||
        modalgroupmemberstep3Show ||
        modalgroupmemberstep4Show) && (
        <Groupmember
          openSnack={open}
          addStudents={addStudents}
          addStusInGroup={addStusInGroup}
          fetchGroupsAndStudents={refetchStudentsAndGroups}
          modalAddgroupstep2Show={modalAddgroupstep2Show}
          setAddgroupstep2ModalShow={setAddgroupstep2ModalShow}
          educatorStudents={studentsInGroup}
          selectedGroup={
            selectedStudentOrGroup?.isGroup && selectedStudentOrGroup
          }
          modalgroupmemberstep3Show={modalgroupmemberstep3Show}
          setgroupmemberstep3ModalShow={setgroupmemberstep3ModalShow}
          modalgroupmemberstep4Show={modalgroupmemberstep4Show}
          setgroupmemberstep4ModalShow={setgroupmemberstep4ModalShow}
          deleteStudentFromGroup={deleteStudentFromGroup}
          modalgroupmemberstep1Show={modalgroupmemberstep1Show}
          setgroupmemberstep1ModalShow={(value) =>
            setgroupmemberstep1ModalShow(value)
          }
          submitHandler={() => {
            refetchActivities();
          }}
          studentsAlreadyInGroup={studentsInGroup}
          refetchGroupsDetails={refetchGroupsDetails}
        />
      )}
    </div>
  );
}

export default Tutor;
