import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { forwardRef } from "react";
import { Content } from "./Content";
import { useActions, useAppState } from "../../overmind";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Questionnaire() {
  const state = useAppState();
  const actions = useActions();

  const handleClose = () => {
    actions.openQuestionnaire(false);
  };
  return (
    <div>
      <Dialog
        fullScreen
        open={state.isQuestionnaireOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Content />
      </Dialog>
    </div>
  );
}
