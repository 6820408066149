import { createContext, useState } from "react";
import { TempNotificationModal } from "../component/dialogs/TempNotificationModal";

export const NotificationContext = createContext({
  showNotification: (title) => {},
});

export const NotificationProvider = ({ children }) => {
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState("");

  const showNotification = (title) => {
    setNotificationTitle(title);
    setTimeout(() => setIsNotificationOpen(true), 250);

    setTimeout(() => {
      setIsNotificationOpen(false);
    }, 2250);
  };

  const value = {
    showNotification,
  };

  return (
    <NotificationContext.Provider value={value}>
      <TempNotificationModal
        isOpen={isNotificationOpen}
        title={notificationTitle}
      />
      {children}
    </NotificationContext.Provider>
  );
};
