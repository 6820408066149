import React, { useState, useEffect, useCallback, useContext } from "react";
import "../home.scss";
import "../profile.scss";
import "../media.scss";
import "../games.scss";
import "../loginModal/login.scss";
import "../messages/messages.scss";
import "../myactivities.scss";
import "../gamestabs.scss";
import { Container, Dropdown, Row, Button, Form, Modal } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import logo from "../../assets/images/logo.png";
import dotthree from "../../assets/images/dotthree.svg";
import addbtnround from "../../assets/images/add-btn.svg";
import darkblueplan from "../../assets/images/darkblue-plan.svg";
import editfileblue from "../../assets/images/editfile-blue.svg";
import { Link, useHistory, useLocation } from "react-router-dom";
import FooterEducator from "../layout/Footer/FooterEducator";
import HeaderGames from "../layout/Header/HeaderGames";
import {
  createNewGame,
  deleteMiniGame,
  getAllGamesList,
  gameflowTemplateList,
  saveEducatorbaseGameFlow,
  getGameflowById,
  editGameflowGames,
  deleteGameFlow,
} from "../../service/apis/games";
import { Box, Pagination, Tooltip } from "@mui/material";
import ActivitesSendModal from "../ActivitesSendModal";
import GameFlowModal from "./GameFlow/GameFlowModal";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import ConfirmationModal from "../dialogs/ConfirmationModal";
import NotificationModal from "../dialogs/NotificationModal";
import TopSearchBar from "./TopSearchBar";
import Gamesflowphotos from "./GameFlow/Gamesflowphotos";
import { CustomSnackbar, useSnackState } from "../../utils/useSnackState";
import { SnackbarContext } from "../../contexts/Snackbar";
import { NotificationContext } from "../../contexts/Notification";

let postsPerPage = 8;

function GamesTabs() {
  const [openForwardGameModal, setOpenForwardGameModal] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [textSearch, setTextSearch] = useState("");
  const [selectedGames, setSelectedGames] = useState([]);
  const [grade, setGrade] = useState("0");
  const [SearchData, setSearchData] = useState([]);
  const [selectedGame, setSelectedGame] = useState({});
  const [selectedActivityType, setSelectedActivityType] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setFilteredData] = useState([]);
  const [gameFlowSelection, setGameFlowSelection] = useState({
    open: false,
    selectedGame: {},
    dataToFilter: [],
  });

  const [openGameDetailModal, setOpenGameDetailModal] = useState({
    open: false,
    selectedTemplateId: "",
    newGame: false,
    isNewGame: false,
  });
  const [confirmationModal, setConfirmationModal] = useState({
    open: false,
    heading: "",
    detail: "",
    selectedId: "",
    successHandler: () => {},
    cancelHandler: () => {},
  });

  const [notificationModal, setNotificationModal] = useState({
    open: false,
    heading: "",
    detail: "",
    btnText: "",
  });

  const history = useHistory();
  const location = useLocation();
  const queryClient = useQueryClient();
  const [snackProps, snackOpen] = useSnackState();
  const { openSnackbar } = useContext(SnackbarContext);
  const { showNotification } = useContext(NotificationContext);

  const {
    isLoading: allGamesLoading,
    data: allGamesData,
    refetch: refetchGameData,
  } = useQuery({
    queryKey: ["allGames"],
    queryFn: async () => await getAllGamesList(0, false),
  });

  const { data: gameflowTemplateData } = useQuery({
    queryKey: ["gameflowTemplate"],
    queryFn: async () => {
      const gameflowTemplate = (await gameflowTemplateList()).rows;
      return gameflowTemplate;
    },
  });

  // data filtering
  useEffect(() => {
    // add filter on category, description, gameType, title

    console.log("grade", grade);
    let searchReseult = selectedGames?.filter(
      (game) =>
        game.category
          ?.toLowerCase()
          .trim()
          .includes(textSearch.toLowerCase()) ||
        game.title?.toLowerCase().trim().includes(textSearch.toLowerCase()) ||
        game.description
          ?.toLowerCase()
          .trim()
          .includes(textSearch.toLowerCase()) ||
        game.gameType?.toLowerCase().trim().includes(textSearch.toLowerCase()),
    );
    if (grade !== "0") {
      searchReseult = searchReseult?.filter(
        (game) => game.studentGrade === grade,
      );
    }
    setSearchData(searchReseult);
    setCurrentPage(1);
  }, [selectedGames, grade, textSearch]);

  // pagenation facutionality
  useEffect(() => {
    const lastPostIndex = currentPage * postsPerPage;
    const firstPostIndex = lastPostIndex - postsPerPage;
    const currentPosts = SearchData?.slice(firstPostIndex, lastPostIndex);
    setFilteredData(currentPosts);
  }, [SearchData, currentPage]);

  // deciding which data to show using tabindex
  useEffect(() => {
    if (!location.search) return;
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get("tab");
    if (tab === "myMinigames") {
      setTabIndex(0);
    } else if (tab == "neithEduGames") {
      setTabIndex(1);
    } else if (tab === "myGameflows") {
      setTabIndex(2);
    } else if (tab === "illumiyaGameFlows") {
      setTabIndex(3);
    } else {
      setTabIndex(0);
    }
  }, [location.search]);

  useEffect(() => {
    if (allGamesLoading) return;
    if (tabIndex === 0) {
      setSelectedGames(allGamesData?.myMinigames);
    } else if (tabIndex === 1) {
      setSelectedGames(allGamesData.adminMinigames);
    } else if (tabIndex === 2) {
      setSelectedGames(allGamesData.myGameflows);
    } else if (tabIndex === 3) {
      setSelectedGames(gameflowTemplateData);
    }
  }, [tabIndex, allGamesData]);

  const deleteGame = async (game) => {
    try {
      const res = await deleteMiniGame(game.id);
      if (!res) throw new Error("error in deleting game");
      // invalidate all games query
      resetConfirmationState();
      showNotification("Game deleted successfully");
      setTimeout(async () => {
        await queryClient.invalidateQueries({ queryKey: ["allGames"] });
        await refetchGameData();
      }, 2000);
    } catch (e) {
      console.log(e);
      snackOpen("Something went wrong", "error");
    }
  };

  const deleteGameflow = async (gameflow) => {
    console.log("gameflow", gameflow);
    try {
      const res = await deleteGameFlow(gameflow.id);
      console.log("res", res);
      if (!res) throw new Error("error in deleting gameflow");
      // invalidate all games query
      resetConfirmationState();
      showNotification("Gameflow deleted successfully");
      setTimeout(async () => {
        await queryClient.invalidateQueries({ queryKey: ["allGames"] });
        await refetchGameData();
      }, 2000);
    } catch (e) {
      console.log(e);
      snackOpen("Something went wrong", "error");
    }
  };

  console.log("currentpage", currentPage);

  const saveMiniGame = async (newGameFormData) => {
    console.log("newGameFormData", newGameFormData);
    try {
      let { topicId, ...data } = newGameFormData;
      const isExist = allGamesData?.myMinigames?.find(
        (game) =>
          game.title === newGameFormData?.title &&
          +game.studentGrade === +newGameFormData?.studentGrade &&
          game.gameType === newGameFormData?.selectedGameType?.name &&
          game.description === newGameFormData?.description &&
          game.category === newGameFormData?.categoryId?.name,
      );
      if (isExist) {
        console.log("game already exists", isExist);
        openSnackbar("Game already exists", "error");
        return;
      }
      let updatedData = {
        ...data,
        selectedTopic: topicId?.id,
        selectedGameType: newGameFormData?.selectedGameType?.id,
        selectedSubject: newGameFormData?.categoryId?.id,
      };

      const result = await createNewGame(updatedData);

      showNotification("Game saved successfully");
      setTimeout(() => {
        history.push({ pathname: "/Questionone", state: [result.data, 1] });
      }, 2000);
    } catch (e) {
      openSnackbar("Something went wrong", "error");
      console.log(e);
    }
  };

  const saveGameFlow = async (inputData) => {
    console.log("gff inputData", inputData);
    console.log("gff allGamesData", allGamesData?.myGameflows);

    try {
      // check if gameflow already exists, if yes then show error
      // TODO: add check on description as well
      const isExist = allGamesData?.myGameflows?.find(
        (gameflow) =>
          gameflow.title === inputData?.title &&
          gameflow.category === inputData?.categoryId?.name &&
          +gameflow.studentGrade === +inputData?.studentGrade,
      );
      if (isExist) {
        console.log("gameflow already exists", isExist);
        openSnackbar("Gameflow already exists", "error");
        return;
      }

      let updatedData = {
        ...inputData,
        categoryId: inputData?.categoryId?.id,
        topicId: inputData?.topicId?.id,
        templateGameflowId: inputData?.templateGameflowId,
        studentGrade: inputData?.studentGrade,
        title: inputData?.title,
        description: inputData?.description,
      };

      let res = await saveEducatorbaseGameFlow(updatedData);
      showNotification("Gameflow saved successfully");
      setTimeout(() => {
        history.push({
          pathname: "/Gamesflowstwo",
          search: `?gameflowId=${res}`,
        });
      }, 2000);
    } catch (e) {
      console.log("error in submitGameFlow in EducatorbaseGameFlow.js", e);
    }
  };

  const submitGameFlow = async (inputData) => {
    console.log("inputData", inputData);
    if (openGameDetailModal.newGame) {
      return await saveMiniGame(inputData);
    } else {
      return await saveGameFlow(inputData);
    }
  };

  const resetConfirmationState = () =>
    setConfirmationModal({
      open: false,
      cancelHandler: () => {},
      confirmHandler: () => {},
      heading: "",
      selectedId: null,
      successHandler: () => {},
      detail: "",
    });

  const forwardMiniGameHandler = async (game) => {
    setSelectedActivityType(game.activityTypeId);
    setSelectedGame(game);
    setOpenForwardGameModal(true);
  };

  const deleteMiniGameHandler = async (game) => {
    setConfirmationModal({
      open: true,
      detail: "",
      heading: "Are you sure you want to delete the game?",
      selectedId: game.id,
      successHandler: () => deleteGame(game),
    });
  };

  const deleteGameflowHandler = async (game) => {
    console.log("game", game);
    setConfirmationModal({
      open: true,
      detail: "",
      heading: "Are you sure you want to delete the gameflow?",
      selectedId: game.id,
      successHandler: () => deleteGameflow(game),
    });
  };

  const publishMiniGameHandler = async (game) => {
    setConfirmationModal({
      open: true,
      heading: "Do you want to apply this material to publish?",
      detail:
        "The material will be reviewed by our experts. If the material is relevant and interesting, it will be posted on the site for general use and you will receive $20",
      selectedId: game.id,
      successHandler: () => {
        resetConfirmationState();
        setNotificationModal({
          open: true,
          heading: "Thanks! Your applications sent",
          detail: "You will receive notification with result of review",
          btnText: "Thanks, i understood",
        });
      },
    });
  };

  const addGameToGameflowHandler = async (gameflowId, selectedGame) => {
    try {
      console.log("selectedGame", selectedGame);
      const gameflowDetails = await getGameflowById(gameflowId);
      console.log("gameflowDetails", gameflowDetails);

      let _games = gameflowDetails?.games?.map((item) => {
        return {
          gamePosition: item.gamePosition,
          gameType: item.gameType,
          gameId: item.gameId,
        };
      });
      let _type = 1;
      if (selectedGame.gameType.toLowerCase() === "quiz") _type = 1;
      else if (selectedGame.gameType.toLowerCase() === "crossword") _type = 2;
      else if (selectedGame.gameType.toLowerCase() === "wordsearch") _type = 3;
      else if (selectedGame.gameType.toLowerCase() === "material") _type = 4;
      else _type = 4;
      let newGame = {
        gamePosition: _games.length,
        gameType: _type,
        gameId: selectedGame.id,
      };
      _games.push(newGame);

      const editResult = await editGameflowGames(+gameflowId, _games);
      console.log("editResult", editResult);
      showNotification("Gameflow saved successfully");
      setTimeout(() => {
        setGameFlowSelection({ open: false, selectedGame: null });
      }, 2000);
      await refetchGameData();
    } catch (e) {
      console.log(
        "error in addGameToGameflowHandler in EducatorbaseGameFlow.js",
        e,
      );
      snackOpen("Error in adding game to gameflow", "error");
    }
  };

  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderGames />
      {/* end header */}

      {/* snackbar start */}
      <CustomSnackbar {...snackProps} />
      {/* snackbar end */}
      {!gameFlowSelection.open && (
        <div className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs">
          <div className="gamestabs-main">
            <Container>
              <div className="Minigames-bx">
                <Tabs
                  selectedIndex={tabIndex}
                  onSelect={(index) => {
                    setCurrentPage(1);
                    setTabIndex(index);
                  }}
                >
                  <TabList className="tabslist-gamestp">
                    <Tab
                      name="myMinigames"
                      onClick={() =>
                        history.push({
                          pathname: "/GamesTabs",
                          search: "?tab=myMinigames",
                        })
                      }
                    >
                      My minigames
                    </Tab>
                    <Tab
                      name="myMinigames"
                      onClick={() =>
                        history.push({
                          pathname: "/GamesTabs",
                          search: "?tab=neithEduGames",
                        })
                      }
                    >
                      Neithedu games
                    </Tab>
                    <Tab
                      name="myGameflows"
                      onClick={() =>
                        history.push({
                          pathname: "/GamesTabs",
                          search: "?tab=myGameflows",
                        })
                      }
                    >
                      My gameflows
                    </Tab>
                    <Tab
                      name="illumiyaGameFlows"
                      onClick={() =>
                        history.push({
                          pathname: "/GamesTabs",
                          search: "?tab=illumiyaGameFlows",
                        })
                      }
                    >
                      Neithedu Template Gameflows
                    </Tab>
                  </TabList>

                  <TabPanel>
                    <div className="minigames-cards">
                      <TopSearchBar
                        textSearch={textSearch}
                        setTextSearch={setTextSearch}
                        btnText={"Create new game"}
                        selectedGrade={grade}
                        setSelectedGrade={setGrade}
                        btnClickHandler={() =>
                          setOpenGameDetailModal({
                            newGame: true,
                            open: true,
                            selectedTemplateId: null,
                            isNewGame: true,
                          })
                        }
                      />
                      {SearchData?.length === 0 && (
                        <p style={{ color: "white" }}>No game found!</p>
                      )}

                      <div className="card-quizgames">
                        <Row>
                          {filteredData &&
                            filteredData?.map((game, index) => (
                              <MiniGameCard
                                key={index}
                                forwardMiniGameHandler={forwardMiniGameHandler}
                                deleteMiniGameHandler={deleteMiniGameHandler}
                                publishMiniGameHandler={publishMiniGameHandler}
                                game={game}
                                editHandler={(g) =>
                                  setGameFlowSelection({
                                    open: true,
                                    game: game,
                                    dataToFilter: allGamesData.myGameflows,
                                  })
                                }
                              />
                            ))}

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Pagination
                              count={Math.ceil(
                                SearchData?.length / postsPerPage,
                              )}
                              color="primary"
                              onChange={(e, p) => setCurrentPage(p)}
                              page={currentPage}
                            />
                          </Box>
                        </Row>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="minigames-cards">
                      <TopSearchBar
                        textSearch={textSearch}
                        setTextSearch={setTextSearch}
                        btnText={""}
                        selectedGrade={grade}
                        setSelectedGrade={setGrade}
                        btnClickHandler={() =>
                          setOpenGameDetailModal({
                            newGame: true,
                            open: true,
                            selectedTemplateId: null,
                            isNewGame: true,
                          })
                        }
                      />
                      {SearchData?.length === 0 && (
                        <p style={{ color: "white" }}>No game found!</p>
                      )}

                      <div className="card-quizgames">
                        <Row>
                          {filteredData?.map((game, index) => (
                            <MiniGameCard
                              key={index}
                              forwardMiniGameHandler={forwardMiniGameHandler}
                              publishMiniGameHandler={publishMiniGameHandler}
                              game={game}
                              editHandler={(g) =>
                                setGameFlowSelection({
                                  open: true,
                                  game: game,
                                  dataToFilter: allGamesData.myGameflows,
                                })
                              }
                            />
                          ))}

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Pagination
                              count={Math.ceil(
                                SearchData?.length / postsPerPage,
                              )}
                              color="primary"
                              onChange={(e, p) => setCurrentPage(p)}
                              page={currentPage}
                            />
                          </Box>
                        </Row>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel className="mygameflw-tabs">
                    <div className="minigames-cards">
                      <TopSearchBar
                        textSearch={textSearch}
                        setTextSearch={setTextSearch}
                        btnText={"Create a gameflow"}
                        selectedGrade={grade}
                        setSelectedGrade={setGrade}
                        btnClickHandler={() =>
                          setOpenGameDetailModal({
                            newGame: false,
                            open: true,
                            selectedTemplateId: null,
                            isNewGame: false,
                          })
                        }
                      />
                      {SearchData?.length === 0 && (
                        <p style={{ color: "white" }}>No gameflow found!</p>
                      )}
                      <div className="card-quizgames">
                        <Row>
                          {filteredData?.map((game, index) => {
                            let gamesList = game?.gameTypes?.split(",");
                            return (
                              <div className="col-md-3 onecard" key={index}>
                                <div className="inner-cardquizz">
                                  <div className="inner-bodyquiz">
                                    <div className="top-list-quiz">
                                      <div className="addlist-quiz">
                                        <div className="quiz-listng-iner quiz-leftcard">
                                          <div className="btn-listnew">
                                            <button
                                              className="attachment-quiz cursor-pointer"
                                              onClick={() => {
                                                setOpenForwardGameModal(true);
                                                setSelectedGame(game);
                                                setSelectedActivityType(3);
                                              }}
                                            >
                                              <img src={darkblueplan} />
                                            </button>
                                          </div>
                                          <div className="btn-listnew">
                                            <div
                                              onClick={() => {
                                                history.push({
                                                  pathname: "/Gamesflowstwo",
                                                  search: `?gameflowId=${game.id}`,
                                                });
                                              }}
                                              className="attachment-quiz cursor-pointer"
                                            >
                                              <img src={editfileblue} />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="quiz-listng-iner quiz-rightcard">
                                          <div className="btn-listnew">
                                            <Dropdown className="dropdown-main dropmenu-custlistt">
                                              <Dropdown.Toggle
                                                variant="success"
                                                id="dropdown-basic"
                                                className="dropdown-btnn"
                                              >
                                                <img src={dotthree} />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu className="dropdown-btn-item customdrop-listmenu">
                                                <Dropdown.Item
                                                  onClick={() =>
                                                    deleteGameflowHandler(game)
                                                  }
                                                >
                                                  Delete
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  Apply to publish
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="quiz-body-content">
                                      <div className="quiz-bdy-content-bx">
                                        <h3>{game.title}</h3>
                                        <div
                                          style={{
                                            width: "100%",
                                            height: "68px",
                                          }}
                                        >
                                          <p
                                            style={{
                                              width: "100%",
                                            }}
                                          >
                                            <Tooltip
                                              title={
                                                <span
                                                  style={{
                                                    paddingLeft: "15px",
                                                    paddingRight: "15px",
                                                  }}
                                                >
                                                  {gamesList?.map((g) => (
                                                    <span>
                                                      {g}
                                                      <br />
                                                    </span>
                                                  ))}
                                                </span>
                                              }
                                              sx={{
                                                maxWidth: "200PX",
                                              }}
                                            >
                                              {/* only show first 3 lists and at 4th show ... */}
                                              {gamesList
                                                ?.slice(0, 3)
                                                ?.map((item, index) => {
                                                  return (
                                                    <span key={index}>
                                                      {item}
                                                      <br />
                                                    </span>
                                                  );
                                                })}
                                              {gamesList?.length > 3 && (
                                                <span>...</span>
                                              )}
                                            </Tooltip>
                                          </p>
                                        </div>
                                        <h5>{game?.category}</h5>
                                        {/* <h4>7th year of study</h4> */}
                                        <div className="points-btn">
                                          <span className="totalpoints-btn">
                                            {game.points} points
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Pagination
                              count={Math.ceil(
                                SearchData?.length / postsPerPage,
                              )}
                              color="primary"
                              onChange={(e, p) => setCurrentPage(p)}
                              page={currentPage}
                            />
                          </Box>
                        </Row>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel className="gameflw-template ">
                    <div className="minigames-cards">
                      <TopSearchBar
                        textSearch={textSearch}
                        setTextSearch={setTextSearch}
                        btnText={""}
                        selectedGrade={grade}
                        setSelectedGrade={setGrade}
                        btnClickHandler={() => {}}
                      />
                      {SearchData?.length === 0 && (
                        <p style={{ color: "white" }}>No gameflow found!</p>
                      )}
                      <div className="heading-gamesbtm">
                        <h3>Choose learning style </h3>
                      </div>
                      <div className="choose-learning-list">
                        <ul className="learner-listing-games">
                          <li>
                            <button className="btn-learner-lsit active">
                              Audio visual learner
                            </button>
                          </li>
                          <li>
                            <button className="btn-learner-lsit">
                              Practical learner
                            </button>
                          </li>
                          <li>
                            <button className="btn-learner-lsit">
                              Thinking Learner
                            </button>
                          </li>
                          <li>
                            <button className="btn-learner-lsit">
                              Any Other Learner
                            </button>
                          </li>
                          <li>
                            <button className="btn-learner-lsit">
                              Visual Learner
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div className="card-quizgames">
                        <Row>
                          {filteredData?.map((game, index) => (
                            <div className="col-md-3 onecard" key={index}>
                              <div className="inner-cardquizz">
                                <div className="inner-bodyquiz">
                                  <div className="top-list-quiz">
                                    <div className="addlist-quiz">
                                      <div className="quiz-listng-iner quiz-leftcard">
                                        <div className="btn-listnew">
                                          <div
                                            className="attachment-quiz cursor-pointer"
                                            onClick={() => {
                                              setOpenGameDetailModal({
                                                open: true,
                                                selectedTemplateId: game?.id,
                                                newGame: false,
                                                isNewGame: false,
                                              });
                                            }}
                                          >
                                            <img src={editfileblue} />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="quiz-body-content">
                                    <div className="quiz-bdy-content-bx">
                                      <h3>{game.title}</h3>
                                      <p>{game.gameTypes}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Pagination
                              count={Math.ceil(
                                SearchData?.length / postsPerPage,
                              )}
                              color="primary"
                              onChange={(e, p) => setCurrentPage(p)}
                              page={currentPage}
                            />
                          </Box>
                        </Row>
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </Container>
          </div>

          {/* footer start */}
          <FooterEducator logo={logo} />
          {/* footer end */}
        </div>
      )}

      {gameFlowSelection.open && (
        <Gamesflowphotos
          type="minigame"
          closeModal={() =>
            setGameFlowSelection({ open: false, game: {}, dataToFilter: [] })
          }
          selectedGame={gameFlowSelection.game}
          data={gameFlowSelection.dataToFilter}
          submitHandler={addGameToGameflowHandler}
        />
      )}

      <ActivitesSendModal
        game={selectedGame}
        modalgamesavedstep2Show={openForwardGameModal}
        activityTypeId={selectedActivityType}
        setgamesavedstep2ModalShow={setOpenForwardGameModal}
      />

      {confirmationModal.open && (
        <ConfirmationModal
          open={confirmationModal.open}
          heading={confirmationModal.heading}
          detail={confirmationModal.detail}
          successHandler={confirmationModal.successHandler}
          closeModal={resetConfirmationState}
          cancelHandler={resetConfirmationState}
        />
      )}

      {notificationModal.open && (
        <NotificationModal
          open={notificationModal.open}
          btnText={notificationModal.btnText}
          detail={notificationModal.detail}
          heading={notificationModal.heading}
          onClose={() =>
            setNotificationModal({
              open: false,
              btnText: "",
              detail: "",
              heading: "",
            })
          }
        />
      )}
      {openGameDetailModal.open && (
        <GameFlowModal
          open={openGameDetailModal.open}
          closeModal={() =>
            setOpenGameDetailModal({
              open: false,
              selectedTemplateId: "",
              newGame: false,
              isNewGame: false,
            })
          }
          GameTypes={[
            {
              id: 1,
              name: "Quiz",
            },
          ]}
          editModal={openGameDetailModal.selectedTemplateId}
          submitHandler={submitGameFlow}
          newGame={openGameDetailModal.newGame}
          isNewGame={openGameDetailModal.isNewGame}
        />
      )}
    </div>
  );
}

export default GamesTabs;

const MiniGameCard = ({
  game,
  forwardMiniGameHandler,
  publishMiniGameHandler,
  deleteMiniGameHandler,
  editHandler,
}) => {
  return (
    <div className="col-md-3 onecard">
      <div className="inner-cardquizz">
        <div className="inner-bodyquiz">
          <div className="top-list-quiz">
            <div className="addlist-quiz">
              <div className="quiz-listng-iner quiz-leftcard">
                <div className="btn-listnew">
                  <button
                    className="attachment-quiz cursor-pointer"
                    onClick={() => forwardMiniGameHandler(game)}
                  >
                    <img src={darkblueplan} />
                  </button>
                </div>
                <div className="btn-listnew">
                  <div
                    className="attachment-quiz cursor-pointer"
                    onClick={() =>
                      editHandler({
                        open: true,
                        selectedGame: game,
                      })
                    }
                  >
                    <img src={addbtnround} />
                  </div>
                </div>
              </div>
              <div className="quiz-listng-iner quiz-rightcard">
                {deleteMiniGameHandler && (
                  <div className="btn-listnew">
                    <Dropdown className="dropdown-main dropmenu-custlistt">
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="dropdown-btnn"
                      >
                        <img src={dotthree} />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="dropdown-btn-item customdrop-listmenu">
                        <Dropdown.Item
                          href="#"
                          onClick={() => deleteMiniGameHandler(game)}
                        >
                          Delete
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => publishMiniGameHandler(game)}
                        >
                          Apply to publish
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="quiz-body-content">
            <div className="quiz-bdy-content-bx">
              <h6>{game.gameType}</h6>
              <h3>{game.title}</h3>
              <h5>{game.category}</h5>

              <p style={{ height: "68px" }}>
                {game.description?.slice(0, 50)}
                {game?.description?.length > 50 && <span>...</span>}
              </p>
              <div className="points-btn">
                <span className="totalpoints-btn">{game.points} points</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
