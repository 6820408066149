import React, { useContext, useEffect, useState } from "react";
import "../home.scss";
import "../profile.scss";
import "../media.scss";
import "../games.scss";
import "../loginModal/login.scss";
import "../myactivities.scss";
import { Dropdown, Button, Form } from "react-bootstrap";
import { Tabs } from "react-tabs";
import logo from "../../assets/images/logo.png";

import { useHistory, useLocation } from "react-router-dom";
import FooterEducator from "../layout/Footer/FooterEducator";
import Addgroup from "../Addgroup";
import Addpupil from "../Addpupil";
import SendInvite from "../SendInvite";
import Groupmember from "../Groupmember";
import HeaderGames from "../layout/Header/HeaderGames";
import {
  addStudentsInGroup,
  addStudentToGroup,
  deleteEducatorGroup,
  deleteEducatorStudent,
  deleteEducatorStudentFromGroup,
  getEducatorGroups,
  getEducatorStuGrp,
} from "../../service/apis/educator";
import {
  getGrpActivities,
  getAllActivitiesstudent,
} from "../../service/apis/activities";
import { Box, Container, Typography } from "@mui/material";
import { LiveLessonCreated } from "../liveLessons/dialogs/LiveLessonCreated";
import {
  PersonalTaskDialogs,
  SelectStudents,
} from "./personalTask/PersonalTaskDialogs";
import { CreateNewGameDialog } from "./games/CreateGameDialog";
import {
  BadgesPopup,
  CreateCustomBadge,
  SendBadgeToGroupStudents,
} from "./badges/BadgesPopup";
import { BuyBadges } from "./badges/BuyBadges";
import { StyledYellowButton } from "../utils/Buttons";
// import { StyledTextField } from "../utils/InputFields";
import { TempNotificationModal } from "../dialogs/TempNotificationModal";
import { CustomSnackbar, useSnackState } from "../../utils/useSnackState";
import { addAssignTask } from "../../service/apis/tasks";
import { useQuery } from "@tanstack/react-query";
import BadgesSentModal from "./badges/BadgesSentModal";
import ChooseActivityBar from "./activity/ChooseActivityBar";
import DeleteConfirmationModal from "../dialogs/DeleteConfirmationModal";
import { CreateLiveLessonModal } from "../liveLessons/dialogs/CreateLiveLesson";
import { useAppState } from "../../overmind";
import { userRole } from "../../service/constant";
import {
  convertDateToObject,
  convertDateToString,
} from "../../utils/helperFunctions";
import ActivityPanel from "./ActivityPanel";
import { NotificationContext } from "../../contexts/Notification";

function Educator() {
  const [snackProps, open] = useSnackState();
  const [modalAddgroupstep1Show, setAddgroupstep1ModalShow] = useState(false);
  const [modalEducatorstep1Show, setEducatorstep1ModalShow] = useState(false);
  const [modalinvitestep1Show, setinvitestep1ModalShow] = useState(false);
  const [modalgroupmemberstep1Show, setgroupmemberstep1ModalShow] =
    useState(false);
  const [modalAddgroupstep2Show, setAddgroupstep2ModalShow] = useState(false);
  const [modalgroupmemberstep3Show, setgroupmemberstep3ModalShow] =
    useState(false);

  /* start Add Memnber Group step3 */
  const [modalgroupmemberstep4Show, setgroupmemberstep4ModalShow] =
    useState(false);

  const [deleteStudentConfirmDialog, setdeleteStudentConfirmDialog] =
    useState(false);

  const [deleteGroupConfirmDialog, setdeleteGroupConfirmDialog] =
    useState(false);
  const [querySearch, setquerySearch] = useState("");

  const [activityId, setActivityId] = useState(null);

  const state = useAppState();

  const {
    data: eductorStudentsAndGroups,
    isLoading: educatorStudentsAndGroupsLoading,
    refetch: refetchStudentsAndGroups,
  } = useQuery({
    queryKey: ["educatorStudentsGroups"],
    queryFn: async () => await getEducatorStuGrp(0, 0),
  });

  const [selectedStudentOrGroup, setSelectedStudentOrGroup] = useState(null);

  const { data: activitiesData, refetch: refetchActivities } = useQuery({
    queryKey: [
      "activities",
      selectedStudentOrGroup?.id,
      selectedStudentOrGroup?.educatorId,
    ],
    queryFn: async () => {
      if (!selectedStudentOrGroup) return [];
      if (selectedStudentOrGroup?.isGroup) {
        return await getGrpActivities(
          selectedStudentOrGroup?.id,
          selectedStudentOrGroup?.educatorId,
        );
      } else if (!selectedStudentOrGroup?.isGroup) {
        return await getAllActivitiesstudent(
          selectedStudentOrGroup?.id,
          selectedStudentOrGroup?.educatorId,
        );
      }
    },
    enabled: !!selectedStudentOrGroup,
  });

  const { data: groupsDetails, refetch: refetchGroupsDetails } = useQuery({
    queryKey: ["groupsDetails"],
    queryFn: async () => await getEducatorGroups(),
  });

  // const createBtnHandler = async (e) => {
  //   console.log("Lesson created");

  //   try {
  //     const response = await CreateLiveLesson("This is first lesson");

  //     console.log("Response : ", response);
  //     setOpenDialog(dialogs.CREATED);
  //   } catch (err) {
  //     console.log("Error : ", err.message);
  //   }
  // };

  const [openDialog, setOpenDialog] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const location = useLocation();

  const { showNotification } = useContext(NotificationContext);

  const query = new URLSearchParams(location.search);
  const studentId = query.get("studentId");

  useEffect(() => {
    console.log("studentId", studentId);
    if (!eductorStudentsAndGroups || !eductorStudentsAndGroups[0]) return;

    if (studentId === selectedStudentOrGroup?.id) return;
    if (studentId) {
      const selectedStudent = eductorStudentsAndGroups?.find(
        (student) => student.id == studentId,
      );
      if (selectedStudent) setSelectedStudentOrGroup(selectedStudent);
      else {
        setSelectedStudentOrGroup(eductorStudentsAndGroups[0]);
        history.push({
          pathname: location.pathname,
          search: `?studentId=${eductorStudentsAndGroups[0]?.id}`,
        });
      }
    } else {
      setSelectedStudentOrGroup(eductorStudentsAndGroups[0]);
      history.push({
        pathname: location.pathname,
        search: `?studentId=${eductorStudentsAndGroups[0].id}`,
      });
    }
  }, [eductorStudentsAndGroups, location]);

  const history = useHistory();

  const addStudents = async ({
    selectedStudents,
    selectedGroupId,
    selectedGroupName,
  }) => {
    await addStudentToGroup(
      selectedStudents,
      selectedGroupId,
      selectedGroupName,
    );
    await refetchStudentsAndGroups();
  };

  const addStusInGroup = async (grpId, stus) => {
    await addStudentsInGroup(grpId, stus);
  };

  const AddAssignTask = async (formData, curUserId, assignedTo, group) => {
    const res = await addAssignTask(formData, curUserId, assignedTo, group);
    return res;
  };

  const deleteStudentFromGroup = async (groupId, studentId) => {
    try {
      await deleteEducatorStudentFromGroup(groupId, studentId);
      await refetchGroupsDetails();
    } catch (err) {}
  };

  const deleteStudentHandler = async () => {
    try {
      await deleteEducatorStudent(selectedStudentOrGroup.id);
      await refetchStudentsAndGroups();
    } catch (err) {}
  };

  const deleteGroupHandler = async () => {
    try {
      await deleteEducatorGroup(selectedStudentOrGroup.id);
      refetchStudentsAndGroups();
    } catch (err) {}
  };

  const searchedStudentsAndGroups = eductorStudentsAndGroups?.filter(
    (stugrp) => {
      return stugrp.name
        ?.toLowerCase()
        .trim()
        .includes(querySearch.toLowerCase());
    },
  );

  const createPersonalTaskHandler = async (_formData) => {
    if (
      !_formData.title?.trim() ||
      !_formData.essence?.trim() ||
      !_formData.points ||
      !_formData.deadline
    ) {
      open("Please fill all the fields", "error");
      return;
    }
    // change date format to DD-MM-YYYY
    let formData = {
      ..._formData,
      deadline: convertDateToString(_formData.deadline),
    };
    console.log("formData 1", formData);
    if (formData.points < 0) {
      open("Points can't be negative", "error");
      return;
    }

    const isExist = activitiesData?.find(
      (activity) =>
        activity.title === formData.title &&
        activity.description === formData.essence &&
        activity.points === formData.points &&
        activity.dueDate === formData.deadline,
    );
    if (isExist) {
      open("Activity already exist", "error");
      return;
    }
    console.log("formData 2", formData);

    const taskId = await AddAssignTask(
      formData,
      selectedStudentOrGroup.educatorId,
      selectedStudentOrGroup.id,
      selectedStudentOrGroup.isGroup,
    );
    if (taskId) {
      await refetchActivities();
    }
    showNotification("Task sent successfully");
    setOpenDialog("");
  };

  const studentsInGroup = selectedStudentOrGroup?.isGroup
    ? groupsDetails?.find((group) => group.id === selectedStudentOrGroup?.id)
        ?.details
    : [];
  console.log("__selectedStudentOrGroup", selectedStudentOrGroup);
  console.log("__groupsDetails", groupsDetails);
  console.log("__studentsInGroup", studentsInGroup);
  // sort by creationDate. creation date has format DD-MM-YYYY
  let activitiesDataToMap = activitiesData?.sort((a, b) => {
    return (
      convertDateToObject(b.creationDate) - convertDateToObject(a.creationDate)
    );
  });
  console.log(
    "test123",
    localStorage.getItem("referral")
      ? JSON.parse(localStorage.getItem("referral")).referId
      : null,
  );
  return (
    <div className="home-wrapper">
      <CustomSnackbar {...snackProps} />
      {/* start header */}
      <HeaderGames />

      {openDialog && openDialog === dialogs.CREATED && (
        <LiveLessonCreated
          closeHandler={() => setOpenDialog("")}
          activeTab={activeTab}
          activityId={activityId}
        />
      )}

      {openDialog && openDialog === dialogs.CREATE_PERSONAL_TASK && (
        <PersonalTaskDialogs
          sendHandler={createPersonalTaskHandler}
          name={selectedStudentOrGroup.name}
          closeHandler={() => setOpenDialog("")}
          type="new"
        />
      )}

      {openDialog && openDialog === dialogs.SELECT_STUDENTS && (
        <SelectStudents
          closeHandler={() => setOpenDialog("")}
          sendToSelectedStudentsHandler={() => {
            showNotification("Activity successfully sent");
            setOpenDialog("");
          }}
          studentsAndGroups={eductorStudentsAndGroups}
        />
      )}
      {openDialog && openDialog === dialogs.CREATE_NEW_GAME && (
        <CreateNewGameDialog closeHandler={() => setOpenDialog(null)} />
      )}
      {openDialog && openDialog === dialogs.BADGES && (
        <BadgesPopup
          sendSelectedBadgesHandler={
            selectedStudentOrGroup?.isGroup
              ? () => setOpenDialog(dialogs.SEND_BADGE_TO_GROUP_STUDENTS)
              : () => setOpenDialog(dialogs.BADGE_SENT_CONFIMRMATION)
          }
          openCustomBadge={() => setOpenDialog(dialogs.CREATE_CUSTOM_BADGE)}
          openBuyBadges={() => setOpenDialog(dialogs.BUY_BADGES)}
          closeHandler={() => setOpenDialog("")}
        />
      )}
      {openDialog && openDialog === dialogs.BUY_BADGES && (
        <BuyBadges closeHandler={() => setOpenDialog("")} />
      )}
      {openDialog && openDialog === dialogs.CREATE_CUSTOM_BADGE && (
        <CreateCustomBadge
          openBadges={() => setOpenDialog(dialogs.BADGES)}
          closeHandler={() => setOpenDialog("")}
        />
      )}
      {openDialog && openDialog === dialogs.SEND_BADGE_TO_GROUP_STUDENTS && (
        <SendBadgeToGroupStudents
          confirmHandler={() => setOpenDialog(dialogs.BADGE_SENT_CONFIMRMATION)}
          backBtnHandler={() => setOpenDialog(dialogs.BADGES)}
          pupilsList={studentsInGroup}
          closeHandler={() => setOpenDialog(null)}
        />
      )}
      {openDialog && openDialog === dialogs.BADGE_SENT_CONFIMRMATION && (
        <BadgesSentModal setOpenDialog={setOpenDialog} />
      )}

      <div
        style={{ minHeight: "100vh" }}
        className="games-dashboard-main dashboard-bgnew blur-bgg educator-home"
      >
        <div className="games-topbread">
          {eductorStudentsAndGroups?.length === 0 ? (
            <Container maxWidth="sm">
              <Box
                sx={{
                  mb: "5rem",
                  width: "100%",
                  backgroundColor: "white",
                  borderRadius: "20px",
                  p: "1rem",
                }}
              >
                <Typography
                  sx={{
                    color: "var(--purple-dark)",
                    fontWeight: "600",
                    fontSize: "1.5rem",
                    mb: "1.5rem",
                  }}
                >
                  You haven't added any students yet
                </Typography>
                <Box sx={{ width: "40%", mx: "auto" }}>
                  <StyledYellowButton
                    title={"Add a pupil"}
                    btnHandler={() => setEducatorstep1ModalShow(true)}
                  />
                </Box>
              </Box>
            </Container>
          ) : (
            <Container>
              <div className="diversity-box">
                <div className="myactivites-main">
                  <div className="myactivites-contain">
                    <Tabs>
                      <div className="activies-row">
                        <div className="left-pannel">
                          <div className="leftbx-activites">
                            <div className="acti-left-detail">
                              <div className="puil-mainbx">
                                <div className="add-pupil">
                                  <div className="pupil-dropdwn">
                                    <Dropdown className="dropdown-main dropmenu-custlistt">
                                      <Dropdown.Toggle
                                        variant="success"
                                        id="dropdown-basic"
                                        className="dropdown-btnn"
                                      >
                                        <i className="fas fa-plus"></i>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu className="dropdown-btn-item customdrop-listmenu">
                                        <Dropdown.Item
                                          href="#"
                                          onClick={() => {
                                            setEducatorstep1ModalShow(true);
                                          }}
                                        >
                                          <span className="rightarrow-icon">
                                            <i className="fas fa-angle-right"></i>
                                          </span>{" "}
                                          Add a pupil
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={() => {
                                            setAddgroupstep1ModalShow(true);
                                          }}
                                        >
                                          <span className="rightarrow-icon">
                                            <i className="fas fa-angle-right"></i>
                                          </span>{" "}
                                          Add a new group
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={() => {
                                            setinvitestep1ModalShow(true);
                                          }}
                                        >
                                          <span className="rightarrow-icon">
                                            <i className="fas fa-angle-right"></i>
                                          </span>{" "}
                                          Invite to Neithedu
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="serchfiled-activities">
                                  <Form.Control
                                    value={querySearch}
                                    onChange={(e) =>
                                      setquerySearch(String(e.target.value))
                                    }
                                    type="text"
                                    placeholder="Search"
                                    className="formbox"
                                  />
                                  <Button type="button" className="seachbtn">
                                    <i className="fas fa-search"></i>
                                  </Button>
                                </div>
                              </div>

                              <div className="activities-list-left">
                                <div className="tabslist-activiti">
                                  {!educatorStudentsAndGroupsLoading &&
                                    searchedStudentsAndGroups?.length === 0 && (
                                      <p>No student or group found</p>
                                    )}
                                  {!educatorStudentsAndGroupsLoading &&
                                    searchedStudentsAndGroups &&
                                    searchedStudentsAndGroups.map(
                                      (stugrp, i) => (
                                        <ChooseActivityBar
                                          key={i}
                                          stugrp={stugrp}
                                          onSelection={async () => {
                                            setSelectedStudentOrGroup(stugrp);
                                            await refetchActivities();
                                            history.push(
                                              `/educator?studentId=${stugrp?.id}`,
                                            );
                                          }}
                                        />
                                      ),
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <ActivityPanel
                          eductorStudentsAndGroups={eductorStudentsAndGroups}
                          selectedStudentOrGroup={selectedStudentOrGroup}
                          setgroupmemberstep1ModalShow={
                            setgroupmemberstep1ModalShow
                          }
                          setAddgroupstep2ModalShow={setAddgroupstep2ModalShow}
                          studentsInGroup={studentsInGroup}
                          activitiesDataToMap={activitiesDataToMap}
                          history={history}
                          setOpenDialog={setOpenDialog}
                          dialogs={dialogs}
                          setdeleteStudentConfirmDialog={
                            setdeleteStudentConfirmDialog
                          }
                          setdeleteGroupConfirmDialog={
                            setdeleteGroupConfirmDialog
                          }
                          state={state}
                          userRole={userRole}
                        />
                      </div>
                    </Tabs>
                  </div>
                </div>
              </div>
            </Container>
          )}
        </div>

        {/* footer start */}
        <FooterEducator logo={logo} />
        {/* footer end */}
      </div>

      {/* start add pupil modal */}

      <DeleteConfirmationModal
        open={deleteStudentConfirmDialog || deleteGroupConfirmDialog}
        toBeDeletedName={selectedStudentOrGroup?.name}
        cancelHandler={() => {
          !selectedStudentOrGroup?.isGroup &&
            setdeleteStudentConfirmDialog(false);
          selectedStudentOrGroup?.isGroup && setdeleteGroupConfirmDialog(false);
        }}
        deleteHandler={async () => {
          if (selectedStudentOrGroup?.isGroup) {
            await deleteGroupHandler();
            showNotification("Group deleted successfully");
            setdeleteGroupConfirmDialog(false);
            await refetchStudentsAndGroups();
          } else {
            await deleteStudentHandler();
            open("Deleted a student", "success");
            setdeleteStudentConfirmDialog(false);
            await refetchStudentsAndGroups();
          }
        }}
      />

      <Addpupil
        openSnack={open} //fn to open snack
        modalEducatorstep1Show={modalEducatorstep1Show} //open
        closeModal={
          () => setEducatorstep1ModalShow(false) //close
        }
        refetchStudentsAndGroups={refetchStudentsAndGroups}
      />

      {/* start Add a Group step1 */}

      <Addgroup
        refetchStudentsAndGroups={refetchStudentsAndGroups}
        modalAddgroupstep1Show={modalAddgroupstep1Show}
        setAddgroupstep1ModalShow={(value) => setAddgroupstep1ModalShow(value)}
        refetchGroupsDetails={refetchGroupsDetails}
      />

      {/* end Add a Group step1 */}

      {/* start Send Invite step1 */}

      <SendInvite
        modalinvitestep1Show={modalinvitestep1Show}
        setinvitestep1ModalShow={(value) => setinvitestep1ModalShow(value)}
      />

      {/* end Send Invite step1 */}

      {(modalgroupmemberstep1Show ||
        modalAddgroupstep2Show ||
        modalgroupmemberstep3Show ||
        modalgroupmemberstep4Show) && (
        <Groupmember
          openSnack={open}
          addStudents={addStudents}
          addStusInGroup={addStusInGroup}
          fetchGroupsAndStudents={refetchStudentsAndGroups}
          modalAddgroupstep2Show={modalAddgroupstep2Show}
          setAddgroupstep2ModalShow={setAddgroupstep2ModalShow}
          educatorStudents={studentsInGroup}
          selectedGroup={
            selectedStudentOrGroup?.isGroup && selectedStudentOrGroup
          }
          modalgroupmemberstep3Show={modalgroupmemberstep3Show}
          setgroupmemberstep3ModalShow={setgroupmemberstep3ModalShow}
          modalgroupmemberstep4Show={modalgroupmemberstep4Show}
          setgroupmemberstep4ModalShow={setgroupmemberstep4ModalShow}
          deleteStudentFromGroup={deleteStudentFromGroup}
          modalgroupmemberstep1Show={modalgroupmemberstep1Show}
          setgroupmemberstep1ModalShow={(value) =>
            setgroupmemberstep1ModalShow(value)
          }
          submitHandler={() => {
            refetchActivities();
          }}
          studentsAlreadyInGroup={studentsInGroup}
          refetchGroupsDetails={refetchGroupsDetails}
        />
      )}

      {openDialog && openDialog === dialogs.CREATE_LIVE && (
        <CreateLiveLessonModal
          closeHandler={() => setOpenDialog("")}
          dialogs={dialogs}
          setOpenDialog={setOpenDialog}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          setActivityId={setActivityId}
        />
      )}
    </div>
  );
}

export default Educator;
const dialogs = {
  CREATE_NEW_GAME: "CREATE_NEW_GAME",
  CREATE_LIVE: "CREATE_LIVE",
  CREATED: "CREATED",
  SELECT_STUDENTS: "SELECT_STUDENTS",
  CREATE_PERSONAL_TASK: "CREATE_PERSONAL_TASK",
  BADGES: "BADGES",
  BUY_BADGES: "BUY_BADGES",
  CREATE_CUSTOM_BADGE: "CREATE_CUSTOM_BADGE",
  BADGE_SENT_CONFIMRMATION: "BADGE_SENT_CONFIMRMATION",
  SEND_BADGE_TO_GROUP_STUDENTS: "SEND_BADGE_TO_GROUP_STUDENTS",
  ACTIVITY_SENT: "ACTIVITY_SENT",
};
