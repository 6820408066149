import axios from "axios";
import { backEnd } from "../env";
import { Axios } from "./Axios";
export const getAllBlogs = () => {
  return axios
    .get(`${backEnd}/blogs`)
    .then((res) => res.data)
    .catch((err) => []);
};
export const getBlogDetail = async ({ id }) => {
  try {
    const res = await axios.get(`${backEnd}/blogs/${id}`);
    return res.data;
  } catch (err) {
    return console.error(err);
  }
};

export const fetchData = (url) => {
  return new Promise((resolve, reject) => {
    try {
      var config = {
        method: "get",
        url: `${backEnd}/${url}`,
      };

      axios(config)
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          //console.log(error);
          return reject(error);
        });
    } catch (err) {
      console.error("catch fetchData axios :", err.message || err);
      return reject(err);
    }
  });
};
// export const fetchStates = (url, countryId) => {
//   return new Promise((resolve, reject) => {
//     try {
//       var config = {
//         method: "get",
//         url: `${backEnd}/${url}/${countryId}`,
//       };

//       axios(config)
//         .then(function (response) {
//           resolve(response);
//         })
//         .catch(function (error) {
//           //console.log(error);
//           return reject(error);
//         });
//     } catch (err) {
//       console.error("catch fetchData axios :", err.message || err);
//       return reject(err);
//     }
//   });
// };

export const fetchCities = (url, countryId) => {
  return new Promise((resolve, reject) => {
    // /${stateId}
    try {
      var config = {
        method: "get",
        url: `${backEnd}/${url}/${countryId}`,
      };

      axios(config)
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          //console.log(error);
          return reject(error);
        });
    } catch (err) {
      console.error("catch fetchData axios :", err.message || err);
      return reject(err);
    }
  });
};

export const postDate = (url, payload) => {
  return new Promise((resolve, reject) => {
    try {
      var config = {
        method: "post",
        url: `${backEnd}/${url}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: payload,
      };

      axios(config)
        .then(function (response) {
          return resolve(response);
        })
        .catch(function (error) {
          //console.log(error);
          return reject(error);
        });
    } catch (err) {
      console.error("catch postDate axios :", err.message || err);
      return reject(err);
    }
  });
};
