import {
  Box,
  LinearProgress,
  linearProgressClasses,
  makeStyles,
  Modal,
  Slider,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";

import { Link } from "react-router-dom";
import ImageIcon from "@mui/icons-material/Image";
import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Skeleton from "@mui/material/Skeleton";

import IIcon from "../../assets/images/i_icon.svg";
import {
  activeMilestones,
  findTwoNumbers,
  greatestArray,
  highestMilestonePoints,
  notRevealedMilestoneArray,
  revealedMilestonesArray,
  sortArray,
  updatedPopupMilestones,
} from "../../utils/filterArray";
import { abbrNum } from "../../utils/roundNumber";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";

export const GiftsStepper = ({
  closeHandler,
  communityArray,
  communityRewardPoints,
}) => {
  var sortedArray = sortArray(communityArray);

  var activeArray = activeMilestones(sortedArray, communityRewardPoints);
  var totalPointsArr = [];
  sortedArray.map((i) => {
    totalPointsArr.push(i.pointsThresholdValue);
  });
  var pointsArr = [];
  var blurredMilestones = [];
  var notRevealedMilestone = [];

  // const marks = totalPointsArr;

  // else {
  //   activeArray = activeMilestones(sortedArray, communityRewardPoints);
  // }
  activeArray.map((i, index) => {
    pointsArr.push(i.pointsThresholdValue);
  });
  // const [marks, setMarks] = useState([
  //   { value: 0, label: "0%", disabled: true },
  //   { value: 25, label: "25%" },
  //   { value: 50, label: "50%" },
  //   { value: 75, label: "75%" },
  //   { value: 100, label: "100%" },
  // ]);

  // const useStyles = makeStyles({
  //   mark: {
  //     "&.MuiSlider-mark.MuiSlider-markDisabled": {
  //       color: "gray",
  //     },
  //   },
  // });
  //   {
  //     "title": "Community Milestones",
  //     "description": "Unlock the Community Milestone System",
  //     "milestoneType": 1,
  //     "isActive": true,
  //     "isUnLocked": false,
  //     "pointsThresholdValue": 5000,
  //     "imageURL": "https://neith-categories.s3.ap-south-1.amazonaws.com/files/categories/Geography%20(2)_ddd5.jpg?X-Amz-Expires=604800&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA5MPRHGUJM7ZKYXQX/20230110/ap-south-1/s3/aws4_request&X-Amz-Date=20230110T123653Z&X-Amz-SignedHeaders=host&X-Amz-Signature=73fcf25be378e69bbd9aba5e5a502ce9f0da72861e06a011802a419939b5a419",
  //     "id": 6
  // }
  // const marks = [
  //   {
  //     value: pointsArr[0],
  //     label: `5k `,
  //     disabled: true,
  //   },
  //   {
  //     value: pointsArr[1],
  //     label: `10k `,
  //   },
  //   {
  //     value: pointsArr[2],
  //     label: `15k `,
  //   },
  //   {
  //     value: pointsArr[3],
  //     label: `20k `,
  //   },
  //   {
  //     value: pointsArr[4],
  //     label: `50k `,
  //   },
  //   {
  //     value: pointsArr[5],
  //     label: `55k `,
  //   },
  //   {
  //     value: pointsArr[6],
  //     label: `60k `,
  //   },
  // ];
  const marks = activeArray;
  if (communityRewardPoints >= pointsArr[pointsArr.length - 1]) {
    activeArray = updatedPopupMilestones(sortedArray, communityRewardPoints);
    activeArray.map((i, index) => {
      pointsArr.push(i.pointsThresholdValue);
    });
  }
  const result = findTwoNumbers(pointsArr, communityRewardPoints);
  if (communityRewardPoints >= 0 && communityRewardPoints <= pointsArr[0]) {
    blurredMilestones = greatestArray(activeArray, result[0]);
    notRevealedMilestone = notRevealedMilestoneArray(activeArray, result[0]);
  } else {
    blurredMilestones = greatestArray(activeArray, result[1]);
    notRevealedMilestone = notRevealedMilestoneArray(activeArray, result[1]);
  }

  // const revealedMilestones = revealedMilestonesArray(
  //   activeArray,
  //   communityRewardPoints,
  // );
  if (communityRewardPoints >= totalPointsArr[2]) {
    notRevealedMilestone = notRevealedMilestone.concat(blurredMilestones);
    blurredMilestones = [];
  }

  const [dataeeee, setDataeee] = useState({
    title: "Community rewards system",
    rewardsData: sortedArray,

    communityRewardPoints: communityRewardPoints,
  });
  const score = communityRewardPoints;
  // var a = highestMilestonePoints(sortedArray);
  // var isSelected;
  // activeArray.map((m) => {
  //   isSelected = m.pointsThresholdValue <= -score;
  // });
  return (
    <Modal sx={{ overflowY: "scroll" }} open>
      <Box
        sx={{
          m: "1rem",
          minHeight: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            boxShadow: "0px 4px 10px rgba(0,0,0,0.15)",
            backgroundColor: "rgba(255,255,255, 0.5)",
            p: "0.5rem",
            borderRadius: "20px",
          }}
        >
          <Box
            sx={{
              fontFamily: "Poppins",
              opacity: "1",
              boxShadow: "0px 4px 10px rgba(0,0,0,0.15)",
              maxWidth: "50rem",
              position: "relative",
              width: "100%",
              p: "3rem",
              pt: "4rem",
              borderRadius: "12px",
              backgroundColor: "var(--purple-dark)",
            }}
          >
            <CloseIcon
              onClick={closeHandler}
              sx={{
                cursor: "pointer",
                color: "var(--orange)",
                position: "absolute",
                top: "22px",
                right: "17px",
              }}
            />
            <Box
              sx={{
                display: "flex",
                marginBottom: "10px",
              }}
            >
              <Box>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    sx={{
                      marginTop: "-40px",
                      color: "var(--orange)",
                      fontWeight: "700",
                      fontFamily: "Mulish",
                    }}
                  >
                    Community rewards
                  </Typography>
                  <Box sx={{ marginTop: "-40px", cursor: "pointer" }}>
                    <Link
                      to={{
                        pathname: "/communityRewards",
                        state: { data: dataeeee },
                      }}
                    >
                      <img
                        style={{
                          marginLeft: "6px",
                          width: "21px",
                          height: "21px",
                        }}
                        src={IIcon}
                        alt=""
                      />
                    </Link>
                  </Box>
                </Box>

                <Typography
                  sx={{
                    marginTop: "-10px",
                    fontSize: "14px",
                    fontWeight: "400",
                    fontFamily: "Mulish",
                    color: "white",
                  }}
                >
                  Collect total {result[1]} points to unlock milestone feature
                  for rewards for whole community
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                position: "relative",
                height: "110px",
              }}
            >
              <Slider
                valueLabelDisplay="auto"
                max={totalPointsArr[totalPointsArr.length - 1]}
                min={0}
                marks={marks}
                value={score}
                sx={{
                  color: "var(--orange)",
                  width: "100%",
                  top: "2px",
                  left: "3px",
                  position: "absolute",
                  "& 	.Mui-disabled": {
                    backgroundColor: "#AC88DE !important",
                  },

                  "& .MuiSlider-markLabel": {
                    // marginTop: "-31px ",
                    // width: "40px",
                    // height: "40px",
                    color:
                      // isSelected ?
                      "white",
                    // : "var(--purple-dark)",
                    // backgroundColor:
                    //   // isSelected ?
                    //   "var(--orange)",
                    // // : "#AC88DE",
                    fontFamily: "Mulish",
                    padding: "8px 9px 12px 7px",
                    borderRadius: "50%",
                  },

                  "& .MuiSlider-rail": {
                    color: "var(--purple)",
                  },
                }}
                size="small"
                // defaultValue={70}
                aria-label="Small"
              />
              {/* =============== */}
              <Box
                sx={{
                  width: "100%",
                  color: "#fff",
                  mt: { sm: "-5px" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      ml: 5,
                      fontSize: "12px",
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      backgroundColor: "#FF7A00",
                      position: "relative",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    100
                    <Box
                      sx={{
                        fontSize: "12px",
                        textAlign: "center",
                        height: "100px",
                        width: "100px",
                        borderRadius: "10px",
                        backgroundColor: "#FF7A00",
                        position: "absolute",
                        top: 50,
                        left: "-75%",
                        display: "grid",
                        placeItems: "center",
                      }}
                    >
                      <div>
                        <PhotoSizeSelectActualIcon sx={{ fontSize: 32 }} />{" "}
                        Communitiy <br />
                        rewards
                      </div>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      mr: -1.3,
                      fontSize: "12px",
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      backgroundColor: "#FF7A00",
                      position: "relative",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    5K
                    <Box
                      sx={{
                        fontSize: "12px",
                        textAlign: "center",
                        height: "100px",
                        width: "100px",
                        borderRadius: "10px",
                        backgroundColor: "#FF7A00",
                        position: "absolute",
                        top: 50,
                        left: "-75%",
                        display: "grid",
                        placeItems: "center",
                      }}
                    >
                      <div>
                        <PhotoSizeSelectActualIcon sx={{ fontSize: 32 }} />{" "}
                        Communitiy <br />
                        rewards
                      </div>
                    </Box>
                  </Box>
                </Box>
              </Box>

              {/* {revealedMilestones.map((item, idx) => {
                const n = abbrNum(item.pointsThresholdValue, 0);

                return (
                  <Box
                    key={idx}
                    sx={{
                      zIndex: 1,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      position: "relative",
                      mx: "0.5rem",
                      mt: "1rem",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "white",

                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "Mulish",
                        padding: "8px 9px 12px 7px",
                        fontWeight: "700",
                        fontSize: "20px",
                        borderRadius: "50%",
                        backgroundColor: "var(--orange)",
                        width: "40px",
                        height: "40px",
                      }}
                    >
                      {n}
                    </Typography>
                    <Box
                      sx={{
                        height: "78px",
                        width: "78px",
                        display: "flex",
                        flexDirection: "column",
                        p: "0.1rem",
                        fontFamily: "Mulish",
                        mt: "1rem",
                        borderRadius: "3px",
                        backgroundColor: "var(--orange)",

                        flexDirection: "column",
                        alignItems: "center",
                        ":before": {
                          position: "absolute",
                          content: '""',
                          display: "block",
                          width: 0,
                          height: 0,
                          left: "50%",
                          marginLeft: "-10px",
                          marginTop: "-20px",
                          border: "10px solid transparent",
                          borderBottom: `10px solid var(--orange)`,
                        },
                      }}
                    >
                      {item.imageURL.length > 0 ? (
                        <img
                          style={{
                            marginTop: "5px",
                            width: "28px",
                            height: "28px",
                          }}
                          src={item.imageURL}
                          alt={item.title}
                        />
                      ) : (
                        <Box
                          sx={{
                            marginTop: "5px",
                            width: "28px",
                            height: "28px",
                            backgroundColor: "var(--orange)",
                          }}
                        ></Box>
                      )}
                      <Box sx={{ width: "90%", mx: "auto" }}>
                        <Typography
                          sx={{
                            fontFamily: "Mulish",
                            wordBreak: "break-word",
                            width: "100%",
                            fontSize: "0.7rem",
                            color: "white",
                          }}
                          textAlign={"center"}
                        >
                          {item.title}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                );
              })} */}
              {/* {notRevealedMilestone.map((item, idx) => {
                const n = abbrNum(item.pointsThresholdValue, 0);
                return (
                  <Box
                    key={idx}
                    sx={{
                      zIndex: 1,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      position: "relative",
                      mx: "0.5rem",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "var(--purple-dark)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "700",
                        fontFamily: "Mulish",
                        padding: "8px 9px 12px 7px",
                        fontSize: "20px",
                        borderRadius: "50%",
                        backgroundColor: "#AC88DE",
                        width: "40px",
                        height: "40px",
                      }}
                    >
                      {n}
                    </Typography>
                    <Box
                      sx={{
                        height: "78px",
                        width: "78px",
                        display: "flex",
                        flexDirection: "column",
                        p: "0.1rem",
                        fontFamily: "Mulish",
                        mt: "1rem",
                        borderRadius: "3px",
                        backgroundColor: "white",

                        flexDirection: "column",
                        alignItems: "center",
                        ":before": {
                          position: "absolute",
                          content: '""',
                          display: "block",
                          width: 0,
                          height: 0,
                          left: "50%",
                          marginLeft: "-10px",
                          marginTop: "-20px",
                          border: "10px solid transparent",
                          borderBottom: `10px solid white`,
                        },
                      }}
                    >
                      {item.imageURL.length > 0 ? (
                        <img
                          style={{
                            marginTop: "5px",
                            width: "28px",
                            height: "28px",
                          }}
                          src={item.imageURL}
                          alt={item.title}
                        />
                      ) : (
                        <Box
                          sx={{
                            marginTop: "5px",
                            width: "28px",
                            height: "28px",
                            backgroundColor: "var(--orange)",
                          }}
                        ></Box>
                      )}
                      <Box sx={{ width: "90%", mx: "auto" }}>
                        <Typography
                          sx={{
                            fontFamily: "Mulish",
                            wordBreak: "break-word",
                            width: "100%",
                            fontSize: "0.7rem",
                            color: "var(--purple)",
                          }}
                          textAlign={"center"}
                        >
                          {item.title}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
              {blurredMilestones.map((item, idx) => {
                const n = abbrNum(item.pointsThresholdValue, 0);
                return (
                  <Box
                    key={idx}
                    sx={{
                      zIndex: 1,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      position: "relative",
                      mx: "0.5rem",
                    }}
                  >
                    <Skeleton variant="circular" width={40} height={40} />

                    <Skeleton
                      variant="rectangular"
                      width={78}
                      height={78}
                      sx={{
                        borderRadius: "3px",
                        mt: "1rem",
                        ":before": {
                          position: "absolute",
                          content: '""',
                          display: "block",
                          width: 0,
                          height: 0,
                          left: "50%",
                          marginLeft: "-10px",
                          marginTop: "-19px",
                          border: "10px solid transparent",
                          borderBottom: `10px solid rgba(0, 0, 0, 0.11)`,
                        },
                      }}
                    />
                  </Box>
                );
              })} */}
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
