import { Grid, Link, Typography } from "@mui/material";
import React from "react";

const LastActivities = ({ setShowActiveGamesAndLastActivities }) => {
  return (
    <Grid item xs={6}>
      <Typography
        textAlign={"start"}
        mb="1rem"
        sx={{ fontSize: "1.2rem", fontWeight: "700", color: "white" }}
      >
        Last activites
      </Typography>

      <div
        className="live-lesson-listbtm"
        style={{
          backgroundColor: "white",
          padding: "0 0.5rem",
          height: "25rem",
          overflowY: "scroll",
          borderRadius: "20px",
        }}
      >
        <div className="lesson-hovrbx" style={{ borderTop: "none" }}>
          <Link to="/createLiveLesson" className="newlesson-card">
            <div className="sesson-listmain">
              <div className="live-lesson-main">
                <div className="custom-row">
                  <div className="lesson-leftbx">
                    <span className="medm-tex purple-clr">Live lesson</span>
                  </div>
                  <div className="lesson-rightbx">
                    <span className="medm-tex date-bx">Oct 05, 2020</span>
                  </div>
                </div>
              </div>
              <div className="title-main">
                Work on mistakes of the test on the topic "Law and legal aspects
                of business activities" from the work book
              </div>
              <div className="lesson-list-bottom">
                <div className="custom-row">
                  <div className="left-boxleson">
                    <ul className="list-point-lesson">
                      <li>
                        <span className="medm-tex orng-clr">Oct 25, 2020</span>
                      </li>
                      <li>
                        <span className="medm-tex sky-clr">Completed</span>
                      </li>
                      <li>
                        <span className="medm-tex orng-clr">
                          30 points + Badge for hard work{" "}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>

        <div className="lesson-hovrbx">
          <Link to="#" className="newlesson-card">
            <div className="sesson-listmain">
              <div className="live-lesson-main">
                <div className="custom-row">
                  <div className="lesson-leftbx">
                    <span className="medm-tex purple-clr">
                      Neithedu Game: Quiz
                    </span>
                  </div>
                  <div className="lesson-rightbx">
                    <span className="medm-tex date-bx">Dec 16, 2020</span>
                  </div>
                </div>
              </div>
              <div className="title-main">Law basics. Beginner concepts</div>
              <div className="lesson-list-bottom">
                <div className="custom-row">
                  <div className="left-boxleson">
                    <ul className="list-point-lesson">
                      <li>
                        <span className="medm-tex orng-clr">Due March 29</span>
                      </li>
                      <li>
                        <span className="medm-tex sky-clr">0/50 points</span>
                      </li>
                      <li>
                        <span className="medm-tex orng-clr">Late</span>
                      </li>
                    </ul>
                  </div>
                  <div className="right-boxlesson">
                    <div className="count-numlist">
                      <span className="count-total-actvnum">2</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>

        <div className="lesson-hovrbx">
          <Link to="#" className="newlesson-card">
            <div className="sesson-listmain">
              <div className="live-lesson-main">
                <div className="custom-row">
                  <div className="lesson-leftbx">
                    <span className="medm-tex purple-clr">
                      Neithedu Game: Quiz
                    </span>
                  </div>
                  <div className="lesson-rightbx">
                    <span className="medm-tex date-bx">Dec 16, 2020</span>
                  </div>
                </div>
              </div>
              <div className="title-main">Law basics. Beginner concepts</div>
              <div className="lesson-list-bottom">
                <div className="custom-row">
                  <div className="left-boxleson">
                    <ul className="list-point-lesson">
                      <li>
                        <span className="medm-tex orng-clr">Due March 29</span>
                      </li>
                      <li>
                        <span className="medm-tex sky-clr">0/50 points</span>
                      </li>
                      <li>
                        <span className="medm-tex orng-clr">Late</span>
                      </li>
                    </ul>
                  </div>
                  <div className="right-boxlesson">
                    <div className="count-numlist">
                      <span className="count-total-actvnum">2</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="lesson-hovrbx">
          <Link to="#" className="newlesson-card">
            <div className="sesson-listmain">
              <div className="live-lesson-main">
                <div className="custom-row">
                  <div className="lesson-leftbx">
                    <span className="medm-tex purple-clr">
                      Neithedu Game: Quiz
                    </span>
                  </div>
                  <div className="lesson-rightbx">
                    <span className="medm-tex date-bx">Dec 16, 2020</span>
                  </div>
                </div>
              </div>
              <div className="title-main">Law basics. Beginner concepts</div>
              <div className="lesson-list-bottom">
                <div className="custom-row">
                  <div className="left-boxleson">
                    <ul className="list-point-lesson">
                      <li>
                        <span className="medm-tex orng-clr">Due March 29</span>
                      </li>
                      <li>
                        <span className="medm-tex sky-clr">0/50 points</span>
                      </li>
                      <li>
                        <span className="medm-tex orng-clr">Late</span>
                      </li>
                    </ul>
                  </div>
                  <div className="right-boxlesson">
                    <div className="count-numlist">
                      <span className="count-total-actvnum">2</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </Grid>
  );
};

export default LastActivities;
