import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import DoneIcon from "@mui/icons-material/Done";
import logo from "../../assets/images/logo.png";

import { useHistory } from "react-router-dom";
import FooterEducator from "../layout/Footer/FooterEducator";

import HeaderGames from "../layout/Header/HeaderGames";

import { Box, Grid, Typography } from "@mui/material";
import { Container } from "react-bootstrap";

function TutorLevel() {
  const history = useHistory();

  const Level = ({ isActive, text, points, maxPoints }) => {
    const Content = () => {
      let Result;
      if (typeof text === "function") {
        Result = text;
        return <Result />;
      }
      return (
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontWeight: "600",
            fontSize: "0.9rem",
            color: isActive ? "black" : "#C4C4C4",
          }}
        >
          {text}
        </Typography>
      );
    };
    return (
      <Grid
        sx={{ mb: "1rem" }}
        container
        columnSpacing={"0.5rem"}
        alignItems={"flex-end"}
      >
        <Grid item xs={0.5}>
          {isActive && <DoneIcon sx={{ color: "var(--orange)" }} />}
        </Grid>
        <Grid item xs={"auto"}>
          {Content()}
        </Grid>
        <Grid item xs>
          <Box
            sx={{
              bottom: "5px",
              position: "relative",
              borderBottom: `1px dashed ${isActive ? "black" : "#C4C4C4"}`,
              width: "auto",
            }}
          ></Box>
        </Grid>
        <Grid item xs={"auto"}>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "0.9rem",
              color: isActive ? "var(--purple-dark)" : "#828282",
            }}
          >
            <b style={{ fontSize: "1.1rem" }}>{points} / </b>
            {maxPoints}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderGames />
      {/* end header */}
      <div
        className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs"
        style={{ minHeight: "100vh" }}
      >
        <Container>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <ArrowBackIcon
              onClick={() => history.push("/Myprofile")}
              sx={{
                position: "absolute",
                color: "var(--orange)",
                fontSize: "3rem",
                top: "0px",
                left: "0px",
              }}
            />
            <Typography
              sx={{ color: "white", fontSize: "1.9rem", fontWeight: "700" }}
            >
              My tutor level in Neithedu
            </Typography>
            <Typography
              onClick={() => history.push("/tutor/levelsInfo")}
              sx={{
                cursor: "pointer",
                fontSize: "1.2rem",
                color: "var(--orange)",
                fontWeight: "700",
                textDecoration: "underline",
                mb: "1rem",
              }}
            >
              What is tutor levels?
            </Typography>
          </Box>
          <Box
            sx={{
              mx: "auto",
              maxWidth: "50rem",
              backgroundColor: "var(--purple)",
              borderRadius: "20px",
              p: "0.7rem",
            }}
          >
            <div className="flowlisting-view">
              <ul className="quizflow-list">
                <li
                  className="active"
                  style={{ maxWidth: "33%", flex: "0 0 33%" }}
                >
                  <div className="listin-quizflow">
                    <div className="quixlok">
                      <span className="lockquiz-icon"></span>
                    </div>
                    <div className="flw-quiz-content">
                      <Typography sx={{ color: "white" }}>Newbie</Typography>{" "}
                    </div>
                  </div>
                </li>

                <li
                  className="active"
                  style={{ maxWidth: "33%", flex: "0 0 33%" }}
                >
                  <div className="listin-quizflow">
                    <div className="quixlok">
                      <span className="lockquiz-icon"></span>
                    </div>
                    <div className="flw-quiz-content">
                      <div style={{ color: "white" }} className="sml-tex">
                        My level is
                      </div>
                      <div
                        style={{ color: "var(--orange)" }}
                        className="medm-tex"
                      >
                        Advanced
                      </div>
                    </div>
                  </div>
                </li>

                <li className="" style={{ maxWidth: "33%", flex: "0 0 33%" }}>
                  <div className="listin-quizflow">
                    <div className="quixlok">
                      <span className="lockquiz-icon"></span>
                    </div>
                    <div className="flw-quiz-content">
                      <Typography sx={{ color: "white" }}>Master</Typography>{" "}
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <Box
              sx={{
                backgroundColor: "white",
                borderRadius: "20px",
                p: "0.7rem",
              }}
            >
              <Typography
                sx={{
                  color: "var(--purple-dark)",
                  fontSize: "1.3rem",
                  fontWeight: "700",
                  mb: "0.5rem",
                  textAlign: "start",
                  fontFamily: "Poppins",
                }}
              >
                Reach your next level
              </Typography>
              <Level
                isActive
                points={147}
                maxPoints={180}
                text={"Days of active tutoring on the website"}
              />
              <Level
                isActive
                points={20}
                maxPoints={30}
                text={"Complete live lessons for last 90 days"}
              />
              <Level
                isActive
                points={120}
                maxPoints={200}
                text={"Completed live lessons for all time"}
              />
              <Level points={120} maxPoints={200} text={"Rating"} />
              <Level
                points={120}
                maxPoints={200}
                text={
                  <Box textAlign={"start"}>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "0.9rem",
                        color: false ? "black" : "#C4C4C4",
                      }}
                    >
                      Completed on time lessons for last 90 day (not canceled,
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "0.9rem",
                        color: false ? "black" : "#C4C4C4",
                      }}
                    >
                      postponed and missed the lessons)
                    </Typography>
                  </Box>
                }
              />
            </Box>
          </Box>
        </Container>
        {/* footer start */}
        <FooterEducator logo={logo} />
        {/* footer end */}
      </div>
    </div>
  );
}

export default TutorLevel;
