import { Form, Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import React, { useContext, useState } from "react";
import { TextField } from "@mui/material";
import { CustomSnackbar, useSnackState } from "../../../utils/useSnackState";
import "react-tabs/style/react-tabs.css";
import { CreateLiveLesson } from "../../../service/apis/lesson";
import DatePicker from "react-datepicker";
import { convertDateToString } from "../../../utils/helperFunctions";
import { NotificationContext } from "../../../contexts/Notification";

export const CreateLiveLessonModal = ({
  closeHandler,
  dialogs,
  setOpenDialog,
  activeTab,
  setActiveTab,
  setActivityId,
}) => {
  const [state, setstate] = useState({
    title: "",
    dateOfLesson: null,
    timeOfLesson: Date.now(),
    placeId: "",
  });

  const [snackProps, snackOpen] = useSnackState();
  const { showNotification } = useContext(NotificationContext);

  const location = useLocation();

  const createLesson = async (e) => {
    const query = new URLSearchParams(location.search);
    const studentId = query.get("studentId");
    try {
      console.log("_state", state);
      const response = await CreateLiveLesson(
        state.title,
        convertDateToString(state.dateOfLesson),
        state.timeOfLesson,
        activeTab === 0 ? 2 : activeTab,
        studentId,
      );

      if (response) {
        setActivityId(response);
        console.log("response", response);
        showNotification("Lesson created successfully");
        setTimeout(() => {
          setOpenDialog(dialogs.CREATED);
        }, 2000);
      }
    } catch (err) {
      console.log("Error");
      snackOpen("Something went wrong", "error");
    }
  };

  const createLessonHandler = (e) => {
    console.log("state", state);
    if (state.title.trim().length < 3) {
      snackOpen("Title should be atleast 3 characters", "error");
      return;
    }
    if (state.dateOfLesson === null) {
      snackOpen("Please select date", "error");
      return;
    }
    if (state.timeOfLesson === null) {
      snackOpen("Please select time", "error");
      return;
    }
    if (activeTab === 1 && state.placeId === "") {
      snackOpen("Please select place", "error");
      return;
    }
    createLesson();
  };

  return (
    <Modal
      className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
      show={true}
      onHide={() => {
        return false;
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <CustomSnackbar {...snackProps} />
      <div className="login-modal-mainbx groupmbr-step2 mystudent-games edit-livelessn">
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>Create the live lesson</h4>
          </Modal.Title>
          <button type="button" onClick={closeHandler} className="close">
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div
            className="tabs_btns"
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem",
            }}
          >
            <button
              style={{
                background: activeTab === 0 ? "#6610f2" : "",
                padding: "0.5rem 1.5rem",
                border: "none",
                borderRadius: "6px",
                color: activeTab === 0 ? "white" : "",
              }}
              onClick={() => setActiveTab(0)}
            >
              Online
            </button>
            <button
              style={{
                background: activeTab === 1 ? "#6610f2" : "",
                padding: "0.5rem 1.5rem",
                border: "none",
                borderRadius: "6px",
                color: activeTab === 1 ? "white" : "",
              }}
              onClick={() => setActiveTab(1)}
            >
              Offline
            </button>
          </div>

          <div className="login-modl-form">
            <div className="loginform-bx">
              <Form.Group className="subsform-fild">
                <div className="newgames-createbx">
                  <div className="frm-gameflw-nw">
                    <Form.Label>Title of the lesson</Form.Label>
                    <Form.Control
                      value={state.title}
                      onChange={(e) =>
                        setstate({ ...state, title: e.target.value })
                      }
                      type="text"
                      placeholder="Type the topic or what you will do"
                    ></Form.Control>
                  </div>

                  <div className="frm-gameflw-nw">
                    <Form.Label>Date of the lesson</Form.Label>
                    {/* <Form.Control
                      type="date"
                      onChange={(e) =>
                        setstate((state) => ({
                          ...state,
                          dateOfLesson: e.target.value,
                        }))
                      }
                      value={state.dateOfLesson}
                      placeholder="2 Sep 2021"
                    ></Form.Control> */}
                    <DatePicker
                      selected={state.dateOfLesson}
                      minDate={new Date() - 1}
                      dateFormat="dd-MM-yyyy"
                      className="dateformat"
                      onChange={(date) =>
                        setstate((state) => ({
                          ...state,
                          dateOfLesson: date,
                        }))
                      }
                    />
                    {console.log("state.dateOfLesson", state.dateOfLesson)}
                  </div>
                  <div className="frm-gameflw-nw">
                    <Form.Label style={{ display: "block" }}>
                      Time of the lesson
                    </Form.Label>
                    <TextField
                      fullWidth
                      id="time"
                      type="time"
                      onChange={(e) => {
                        setstate((state) => ({
                          ...state,
                          timeOfLesson: e.target.value,
                        }));
                      }}
                      value={state.timeOfLesson}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                      sx={{
                        input: {
                          color: "var(--purple)",
                          fontWeight: "600",
                        },
                        backgroundColor: "#F0EFFC",
                        borderRadius: "10px",
                        fieldset: {
                          border: "none",
                        },
                      }}
                    />
                  </div>

                  {activeTab ? (
                    <div className="frm-gameflw-nw">
                      <Form.Label>Place</Form.Label>
                      <Form.Control
                        value={state.placeId}
                        onChange={(e) =>
                          setstate({
                            ...state,
                            placeId: e.target.value,
                          })
                        }
                        type="number"
                        placeholder="Choose the place"
                      ></Form.Control>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Form.Group>

              <div className="btnlogin-main">
                <div
                  className="btnlogin btnweak-bx"
                  onClick={createLessonHandler}
                  style={{ cursor: "pointer" }}
                >
                  Create the lesson
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};
