import { Axios, AxiosM } from "../Axios";

export const getMaterialGamesList = async (assignToId, isGroup) => {
  let user = localStorage.getItem("user");
  let userInfo = JSON.parse(user);
  let userId = userInfo?.applicationUser?.id;

  let param1 = assignToId ? assignToId : userId;
  let param2 = isGroup ? isGroup : false;

  return (await Axios.get(`/materials/${param1}/${param2}`)).data;
};

export const getMaterialliniList = async () => {
  return (await Axios.get("/materials/illumiya-materials-list")).data;
};

export const deleteMaterial = async (material_id) => {
  return (await Axios.delete(`/materials/${+material_id}`)).data;
};

export const postMaterialGame = async (data) => {
  const res = await Axios.post(
    "/materials/save-update-educator-materials",
    data,
  );
  return res.data;
};

export const getS3SignedUrl = async (fileName) => {
  return (
    await Axios.get(
      `https://services.neithedu.com/api/buckets/generate-preSignedUrl/neith-materials/${fileName}`,
    )
  ).data;
};
