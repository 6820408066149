import React, { useEffect } from "react";
import "./home.scss";
import "./profile.scss";
import "./media.scss";
import "./games.scss";
import "./loginModal/login.scss";
import "./myactivities.scss";
import "./Parentsaccount.scss";
import "./childrenprogress.scss";
import { Container, Row, Form, Modal } from "react-bootstrap";
import logo from "../assets/images/logo.png";
import { Link } from "react-router-dom";
import Headerparents from "./layout/Header/Headerparents";
import FooterParent from "./layout/Footer/FooterParent";
import Addmychild from "./Addmychild";
import { getChildProgress } from "../service/apis/parent";

function ChildrenProgress() {
  /* start modal */
  const [modalShow, setModalShow] = React.useState(false);
  /* end modal */
  const [childrenData, setChildrenData] = React.useState([]);

  /* start peroanl task*/
  const [modalpersonaltskstep1Show, setpersonaltskstep1ModalShow] =
    React.useState(false);

  /* end persoanl task  */

  /* satrt add pupil modal */

  /* start Eductor signup step1 */
  const [modalAddmychildstep1Show, setAddmychildstep1ModalShow] =
    React.useState(false);
  /* end Eductor signup step1 */

  /* end add pupil modal */

  useEffect(() => {
    getChildProgress().then((res) => {
      setChildrenData(res);
    });
  }, []);

  console.log(childrenData);

  return (
    <div className="home-wrapper">
      {/* start header */}
      <Headerparents setModalShow={(value) => setModalShow(value)} />
      {/* end header */}

      <div className="games-dashboard-main dashboard-bgnew blur-bgg childrenprogres">
        <div className="games-topbread">
          <Container>
            <div className="children-proglist mb_tab2">
              {childrenData.map((child) => (
                <div className="children-card-progd" key={child.childId}>
                  <Link to="ChildProgressDetails" className="chlidcard-outer">
                    <div className="child-inercard-prog">
                      <div className="custrow">
                        <div className="leftcard-prog">
                          <div className="child-profbx">
                            <div className="chlid-cardd">
                              <div className="child-profle">
                                <span className="profile-circl-child">
                                  <i className="fas fa-user"></i>
                                </span>
                              </div>
                              <div className="child-name">
                                <div className="name-child">
                                  {child.childName}
                                </div>
                                <div className="child-subject">
                                  {child.childCategories}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="right-prog">
                          <div className="child-righgtlist">
                            <ul className="child-activty-list">
                              <li className="iner-child-actvlist">
                                <div className="child-lst2">
                                  <h5>{child.activitiesInProgress}</h5>
                                  <h6>
                                    activities in <br></br> the process
                                  </h6>
                                </div>
                              </li>
                              <li className="iner-child-actvlist">
                                <div className="child-lst2">
                                  <h5>{child.activitiesCompleted}</h5>
                                  <h6>
                                    completed <br></br> activities
                                  </h6>
                                </div>
                              </li>
                              <li className="iner-child-actvlist">
                                <div className="child-lst2">
                                  <h5>{child.activitiesMissedDeadline}</h5>
                                  <h6>
                                    missed <br></br> deadlines
                                  </h6>
                                </div>
                              </li>
                              <li className="iner-child-actvlist">
                                <div className="child-lst2">
                                  <h5>{child.newReports}</h5>
                                  <h6>
                                    new <br></br> reports
                                  </h6>
                                </div>
                              </li>
                              <li className="iner-child-actvlist">
                                <div className="child-lst2">
                                  <h5>{child.bookedLessons}</h5>
                                  <h6>
                                    booked <br></br> lessons
                                  </h6>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </Container>
        </div>

        {/* footer start */}
        <FooterParent logo={logo} />
        {/* footer end */}
      </div>

      {/* start add pupil modal */}

      <Addmychild
        modalAddmychildstep1Show={modalAddmychildstep1Show}
        setAddmychildstep1ModalShow={(value) =>
          setAddmychildstep1ModalShow(value)
        }
      />

      {/* end add pupil modal */}

      {/* start perosnal task aprent */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        // {...props}
        show={modalpersonaltskstep1Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2 mystudent-games persltask-form">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>
                New personal task to <br></br> Alexandra
              </h4>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setpersonaltskstep1ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="newgames-createbx">
                    <Row>
                      <div className="col-md-12">
                        <Form.Label>Title of the task</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Help your mother"
                        ></Form.Control>
                      </div>
                      <div className="col-md-12">
                        <Form.Label>Choose type of a task</Form.Label>
                        <div className="tasktype">
                          <ul className="learner-listing-games taskpurple">
                            <li>
                              <button className="btn-learner-lsit">
                                Do homework
                              </button>
                            </li>
                            <li>
                              <button className="btn-learner-lsit">
                                Do house work
                              </button>
                            </li>
                            <li>
                              <button className="btn-learner-lsit">Any</button>
                            </li>
                            <li>
                              <button className="btn-learner-lsit">
                                Another taskc
                              </button>
                            </li>
                            <li>
                              <button className="btn-learner-lsit active">
                                Custom task
                              </button>
                            </li>
                            <li>
                              <button className="btn-learner-lsit">More</button>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <Form.Label>Description </Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Describe your task"
                        />
                      </div>
                      <div className="col-md-12">
                        <Form.Label>Deadline</Form.Label>
                        <div className="datepick-frm datepicker-purple">
                          <Form.Group className="subsform-fild">
                            <Form.Control
                              type="date"
                              placeholder="From"
                              className="texblck"
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <Form.Label>
                          How many points child can earn for the task
                        </Form.Label>
                      </div>
                      <div className="col-md-4 std-point-frm">
                        <Form.Control
                          type="text"
                          placeholder="20"
                          className="texblck"
                        />
                      </div>
                      <div className="col-md-4 std-point-frm std-point-label">
                        <Form.Label>points if</Form.Label>
                      </div>
                      <div className="col-md-4 std-point-frm">
                        <Form.Control as="select" className="texblck">
                          <option value="2">completed</option>
                        </Form.Control>
                      </div>
                    </Row>
                  </div>
                </Form.Group>

                <div className="btnlogin-main taskbtn-uppr">
                  <Link
                    to="#"
                    className="btnlogin btnweak-bx"
                    onClick={() => setpersonaltskstep1ModalShow(false)}
                  >
                    Send the task to Alexandra
                  </Link>
                </div>

                <div className="btnlogin-main">
                  <Link to="#" className="btnlogin btnweak-bx">
                    Send to some children
                  </Link>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end personaltask */}
    </div>
  );
}

export default ChildrenProgress;
