export const USER_DETAILS = "user";
export const OWNER_TOKEN = "OWNER_TOKEN";
export const AUTH_TOKEN = "jwtToken";

export class LocalStorage {
  static saveLogin(responseObject) {
    return new Promise((resolve) => {
      if (responseObject && responseObject.ownerToken) {
        localStorage.setItem(OWNER_TOKEN, responseObject.ownerToken);
      }
      if (responseObject) {
        localStorage.setItem(USER_DETAILS, JSON.stringify(responseObject));
      }
      if (responseObject?.token?.value)
        localStorage.setItem(AUTH_TOKEN, responseObject.token.value);
      return resolve(true);
    });
  }

  static getUserDetails() {
    try {
      const userDetailStr = localStorage.getItem(USER_DETAILS);
      return userDetailStr ? JSON.parse(userDetailStr) : undefined;
    } catch (e) {
      return undefined;
    }
  }
  static getRoleId() {
    try {
      const userDetailStr = localStorage.getItem(USER_DETAILS);
      const userDetail = userDetailStr ? JSON.parse(userDetailStr) : undefined;
      const roleId = userDetail.role;
      return Promise.resolve(roleId);
    } catch (e) {
      return Promise.resolve(0);
    }
  }
  static getAuthToken() {
    try {
      const AuthTokenStr = localStorage.getItem(AUTH_TOKEN);
      return Promise.resolve(AuthTokenStr);
    } catch (e) {
      console("\n error in AuthTokenStr:", e.message || e);
      return Promise.resolve(undefined);
    }
  }
  static getOwnerToken() {
    try {
      const OwnerTokenStr = localStorage.getItem(OWNER_TOKEN);
      return OwnerTokenStr ? OwnerTokenStr : undefined;
    } catch (e) {
      return undefined;
    }
  }

  static removeAFieldFromStorage = (field) => {
    return new Promise((resolve, reject) => {
      try {
        localStorage.removeItem(field);
        return resolve(true);
      } catch (err) {
        return reject(err);
      }
    });
  };
  static removeAllStorage() {
    return new Promise((resolve, reject) => {
      try {
        localStorage.removeItem(USER_DETAILS);
        localStorage.removeItem(OWNER_TOKEN);
        localStorage.removeItem(AUTH_TOKEN);
        return resolve(true);
      } catch (err) {
        return reject(err);
      }
    });
  }
}
