import { CircularProgress } from "@mui/material";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import { validateEmail } from "../../../service/commanFunction";
import { CustomSnackbar, useSnackState } from "../../../utils/useSnackState";

export function CreateNewPassword({ handleClose, nextPopup }) {
  const [snackProps, open] = useSnackState();
  const [form, setform] = useState({
    newPassword: "",
    repeatPassword: "",
  });

  const [viewPassword, setviewPassword] = useState("password");
  const [viewRepeatPassword, setviewRepeatPassword] = useState("password");
  const [isLoading, setisLoading] = useState(false);
  const error = {};
  const handleSave = async () => {
    if (form.newPassword.trim().length === 0) {
      open("Enter new password", "error");
      return;
    }
    if (form.newPassword.trim() !== form.repeatPassword.trim()) {
      open("Password doesn't match", "error");
      return;
    }
    handleClose();
  };
  return (
    <Modal
      className="loginmodal"
      // {...props}
      show={true}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <CustomSnackbar {...snackProps} />
      <div className="login-modal-mainbx">
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>Create new password</h4>
            <p>Thanks for confirmation!</p>
            <p>Create a new password, please</p>
          </Modal.Title>
          <button type="button" onClick={handleClose} className="close">
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="login-modl-form">
            <div className="loginform-bx">
              <Form.Group className="subsform-fild">
                <Form.Label>Create a new password</Form.Label>

                <div className="passwd-fild" style={{ position: "relative" }}>
                  <Form.Control
                    type={viewPassword}
                    placeholder="**********"
                    name="password"
                    value={form.newPassword}
                    onChange={(e) =>
                      setform({ ...form, newPassword: e.target.value })
                    }
                  />
                  <span
                    onClick={() =>
                      setviewPassword((prevState) =>
                        prevState === "text" ? "password" : "text",
                      )
                    }
                    className="icon-eye"
                    style={{
                      position: "absolute",
                      top: "13px",
                      cursor: "pointer",
                      right: "10px",
                    }}
                  >
                    <i
                      className="fas fa-eye"
                      style={{
                        color:
                          viewPassword === "text" ? "var(--purple)" : "black",
                      }}
                    ></i>
                  </span>
                </div>
                {error.password && (
                  <span className="error">{error.password}</span>
                )}
              </Form.Group>
              <Form.Group className="subsform-fild">
                <Form.Label>Repeat a password</Form.Label>

                <div className="passwd-fild" style={{ position: "relative" }}>
                  <Form.Control
                    type={viewRepeatPassword}
                    placeholder="**********"
                    name="password"
                    value={form.repeatPassword}
                    onChange={(e) =>
                      setform({ ...form, repeatPassword: e.target.value })
                    }
                  />
                  <span
                    onClick={() =>
                      setviewRepeatPassword((prevState) =>
                        prevState === "text" ? "password" : "text",
                      )
                    }
                    className="icon-eye"
                    style={{
                      position: "absolute",
                      top: "13px",
                      cursor: "pointer",
                      right: "10px",
                    }}
                  >
                    <i
                      className="fas fa-eye"
                      style={{
                        color:
                          viewRepeatPassword === "text"
                            ? "var(--purple)"
                            : "black",
                      }}
                    ></i>
                  </span>
                </div>
                {error.password && (
                  <span className="error">{error.password}</span>
                )}
              </Form.Group>
              <div className="btnlogin-main">
                <button
                  style={{ fontSize: "0.8rem" }}
                  disabled={isLoading}
                  onClick={handleSave}
                  className="btnlogin btnweak-bx"
                >
                  {isLoading ? (
                    <CircularProgress
                      size={"1.5rem"}
                      sx={{ color: "var(--purple)" }}
                    />
                  ) : (
                    "Save and go to my account"
                  )}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
}
