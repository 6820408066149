import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import "./FileUpload.scss";

function FileUpload({ uploadedMaterials, setUploadedMaterials }) {
  console.log("uploadedMaterials", uploadedMaterials);

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 5,
    onDrop: (acceptedFiles) => {
      let newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      );

      console.log("uploaded files", newFiles);

      let filesToBeAdded = newFiles.map((f, i) => ({
        fileData: f,
        gamePosition: uploadedMaterials.length + i,
        materialType: "new",
      }));

      // assign gamePosition: index, starting from index of last item in uploadedMaterials
      setUploadedMaterials([...uploadedMaterials, ...filesToBeAdded]);
    },
  });

  // onLoad={() => {
  //   URL.revokeObjectURL(file.preview);
  // }}

  useEffect(() => {
    return () =>
      uploadedMaterials.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <section className="fileUploadContainer">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
    </section>
  );
}
export default FileUpload;
