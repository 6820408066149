import React, { useEffect, useRef } from "react";
import VideoLayout from "./components/VideoLayout";
import VideoChatControls from "./components/VideoChatControls";
import Chat from "./components/Chat";
import "./styles/home.css";
import "./styles/main.css";
import Iframe from "react-iframe";
import io from "socket.io-client";
import { useHistory } from "react-router-dom";
import "./styles/videoLayout.css";
import {
  OFFER_OPTIONS,
  PEERCONNECTION_CONFIGURATION,
  SOCKET_IO_URL,
} from "./components/liveLessonConstants";
import { updateLessonStatus } from "../../service/apis/lesson";
var isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function () {
    return (
      navigator.userAgent.match(/IEMobile/i) ||
      navigator.userAgent.match(/WPDesktop/i)
    );
  },
  any: function () {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
    );
  },
};

function LiveLessonApp() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  let is_teacher = params.get("isTeacher");
  const room = params.get("roomId");
  const activityId = params.get("activityId");

  // start
  let myUserName = params.get("name");
  const [socket, setSocket] = React.useState(null);
  const [isSocketConnected, setIsSocketConnected] = React.useState(false);
  const [remoteName, setRemoteName] = React.useState("");
  const [whiteBoardUrl, setWhiteBoardUrl] = React.useState("");
  const [messages, setMessages] = React.useState([]);
  const [isChatWindowOpen, setIsChatWindowOpen] = React.useState(false);
  const [isWhiteBoardActive, setIsWhiteBoardActive] = React.useState(false);
  const [isGridActive, setIsGridActive] = React.useState(false);
  const [whiteBoardFullScreen, setWhiteBoardFullScreen] = React.useState(false);
  const [fetchedWhiteBoardUrl, setFetchedWhiteBoardUrl] = React.useState("");

  const localStreamRef = React.useRef(null);
  const remoteStreamRef = React.useRef(null);
  const peerConnectionRef = React.useRef(null);

  const selfVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);

  const setLocalStream = (data) => {
    localStreamRef.current = data;
  };

  const VideoLayoutNew = ({
    selfParent,
    selfVideo,
    remoteParent,
    remoteVideo,
    selfName,
    remoteName,
    videosParent,
  }) => {
    return (
      <>
        <div className={videosParent}>
          <div className={remoteParent}>
            <video
              className={remoteVideo}
              ref={remoteVideoRef}
              playsInline
              autoPlay
            />
            {remoteName && <span className={remoteName}>{remoteName}</span>}
          </div>
          <div className={selfParent}>
            <video
              className={selfVideo}
              ref={selfVideoRef}
              playsInline
              autoPlay
              muted
            />
            {myUserName && <span className={selfName}>You</span>}
          </div>
        </div>
      </>
    );
  };

  const onSocketConnected = () => {
    try {
      console.log("__socket connected");
      setIsSocketConnected(true);
    } catch (e) {
      console.log("__socket connection Exception " + e);
    }
  };
  const onSocketDisconnected = () => {
    try {
      console.log("__socket disconnected");
      setIsSocketConnected(false);
    } catch (e) {
      console.log("__socket disconnection Exception " + e);
    }
  };

  const gotRemoteStream = (event) => {
    console.log("gotRemoteStream", event.streams[0]);
    try {
      if (remoteStreamRef.current === event.streams[0]) return;
      remoteStreamRef.current = event.streams[0];
      remoteVideoRef.current.srcObject = event.streams[0];
    } catch (e) {
      console.log("remotestream Exception " + e);
    }
  };
  const onIceStateChange = (e) => {
    try {
    } catch (e) {
      console.log("icestatechange Exception " + e);
    }
  };

  const onPeerConnected = async (data) => {
    if (!socket) return;
    try {
      if (data) {
        setRemoteName(data.name);
      }

      socket.emit("update_name", {
        room: room,
        name: myUserName,
      });

      const pc_constrants = {};
      peerConnectionRef.current = new RTCPeerConnection(
        PEERCONNECTION_CONFIGURATION,
        pc_constrants,
      );
      peerConnectionRef.current.addEventListener("icecandidate", (e) =>
        onIceCandidate(e),
      );
      peerConnectionRef.current.addEventListener("track", (e) =>
        gotRemoteStream(e),
      );
      peerConnectionRef.current.addEventListener(
        "iceconnectionstatechange",
        (e) => onIceStateChange(e),
      );

      for (const track of localStreamRef.current?.getTracks()) {
        peerConnectionRef.current.addTrack(track, localStreamRef.current);
      }

      const offer = await peerConnectionRef.current.createOffer(OFFER_OPTIONS);
      await peerConnectionRef.current.setLocalDescription(offer);

      socket.emit("offer", {
        room: room,
        jsep: offer,
      });

      if (is_teacher && whiteBoardUrl) {
        // We already have isWhiteBoardActive session is running
        socket.emit("start-whiteboard", {
          room: room,
          name: myUserName,
          url: whiteBoardUrl,
        });
      }
    } catch (e) {
      console.log("peer connected Exception " + e);
    }
  };

  const onPeerDisconnected = async () => {
    try {
      if (peerConnectionRef.current) peerConnectionRef.current.close();
      remoteStreamRef.current = null;
      remoteVideoRef.current.srcObject = null;

      if (!is_teacher)
        localStreamRef.current.getTracks().forEach((track) => track.stop());

      peerConnectionRef.current = null;
    } catch (e) {
      console.log("peer disconnect Exception " + e);
    } finally {
      peerConnectionRef.current = null;
    }
  };

  const onIceCandidate = (e) => {
    try {
      if (!e.candidate) return;
      socket.emit("candidate", {
        room: room,
        jsep: e.candidate,
      });
    } catch (e) {
      console.log("icecandidate Exception " + e);
    }
  };
  const onUpdateRemoteName = async (data) => {
    setRemoteName(data.name);
  };

  const onOffer = async (data) => {
    try {
      const pc_constrants = {};
      peerConnectionRef.current = new RTCPeerConnection(
        PEERCONNECTION_CONFIGURATION,
        pc_constrants,
      );
      peerConnectionRef.current.addEventListener("icecandidate", (e) =>
        onIceCandidate(e),
      );
      peerConnectionRef.current.addEventListener("track", (e) =>
        gotRemoteStream(e),
      );
      peerConnectionRef.current.addEventListener(
        "iceconnectionstatechange",
        (e) => onIceStateChange(e),
      );

      for (const track of localStreamRef.current.getTracks()) {
        peerConnectionRef.current.addTrack(track, localStreamRef.current);
      }
      peerConnectionRef.current.setRemoteDescription(
        new RTCSessionDescription(data.jsep),
      );
      const answer = await peerConnectionRef.current.createAnswer(
        OFFER_OPTIONS,
      );
      await peerConnectionRef.current.setLocalDescription(answer);

      socket.emit("answer", {
        room: room,
        jsep: answer,
      });
    } catch (e) {
      console.log("offer Exception " + e);
    }
  };

  const onAnswer = async (data) => {
    try {
      peerConnectionRef.current.setRemoteDescription(
        new RTCSessionDescription(data.jsep),
      );
    } catch (e) {
      console.log("[Connector:onAnswer] Exception " + e);
    }
  };

  const onCandidate = async (data) => {
    try {
      peerConnectionRef.current.addIceCandidate(new RTCIceCandidate(data.jsep));
    } catch (e) {
      console.log("[Connector:onCandidate] Exception " + e);
    }
  };

  const onStartWhiteBoard = async (data) => {
    try {
      console.log("[Connector:onStartWhiteBoard] " + JSON.stringify(data));
      setWhiteBoardUrl(data.url);
      setIsWhiteBoardActive(true);
    } catch (e) {
      console.log("[Connector:onStartWhiteBoard] Exception " + e);
    }
  };

  const onStopWhiteBoard = async (data) => {
    try {
      setWhiteBoardUrl(null);
      setIsWhiteBoardActive(false);
    } catch (e) {
      console.log("[Connector:onStopWhiteBoard] Exception " + e);
    }
  };

  const onMuteAudio = async (data) => {
    try {
      console.log("[Connector:onMuteAudio] " + JSON.stringify(data));
    } catch (e) {
      console.log("[Connector:onMuteAudio] Exception " + e);
    }
  };

  const onBlockVideo = async (data) => {
    try {
      console.log("[Connector:onBlockVideo] " + JSON.stringify(data));
    } catch (e) {
      console.log("[Connector:onBlockVideo] Exception " + e);
    }
  };

  const onChat = async (data) => {
    try {
      const time = new Date();
      console.log("got on chat message " + data.message);
      let obj = {
        value: data.message,
        style: "receive",
        animation: false,
        timeStamp: time.getHours() + ":" + ("0" + time.getMinutes()).slice(-2),
      };
      setMessages((messages) => [...messages, obj]);
    } catch (e) {
      console.log("[Connector:onChat] Exception " + e);
    }
  };
  const onRead = async (data) => {
    try {
      if (data.read === true) {
        let _messages = { ...messages };
        _messages.forEach((element) => {
          if (element.style === "send") {
            element.read = true;
          }
        });
        setIsChatWindowOpen(true);
        setMessages(_messages);
      } else {
        setIsChatWindowOpen(false);
      }
    } catch (e) {
      console.log("[Connector:onRead] Exception " + e);
    }
  };

  const createLocalStream = async () => {
    try {
      if (isMobile.Android() || isMobile.iOS()) {
        localStreamRef.current = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: { width: 640, height: 480 },
        });
      } else {
        localStreamRef.current = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: { width: 1280, height: 720 },
        });
      }
      selfVideoRef.current.srcObject = localStreamRef.current;
    } catch (e) {
      console.log("[Connector:createLocalStream] Exception " + e);
    }
  };

  console.log("localstreamRef.current", localStreamRef.current);
  console.log("remoteStreamRef.current", remoteStreamRef.current);

  const toggleWhiteboard = () => {
    try {
      if (!isWhiteBoardActive) {
        socket.emit("start-whiteboard", {
          room: room,
          name: myUserName,
          url: fetchedWhiteBoardUrl === "" ? null : fetchedWhiteBoardUrl,
        });

        if (fetchedWhiteBoardUrl) {
          setWhiteBoardUrl(fetchedWhiteBoardUrl);
        }
      } else {
        socket.emit("stop-whiteboard", {
          room: room,
          name: myUserName,
        });

        setFetchedWhiteBoardUrl(whiteBoardUrl);
        setWhiteBoardUrl(null);
      }

      setIsWhiteBoardActive(!isWhiteBoardActive);
    } catch (e) {
      console.log("[Connector:toggleWhiteboard] Exception " + e);
    }
  };

  const onChatWindowOpen = () => {
    try {
      socket.emit("read", {
        room: room,
        read: true,
      });
    } catch (e) {
      console.log("[Connector:onChatWindowOpen] Exception " + e);
    }
  };

  const toggleChatWindow = () => {
    try {
      if (!isChatWindowOpen) {
        onChatWindowOpen();
        setIsChatWindowOpen(true);
      } else {
        setIsChatWindowOpen(false);
      }
    } catch (e) {
      console.log("[Connector:toggleChatWindow] Exception " + e);
    }
  };

  const sendMessage = async (message) => {
    try {
      const time = new Date();
      let obj = {
        value: message,
        style: "send",
        animation: false,
        timeStamp: time.getHours() + ":" + ("0" + time.getMinutes()).slice(-2),
        read: true,
      };

      await socket.emit("chat", {
        room: room,
        message: message,
      });
      setMessages((messages) => [...messages, obj]);
    } catch (e) {
      console.log("[Connector:sendMessage] Exception " + e);
    }
  };
  const history = useHistory();
  const terminateMeeting = async () => {
    try {
      let isExecuted = window.confirm(
        "Are you sure you want to stop the session?",
      );
      if (!isExecuted) {
        return;
      }

      // change status
      await updateLessonStatus(room, 7, 0);

      if (peerConnectionRef.current) {
        peerConnectionRef.current.close();
      }

      peerConnectionRef.current = null;
      setRemoteName("");
      remoteStreamRef.current = null;
      remoteVideoRef.current.srcObject = null;

      localStreamRef.current = localStreamRef.current
        .getTracks()
        .forEach((track) => track.stop());
      setLocalStream(null);
      selfVideoRef.current.srcObject = null;
      history.push(`/LiveLessonDetail/8/${activityId}`);
    } catch (e) {
      console.log("[Connector:stop] Exception " + e);
    }

    try {
      socket.close();
    } catch (e) {
      console.log("[Connector:stop] Exception 1 " + e);
    } finally {
      socket.close();
    }
  };

  async function connect() {
    let is_initilized = false;
    try {
      if (is_initilized) {
        return;
      }

      is_initilized = true;

      socket.on("connect", () => onSocketConnected());
      socket.on("disconnect", () => onSocketDisconnected());
      socket.on("peer-connected", (data) => onPeerConnected(data));
      socket.on("peer-disconnected", (data) => onPeerDisconnected(data));
      socket.on("update_name", (data) => onUpdateRemoteName(data));
      socket.on("offer", (data) => onOffer(data));
      socket.on("answer", (data) => onAnswer(data));
      socket.on("candidate", (data) => onCandidate(data));
      socket.on("start-whiteboard", (data) => onStartWhiteBoard(data));
      socket.on("stop-whiteboard", (data) => onStopWhiteBoard(data));
      socket.on("mute-audio", (data) => onMuteAudio(data));
      socket.on("block-video", (data) => onBlockVideo(data));
      socket.on("chat", (data) => onChat(data));
      socket.on("read", (data) => onRead(data));
    } catch (e) {
      console.log("[Connector:connect] Exception " + e);
    }
  }

  const start = async () => {
    try {
      await createLocalStream();
      await socket.emit("join-room", {
        room: room,
        name: myUserName,
        is_teacher: is_teacher,
      });
    } catch (e) {
      console.log("[Connector:connect] Exception " + e);
    }
  };
  useEffect(() => {
    if (!socket) return;
    const fn = async () => {
      await connect();
      await start();
    };
    fn();
  }, [socket]);

  useEffect(() => {
    if (socket) return;
    let _socket = io(SOCKET_IO_URL);
    setSocket(_socket);
  }, []);

  // if whiteboard is active and full screen, then show only whiteboard
  if (isWhiteBoardActive && whiteBoardFullScreen) {
    return (
      <div className="CBFVL page-wrapper">
        <div className="content-wrapper">
          <div>
            <div className="video-and-controls-wrapper">
              <VideoChatControls
                localStream={localStreamRef.current}
                setLocalStream={setLocalStream}
                toggleWhiteboard={toggleWhiteboard}
                isWhiteBoardActive={isWhiteBoardActive}
                whiteBoardFullScreen={whiteBoardFullScreen}
                setWhiteBoardFullScreen={setWhiteBoardFullScreen}
                toggleChatWindow={toggleChatWindow}
                isChatWindowOpen={isChatWindowOpen}
                isGridActive={isGridActive}
                setIsGridActive={setIsGridActive}
                terminateMeeting={terminateMeeting}
              />
              <VideoLayoutNew
                selfParent="selfParent"
                selfVideo="selfVideo"
                remoteParent="remoteParent"
                remoteVideo="remoteVideo"
                selfName="selfName"
                remoteName="remoteName"
                videosParent="videosParent"
              />
            </div>
            <div className="board-wrapper">
              <Iframe
                url={whiteBoardUrl}
                id="myId"
                width="100%"
                height="100%"
              />
            </div>
          </div>
          {isChatWindowOpen && (
            <div className="CBFVL CSVL-chat-wrapper">
              <Chat
                toggleChat={toggleChatWindow}
                sendChatMessage={sendMessage}
                messages={messages}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  // if chat is closed, isWhiteBoardActive is closed, grid view is closed, then show fullSelfVideoLayout
  if (!isChatWindowOpen && !isWhiteBoardActive && !isGridActive) {
    console.log("fullSelfVideoLayout");
    return (
      <>
        <div
          className="FSVL page-wrapper"
          onClick={() => {
            console.log("1515", localStreamRef.current);
          }}
        >
          <div className="content-wrapper">
            <div className="video-and-controls-wrapper">
              <VideoChatControls
                localStream={localStreamRef.current}
                setLocalStream={setLocalStream}
                toggleWhiteboard={toggleWhiteboard}
                isWhiteBoardActive={isWhiteBoardActive}
                whiteBoardFullScreen={whiteBoardFullScreen}
                setWhiteBoardFullScreen={setWhiteBoardFullScreen}
                toggleChatWindow={toggleChatWindow}
                isChatWindowOpen={isChatWindowOpen}
                isGridActive={isGridActive}
                setIsGridActive={setIsGridActive}
                terminateMeeting={terminateMeeting}
              />

              <VideoLayoutNew
                selfParent="selfParent"
                selfVideo="selfVideo"
                remoteParent="remoteParent"
                remoteVideo="remoteVideo"
                selfName="selfName"
                remoteName="remoteName"
                videosParent="videosParent"
              />
            </div>
          </div>
          {isWhiteBoardActive && whiteBoardUrl && (
            <div>
              <Iframe
                url={whiteBoardUrl}
                id="myId"
                width="100%"
                height="100%"
              />
            </div>
          )}

          {isChatWindowOpen && (
            <Chat
              toggleChat={toggleChatWindow}
              sendChatMessage={sendMessage}
              messages={messages}
            />
          )}
        </div>
      </>
    );
  }

  // if chat is open, isWhiteBoardActive is closed, grid view is closed, then show chat and selfVideoLayout

  if (isChatWindowOpen && !isWhiteBoardActive && !isGridActive) {
    console.log("chat selfVideoLayout");
    return (
      <>
        <div className="CSVL page-wrapper">
          <div className="content-wrapper">
            <div className="video-and-controls-wrapper">
              <VideoChatControls
                localStream={localStreamRef.current}
                setLocalStream={setLocalStream}
                toggleWhiteboard={toggleWhiteboard}
                isWhiteBoardActive={isWhiteBoardActive}
                whiteBoardFullScreen={whiteBoardFullScreen}
                setWhiteBoardFullScreen={setWhiteBoardFullScreen}
                toggleChatWindow={toggleChatWindow}
                isChatWindowOpen={isChatWindowOpen}
                isGridActive={isGridActive}
                setIsGridActive={setIsGridActive}
                terminateMeeting={terminateMeeting}
              />

              <VideoLayoutNew
                selfParent="selfParent"
                selfVideo="selfVideo"
                remoteParent="remoteParent"
                remoteVideo="remoteVideo"
                selfName="selfName"
                remoteName="remoteName"
                videosParent="videosParent"
              />
            </div>

            {isChatWindowOpen && (
              <div className="CSVL CSVL-chat-wrapper">
                <Chat
                  toggleChat={toggleChatWindow}
                  sendChatMessage={sendMessage}
                  messages={messages}
                />
              </div>
            )}
          </div>
          {isWhiteBoardActive && (
            <div>
              <Iframe
                url={whiteBoardUrl}
                id="myId"
                width="100%"
                height="100%"
              />
            </div>
          )}
        </div>
      </>
    );
  }
  // if chat is closed, isWhiteBoardActive is open, grid view is closed, then show isWhiteBoardActive and selfVideoLayout
  if (
    !isChatWindowOpen &&
    isWhiteBoardActive &&
    !isGridActive &&
    !whiteBoardFullScreen
  ) {
    console.log("board selfVideoLayout");
    return (
      <>
        <div className="BSVL page-wrapper">
          <div className="content-wrapper">
            <div className="video-and-controls-wrapper">
              <VideoChatControls
                localStream={localStreamRef.current}
                setLocalStream={setLocalStream}
                toggleWhiteboard={toggleWhiteboard}
                isWhiteBoardActive={isWhiteBoardActive}
                whiteBoardFullScreen={whiteBoardFullScreen}
                setWhiteBoardFullScreen={setWhiteBoardFullScreen}
                toggleChatWindow={toggleChatWindow}
                isChatWindowOpen={isChatWindowOpen}
                isGridActive={isGridActive}
                setIsGridActive={setIsGridActive}
                terminateMeeting={terminateMeeting}
              />
              <VideoLayoutNew
                selfParent="selfParent"
                selfVideo="selfVideo"
                remoteParent="remoteParent"
                remoteVideo="remoteVideo"
                selfName="selfName"
                remoteName="remoteName"
                videosParent="videosParent"
              />
            </div>
            <div className="board-wrapper">
              <Iframe
                url={whiteBoardUrl}
                id="myId"
                width="100%"
                height="100%"
              />
            </div>

            {isChatWindowOpen && (
              <div className="BSVL CSVL-chat-wrapper">
                <Chat
                  toggleChat={toggleChatWindow}
                  sendChatMessage={sendMessage}
                  messages={messages}
                />
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
  // if chat is closed,isWhiteBoardActive is full screen, grid view is closed, then show isWhiteBoardActive only

  if (
    !isChatWindowOpen &&
    !isGridActive &&
    whiteBoardFullScreen &&
    isWhiteBoardActive
  ) {
    return (
      <>
        <div className="FBSVL page-wrapper">
          <div className="content-wrapper">
            <div className="video-and-controls-wrapper">
              <VideoChatControls
                localStream={localStreamRef.current}
                setLocalStream={setLocalStream}
                toggleWhiteboard={toggleWhiteboard}
                isWhiteBoardActive={isWhiteBoardActive}
                whiteBoardFullScreen={whiteBoardFullScreen}
                setWhiteBoardFullScreen={setWhiteBoardFullScreen}
                toggleChatWindow={toggleChatWindow}
                isChatWindowOpen={isChatWindowOpen}
                isGridActive={isGridActive}
                setIsGridActive={setIsGridActive}
                terminateMeeting={terminateMeeting}
              />
              <VideoLayoutNew
                selfParent="selfParent"
                selfVideo="selfVideo"
                remoteParent="remoteParent"
                remoteVideo="remoteVideo"
                selfName="selfName"
                remoteName="remoteName"
                videosParent="videosParent"
              />
            </div>
            <div className="board-wrapper">
              <Iframe
                url={whiteBoardUrl}
                id="myId"
                width="100%"
                height="100%"
              />
            </div>

            {isChatWindowOpen && (
              <div className="FBSVL CSVL-chat-wrapper">
                <Chat
                  toggleChat={toggleChatWindow}
                  sendChatMessage={sendMessage}
                  messages={messages}
                />
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
  // if chat is closed, isWhiteBoardActive is closed, grid view is open, then show grid view
  if (!isChatWindowOpen && !isWhiteBoardActive && isGridActive) {
    console.log("grid view");
    return (
      <>
        <div className="GV page-wrapper">
          <div className="content-wrapper">
            <div className="video-and-controls-wrapper">
              <VideoChatControls
                localStream={localStreamRef.current}
                setLocalStream={setLocalStream}
                toggleWhiteboard={toggleWhiteboard}
                isWhiteBoardActive={isWhiteBoardActive}
                whiteBoardFullScreen={whiteBoardFullScreen}
                setWhiteBoardFullScreen={setWhiteBoardFullScreen}
                toggleChatWindow={toggleChatWindow}
                isChatWindowOpen={isChatWindowOpen}
                isGridActive={isGridActive}
                setIsGridActive={setIsGridActive}
                terminateMeeting={terminateMeeting}
              />
              <VideoLayoutNew
                selfParent="selfParent"
                selfVideo="selfVideo"
                remoteParent="remoteParent"
                remoteVideo="remoteVideo"
                selfName="selfName"
                remoteName="remoteName"
                videosParent="videosParent"
              />
            </div>
          </div>
        </div>
      </>
    );
  }

  // if chat is active and isWhiteBoardActive is active, but grid view is not active, then show chat, isWhiteBoardActive and selfVideoLayout
  if (
    isChatWindowOpen &&
    isWhiteBoardActive &&
    !isGridActive &&
    !whiteBoardFullScreen
  ) {
    console.log("chat board selfVideoLayout CBSVL");
    return (
      <>
        <div className="CBSVL page-wrapper">
          <div className="content-wrapper">
            <div className="left-content">
              <div className="video-and-controls-wrapper">
                <VideoChatControls
                  localStream={localStreamRef.current}
                  setLocalStream={setLocalStream}
                  toggleWhiteboard={toggleWhiteboard}
                  isWhiteBoardActive={isWhiteBoardActive}
                  whiteBoardFullScreen={whiteBoardFullScreen}
                  setWhiteBoardFullScreen={setWhiteBoardFullScreen}
                  toggleChatWindow={toggleChatWindow}
                  isChatWindowOpen={isChatWindowOpen}
                  isGridActive={isGridActive}
                  setIsGridActive={setIsGridActive}
                  terminateMeeting={terminateMeeting}
                />

                <VideoLayoutNew
                  selfParent="selfParent"
                  selfVideo="selfVideo"
                  remoteParent="remoteParent"
                  remoteVideo="remoteVideo"
                  selfName="selfName"
                  remoteName="remoteName"
                  videosParent="videosParent"
                />
              </div>
              <div className="board-wrapper">
                <Iframe
                  url={whiteBoardUrl}
                  id="myId"
                  width="100%"
                  height="100%"
                />
              </div>
            </div>
            {isChatWindowOpen && (
              <div className="CBSVL CSVL-chat-wrapper">
                <Chat
                  toggleChat={toggleChatWindow}
                  sendChatMessage={sendMessage}
                  messages={messages}
                />
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
  // if chat is active, whiteboard is active, whiteboard is full screen and grid view is not active, then show chat, whiteboard and selfVideoLayout

  if (
    isChatWindowOpen &&
    isWhiteBoardActive &&
    whiteBoardFullScreen &&
    !isGridActive
  ) {
    console.log("chat board full video layout CBFVL");
    return (
      <>
        <div className="CBFVL page-wrapper">
          <div className="content-wrapper">
            <div>
              <div className="video-and-controls-wrapper">
                <VideoChatControls
                  localStream={localStreamRef.current}
                  setLocalStream={setLocalStream}
                  toggleWhiteboard={toggleWhiteboard}
                  isWhiteBoardActive={isWhiteBoardActive}
                  whiteBoardFullScreen={whiteBoardFullScreen}
                  setWhiteBoardFullScreen={setWhiteBoardFullScreen}
                  toggleChatWindow={toggleChatWindow}
                  isChatWindowOpen={isChatWindowOpen}
                  isGridActive={isGridActive}
                  setIsGridActive={setIsGridActive}
                  terminateMeeting={terminateMeeting}
                />
                <VideoLayoutNew
                  selfParent="selfParent"
                  selfVideo="selfVideo"
                  remoteParent="remoteParent"
                  remoteVideo="remoteVideo"
                  selfName="selfName"
                  remoteName="remoteName"
                  videosParent="videosParent"
                />
              </div>
              <div className="board-wrapper">
                <Iframe
                  url={whiteBoardUrl}
                  id="myId"
                  width="100%"
                  height="100%"
                />
              </div>
            </div>
            {isChatWindowOpen && (
              <div className="CBFVL CSVL-chat-wrapper">
                <Chat
                  toggleChat={toggleChatWindow}
                  sendChatMessage={sendMessage}
                  messages={messages}
                />
              </div>
            )}
          </div>
        </div>
      </>
    );
  }

  // if grid is active, chat is active
  if (isGridActive && isChatWindowOpen && !isWhiteBoardActive) {
    console.log("grid view chat GVC");
    // copy from CBSVL
    return (
      <>
        <div className="GVC page-wrapper">
          <div className="content-wrapper">
            <div className="left-content">
              <div className="video-and-controls-wrapper">
                <VideoChatControls
                  localStream={localStreamRef.current}
                  setLocalStream={setLocalStream}
                  toggleWhiteboard={toggleWhiteboard}
                  isWhiteBoardActive={isWhiteBoardActive}
                  whiteBoardFullScreen={whiteBoardFullScreen}
                  setWhiteBoardFullScreen={setWhiteBoardFullScreen}
                  toggleChatWindow={toggleChatWindow}
                  isChatWindowOpen={isChatWindowOpen}
                  isGridActive={isGridActive}
                  setIsGridActive={setIsGridActive}
                  terminateMeeting={terminateMeeting}
                />
                <VideoLayoutNew
                  selfParent="selfParent"
                  selfVideo="selfVideo"
                  remoteParent="remoteParent"
                  remoteVideo="remoteVideo"
                  selfName="selfName"
                  remoteName="remoteName"
                  videosParent="videosParent"
                />
              </div>
            </div>
            {isChatWindowOpen && (
              <div className="GVC CSVL-chat-wrapper">
                <Chat
                  toggleChat={toggleChatWindow}
                  sendChatMessage={sendMessage}
                  messages={messages}
                />
              </div>
            )}
          </div>
        </div>
      </>
    );
  }

  // if grid is active, whiteboard is active, chat is not active
  if (isGridActive && isWhiteBoardActive && !isChatWindowOpen) {
    console.log("grid view board GVB");
    // copy from CBSVL
    return (
      <>
        <div className="GVB page-wrapper">
          <div className="content-wrapper">
            <div className="left-content">
              <div className="video-and-controls-wrapper">
                <VideoChatControls
                  localStream={localStreamRef.current}
                  setLocalStream={setLocalStream}
                  toggleWhiteboard={toggleWhiteboard}
                  isWhiteBoardActive={isWhiteBoardActive}
                  whiteBoardFullScreen={whiteBoardFullScreen}
                  setWhiteBoardFullScreen={setWhiteBoardFullScreen}
                  toggleChatWindow={toggleChatWindow}
                  isChatWindowOpen={isChatWindowOpen}
                  isGridActive={isGridActive}
                  setIsGridActive={setIsGridActive}
                  terminateMeeting={terminateMeeting}
                />

                <VideoLayoutNew
                  selfParent="selfParent"
                  selfVideo="selfVideo"
                  remoteParent="remoteParent"
                  remoteVideo="remoteVideo"
                  selfName="selfName"
                  remoteName="remoteName"
                  videosParent="videosParent"
                />
              </div>
            </div>
            <div className="board-wrapper">
              <Iframe
                url={whiteBoardUrl}
                id="myId"
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </div>
      </>
    );
  }

  // if chat is open, whiteboard is active, grid view is active
  if (isChatWindowOpen && isWhiteBoardActive && isGridActive) {
    console.log("grid view board chat GVBC");
    // copy from CBSVL
    return (
      <>
        <div className="GVBC page-wrapper">
          <div className="content-wrapper">
            <div>
              <div className="left-content">
                <div className="video-and-controls-wrapper">
                  <VideoChatControls
                    localStream={localStreamRef.current}
                    setLocalStream={setLocalStream}
                    toggleWhiteboard={toggleWhiteboard}
                    isWhiteBoardActive={isWhiteBoardActive}
                    whiteBoardFullScreen={whiteBoardFullScreen}
                    setWhiteBoardFullScreen={setWhiteBoardFullScreen}
                    toggleChatWindow={toggleChatWindow}
                    isChatWindowOpen={isChatWindowOpen}
                    isGridActive={isGridActive}
                    setIsGridActive={setIsGridActive}
                    terminateMeeting={terminateMeeting}
                  />
                  <VideoLayoutNew
                    selfParent="selfParent"
                    selfVideo="selfVideo"
                    remoteParent="remoteParent"
                    remoteVideo="remoteVideo"
                    selfName="selfName"
                    remoteName="remoteName"
                    videosParent="videosParent"
                  />
                </div>
              </div>
              <div className="board-wrapper">
                <Iframe
                  url={whiteBoardUrl}
                  id="myId"
                  width="100%"
                  height="100%"
                />
              </div>
            </div>
            {isChatWindowOpen && (
              <div className="GVBC CSVL-chat-wrapper">
                <Chat
                  toggleChat={toggleChatWindow}
                  sendChatMessage={sendMessage}
                  messages={messages}
                />
              </div>
            )}
          </div>
        </div>
      </>
    );
  }

  return <></>;
}

export default LiveLessonApp;
