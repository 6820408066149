import React, { useState } from "react";
import { HeaderDashboard } from "../layout/HeaderDashboard";
import logo from "../../assets/images/logo.png";
import Footer from "../layout/Footer/Footer";
// import PositionPoint from "../../assets/images/position.svg";
// import ImageIcon from "@mui/icons-material/Image";
import { useLocation } from "react-router";
import { withRouter } from "react-router";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
// import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { abbrNum } from "../../utils/roundNumber";
import { useHistory } from "react-router-dom";

import "../home.scss";
import "../profile.scss";
import "../loginModal/login.scss";
import "../dashboard.scss";
import "../sidemenu.scss";
import "../media.scss";

// import BackArrow from "../../assets/images/back-arrow.svg";
import { Box, Typography } from "@mui/material";
import {
  findTwoNumbers,
  greatestArray,
  notRevealedMilestoneArray,
  revealedMilestonesArray,
  // sortArray,
} from "../../utils/filterArray";
import VTimeline from "../VTimeline/VTimeline";
const CommunityReward = (props) => {
  const [value, setValue] = useState("");
  let location = useLocation();
  const communityRewardData = location?.state.data;

  console.log(communityRewardData)

  // const score = -communityRewardData.communityRewardPoints;
  communityRewardData.rewardsData.map((s) => {
    const n = abbrNum(s.pointsThresholdValue, 0);
  });

  var pointsArr = [];
  var blurredMilestones = [];
  var notRevealedMilestone = [];

  communityRewardData.rewardsData.map((i, index) => {
    pointsArr.push(i.pointsThresholdValue);
  });
  const result = findTwoNumbers(
    pointsArr,
    communityRewardData.communityRewardPoints,
  );
  if (
    communityRewardData.communityRewardPoints >= 0 &&
    communityRewardData.communityRewardPoints <= pointsArr[0]
  ) {
    blurredMilestones = greatestArray(
      communityRewardData.rewardsData,
      result[0],
    );
    notRevealedMilestone = notRevealedMilestoneArray(
      communityRewardData.rewardsData,
      result[0],
    );
  } else {
    blurredMilestones = greatestArray(
      communityRewardData.rewardsData,
      result[1],
    );
    notRevealedMilestone = notRevealedMilestoneArray(
      communityRewardData.rewardsData,
      result[1],
    );
  }

  const revealedMilestones = revealedMilestonesArray(
    communityRewardData.rewardsData,
    communityRewardData.communityRewardPoints,
  );
  // const notRevealedMilestone = notRevealedMilestoneArray(
  //   communityRewardData.rewardsData,
  //   result[1],
  // );
  function valuetext(value) {
    setValue(value);
    return `${value}`;
  }
  // const mark = [

  // ]
  const mark = [
    {
      value: -5000,
      label: `5k `,
    },
    {
      value: -10000,
      label: `10k `,
    },
    {
      value: -20000,
      label: `20k `,
    },
  ];


  const history = useHistory();

  return (
    <div className="home-wrapper " style={{ fontFamily: "Mulish" }}>
      <HeaderDashboard />
      <div
        className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs"
        style={{ minHeight: "100vh" }}
      >
        <Box
          sx={{
            margin: "0 auto",
            width: {
              xs: "100%",
              sm: "90%",
              lg: "80%",
            },
          }}
        >
          {/* heading starts */}
          <Box sx={{ display: "flex" }}>
            <Box sx={{ color: "#ffffff", justifyContent: "flex-start" }}>
              <KeyboardBackspaceIcon
                onClick={()=> history.goBack()}
                sx={{ color: "white", fontSize: "2rem" ,cursor:"pointer" }}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#ffffff",
                  fontWeight: "900",
                  fontSize: "24px",
                }}
              >
                {communityRewardData.title}
              </Typography>
            </Box>
          </Box>
          {/* heading ends */}

          {/* description starts */}
          <Box
            sx={{
              margin: "15px 0",
              width: {
                xs: "100%",
                sm: "100%",
                lg: "51.3%",
              },
              margin: "0 auto",
            }}
          >
            <Typography sx={{ color: "#ffffff", margin: "15px 0 0 0" }}>
              We present gifs for points that you collected! You can see the
              number of your points on the header in the right side. When number
              reaches next level you receive gifts from us!
              <span style={{ color: "var(--orange)", fontWeight: "600" }}>
                So it’s in your hands! Earn more points in games and receive
                more gifts!
              </span>
            </Typography>
          </Box>
          {/* description ends */}

          {/* points timeline starts */}
          <VTimeline />
        </Box>
        {/*points timeline ends   */}
        {/* </Box> */}

        <Footer logo={logo} />
      </div>
    </div>
  );
};
export default withRouter(CommunityReward);
