import React from "react";
import Footer from "../layout/Footer/Footer";
import { HeaderDashboard } from "../layout/HeaderDashboard";
import logo from "../../assets/images/logo.png";
import { Container } from "react-bootstrap";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import BlockIcon from "@mui/icons-material/Block";
import { Box, Grid, Rating, Stack, Typography } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import SchoolIcon from "@mui/icons-material/School";
import PersonIcon from "@mui/icons-material/Person";
import WorkIcon from "@mui/icons-material/Work";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { StyledYellowButton } from "../utils/Buttons";
import { useHistory } from "react-router";
export const TutorDetails = () => {
  const history = useHistory();
  const tutorDetails = {
    name: "Rajesh Koothrappali",
    gender: "Male",
    age: "36",
    location: "India, Mumbai",
    about:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    subjects: ["Biology", "History", "English"],
    ageOfStudents: "10-16 y.o.",
    experience: 7,
    teachingHours: 74,
    noOfReviews: 80,
    overallRating: 4,
    reviews: [
      {
        name: "Asher Talbot",
        date: Date.now(),
        reviewMessage:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
        rating: 4,
        individualRating: {
          teachingSkills: 3,
          communication: 4,
          programQuality: 2,
        },
      },
      {
        name: "Asher Talbot",
        date: Date.now(),
        reviewMessage:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
        rating: 4,
        individualRating: {
          teachingSkills: 3,
          communication: 4,
          programQuality: 2,
        },
      },
    ],
  };

  const ReviewCard = ({ review }) => {
    return (
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "20px",
          width: "100%",
          p: "2rem",
          mb: "1rem",
        }}
      >
        <Grid justifyContent={"space-between"} container>
          <Grid item sm={7}>
            <Stack
              mb="1rem"
              direction="row"
              alignItems={"end"}
              columnGap={"2rem"}
            >
              <Typography
                sx={{
                  color: "var(--purple-dark)",
                  fontWeight: "700",
                  fontSize: "1.3rem",
                }}
              >
                {review.name}
              </Typography>
              <Typography sx={{ color: "var(--orange)" }}>
                14 Sep, 2021
              </Typography>
            </Stack>
            <Typography sx={{ lineHeight: "20px", color: "black" }}>
              {review.reviewMessage}
            </Typography>
          </Grid>
          <Grid item sm={3}>
            <Stack
              mb="1rem"
              alignItems="center"
              direction={"row"}
              columnGap="0.5rem"
            >
              <Typography
                sx={{
                  fontSize: "0.9rem",
                  color: "var(--purple-dark)",
                  fontWeight: "800",
                  borderRadius: "6px",
                }}
              >
                {Number(review.rating).toFixed(1)}
              </Typography>
              <Rating readOnly value={review.rating} />
            </Stack>
            <Stack rowGap={"0.7rem"}>
              <Stack direction="row" justifyContent={"space-between"}>
                <Typography sx={{ fontWeight: "700" }}>
                  Teaching skills
                </Typography>
                <Rating
                  readOnly
                  value={review.individualRating.teachingSkills}
                />
              </Stack>
              <Stack direction="row" justifyContent={"space-between"}>
                <Typography sx={{ fontWeight: "700" }}>
                  communication{" "}
                </Typography>
                <Rating
                  readOnly
                  value={review.individualRating.communication}
                />
              </Stack>
              <Stack direction="row" justifyContent={"space-between"}>
                <Typography sx={{ fontWeight: "700" }}>
                  Program quality
                </Typography>
                <Rating
                  readOnly
                  value={review.individualRating.programQuality}
                />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const PlainButton = ({ sx, children, onClick }) => (
    <Box
      sx={{
        fontSize: "0.9rem",
        cursor: "pointer",
        textAlign: "center",
        fontWeight: "700",
        color: "var(--purple-dark)",
        backgroundColor: "var(--yellow)",
        p: "0.3rem 0.5rem",
        borderRadius: "10px",
        width: "100%",
        ...sx,
      }}
      onClick={onClick}
    >
      {children}
    </Box>
  );
  return (
    <div className="home-wrapper">
      <HeaderDashboard />
      <div
        style={{ minHeight: "100vh" }}
        className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs"
      >
        <Container style={{ textAlign: "start" }}>
          <Stack mb="1rem" direction={"row"} alignItems="center">
            <KeyboardBackspaceIcon
              onClick={() => history.push("/student/myConnections")}
              sx={{
                color: "var(--orange)",
                fontSize: "2rem",
                cursor: "pointer",
              }}
            />
            <Typography
              ml="20rem"
              sx={{ color: "white", fontWeight: "700", fontSize: "2rem" }}
            >
              {tutorDetails.name}
            </Typography>
            <Typography
              sx={{
                ml: "1rem",
                height: "1.7rem",
                width: "5rem",
                textAlign: "center",
                color: "white",
                backgroundColor: "var(--orange)",
                padding: "0.1rem",
                borderRadius: "6px",
                fontWeight: "600",
              }}
            >
              Coach
            </Typography>
          </Stack>

          <Box
            sx={{
              position: "relative",
              backgroundColor: "white",
              borderRadius: "1.2rem",
              p: "1rem",
            }}
          >
            <img
              style={{
                position: "absolute",
                top: "-25%",
                left: "80px",
              }}
              src="/images/profilepic.png"
              alt="profile"
            />
            <Grid container>
              <Grid item sm={4}>
                <Box
                  sx={{
                    mt: "8rem",
                  }}
                >
                  <Grid
                    alignItems={"end"}
                    container
                    mx={"auto"}
                    width={"60%"}
                    rowSpacing={"1rem"}
                  >
                    <Grid item xs={12}>
                      <PlainButton
                        sx={{
                          background:
                            "radial-gradient(89.85% 73.57% at 84.21% 75.36%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0) 86.18%) , radial-gradient(63.69% 56.22% at 25.75% 17.94%, rgba(255, 255, 255, 0.413) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%) , #FFD300",
                        }}
                      >
                        Check tutor's schedule
                      </PlainButton>
                    </Grid>
                    <Grid item xs={3}>
                      <FavoriteBorderIcon
                        sx={{
                          mx: "auto",
                          color: "white",
                          background:
                            "radial-gradient(89.85% 73.57% at 84.21% 75.36%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0) 86.18%),radial-gradient(63.69% 56.22% at 25.75% 17.94%, rgba(255, 255, 255, 0.413) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%),#632CAF",
                          p: "0.3rem",
                          borderRadius: "6px",
                          fontSize: "2rem",
                        }}
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <PlainButton
                        sx={{
                          // backgroundColor: "var(--purple)",
                          background:
                            "radial-gradient(89.85% 73.57% at 84.21% 75.36%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0) 86.18%),radial-gradient(63.69% 56.22% at 25.75% 17.94%, rgba(255, 255, 255, 0.413) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%),#632CAF",
                          color: "white",
                        }}
                      >
                        {" "}
                        Check tutor details
                      </PlainButton>
                    </Grid>
                    <Grid item xs={3}>
                      <BlockIcon
                        sx={{
                          color: "white",
                          background:
                            "radial-gradient(89.85% 73.57% at 84.21% 75.36%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0) 86.18%), radial-gradient(63.69% 56.22% at 25.75% 17.94%, rgba(255, 255, 255, 0.413) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%),#FF7A00",
                          mx: "auto",

                          p: "0.3rem",
                          borderRadius: "6px",
                          fontSize: "2rem",
                        }}
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <PlainButton
                        sx={{
                          background:
                            "radial-gradient(89.85% 73.57% at 84.21% 75.36%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0) 86.18%), radial-gradient(63.69% 56.22% at 25.75% 17.94%, rgba(255, 255, 255, 0.413) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%),#FF7A00",
                          color: "white",
                        }}
                      >
                        Report
                      </PlainButton>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item sm={8}>
                <Grid container>
                  <Grid item xs={3}>
                    <Stack direction={"row"} columnGap="0.5rem">
                      <SchoolIcon sx={{ color: "var(--orange)" }} />{" "}
                      <Typography
                        sx={{
                          color: "black",
                          fontWeight: "800",
                          fontSize: "1.1rem",
                        }}
                      >
                        Advanced
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={3}>
                    <Stack direction={"row"} columnGap="0.5rem">
                      <LocationOnIcon sx={{ color: "var(--orange)" }} />
                      <Typography
                        sx={{
                          color: "black",
                          fontWeight: "800",
                          fontSize: "1.1rem",
                        }}
                      >
                        {tutorDetails.location}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={3}>
                    <Stack direction={"row"} columnGap="0.5rem">
                      <PersonIcon sx={{ color: "var(--orange)" }} />
                      <Typography
                        sx={{
                          color: "black",
                          fontWeight: "800",
                          fontSize: "1.1rem",
                        }}
                      >
                        {`${tutorDetails.gender}, ${tutorDetails.age} y.o.`}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>

                <Grid container mt="2rem">
                  <Grid item xs={6}>
                    <Stack columnGap={"1rem"} flexDirection="row">
                      <WorkIcon
                        sx={{
                          color: "var(--purple)",
                          fontSize: "2.8rem",
                          width: "30px",
                        }}
                      />
                      <Typography>teaching hours on Neithedu</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: "700" }}>
                      Age of students
                    </Typography>
                    <Typography>{tutorDetails.experience}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack columnGap={"1rem"} flexDirection={"row"}>
                      <img
                        alt="book"
                        src="/images/icons/book.png"
                        width={"30px"}
                      />
                      <Typography>years of teaching experience</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: "700" }}>
                      Subjects:
                    </Typography>
                    <Typography>
                      {tutorDetails.subjects.reduce(
                        (init, val, idx, arr) =>
                          arr.length - 1 !== idx
                            ? init + val + ", "
                            : init + val,
                        "",
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography
                  sx={{
                    mt: "2rem",
                    fontWeight: "700",
                    color: "var(--purple)",
                    fontSize: "1.2rem",
                  }}
                >
                  About me
                </Typography>
                <Typography mt="1rem">{tutorDetails.about}</Typography>
              </Grid>
            </Grid>
          </Box>

          <Stack
            direction={"row"}
            mt="2rem"
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Stack direction={"row"} columnGap="1rem" alignItems={"center"}>
              <Typography
                sx={{
                  color: "white",
                  fontWeight: "700",
                  fontSize: "1.3rem",
                }}
              >
                {tutorDetails.noOfReviews + " reviews"}
              </Typography>
              <Typography
                sx={{
                  height: "1.9rem",
                  width: "2.3rem",
                  p: "0.3rem 0.4rem",
                  fontSize: "0.9rem",
                  color: "var(--purple-dark)",
                  fontWeight: "800",
                  backgroundColor: "var(--yellow)",
                  borderRadius: "6px",
                }}
              >
                {"4.0"}
              </Typography>
              <Rating readOnly value={4} />
            </Stack>
            <Box>
              <select
                style={{
                  backgroundColor: "rgba(255,255,255,0.1)",
                  borderRadius: "10px",
                  color: "white",
                  padding: "0.5rem",
                  border: "none",
                }}
              >
                <option value="1">Sort by date (from the newest)</option>
                <option value="2">By raiting (from highest)</option>
                <option value="3">By level (from Newbie to Master)</option>
                <option value="4">By level (from Master to Newbie)</option>
              </select>
            </Box>
          </Stack>
          <Box mt={"2rem"}>
            {tutorDetails.reviews.map((review, idx) => {
              return <ReviewCard key={idx} review={review} />;
            })}
          </Box>

          <Stack direction="row" justifyContent={"center"}>
            <Box width={"10rem"} mt="1rem">
              <StyledYellowButton title={"More reviews"} />
            </Box>
          </Stack>
        </Container>
        <Footer logo={logo} />
      </div>
    </div>
  );
};
