import React, { useContext, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./loginModal/login.scss";
import { searchEducatorStudentsNameEmail } from "../service/apis/educator";
import { CustomSnackbar, useSnackState } from "../utils/useSnackState";
import { addManyChildren } from "../service/apis/parent";
import { NotificationContext } from "../contexts/Notification";

const Addmychild = ({ open, setOpen }) => {
  const [students, setstudents] = useState([]);
  const [snackProps, snackOpen] = useSnackState();
  const [studentEmail, setstudentEmail] = useState("");
  const [selectedStudents, setselectedStudents] = useState([]);

  const { showNotification } = useContext(NotificationContext);

  const searchStudentHandler = async () => {
    try {
      const res = await searchEducatorStudentsNameEmail(studentEmail);
      console.log("students", res);
      if (res.length === 0) {
        snackOpen("No student found", "error");
      } else {
        setstudents(res);
      }
    } catch (err) {
      snackOpen("Something went wrong", "error");
    }
  };

  const selectStudent = (student) => {
    const index = selectedStudents.findIndex(
      (stud) => stud.studentId === student.studentId,
    );
    if (index === -1) {
      setselectedStudents((state) => [...state, student]);
    } else
      setselectedStudents((state) =>
        state.filter((stud) => stud.studentId !== student.studentId),
      );
  };

  const addToMyChildrenHandler = async () => {
    console.log("selectedStudents", selectedStudents);
    try {
      let selectedId = selectedStudents[0].studentId;
      const res = await addManyChildren(selectedId);
      console.log("addedstudents", res);
      // TODO: refetch students list
      showNotification("Child added successfully");
      setTimeout(() => {
        setOpen(false);
      }, 2000);
    } catch (err) {
      snackOpen("Something went wrong", "error");
    }
  };

  const btnClickHanlder = () => {
    if (selectedStudents.length > 0) {
      addToMyChildrenHandler();
    } else {
      searchStudentHandler();
    }
  };

  return (
    <div className="add-pupil-mainbx">
      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-pupil-modal2 mychild-frm"
        show={open}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Add my child</h4>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setOpen(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <CustomSnackbar {...snackProps} />
            <div className="login-modl-form">
              <div className="loginform-bx">
                <div className="pupiladd-filednw">
                  <Form.Group className="subsform-fild">
                    <Form.Label>Email of child in Neithedu</Form.Label>
                    <div className="livein-field">
                      <Form.Control
                        type="email"
                        placeholder="child_email@example.com"
                        value={studentEmail}
                        onChange={(e) => {
                          setstudents([]);
                          setselectedStudents([]);
                          setstudentEmail(e.target.value);
                        }}
                      ></Form.Control>
                    </div>
                  </Form.Group>
                </div>

                {students.length > 0 && (
                  <div className="newpupil-list-bx">
                    <Form.Group className="subsform-fild child-tex-fld height-max-flow">
                      <Form.Label>My childen are:</Form.Label>
                      <div className="child-boxnew">
                        {students.map((student, index) => {
                          const isSelected =
                            selectedStudents.findIndex(
                              (stud) => stud.studentId === student.studentId,
                            ) !== -1;

                          return (
                            <div className="child-account" key={index}>
                              <div className="activ-detail-list">
                                <label className="custm-check">
                                  <Form.Control
                                    type="checkbox"
                                    checked={isSelected}
                                    onChange={(e) => selectStudent(student)}
                                  />
                                  <span className="checkmark"></span>
                                  <div className="active-detail-row">
                                    <div className="activiti-prof-icon">
                                      <span className="profile-icon-box">
                                        <i className="fas fa-user"></i>
                                      </span>
                                    </div>
                                    <div className="activiti-center-detail">
                                      <h5>{student.name}</h5>
                                      <h6>{student.location}</h6>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </Form.Group>
                  </div>
                )}

                <div className="btnlogin-main">
                  <button
                    type="submit"
                    onClick={btnClickHanlder}
                    className="btnlogin btnweak-bx"
                    disabled={studentEmail.length < 3}
                  >
                    {selectedStudents?.length > 0
                      ? "Add to my children list"
                      : "Search a student"}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default Addmychild;
