import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { StyledYellowButton } from "../utils/Buttons";
import { DialogBox } from "../utils/DialogBox";
import CloseIcon from "@mui/icons-material/Close";

export const HaveTutors = ({ onClose }) => {
  return (
    <DialogBox isOpen={true} close={onClose}>
      <Box position={"relative"}>
        <CloseIcon
          onClick={onClose}
          sx={{
            cursor: "pointer",
            color: "var(--purple-dark)",
            top: "-5px",
            right: "-5px",
            position: "absolute",
          }}
        />
        <Typography
          textAlign={"center"}
          sx={{
            color: "var(--purple-dark)",
            fontSize: "1.3rem",
            fontWeight: "700",
          }}
        >
          Great news! we have tutors
        </Typography>
        <Typography
          textAlign={"center"}
          sx={{
            mb: "1rem",
            color: "var(--purple-dark)",
            fontSize: "1.3rem",
            fontWeight: "700",
          }}
        >
          of this subject for your age!
        </Typography>
        <Typography
          mb="1rem"
          fontWeight={"500"}
          textAlign={"center"}
          fontSize="0.9rem"
        >
          You have only one trial lesson, so explore tutors and choose one. You
          can check their profiles, chat with all of them, ask questions. When
          you are ready, just text a tutor about it
        </Typography>
        <Box mr="1rem">
          <Grid
            rowSpacing={"1rem"}
            alignItems="center"
            columnSpacing="1rem"
            container
          >
            {Array.from(Array(3)).map((_, idx) => {
              return (
                <>
                  <Grid item xs={2}>
                    <img
                      width={"100%"}
                      src={"/images/profilepic.png"}
                      alt="profile pic"
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Stack>
                      <Typography
                        sx={{
                          color: "var(--purple-dark)",
                          fontWeight: "700",
                          fontSize: "1rem",
                        }}
                      >
                        Lion Amari
                      </Typography>
                      <Typography
                        sx={{ color: "var(--grey-light)" }}
                        fontSize={"0.9rem"}
                      >
                        India, Mumbai
                      </Typography>
                      <Typography
                        sx={{ color: "var(--grey-light)" }}
                        fontSize={"0.9rem"}
                      >
                        8 years of teaching
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={5}>
                    <Box width="100%">
                      <StyledYellowButton
                        sx={{ fontSize: "0.8rem", p: "0.4rem" }}
                        btnHandler={() => {}}
                        title="Go to chat with a tutor"
                      />
                    </Box>
                  </Grid>
                </>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </DialogBox>
  );
};
