export const questions = [
  [
    "Some seemingly insignificant statement",
    "Oh, this is really doesn’t appear for me!",
    "The sentence is too lorem ipsum",
    "The sentence is too lorem ipsum",
  ],
  [
    "Some seemingly insignificant statement",
    "Oh, this is really doesn’t appear for me!",
    "The sentence is too lorem ipsum",
    "The sentence is too lorem ipsum",
  ],
  [
    "Some seemingly insignificant statement",
    "Oh, this is really doesn’t appear for me!",
    "The sentence is too lorem ipsum",
    "The sentence is too lorem ipsum",
  ],
  [
    "Some seemingly insignificant statement",
    "Oh, this is really doesn’t appear for me!",
    "The sentence is too lorem ipsum",
    "The sentence is too lorem ipsum",
  ],
  [
    "Some seemingly insignificant statement",
    "Oh, this is really doesn’t appear for me!",
    "The sentence is too lorem ipsum",
    "The sentence is too lorem ipsum",
  ],
  [
    "Some seemingly insignificant statement",
    "Oh, this is really doesn’t appear for me!",
    "The sentence is too lorem ipsum",
    "The sentence is too lorem ipsum",
  ],
  [
    "Some seemingly insignificant statement",
    "Oh, this is really doesn’t appear for me!",
    "The sentence is too lorem ipsum",
    "The sentence is too lorem ipsum",
  ],
  [
    "Some seemingly insignificant statement",
    "Oh, this is really doesn’t appear for me!",
    "The sentence is too lorem ipsum",
    "The sentence is too lorem ipsum",
  ],
];
