import React from "react";

const ChooseActivityBar = ({ onSelection, stugrp }) => {
  return (
    <div onClick={onSelection} className="tabslist-activiti-tab tabtwo">
      <div className="activ-detail-list">
        <div className="active-detail-row">
          <div className="activiti-prof-icon">
            <span className="profile-icon-box">
              {stugrp?.isGroup ? (
                <i className="fa fa-users"></i>
              ) : (
                <i className="fa fa-user"></i>
              )}
            </span>
          </div>
          <div className="activiti-center-detail">
            <h5>{stugrp.name}</h5>
            <h6>{stugrp.numberOfActivities} activities</h6>
          </div>
          <div className="activiti-count-mesg">
            <span className="count-num-activiti">
              {stugrp.numberOfActivities}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseActivityBar;
