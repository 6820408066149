import React, { useEffect, useState } from "react";
import { HeaderDashboard } from "../layout/HeaderDashboard";
import { getUserCards } from "../../service/apis/reward";
import { Grid } from "@mui/material";
import { Badge, Card } from "react-bootstrap";

const StudentBadge = () => {
  const [badgesData, setBadgesData] = useState([]);
  useEffect(() => {
    getUserCards().then((res) => {
      console.log("res", res);
      setBadgesData(res);
    });
  }, []);

  // filter the badgesData array to get the data with isUnlocked true

  const badgesDataToMap = badgesData.filter((item) => item.isUnlocked === true);
  return (
    <div className="home-wrapper">
      <div style={{ display: "flex", flexWrap: "wrap", gap: "40px" }}>
        {badgesDataToMap.map((item, idx) => (
          <div
            style={{
              width: "200px",
              height: "200px",
              backgroundColor: "grey",
              backgroundImage: `url(${item.imgUrl})`,
            }}
          >
            <p>{item.title}</p>
            <img
              src={item.imgUrl}
              style={{ width: "100px", height: "100px" }}
              alt=""
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default StudentBadge;
