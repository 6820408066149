import { Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export const CreateNewGameDialog = ({ closeHandler }) => {
  return (
    <Modal
      className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
      // {...props}
      show={true}
      onHide={() => {
        closeHandler();
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="login-modal-mainbx groupmbr-step2 mystudent-games">
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>New game</h4>
          </Modal.Title>
          <button type="button" onClick={closeHandler} className="close">
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="login-modl-form">
            <div className="loginform-bx">
              <Form.Group className="subsform-fild">
                <div className="newgames-createbx">
                  <Row>
                    <div className="col-md-12">
                      <Form.Label>Title of the game</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Help your mother"
                      ></Form.Control>
                    </div>
                    <div className="col-md-12">
                      <Form.Label>Type of the game</Form.Label>
                      <Form.Control as="select">
                        <option value="2">Quiz</option>
                      </Form.Control>
                    </div>
                    <div className="col-md-12">
                      <Form.Label>Subject</Form.Label>
                      <Form.Control as="select">
                        <option value="2">Biology</option>
                      </Form.Control>
                    </div>
                    <div className="col-md-12">
                      <Form.Label>For children</Form.Label>
                      <Form.Control as="select">
                        <option value="2">7th year of study</option>
                      </Form.Control>
                    </div>
                    <div className="col-md-12">
                      <Form.Label>
                        How many points students can earn for the game
                      </Form.Label>
                    </div>
                    <div className="col-md-4 std-point-frm">
                      <Form.Control type="text" placeholder="10" />
                    </div>
                    <div className="col-md-4 std-point-frm std-point-label">
                      <Form.Label>points for</Form.Label>
                    </div>
                    <div className="col-md-4 std-point-frm">
                      <Form.Control as="select">
                        <option value="2">1 question</option>
                      </Form.Control>
                    </div>
                    <div className="col-md-12">
                      <Form.Label>Description (optional)</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Add some text that only you will see with any notes, descriptions etc"
                      />
                    </div>
                  </Row>
                </div>
              </Form.Group>

              <div className="btnlogin-main">
                <Link to="Questionone" className="btnlogin btnweak-bx">
                  Save and go to questions
                </Link>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};
