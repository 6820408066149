import React from "react";
import "./home.scss";
import "./profile.scss";
import "./media.scss";
import "./games.scss";
import "./loginModal/login.scss";
import "./messages/messages.scss";
import "./myactivities.scss";
import "./gamestabs.scss";
import "./gamesflowphotos.scss";
import "./selectedgames.scss";
import "./questionone.scss";
import "./creatingmaterial.scss";
import "./Currentlesson.scss";
import { Container, Button, Form, Modal } from "react-bootstrap";

import logo from "../assets/images/logo.png";
// import gamesgalery1 from "../assets/images/games-galery1.jpg";
// import gamesgalery2 from "../assets/images/games-galery2.jpg";
// import chaticon from "../assets/images/chat-icon.svg";
// import batchorange from "../assets/images/batch-orange.svg";
// import palinblue from "../assets/images/palin-blue.svg";
// import mike from "../assets/images/mike.svg";
// import attachemtnblue from "../assets/images/attachemtn-blue.svg";
// import downarrowblue from "../assets/images/down-arrow-blue.svg";
// import dotthree from "../assets/images/dotthree.svg";
// import addbtnround from "../assets/images/add-btn.svg";
// import darkblueplan from "../assets/images/darkblue-plan.svg";
import backorange from "../assets/images/backorange.svg";
// import editorangelg from "../assets/images/editlg-orange.svg";
// import placeholder from "../assets/images/placeholder.jpg";
// import lessonpic1 from "../assets/images/lesson-pic1.jpg";
// import lessonpic2 from "../assets/images/lesson-pic2.jpg";
// import lessonpic3 from "../assets/images/lesson-pic3.jpg";
// import lessonpic4 from "../assets/images/lesson-pic4.jpg";
// import lessonpic5 from "../assets/images/lesson-pic5.jpg";
// import attachment from "../assets/images/attachement.svg";
// import plain from "../assets/images/plain.svg";
// import playvd from "../assets/images/play-vd.png";

// import Slider from "react-slick";
// import SliderCenter from "./Slider";
import { Link } from "react-router-dom";
import FooterEducator from "./layout/Footer/FooterEducator";
// import Addgroup from "./Addgroup";
// import Addpupil from "./Addpupil";
// import SendInvite from "./SendInvite";
// import Groupmember from "./Groupmember";
import HeaderGames from "./layout/Header/HeaderGames";

function Newlivelessionthree() {
  // const [show, setShow] = React.useState("1");

  // const [breadMenuShow, setBreadMenuShow] = React.useState(false);
  /* start modal */
  const [modalShow, setModalShow] = React.useState(false);
  /* end modal */

  /* startsave gamed step1 */
  const [modalgamesavedstep1Show, setgamesavedstep1ModalShow] =
    React.useState(false);

  /* end save gamned step1 */

  /* start create a new games*/
  const [modalgamecreatestep1Show, setgamecreatestep1ModalShow] =
    React.useState(false);

  /* end create a new games */

  /* start session create*/
  const [modalsessioncreatestep1Show, setsessioncreatestep1ModalShow] =
    React.useState(false);

  /* end session create */

  /* start session cancel*/
  const [modalsessioncreatestep2Show, setsessioncreatestep2ModalShow] =
    React.useState(false);

  /* end session cancel */

  /* start edit lession*/
  const [modalsessioncreatestep3Show, setsessioncreatestep3ModalShow] =
    React.useState(false);

  /* end edit lession */

  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderGames setModalShow={(value) => setModalShow(value)} />
      {/* end header */}

      <div className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs">
        <div className="cureent-lession-bx completelession-main">
          <div className="current-lesson-main ">
            <Container>
              <div className="current-lesson-contain">
                <div className="backbtn">
                  <Link to="Newlivelession" className="backbtnn-icnn">
                    <img src={backorange} />
                  </Link>
                </div>
                <div className="current-lesson-topbr">
                  <div className="medm-tex orng-clr">Live lesson</div>
                  <h1>
                    Work on mistakes of the test on the topic "Law and legal
                    aspects of business activities" from the work book, the
                    second part
                  </h1>
                  <div className="lesson-status-list">
                    <ul className="lesson-listing-curnt">
                      <li>
                        <div className="lesson-status-detail">
                          <span className="medm-tex orng-clr onelist">
                            Time:{" "}
                          </span>
                          <span className="medm-tex orng-clr twolist">
                            Oct 25, 11:00 am
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="lesson-status-detail">
                          <span className="medm-tex orng-clr onelist">
                            Status:{" "}
                          </span>
                          <span className="medm-tex orng-clr twolist">
                            Active
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="lesson-status-detail">
                          <span className="medm-tex orng-clr onelist">
                            Rate:{" "}
                          </span>
                          <span className="medm-tex orng-clr twolist">$15</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Container>
          </div>
          <div className="lession-time-card">
            <Container>
              <div className="lession-card-timebx">
                <div className="lessontime-bottom">
                  <div className="lessonnew-cardbx">
                    <div className="newlesson-top">
                      <div className="bnewleson-heading">
                        Time to the lesson
                      </div>
                    </div>
                    <div className="newlesson-clock">
                      <ul className="newlesson-listclk">
                        <li>
                          <div className="clock-time">
                            <div className="timenum">0</div>
                            <div className="time-days">days</div>
                          </div>
                        </li>
                        <li>
                          <div className="clock-time">
                            <div className="timenum">0</div>
                            <div className="time-days">hours</div>
                          </div>
                        </li>
                        <li>
                          <div className="clock-time">
                            <div className="timenum">4</div>
                            <div className="time-days">minutes</div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="btn-list-lesson">
                      <ul className="btn-listing-lesson">
                        <li>
                          <div className="btn-buy">
                            <div className="btn-week">
                              <a
                                href="Completedlessontwo"
                                className="btnweak-bx"
                              >
                                <span className="btn-title">
                                  Start the lesson
                                </span>
                              </a>
                            </div>
                          </div>
                        </li>
                        <li className="whitebtn-tx">
                          <div className="btn-buy">
                            <div className="btn-week">
                              <a
                                href="#"
                                className="btnweak-bx"
                                onClick={() =>
                                  setgamecreatestep1ModalShow(true)
                                }
                              >
                                <span className="btn-title">
                                  Edit the lesson
                                </span>
                              </a>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="newlesson-shape"></div>
                  </div>
                </div>
              </div>
            </Container>
          </div>

          <div className="pagination-lession">
            <Container>
              <div className="pagination-lession-inner">
                <ul>
                  <li className="one">
                    <Link to="#" className="pagination-btn">
                      <span className="iconn">
                        <i className="fas fa-angle-left"></i>
                      </span>
                      <span className="tex-lesn">Previous lesson</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </Container>
          </div>
        </div>

        {/* footer start */}
        <FooterEducator logo={logo} />
        {/* footer end */}
      </div>

      {/* start modal my student */}

      {/* start game save step1 */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        // {...props}
        show={modalgamesavedstep1Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2 mystudent-games">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>The game is saved</h4>
              <h5>Send the game to my students</h5>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setgamesavedstep1ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="serch-inner-group">
                    <div className="serchfiled-activities">
                      <Form.Control
                        type="text"
                        placeholder="Start typing name or year of study"
                        className="formbox"
                      />
                      <Button type="button" className="seachbtn">
                        <i className="fas fa-search"></i>
                      </Button>
                    </div>
                  </div>

                  <div className="newpupil-list-bx">
                    <Form.Group className="subsform-fild child-tex-fld height-max-flow">
                      <div className="child-boxnew">
                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Aaradhya Rayasam</h5>
                                  <h6>India, Mumbai 1 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Chandrashekhar Kalvakuntla</h5>
                                  <h6>India, Mumbai 3 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>7F Class</h5>
                                  <h6>Hamut Kapit, Kemal Mara and 12 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Additional group</h5>
                                  <h6>Laska Doe, Hamut Kapit and 4 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>8A Class for exams</h5>
                                  <h6>Hamut Kapit, Laska Doe and 8 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Chandrashekhar Kalvakuntla</h5>
                                  <h6>India, Mumbai 3 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </Form.Group>

                <div className="student-btn-savedgame">
                  <div className="btnlogin-main">
                    <button type="submit" className="btnlogin btnweak-bx">
                      Send to selected students
                    </button>
                  </div>
                </div>
                <div className="btnlogin-main">
                  <button type="submit" className="btnlogin btnweak-bx">
                    Later
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end Add a Member Group step2 */}

      {/* end modal my student */}

      {/* start create a new games */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        // {...props}
        show={modalgamecreatestep1Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2 mystudent-games edit-livelessn">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Edit the live lesson</h4>
              <p>
                Student should confirm changes in the lesson before it will
                update
              </p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setgamecreatestep1ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="newgames-createbx">
                    <div className="frm-gameflw-nw">
                      <Form.Label>
                        Title of the lesson for useful searching someth...
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Type the topic or what you will do"
                      ></Form.Control>
                    </div>

                    <div className="frm-gameflw-nw">
                      <Form.Label>Date of the lesson</Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="2 Sep 2021"
                      ></Form.Control>
                    </div>
                    <div className="frm-gameflw-nw">
                      <Form.Label>Time of the lesson</Form.Label>
                      <Form.Control as="select">
                        <option value="2">10:00 am</option>
                      </Form.Control>
                    </div>
                  </div>
                </Form.Group>

                <div className="btnlogin-main">
                  <Link
                    to="#"
                    className="btnlogin btnweak-bx"
                    onClick={() => {
                      setsessioncreatestep1ModalShow(true);
                      setgamecreatestep1ModalShow(false);
                    }}
                  >
                    Edit the lesson
                  </Link>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end create a new games */}

      {/* start create a new games */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        // {...props}
        show={modalsessioncreatestep1Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2 mystudent-games session-created">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Live lesson was edited</h4>
              <p>
                Notice that the student should confirm changes in the lesson.
                Changes without confirmation will be cancelled in 3 days
              </p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setsessioncreatestep1ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <div className="btnlogin-main">
                  <Link to="LiveLessonDetail" className="btnlogin btnweak-bx">
                    Thanks, i understood
                  </Link>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end create a new games */}

      {/* start create a new games */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        // {...props}
        show={modalsessioncreatestep2Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2 mystudent-games session-created cancel-lession">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Are you sure that you want to cancel the live lesson?</h4>
              <p>
                Cancellation of the lesson affects to rating decrease and your
                membership on <span className="deco-tex">Community pot</span>
              </p>
              <p>
                We recommend to discuss possibility to change the date / time
                with the student .{" "}
              </p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setsessioncreatestep2ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <div className="btnlogin-main cancl-lession-btn">
                  <Link
                    to="#"
                    className="btnlogin btnweak-bx"
                    onClick={() => {
                      setsessioncreatestep3ModalShow(true);
                      setsessioncreatestep2ModalShow(false);
                    }}
                  >
                    Yes, i want to cancel the lesson
                  </Link>
                </div>

                <div className="btnlogin-main">
                  <Link
                    to="#"
                    className="btnlogin btnweak-bx"
                    onClick={() => setsessioncreatestep2ModalShow(false)}
                  >
                    No, go back
                  </Link>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end create a new games */}

      {/* start lession edit */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        // {...props}
        show={modalsessioncreatestep3Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2 mystudent-games session-created">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Live lesson was cancelled</h4>
              <p>
                Student will be notified about cancellation of the lesson. Your
                rating will be revised.
              </p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setsessioncreatestep3ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <div className="btnlogin-main">
                  <Link to="Newlivelession" className="btnlogin btnweak-bx">
                    I understood
                  </Link>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end lession edit */}
    </div>
  );
}

export default Newlivelessionthree;
