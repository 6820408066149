import { Box, Typography } from "@mui/material";
import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../layout/Footer/Footer";
import HeaderGames from "../layout/Header/HeaderGames";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
export const LevelsInfo = ({ history }) => {
  const Heading = ({ text }) => {
    return (
      <Typography
        sx={{
          my: "1rem",
          fontWeight: "700",
          color: "var(--purple)",
          fontSize: "1.5rem",
        }}
      >
        {text}
      </Typography>
    );
  };
  return (
    <div className="home-wrapper">
      <HeaderGames />

      <div
        style={{ minHeight: "100vh" }}
        className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs"
      >
        <Container style={{ textAlign: "start" }}>
          <Box
            sx={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ArrowBackIcon
              onClick={() => history.push("/educator/tutorLevel")}
              sx={{
                cursor: "pointer",
                position: "absolute",
                color: "var(--orange)",
                fontSize: "2.4rem",
                top: "0px",
                left: "0px",
              }}
            />
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: "700",
                fontSize: "1.5rem",
                color: "white",
              }}
            >
              Tutor levels in Neithedu
            </Typography>
          </Box>
          <Box
            sx={{
              marginTop: "2rem",
              background: "white",
              borderRadius: "20px",
              padding: "2rem",
              li: {
                listStyle: "disc",
              },
            }}
          >
            <Heading text={"What are tutor levels?"} />
            <Typography>
              Your tutor level is based on your performance. Superb service,
              high student satisfaction and more number of succesful lessons can
              earn you a high level on Neithedu, and help you get more lessons.
            </Typography>
            <Typography sx={{ fontWeight: "600" }}>
              Level is counted every month and take all statistics for last 3
              month
            </Typography>

            <Heading text={"Newbie level"} />
            <Typography>
              This is where everything starts. When your application to became a
              tutor is confirmed, you’re automatically ranked as a Newbie. We
              encourage you to do your best work and service to move up to the
              next level.
            </Typography>
            <Typography my={"1rem"}>
              If you add agancy in tutor form, you will go to Advanced level
              without Newbie{" "}
            </Typography>

            <Typography sx={{ fontWeight: "600" }}>
              What you have in this level:
            </Typography>

            <ul>
              <li>Not more than 5 lessons per a week</li>
              <li>Withdraw your earnings after 14 days after previous time</li>
              <li>Withdraw your earnings from $100</li>
            </ul>

            <Heading text={"Advanced level"} />
            <Typography mb="1rem">
              To reach Advanced level, you need to meet the following
              requirements:
            </Typography>

            <ul>
              <li>Be an active tutor for at least 30 days on the website</li>
              <li>Complete at least 15 live lessons for last 90 days</li>
              <li>Maintain a 4.5-star rating over 90 days</li>
              <li>
                90% lessons for last 90 day should be completed on time (not
                canceled, postponed and missed)
              </li>
            </ul>

            <Typography sx={{ fontWeight: "600" }}>
              What you’ll get in this level:
            </Typography>

            <ul style={{ listStyleType: "circle" }}>
              <li>No limit for number of lessons per a week</li>
              <li>Access to share of community pot</li>
              <li>
                Become a recommended tutor to students in automated suggestions
              </li>
              <li>Withdraw your earnings after 7 days after previous time</li>
              <li>Withdraw your earnings from $50</li>
            </ul>
            <Heading text="Master level" />
            <Typography>
              {" "}
              To reach Master level, you need to meet the following
              requirements:
            </Typography>
            <ul>
              <li>Be an active tutor for at least 180 days on the website</li>
              <li>Complete at least 30 live lessons for last 90 days</li>
              <li>Complete at least 200 live lessons for all time</li>
              <li>Maintain a 4.5-star rating over 90 days</li>
              <li>
                90% lessons for last 90 day should be completed on time (not
                canceled, postponed and missed)
              </li>
            </ul>
            <Typography sx={{ fontWeight: "600" }}>
              What you’ll get in this level:
            </Typography>

            <ul>
              <li>All benefits of lower levels</li>
              <li>
                Additional support from Neithedu via personal support coach
              </li>
              <li>Discount -7% for yearly subscription plan</li>
              <li>Withdraw your earnings after 3 days after previous time</li>
              <li>No limit of summ for withdrawing your earnings</li>
            </ul>
          </Box>
        </Container>
        <Footer />
      </div>
    </div>
  );
};
