// api/users/child-email

import { Axios } from "../Axios";

// GET functions
export const getChildProgress = async () => {
  const res = await Axios.get("/parents/parents-children-progress");
  return res.data;
};

// POST functions
export const addManyChildren = async (studentId) => {
  console.log("childrenArr", studentId);

  const res = await Axios.post("/parents/add-child-to-parent", [studentId]);
  console.log("res123", res.data);
  return res.data;
};

export const addEducatorGroup = async (groupName, selectedStudents) => {
  // console.log(groupName, selectedStudents)
  const res = await Axios.post("/educators/groups", {
    groupName,
    details: selectedStudents.map((student) => ({ studentId: student })),
  });
  return res.data;
};
