import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import "./home.scss";
import "./profile.scss";
import "./media.scss";
import "./games.scss";
import "./loginModal/login.scss";
import { Form, Modal, Row } from "react-bootstrap";
import { postDate } from "../service/AllApi";
import { validateEmail } from "../service/commanFunction";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { Axios } from "../service/Axios";
import axios from "axios";
import { backEnd } from "../env";
import { ConfirmationDialog } from "./registration/ConfirmationDialog";
import { CustomSnackbar, useSnackState } from "../utils/useSnackState";
import { disabledBtnProps } from "../utils/util";
import { sendOtp } from "../service/apis/registration";

const StudentSignUp = ({
  setModalSignupShow,
  modalStudentShow,
  setStudentModalShow,
  country,
  setModalShow,
}) => {
  const [snackProps, snackOpen] = useSnackState();
  const [modalStudentstep1Show, setStudentstep1ModalShow] = useState(false);
  const [name, setName] = useState({ first: "", last: "" });
  const [years, setYears] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [modalStudentstep2Show, setStudentstep2ModalShow] = useState(false);
  const [modalStudentstep3Show, setStudentstep3ModalShow] = useState(false);
  const [modalStudentstep4Show, setStudentstep4ModalShow] = useState(false);
  const [modalStudentstep5Show, setStudentstep5ModalShow] = useState(false);
  const [modalStudentstep6Show, setStudentstep6ModalShow] = useState(false);
  const [modalStudentstep7Show, setStudentstep7ModalShow] = useState(false);
  const [viewPassword, setviewPassword] = useState({
    create: "password",
    repeat: "password",
  });
  const [otp, setOTP] = useState(null);
  const [values, setValues] = useState({
    userId: null,
    fullName: "",
    userName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
    countryId: 101,
    cityId: 0,
    stateId: null,
    yearsOfStudy: "",
  });
  const [errors, setErrors] = useState({
    fullName: false,
    userName: false,
    email: false,
    password: false,
    confirmPassword: false,
    phoneNumber: false,
    stateId: false,
    cityId: false,
    yearsOfStudy: false,
  });
  const [otpError, setOtpError] = useState(false);
  const [phoneVerify, setPhoneVerify] = useState(false);
  const [states, setstates] = useState([]);
  const [cities, setcities] = useState([]);
  const fetchStates = async () => {
    try {
      const res = await Axios.get(`states/${values.countryId}`);
      setstates(res.data);
    } catch (err) {}
  };
  useEffect(() => {
    const year = new Date("12/12/2000").getFullYear();
    const yea = Array.from(new Array(25), (val, index) => index + year);
    setYears(yea);
    fetchStates();
  }, []);

  const updateOtp = useCallback((e) => {
    try {
      setOtpError(false);
      if (
        !e.target.value ||
        e.target.value === "" ||
        e.target.value === undefined
      ) {
        setOtpError(true);
      }
      setOTP(e.target.value);
    } catch (err) {
      //console.log("error in updateOtp:", err.message || err);
    }
  });
  const verify = async () => {
    try {
      if (otpError) {
        return;
      }
      if (!otp) {
        setOtpError(true);
      }
      const payload = {
        userId: values.userId,
        otp: String(otp),
      };
      const response = await postDate("auth/verify-otp", payload);
      setDisabled(false);
      setPhoneVerify(true);
      setOtpError(false);

      setStudentstep6ModalShow(false);
      setStudentstep7ModalShow(true);
    } catch (err) {
      setOtpError(true);
      setPhoneVerify(false);

      //console.log("error :", err.message || err);
    }
  };

  const handleSignUp = async () => {
    try {
      if (!values.password || values.password === "") {
        setErrors({
          ...errors,
          password: true,
        });
        return;
      }
      if (!values.confirmPassword || values.confirmPassword === "") {
        setErrors({
          ...errors,
          confirmPassword: true,
        });
        return;
      }
      if (values.confirmPassword !== values.password) {
        setErrors({
          ...errors,
          password: true,
          confirmPassword: true,
        });
        return;
      }

      const payload = {
        firstName: name.first,
        lastName: name.last,
        // userName: values.email,
        email: values.email,
        password: values.password,
        confirmPassword: values.confirmPassword,
        phoneNumber: values.phoneNumber,
        genderId: 1,
        countryId: parseInt(values.countryId),
        cityId: parseInt(values.cityId),
        yearsOfBirth: parseInt(values.yearsOfStudy),
        // yearsOfStudy: parseInt(values.yearsOfStudy),
        gradeId: parseInt(values.yearsOfStudy),
        // gradeLevel: 10,
        grade: parseInt(values.yearsOfStudy),
        syllabusId: 0,
        inviteToken: localStorage.getItem("referral")
          ? JSON.parse(localStorage.getItem("referral")).referId
          : null,
      };
      // console.log(payload, "payload");
      const response = await postDate("register/student", payload);
      setValues((state) => ({ ...state, userId: response.data.userId }));
      setPhoneVerify(true);

      await sendOtp(values.phoneNumber, response.data.userId);
      setStudentstep6ModalShow(false);
      changeModel(6);
    } catch (err) {
      // console.log(err);
      if (err?.response?.data?.errors)
        snackOpen(err?.response?.data?.errors, "error");
    }
  };

  const updateValues = useCallback(async (e, name) => {
    try {
      const errorObject = {};
      if (name === "confirmPassword" || name === "password") {
        errorObject["password"] = false;
        errorObject["confirmPassword"] = false;
      } else {
        errorObject[name] = false;
      }
      if (
        !e.target.value ||
        e.target.value === "" ||
        e.target.value === undefined
      ) {
        errorObject[name] = true;
      }
      const updateObject = {};
      if (name === "stateId" || name === "cityId") {
        updateObject[name] = parseInt(e.target.value);
        if (name === "stateId") {
          const res = await Axios.get(
            `/cities/${values.countryId}/${e.target.value}`,
          );
          setcities(res.data);
        }
      } else {
        updateObject[name] = e.target.value;
      }
      setValues({
        ...values,
        ...updateObject,
      });
      setErrors({
        ...errors,
        ...errorObject,
      });
    } catch (err) {
      //console.log("\n updateValues error:", err.message || err);
    }
  });
  const changeModel = useCallback((step) => {
    try {
      switch (step) {
        case 0:
          setStudentstep1ModalShow(false);
          setStudentModalShow(true);
          break;
        case 1:
          setStudentstep1ModalShow(true);
          setStudentModalShow(false);
          setStudentstep2ModalShow(false);
          break;
        case 2:
          setStudentstep2ModalShow(true);
          setStudentstep1ModalShow(false);
          setStudentstep3ModalShow(false);
          break;
        case 3:
          setStudentstep3ModalShow(true);
          setStudentstep2ModalShow(false);
          setStudentstep4ModalShow(false);
          break;
        case 4:
          setStudentstep4ModalShow(true);
          setStudentstep3ModalShow(false);
          setStudentstep5ModalShow(false);
          break;
        case 5:
          setStudentstep5ModalShow(true);
          setStudentstep4ModalShow(false);
          setStudentstep6ModalShow(false);
          break;
        case 6:
          setStudentstep6ModalShow(true);
          setStudentstep5ModalShow(false);
          break;
        default:
          setStudentModalShow(false);
          setStudentstep1ModalShow(false);
          setStudentstep2ModalShow(false);
          setStudentstep3ModalShow(false);
          setStudentstep4ModalShow(false);
          setStudentstep5ModalShow(false);
          setStudentstep6ModalShow(false);
          setStudentstep7ModalShow(false);
          setModalSignupShow(true);
          break;
      }
    } catch (err) {
      //console.log("\n error in changeModel:", err.message || err);
    }
  });
  const step1FetchData = useCallback(async () => {
    const haveErrors = {
      first: false,
      last: false,
    };
    if (!name.first || name.first === "" || name.first === undefined) {
      haveErrors.first = true;
    }
    if (!name.last || name.last === "" || name.last === undefined) {
      haveErrors.last = true;
    }
    if (haveErrors.first || haveErrors.last) {
      setErrors({
        ...errors,
        ...haveErrors,
      });
      return;
    }
    setValues({ ...values, fullName: name.first + " " + name.last });
    changeModel(1);
  });
  const grades = (() => {
    const grades = [...Array(13).keys()];
    grades.splice(0, 1);
    return grades;
  })();

  return (
    <>
      <Modal
        className="loginmodal student-login-main"
        // {...props}
        show={modalStudentShow}
        onHide={() => changeModel(-1)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header closeButton>
            <div className="btnback">
              <Link
                to="#"
                onClick={() => changeModel(-1)}
                className="backbtn-icn"
              >
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Sign up as a Student</h4>
              <p>We are Neithedu team!</p>
              <p>Please introduce yourself</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <Form.Label>Your first name</Form.Label>
                  <Form.Control
                    type="name"
                    value={name.first}
                    onChange={(e) => {
                      // only alphabets
                      if (!/^[a-zA-Z]*$/g.test(e.target.value)) {
                        return false;
                      }
                      setErrors({ ...errors, first: false });
                      setName({ ...name, first: e.target.value });
                    }}
                    className={errors.first ? "error-box" : ""}
                    placeholder="Your first name"
                  />
                </Form.Group>

                <Form.Group className="subsform-fild">
                  <Form.Label>Your last name</Form.Label>
                  <Form.Control
                    type="name"
                    value={name.last}
                    onChange={(e) => {
                      if (!/^[a-zA-Z]*$/g.test(e.target.value)) {
                        return false;
                      }
                      setErrors({ ...errors, last: false });
                      setName({ ...name, last: e.target.value });
                    }}
                    className={errors.last ? "error-box" : ""}
                    placeholder="Your last name"
                  />
                </Form.Group>

                <div className="btnlogin-main">
                  <button
                    type="submit"
                    style={
                      name.first.length > 0 && name.last.length > 0
                        ? {}
                        : { ...disabledBtnProps }
                    }
                    className="btnlogin btnweak-bx"
                    onClick={step1FetchData}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="count-barr">
            <div className="counting-barful">
              <div className="linebar-tex">1/7</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        className="loginmodal student-login-main student-signup-step1"
        // {...props}
        show={modalStudentstep1Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header>
            <div className="btnback">
              <Link
                to="#"
                onClick={() => changeModel(0)}
                className="backbtn-icn"
              >
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Sign up as a student</h4>
              <p>Nice to meet you, {values.fullName}!</p>
              <p>What is your year of study?</p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => changeModel(-1)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <Form.Label>I am in Grade</Form.Label>
                  <Form.Control
                    className={errors.yearsOfStudy ? "error-box" : ""}
                    value={values.yearsOfStudy}
                    onChange={(e) => updateValues(e, "yearsOfStudy")}
                    as="select"
                  >
                    <option value="0">Choose your Grade</option>
                    {grades.map((val, index) => {
                      return (
                        <option
                          value={val}
                          key={index}
                        >{`Grade ${val}`}</option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>

                <div className="btnlogin-main">
                  <button
                    type="submit"
                    className="btnlogin btnweak-bx"
                    style={
                      values.yearsOfStudy !== "" ? {} : { ...disabledBtnProps }
                    }
                    onClick={() => {
                      if (
                        values.yearsOfStudy === 0 ||
                        values.yearsOfStudy === ""
                      ) {
                        setErrors({
                          ...errors,
                          yearsOfStudy: true,
                        });
                        return false;
                      }
                      changeModel(2);
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="count-barr std-count-step2">
            <div className="counting-barful">
              <div className="linebar-tex">2/7</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        className="loginmodal student-login-main student-signup-step2"
        // {...props}
        show={modalStudentstep2Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header>
            <div className="btnback">
              <Link
                to="#"
                onClick={() => changeModel(1)}
                className="backbtn-icn"
              >
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Sign up as a student</h4>
              <p>Greate!</p>
              <p>Where are you from?</p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => changeModel(-1)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <Form.Label>I live in</Form.Label>
                  <Row>
                    <div className="col-md-6 one">
                      <div className="livein-field">
                        <Form.Control
                          value={values.stateId}
                          className={errors.stateId ? "error-box" : ""}
                          onChange={(e) => updateValues(e, "stateId")}
                          as="select"
                        >
                          <option value="0">Choose your state</option>
                          {states
                            .sort((a, b) => a?.name.localeCompare(b.name))
                            .map((state, index) => {
                              return (
                                <option key={index} value={state?.id}>
                                  {state?.name}
                                </option>
                              );
                            })}
                        </Form.Control>
                      </div>
                    </div>
                    <div className="col-md-6 one">
                      <div className="livein-field">
                        <Form.Control
                          as="select"
                          value={values.cityId}
                          className={errors.cityId ? "error-box" : ""}
                          onChange={(e) => updateValues(e, "cityId")}
                        >
                          <option value="0">Choose your city</option>
                          {cities.map((city, index) => {
                            return (
                              <option key={index} value={city?.id}>
                                {city?.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </div>
                    </div>
                  </Row>
                </Form.Group>

                <div className="btnlogin-main">
                  <button
                    type="submit"
                    className="btnlogin btnweak-bx"
                    style={
                      values.cityId && values.stateId
                        ? {}
                        : { ...disabledBtnProps }
                    }
                    onClick={() => {
                      let validationErrors = {
                        stateId: false,
                        cityId: false,
                        isError: false,
                      };
                      if (!values.stateId) {
                        validationErrors.stateId = true;
                        validationErrors.isError = true;
                      }
                      if (!values.cityId) {
                        validationErrors.cityId = true;
                        validationErrors.isError = true;
                      }
                      if (validationErrors.isError) {
                        setErrors({
                          ...errors,
                          ...validationErrors,
                        });
                        return false;
                      }
                      changeModel(3);
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="count-barr std-count-step3">
            <div className="counting-barful">
              <div className="linebar-tex">3/7</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        className="loginmodal student-login-main student-signup-step3"
        show={modalStudentstep3Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header>
            <div className="btnback">
              <Link
                to="#"
                onClick={() => changeModel(2)}
                className="backbtn-icn"
              >
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Sign up as a student</h4>
              <p>
                Put your email. Don’t worry, we will send just the most
                important information!
              </p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => changeModel(-1)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <Form.Label>My email is</Form.Label>
                  <Form.Control
                    type="email"
                    value={values.email}
                    onChange={async (e) => {
                      const val = e.target.value;
                      updateValues({ target: { value: val } }, "email");
                      if (!(await validateEmail(val))) {
                        setErrors({ ...errors, email: true });
                      }
                    }}
                    className={errors.email ? "error-box" : ""}
                    placeholder="example@email.com"
                  />
                </Form.Group>

                <div className="btnlogin-main">
                  <button
                    type="submit"
                    style={values.email ? {} : { ...disabledBtnProps }}
                    className="btnlogin btnweak-bx"
                    onClick={async () => {
                      if (values.email === "") {
                        setErrors({
                          ...errors,
                          email: true,
                        });
                        return false;
                      }
                      const emailValid = await validateEmail(values.email);
                      if (!emailValid) {
                        setErrors({
                          ...errors,
                          email: true,
                        });
                        return;
                      }
                      // await handleSignUp();

                      changeModel(4);
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="count-barr std-count-step4">
            <div className="counting-barful">
              <div className="linebar-tex">4/7</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        className="loginmodal student-login-main student-signup-step6"
        // {...props}
        show={modalStudentstep4Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <CustomSnackbar {...snackProps} />
        <div className="login-modal-mainbx">
          <Modal.Header>
            <div className="btnback">
              <Link
                to="#"
                onClick={() => changeModel(3)}
                className="backbtn-icn"
              >
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Sign up as a student</h4>
              <p>
                Create the password to enter to your account with at least 6
                signs
              </p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => changeModel(-1)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <Form.Label>Create a password</Form.Label>
                  <div className="passwd-fild" style={{ position: "relative" }}>
                    <Form.Control
                      type={viewPassword.create}
                      placeholder="**********"
                      value={values.password}
                      onChange={(e) => updateValues(e, "password")}
                      className={errors.password ? "error-box" : ""}
                    />
                    <span
                      onClick={() =>
                        setviewPassword((prevState) => ({
                          ...prevState,
                          create:
                            prevState.create === "text" ? "password" : "text",
                        }))
                      }
                      className="icon-eye"
                      style={{
                        position: "absolute",
                        top: "13px",
                        cursor: "pointer",
                        right: "10px",
                      }}
                    >
                      <i
                        className="fas fa-eye"
                        style={{
                          color:
                            viewPassword.create === "text"
                              ? "var(--purple)"
                              : "black",
                        }}
                      ></i>
                    </span>
                  </div>
                </Form.Group>

                <Form.Group className="subsform-fild">
                  <Form.Label>Repeat a password</Form.Label>
                  <div className="passwd-fild" style={{ position: "relative" }}>
                    <Form.Control
                      type={viewPassword.repeat}
                      placeholder="**********"
                      value={values.confirmPassword}
                      onChange={(e) => updateValues(e, "confirmPassword")}
                      className={errors.confirmPassword ? "error-box" : ""}
                    />
                    <span
                      style={{
                        position: "absolute",
                        top: "13px",
                        cursor: "pointer",
                        right: "10px",
                      }}
                      className="icon-eye"
                      onClick={() =>
                        setviewPassword((prevState) => ({
                          ...prevState,
                          repeat:
                            prevState.repeat === "text" ? "password" : "text",
                        }))
                      }
                    >
                      <i
                        className="fas fa-eye"
                        style={{
                          color:
                            viewPassword.repeat === "text"
                              ? "var(--purple)"
                              : "black",
                        }}
                      ></i>
                    </span>
                  </div>
                </Form.Group>

                <div className="btnlogin-main">
                  <button
                    style={
                      values.password && values.confirmPassword
                        ? {}
                        : { ...disabledBtnProps }
                    }
                    onClick={() => {
                      const errors = {};
                      let isError = false;
                      if (values.password === "") {
                        errors.password = true;
                        isError = true;
                      }
                      if (values.confirmPassword === "") {
                        errors.confirmPassword = true;
                        isError = true;
                      }
                      if (values.password !== values.confirmPassword) {
                        errors.confirmPassword = true;
                        errors.password = true;
                        isError = true;
                        snackOpen("Passwords not matched", "error");
                        return;
                      }
                      if (values.password.length < 6) {
                        errors.confirmPassword = true;
                        errors.password = true;
                        isError = true;
                        snackOpen(
                          "Password should be atleast 6 characters",
                          "error",
                        );
                      }
                      if (isError) {
                        setErrors((state) => ({ ...state, ...errors }));
                        return;
                      }

                      changeModel(5);
                    }}
                    className="btnlogin btnweak-bx"
                  >
                    Save the password
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="count-barr std-count-step6">
            <div className="counting-barful">
              <div className="linebar-tex">5/7</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        className="loginmodal student-login-main student-signup-step4"
        // {...props}
        show={modalStudentstep5Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <CustomSnackbar {...snackProps} />

        <div className="login-modal-mainbx">
          <Modal.Header>
            <div className="btnback">
              <Link
                to="#"
                onClick={() => changeModel(4)}
                className="backbtn-icn"
              >
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Sign up as a student</h4>
              <p>
                You should confirm your mobile phone in order to save your
                account from being hacked
              </p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => changeModel(-1)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <Form.Label>My mobile phone is</Form.Label>
                  <PhoneInput
                    international
                    defaultCountry="IN"
                    placeholder="+375 22 123 45 67"
                    value={values.phoneNumber}
                    onChange={(e) => {
                      setErrors({
                        ...errors,
                        phoneNumber: false,
                      });
                      setValues({
                        ...values,
                        phoneNumber: e,
                      });
                    }}
                    className={errors.phoneNumber ? "error-box" : ""}
                  />
                </Form.Group>

                <div className="btnlogin-main">
                  <button
                    type="submit"
                    style={values.phoneNumber ? {} : { ...disabledBtnProps }}
                    className="btnlogin btnweak-bx"
                    onClick={async () => {
                      if (!values.phoneNumber || values.phoneNumber === "") {
                        setErrors({
                          ...errors,
                          phoneNumber: true,
                        });
                        return false;
                      }

                      if (values.phoneNumber.substring(3).length !== 10) {
                        snackOpen("Mobile number should be 10 digits", "error");
                        return;
                      }
                      await handleSignUp();
                    }}
                  >
                    Send a verification code
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="count-barr std-count-step5">
            <div className="counting-barful">
              <div className="linebar-tex">6/7</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        className="loginmodal student-login-main student-signup-step5"
        show={modalStudentstep6Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header>
            <div className="btnback">
              <Link
                to="#"
                onClick={() => changeModel(5)}
                className="backbtn-icn"
              >
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Sign up as a student</h4>
              <p>
                You should confirm your mobile phone in order to save your
                account from being hacked
              </p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => changeModel(-1)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <Form.Label>My mobile phone is</Form.Label>
                  <div className="phone-bar-form phone-with-country">
                    <PhoneInput
                      international
                      defaultCountry="IN"
                      placeholder="+375 22 123 45 67"
                      value={values.phoneNumber}
                      readOnly={true}
                    />
                    <div className="sendagain-btn">
                      <Link
                        to={"#"}
                        onClick={async () =>
                          await sendOtp(values.phoneNumber, values.userId)
                        }
                      >
                        Send again
                      </Link>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className="subsform-fild">
                  <Form.Label>Verification code</Form.Label>
                  <Form.Control
                    onChange={(e) => updateOtp(e)}
                    className={otpError ? "error-box" : ""}
                    type="text"
                    placeholder="Enter Otp here"
                  />
                </Form.Group>

                <div className="btnlogin-main">
                  <button
                    type="submit"
                    style={otp ? {} : { ...disabledBtnProps }}
                    className="btnlogin btnweak-bx"
                    onClick={async () => {
                      if (otpError || !otp || !phoneVerify) {
                        setOtpError(true);
                        return;
                      }
                      await verify();
                    }}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="count-barr std-count-step5">
            <div className="counting-barful">
              <div className="linebar-tex">7/7</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>

      <ConfirmationDialog
        isOpen={modalStudentstep7Show}
        signInScreenHandler={() => {
          setStudentstep7ModalShow(false);
          setModalShow(true);
        }}
        closeDialog={changeModel}
      />
    </>
  );
};

export default StudentSignUp;
