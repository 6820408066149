import React, { useContext, useEffect, useState } from "react";
import "../../home.scss";
import "../../profile.scss";
import "../../media.scss";
import "../../games.scss";
import "../../loginModal/login.scss";
import "../../messages/messages.scss";
import "../../myactivities.scss";
import "../../gamestabs.scss";
import "../../gamesflowphotos.scss";
import "../../selectedgames.scss";
import "../../gamequiz.scss";
import { Col, Container, Form, Row } from "react-bootstrap";
import logo from "../../../assets/images/logo.png";
import backorange from "../../../assets/images/backorange.svg";
import { Link, useParams } from "react-router-dom";
import FooterEducator from "../../layout/Footer/FooterEducator";
import HeaderGames from "../../layout/Header/HeaderGames";
import { getSingalActivities } from "../../../service/apis/activities";
import { Box, Grid, Stack, Typography, Divider } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { manualMarkQuestion, sendAnswerQu } from "../../../service/apis/games";
import ConfirmationModal from "../../dialogs/ConfirmationModal";
import {
  educatorQuestionsListIcon,
  educatorQuestionsListWrapper,
  educatorQuestionMarkDone,
  educatorQuestionMarkNotDone,
  educatorGameMarkingBox,
  markableCloseIcon,
  markableTickIcon,
  correctAnswerIcon,
  wrongAnswerIcon,
} from "../../../utils/style-constants";
import {
  markOrCompleteEduOrParentTask,
  sendForRevision,
} from "../../../service/apis/tasks";
import { SnackbarContext } from "../../../contexts/Snackbar";
import SendRevision from "../../SendRevision";
import { convertDateToString } from "../../../utils/helperFunctions";
import { NotificationContext } from "../../../contexts/Notification";

function SaveButton({ saveGameHandler, totalPoints }) {
  const [enteredPoints, setEnteredPoints] = useState(totalPoints);

  const btnClickHandler = () => {
    saveGameHandler(enteredPoints);
  };
  return (
    <div className="number-points-frm" style={{ paddingTop: "0px" }}>
      <div className="points-iner-nw">
        <Col>
          <div className="numheading">
            <h5>Number of points for the answer</h5>
          </div>
          <Row>
            <div className="col-6 col-md-3 one">
              <div className="points-num-bx">
                <Form.Group className="subsform-fild">
                  <Form.Control
                    type="text"
                    placeholder="20"
                    value={enteredPoints}
                    onChange={(e) => setEnteredPoints(e.target.value)}
                    style={{
                      minWidth: "50px",
                      paddingLeft: "15px",
                      paddingRight: "8px",
                    }}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="col-6 col-md-4 two" onClick={btnClickHandler}>
              <div className="save-points">
                <div className="btn-week">
                  <Link to="#" className="btnweak-bx">
                    <span className="btn-title">Save</span>
                  </Link>
                </div>
              </div>
            </div>
          </Row>
        </Col>
      </div>
    </div>
  );
}

function StudentActivityDetails() {
  const [points, setPoints] = useState("");
  const [confirmationModal, setConfirmationModal] = useState({
    open: false,
    heading: "",
    detail: "",
    selectedId: "",
    successHandler: () => {},
  });
  const [task, setTask] = useState({});
  const [showRevisionModal, setShowRevisionModal] = useState(false);

  const params = useParams();
  const { activityTypeId, activityId } = params;
  const { openSnackbar } = useContext(SnackbarContext);
  const { showNotification } = useContext(NotificationContext);

  const getActivityDetails = async () => {
    try {
      const isGameFlow =
        activityTypeId === "3" || activityTypeId === "4" ? true : false;
      const response = await getSingalActivities(
        activityTypeId,
        activityId,
        isGameFlow,
      );

      setTask(response);
      console.log("Response : ", response);
    } catch (err) {
      console.log("Error : ", err);
    }
  };

  useEffect(() => {
    getActivityDetails();
  }, [activityTypeId, activityId]);

  let gradeWithSuffix = "";
  if (task?.grade) {
    if (+task.grade === 1) {
      gradeWithSuffix = "1st";
    } else if (+task.grade === 2) {
      gradeWithSuffix = "2nd";
    } else if (+task.grade === 3) {
      gradeWithSuffix = "3rd";
    } else {
      gradeWithSuffix = task.grade + "th";
    }
  }

  let allGamesCompleted = false;
  if (task?.games) {
    allGamesCompleted = task.games.every((item) => item.IsCompleted);
  }

  const markNonDiscreteQuestion = async (
    markedQuestionId,
    earnedPoints,
    isCorrect,
  ) => {
    try {
      // TODO
      const res = await manualMarkQuestion(
        markedQuestionId,
        earnedPoints,
        isCorrect,
        "NonDiscrete",
      );
      console.log("response", res);
      getActivityDetails();
    } catch (e) {
      console.log("error", e);
    }
  };

  const markDiscreteQuestion = async (item, value) => {
    try {
      const res = await manualMarkQuestion(
        item.MarkedQuestionId,
        value ? item.Points : item.EarnedPoints,
        value,
        "Discrete",
      );
      console.log("response", res);
      getActivityDetails();
    } catch (e) {
      console.log("error", e);
    }
  };

  const markAsCompleteHandler = async () => {
    await markOrCompleteEduOrParentTask(task.earnedPoints, task.id);
    resetConfirmationState();
    showNotification("Marked as complete");
    getActivityDetails();
  };

  const resetConfirmationState = () => {
    setConfirmationModal({
      open: false,
      heading: "",
      detail: "",
      successHandler: "",
      successText: "",
    });
  };

  const SendRevisionHandler = async (comment, deadline) => {
    try {
      const response = await sendForRevision(
        activityId,
        comment,
        task.points,
        convertDateToString(deadline),
      );
      if (response) {
        showNotification("Sent for revision");
        setShowRevisionModal(false);
        getActivityDetails();
      }
      getActivityDetails();
    } catch (e) {
      console.log(e);
      openSnackbar("Something went wrong", "error");
    }
  };
  return (
    <div className="home-wrapper">
      <HeaderGames />
      <div className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs">
        <div className="games-photosynths gamequiz-main">
          <Container>
            <div className="quiz-game-popup">
              <div className="current-lesson-main ">
                <div className="current-lesson-contain">
                  <span className="back-btndrop">
                    <Link to="/educator" className="backbtn-lg">
                      <img src={backorange} alt="" />
                    </Link>
                  </span>
                  <div className="current-lesson-topbr">
                    <div
                      className="medm-tex orng-clr"
                      style={{
                        display: "flex",
                        gap: "2rem",
                        justifyContent: "center",
                      }}
                    >
                      <h4>
                        {task?.activityName || task?.activityTypeName}:{" "}
                        {task?.activityTypeName === "My Material" && (
                          <>
                            {task?.noOfAudios > 0 && (
                              <span>
                                {task?.noOfAudios} audio
                                {task?.noOfAudios > 1 ? "s" : ""},{" "}
                              </span>
                            )}
                            {task?.noOfImages > 0 && (
                              <span>
                                {task?.noOfImages} image
                                {task?.noOfImages > 1 ? "s" : ""},{" "}
                              </span>
                            )}
                            {task?.noOfPdf > 0 && (
                              <span>
                                {task?.noOfPdf} pdf
                                {task?.noOfPdf > 1 ? "s" : ""},{" "}
                              </span>
                            )}
                            {task?.noOfVideos > 0 && (
                              <span>
                                {task?.noOfVideos} video
                                {task?.noOfVideos > 1 ? "s" : ""},{" "}
                              </span>
                            )}
                          </>
                        )}
                        {task?.gameType && <span>{task?.gameType} </span>}
                      </h4>
                      <h4>
                        {task?.categoryName &&
                          `${task.categoryName}, ${gradeWithSuffix} grade`}
                      </h4>
                    </div>
                    <div>
                      {" "}
                      <h1>{task?.title}</h1>
                    </div>
                    <div
                      style={{
                        marginTop: "1rem",
                        padding: "2rem 2rem",
                        borderRadius: "20px",
                        backgroundColor: "rgba(255,255,255,0.1)",
                      }}
                    >
                      <div className="lesson-status-list quiz-gamelession">
                        <ul className="lesson-listing-curnt">
                          <li>
                            <div className="lesson-status-detail">
                              <span
                                className="medm-tex orng-clr"
                                style={{ color: "#fff" }}
                              >
                                Mode:
                              </span>
                              <span className="medm-tex orng-clr onelist">
                                {" "}
                                {task?.mode ? task?.mode : "Active"}
                              </span>
                            </div>
                            {task?.status && (
                              <div className="lesson-status-detail">
                                <span
                                  className="medm-tex orng-clr "
                                  style={{ color: "#fff" }}
                                >
                                  Status:{" "}
                                </span>
                                <span className="medm-tex orng-clr onelist">
                                  {task?.status}
                                </span>
                              </div>
                            )}
                          </li>
                          <li>
                            {task?.creationDate && (
                              <div className="lesson-status-detail">
                                <span
                                  className="medm-tex orng-clr"
                                  style={{ color: "#fff" }}
                                >
                                  Created:
                                </span>
                                <span className="medm-tex orng-clr onelist">
                                  {" " + task?.creationDate}
                                </span>
                              </div>
                            )}
                            {task?.deadline && (
                              <div className="lesson-status-detail">
                                <span
                                  className="medm-tex orng-clr"
                                  style={{ color: "#fff" }}
                                >
                                  Deadline:
                                </span>
                                <span className="medm-tex orng-clr onelist">
                                  {" " + task?.deadline}
                                </span>
                              </div>
                            )}
                          </li>
                          <li>
                            {(task?.totalPoints || task.totalPoints === 0) && (
                              <div className="lesson-status-detail">
                                <span
                                  className="medm-tex orng-clr"
                                  style={{ color: "#fff" }}
                                >
                                  Total points:
                                </span>
                                <span className="medm-tex orng-clr onelist">
                                  {" " + task.totalPoints}
                                </span>
                              </div>
                            )}

                            {(task?.earnedPoints ||
                              task.earnedPoints === 0) && (
                              <div className="lesson-status-detail">
                                <span
                                  className="medm-tex orng-clr"
                                  style={{ color: "#fff" }}
                                >
                                  Earned points:
                                </span>
                                <span className="medm-tex orng-clr onelist">
                                  {" " + task.earnedPoints}
                                </span>
                              </div>
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>

                    {task.activityName === "My Gameflows" && (
                      <div
                        style={{
                          marginTop: "1rem",
                          padding: "2rem 2rem",
                          borderRadius: "20px",
                          backgroundColor: "rgba(255,255,255,0.1)",
                        }}
                      >
                        <div className="lesson-status-list quiz-gamelession">
                          {/* <ul className="lesson-listing-curnt"> */}
                          <div
                            className="description"
                            style={{
                              color: "#fff",
                              maxWidth: "85%",
                              margin: "0 auto",
                            }}
                          >
                            <b>Description:</b> {task?.description}
                          </div>

                          <div
                            className="progress-bar"
                            style={{
                              background: "white",
                              color: "#828282",
                              display: "flex",
                              flexDirection: "row",
                              borderRadius: "12px",
                              padding: "3% 5%",
                              margin: "5% 0",
                              flexWrap: "wrap",
                            }}
                          >
                            {task?.games?.map((item, index, arr) => {
                              let iconToShow = "";

                              if (allGamesCompleted) {
                                iconToShow = "/images/icons/done.svg";
                              } else if (item.IsCompleted) {
                                iconToShow = "circle";
                              } else if (
                                task.status === "InProgress" &&
                                index === 0 &&
                                arr[0].IsCompleted === false
                              ) {
                                iconToShow = "blank";
                              } else if (
                                task.status === "InProgress" &&
                                arr[index - 1]?.IsCompleted === true
                              ) {
                                iconToShow = "blank";
                              } else if (!item?.IsCompleted) {
                                iconToShow = "/images/icons/unlock2.svg";
                              }

                              return (
                                <div
                                  key={index}
                                  className="item"
                                  style={{
                                    display: "flex",
                                    alignItems: "start",
                                    flex: "auto",
                                  }}
                                >
                                  <div className="icon">
                                    <div
                                      className="circle"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {iconToShow === "circle" ? (
                                        <div
                                          style={{
                                            width: "43px",
                                            height: "43px",
                                            borderRadius: "50%",
                                            background: "#4CAF50",
                                          }}
                                        ></div>
                                      ) : iconToShow === "blank" ? (
                                        <span class="lockquiz-icon"></span>
                                      ) : (
                                        <img src={iconToShow} alt="Unlock" />
                                      )}
                                    </div>
                                    <div
                                      className="text"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "20px",
                                      }}
                                    >
                                      {item.GameTypeName}
                                    </div>
                                    <div
                                      className="title"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "800",
                                        lineHeight: "20px",
                                        color: "#632CAF",
                                      }}
                                    >
                                      {item.Title}
                                    </div>
                                  </div>

                                  <div
                                    className="spacer"
                                    style={{
                                      borderRadius: "37px",
                                      height: "5.21px",
                                      background: "#C4C4C4",
                                      width: "12px",
                                      flex: "auto",
                                      marginTop: "1.2rem",
                                    }}
                                  ></div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {Object.keys(task)?.length > 0 &&
                task.quizQuestions?.length > 0 && (
                  <Box sx={educatorQuestionsListWrapper}>
                    {task.quizQuestions?.map((item, idx) => {
                      let answerStatus = null;
                      if (!item.Answer) {
                        answerStatus = "Not attempted yet";
                      } else {
                        answerStatus = item.Answer;
                      }
                      let iconsToShow = "";
                      if (!item.Answer || task.mode === "InActive") {
                        iconsToShow = (
                          <>
                            <Box key={idx} sx={educatorQuestionsListIcon}>
                              <DoneIcon color="inherit" />
                            </Box>

                            <Box sx={educatorQuestionsListIcon}>
                              <CloseIcon sx={{ color: "inherit" }} />
                            </Box>
                          </>
                        );
                      } else if (
                        item.QuestionType === "NonDiscrete" &&
                        task.mode !== "InActive"
                      ) {
                        iconsToShow = (
                          <>
                            <Box
                              sx={educatorQuestionMarkDone}
                              onClick={() =>
                                markNonDiscreteQuestion(
                                  item.MarkedQuestionId,
                                  item.Points,
                                  true,
                                )
                              }
                            >
                              <DoneIcon color="inherit" />
                            </Box>
                            <Box
                              sx={educatorQuestionMarkNotDone}
                              onClick={() =>
                                markNonDiscreteQuestion(
                                  item.MarkedQuestionId,
                                  0,
                                  false,
                                )
                              }
                            >
                              <CloseIcon sx={{ color: "inherit" }} />
                            </Box>
                          </>
                        );
                      } else if (
                        item.QuestionType === "Discrete" &&
                        item.IsCorrect &&
                        task.mode !== "InActive"
                      ) {
                        iconsToShow = (
                          <Box
                            sx={markableTickIcon}
                            onClick={() =>
                              setConfirmationModal({
                                open: true,
                                detail: "",
                                heading:
                                  "Are you sure you want to mark it incorrect?",
                                successHandler: async () => {
                                  await markDiscreteQuestion(item, false);
                                  resetConfirmationState();
                                  openSnackbar("Marked as incorrect");
                                  getActivityDetails();
                                },
                              })
                            }
                          >
                            <DoneIcon color="inherit" />
                          </Box>
                        );
                      } else if (
                        item.QuestionType === "Discrete" &&
                        !item.IsCorrect &&
                        task.mode !== "InActive"
                      ) {
                        iconsToShow = (
                          <Box
                            sx={markableCloseIcon}
                            onClick={() =>
                              setConfirmationModal({
                                open: true,
                                detail: "",
                                heading:
                                  "Are you sure you want to mark it correct?",
                                successHandler: async () => {
                                  await markDiscreteQuestion(item, true);
                                  resetConfirmationState();
                                  openSnackbar("Marked as correct");
                                  getActivityDetails();
                                },
                              })
                            }
                          >
                            <CloseIcon sx={{ color: "inherit" }} />
                          </Box>
                        );
                      } else if (item.IsCorrect && task.mode !== "InActive") {
                        iconsToShow = (
                          <Box sx={correctAnswerIcon}>
                            <DoneIcon color="inherit" />
                          </Box>
                        );
                      } else if (!item.IsCorrect && task.mode !== "InActive") {
                        iconsToShow = (
                          <Box sx={wrongAnswerIcon}>
                            <CloseIcon sx={{ color: "inherit" }} />
                          </Box>
                        );
                      }

                      return (
                        <>
                          <Grid
                            container
                            my="1rem"
                            alignItems="center"
                            key={idx}
                          >
                            <Grid item xs={4}>
                              <Box sx={educatorGameMarkingBox}>
                                <Stack flexDirection="row">
                                  <Typography
                                    sx={{
                                      fontSize: "0.9rem",
                                      color: "var(--purple-dark)",
                                      fontWeight: "700",
                                    }}
                                  >
                                    Question {idx + 1}
                                  </Typography>
                                  <Typography
                                    ml="1rem"
                                    sx={{
                                      color: "var(--grey-light)",
                                      fontSize: "0.9rem",
                                      lineBreak: "anywhere",
                                    }}
                                  >
                                    {item.QuestionType}
                                  </Typography>
                                </Stack>
                                <Typography
                                  sx={{
                                    color: "black",
                                  }}
                                  textAlign="start"
                                  mt="1rem"
                                  fontSize="0.9rem"
                                >
                                  {item.QuestionText}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={4}>
                              <Box p={"1rem"} pt={"5px"}>
                                <Typography
                                  sx={{
                                    textAlign: "start",
                                    color: "#6a64e6",
                                    fontWeight: "500",
                                  }}
                                >
                                  Answer
                                </Typography>
                                <Typography sx={{ textAlign: "start" }}>
                                  {answerStatus}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={3}>
                              {item.Answer &&
                              item.QuestionType === "NonDiscrete" ? (
                                <SaveButton
                                  saveGameHandler={(enteredPoints) => {
                                    if (enteredPoints > item.Points) {
                                      alert(
                                        "Earned points cannot be greater than total points",
                                      );
                                      return;
                                    }
                                    markNonDiscreteQuestion(
                                      item.MarkedQuestionId,
                                      enteredPoints,
                                      true,
                                    );
                                  }}
                                  totalPoints={item.Points}
                                />
                              ) : (
                                <>
                                  {item.Answer && (
                                    <div
                                      className="number-points-frm"
                                      style={{ paddingTop: "0px" }}
                                    >
                                      <div className="points-iner-nw">
                                        <Col>
                                          <div className="numheading">
                                            <h5>Earned Points</h5>
                                          </div>
                                          <Typography component="p" ml={6}>
                                            {item.EarnedPoints}
                                          </Typography>
                                        </Col>
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                            </Grid>

                            <Grid
                              item
                              xs={1}
                              sx={{ display: "flex", justifyContent: "end" }}
                            >
                              <Stack rowGap={"2px"}>{iconsToShow}</Stack>
                            </Grid>
                          </Grid>
                          {idx !== task.quizQuestions?.length - 1 && (
                            <Divider />
                          )}
                        </>
                      );
                    })}
                  </Box>
                )}
              {/* TODO: change it back */}
              {task?.status === "Done" && (
                <>
                  <Row
                    style={{
                      justifyContent: "center",
                      gap: "70px",
                      position: "relative",
                      bottom: "15px",
                    }}
                  >
                    <Box
                      sx={{ maxWidth: "200px" }}
                      onClick={() => setShowRevisionModal(true)}
                      className="btnlogin btnweak-bx"
                    >
                      Send for revision
                    </Box>

                    <Box
                      sx={{ maxWidth: "200px" }}
                      onClick={() => {
                        setConfirmationModal({
                          open: true,
                          detail:
                            "You won't be able to change anything once it is completed",
                          heading: "Are you sure you want to mark it complete?",
                          successHandler: markAsCompleteHandler,
                        });
                      }}
                      className="btnlogin btnweak-bx"
                    >
                      Complete
                    </Box>
                  </Row>
                </>
              )}
            </div>
          </Container>
        </div>
        {confirmationModal.open && (
          <ConfirmationModal
            open={confirmationModal.open}
            heading={confirmationModal.heading}
            detail={confirmationModal.detail}
            successHandler={confirmationModal.successHandler}
            closeModal={() => {
              setConfirmationModal({
                open: false,
                heading: "",
                detail: "",
              });
            }}
            cancelHandler={() => {
              setConfirmationModal({
                open: false,
                heading: "",
                detail: "",
              });
            }}
          />
        )}
        {showRevisionModal && (
          <SendRevision
            submit={SendRevisionHandler}
            deadline={task.deadline}
            closeModal={() => setShowRevisionModal(false)}
          />
        )}
        <FooterEducator logo={logo} />
      </div>
    </div>
  );
}

export default StudentActivityDetails;
