import React from "react";
import { useState } from "react";
import { CreateNewPassword } from "./CreateNewPassword";
import { EmailRecovery } from "./Email";
import { Phone1 } from "./Phone";

const POPUPS = {
  PHONE1: "PHONE1",
  EMAIL: "EMAIL",
  NEW_PASSWORD: "NEW_PASSWORD",
};
export const ForgotPassword = ({ handleClose }) => {
  const [currentPopup, setcurrentPopup] = useState(POPUPS.PHONE1);
  return (
    <>
      {currentPopup === POPUPS.PHONE1 && (
        <Phone1
          nextPopup={() => setcurrentPopup(POPUPS.EMAIL)}
          handleClose={handleClose}
        />
      )}
      {currentPopup === POPUPS.EMAIL && (
        <EmailRecovery
          nextPopup={() => setcurrentPopup(POPUPS.NEW_PASSWORD)}
          handleClose={handleClose}
        />
      )}
      {currentPopup === POPUPS.NEW_PASSWORD && (
        <CreateNewPassword nextPopup={handleClose} handleClose={handleClose} />
      )}
    </>
  );
};
