export const educatorQuestionsListWrapper = {
  backgroundColor: "white",
  borderRadius: "20px",
  p: "1rem",
  mt: "2rem",
  position: "relative",
  pt: "0.1rem",
};
export const educatorQuestionsListIcon = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "2.5rem",
  width: "2.5rem",
  borderTopRightRadius: "10px",
  borderTopLeftRadius: "10px",
  backgroundColor: "#EDECFF",
  color: "var(--purple)",
};
export const educatorQuestionMarkDone = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "2.5rem",
  width: "2.5rem",
  borderTopRightRadius: "10px",
  borderTopLeftRadius: "10px",
  backgroundColor: "green",
  color: "#fff",
  cursor: "pointer",
};

export const educatorQuestionMarkNotDone = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "2.5rem",
  width: "2.5rem",
  borderRadius: "10px",
  backgroundColor: "red",
  color: "#fff",
  cursor: "pointer",
};
export const markableCloseIcon = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "2.5rem",
  width: "2.5rem",
  borderRadius: "10px",
  backgroundColor: "red",
  color: "#fff",
  cursor: "pointer",
};
export const markableTickIcon = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "2.5rem",
  width: "2.5rem",
  borderRadius: "10px",
  backgroundColor: "green",
  color: "#fff",
  cursor: "pointer",
};
export const correctAnswerIcon = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "2.5rem",
  width: "2.5rem",
  borderRadius: "10px",
  backgroundColor: "#EDECFF",
  color: "var(--purple)",
};
export const wrongAnswerIcon = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "2.5rem",
  width: "2.5rem",
  borderRadius: "10px",
  backgroundColor: "#EDECFF",
  color: "var(--purple)",
};
export const educatorGameMarkingBox = {
  backgroundColor: "#EDEDFF",
  borderRadius: "10px",
  p: "1rem",
};
