import React from "react";
import "./home.scss";
import "./profile.scss";
import "./media.scss";
import "./games.scss";
import "./loginModal/login.scss";
import "./messages/messages.scss";
import "./myactivities.scss";
import "./gamestabs.scss";
import "./gamesflowphotos.scss";
import "./selectedgames.scss";
import "./gamequiz.scss";
import {
  // Navbar,
  // Nav,
  // NavDropdown,
  Container,
  // Dropdown,
  // br,
  Row,
  // Accordion,
  // Card,
  Button,
  Form,
  Modal,
  // Select,
  // Sonnet,
} from "react-bootstrap";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import logo from "../assets/images/logo.png";
// import gamesgalery1 from "../assets/images/games-galery1.jpg";
// import gamesgalery2 from "../assets/images/games-galery2.jpg";
// import chaticon from "../assets/images/chat-icon.svg";
// import batchorange from "../assets/images/batch-orange.svg";
// import palinblue from "../assets/images/palin-blue.svg";
// import mike from "../assets/images/mike.svg";
// import attachemtnblue from "../assets/images/attachemtn-blue.svg";
// import downarrowblue from "../assets/images/down-arrow-blue.svg";
// import dotthree from "../assets/images/dotthree.svg";
// import addbtnround from "../assets/images/add-btn.svg";
// import darkblueplan from "../assets/images/darkblue-plan.svg";
import backorange from "../assets/images/backorange.svg";
import editorangelg from "../assets/images/editlg-orange.svg";
import darkorangeplan from "../assets/images/darkorange-plan.svg";

// import Slider from "react-slick";
// import SliderCenter from "./Slider";
import { Link } from "react-router-dom";
import FooterEducator from "./layout/Footer/FooterEducator";
// import Addgroup from "./Addgroup";
// import Addpupil from "./Addpupil";
// import SendInvite from "./SendInvite";
// import Groupmember from "./Groupmember";
import HeaderGames from "./layout/Header/HeaderGames";
// import SendRevision from "./SendRevision";
import Selectstudent from "./Selectstudent";

function MyGameFlow() {
  // const [show, setShow] = React.useState("1");

  // const [breadMenuShow, setBreadMenuShow] = React.useState(false);
  /* start modal */
  const [modalShow, setModalShow] = React.useState(false);
  /* end modal */

  /* startsave gamed step1 */
  const [modalgamesavedstep1Show, setgamesavedstep1ModalShow] =
    React.useState(false);

  /* end save gamned step1 */

  /* start send revision popup */
  // const [modalpersonaltskstep1Show, setpersonaltskstep1ModalShow] =
  //   React.useState(false);

  /* end send revision popup */

  /* start select student */
  const [modalAddgroupstep2Show, setAddgroupstep2ModalShow] =
    React.useState(false);

  /* end select student */

  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderGames setModalShow={(value) => setModalShow(value)} />
      {/* end header */}

      <div className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs">
        <div className="games-photosynths gamequiz-main quizflow-main">
          <Container>
            <div className="quiz-game-popup">
              <div className="current-lesson-main ">
                <div className="current-lesson-contain">
                  <span className="back-btndrop">
                    <Link to="educator" className="backbtn-lg">
                      <img src={backorange} alt=""/>
                    </Link>
                  </span>
                  <div className="right-iconbox-quizz">
                    <ul>
                      <li>
                        <Link
                          to="#"
                          className="editbtn-lgnew"
                          onClick={() => setAddgroupstep2ModalShow(true)}
                        >
                          <img src={darkorangeplan} />
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="editbtn-lgnew">
                          <img src={editorangelg} alt=""/>
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="current-lesson-topbr">
                    <div className="medm-tex orng-clr">My Game flow</div>
                    <h1>
                      Cellular Life and Genetics, Title of the game that is
                      located in Neithedu
                    </h1>
                    <div className="lesson-status-list quiz-gamelession">
                      <ul className="lesson-listing-curnt">
                        <li>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Mode:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              Mode: Active{" "}
                            </span>
                          </div>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Status:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              Not checked, Late{" "}
                            </span>
                          </div>
                        </li>
                        <li>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Deadline:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              29 Nov 2021
                            </span>
                          </div>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Created:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              16 Aug 2021
                            </span>
                          </div>
                        </li>
                        <li>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Points:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              10 for question
                            </span>
                          </div>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Total points:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              250 points
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="question-scrlbar-bx mygameflow-quizz">
                <div className="question-sheet-quiz">
                  <div className="questionbox-main beginer-box">
                    <div className="iner-quest-report quizgame-bodytable">
                      <Link to="GameflowNotchecked" className="gameflw-link">
                        <div className="innergame-flwbg">
                          <Row className="reportcard-box">
                            <div className="col-md-6 col-lg-6 one">
                              <div className="left-quedtions">
                                <div className="Question-listbx">
                                  <div className="title">
                                    <span className="open-quest">
                                      {" "}
                                      Fill-in-the-Blank
                                    </span>
                                  </div>
                                  <div className="question-contwnt">
                                    <p className="boldtex">
                                      Title of the game that educator created
                                      himself something
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-6 two">
                              <div className="answer-right">
                                <div className="aswer-details">
                                  <div className="inner-quizgame-cont">
                                    <ul>
                                      <li>
                                        <span className="medm-tex orng-clr">
                                          Not checked{" "}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="gameflw-right-count">
                                    <span className="countsml-flow">1</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Row>
                        </div>
                      </Link>
                    </div>

                    <div className="iner-quest-report quizgame-bodytable">
                      <Link to="/PersonalTask" className="gameflw-link">
                        <div className="innergame-flwbg">
                          <Row className="reportcard-box">
                            <div className="col-md-6 col-lg-6 one">
                              <div className="left-quedtions">
                                <div className="Question-listbx">
                                  <div className="title">
                                    <span className="open-quest">
                                      {" "}
                                      Personal task
                                    </span>
                                  </div>
                                  <div className="question-contwnt">
                                    <p className="boldtex">
                                      Another title of the peronal task
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-6 two">
                              <div className="answer-right">
                                <div className="aswer-details">
                                  <div className="inner-quizgame-cont">
                                    <ul>
                                      <li>
                                        <span className="medm-tex orng-clr">
                                          Not checked{" "}
                                        </span>
                                      </li>
                                      <li>
                                        <span className="medm-tex">
                                          Sent 27 Nov, 2021 02:12pm
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="gameflw-right-count">
                                    <span className="countsml-flow">1</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Row>
                        </div>
                      </Link>
                    </div>

                    <div className="iner-quest-report quizgame-bodytable">
                      <Link to="/GameQuiz" className="gameflw-link">
                        <div className="innergame-flwbg">
                          <Row className="reportcard-box">
                            <div className="col-md-6 col-lg-6 one">
                              <div className="left-quedtions">
                                <div className="Question-listbx">
                                  <div className="title">
                                    <span className="open-quest">
                                      {" "}
                                      Neithedu Game: Quiz
                                    </span>
                                  </div>
                                  <div className="question-contwnt">
                                    <p className="boldtex">
                                      Name of the game that teacher sent to this
                                      flow
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-6 two">
                              <div className="answer-right">
                                <div className="aswer-details">
                                  <div className="inner-quizgame-cont">
                                    <ul>
                                      <li>
                                        <span className="medm-tex orng-clr">
                                          Late{" "}
                                        </span>
                                      </li>
                                      <li>
                                        <span className="medm-tex">
                                          Deadline missed 29 Nov, 2020 04:59pm
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Row>
                        </div>
                      </Link>
                    </div>

                    <div className="iner-quest-report quizgame-bodytable">
                      <Link to="#" className="gameflw-link">
                        <div className="innergame-flwbg">
                          <Row className="reportcard-box">
                            <div className="col-md-6 col-lg-6 one">
                              <div className="left-quedtions">
                                <div className="Question-listbx">
                                  <div className="title">
                                    <span className="open-quest">
                                      {" "}
                                      Material
                                    </span>
                                  </div>
                                  <div className="question-contwnt">
                                    <p className="boldtex">
                                      One more title that can be here anyway
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-6 two">
                              <div className="answer-right">
                                <div className="aswer-details">
                                  <div className="inner-quizgame-cont">
                                    <ul>
                                      <li>
                                        <span className="medm-tex orng-clr">
                                          Seen{" "}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Row>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>

        {/* footer start */}
        <FooterEducator logo={logo} />
        {/* footer end */}
      </div>

      {/* start modal my student */}

      {/* start game save step1 */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        // {...props}
        show={modalgamesavedstep1Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2 mystudent-games">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>The game is saved</h4>
              <h5>Send the game to my students</h5>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setgamesavedstep1ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="serch-inner-group">
                    <div className="serchfiled-activities">
                      <Form.Control
                        type="text"
                        placeholder="Start typing name or year of study"
                        className="formbox"
                      />
                      <Button type="button" className="seachbtn">
                        <i className="fas fa-search"></i>
                      </Button>
                    </div>
                  </div>

                  <div className="newpupil-list-bx">
                    <Form.Group className="subsform-fild child-tex-fld height-max-flow">
                      <div className="child-boxnew">
                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Aaradhya Rayasam</h5>
                                  <h6>India, Mumbai 1 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Chandrashekhar Kalvakuntla</h5>
                                  <h6>India, Mumbai 3 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>7F Class</h5>
                                  <h6>Hamut Kapit, Kemal Mara and 12 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Additional group</h5>
                                  <h6>Laska Doe, Hamut Kapit and 4 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>8A Class for exams</h5>
                                  <h6>Hamut Kapit, Laska Doe and 8 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Chandrashekhar Kalvakuntla</h5>
                                  <h6>India, Mumbai 3 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </Form.Group>

                <div className="student-btn-savedgame">
                  <div className="btnlogin-main">
                    <button type="submit" className="btnlogin btnweak-bx">
                      Send to selected students
                    </button>
                  </div>
                </div>
                <div className="btnlogin-main">
                  <button type="submit" className="btnlogin btnweak-bx">
                    Later
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end Add a Member Group step2 */}

      {/* end modal my student */}

      {/* start select studen popup */}

      <Selectstudent
        modalAddgroupstep2Show={modalAddgroupstep2Show}
        setAddgroupstep2ModalShow={(value) => setAddgroupstep2ModalShow(value)}
      />

      {/* end select studen popup */}
    </div>
  );
}

export default MyGameFlow;
