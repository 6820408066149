import React from "react";
import "./home.scss";
import "./profile.scss";
import "./media.scss";
import "./games.scss";
import "./loginModal/login.scss";
import "./myactivities.scss";
import "./Parentsaccount.scss";
import "./childrenprogress.scss";
import "./childrenprogressdetail.scss";
import { Container, Row, Form, Modal } from "react-bootstrap";
import logo from "../assets/images/logo.png";
import instslide1 from "../assets/images/insturction-slide1.jpg";
import backorange from "../assets/images/backorange.svg";

import { Link } from "react-router-dom";
import Headerparents from "./layout/Header/Headerparents";
import FooterParent from "./layout/Footer/FooterParent";
import Addmychild from "./Addmychild";

function ChildProgressDetails() {
  /* start modal */
  const [modalShow, setModalShow] = React.useState(false);
  /* end modal */

  /* start peroanl task*/
  const [modalpersonaltskstep1Show, setpersonaltskstep1ModalShow] =
    React.useState(false);

  /* end persoanl task  */

  /* satrt add pupil modal */

  /* start Eductor signup step1 */
  const [modalAddmychildstep1Show, setAddmychildstep1ModalShow] =
    React.useState(false);
  /* end Eductor signup step1 */

  /* end add pupil modal */

  return (
    <div className="home-wrapper">
      {/* start header */}
      <Headerparents setModalShow={(value) => setModalShow(value)} />
      {/* end header */}

      <div className="games-dashboard-main dashboard-bgnew blur-bgg childrenprogres-details">
        <div className="games-topbread">
          <Container>
            <div className="child-details">
              <div className="child-details-uper">
                <div className="backarw-child">
                  <Link to="ChildrenProgress" className="backbtn-lg">
                    <img src={backorange} alt="" />
                  </Link>
                </div>
                <div className="custmrow-detail">
                  <div className="left-chlddetal">
                    <div className="profile-pic-child">
                      <span className="prof-picchild">
                        <i className="fas fa-user"></i>
                      </span>
                    </div>
                  </div>
                  <div className="right-chlddetal">
                    <div className="detail-child-inner">
                      <div className="lastseen">Last seen 20 min ago</div>
                      <h3>Alexandra Rossie</h3>
                      <div className="subjet-detailchld">
                        <ul className="subjchld-list">
                          <li>
                            <h5>Active subjects:</h5>
                            <h6>Biology, History, English </h6>
                          </li>
                          <li>
                            <h5>Completed activities:</h5>
                            <h6>5 for the last week </h6>
                          </li>
                          <li>
                            <h5>Missed deadlines:</h5>
                            <h6>2 activities</h6>
                          </li>
                          <li>
                            <h5>Next live lesson</h5>
                            <h6>
                              2 Sep, 2021 <span className="paid">Paid</span>
                            </h6>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="booked-lession">
                <Row className="booked-bx">
                  <div className="col-md-4 one">
                    <div className="booked-plan">
                      <div className="calender-bx-live"></div>
                      <div className="curentplain-bx">
                        <div className="plancurnt-main">
                          <h5>Current plan is</h5>
                          <h4>Personal education</h4>

                          <div className="plain-card-persl">
                            <div className="lessontime-bottom">
                              <div className="lessonnew-cardbx">
                                <div className="newlesson-top">
                                  <div className="bnewleson-heading">
                                    Plan is active till 2 Sep, 11.36 pm
                                  </div>
                                </div>
                                <div className="newlesson-clock">
                                  <ul className="newlesson-listclk">
                                    <li>
                                      <div className="clock-time">
                                        <div className="timenum">5</div>
                                        <div className="time-days">days</div>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="clock-time">
                                        <div className="timenum">14</div>
                                        <div className="time-days">hours</div>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="clock-time">
                                        <div className="timenum">37</div>
                                        <div className="time-days">minutes</div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                                <div className="btn-list-lesson">
                                  <ul className="btn-listing-lesson">
                                    <li className="whitebtn-tx">
                                      <div className="btn-buy">
                                        <div className="btn-week">
                                          <a className="btnweak-bx">
                                            <span className="btn-title">
                                              Renew subscription
                                            </span>
                                          </a>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>

                                <div className="newlesson-shape"></div>
                              </div>
                            </div>
                          </div>
                          <div className="livelessn-card">
                            <div className="child-lession">
                              <h4>Live lessons</h4>
                              <div className="lession-leftnw">
                                <p>
                                  <strong>0</strong> free lessons left
                                </p>
                                <p>
                                  <strong>2</strong> <span>/5</span> additional
                                  lessons left
                                </p>
                              </div>
                              <div className="weekduring">
                                <p className="nxtweek">during next 2 weeks</p>
                                <p className="total-bkdleson">
                                  4 booked lessons
                                </p>
                              </div>

                              <div className="morelesn-btn">
                                <div className="btn-buy">
                                  <div className="btn-week">
                                    <a className="btnweak-bx">
                                      <span className="btn-title">
                                        Buy more lessons
                                      </span>
                                    </a>
                                  </div>
                                </div>
                              </div>

                              <div className="history-pay">
                                <Link to="#" className="payment-histry-btn">
                                  History of payment
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-8 two">
                    <div className="rightprogres-main">
                      <div className="progress-cardouter-main">
                        <div className="activity-process">
                          <div className="process-cardbx">
                            <div className="title-process">
                              <h3>Activities in the process</h3>
                            </div>
                            <div className="innercard-process">
                              <div className="process-body">
                                <div className="lesson-hovrbx">
                                  <div className="newlesson-card cardlink-bxx">
                                    <div className="sesson-listmain">
                                      <div className="live-lesson-main">
                                        <div className="custom-row">
                                          <div className="lesson-leftbx">
                                            <span className="medm-tex purple-clr">
                                              Neithedu Game: Quiz
                                            </span>
                                          </div>
                                          <div className="lesson-rightbx">
                                            <span className="medm-tex date-bx">
                                              Nov 16, 2021
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="title-main">
                                        Law basics. Beginner concepts
                                      </div>
                                      <div className="lesson-list-bottom">
                                        <div className="custom-row">
                                          <div className="left-boxleson">
                                            <ul className="list-point-lesson">
                                              <li>
                                                <span className="medm-tex orng-clr">
                                                  Due March 29
                                                </span>
                                              </li>
                                              <li>
                                                <span className="medm-tex orng-clr">
                                                  0/50 points
                                                </span>
                                              </li>
                                              <li>
                                                <span className="medm-tex sky-clr">
                                                  Late{" "}
                                                </span>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="lesson-hovrbx">
                                  <div className="newlesson-card cardlink-bxx">
                                    <div className="sesson-listmain">
                                      <div className="live-lesson-main">
                                        <div className="custom-row">
                                          <div className="lesson-leftbx">
                                            <span className="medm-tex purple-clr">
                                              Neithedu Game: Wordsearch
                                            </span>
                                          </div>
                                          <div className="lesson-rightbx">
                                            <span className="medm-tex date-bx">
                                              Sep 18, 2021
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="title-main">
                                        Microorganisms in the ocean. Find 25
                                        names of the organisms
                                      </div>
                                      <div className="lesson-list-bottom">
                                        <div className="custom-row">
                                          <div className="left-boxleson">
                                            <ul className="list-point-lesson">
                                              <li>
                                                <span className="medm-tex orng-clr">
                                                  Words: 25/25
                                                </span>
                                              </li>

                                              <li>
                                                <span className="medm-tex sky-clr">
                                                  Time: 1h 43min{" "}
                                                </span>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="moreactive-btn">
                                  <div className="history-pay">
                                    <Link to="#" className="payment-histry-btn">
                                      More activities
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="progress-cardouter-main">
                        <div className="activity-process">
                          <div className="process-cardbx">
                            <div className="title-process">
                              <h3>Last news</h3>
                            </div>
                            <div className="innercard-process">
                              <div className="process-body">
                                <div className="news-card">
                                  <div className="newscard-inner">
                                    <div className="activ-detail-list">
                                      <div className="active-detail-row">
                                        <div className="activiti-prof-icon">
                                          <span className="profile-icon-box">
                                            <i className="fas fa-user"></i>
                                          </span>
                                        </div>
                                        <div className="activiti-center-detail">
                                          <h5>Jone Doe</h5>
                                          <h6>1 activity</h6>
                                        </div>
                                      </div>
                                      <div className="news-content">
                                        <div className="news-row">
                                          <div className="news-left">
                                            <div className="news-contentbx">
                                              <p>
                                                Game flow “Biology” is completed
                                              </p>
                                            </div>
                                          </div>
                                          <div className="news-right">
                                            <div className="news-report">
                                              <Link
                                                to="StudentReport"
                                                className="report-btn"
                                              >
                                                Report
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="newscard-inner">
                                    <div className="activ-detail-list">
                                      <div className="active-detail-row">
                                        <div className="activiti-prof-icon">
                                          <span className="profile-icon-box">
                                            <i className="fas fa-user"></i>
                                          </span>
                                        </div>
                                        <div className="activiti-center-detail">
                                          <h5>Alexandra Rossie</h5>
                                          <h6>05 Aug 8:49pm</h6>
                                        </div>
                                      </div>
                                      <div className="news-content">
                                        <div className="news-row">
                                          <div className="news-left">
                                            <div className="news-contentbx">
                                              <p>
                                                Congratulations! Encouraging
                                                badge for Helping others is
                                                gotten!
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="helping-other">
                                        <div className="helping-other-main">
                                          <Row>
                                            <div className="col-6 one">
                                              <div className="left-img">
                                                <div className="leftpic">
                                                  <img
                                                    src={instslide1}
                                                    className="imgfluid"
                                                    alt=""
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-6 two">
                                              <div className="right-bx-text">
                                                <div className="helping-card">
                                                  <h5>Badge for</h5>
                                                  <h4>Helping others</h4>
                                                  <div className="helping-points-list">
                                                    <ul>
                                                      <li>
                                                        <div className="point-content">
                                                          +30 points
                                                        </div>
                                                      </li>
                                                      <li>
                                                        <div className="point-content">
                                                          +4 power to
                                                          <br></br>Science card
                                                        </div>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Row>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="moreactive-btn">
                                  <div className="history-pay">
                                    <Link to="#" className="payment-histry-btn">
                                      More news
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
            </div>
          </Container>
        </div>

        {/* footer start */}
        <FooterParent logo={logo} />
        {/* footer end */}
      </div>

      {/* start add pupil modal */}

      <Addmychild
        modalAddmychildstep1Show={modalAddmychildstep1Show}
        setAddmychildstep1ModalShow={(value) =>
          setAddmychildstep1ModalShow(value)
        }
      />

      {/* end add pupil modal */}

      {/* start perosnal task aprent */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        // {...props}
        show={modalpersonaltskstep1Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2 mystudent-games persltask-form">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>
                New personal task to <br></br> Alexandra
              </h4>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setpersonaltskstep1ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="newgames-createbx">
                    <Row>
                      <div className="col-md-12">
                        <Form.Label>Title of the task</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Help your mother"
                        ></Form.Control>
                      </div>
                      <div className="col-md-12">
                        <Form.Label>Choose type of a task</Form.Label>
                        <div className="tasktype">
                          <ul className="learner-listing-games taskpurple">
                            <li>
                              <button className="btn-learner-lsit">
                                Do homework
                              </button>
                            </li>
                            <li>
                              <button className="btn-learner-lsit">
                                Do house work
                              </button>
                            </li>
                            <li>
                              <button className="btn-learner-lsit">Any</button>
                            </li>
                            <li>
                              <button className="btn-learner-lsit">
                                Another taskc
                              </button>
                            </li>
                            <li>
                              <button className="btn-learner-lsit active">
                                Custom task
                              </button>
                            </li>
                            <li>
                              <button className="btn-learner-lsit">More</button>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <Form.Label>Description </Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Describe your task"
                        />
                      </div>
                      <div className="col-md-12">
                        <Form.Label>Deadline</Form.Label>
                        <div className="datepick-frm datepicker-purple">
                          <Form.Group className="subsform-fild">
                            <Form.Control
                              type="date"
                              placeholder="From"
                              className="texblck"
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <Form.Label>
                          How many points child can earn for the task
                        </Form.Label>
                      </div>
                      <div className="col-md-4 std-point-frm">
                        <Form.Control
                          type="text"
                          placeholder="20"
                          className="texblck"
                        />
                      </div>
                      <div className="col-md-4 std-point-frm std-point-label">
                        <Form.Label>points if</Form.Label>
                      </div>
                      <div className="col-md-4 std-point-frm">
                        <Form.Control as="select" className="texblck">
                          <option value="2">completed</option>
                        </Form.Control>
                      </div>
                    </Row>
                  </div>
                </Form.Group>

                <div className="btnlogin-main taskbtn-uppr">
                  <Link
                    to="#"
                    className="btnlogin btnweak-bx"
                    onClick={() => setpersonaltskstep1ModalShow(false)}
                  >
                    Send the task to Alexandra
                  </Link>
                </div>

                <div className="btnlogin-main">
                  <Link to="#" className="btnlogin btnweak-bx">
                    Send to some children
                  </Link>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end personaltask */}
    </div>
  );
}

export default ChildProgressDetails;
