import React from "react";
import { Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

function Myprofilesidebar() {
  return (
    <div className="myprofl-sidebar">
      <ul className="profile-list-inner">
        <li>
          <Link to="Myprofile" className="profile-linking active">
            My profile
          </Link>
        </li>
        <li>
          <Link to="Subscription" className="profile-linking">
            Subscription
          </Link>
        </li>
        <li>
          <Link to="Earnings" className="profile-linking">
            Earnings
          </Link>
        </li>
        <li>
          <Link to="Security" className="profile-linking">
            Security
          </Link>
        </li>
        <li>
          <Link to="Notifications" className="profile-linking">
            Notifications
          </Link>
        </li>
        <li>
          <Link to="Support" className="profile-linking">
            Support
          </Link>
        </li>
        <li>
          <Link to="Howearn" className="profile-linking">
            How earn with Neithedu
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default Myprofilesidebar;
