import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

export const LiveLessonCreated = ({ closeHandler, activeTab, activityId }) => {
  return (
    <Modal
      className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
      show={true}
      onHide={() => {
        return false;
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="login-modal-mainbx groupmbr-step2 mystudent-games session-created">
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>{activeTab === 0 ? "Online" : "Offline"} lesson was created</h4>
            <p>
              Notice that the student should confirm the lesson. Lessons without
              confirmation aren’t active and will be cancelled in 3 days
            </p>
          </Modal.Title>
          <button type="button" onClick={closeHandler} className="close">
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="login-modl-form">
            <div className="loginform-bx">
              <div className="btnlogin-main">
                <Link
                  to={`/LiveLessonDetail/8/${activityId}`}
                  className="btnlogin btnweak-bx"
                >
                  Thanks, i understood
                </Link>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};
