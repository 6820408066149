import React from "react";
import { SnackbarProvider } from "./Snackbar";
import { NotificationProvider } from "./Notification";

const ContextStore = ({ children }) => {
  return (
    <>
      <SnackbarProvider>
        <NotificationProvider>{children}</NotificationProvider>
      </SnackbarProvider>
    </>
  );
};

export default ContextStore;
