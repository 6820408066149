import { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "../../home.scss";
import "../../profile.scss";
import "../../media.scss";
import "../../games.scss";
import "../../loginModal/login.scss";
import "../../messages/messages.scss";
import "../../myactivities.scss";
import "../../gamestabs.scss";
import "../../gamesflowphotos.scss";
import "../../selectedgames.scss";
import "../../questionone.scss";
import "../../creatingmaterial.scss";
import "../../Currentlesson.scss";
import { Container, Card } from "react-bootstrap";
import { useHistory } from "react-router";
import logo from "../../../assets/images/logo.png";
import backorange from "../../../assets/images/backorange.svg";

import { Link } from "react-router-dom";
import FooterEducator from "../../layout/Footer/FooterEducator";

import { HeaderDashboard } from "../../layout/HeaderDashboard";
import { monthNames } from "../../../utils/util";
import {
  Box,
  // CardActions,
  // FormControl,
  ListItemButton,
  // TextField,
  Typography,
} from "@mui/material";
import { DialogBox } from "../../utils/DialogBox";
import {
  CloseButton,
  StyledPurpleButton,
  StyledYellowButton,
} from "../../utils/Buttons";

const dateFormat = (time) => {
  const d = new Date(time);
  return `${monthNames[d.getMonth()]} ${d.getDate()}, ${d.toLocaleString(
    "en-US",
    { hour: "numeric", hour12: true },
  )}`;
};

const dialogs = {
  CANCEL: "CANCEL",
  REASON: "REASON",
  CANCELCONFIRM: "CANCELCONFIRM",
};
function StudentLiveLesson() {
  const history = useHistory();
  const [state, setstate] = useState({
    time: Date.now(),
    status: "Pending",
    deadline: new Date().setHours(50),
    dialog: null,
  });

  const changeDialog = (dialog) => {
    setstate((state) => ({ ...state, dialog }));
  };

  /* end edit lession */
  const DeadLine = () => {
    const [temp, settemp] = useState({});
    var dateFuture = state.deadline;
    var dateNow = new Date();

    var seconds = Math.floor((dateFuture - dateNow) / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);

    hours = hours - days * 24;
    minutes = minutes - days * 24 * 60 - hours * 60;
    seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
    useEffect(() => {
      const interval = setInterval(() => settemp({}), 1000 * 60);
      return () => {
        clearInterval(interval);
      };
    }, []);

    return (
      <div className="lession-card-timebx">
        <div className="lessontime-bottom">
          <div className="lessonnew-cardbx">
            <div className="newlesson-top">
              <div className="bnewleson-heading">Time to the lesson </div>
            </div>
            <div className="newlesson-clock">
              <ul className="newlesson-listclk">
                <li>
                  <div className="clock-time">
                    <div className="timenum">{days}</div>
                    <div className="time-days">days</div>
                  </div>
                </li>
                <li>
                  <div className="clock-time">
                    <div className="timenum">{hours}</div>
                    <div className="time-days">hours</div>
                  </div>
                </li>
                <li>
                  <div className="clock-time">
                    <div className="timenum">{minutes}</div>
                    <div className="time-days">minutes</div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="btn-list-lesson">
              <ul className="btn-listing-lesson">
                <li>
                  <div className="btn-buy">
                    <div className="btn-week">
                      <a href="#" className="btnweak-bx">
                        <span className="btn-title" onClick={() => {}}>
                          Chat with the educator
                        </span>
                      </a>
                    </div>
                  </div>
                </li>
                <li className="whitebtn-tx">
                  <div className="btn-buy">
                    <div className="btn-week">
                      <a
                        href="#"
                        className="btnweak-bx"
                        onClick={() => changeDialog(dialogs.CANCEL)}
                      >
                        <span
                          style={{ color: "var(--purple)" }}
                          className="btn-title"
                        >
                          Cancel the lesson
                        </span>
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="newlesson-shape"></div>
          </div>
        </div>
      </div>
    );
  };
  const CancelDialog = () => {
    return (
      <DialogBox isOpen={true} close={() => {}}>
        <CloseButton closeHandler={() => changeDialog(null)} />
        <Box sx={{ textAlign: "center", m: "2rem" }}>
          <Typography
            sx={{
              color: "var(--purple-dark)",
              fontWeight: "800",
              fontSize: "1.3rem",
              mb: "1rem",
            }}
          >
            Are you sure that you want to cancel the live lesson?
          </Typography>
          <Typography sx={{ color: "var(--black)" }}>
            Note, if date/time, topic, method of the lesson doesn’t suit you,
            you can discuss changing with your educator without cancellation. In
            this case educator changes data and this lesson will be provided
            when and how it is convenient for you.
          </Typography>
          <Box
            my="1rem"
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "85%",
              rowGap: "1rem",
            }}
          >
            <StyledYellowButton
              btnHandler={() => {}}
              title={"Chat with the educator"}
            />
            <StyledPurpleButton
              btnHandler={() => changeDialog(dialogs.REASON)}
              title={"I am sure, cancel the lesson"}
            />
          </Box>
        </Box>
      </DialogBox>
    );
  };

  const ReasonDialog = () => {
    const data = [
      {
        title: "I found another educator",
        id: 0,
      },
      { title: "I am not able to have lessons now", id: 1 },
      { title: "I don't like this tutor", id: 2 },
      { title: "I don't need educator for this subject more", id: 3 },
    ];
    const [otherReason, setotherReason] = useState({
      open: false,
      message: "",
    });
    const [reason, setreason] = useState({
      selected: null,
    });
    const [open, setopen] = useState(false);
    return (
      <DialogBox isOpen={true} close={() => {}}>
        <CloseButton closeHandler={() => changeDialog(null)} />
        <Box sx={{ textAlign: "center", m: "2rem" }}>
          <Typography
            sx={{
              color: "var(--purple-dark)",
              fontWeight: "800",
              fontSize: "1.3rem",
            }}
          >
            Choose a reason of cancellation
          </Typography>
          <Typography sx={{ color: "var(--black)", fontWeight: "500" }}>
            Educator will not see the reason that you choose
          </Typography>
          {data.map((e) => {
            const isSelected = reason.selected === e.id;
            return (
              <Box
                onClick={() => {
                  setotherReason((state) => ({ open: false, message: "" }));
                  setreason((state) => ({ ...state, selected: e.id }));
                }}
                key={e.id}
                sx={{ mt: "1rem" }}
              >
                <ListItemButton
                  sx={{
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: isSelected
                      ? "var(--yellow)"
                      : "var(--purple)",
                  }}
                >
                  <ArrowForwardIosIcon
                    sx={{
                      color: isSelected ? "var(--purple)" : "var(--yellow)",
                      mr: "1rem",
                    }}
                  />
                  <Typography>{e.title}</Typography>
                </ListItemButton>
              </Box>
            );
          })}
          {otherReason.open === false ? (
            <Box
              onClick={() => {
                setreason((state) => ({ ...state, selected: null }));
                setotherReason((state) => ({ ...state, open: true }));
              }}
              sx={{ mt: "1rem" }}
            >
              <Card>
                <ListItemButton
                  sx={{
                    backgroundColor: "var(--purple)",
                    display: "flex",
                    borderRadius: "10px",
                    alignItems: "center",
                  }}
                >
                  <ArrowForwardIosIcon
                    sx={{ color: "var(--yellow)", mr: "1rem" }}
                  />
                  <Typography>Other reasons</Typography>
                </ListItemButton>
              </Card>
            </Box>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                marginTop: "1rem",
                rowGap: "1rem",
              }}
            >
              <label>Other reasons</label>
              <textarea
                style={{
                  resize: "none",
                  width: "100%",
                  padding: "0.3rem",
                  borderRadius: "5px",
                }}
                rows={4}
                placeholder="Type here"
                onChange={(e) =>
                  setotherReason((state) => ({
                    ...state,
                    message: e.target.value,
                  }))
                }
                value={otherReason.message}
              />
            </div>
          )}
          <Box
            sx={{
              width: "15rem",
              mx: "auto",
              display: "flex",
              mt: "1rem",
              justifyContent: "center",
            }}
          >
            <StyledYellowButton
              btnHandler={() => {
                history.push("/student/cancelledLiveLesson");
              }}
              title="Complete the cancellation"
            />
          </Box>
        </Box>
      </DialogBox>
    );
  };

  const ConfirmCancel = () => {
    return (
      <DialogBox isOpen={true}>
        <Box
          sx={{
            display: "flex",

            flexDirection: "column",
            justifyContent: "center",
            rowGap: "1rem",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              color: "var(--purple-dark)",
              fontWeight: "800",
              fontSize: "1.3rem",
            }}
          >
            Live lesson was cancelled
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              color: "var(--black)",
              fontWeight: "500",
            }}
          >
            Educator will be notified about cancellation of the lesson. You can
            discuss new lesson in chat
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <StyledYellowButton
              btnHandler={() => changeDialog(null)}
              title="I understood"
            />
          </Box>
        </Box>
      </DialogBox>
    );
  };

  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderDashboard />

      {/* end header */}
      {state.dialog === dialogs.CANCEL && <CancelDialog />}
      {state.dialog === dialogs.REASON && <ReasonDialog />}
      {state.dialog === dialogs.CANCELCONFIRM && <ConfirmCancel />}

      <div className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs">
        <div className="cureent-lession-bx completelession-main">
          <div className="current-lesson-main ">
            <Container>
              <div className="current-lesson-contain">
                <div className="backbtn">
                  <Link to="Newlivelession" className="backbtnn-icnn">
                    <img src={backorange} alt="" />
                  </Link>
                </div>
                <div className="current-lesson-topbr">
                  <div className="medm-tex orng-clr">Live lesson</div>
                  <h1>
                    Title of the lesson for useful searching something about
                    something
                  </h1>
                  <div className="lesson-status-list">
                    <ul className="lesson-listing-curnt">
                      <li>
                        <div className="lesson-status-detail">
                          <span className="medm-tex orng-clr onelist">
                            Time:{" "}
                          </span>
                          <span className="medm-tex orng-clr twolist">
                            {dateFormat(state.time)}
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="lesson-status-detail">
                          <span className="medm-tex orng-clr onelist">
                            Status:{" "}
                          </span>
                          <span className="medm-tex orng-clr twolist">
                            {state.status}
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Container>
          </div>
          <div className="lession-time-card">
            <Container>
              <DeadLine />
            </Container>
          </div>

          <Box
            sx={{
              mb: "2rem",
              color: "var(--yellow)",
              textDecoration: "underline",
              fontWeight: "700",
              fontSize: "1.2rem",
            }}
          >
            <a>Check the previous lesson</a>
          </Box>

          <div className="pagination-lession">
            <Container>
              <div className="pagination-lession-inner">
                <ul>
                  <li className="one">
                    <Link to="#" className="pagination-btn">
                      <span className="iconn">
                        <i className="fas fa-angle-left"></i>
                      </span>
                      <span className="tex-lesn">Previous lesson</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </Container>
          </div>
        </div>

        {/* footer start */}
        <FooterEducator logo={logo} />
        {/* footer end */}
      </div>

      {/* end lession edit */}
    </div>
  );
}

export default StudentLiveLesson;
