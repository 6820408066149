// import { Container } from "react-bootstrap";
import React, { useState } from "react";
import "../student/StudentProfile/studentProfile.scss";
import "../home.scss";
import "../teachers.scss";
import "../blog/blog.scss";
import "../profile.scss";
import "../loginModal/login.scss";
import "react-toastify/dist/ReactToastify.css";

import tick from "../../assets/images/tick.png";
import { ToastContainer, toast } from "react-toastify";

import cross from "../../assets/images/cross.png";
import "../media.scss";
import { Link } from "react-router-dom";
// import "../studentProfile.scss";
import { Row } from "react-bootstrap";
import logo from "../../assets/images/logo.png";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
// import { Form, Modal } from "react-bootstrap";

import {
  // Button,
  Container,
  FormControl,
  Grid,
  // MenuItem,
  Dialog,
  DialogTitle,
  IconButton,
  // DialogContent,
  // DialogActions,
  // TextField,
  Typography,
} from "@mui/material";
import { CircularProgress } from "@mui/material";

import { styled } from "@mui/material/styles";

import { HeaderDashboard } from "../layout/HeaderDashboard";
import { Box } from "@mui/system";
import {
  createSubscription,
  getPlansForStudents,
  getPlansForStudentsByPlanId,
} from "../../service/apis/common";
import { useEffect } from "react";
import { USER_DETAILS } from "../../service/LocalStorage";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
export default function Payment(props) {
  const [open, setOpen] = useState(false);
  const [planId, setPlanId] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [plan, setPlan] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [proceed, setProceed] = useState(true);
  const [role, setRole] = useState(null);
  const [allPlans, setAllPlans] = useState(null);
  const [userId, setUserId] = useState(null);
  const [onPageLoad, setOnPageLoad] = useState(true);
  useEffect(() => {
    if (onPageLoad) {
      setPlanId(props.location.state);
      planData();
      setOnPageLoad(false);
    }
  }, [planId]);

  useEffect(() => {
    if (localStorage.getItem(USER_DETAILS)) {
      var user = localStorage.getItem(USER_DETAILS);
      var userDetails = JSON.parse(user);
      setRole(userDetails.role);
      setUserId(userDetails.applicationUser.id);
    } else {
      return;
    }
    planData();
    getAllPlansData();
  }, [planId]);
  const getAllPlansData = async () => {
    const res = await getPlansForStudents(role);
    setAllPlans(res.data);
  };

  const planData = async () => {
    setIsLoading(true);
    try {
      const res = await getPlansForStudentsByPlanId(planId);
      setPlan(res.data);
      setIsLoading(false);
      setProceed(false);
    } catch (error) {
      console.log(error, "paying");
    }
  };

  const handleClickOpen = () => {
    setModalShow(true);
  };
  const handlePayment = async (e) => {
    try {
      e.preventDefault();
      const res = await createSubscription(userId, planId, 1, 1);
      // history.push(res.shortUrl)
      // console.log(res);
      if (res.status === "created") {
        window.open(res.shortUrl, "_blank");
      }
    } catch (error) {
      toast.error(
        "There is an active subscription in place, please see your subscription status",
        {
          className: "toast-error",
        },
      );
      console.log(error.status);
      console.log(error.message, "ee");
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box
      sx={{
        height: {
          sx: "145vh",
          lg: "140vh",
          sm: "155vh",
        },
        background: "linear-gradient(147.04deg, #5081F2 27.21%, #7658DF 81.6%)",
        boxShadow: "0px -7px 59px rgb(0 0 0 / 20%)",
      }}
      className="home-wrapper"
    >
      <div>
        <HeaderDashboard />
        <div style={{ color: "#FFD300", margin: "10px", height: "auto" }}>
          <div style={{ marginTop: "48px" }}>
            <Typography color="#ffffff" fontWeight={700} fontSize="24px">
              Payment
            </Typography>
          </div>

          <ToastContainer />

          <Container
            style={{
              transformOrigin: "0 0",
              transform: "rotateX( 4deg) rotateY( 7deg)",
              boxShadow: "0px 16px 21px rgba(0, 0, 0, 0.25)",
              borderRadius: "35px",
              backgroundColor: "white",

              marginTop: "39px",
              paddingBottom: "20px",
              marginBottom: "60px",
            }}
          >
            <Grid
              container
              spacing={3.5}
              columns={12}
              sx={{ justifyContent: "space-between" }}
            >
              {/* left grid */}
              {isLoading ? (
                <CircularProgress
                  size={"1.5rem"}
                  sx={{
                    color: "var(--purple)",
                    mx: "auto",
                    mt: "200px",
                    alignItems: "center",
                  }}
                />
              ) : (
                <Grid item sm={6} lg={7} xs={12}>
                  <Box
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "90%",
                        lg: "70%",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        marginTop: "20px",
                      }}
                    >
                      <Typography
                        fontWeight={900}
                        fontSize="18px"
                        sx={{ justifyContent: "flex-start", color: "#632caf" }}
                      >
                        {plan ? plan.item.name : ""}
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "auto",
                          color: "var(--orange)",
                          fontWeight: "700",
                          fontSize: "15px",
                          fontFamily: "Mulish",
                        }}
                      >
                        {plan ? (plan.item.currency === "USD" ? "$" : "₹") : ""}
                        {plan ? plan.item.amount : ""}
                      </Typography>
                    </Box>
                    <FormControl sx={{ display: "flex", marginTop: "54.5px" }}>
                      <Typography
                        fontWeight={400}
                        fontSize="14px"
                        sx={{
                          marginTop: "30px",
                          marginRight: "auto",
                          color: "rgba(0, 0, 0, 0.6)",
                          marginBottom: "4px",
                        }}
                      >
                        Choose the term of subscription
                      </Typography>
                      <Box
                        className="term-of-subscritpion"
                        sx={{ width: "100%", marginTop: "4px" }}
                      >
                        <select
                          style={{
                            color: "var(--purple)",
                            fontSize: "14px",
                            borderRadius: "13px",
                          }}
                          onChange={(e) => {
                            setPlanId(e.target.value);
                            setProceed(true);
                          }}
                        >
                          {allPlans
                            ? allPlans.map((a) => {
                                return (
                                  <option value={a.id}>
                                    {a.item.description}
                                  </option>
                                );
                              })
                            : ""}
                        </select>
                      </Box>
                    </FormControl>

                    <Box
                      sx={{
                        fontFamily: "Mulish",
                        width: "100%",
                        marginTop: "64px",
                        display: "flex",
                      }}
                    >
                      <Typography
                        fontWeight={400}
                        fontSize="14px"
                        sx={{
                          justifyContent: "flex-start",
                          marginTop: "40px",
                          // width: "40%",
                          marginRight: "auto",
                          lineHeight: "20px",
                          color: "#632caf",
                          // color: "white",
                        }}
                      >
                        By paying, you accept the{" "}
                        <a
                          className="underline"
                          href="/"
                          style={{
                            textDecorationLine: "underline !important",
                            color: "var(--orange)",
                          }}
                        >
                          Terms of Use
                        </a>{" "}
                        and{" "}
                        <a
                          className="underline"
                          href="/"
                          style={{
                            textDecorationLine: "underline !important",
                            color: "var(--orange)",
                          }}
                        >
                          Privacy
                        </a>
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        width: "100%",
                        marginTop: "26px",
                        display: "flex",
                      }}
                    >
                      <Typography
                        fontWeight={800}
                        fontSize="18px"
                        sx={{ justifyContent: "flex-start", color: "#632caf" }}
                      >
                        Total to pay
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "auto",
                          fontSize: "24px",
                          fontWeight: "800",
                          color: "#632caf",
                        }}
                      >
                        {plan ? (plan.item.currency === "USD" ? "$" : "₹") : ""}
                        {plan ? plan.item.amount : ""}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              )}

              {/* right grid */}
              <Grid item sm={6} lg={5} xs={12} sx={{ marginTop: "20px" }}>
                <Box
                  sm={{ display: "flex" }}
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "90%",
                      lg: "60%",
                      // lg: 2000,
                    },
                    // justifyContent: "start",
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent: "flex-end",
                    marginRight: "auto",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    {isLoading ? (
                      <CircularProgress
                        size={"1.5rem"}
                        sx={{
                          color: "var(--purple)",
                          mx: "auto",
                          mt: "200px",
                          alignItems: "center",
                        }}
                      />
                    ) : (
                      <Box
                        sx={{
                          fontFamily: "Poppins",
                          // display: "flex",
                          // flexDirection: "column",
                        }}
                      >
                        <Box sx={{ width: "104%" }}>
                          <Typography
                            sx={{
                              color: "#632CAF",
                              lineHeight: "35px",
                              fontWeight: "800",
                              fontSize: "26px",
                            }}
                          >
                            {" "}
                            {plan ? plan.item.name : ""}
                          </Typography>
                        </Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            fontSize: "16px",
                            lineHeight: "26px",
                            letterSpacing: "0.09em",
                            color: "#FF7A00",
                          }}
                        >
                          {plan ? plan.item.description : ""}
                        </Typography>
                        <Box className="card-list-plan" sx={{ mt: "17px" }}>
                          <ul
                            style={{
                              fontFamily: "Poppins",
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              textAlign: "start",
                              justifyContent: "start",
                              fontWeight: "400",
                              fontSize: "12px",
                              color: "#6A64E6",
                            }}
                          >
                            <li style={{ marginBottom: "20px" }}>
                              <span className="icontick">
                                <img src={tick} className="img-fluid" alt=""/>
                              </span>
                              <span className="list-detail-plan">
                                All standard games for your year
                              </span>
                            </li>
                            <li style={{ marginBottom: "20px" }}>
                              <span className="icontick">
                                <img src={cross} className="img-fluid" alt=""/>
                              </span>
                              <span className="list-detail-plan">
                                Connection with parents
                              </span>
                            </li>
                            <li
                              className="display-flex"
                              style={{
                                dispaly: "flex",
                                marginBottom: "20px",

                                flexDirection: "row",
                              }}
                            >
                              <div
                                style={{
                                  width: "37px",
                                  height: "26px",
                                  marginTop: "6px",
                                }}
                                className="icontick"
                              >
                                <img
                                  style={{ width: "100%", height: "100%" }}
                                  src={cross}
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                              <div className="list-detail-plan">
                                Possibility to get tasks from your teacher or
                                tutor (educator)
                              </div>
                            </li>
                            <li style={{ marginBottom: "20px" }}>
                              <span className="icontick">
                                <img src={cross} className="img-fluid" alt=""/>
                              </span>
                              <span className="list-detail-plan">
                                Study with Tutor Neithedu
                              </span>
                            </li>
                            <li>
                              <span className="icontick">
                                <img src={cross} className="img-fluid" alt=""/>
                              </span>
                              <span className="list-detail-plan">
                                Free live lessons with educators
                              </span>
                            </li>
                          </ul>
                        </Box>
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{
                      // marginTop: "106px",
                      display: "flex",
                      width: {
                        sm: "93%",
                        lg: "88%",
                        xs: "80%",
                      },
                      textAlign: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "243px",
                        display: "flex",
                        marginTop: "6px",
                        marginLeft: "39px",
                        // justifyContent: "space-around",
                      }}
                    >
                      <button
                        style={{
                          width: "100%",
                          border: "0px",
                        }}
                        disabled={proceed}
                        onClick={handlePayment}
                        className=" btnlogin btnweak-bx"
                      >
                        Pay
                      </button>
                    </Box>

                    {/* thank you dialog  starts*/}
                    {/* <Modal
                      className="loginmodal"
                      // {...props}
                      show={modalShow}
                      onHide={() => {
                        setModalShow(false);
                        return false;
                      }}
                      size="lg"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <div
                        style={{ padding: "10px" }}
                        className="login-modal-mainbx"
                      >
                        <Modal.Header>
                          <Modal.Title id="contained-modal-title-vcenter">
                            <h4 style={{ marginTop: "-42px" }}>Thank You!</h4>
                            <p style={{ fontSize: "22px" }}>
                              Payment successful
                            </p>
                          </Modal.Title>
                          <button
                            type="button"
                            onClick={() => setModalShow(false)}
                            className="close"
                          >
                            <span aria-hidden="true">×</span>
                            <span className="sr-only">Close</span>
                          </button>
                        </Modal.Header>
                        <Modal.Body
                          style={{
                            textAlign: "center",
                            width: "100%",
                          }}
                        >
                          <div style={{ width: "80%", margin: "0 auto" }}>
                            <div className="login-modl-form">
                              {" "}
                              An automatic payment receipt will be sent to your
                              registered email
                            </div>
                            <div style={{ marginTop: "15px" }}>
                              <Link style={{ color: "#333333" }} to="/">
                                Back to home
                              </Link>
                            </div>
                          </div>
                        </Modal.Body>
                      </div>
                    </Modal> */}
                    {/* thank you modal ends */}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>

      {/* footer starts */}
      <div className="footer-main">
        <div className="custome-container">
          <div className="footer-section">
            <div className="footershape-bg"></div>
            <Row>
              <div className="col-md-5 one">
                <div className="foter-logo">
                  <Link to="/">
                    <img src={logo} className="img-fluid" alt=""/>
                  </Link>
                </div>
              </div>
              <div className="col-md-7 two">
                <div className="foter-list">
                  <div className="foter-listnew">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="#">How it works</Link>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <Link to="#">Plans</Link>
                      </li>
                      <li>
                        <Link to="#">Games</Link>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <Link to="#">For parents</Link>
                      </li>
                      <li>
                        <Link to="/tutor">For teachers</Link>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <Link to="/blog">Blog</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contacts</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Row>
          </div>

          <div className="foter-copywrite">
            <ul>
              <li>1999-2021 Neithedu.</li>
              <li>All Rights Reserved</li>
            </ul>
          </div>
        </div>
      </div>
      {/* footer ends */}
    </Box>
  );
}
