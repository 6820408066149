import { Axios } from "../Axios";

// GET functions

export const getEducatorStudents = async () => {
  const res = await Axios.get("/educators/students");
  return res.data;
};

export const getEducatorGroups = async () => {
  const res = await Axios.get("/educators/groups");
  return res.data;
};

export const getEducatorStuGrp = async (
  activityToPerformId,
  activityTypeId,
) => {
  const res = await Axios.get(
    `/educators/educator-students-group/${activityToPerformId}/${activityTypeId}`,
  );
  return res.data;
};

export const checkGroupExist = async (groupName = "") => {
  return (await Axios.get("/educators/groups/exists/" + groupName)).data;
};

export const searchEducatorStudents = async (query) => {
  const res = await Axios.get("/educators/students/" + query);
  return res.data;
};

export const searchEducatorStudentsNameEmail = async (query) => {
  const res = await Axios.get(
    "/educators/search-students-by-name-email/" + query,
  );
  return res.data;
};

// POST functions

export const addManyStudents = async (studentsList) => {
  const res = await Axios.post(
    "/educators/students/many",
    studentsList.map((studId) => ({ exists: true, studentId: studId })),
  );
  return res.data;
};

export const addEducatorGroup = async (groupName, selectedStudents) => {
  // console.log(groupName, selectedStudents)
  const res = await Axios.post("/educators/groups", {
    groupName,
    details: selectedStudents.map((student) => ({ studentId: student })),
  });
  return res.data;
};

export const addStudentsInGroup = async (groupId, studentIDs) => {
  // console.log(studentIDs)
  const res = await Axios.post("educators/groups/add-students-in-group", {
    groupId: groupId,
    studentIDs: studentIDs,
  });

  return res.data;
};

export const sendInvitationToStudent = async (studentEmail, educatorId) => {
  const res = await Axios.get(
    `/Invitations/invite-students-to-neithedu/${studentEmail}`,
  );
  return res.data;
};

export const updateEducatorProfile = async (
  firstName,
  lastName,
  userId,
  countryId,
  cityId,
  genderId,
  startStudentGrade,
  endStudentGrade,
  yearsOfBirth,
  workExperience,
) => {
  console.log(firstName, "firstname");
  console.log(lastName, "lastName");
  console.log(userId, "userId");
  console.log(countryId, "countryId");
  console.log(cityId, "cityId");
  console.log(genderId, "genderId");
  console.log(startStudentGrade, "startStudentGrade");
  console.log(endStudentGrade, "endStudentGrade");
  console.log(yearsOfBirth, " yearsOfBirth,");
  console.log(workExperience, "workExperience");

  const res = await Axios.post("/profile/update/educator", {
    // firstName,
    // lastName,
    // userId,
    // countryId,
    // cityId,
    // genderId,
    // startStudentGrade,
    // endStudentGrade,
    // yearsOfBirth,
    // workExperience,
    firstName: firstName,
    lastName: lastName,
    countryId: countryId,
    cityId: cityId,
    genderId: 1,
    languageOfAccountId: 0,
    userId: userId,
    startStudentGrade: 1,
    endStudentGrade: 5,
    yearsOfBirth: 1990,
    workExperience: 5,
    educatorCategories: [0],
  });

  console.log(res + "heloooooooooooooooooo");

  return res;
};

export const updatePrantProfile = async (
  firstName,
  lastName,
  countryId,
  cityId,
  genderId,
) => {
  console.log(firstName, "firstname");
  console.log(lastName, "lastName");
  console.log(countryId, "countryId");
  console.log(cityId, "cityId");
  console.log(genderId, "genderId");

  const res = await Axios.post("/profile/update/parent", {
    // firstName,
    // lastName,
    // userId,
    // countryId,
    // cityId,
    // genderId,
    // startStudentGrade,
    // endStudentGrade,
    // yearsOfBirth,
    // workExperience,
    firstName: firstName,
    lastName: lastName,
    countryId: countryId,
    cityId: cityId,
    genderId: 1,
    languageOfAccountId: 0,
    childEmails: ["Demo@gmail.com"],
    // firstName: firstName,
    // lastName: lastName,
    // countryId: countryId,
    // cityId: cityId,
    // genderId: genderId,
  });

  return res;
};

// PUT functions

export const addStudentToGroup = async (studentIds, groupId, groupName) => {
  // console.log("group idi ", studentIds, groupId, groupName);
  const res = await Axios.put("/educators/groups", {
    id: groupId,
    groupName,
    details: studentIds.map((id) => ({ studentId: id })),
  });

  return res.data;
};

// DELETE functions

export const deleteEducatorStudentFromGroup = async (groupId, studentId) => {
  return (await Axios.delete(`/educators/groups/${groupId}/${studentId}`)).data;
};

export const deleteEducatorStudent = async (studentId) => {
  return (await Axios.delete("/educators/students/" + studentId)).data;
};

export const deleteEducatorGroup = async (groupId) => {
  // console.log("grou => ", groupId )
  return (await Axios.delete("/educators/groups/" + groupId)).data;
};

export const createNewTask = async ({
  title = "",
  typeId = "",
  description = "",
  taskPoints = [
    {
      status: "",
      points: 0,
    },
  ],
}) => {
  return (
    await Axios.post("/tasks", {
      title,
      typeId,
      description,
      taskPoints,
    })
  ).data;
};

export const EducatorAPI = {};
