import React from "react";
import "./home.scss";
import "./teachers.scss";
import "./blog/blog.scss";
import "./profile.scss";
import "./loginModal/login.scss";
import "./media.scss";
import { Container, Row, Form, Modal } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import logo from "../assets/images/logo.png";
import facebook from "../assets/images/facebook.svg";
import linkedin from "../assets/images/linkedin.svg";
import google from "../assets/images/google-plus.svg";
import gift from "../assets/images/gift.svg";
import plus from "../assets/images/plus.svg";
import count1 from "../assets/images/count1.svg";
import count2 from "../assets/images/count2.svg";
import flag from "../assets/images/flag.svg";

import profilepic from "../assets/images/profile-img.png";
import { Link } from "react-router-dom";
import { HeaderDashboard } from "./layout/HeaderDashboard";
import { LocalStorage } from "../service/LocalStorage";

function Profile() {
  const [modalShow, setModalShow] = React.useState(false);
  const [user, setUser] = React.useState({});
  React.useEffect(() => {
    const userInfo = LocalStorage.getUserDetails();
    if (userInfo) {
      setUser(userInfo);
    }
  }, []);
  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderDashboard />
      {/* end header */}
      <div className="profilesection">
        {/* mobile view topbar filter */}
        <div className="filter-bartop">
          <div className="filterbar-container">
            <div className="filterbar-list">
              <ul className="header-btn-top profile-menubar">
                <li className="btn-bx-get mobile-show-only">
                  <div className="count-bar">1356</div>
                </li>
                <li className="btn-bx-get mobile-show-only">
                  <div className="count-bar">0</div>
                </li>
                <li className="btn-bx-get mobile-show-only">
                  <Link to="#" className="gift-ico">
                    {" "}
                    <img src={plus} className="img-fluid" />
                  </Link>
                </li>
                <li className="btn-bx-get mobile-show-only">
                  <Link to="#" className="gift-ico">
                    {" "}
                    <img src={gift} className="img-fluid" />
                  </Link>
                </li>
                <li className="btn-bx-get mobile-show-only">
                  <Link to="#" className="gift-ico">
                    {" "}
                    <img src={count1} className="img-fluid" />
                  </Link>
                </li>
                <li className="btn-bx-get mobile-show-only">
                  <Link to="#" className="gift-ico">
                    {" "}
                    <img src={count2} className="img-fluid" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* mobile view topbar filter end*/}
        <Container>
          <Tabs>
            <Row>
              <div className="col-md-5">
                <div className="profile-tabs">
                  <div className="profileshape-bg"></div>
                  <div className="profile-pic">
                    <div className="profile-image">
                      {" "}
                      <img src={profilepic} className="img-fluid" />{" "}
                    </div>
                  </div>
                  <TabList>
                    <Tab>My profile</Tab>
                    <Tab>Subscription</Tab>
                    <Tab>Reports</Tab>
                    <Tab>Requests</Tab>
                    <Tab>Change the password</Tab>
                    <Tab>Notifications</Tab>
                    <Tab>Settings</Tab>
                  </TabList>
                </div>
              </div>
              <div className="col-md-7">
                <TabPanel>
                  <div className="tab-heading">My profile</div>
                  <div className="profile-form-section">
                    <form>
                      <ul>
                        <li>
                          <label>My name is *</label>
                          <Form.Group className="subsform-fild">
                            <Form.Control
                              type="text"
                              placeholder="Name"
                              value={user?.name}
                            />
                          </Form.Group>
                        </li>
                        <li>
                          <label>My email is *</label>
                          <Form.Group className="subsform-fild">
                            <Form.Control
                              type="text"
                              placeholder="EMAIL"
                              value={user?.email}
                            />
                          </Form.Group>
                        </li>
                        <li>
                          <label>My mobile phone is *</label>
                          <div className="phone-bar">
                            <div className="country-cd">
                              <img src={flag} className="img-fluid" />
                            </div>
                            <div className="number-fld">
                              <Form.Group className="subsform-fild">
                                <Form.Control
                                  type="text"
                                  placeholder="Phone Number"
                                  value={user?.phone}
                                />
                              </Form.Group>
                            </div>
                          </div>
                        </li>
                        <li>
                          <label>My year of study *</label>
                          <Form.Control as="select">
                            <option>5th year </option>
                          </Form.Control>
                        </li>
                        <li>
                          <label>I live in *</label>
                          <Row>
                            <div className="col-md-6">
                              <Form.Control as="select">
                                <option>India</option>
                              </Form.Control>
                            </div>
                            <div className="col-md-6">
                              <Form.Control as="select" className="city-select">
                                <option>Mumbai</option>
                              </Form.Control>
                            </div>
                          </Row>
                        </li>
                        <li>
                          <div className="btn-week">
                            <Link to="" className="btnweak-bx">
                              <span className="btn-title">Save</span>
                            </Link>
                          </div>
                        </li>
                      </ul>
                    </form>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="tab-heading">Subscription</div>
                </TabPanel>
                <TabPanel>
                  <div className="tab-heading">Reports</div>
                </TabPanel>
                <TabPanel>
                  <div className="tab-heading">Requests</div>
                </TabPanel>
                <TabPanel>
                  <div className="tab-heading">Change the password</div>
                </TabPanel>
                <TabPanel>
                  <div className="tab-heading">Notifications</div>
                </TabPanel>
                <TabPanel>
                  <div className="tab-heading">Settings</div>
                </TabPanel>
              </div>
            </Row>
          </Tabs>
        </Container>
      </div>

      <div className="testimaonial-section subscribe-blog">
        <div className="subs-blog">
          <Container>
            <div className="subscribe-bxx">
              <div className="communty-box">
                <div className="communty-section">
                  <div className="heading-top">
                    <h4 className="title-medium">Linked social networks</h4>
                  </div>
                  <div className="communtiy-content">
                    <p className="small-tex">
                      Add your social media accounts. You will be able to log
                      into Neithedu using any of them.
                    </p>

                    <div className="social-media">
                      <ul>
                        <li>
                          <Link to="#">
                            <img src={google} className="img-fluid" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <img src={facebook} className="img-fluid" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <img src={linkedin} className="img-fluid" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>

        <div className="footer-main">
          <div className="custome-container">
            <div className="footer-section">
              <div className="footershape-bg"></div>
              <Row>
                <div className="col-md-5 one">
                  <div className="foter-logo">
                    <Link to="/">
                      <img src={logo} className="img-fluid" />
                    </Link>
                  </div>
                </div>
                <div className="col-md-7 two">
                  <div className="foter-list">
                    <div className="foter-listnew">
                      <ul>
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>
                          <Link to="#">How it works</Link>
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <Link to="#">Plans</Link>
                        </li>
                        <li>
                          <Link to="#">Games</Link>
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <Link to="#">For parents</Link>
                        </li>
                        <li>
                          <Link to="/tutor">For teachers</Link>
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <Link to="/blog">Blog</Link>
                        </li>
                        <li>
                          <Link to="/contact">Contacts</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Row>
            </div>

            <div className="foter-copywrite">
              <ul>
                <li>1999-2021 Neithedu.</li>
                <li>All Rights Reserved</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* <Button variant="primary" onClick={() => setModalShow(true)}>
       login
      </Button> */}
      <Modal
        className="loginmodal"
        // {...props}
        show={modalShow}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Log in</h4>
              <p>Please, enter your email and password</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <Form className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" />
                </Form.Group>

                <Form.Group className="subsform-fild">
                  <Form.Label className="forg-texfield">
                    <span className="label-bx">Password</span>
                    <span className="forget-tex">
                      <Link to="#" className="title-med">
                        Forgot the password?
                      </Link>
                    </span>
                  </Form.Label>
                  <Form.Control type="password" />
                </Form.Group>

                <div className="have-account">
                  <Link to="#" className="title-med">
                    I don’t have account yet
                  </Link>
                </div>

                <div className="btnlogin-main">
                  <button type="submit" className="btnlogin btnweak-bx">
                    Login in
                  </button>
                </div>
              </Form>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="social-media-detail">
            <h5>Or log in by social media</h5>
            <div className="social-media">
              <ul>
                <li>
                  <a>
                    <img src={google} className="img-fluid" />
                  </a>
                </li>
                <li>
                  <a>
                    <img src={facebook} className="img-fluid" />
                  </a>
                </li>
                <li>
                  <a>
                    <img src={linkedin} className="img-fluid" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Profile;
