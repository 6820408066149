import React from "react";
import "./home.scss";
import "./profile.scss";
import "./media.scss";
import "./games.scss";
import "./loginModal/login.scss";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

const SignUpOptions = ({
  openLoginModal,
  setModalSignupShow,
  modalSignupShow,
  setStudentModalShow,
  setParentModalShow,
  setEducatorModalShow,
}) => {
  // console.log(modalSignupShow, "modalSignupShow");
  return (
    <Modal
      className="loginmodal signup-box"
      // {...props}
      show={modalSignupShow}
      onHide={() => {
        setModalSignupShow(false);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="login-modal-mainbx">
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>Sign up</h4>
            <p>Registration will just take 60 seconds!</p>
            <p>Please chose your role in the system</p>
          </Modal.Title>
          <button
            type="button"
            onClick={() => setModalSignupShow(false)}
            className="close"
          >
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="signup-popup-list">
            <div className="signup-main-bx">
              <Link
                to="#"
                className="signup-student"
                onClick={() => {
                  setStudentModalShow(true);
                  setModalSignupShow(false);
                }}
              >
                <div className="signup-cardbx">
                  <div className="custom-row">
                    <div className="left-bx">
                      <div className="card-signup-new">
                        <div className="title-sml">
                          <i className="fas fa-angle-right"></i> Student
                        </div>
                      </div>
                    </div>
                    <div className="right-content-signup">
                      <p>
                        You study at school and your goal is learn subjects with
                        Neithedu’s help
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="signup-main-bx">
              <Link
                to="#"
                className="signup-student"
                onClick={() => {
                  setEducatorModalShow(true);
                  setModalSignupShow(false);
                }}
              >
                <div className="signup-cardbx">
                  <div className="custom-row">
                    <div className="left-bx">
                      <div className="card-signup-new">
                        <div className="title-sml">
                          <i className="fas fa-angle-right"></i> Educator
                        </div>
                      </div>
                    </div>
                    <div className="right-content-signup">
                      <p>
                        You are a teacher or a tutor and your goal is to teach
                        with Neithedu’s help
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="signup-main-bx">
              <Link
                to="#"
                className="signup-student"
                onClick={() => {
                  setParentModalShow(true);
                  setModalSignupShow(false);
                }}
              >
                <div className="signup-cardbx">
                  <div className="custom-row">
                    <div className="left-bx">
                      <div className="card-signup-new">
                        <div className="title-sml">
                          <i className="fas fa-angle-right"></i> Parent
                        </div>
                      </div>
                    </div>
                    <div className="right-content-signup">
                      <p>
                        Your children are registered as students in Neithedu and
                        your goal is to check their progress
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="have-account">
              <div
                onClick={() => {
                  openLoginModal();
                  setModalSignupShow();
                }}
                style={{ textDecoration: "underline", cursor: "pointer" }}
                className="title-med"
              >
                I already have account
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default SignUpOptions;
