import React from "react";
import "../home.scss";
import "../profile.scss";
import "../media.scss";
import "../games.scss";
import "../loginModal/login.scss";
import "../messages/messages.scss";
import "../myactivities.scss";
import { Container, Dropdown, Button, Form } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import logo from "../../assets/images/logo.png";
import palinblue from "../../assets/images/palin-blue.svg";
import mike from "../../assets/images/mike.svg";
import attachemtnblue from "../../assets/images/attachemtn-blue.svg";

import { Link } from "react-router-dom";
import FooterEducator from "../layout/Footer/FooterEducator";
import Addgroup from "../Addgroup";
import Addpupil from "../Addpupil";
import SendInvite from "../SendInvite";
import Groupmember from "../Groupmember";
import HeaderGames from "../layout/Header/HeaderGames";

function Studentchat() {
  const [modalShow, setModalShow] = React.useState(false);
  /* end modal */

  /* satrt add pupil modal */
  /* start Eductor signup step1 */
  const [modalAddgroupstep1Show, setAddgroupstep1ModalShow] =
    React.useState(false);
  /* end Eductor signup step1 */

  /* start Eductor signup step1 */
  const [modalEducatorstep1Show, setEducatorstep1ModalShow] =
    React.useState(false);
  /* end Eductor signup step1 */

  /* start Send Invite step1 */
  const [modalinvitestep1Show, setinvitestep1ModalShow] = React.useState(false);
  /* end Send Invitestep1 */

  /* start group member step1 */
  const [modalgroupmemberstep1Show, setgroupmemberstep1ModalShow] =
    React.useState(false);
  /* end group member */

  /* end add pupil modal */

  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderGames setModalShow={(value) => setModalShow(value)} />
      {/* end header */}

      <div className="games-dashboard-main dashboard-bgnew blur-bgg educator-home">
        <div className="games-topbread">
          <Container>
            <div className="diversity-box student-chat-main">
              <div className="myactivites-main my-messagerm-chat">
                <div className="myactivites-contain">
                  <Tabs>
                    <div className="activies-row">
                      <div className="left-pannel">
                        <div className="leftbx-activites">
                          <div className="acti-left-detail">
                            <div className="puil-mainbx">
                              <div className="add-pupil">
                                <div className="pupil-dropdwn">
                                  <Dropdown className="dropdown-main dropmenu-custlistt">
                                    <Dropdown.Toggle
                                      variant="success"
                                      id="dropdown-basic"
                                      className="dropdown-btnn"
                                    >
                                      <i className="fas fa-plus"></i>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className="dropdown-btn-item customdrop-listmenu">
                                      <Dropdown.Item
                                        href="#"
                                        onClick={() => {
                                          setEducatorstep1ModalShow(true);
                                        }}
                                      >
                                        <span className="rightarrow-icon">
                                          <i className="fas fa-angle-right"></i>
                                        </span>{" "}
                                        Add a pupil
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        href="#"
                                        onClick={() => {
                                          setAddgroupstep1ModalShow(true);
                                        }}
                                      >
                                        <span className="rightarrow-icon">
                                          <i className="fas fa-angle-right"></i>
                                        </span>{" "}
                                        Add a new group
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        href="#"
                                        onClick={() => {
                                          setinvitestep1ModalShow(true);
                                        }}
                                      >
                                        <span className="rightarrow-icon">
                                          <i className="fas fa-angle-right"></i>
                                        </span>{" "}
                                        Invite to Neithedu
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                              <div className="serchfiled-activities">
                                <Form.Control
                                  type="text"
                                  placeholder="Search"
                                  className="formbox"
                                />
                                <Button type="button" className="seachbtn">
                                  <i className="fas fa-search"></i>
                                </Button>
                              </div>
                            </div>
                            <div className="activities-list-left">
                              <TabList className="tabslist-activiti">
                                <Tab className="tabslist-activiti-tab">
                                  <div className="activ-detail-list">
                                    <div className="active-detail-row">
                                      <div className="activiti-prof-icon">
                                        <span className="profile-icon-box">
                                          <i className="fas fa-user"></i>
                                        </span>
                                      </div>
                                      <div className="activiti-center-detail">
                                        <h5>Tutor name</h5>
                                        <h6>1 activity</h6>
                                      </div>
                                      <div className="activiti-count-mesg">
                                        <span className="count-num-activiti">
                                          1
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </Tab>
                                <Tab className="tabslist-activiti-tab">
                                  <div className="activ-detail-list">
                                    <div className="active-detail-row">
                                      <div className="activiti-prof-icon">
                                        <span className="profile-icon-box">
                                          <i className="fas fa-user"></i>
                                        </span>
                                      </div>
                                      <div className="activiti-center-detail">
                                        <h5>7F Biology Class</h5>
                                        <h6>2 activity</h6>
                                      </div>
                                      <div className="activiti-count-mesg">
                                        <span className="count-num-activiti">
                                          9
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </Tab>
                                <Tab className="tabslist-activiti-tab">
                                  <div className="activ-detail-list">
                                    <div className="active-detail-row">
                                      <div className="activiti-prof-icon">
                                        <span className="profile-icon-box">
                                          <i className="fas fa-user"></i>
                                        </span>
                                      </div>
                                      <div className="activiti-center-detail">
                                        <h5>Anna Ramadan</h5>
                                        <h6>3 activity</h6>
                                      </div>
                                      <div className="activiti-count-mesg">
                                        <span className="count-num-activiti">
                                          3
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </Tab>
                                <Tab className="tabslist-activiti-tab">
                                  <div className="activ-detail-list">
                                    <div className="active-detail-row">
                                      <div className="activiti-prof-icon">
                                        <span className="profile-icon-box">
                                          <i className="fas fa-user"></i>
                                        </span>
                                      </div>
                                      <div className="activiti-center-detail">
                                        <h5>Ruslana Kapture</h5>
                                        <h6>1 activity</h6>
                                      </div>
                                    </div>
                                  </div>
                                </Tab>
                              </TabList>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="right-panel">
                        <div className="activities-rightbx">
                          <TabPanel className="tabslist-content-act">
                            <div className="myacti-listtabs educator-lsttabs-new">
                              <div className="chat-headerbar">
                                <div className="chat-topbar-list">
                                  <div className="activ-detail-list">
                                    <div className="active-detail-row">
                                      <div className="activiti-prof-icon">
                                        <span className="profile-icon-box">
                                          <i className="fas fa-user"></i>
                                        </span>
                                      </div>
                                      <div className="activiti-center-detail">
                                        <h5>Maxine Doe</h5>
                                        <span
                                          className="group-member grpmember-btn"
                                          onClick={() => {
                                            setgroupmemberstep1ModalShow(true);
                                          }}
                                        >
                                          22 members
                                        </span>
                                        <h6>3 activities in progress</h6>
                                      </div>
                                      <div className="activiti-count-mesg">
                                        <div className="chat-right-droplist">
                                          <ul className="list-chatbx">
                                            <li className="chat-dropright educ-acvt">
                                              <div className="droplist-right-btn">
                                                <div className="dropdown-main">
                                                  <Link
                                                    to="Messages"
                                                    className="dropdown-btnn addactv-btndrp"
                                                  >
                                                    Activities
                                                  </Link>
                                                </div>
                                              </div>
                                            </li>

                                            <li className="chat-dropright edu-lst-drp">
                                              <div className="droplist-right-btn">
                                                <Dropdown className="dropdown-main">
                                                  <Dropdown.Toggle
                                                    variant="success"
                                                    id="dropdown-basic"
                                                    className="dropdown-btnn"
                                                  >
                                                    <i className="fas fa-ellipsis-v"></i>
                                                  </Dropdown.Toggle>

                                                  <Dropdown.Menu className="dropdown-btn-item">
                                                    <Dropdown.Item href="#">
                                                      <span className="rightarrow-icon">
                                                        <i className="fas fa-angle-right"></i>
                                                      </span>{" "}
                                                      All files
                                                    </Dropdown.Item>
                                                    <Dropdown.Item href="#">
                                                      <span className="rightarrow-icon">
                                                        <i className="fas fa-angle-right"></i>
                                                      </span>{" "}
                                                      Notifications
                                                    </Dropdown.Item>
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="Message-chat-room">
                                <div className="chatbox-room">
                                  <div className="chat-innerbox">
                                    <div className="chat-upperbox">
                                      <div className="custom-row">
                                        <div className="chatbox-inner chat-sender">
                                          <div className="message-rom-iner">
                                            <div className="message-time">
                                              <span className="time">
                                                11.05
                                              </span>
                                            </div>
                                            <div className="message-box">
                                              <div className="message-tex">
                                                You have new game “Lorem ipsum
                                                dolor sit amet, consectetur
                                                adipiscing elit, sed do eiusmod
                                                tempor incididunt ut labore et
                                                dolore magna aliqua. Ut enim ad
                                                minim veniam”
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="chatbox-inner chat-receiver">
                                          <div className="message-rom-iner">
                                            <div className="message-box">
                                              <div className="message-tex">
                                                Lorem ipsum dolor sit amet,
                                                consectetur adipiscing elit, sed
                                                do eiusmod tempor incididunt ut
                                                labore et dolore magna aliqua.
                                                Ut enim ad minim veniam
                                              </div>
                                            </div>
                                            <div className="message-time">
                                              <span className="time">
                                                11.05
                                              </span>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="chatbox-inner chat-sender">
                                          <div className="message-rom-iner">
                                            <div className="message-time">
                                              <span className="time">
                                                11.05
                                              </span>
                                            </div>
                                            <div className="message-box">
                                              <div className="message-tex">
                                                You have new game “Lorem ipsum
                                                dolor sit amet, consectetur
                                                adipiscing elit, sed do eiusmod
                                                tempor incididunt ut labore et
                                                dolore magna aliqua. Ut enim ad
                                                minim veniam”
                                              </div>
                                              <div className="game-opn">
                                                <Link
                                                  to="#"
                                                  className="btnweak-bx gameopn-btn"
                                                >
                                                  Open the game
                                                </Link>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="chatbox-inner chat-receiver">
                                          <div className="message-rom-iner">
                                            <div className="message-box">
                                              <div className="message-tex">
                                                Lorem ipsum dolor sit amet,
                                                consectetur adipiscing elit, sed
                                                do eiusmod tempor incididunt ut
                                                labore et dolore magna aliqua.
                                                Ut enim ad minim veniam
                                              </div>
                                            </div>
                                            <div className="message-time">
                                              <span className="time">
                                                11.05
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="chat-footer">
                                  <div className="chat-footer-main">
                                    <div className="chat-footer-message">
                                      <div className="message-send-new">
                                        <Form.Control
                                          as="textarea"
                                          className="message-textarea"
                                          placeholder="Your message"
                                        />
                                        <div className="right-chaticon">
                                          <ul>
                                            <li>
                                              <button className="sendme-btn ">
                                                <img
                                                  src={palinblue}
                                                  className="img-fluid"
                                                  alt=""
                                                />
                                              </button>
                                            </li>
                                            <li>
                                              <button className="attachment-btn">
                                                <img
                                                  src={mike}
                                                  className="img-fluid"
                                                  alt=""
                                                />
                                              </button>
                                            </li>
                                            <li>
                                              <button className="attachment-btn">
                                                <img
                                                  src={attachemtnblue}
                                                  className="img-fluid"
                                                  alt=""
                                                />
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel className="tabslist-content-act">
                            <div className="myacti-listtabs educator-lsttabs-new">
                              <div className="chat-headerbar">
                                <div className="chat-topbar-list">
                                  <div className="activ-detail-list">
                                    <div className="active-detail-row">
                                      <div className="activiti-prof-icon">
                                        <span className="profile-icon-box">
                                          <i className="fas fa-user"></i>
                                        </span>
                                      </div>
                                      <div className="activiti-center-detail">
                                        <h5>Maxine Doe</h5>
                                        <h6>3 activities in progress</h6>
                                      </div>
                                      <div className="activiti-count-mesg">
                                        <div className="chat-right-droplist">
                                          <ul className="list-chatbx">
                                            <li className="chat-dropright educ-acvt">
                                              <div className="droplist-right-btn">
                                                <div className="dropdown-main">
                                                  <Link
                                                    to="Messages"
                                                    className="dropdown-btnn addactv-btndrp"
                                                  >
                                                    Activities
                                                  </Link>
                                                </div>
                                              </div>
                                            </li>

                                            <li className="chat-dropright edu-lst-drp">
                                              <div className="droplist-right-btn">
                                                <Dropdown className="dropdown-main">
                                                  <Dropdown.Toggle
                                                    variant="success"
                                                    id="dropdown-basic"
                                                    className="dropdown-btnn"
                                                  >
                                                    <i className="fas fa-ellipsis-v"></i>
                                                  </Dropdown.Toggle>

                                                  <Dropdown.Menu className="dropdown-btn-item">
                                                    <Dropdown.Item href="#">
                                                      <span className="rightarrow-icon">
                                                        <i className="fas fa-angle-right"></i>
                                                      </span>{" "}
                                                      All files
                                                    </Dropdown.Item>
                                                    <Dropdown.Item href="#">
                                                      <span className="rightarrow-icon">
                                                        <i className="fas fa-angle-right"></i>
                                                      </span>{" "}
                                                      Notifications
                                                    </Dropdown.Item>
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="Message-chat-room">
                                <div className="chatbox-room">
                                  <div className="chat-innerbox">
                                    <div className="chat-upperbox">
                                      <div className="custom-row">
                                        <div className="chatbox-inner chat-sender">
                                          <div className="message-rom-iner">
                                            <div className="message-time">
                                              <span className="time">
                                                11.05
                                              </span>
                                            </div>
                                            <div className="message-box">
                                              <div className="message-tex">
                                                You have new game “Lorem ipsum
                                                dolor sit amet, consectetur
                                                adipiscing elit, sed do eiusmod
                                                tempor incididunt ut labore et
                                                dolore magna aliqua. Ut enim ad
                                                minim veniam”
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="chatbox-inner chat-receiver">
                                          <div className="message-rom-iner">
                                            <div className="message-box">
                                              <div className="message-tex">
                                                Lorem ipsum dolor sit amet,
                                                consectetur adipiscing elit, sed
                                                do eiusmod tempor incididunt ut
                                                labore et dolore magna aliqua.
                                                Ut enim ad minim veniam
                                              </div>
                                            </div>
                                            <div className="message-time">
                                              <span className="time">
                                                11.05
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="chatbox-inner chat-receiver">
                                          <div className="message-rom-iner">
                                            <div className="message-box">
                                              <div className="message-tex">
                                                Lorem ipsum dolor sit amet,
                                                consectetur adipiscing elit, sed
                                                do eiusmod tempor incididunt ut
                                                labore et dolore magna aliqua.
                                                Ut enim ad minim veniam
                                              </div>
                                            </div>
                                            <div className="message-time">
                                              <span className="time">
                                                11.05
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="chat-footer">
                                  <div className="chat-footer-main">
                                    <div className="chat-footer-message">
                                      <div className="message-send-new">
                                        <Form.Control
                                          as="textarea"
                                          className="message-textarea"
                                          placeholder="Your message"
                                        />
                                        <div className="right-chaticon">
                                          <ul>
                                            <li>
                                              <button className="sendme-btn ">
                                                <img
                                                  src={palinblue}
                                                  className="img-fluid"
                                                  alt=""
                                                />
                                              </button>
                                            </li>
                                            <li>
                                              <button className="attachment-btn">
                                                <img
                                                  src={mike}
                                                  className="img-fluid"
                                                  alt=""
                                                />
                                              </button>
                                            </li>
                                            <li>
                                              <button className="attachment-btn">
                                                <img
                                                  src={attachemtnblue}
                                                  className="img-fluid"
                                                  alt=""
                                                />
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel className="tabslist-content-act">
                            <div className="myacti-listtabs educator-lsttabs-new">
                              <div className="chat-headerbar">
                                <div className="chat-topbar-list">
                                  <div className="activ-detail-list">
                                    <div className="active-detail-row">
                                      <div className="activiti-prof-icon">
                                        <span className="profile-icon-box">
                                          <i className="fas fa-user"></i>
                                        </span>
                                      </div>
                                      <div className="activiti-center-detail">
                                        <h5>Maxine Doe</h5>
                                        <h6>3 activities in progress</h6>
                                      </div>
                                      <div className="activiti-count-mesg">
                                        <div className="chat-right-droplist">
                                          <ul className="list-chatbx">
                                            <li className="chat-dropright educ-acvt">
                                              <div className="droplist-right-btn">
                                                <div className="dropdown-main">
                                                  <Link
                                                    to="Messages"
                                                    className="dropdown-btnn addactv-btndrp"
                                                  >
                                                    Activities
                                                  </Link>
                                                </div>
                                              </div>
                                            </li>

                                            <li className="chat-dropright edu-lst-drp">
                                              <div className="droplist-right-btn">
                                                <Dropdown className="dropdown-main">
                                                  <Dropdown.Toggle
                                                    variant="success"
                                                    id="dropdown-basic"
                                                    className="dropdown-btnn"
                                                  >
                                                    <i className="fas fa-ellipsis-v"></i>
                                                  </Dropdown.Toggle>

                                                  <Dropdown.Menu className="dropdown-btn-item">
                                                    <Dropdown.Item href="#">
                                                      <span className="rightarrow-icon">
                                                        <i className="fas fa-angle-right"></i>
                                                      </span>{" "}
                                                      All files
                                                    </Dropdown.Item>
                                                    <Dropdown.Item href="#">
                                                      <span className="rightarrow-icon">
                                                        <i className="fas fa-angle-right"></i>
                                                      </span>{" "}
                                                      Notifications
                                                    </Dropdown.Item>
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="Message-chat-room">
                                <div className="chatbox-room">
                                  <div className="chat-innerbox">
                                    <div className="chat-upperbox">
                                      <div className="custom-row">
                                        <div className="chatbox-inner chat-sender">
                                          <div className="message-rom-iner">
                                            <div className="message-time">
                                              <span className="time">
                                                11.05
                                              </span>
                                            </div>
                                            <div className="message-box">
                                              <div className="message-tex">
                                                You have new game “Lorem ipsum
                                                dolor sit amet, consectetur
                                                adipiscing elit, sed do eiusmod
                                                tempor incididunt ut labore et
                                                dolore magna aliqua. Ut enim ad
                                                minim veniam”
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="chatbox-inner chat-sender">
                                          <div className="message-rom-iner">
                                            <div className="message-time">
                                              <span className="time">
                                                11.05
                                              </span>
                                            </div>
                                            <div className="message-box">
                                              <div className="message-tex">
                                                You have new game “Lorem ipsum
                                                dolor sit amet, consectetur
                                                adipiscing elit, sed do eiusmod
                                                tempor incididunt ut labore et
                                                dolore magna aliqua. Ut enim ad
                                                minim veniam”
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="chatbox-inner chat-receiver">
                                          <div className="message-rom-iner">
                                            <div className="message-box">
                                              <div className="message-tex">
                                                Lorem ipsum dolor sit amet,
                                                consectetur adipiscing elit, sed
                                                do eiusmod tempor incididunt ut
                                                labore et dolore magna aliqua.
                                                Ut enim ad minim veniam
                                              </div>
                                            </div>
                                            <div className="message-time">
                                              <span className="time">
                                                11.05
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="chat-footer">
                                  <div className="chat-footer-main">
                                    <div className="chat-footer-message">
                                      <div className="message-send-new">
                                        <Form.Control
                                          as="textarea"
                                          className="message-textarea"
                                          placeholder="Your message"
                                        />
                                        <div className="right-chaticon">
                                          <ul>
                                            <li>
                                              <button className="sendme-btn ">
                                                <img
                                                  src={palinblue}
                                                  className="img-fluid"
                                                  alt=""
                                                />
                                              </button>
                                            </li>
                                            <li>
                                              <button className="attachment-btn">
                                                <img
                                                  src={mike}
                                                  className="img-fluid"
                                                  alt=""
                                                />
                                              </button>
                                            </li>
                                            <li>
                                              <button className="attachment-btn">
                                                <img
                                                  src={attachemtnblue}
                                                  className="img-fluid"
                                                  alt=""
                                                />
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel className="tabslist-content-act">
                            <div className="myacti-listtabs educator-lsttabs-new">
                              <div className="chat-headerbar">
                                <div className="chat-topbar-list">
                                  <div className="activ-detail-list">
                                    <div className="active-detail-row">
                                      <div className="activiti-prof-icon">
                                        <span className="profile-icon-box">
                                          <i className="fas fa-user"></i>
                                        </span>
                                      </div>
                                      <div className="activiti-center-detail">
                                        <h5>Maxine Doe</h5>
                                        <h6>3 activities in progress</h6>
                                      </div>
                                      <div className="activiti-count-mesg">
                                        <div className="chat-right-droplist">
                                          <ul className="list-chatbx">
                                            <li className="chat-dropright educ-acvt">
                                              <div className="droplist-right-btn">
                                                <div className="dropdown-main">
                                                  <Link
                                                    to="Messages"
                                                    className="dropdown-btnn addactv-btndrp"
                                                  >
                                                    Activities
                                                  </Link>
                                                </div>
                                              </div>
                                            </li>

                                            <li className="chat-dropright edu-lst-drp">
                                              <div className="droplist-right-btn">
                                                <Dropdown className="dropdown-main">
                                                  <Dropdown.Toggle
                                                    variant="success"
                                                    id="dropdown-basic"
                                                    className="dropdown-btnn"
                                                  >
                                                    <i className="fas fa-ellipsis-v"></i>
                                                  </Dropdown.Toggle>

                                                  <Dropdown.Menu className="dropdown-btn-item">
                                                    <Dropdown.Item href="#">
                                                      <span className="rightarrow-icon">
                                                        <i className="fas fa-angle-right"></i>
                                                      </span>{" "}
                                                      All files
                                                    </Dropdown.Item>
                                                    <Dropdown.Item href="#">
                                                      <span className="rightarrow-icon">
                                                        <i className="fas fa-angle-right"></i>
                                                      </span>{" "}
                                                      Notifications
                                                    </Dropdown.Item>
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="Message-chat-room">
                                <div className="chatbox-room">
                                  <div className="chat-innerbox">
                                    <div className="chat-upperbox">
                                      <div className="custom-row">
                                        <div className="chatbox-inner chat-sender">
                                          <div className="message-rom-iner">
                                            <div className="message-time">
                                              <span className="time">
                                                11.05
                                              </span>
                                            </div>
                                            <div className="message-box">
                                              <div className="message-tex">
                                                You have new game “Lorem ipsum
                                                dolor sit amet, consectetur
                                                adipiscing elit, sed do eiusmod
                                                tempor incididunt ut labore et
                                                dolore magna aliqua. Ut enim ad
                                                minim veniam”
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="chatbox-inner chat-receiver">
                                          <div className="message-rom-iner">
                                            <div className="message-box">
                                              <div className="message-tex">
                                                Lorem ipsum dolor sit amet,
                                                consectetur adipiscing elit, sed
                                                do eiusmod tempor incididunt ut
                                                labore et dolore magna aliqua.
                                                Ut enim ad minim veniam
                                              </div>
                                            </div>
                                            <div className="message-time">
                                              <span className="time">
                                                11.05
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="chat-footer">
                                  <div className="chat-footer-main">
                                    <div className="chat-footer-message">
                                      <div className="message-send-new">
                                        <Form.Control
                                          as="textarea"
                                          className="message-textarea"
                                          placeholder="Your message"
                                        />
                                        <div className="right-chaticon">
                                          <ul>
                                            <li>
                                              <button className="sendme-btn ">
                                                <img
                                                  src={palinblue}
                                                  className="img-fluid"
                                                />
                                              </button>
                                            </li>
                                            <li>
                                              <button className="attachment-btn">
                                                <img
                                                  src={mike}
                                                  className="img-fluid"
                                                  alt=""
                                                />
                                              </button>
                                            </li>
                                            <li>
                                              <button className="attachment-btn">
                                                <img
                                                  src={attachemtnblue}
                                                  className="img-fluid"
                                                  alt=""
                                                />
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                        </div>
                      </div>
                    </div>
                  </Tabs>
                </div>
              </div>
            </div>
          </Container>
        </div>

        {/* footer start */}
        <FooterEducator logo={logo} />
        {/* footer end */}
      </div>

      {/* start add pupil modal */}

      <Addpupil
        modalEducatorstep1Show={modalEducatorstep1Show}
        setEducatorstep1ModalShow={(value) => setEducatorstep1ModalShow(value)}
      />

      {/* start Add a Group step1 */}

      <Addgroup
        modalAddgroupstep1Show={modalAddgroupstep1Show}
        setAddgroupstep1ModalShow={(value) => setAddgroupstep1ModalShow(value)}
      />

      {/* end Add a Group step1 */}

      {/* start Send Invite step1 */}

      <SendInvite
        modalinvitestep1Show={modalinvitestep1Show}
        setinvitestep1ModalShow={(value) => setinvitestep1ModalShow(value)}
      />

      {/* end Send Invite step1 */}

      {/* start Send Invite step1 */}
      <Groupmember
        modalgroupmemberstep1Show={modalgroupmemberstep1Show}
        setgroupmemberstep1ModalShow={(value) =>
          setgroupmemberstep1ModalShow(value)
        }
      />
      {/* end Send Invite step1 */}

      {/* end add pupil modal */}
    </div>
  );
}

export default Studentchat;
