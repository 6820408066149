import React from "react";
import "./home.scss";
import "./profile.scss";
import "./media.scss";
import "./games.scss";
import "./myactivities.scss";
import "./allfiles.scss";
import { Row } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import logo from "../assets/images/logo.png";
import gleryfiles1 from "../assets/images/files-glery1.jpg";
import gleryfiles2 from "../assets/images/files-glery2.jpg";
import gleryfiles3 from "../assets/images/files-glery3.jpg";
import gleryfiles4 from "../assets/images/files-glery4.jpg";
import gleryfiles5 from "../assets/images/files-glery5.jpg";
import Footer from "./layout/Footer/Footer";
import { HeaderDashboard } from "./layout/HeaderDashboard";

function Allfiles() {
  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderDashboard />
      {/* end header */}

      <div className="games-dashboard-main dashboard-bgnew blur-bgg">
        <div className="games-topbread allfile-section">
          <div className="contain-custm">
            <div className="diversity-box">
              <div className="myactivites-main">
                <div className="myactivites-contain">
                  <Tabs className="allfiles-tabsnew-boxx">
                    <div className="allflies-header">
                      <div className="allfiles-tabsbox">
                        <div className="close-iconbtn">
                          <button className="btnclose-glry">&#x2716;</button>
                        </div>
                        <TabList className="tabslist-activiti">
                          <Tab className="tabslist-activiti-tab">
                            <div className="allfilesmain-bx">
                              <div className="allfiles-title">
                                Photos{" "}
                                <span className="total-numfiles">178</span>
                              </div>
                            </div>
                          </Tab>
                          <Tab className="tabslist-activiti-tab">
                            <div className="allfilesmain-bx">
                              <div className="allfiles-title">
                                Videos{" "}
                                <span className="total-numfiles">26</span>
                              </div>
                            </div>
                          </Tab>
                          <Tab className="tabslist-activiti-tab">
                            <div className="allfilesmain-bx">
                              <div className="allfiles-title">
                                Documents{" "}
                                <span className="total-numfiles">5</span>
                              </div>
                            </div>
                          </Tab>
                          <Tab className="tabslist-activiti-tab">
                            <div className="allfilesmain-bx">
                              <div className="allfiles-title">
                                Audio files{" "}
                                <span className="total-numfiles">58</span>
                              </div>
                            </div>
                          </Tab>
                          <Tab className="tabslist-activiti-tab">
                            <div className="allfilesmain-bx">
                              <div className="allfiles-title">
                                Links <span className="total-numfiles">19</span>
                              </div>
                            </div>
                          </Tab>

                          <Tab className="tabslist-activiti-tab">
                            <div className="allfilesmain-bx">
                              <div className="allfiles-title">
                                Voice messages{" "}
                                <span className="total-numfiles">19</span>
                              </div>
                            </div>
                          </Tab>
                        </TabList>
                      </div>

                      <div className="allfiles-bottombx">
                        <div className="all-files-gallery">
                          <div className="gallery-allfle-new">
                            <TabPanel className="tabslist-content-act">
                              <div className="myacti-listtabs">
                                <Row>
                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles1}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles2}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles3}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles3}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles4}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles5}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles5}
                                          className="img-fluid"
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles1}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles2}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles2}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles4}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles3}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles1}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles2}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles4}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Row>
                              </div>
                            </TabPanel>
                            <TabPanel className="tabslist-content-act">
                              <div className="myacti-listtabs">
                                <Row>
                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles1}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles2}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles3}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles3}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles4}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles5}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles5}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles1}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles2}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles2}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles4}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles3}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles1}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles2}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles4}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Row>
                              </div>
                            </TabPanel>

                            <TabPanel className="tabslist-content-act">
                              <div className="myacti-listtabs">
                                <Row>
                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles1}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles2}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles3}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Row>
                              </div>
                            </TabPanel>
                            <TabPanel className="tabslist-content-act">
                              <div className="myacti-listtabs">
                                <Row>
                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles1}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles2}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles3}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Row>
                              </div>
                            </TabPanel>
                            <TabPanel className="tabslist-content-act">
                              <div className="myacti-listtabs">
                                <Row>
                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles1}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles2}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles3}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Row>
                              </div>
                            </TabPanel>

                            <TabPanel className="tabslist-content-act">
                              <div className="myacti-listtabs">
                                <Row>
                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles1}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles2}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 one">
                                    <div className="gallery-frame-file">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles3}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Row>
                              </div>
                            </TabPanel>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* footer start */}
        <Footer logo={logo} />
        {/* footer end */}
      </div>
    </div>
  );
}

export default Allfiles;
