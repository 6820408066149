import { AUTH_TOKEN, USER_DETAILS } from "../service/LocalStorage";

const loginUserToken = localStorage.getItem(AUTH_TOKEN);
const userDetailStr = localStorage.getItem(USER_DETAILS);
const userDetail = userDetailStr ? JSON.parse(userDetailStr) : undefined;
const roleId = userDetail?.role || "";

export const state = {
  isNewUser: userDetail?.isFirstTimeLogin || false,
  token: loginUserToken ? true : false,
  roleId: roleId,
  isQuestionnaireOpen: false,
  isEducatorQuestionnaireOpen: false,
  userDetail: userDetail,
  blogs: [],
  subjects: [],
  selectedSubjectID: 0,
  testData:""
};
