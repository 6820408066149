import { Box, Grid, styled, Typography } from "@mui/material";
import {
  CloseButton,
  StyledPurpleButton,
  StyledYellowButton,
} from "../../utils/Buttons";

import questionnaireHomeImage from "../../../assets/images/questionnaireHome.png";

const HomeImage = styled("img")({
  borderRadius: "0px 0px 0px 20px",
  width: "100%",
  height: "100%",
  objectFit: "contain",
});

export const Result = ({ closeQuestionnaire }) => {
  return (
    <Grid
      container
      sx={{
        position: "relative",
        backgroundColor: "var(--white)",
        borderRadius: "10px",
        maxWidth: "60rem",
        m: "3rem auto 10rem auto",
        p: "1rem",
      }}
    >
      <CloseButton closeHandler={closeQuestionnaire} />
      <Grid item md={6} xs={12}>
        <HomeImage src={questionnaireHomeImage} alt="studying" />
      </Grid>
      <Grid sx={{ position: "relative", p: "1.5rem" }} item md={6} xs={12}>
        <Typography
          mt="3rem"
          mb="0rem"
          sx={{ fontSize: "1rem", fontWeight: "800" }}
          color="var(--purple-dark)"
        >
          Your learning styles is
        </Typography>
        <Typography
          mb={"1rem"}
          sx={{ color: "var(--purple-dark)", fontWeight: "700" }}
          fontSize="1.9rem"
          variant="h5"
        >
          Practical Learner{" "}
        </Typography>

        <Typography
          fontSize="0.8rem"
          mb={"1rem"}
          sx={{ color: "var(--black)", fontWeight: "500" }}
          variant="body"
          display={"block"}
        >
          It means that you need to choose lessons with will find a series of
          questions that might be used to describe your learning style.
          Presented in each item a bipolar and next text with explanation of the
          rule.
        </Typography>
        <Typography
          fontSize="0.8rem"
          sx={{ color: "var(--black)", fontWeight: "500" }}
          variant="body"
          display={"block"}
        >
          People like you usually can’t remember information It means that you
          will find a series of questions that might be used to describe your
          learning style. Presented in each item a bipolar and next text with
          explanation of the rule because they are other types.
        </Typography>
        <Typography
          mt="1rem"
          mb="0rem"
          sx={{ fontSize: "0.8rem", fontWeight: "800" }}
          color="var(--purple-dark)"
        >
          Contratulations! Now you know your learning style and how to study
          easy. Your teachers and tutors will see your style and will give you
          tasks that suit you!{" "}
        </Typography>

        <Box sx={{ height: "2rem" }}>{/* Some text */}</Box>
        <Box
          sx={{
            fontSize: "0.9rem",
            padding: "0.8rem",
            position: "absolute",
            bottom: "-3rem",
            display: "flex",
            flexDirection: "column",
            rowGap: "1rem",
            left: "15%",
          }}
        >
          <StyledYellowButton
            title={"Take points and go to the games"}
            btnHandler={() => {}}
          />

          <StyledPurpleButton
            title={"Take points and go to the tutors"}
            btnHandler={() => {}}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
