import React from "react";
import "../home.scss";
import "../teachers.scss";
import "./about.scss";
import "../media.scss";
import { Container, Row } from "react-bootstrap";
// import logo from "../assets/images/logo.png";
import picslider from "../../assets/images/girl1.png";
import picsliderbg from "../../assets/images/bg-vector-icon.png";
import star from "../../assets/images/starbx.png";
import member1 from "../../assets/images/member1.png";
import member2 from "../../assets/images/member2.png";
import member3 from "../../assets/images/member3.png";
import member4 from "../../assets/images/member4.png";
import member5 from "../../assets/images/member5.png";
import shapecount from "../../assets/images/shape-count.png";

import Slider from "react-slick";
import { Link, useHistory } from "react-router-dom";
import { HeaderDashboard } from "../layout/HeaderDashboard";
// import { HeaderStudent } from "../layout/Header/HeaderStudent";
import { Helmet } from "react-helmet";
import { HomeFooter } from "../layout/HomeFooter";

function About() {
  const history = useHistory();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    // nextArrow: <img src={rightarrow} className="img-fluid" />,
    // prevArrow: <img src={leftarrow} className="img-fluid" />,
    slidesToScroll: 1,
  };

  const settingsone = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 2,
    nav: false,
    // nextArrow: <img src={rightarrow} className="img-fluid" />,
    // prevArrow: <img src={leftarrow} className="img-fluid" />,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },

      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  const settingsthird = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    nav: false,
    // nextArrow: <img src={rightarrow} className="img-fluid" />,
    // prevArrow: <img src={leftarrow} className="img-fluid" />,
    slidesToScroll: 1,
  };

  const settingsnew = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "10px",
    slidesToShow: 5,
    // nextArrow: <img src={rightarrow} className="img-fluid" />,
    // prevArrow: <img src={leftarrow} className="img-fluid" />,
    speed: 500,
  };

  return (
    <div className="home-wrapper">
      <Helmet>
        <title>About Us - Best Learning Apps, Neithedu </title>
        <meta
          name="description"
          content="We're a UK based edtech company, a dynamic team with over 50 years of creating games and learning apps that students love. We work with love and passion for you."
        />
      </Helmet>

      <div className="testimaonial-section testimonial-eduction out-team">
        <div
          className="our-team-bx about-page-container"
          style={{ marginBottom: "2rem" }}
        >
          <HeaderDashboard />

          <div className=" useful-tech about-section-boxx">
            <div className="about-content">
              <Container>
                <div className="about-card">
                  <div className="custom-row">
                    <div className="left-pix-card">
                      <div className="pic-box">
                        <div className="kids-pic2">
                          <img src={star} className="img-fluid" />
                        </div>
                      </div>
                    </div>

                    <div className="right-aboutbx-crad">
                      <div className="about-cardiner">
                        <h4>About Us</h4>
                        <p className="small-tex">
                          We’re an edtech company, a dynamic team with
                          over 50 years of creating games and learning apps that
                          students love. Based on extensive experience and a
                          desire to help modern children learn a lot of
                          information that is presented to them in the form of
                          school subjects, we decided to create a new approach
                          to learning it. We have developed a number of
                          specialised games to help students better master the
                          curriculum and disciplines. Our approach allows you to
                          have fun and easily learn new, necessary information
                          by playing games without spending a lot of time and
                          effort.
                          <br></br>
                          Here you will find various school and university
                          subjects in which you can practice.
                        </p>

                        <p className="small-tex">
                          We work with love and passion for you.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="aboutshape-bg"></div>
                </div>
              </Container>
            </div>
          </div>
          <Container>
            <div className="team-main">
              <div className="heading-top">
                <h4 className="title-medium">Meet our team</h4>
                <div className="title-team">
                  Mad Scientists, Passionate Teachers, and Award-Winning
                  Artists...
                </div>
                <p className="hd-tex">
                  With over 50 years of experience creating games and learning
                  apps that students love.
                </p>
              </div>

              <div className="teamlist-tex">
                <Row className="member">
                  <div className="col-md-5 col-lg-3">
                    <div className="teamlist-frame">
                      {" "}
                      <img src={member1} className="img-fluid" />{" "}
                    </div>
                  </div>
                  <div className="col-md-7 col-lg-9">
                    <div className="team-text">
                      <div className="t-name">Namit Kapoor</div>
                      <div className="t-status">Founder & Director</div>
                      <p className="small-tex">
                        An expert on preparing children for the future, in which
                        professional complexity grows so that they do not have
                        problems finding (or creating) a career that they are
                        passionate about.
                      </p>
                      <p className="small-tex">
                        He is a certified learning professional, a teacher with
                        international experience who worked in 4 continents and
                        10+ countries (including UK, China, India, and Dubai),
                        combining teaching in schools and private tutoring
                      </p>
                    </div>
                  </div>
                </Row>

                <Row className="member">
                  <div className="col-md-1 col-lg-1"></div>
                  <div className="col-md-5 col-lg-3">
                    <div className="teamlist-frame">
                      {" "}
                      <img src={member4} className="img-fluid" />{" "}
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-8">
                    <div className="team-text">
                      <div className="t-name">Arun Kumar</div>
                      <div className="t-status">Senior Coach & Motivator</div>
                      <p className="small-tex">
                        As an NLP certified Master Trainer, Arun’s expertise
                        informs all aspects of the Neithedu experience.
                      </p>
                      <p className="small-tex">
                        His 13+ years of educational training expertise in soft
                        skills (like leadership, communication, and
                        problem-solving) and personal development ensure our
                        programs put people first and always deliver on detail
                        and effectiveness.
                      </p>
                    </div>
                  </div>
                </Row>

                <Row className="member">
                  <div className="col-md-5 col-lg-3">
                    <div className="teamlist-frame">
                      {" "}
                      <img src={member2} className="img-fluid" />{" "}
                    </div>
                  </div>
                  <div className="col-md-7 col-lg-9">
                    <div className="team-text">
                      <div className="t-name">Steve Foskett</div>
                      <div className="t-status">Research & Development</div>
                      <p className="small-tex">
                        An international e-learning career specialist and STEM
                        educator, expert in gamification and animations thanks
                        to whose technical experience the Neithedu platform
                        thrives.{" "}
                      </p>
                      <p className="small-tex">
                        He designed eLearning solutions for the BBC and
                        Cambridge University Press.
                      </p>
                      <p className="small-tex">
                        He has also designed, written, and prepared a wide range
                        of eLearning multimedia games and teaching solutions for
                        organisations as diverse as English Rugby, TAG
                        learning/The Big Bus, the Imperial War Museum.
                      </p>
                    </div>
                  </div>
                </Row>

                <Row className="member">
                  <div className="col-md-1 col-lg-1"></div>
                  <div className="col-md-5 col-lg-3">
                    <div className="teamlist-frame">
                      {" "}
                      <img src={member3} className="img-fluid" />{" "}
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-8">
                    <div className="team-text">
                      <div className="t-name">Suneet Shivaprasad</div>
                      <div className="t-status">Project Developer</div>
                      <p className="small-tex">
                        Entrepreneur, C-Level Executive, mentor and guest
                        lecture on marketing strategy and innovation for Warwick
                        Business School, City Business School, University
                        College London, and Queen Mary University London.
                      </p>
                      <p className="small-tex">
                        In the past the consultant of EF, Cirque Du Soleil,
                        Tesco, Bulgari, and several international governments.
                      </p>
                      <p className="small-tex">
                        This great expert makes sure that everything keeps
                        running smoothly and fixes things when they break.
                      </p>
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="col-md-5 col-lg-3">
                    <div className="teamlist-frame">
                      {" "}
                      <img src={member5} className="img-fluid" />{" "}
                    </div>
                  </div>
                  <div className="col-md-7 col-lg-9">
                    <div className="team-text">
                      <div className="t-name">Lori Figueiredo</div>
                      <div className="t-status">
                        Psychologist & Special Advisor
                      </div>
                      <p className="small-tex">
                        The global learning strategist whose passion for smart
                        technology helps us to drive forward. Her
                        custom-designed solutions have enhanced the prospects of
                        individuals and organizations worldwide
                      </p>
                      <p className="small-tex">
                        For more than 3 decades her work has spanned over more
                        than 50 organizations, 15 industries, and 100 countries
                        constantly disrupting the way people learn.
                      </p>
                    </div>
                  </div>
                </Row>

                <div className="more-about">
                  More about other projects of our team you can find on{" "}
                  <Link to="#">our website</Link>{" "}
                </div>
              </div>
            </div>
          </Container>
        </div>

        <HomeFooter />
      </div>
    </div>
  );
}

export default About;
