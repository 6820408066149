import React, { useContext, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import {
  addManyStudents,
  searchEducatorStudentsNameEmail,
} from "../service/apis/educator";
import "./loginModal/login.scss";
import { NotificationContext } from "../contexts/Notification";

const Addpupil = ({
  openSnack,
  refetchStudentsAndGroups,
  closeModal,
  modalEducatorstep1Show,
}) => {
  const [students, setstudents] = useState([]);
  const [studentEmail, setstudentEmail] = useState("");
  const [selectedStudents, setselectedStudents] = useState([]);
  const [studentsAlreadyFetched, setstudentsAlreadyFetched] = useState(false);
  const { showNotification } = useContext(NotificationContext);
  const fetchStudents = async () => {
    const res = await searchEducatorStudentsNameEmail(studentEmail);
    console.log("students", res);
    setselectedStudents([]);
    setstudents(res);
    setstudentsAlreadyFetched(true);
  };

  const selectStudent = (studentId) => {
    const index = selectedStudents.findIndex((stud) => stud === studentId);
    if (index === -1) {
      setselectedStudents((state) => [...state, studentId]);
    } else
      setselectedStudents((state) =>
        state.filter((stud) => stud !== studentId),
      );
  };
  const history = useHistory();

  const addStudentsHandler = async () => {
    try {
      const res = await addManyStudents(selectedStudents);
      console.log("adding pupil res", res);
      setstudentEmail("");
      setstudents([]);
      setselectedStudents([]);
      setstudentsAlreadyFetched(false);
      await refetchStudentsAndGroups();

      showNotification("students added successfully");
      history.push(`educator?studentId=${res[0] ? res[0] : true}`);
      closeModal();
    } catch (err) {
      openSnack("Something went wrong", "error");
    }
  };

  return (
    <div className="add-pupil-mainbx">
      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-pupil-modal2 mychild-frm"
        show={modalEducatorstep1Show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Add a new pupil</h4>
            </Modal.Title>
            <button
              type="button"
              onClick={() => {
                setstudentEmail("");
                setstudents([]);
                setselectedStudents([]);
                setstudentsAlreadyFetched(false);
                closeModal();
              }}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <div className="pupiladd-filednw">
                  <Form.Group className="subsform-fild">
                    <Form.Label>Pupil’s email or name in Neithedu</Form.Label>
                    <div className="livein-field">
                      <Form.Control
                        value={studentEmail}
                        onChange={(e) => setstudentEmail(e.target.value)}
                        type="email"
                        placeholder="child_email@example.com"
                      ></Form.Control>
                    </div>
                  </Form.Group>
                </div>
                <div className="btnad-pupil-lg">
                  <div className="btnlogin-main">
                    <button
                      onClick={fetchStudents}
                      type="submit"
                      className="btnlogin btnweak-bx"
                      disabled={studentEmail.length < 3}
                    >
                      Search a student
                    </button>
                  </div>
                </div>

                {studentsAlreadyFetched && (
                  <div className="newpupil-list-bx">
                    <Form.Group className="subsform-fild child-tex-fld height-max-flow">
                      <Form.Label>
                        {studentsAlreadyFetched && students.length > 0
                          ? "My new pupil is"
                          : "No student found"}
                      </Form.Label>
                      <div className="child-boxnew">
                        {students.map((student, index) => {
                          const isSelected =
                            selectedStudents.findIndex(
                              (stud) => stud === student.studentId,
                            ) !== -1;

                          return (
                            <div className="child-account" key={index}>
                              <div className="activ-detail-list">
                                <label className="custm-check">
                                  <Form.Control
                                    type="checkbox"
                                    checked={isSelected}
                                    onChange={(e) =>
                                      selectStudent(student.studentId)
                                    }
                                  />
                                  <span className="checkmark"></span>
                                  <div className="active-detail-row">
                                    <div className="activiti-prof-icon">
                                      <span className="profile-icon-box">
                                        <i className="fas fa-user"></i>
                                      </span>
                                    </div>
                                    <div className="activiti-center-detail">
                                      <h5>{student.name}</h5>
                                      <h6>{student.location}</h6>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </Form.Group>
                  </div>
                )}

                {studentsAlreadyFetched && selectedStudents.length > 0 && (
                  <div className="btnlogin-main">
                    <button
                      onClick={addStudentsHandler}
                      type="submit"
                      className="btnlogin btnweak-bx"
                    >
                      Confirm and add to my pupils
                    </button>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default Addpupil;
