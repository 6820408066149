import React from "react";
// import { getTopicsByCategoryId } from "../../service/apis/common";
// import Skeleton from "@mui/material/Skeleton";
import { Link } from "react-router-dom";
import slideC from "../../assets/images/gallery5.png";

export default function TopicsList({ topics }) {
  // const [topics, settopics] = useState([]);
  // const [topicsLoaded, settopicsLoaded] = useState(false);
  // console.log(sub, "sub");

  // useEffect(() => {
  //   const fetchTopics = async () => {
  //     console.log("hello");
  //     try {
  //       const res = await getTopicsByCategoryId(sub);
  //       console.log(res, "topics");
  //       if (res.rows) {
  //         settopics(res.rows);
  //       }
  //       settopicsLoaded(true);
  //     } catch (err) {
  //       settopicsLoaded(true);
  //     }
  //   };
  //   console.log("fetching topics for sub", sub);
  //   settopicsLoaded(false);
  //   fetchTopics();
  //   return () => {
  //     settopics([]);
  //     settopicsLoaded(false);
  //   };
  // }, [sub]);
  return (
    <div className="std-list">
      <ul className="std-listing-gllry">
        {topics.length > 0 ? (
          topics.map((t) => (
            <li key={t.id}>
              <Link
                to={{
                  pathname: "/games",
                  state: { topicId: t.id, topic: t, isTopic: true },
                }}
                className="gift-ico"
              >
                <div className="gallery-std-bx">
                  <div className="gallery-std-card">
                    <div className="galer-card-pic">
                      <div className="galerybx-pix1">
                        <img
                          src={t.topicImage || slideC}
                          className="img-fluid rounded"
                          alt="ats"
                        />
                      </div>
                      <div className="shapebg-gallery"></div>
                    </div>
                    <div className="galery-content-std">
                      <div className="galery-title">{t.topicName}</div>
                    </div>
                  </div>
                </div>
              </Link>
            </li>
          ))
        ) : (
          <div className="Not_available">
              <div className="list-counter">
                <h2>No Topics Available</h2>
                <span className="shapebx-count"></span>
              </div>
          </div>
        )}
      </ul>
    </div>
  );
}
