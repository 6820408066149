import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router";
import FooterEducator from "../../layout/Footer/FooterEducator";
import HeaderGames from "../../layout/Header/HeaderGames";
import { StyledPurpleButton } from "../../utils/Buttons";

export const BadgesPayments = () => {
  const location = useLocation();
  const [state, setstate] = useState({
    badges: [
      {
        badgeTitle: "",
        qty: 0,
      },
    ],
  });

  const totalQty = state.badges.reduce((prev, curr) => prev + curr.qty, 0);
  const totalPrice = state.badges.reduce(
    (prev, curr) => prev + curr.qty * 0.4,
    0,
  );

  useEffect(() => {
    setstate({ badges: location.state?.badges || [] });
  }, []);

  return (
    <div className="home-wrapper">
      <HeaderGames />
      <div
        style={{ minHeight: "100vh" }}
        className="games-dashboard-main dashboard-bgnew blur-bgg educator-home"
      >
        <Container>
          <Typography
            sx={{
              mb: "1rem",
              color: "var(--white)",
              fontSize: "1.8rem",
              fontWeight: "700",
            }}
          >
            Payments
          </Typography>
          <Grid
            container
            sx={{
              p: "1rem",
              borderRadius: "10px",
              backgroundColor: "var(--white)",
            }}
          >
            <Grid item sm={6}>
              <Box>
                <Stack
                  mb="1rem"
                  flexDirection="row"
                  justifyContent={"space-between"}
                  alignItems="center"
                >
                  <Typography sx={{ fontWeight: "700", fontSize: "1.3rem" }}>
                    {totalQty} badges
                  </Typography>
                  <Typography sx={{ fontWeight: "600", fontSize: "1.1rem" }}>
                    $0.40 / badge
                  </Typography>
                </Stack>
                {state.badges.map((badge, idx) => {
                  return (
                    <Stack
                      mb="1rem"
                      key={idx}
                      flexDirection={"row"}
                      justifyContent="space-between"
                    >
                      <Typography>{badge.title}</Typography>
                      <Typography>x {badge.qty}</Typography>
                    </Stack>
                  );
                })}
                <Typography sx={{ my: "1rem", textAlign: "start" }}>
                  By paying, you accept the <u>Terms of Use</u> and{" "}
                  <u>Privacy</u>
                </Typography>
                <Stack flexDirection="row" justifyContent={"space-between"}>
                  <Typography sx={{ fontSize: "1.3rem", fontWeight: "700" }}>
                    Total to pay
                  </Typography>
                  <Typography sx={{ fontWeight: "800", fontSize: "1.3rem" }}>
                    ${totalPrice}
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid item sm={6}>
              <Box
                sx={{
                  height: "100%",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                }}
              >
                <Box sx={{ width: "10rem" }}>
                  <StyledPurpleButton title={"Pay"} btnHandler={() => {}} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <FooterEducator />
      </div>
    </div>
  );
};
