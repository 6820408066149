import { CircularProgress } from "@mui/material";
import React from "react";

export default function Loading() {
  return (
    <div>
      <CircularProgress
        size={"1.5rem"}
        sx={{
          color: "var(--purple)",
          mx: "auto",

          alignItems: "center",
        }}
      />
    </div>
  );
}
