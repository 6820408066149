import { Axios } from "../Axios";

export const activitiesSendOne = async (
  formData,
  assignedBy,
  selectedItem,
  taskId,
  activityTypeId = 41,
) => {
  const res = await Axios.post("/activities/send-one-activity", {
    assignedBy: assignedBy,
    assignerDetails: selectedItem.map((item) => ({
      isAssignedToGroup: true,
      assignedTo: item.student,
    })),
    activityTypeId: activityTypeId,
    activityToPerformId: taskId,
    points: formData.points,
    dueDate: formData.deadline,
  });
  return res.data;
};

export const activitiesSendOneGame = async (
  assignedBy,
  deadline,
  selectedItem,
  game,
  activityTypeId,
) => {
  let data = {
    assignedBy: assignedBy,
    assignerDetails: selectedItem.map((item) => ({
      isAssignedToGroup: item.isGroup,
      assignedTo: item.id,
    })),
    activityTypeId: activityTypeId,
    activityToPerformId: +activityTypeId !== 7 ? game.id : game.taskId,
    points: game.points,
    dueDate: deadline,
  };
  console.log("sendOneActivity", data);
  const res = await Axios.post("/activities/send-one-activity", data);
  return res.data;
};

export const testSendOne = async (formData, selectedItem) => {
  console.log(selectedItem);

  const res = await Axios.post("/activities/send-one-activity", {
    assignedBy: formData.assignedById,
    assignerDetails: selectedItem.map((item) => ({
      isAssignedToGroup: false,
      assignedTo: item,
    })),
    activityTypeId: 41,
    activityToPerformId: formData.taskId,
    points: formData.points,
    dueDate: new Date(formData.deadline),
  });
  return res.data;
};

export const activitiesSendMultiGame = async (
  assignedBy,
  assignedTo,
  Group,
  selectedGames,
  deadline,
  activityTypeId,
) => {
  console.log("selectedGames", selectedGames);

  const res = await Axios.post("/activities/send-multiple-activities", {
    assignedBy: assignedBy,
    assignedTo: assignedTo,
    isAssignedToGroup: Group,
    sendingActivityDetails: selectedGames.map((item) => ({
      points: item.points,
      activityToPerformId: item.id,
      activityTypeId: item.activityTypeId,
    })),
    dueDate: deadline,
  });
  return res.data;
};

export const activitiesSendMulti = async (
  formData,
  assignedBy,
  activityTypeId = 41,
  taskId,
) => {
  console.log("selectedGames", taskId);

  const res = await Axios.post("/activities/send-multiple-activities", {
    assignedBy: assignedBy,
    assignedTo: 0,
    isAssignedToGroup: true,
    sendingActivityDetails: [
      {
        points: formData.points,
        activityToPerformId: taskId,
        activityTypeId: activityTypeId,
      },
    ],
    dueDate: formData.deadline,
  });

  return res.data;
};

export const getAllActivities = async (assignedToId, assignedById) => {
  const res = await Axios.get(
    "/activities?assignedToId=" +
      assignedToId +
      "&assignedById=" +
      assignedById,
  );
  return res.data;
};

export const getAllActivitiesstudent = async (assignedToId, educatorId) => {
  const res = await Axios.get(
    "/activities?assignedToId=" + assignedToId + "&assignedById=" + educatorId,
  );
  return res.data;
};

export const getSingalActivities = async (acId, id, isGameFlow) => {
  const apiUrl = isGameFlow
    ? `/activities/${acId}/0/${id}`
    : `/activities/${acId}/${id}/0`;
  const res = await Axios.get(apiUrl);
  return res.data;
};

export const getSingalActivitiesGroup = async (acId, id, blo) => {
  // : `/PersonalTaskAnswergroup/${item.assignedToId}/${item.activityToBePerformedId}/${item.activityTypeId}`;

  console.log("callingapi", acId, id, blo);
  const res = await Axios.get(
    `/activities/get-group-notification-details/${acId}/${id}/${blo}`,
  );
  console.log("callingapi res", acId, id, blo);
  return res.data;
};

export const studentSingleGroupActivity = async (
  assignedToGroupId,
  activityToPerformId,
  activityTypeId,
) => {
  const res = await Axios.get(
    `/activities/get-group-notification-details/${assignedToGroupId}/${activityToPerformId}/${activityTypeId}`,
  );
  console.log("callingapi res", res);
  return res.data;
};

export const getGrpActivities = async (groupId, educatorId) => {
  const res = await Axios.get(
    `/activities/get-group-activities-notifications?assignedToId=${groupId}&assignedBy=${educatorId}`,
  );
  return res.data;
};

export const getGrpAllActivities = async (groupId) => {
  const res = await Axios.get("/activities/groups/" + groupId);

  return res.data;
};

export const getStudentAllActivities = async () => {
  const res = await Axios.get("/educators/student-educators-groups");

  return res.data;
};

export const getParentdAllActivities = async () => {
  const res = await Axios.get("/educators/parent-students");

  return res.data;
};

export const addChild = async (formData) => {
  const res = await Axios.post("/users/child-email", {
    childEmail: formData.map((item) => item.email),
  });
  return res.data;
};

export const studentUpdateStatus = async (activityId, status, earnedPoints) => {
  const res = await Axios.post("/gameflows/save-games-status", {
    activityId: activityId,
    status: status,
    earnedPoints: earnedPoints,
  });
  return res.data;
};
