import { Box, Grid, Modal as MuiModal, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { borderRadius } from "@mui/system";
import DoneIcon from "@mui/icons-material/Done";
import { StyledPurpleButton, StyledYellowButton } from "../../utils/Buttons";
import { Button, Form, Modal } from "react-bootstrap";
import { CustomSnackbar } from "../../../utils/useSnackState";
import { Link } from "react-router-dom";

const badges = [
  {
    img: "/images/badge2.png",
    title: "Helping others",
    points: 30,
    count: 4,
  },
  {
    img: "/images/badge2.png",
    title: "Working hard",
    points: 50,
    count: 3,
  },
  {
    img: "/images/badge2.png",
    title: "Helping others",
    points: 30,
    count: 3,
  },
  {
    img: "/images/badge2.png",
    title: "Working hard",
    points: 50,
    count: 3,
  },
  {
    img: "/images/badge1.png",
    title: "Helping others",
    points: 30,
    count: 2,
  },
  {
    img: "/images/badge1.png",
    title: "Helping others",
    points: 30,
    count: 2,
  },
  {
    img: "/images/badge1.png",
    title: "Helping others",
    points: 30,
    count: 2,
  },
  {
    img: "/images/badge1.png",
    title: "Helping others",
    points: 30,
    count: 2,
  },
  {
    img: "/images/badge1.png",
    title: "Helping others",
    points: 30,
    count: 2,
  },
  {
    img: "/images/badge1.png",
    title: "Helping others",
    points: 30,
    count: 2,
  },
  {
    img: "/images/badge1.png",
    title: "Helping others",
    points: 30,
    count: 2,
  },
  {
    img: "/images/badge1.png",
    title: "Helping others",
    points: 30,
    count: 2,
  },
  {
    img: "/images/badge1.png",
    title: "Working hard",
    points: 50,
    count: 2,
  },
];
const pointOptions = [10, 20, 30, 40, 50];

export const SendBadgeToGroupStudents = ({
  closeHandler,
  backBtnHandler,
  confirmHandler,
  pupilsList = [],
}) => {
  const [state, setstate] = useState({
    selectedPupils: [],
    searchQuery: "",
  });

  const IsAllSelected = state.selectedPupils.length === pupilsList.length;
  const selectPupil = (pupilId) => {
    if (state.selectedPupils.includes(pupilId)) {
      setstate((prevState) => ({
        ...prevState,
        selectedPupils: prevState.selectedPupils.filter((pu) => pu !== pupilId),
      }));
    } else {
      setstate((prevState) => ({
        ...prevState,
        selectedPupils: [...prevState.selectedPupils, pupilId],
      }));
    }
  };

  const selectAllHandler = () => {
    setstate((prevState) => {
      const selectedPupils = pupilsList.map((pupil) => pupil.studentId);
      return { ...prevState, selectedPupils };
    });
  };
  return (
    <Modal
      className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
      // {...props}
      show={true}
      onHide={() => {
        return false;
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="login-modal-mainbx">
        <Modal.Header>
          <div className="btnback">
            <Link to="#" onClick={backBtnHandler} className="backbtn-icn">
              <i className="fas fa-arrow-left"></i>
            </Link>
          </div>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>Send encouraging badges</h4>
            <p>Choose pupils to send the badges</p>
            <h5>
              <span className="num-pupil-active">
                {state.selectedPupils.length}
              </span>
              <span className="total-pupil-num">
                / {pupilsList.length} pupils
              </span>
            </h5>
          </Modal.Title>
          <button type="button" onClick={closeHandler} className="close">
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
          {/* <CustomSnackbar {...snackProps} /> */}
        </Modal.Header>
        <Modal.Body>
          <Box
            sx={{
              mb: "1rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.2rem",
                fontWeight: "700",
                color: "var(--purple)",
              }}
            >
              Send to all students of the group
            </Typography>
            <label className="custm-check" style={{ position: "relative" }}>
              <Form.Control
                checked={IsAllSelected}
                type="checkbox"
                onClick={() => selectAllHandler()}
              />
              <span
                className="checkmark"
                style={{ position: "absolute", top: "-10px", right: "8px" }}
              ></span>
            </label>
          </Box>
          <div className="login-modl-form">
            <div className="loginform-bx">
              <Form.Group className="subsform-fild">
                <div className="serch-inner-group">
                  <div className="serchfiled-activities">
                    <Form.Control
                      type="text"
                      value={state.searchQuery}
                      onChange={(e) =>
                        setstate((prevState) => ({
                          ...prevState,
                          searchQuery: e.target.value,
                        }))
                      }
                      placeholder="Start typing name or year of study"
                      className="formbox"
                    />
                    <Button type="button" className="seachbtn">
                      <i className="fas fa-search"></i>
                    </Button>
                  </div>
                </div>

                <div className="newpupil-list-bx">
                  <Form.Group className="subsform-fild child-tex-fld height-max-flow">
                    <div className="child-boxnew">
                      {pupilsList
                        .filter((pupil) => {
                          if (
                            pupil.studentName
                              .toLowerCase()
                              .includes(state.searchQuery.toLowerCase()) ||
                            pupil?.studentEmail
                              ?.toLowerCase()
                              .includes(state.searchQuery.toLowerCase())
                          )
                            return true;
                          return false;
                        })
                        .map((student) => {
                          const isSelected =
                            state.selectedPupils.findIndex(
                              (stud) => stud === student.studentId,
                            ) !== -1;
                          return (
                            <div
                              key={student.studentId}
                              className="child-account"
                            >
                              <div className="activ-detail-list">
                                <label className="custm-check">
                                  <Form.Control
                                    checked={isSelected}
                                    type="checkbox"
                                    onClick={() =>
                                      selectPupil(student.studentId)
                                    }
                                  />
                                  <span className="checkmark"></span>
                                  <div className="active-detail-row">
                                    <div className="activiti-prof-icon">
                                      <span className="profile-icon-box">
                                        <i className="fas fa-user"></i>
                                      </span>
                                    </div>
                                    <div className="activiti-center-detail">
                                      <h5>{student.studentName}</h5>
                                      {/* <h6>{student.location}</h6> */}
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </Form.Group>
                </div>
              </Form.Group>

              <div className="btnlogin-main">
                <button
                  onClick={() => {
                    if (state.selectedPupils.length > 0) {
                      confirmHandler();
                    }
                  }}
                  style={{
                    backgroundColor:
                      !state.selectedPupils.length > 0 && "var(--grey-light)",
                    color: !state.selectedPupils.length > 0 && "white",
                  }}
                  type="submit"
                  className="btnlogin btnweak-bx"
                >
                  Confirm and go to badges
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};
export const CreateCustomBadge = ({ openBadges, closeHandler }) => {
  const [customBadgeState, setcustomBadgeState] = useState({
    badgeTitle: "",
    noOfPoints: 10,
  });
  return (
    <Modal
      className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal1"
      // {...props}
      show={true}
      onHide={() => {
        return false;
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="login-modal-mainbx">
        <Modal.Header>
          <div className="btnback">
            <div onClick={openBadges} className="backbtn-icn">
              <i className="fas fa-arrow-left"></i>
            </div>
          </div>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>Custom badge</h4>
            <h6>to Alexandra</h6>
          </Modal.Title>
          <button type="button" onClick={closeHandler} className="close">
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
          {/* <CustomSnackbar {...snackProps} /> */}
          {/* <Snackbar open={true} autoHideDuration={6000} onClose={() => {}}>
          <Alert
            onClose={() => {}}
            severity="success"
            sx={{ width: "100%" }}
          >
            This is a success message!
          </Alert>
        </Snackbar> */}
        </Modal.Header>
        <Modal.Body>
          <div className="login-modl-form">
            <div className="loginform-bx">
              <Form.Group className="subsform-fild">
                <div className="upload-profilebx">
                  <div className="file-upnew">
                    <Form.Control
                      type="file"
                      className="brower-btn"
                      id="input-file"
                    />
                    <label for="input-file" className="profile-uplod-btn">
                      <i className="fas fa-camera"></i>
                    </label>
                  </div>
                </div>
                <Form.Label>Badge for</Form.Label>
                <div className="livein-field">
                  <Form.Control
                    value={customBadgeState.badgeTitle}
                    onChange={(e) =>
                      setcustomBadgeState((state) => ({
                        ...state,
                        badgeTitle: e.target.value,
                      }))
                    }
                    type="text"
                    placeholder="Type here"
                  />
                </div>
                <Form.Label>Number of points</Form.Label>
                <div className="livein-field">
                  <Form.Control
                    value={customBadgeState.noOfPoints}
                    onChange={(e) =>
                      setcustomBadgeState((state) => ({
                        ...state,
                        noOfPoints: e.target.value,
                      }))
                    }
                    as="select"
                    placeholder="Type here"
                  >
                    {pointOptions.map((op, idx) => {
                      return (
                        <option key={idx} value={op}>
                          {op}
                        </option>
                      );
                    })}
                  </Form.Control>
                </div>
              </Form.Group>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  rowGap: "1rem",
                  mb: "2rem",
                }}
              >
                <Box
                  sx={{
                    width: "90%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <StyledPurpleButton
                    title={"Send the badge without saving"}
                    btnHandler={() => {}}
                  />
                </Box>
                <Box
                  sx={{
                    width: "90%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <StyledYellowButton
                    title={"Save and send the badge"}
                    btnHandler={() => {}}
                  />
                </Box>
              </Box>
              {/* <div className="btnlogin-main">
                <button type="submit" className="btnlogin btnweak-bx">
                  Send the badge without saving
                </button>
              </div> */}
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export const CustomBadge = ({ openCustomBadge, count }) => {
  return (
    <Box
      onClick={openCustomBadge}
      sx={{
        borderRadius: "10px",
        position: "relative",
        backgroundColor: "var(--purple-dark)",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",

        height: "15rem",
        p: "1rem",
      }}
    >
      <Typography
        sx={{
          borderRadius: "50%",
          p: "0.2rem",
          width: "22px",
          height: "22px",
          top: "5px",
          right: "5px",
          position: "absolute",
          backgroundColor: "var(--sky)",
          color: "var(--purple-dark)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontWeight: "700",
        }}
      >
        {count}
      </Typography>
      <Box
        sx={{
          width: "5rem",
          height: "5rem",
          borderRadius: "50%",
          p: "10px",
          backgroundColor: "rgba(255,255,255,0.1)",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            backgroundColor: "transparent",
            border: "2px solid var(--orange)",
          }}
        ></Box>
      </Box>
      <Typography sx={{ fontSize: "1.2rem", color: "white" }}>
        Custom badge
      </Typography>
    </Box>
  );
};

export const Badge = ({ imageSrc, points, badgeTitle, count }) => {
  const [badgeState, setbadgeState] = useState({
    isSelected: false,
    count: 0,
  });

  useEffect(() => {
    setbadgeState((prevState) => ({ ...prevState, count }));
  }, []);

  return (
    <Box
      onClick={() =>
        setbadgeState((prevState) => ({
          isSelected: !prevState.isSelected,
          count: prevState.isSelected
            ? prevState.count + 1
            : prevState.count - 1,
        }))
      }
      sx={{
        cursor: "pointer",
        height: "15rem",
        backgroundColor: "#EDEDFF",
        position: "relative",
        borderRadius: "10px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
      }}
    >
      <Typography
        sx={{
          borderRadius: "50%",
          p: "0.2rem",
          width: "22px",
          height: "22px",
          top: "5px",
          right: "5px",
          position: "absolute",
          backgroundColor: "var(--sky)",
          color: "var(--purple-dark)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontWeight: "700",
        }}
      >
        {badgeState.count}
      </Typography>
      <DoneIcon
        sx={{
          position: "absolute",

          top: "5px",
          left: "5px",
          backgroundColor: badgeState.isSelected ? "var(--orange)" : "#E0E0E0",
          p: "0.2rem",
          fontSize: "1.5rem",
          borderRadius: "50%",
          color: "white",
        }}
      />
      <Box sx={{ height: "45%" }}>
        <img
          src={imageSrc}
          style={{
            width: "100%",
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
          }}
          alt={badgeTitle}
        />
      </Box>
      <Box
        sx={{
          height: "55%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-evenly",
          p: "1rem",
        }}
      >
        <Typography sx={{ color: "var(--purple-dark)", fontSize: "1.2rem" }}>
          Badge for
        </Typography>
        <Typography
          sx={{
            fontWeight: "700",
            color: "var(--orange)",
            fontSize: "1.3rem",
          }}
        >
          {badgeTitle}
        </Typography>
        <Typography
          sx={{
            color: "var(--yellow)",
            fontSize: "1rem",
            fontWeight: "700",
            backgroundColor: "var(--purple-dark)",
            p: "0.2rem",
            borderRadius: "10px",
          }}
        >
          +{points} points
        </Typography>
      </Box>
    </Box>
  );
};
export const BadgesPopup = ({
  openCustomBadge,
  openBuyBadges,
  sendSelectedBadgesHandler,
  closeHandler,
}) => {
  return (
    <MuiModal
      // sx={{ overflowY: "scroll" }}
      open={true}
      onClose={() => {}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          p: "1rem",
        }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            maxWidth: "70rem",
            width: "100%",
            p: "1rem",
            borderRadius: "15px",
            position: "relative",
          }}
        >
          <CloseIcon
            onClick={closeHandler}
            sx={{
              cursor: "pointer",
              color: "var(--purple-dark)",
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
          />
          <Box
            sx={{
              display: "flex",
              mb: "2rem",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                color: "var(--purple-dark)",
                fontSize: "1.2rem",
                fontWeight: "700",
              }}
            >
              Send encouraging badges to Alexandra Rossie
            </Typography>
            <Stack flexDirection="row" sx={{ mr: "3rem" }}>
              <Box sx={{ mx: "1rem", width: "10rem" }}>
                <StyledYellowButton
                  sx={{ fontSize: "0.8rem" }}
                  title={"Buy new badges"}
                  btnHandler={() => {
                    openBuyBadges();
                  }}
                />
              </Box>
              <Box sx={{ mx: "1rem", width: "50%" }}>
                <StyledPurpleButton
                  sx={{ fontSize: "0.8rem" }}
                  title={"Send selected badges"}
                  btnHandler={sendSelectedBadgesHandler}
                />
              </Box>
            </Stack>
          </Box>
          <Box sx={{ overflowY: "scroll", maxHeight: "30rem" }}>
            <Grid container rowSpacing={"1rem"} columnSpacing={"1rem"}>
              <Grid item md={4} xs={4} lg={3}>
                <CustomBadge count={3} openCustomBadge={openCustomBadge} />
              </Grid>
              {badges.map((badge, idx) => {
                return (
                  <Grid key={idx} item md={4} xs={4} lg={3}>
                    <Badge
                      badgeTitle={badge.title}
                      count={badge.count}
                      imageSrc={badge.img}
                      points={badge.points}
                    />
                  </Grid>
                );
              })}
              <Grid item md={4} xs={4} lg={3}></Grid>
              <Grid item md={4} xs={4} lg={3}></Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </MuiModal>
  );
};
