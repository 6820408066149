import { Box, Typography } from "@mui/material";
import {
  CloseButton,
  StyledPurpleButton,
  StyledYellowButton,
} from "../../utils/Buttons";
import { DialogBox } from "../../utils/DialogBox";

const RefundDialog = ({ returnMoneyHandler, setModalType }) => {
  return (
    <DialogBox isOpen={true}>
      <CloseButton closeHandler={() => setModalType(null)} />
      <Box sx={{ textAlign: "center", m: "2rem" }}>
        <Typography
          sx={{
            color: "var(--purple-dark)",
            fontWeight: "800",
            fontSize: "1.3rem",
            mb: "1rem",
          }}
        >
          My tutor hasn't arrived
        </Typography>
        <Typography sx={{ color: "var(--black)" }}>
          If your tutor hasn't arrived, you can apply to return money from the
          lesson. We recommend to contact your tutor to be sure he is not late.
        </Typography>
        <Box
          my="1rem"
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "85%",
            rowGap: "1rem",
          }}
        >
          <StyledYellowButton
            title={"I will wait for the tutor"}
            btnHandler={() => {
              setModalType(null);
            }}
          />
          <StyledPurpleButton
            btnHandler={() => {
              returnMoneyHandler();
            }}
            title={"Apply for refund"}
          />
        </Box>
      </Box>
    </DialogBox>
  );
};
export default RefundDialog;
