import React, { useContext, useEffect, useState } from "react";
import "./home.scss";
import "./profile.scss";
import "./games.scss";
import "./loginModal/login.scss";
import "./profile.scss";
import "./messages/messages.scss";
import "./myactivities.scss";
import "./gamestabs.scss";
import "./gamesflowphotos.scss";
import "./selectedgames.scss";
import "./Myprofile.scss";
import "./media.scss";
import { Container, Row, Button, Form, Modal } from "react-bootstrap";
import { Tabs } from "react-tabs";

import logo from "../assets/images/logo.png";
import profilepic from "../assets/images/profile-img.png";

import { useHistory } from "react-router-dom";
import FooterEducator from "./layout/Footer/FooterEducator";
import HeaderGames from "./layout/Header/HeaderGames";
import HeaderTutor from "./layout/Header/HeaderTutor";
import Becometutornew from "./tutor/Becometutornew";
import Myprofilesidebar from "./Myprofilesidebar";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { useFormik } from "formik";
import { getProfileDetails } from "../service/apis/common";
import {
  updateEducatorProfile,
  updatePrantProfile,
} from "../service/apis/educator";
import { CircularProgress, Rating, Stack, Typography } from "@mui/material";
import { useAppState } from "../overmind";
import { userRole } from "../service/constant";
import { fetchCities, fetchData } from "../service/AllApi";
import {
  // filterArray,
  filterCityId,
  filterCountryId,
  filterSyllabusId,
} from "../utils/filterArray";
import flag from "../assets/images/flag.svg";
import { CustomSnackbar, useSnackState } from "../utils/useSnackState";
import { NotificationContext } from "../contexts/Notification";

// range slider
function valueLabelFormat(value) {
  return `${value} y.o.`;
}

function Myprofile() {
  const [value, setValue] = React.useState([20, 37]);
  const [userDetails, setUserDetails] = useState("");
  const history = useHistory();
  const [countries, setCountries] = useState([]);
  const [countryId, setCountryId] = useState();
  const [cityId, setCityId] = useState();
  const [cities, setCities] = useState([]);
  const [isProfileLoading, setIsProfileLoading] = useState(false);
  const state = useAppState();
  const [years, setYears] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [snackProps, snackOpen] = useSnackState();
  const { showNotification } = useContext(NotificationContext);

  // const [states, setstates] = React.useState([]);
  // const [cities, setcities] = React.useState([]);

  // const fetchStates = async () => {
  //   try {
  //     const res = await Axios.get(`/states/${101}`);
  //     setstates(res.data.states);
  //   } catch (err) {}
  // };
  // const fetchCities = async (stateId) => {
  //   try {
  //     const res = await Axios.get(`/cities/${101}/${stateId}`);
  //     setcities(res.data.cities);
  //   } catch (err) {}
  // };

  const isTutor = state.roleId === userRole.TUTOR;
  console.log("ROLE : ", state.roleId);

  //console.log("state", state);
  const [breadMenuShow, setBreadMenuShow] = React.useState(false);
  /* start modal */
  const [modalShow, setModalShow] = React.useState(false);
  /* end modal */

  /* start becometutor */
  const [modalEducatorstep1Show, setEducatorstep1ModalShow] =
    React.useState(false);
  /* end becometutor */

  /* startsave gamed step1 */
  const [modalgamesavedstep1Show, setgamesavedstep1ModalShow] =
    React.useState(false);

  /* end save gamned step1 */

  /* start Tutor signup step6 */
  const [modalTutorstep7Show, setTutorstep7ModalShow] = React.useState(false);
  /* end Tutor signup step6 */
  /* start Tutor signup step2 */
  const [modalTutorstep2Show, setTutorstep2ModalShow] = React.useState(false);
  /* end Tutor signup step2 */
  const [profileDetails, setprofileDetails] = React.useState({
    stateId: "",
    cityId: "",
    countryId: 101,
    firstName: "",
    lastName: "",
    country: "",
    city: "",
    phoneNumber: "",
    yearsOfBirth: "",
    gender: "",
    workExperience: 0,
    genderId: "",
    subjects: [],
  });

  console.log(userDetails);

  const fetchProfileDetails = async () => {
    try {
      const res = await getProfileDetails();
      setUserDetails(res);
      if (res) {
        setValue([res.startStudentGrade, res.endStudentGrade]);
      }
      setprofileDetails(res);
    } catch (err) {}
  };

  console.log(countryId);

  useEffect(() => {
    fetchProfileDetails();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: profileDetails,
    // validationSchema: Yup,
  });

  const fetchCountries = async () => {
    try {
      const response = await fetchData("countries");
      if (response && response.data) {
        setCountries(response.data);

        const filteredCountry = filterCountryId(
          response.data,
          userDetails?.country,
        );

        if (countryId === undefined) {
          filteredCountry.map((f) => {
            setCountryId(f.id);
          });
        }
      }
    } catch (error) {}
  };

  const fetchCitiesOnLoad = async () => {
    const res = await fetchCities("cities", countryId);
    setCities(res.data);

    const cityIdArray = filterCityId(res.data, userDetails?.city);
    console.log(cityIdArray);
    if (cityId === undefined) {
      cityIdArray.map((c) => {
        setCityId(c.id);
      });
    }
  };

  console.log(userDetails);

  useEffect(() => {
    if (userDetails != null) {
      fetchCountries();
    }
  }, [userDetails]);

  useEffect(() => {
    if (countryId != undefined) {
      fetchCitiesOnLoad();
    }
  }, [countryId]);

  useEffect(() => {
    const year = new Date("12/12/1960").getFullYear();
    const yea = Array.from(new Array(60), (val, index) => index + year);
    setYears(yea);
  }, []);

  const updateProfile = async () => {
    try {
      if (userDetails.roleId === 4) {
        setIsloading(true);
        const res = await updatePrantProfile(
          formik.values.firstName,
          formik.values.lastName,
          parseInt(countryId),
          parseInt(cityId),
          parseInt(formik.values.genderId),
        );
        setIsloading(false);
      } else {
        setIsloading(true);
        console.log(
          "data sent for update",
          formik.values.firstName,
          formik.values.lastName,
          parseInt(userDetails?.id),
          parseInt(countryId),
          parseInt(cityId),
          parseInt(formik.values.genderId),
          parseInt(value[0]),
          parseInt(value[1]),
          parseInt(formik.values.yearsOfBirth),
          parseInt(formik.values.workExperience),
        );
        const res = await updateEducatorProfile(
          formik.values.firstName,
          formik.values.lastName,
          parseInt(userDetails?.id),
          parseInt(countryId),
          parseInt(cityId),
          parseInt(formik.values.genderId),
          parseInt(value[0]),
          parseInt(value[1]),
          parseInt(formik.values.yearsOfBirth),
          parseInt(formik.values.workExperience),
        );
        showNotification("profile updated successfully");

        setIsloading(false);
      }
    } catch (err) {
      snackOpen("please make changes before save", "error");
      console.log(err);
      setIsloading(false);
    }
  };

  //console.log("form values", formik.values);
  return (
    <div className="home-wrapper">
      {/* start header */}

      {state.roleId === userRole.TUTOR ? (
        <HeaderTutor />
      ) : (
        <HeaderGames setModalShow={(value) => setModalShow(value)} />
      )}

      {/* end header */}
      <CustomSnackbar {...snackProps} />

      <div className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs">
        <div className="myprofile-main">
          <div className="profilesection">
            <Container>
              <div className="profilemain-card">
                <Tabs>
                  <Row>
                    <div className="col-lg-5">
                      <div className="profile-tabs">
                        <div className="profileshape-bg"></div>
                        <div className="profile-pic">
                          <div className="profile-image">
                            {" "}
                            <img
                              style={{ cursor: "pointer" }}
                              onClick={() => history.push("/tutor/profilePage")}
                              src={profilepic}
                              className="img-fluid"
                            />{" "}
                            <div className="file-upnew">
                              <Form.Control
                                type="file"
                                className="brower-btn"
                                id="input-file"
                              />
                              <label
                                htmlFor="input-file"
                                className="profile-uplod-btn"
                              >
                                <i className="fas fa-camera"></i>
                              </label>
                            </div>
                          </div>
                          <Box
                            sx={{
                              mt: "1rem",
                              display: "flex",
                              alignItems: "start",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              onClick={() => history.push("/tutor/profilePage")}
                              sx={{
                                cursor: "pointer",
                                color: "black",
                                fontSize: "1.3rem",
                                fontWeight: "700",
                              }}
                            >
                              Rajesh
                            </Typography>
                            {!isTutor && (
                              <>
                                <Typography
                                  sx={{ fontWeight: "700", cursor: "pointer" }}
                                  onClick={() =>
                                    history.push("/educator/tutorLevel")
                                  }
                                >
                                  Advanced level
                                </Typography>
                                <Stack flexDirection="row">
                                  <Typography>4.0</Typography>
                                  <Rating value={4} readOnly />
                                </Stack>
                              </>
                            )}
                          </Box>
                        </div>
                        <div className="profile-lftsidee">
                          {/* start my profile sidebar */}
                          <Myprofilesidebar />
                          {/* end  my profile sidebar */}

                          <div className="profile-footer">
                            {!isTutor && (
                              <button
                                type="submit"
                                className="btnlogin btnweak-bx"
                                onClick={() => {
                                  setEducatorstep1ModalShow(true);
                                }}
                              >
                                Became a tutor
                              </button>
                            )}
                            <Becometutornew
                              modalEducatorstep1Show={modalEducatorstep1Show}
                              setEducatorstep1ModalShow={(value) =>
                                setEducatorstep1ModalShow(value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {userDetails.roleId === 4 ? (
                      <div className="col-lg-7">
                        <div className="myprofile-right">
                          <div className="tab-heading">My profile</div>
                          <div className="profile-form-section">
                            <form className="form-myprofile">
                              <div className="personal-info-headg">
                                <h4>Personal info</h4>
                              </div>
                              <ul>
                                <li>
                                  <label>My first name is *</label>
                                  <Form.Group className="subsform-fild">
                                    <Form.Control
                                      onChange={formik.handleChange}
                                      name="firstName"
                                      value={formik.values.firstName}
                                      type="text"
                                      placeholder="Valentina"
                                    />
                                  </Form.Group>
                                </li>
                                <li>
                                  <label>My last name is *</label>
                                  <Form.Group className="subsform-fild">
                                    <Form.Control
                                      value={formik.values.lastName}
                                      onChange={formik.handleChange}
                                      name="lastName"
                                      type="text"
                                      placeholder="Rosembaum"
                                    />
                                  </Form.Group>
                                </li>
                                <li>
                                  <label>My email is *</label>
                                  <Form.Group className="subsform-fild">
                                    <Form.Control
                                      disabled
                                      type="text"
                                      placeholder="EMAIL"
                                      value={formik.values.email}
                                      readOnly
                                    />
                                  </Form.Group>
                                </li>
                                <li>
                                  <label>My mobile phone is *</label>
                                  <div className="phone-bar">
                                    <div className="country-cd">
                                      <img
                                        src={flag}
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </div>
                                    <div className="number-fld">
                                      <Form.Group className="subsform-fild">
                                        <Form.Control
                                          type="text"
                                          readOnly
                                          disabled
                                          placeholder="Phone Number"
                                          value={formik.values.phoneNumber}
                                          // {...profileFormik.getFieldProps(
                                          //   "phoneNumber",
                                          // )}
                                        />
                                      </Form.Group>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <label>I live in *</label>
                                  <Row>
                                    <div className="col-md-6">
                                      <Form.Group className="subsform-fild">
                                        <Form.Control
                                          as="select"
                                          onChange={(e) =>
                                            setCountryId(e.target.value)
                                          }
                                        >
                                          <option value={countryId}>
                                            {formik.values.country}
                                          </option>

                                          {countries
                                            .sort((a, b) =>
                                              a?.name.localeCompare(b.name),
                                            )
                                            .map((country, index) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={country?.id}
                                                >
                                                  {country?.name}
                                                </option>
                                              );
                                            })}
                                        </Form.Control>
                                      </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                      <Form.Group className="subsform-fild">
                                        <Form.Control
                                          as="select"
                                          onChange={(e) =>
                                            setCityId(e.target.value)
                                          }
                                        >
                                          <option value={cityId}>
                                            {formik.values.city}
                                          </option>
                                          {isProfileLoading ? (
                                            <option>Loading..</option>
                                          ) : (
                                            cities.map((city, index) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={city?.id}
                                                >
                                                  {city?.name}
                                                </option>
                                              );
                                            })
                                          )}
                                        </Form.Control>
                                      </Form.Group>
                                    </div>
                                  </Row>
                                </li>
                                {/* <li>
                                  <Row>
                                    <div className="col-md-6">
                                      <label>My gender</label>
                                      <Form.Group className="subsform-fild">
                                        <Form.Control as="select"
                                          onChange={formik.handleChange}
                                          name="genderId"
                                        
                                        >
                                          <option value={1}>Male</option>
                                          <option value={2}>Female</option>
                                        </Form.Control>
                                      </Form.Group>
                                    </div>
                                  </Row>
                                </li> */}
                                {/* <li>
                                  <div className="btn-week">
                                    <div
                                      onClick={() => updateProfile()}
                                      className="btnweak-bx"
                                    >
                                      <span className="btn-title">Save</span>
                                    </div>
                                  </div>
                                </li> */}
                                <li>
                                  <div className="btn-week">
                                    <div
                                      style={{ cursor: "pointer" }}
                                      onClick={() => updateProfile()}
                                      className="btnweak-bx"
                                    >
                                      <span className="btn-title">
                                        {isLoading ? (
                                          <CircularProgress
                                            size={"1.5rem"}
                                            sx={{ color: "var(--purple)" }}
                                          />
                                        ) : (
                                          "Save"
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </form>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="col-lg-7">
                        <div className="myprofile-right">
                          <div className="tab-heading">My profile</div>
                          <div className="profile-form-section">
                            <form className="form-myprofile">
                              <div className="personal-info-headg">
                                <h4>Personal info</h4>
                              </div>
                              <ul>
                                <li>
                                  <label>My first name is *</label>
                                  <Form.Group className="subsform-fild">
                                    <Form.Control
                                      onChange={formik.handleChange}
                                      name="firstName"
                                      value={formik.values.firstName}
                                      type="text"
                                      placeholder="Valentina"
                                    />
                                  </Form.Group>
                                </li>
                                <li>
                                  <label>My last name is *</label>
                                  <Form.Group className="subsform-fild">
                                    <Form.Control
                                      value={formik.values.lastName}
                                      onChange={formik.handleChange}
                                      name="lastName"
                                      type="text"
                                      placeholder="Rosembaum"
                                    />
                                  </Form.Group>
                                </li>

                                <li>
                                  <label>My email is *</label>
                                  <Form.Group className="subsform-fild">
                                    <Form.Control
                                      disabled
                                      type="text"
                                      placeholder="EMAIL"
                                      value={formik.values.email}
                                      readOnly
                                    />
                                  </Form.Group>
                                </li>
                                <li>
                                  <label>My mobile phone is *</label>
                                  <div className="phone-bar">
                                    <div className="country-cd">
                                      <img
                                        src={flag}
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </div>
                                    <div className="number-fld">
                                      <Form.Group className="subsform-fild">
                                        <Form.Control
                                          type="text"
                                          readOnly
                                          disabled
                                          placeholder="Phone Number"
                                          value={formik.values.phoneNumber}
                                          // {...profileFormik.getFieldProps(
                                          //   "phoneNumber",
                                          // )}
                                        />
                                      </Form.Group>
                                    </div>
                                  </div>
                                </li>

                                <li>
                                  <label>I live in *</label>
                                  <Row>
                                    <div className="col-md-6">
                                      <Form.Group className="subsform-fild">
                                        <Form.Control
                                          as="select"
                                          onChange={(e) =>
                                            setCountryId(e.target.value)
                                          }
                                        >
                                          <option value={countryId}>
                                            {formik.values.country}
                                          </option>

                                          {countries
                                            .sort((a, b) =>
                                              a?.name.localeCompare(b.name),
                                            )
                                            .map((country, index) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={country?.id}
                                                >
                                                  {country?.name}
                                                </option>
                                              );
                                            })}
                                        </Form.Control>
                                      </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                      <Form.Group className="subsform-fild">
                                        <Form.Control
                                          as="select"
                                          onChange={(e) =>
                                            setCityId(e.target.value)
                                          }
                                        >
                                          <option value={cityId}>
                                            {formik.values.city}
                                          </option>
                                          {isProfileLoading ? (
                                            <option>Loading..</option>
                                          ) : (
                                            cities.map((city, index) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={city?.id}
                                                >
                                                  {city?.name}
                                                </option>
                                              );
                                            })
                                          )}
                                        </Form.Control>
                                      </Form.Group>
                                    </div>
                                  </Row>
                                </li>
                                {/* <li>
                                  <Row>
                                    <div className="col-md-6">
                                      <label>Year of my birth</label>
                                      <Form.Group className="subsform-fild">
                                        <Form.Control as="select"
                                      onChange={formik.handleChange}
                                      name="yearsOfBirth"
                                        >
                                        <option value={formik.values.yearsOfBirth}>
                                                  {formik.values.yearsOfBirth}
                                                </option>
                                              {years.map((year, index) => {
                                              return (
                                                <option key={index} value={year}>
                                                  {year}
                                                </option>
                                              );
                                            })}
                                        </Form.Control>
                                      </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                      <label>My gender</label>
                                      <Form.Group className="subsform-fild">
                                        <Form.Control as="select"
                                          onChange={formik.handleChange}
                                          name="genderId"
                                        
                                        >
                                          <option value={1}>Male</option>
                                          <option value={2}>Female</option>
                                        </Form.Control>
                                      </Form.Group>
                                    </div>
                                  </Row>
                                </li> */}
                                {/* <div className="personal-info-headg">
                                  <h4>Personal info</h4>
                                </div> */}
                                {/*   
                                <li>
                                  <label>My subjects are</label>
                                  <div className="educator-subject-list">
                                    <ul className="subj-list-box">
                                      {profileDetails.syllabusList?.map(
                                        (syllabus) => {
                                          return (
                                            <li key={syllabus.value}>
                                              <label className="subject-checkk">
                                                {syllabus.text}
                                                <input
                                                  type="checkbox"
                                                  name="math"
                                                />
                                                <span className="checkmark"></span>
                                              </label>
                                            </li>
                                          );
                                        },
                                      )}
                                      <li>
                                        <label className="subject-checkk">
                                          Maths
                                          <input type="checkbox" name="math" />
                                          <span className="checkmark"></span>
                                        </label>
                                      </li>
                                      <li>
                                        <label className="subject-checkk">
                                          History
                                          <input type="checkbox" />
                                          <span className="checkmark"></span>
                                        </label>
                                      </li>
  
                                      <li>
                                        <label className="subject-checkk">
                                          English
                                          <input type="checkbox" />
                                          <span className="checkmark"></span>
                                        </label>
                                      </li>
  
                                      <li>
                                        <label className="subject-checkk">
                                          Physics
                                          <input type="checkbox" />
                                          <span className="checkmark"></span>
                                        </label>
                                      </li>
  
                                      <li>
                                        <label className="subject-checkk">
                                          Biology
                                          <input type="checkbox" />
                                          <span className="checkmark"></span>
                                        </label>
                                      </li>
  
                                      <li>
                                        <label className="subject-checkk">
                                          Chemistry
                                          <input type="checkbox" />
                                          <span className="checkmark"></span>
                                        </label>
                                      </li>
  
                                      <li>
                                        <label className="subject-checkk">
                                          Literature
                                          <input type="checkbox" />
                                          <span className="checkmark"></span>
                                        </label>
                                      </li>
  
                                      <li>
                                        <label className="subject-checkk">
                                          Astronomy
                                          <input type="checkbox" />
                                          <span className="checkmark"></span>
                                        </label>
                                      </li>
                                      <li>
                                        <label className="subject-checkk">
                                          Geography
                                          <input type="checkbox" />
                                          <span className="checkmark"></span>
                                        </label>
                                      </li>
                                    </ul>
                                  </div>
                                </li> */}

                                {/* <li>
                                  <label>I work with children of age</label>
                                  <div className="age-slidermax">
                                    <div className="ageslider-maxx">
                                      <Box>
                                        <Slider
                                          value={value}
                                          min={5}
                                          step={1}
                                          max={30}
                                          getAriaValueText={valueLabelFormat}
                                          valueLabelFormat={valueLabelFormat}
                                          onChange={handleChange}
                                          valueLabelDisplay="auto"
                                          aria-labelledby="non-linear-slider"
                                        />
                                      </Box>
                                    </div>
                                  </div>
                                </li> */}

                                {/* <li>
                                  <label>
                                    My work experience as an educator is
                                  </label>
                                  <Form.Control
                                    onChange={formik.handleChange}
                                    name="workExperience"
                                    value={formik.values.workExperience}
                                    type="Number"
                                    placeholder="Valentina"
                                  
                                  >
                                    
                                  </Form.Control>
                                </li> */}

                                <li>
                                  <div className="btn-week">
                                    <div
                                      style={{ cursor: "pointer" }}
                                      onClick={() => updateProfile()}
                                      className="btnweak-bx"
                                    >
                                      <span className="btn-title">
                                        {isLoading ? (
                                          <CircularProgress
                                            size={"1.5rem"}
                                            sx={{ color: "var(--purple)" }}
                                          />
                                        ) : (
                                          "Save"
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </form>
                          </div>
                        </div>
                      </div>
                    )}
                  </Row>
                </Tabs>
              </div>
            </Container>
          </div>
        </div>

        {/* footer start */}
        <FooterEducator logo={logo} />
        {/* footer end */}
      </div>

      {/* start modal my student */}

      {/* start game save step1 */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        // {...props}
        show={modalgamesavedstep1Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2 mystudent-games">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>The game is saved</h4>
              <h5>Send the game to my students</h5>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setgamesavedstep1ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="serch-inner-group">
                    <div className="serchfiled-activities">
                      <Form.Control
                        type="text"
                        placeholder="Start typing name or year of study"
                        className="formbox"
                      />
                      <Button type="button" className="seachbtn">
                        <i className="fas fa-search"></i>
                      </Button>
                    </div>
                  </div>

                  <div className="newpupil-list-bx">
                    <Form.Group className="subsform-fild child-tex-fld height-max-flow">
                      <div className="child-boxnew">
                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Aaradhya Rayasam</h5>
                                  <h6>India, Mumbai 1 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Chandrashekhar Kalvakuntla</h5>
                                  <h6>India, Mumbai 3 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>7F Class</h5>
                                  <h6>Hamut Kapit, Kemal Mara and 12 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Additional group</h5>
                                  <h6>Laska Doe, Hamut Kapit and 4 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>8A Class for exams</h5>
                                  <h6>Hamut Kapit, Laska Doe and 8 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Chandrashekhar Kalvakuntla</h5>
                                  <h6>India, Mumbai 3 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </Form.Group>

                <div className="student-btn-savedgame">
                  <div className="btnlogin-main">
                    <button type="submit" className="btnlogin btnweak-bx">
                      Send to selected students
                    </button>
                  </div>
                </div>
                <div className="btnlogin-main">
                  <button type="submit" className="btnlogin btnweak-bx">
                    Later
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end Add a Member Group step2 */}

      {/* end modal my student */}
    </div>
  );
}

export default Myprofile;
