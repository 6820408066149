import { getAllBlogs } from "../service/AllApi";
import { Axios } from "../service/Axios";
import { LocalStorage } from "../service/LocalStorage";

export const updateToken = async ({ state, effects, actions }) => {
  const fetchedToken = await LocalStorage.getAuthToken();
  // console.log("updating token, ", fetchedToken);
  if (fetchedToken) {
    state.token = true;
  } else {
    state.token = false;
  }
};

export const resetToken = async ({ state, effects, actions }, value) => {
  state.token = value;
};

export const updateRoleId = async ({ state }) => {
  const RoleId = await LocalStorage.getRoleId();

  // console.log("role if from func ", RoleId);
  state.roleId = RoleId;
};
export const resetRoleId = async ({ state }, value) => {
  // console.log("resetRolid ", value);

  state.roleId = value;
};
export const setSelectedSubjectId = async ({ state }, value) => {
  state.selectedSubjectID = value;
};

export const updateIsFirstTimeLogin = async ({ state }, value) => {
  // state.
};

export const openQuestionnaire = ({ state }, value) => {
  state.isQuestionnaireOpen = value;
};
export const openEducatorQuestionnaire = ({ state }, value) => {
  state.isEducatorQuestionnaireOpen = value;
};
export const logOutUser = async ({ state }) => {
  // console.log(state, "logout");
  localStorage.clear();
  state.token = false;
  // state.roleId = 0;
};

export const getKeyValues = async ({ state }) => {
  const { data } = await Axios.get("/public/all-enum-values");
  state.keyValues = data;
};
export const getBlogs = async ({ state }) => {
  const res = await getAllBlogs();
  if (res && res.rows) state.blogs = res.rows;
};


export const getTestData = ({state},value)=>{
    state.testData = value;
}