import { useState, useCallback, useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";

import "../../home.scss";
import "../../profile.scss";
import "../../loginModal/login.scss";
import "../../dashboard.scss";
import "../../sidemenu.scss";
import "../../educator.scss";
import "../../media.scss";
// import logo from "../assets/images/logo.png";
import breadmenu from "../../../assets/images/breadmenu.svg";
import languages from "../../../assets/images/languages.svg";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { Link, useHistory, useLocation } from "react-router-dom";
import LoginModal from "../../loginModal/Login";
import Signup from "../../signup";
import crossicon from "../../../assets/images/crossicon.svg";
import { useAppState, useActions } from "../../../overmind";
import { roleBasedRedirect } from "../../../service/commanFunction";
const logo = "/neitheduLogo.png";
const HeaderGames = () => {
  const state = useAppState();
  const actions = useActions();
  const [breadMenuShow, setBreadMenuShow] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const [link, setLink] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [modalSignupShow, setModalSignupShow] = useState(false);
  const [redirectTo, setRedirectTo] = useState("/");
  useEffect(() => {
    setLink(location?.pathname);
  }, []);
  useEffect(async () => {
    if (!state.token) return;
    const redirectPage = await roleBasedRedirect(state.roleId);
    setRedirectTo(redirectPage);
  }, [state.token]);
  const logOutUser = useCallback(async () => {
    actions.logOutUser();
    history.push("/");
  });

  return (
    <>
      {location.pathname === "/dashboard" ||
      location.pathname === "/profile" ||
      location.pathname === "/games" ||
      location.pathname === "/MyActivities" ||
      location.pathname === "/Allfiles" ||
      location.pathname === "/Instruction" ||
      location.pathname === "/newlesson" ||
      location.pathname === "/Gameflow" ||
      location.pathname === "/educator" ||
      location.pathname === "/tutor" ||
      location.pathname === "/Messages" ||
      location.pathname === "/Gamesflowphotos" ||
      location.pathname === "/Selectedgames" ||
      location.pathname === "/Gamesflowstwo" ||
      location.pathname === "/Questionone" ||
      location.pathname === "/Mymaterials" ||
      location.pathname === "/Gamesflowsnew" ||
      location.pathname === "/Creatingmaterial" ||
      location.pathname === "/Videosmaterialadd" ||
      location.pathname === "/Addmymaterials" ||
      location.pathname === "/Completedlesson" ||
      location.pathname === "/Newlivelession" ||
      location.pathname.includes("/LiveLessonDetail") ||
      location.pathname === "/Newlivelessionthree" ||
      location.pathname === "/Completedlessontwo" ||
      location.pathname === "/Studentchat" ||
      location.pathname === "/Myprofile" ||
      location.pathname === "/Subscription" ||
      location.pathname === "/Earnings" ||
      location.pathname === "/Security" ||
      location.pathname === "/Notifications" ||
      location.pathname === "/Support" ||
      location.pathname === "/Howearn" ||
      location.pathname === "/GameQuiz" ||
      location.pathname === "/PersonalTask" ||
      location.pathname === "/MyGameFlow" ||
      location.pathname === "/GameflowNotchecked" ||
      location.pathname === "/GamesTabs" ||
      location.pathname === "/educator/selectMyGames" ||
      location.pathname === "/educator/gamequizReport" ||
      location.pathname === "/educator/quizStatus" ||
      location.pathname === "/educator/quizNotChecked" ||
      location.pathname === "/educator/quizLate" ||
      location.pathname.slice(0, 33) === "/educator/groupTaskDeadlineMissed" ||
      location.pathname.slice(0, 28) === "/educator/student-game-details" ||
      location.pathname === "/educator/tutorLevel" ||
      location.pathname === "/educator/badgePayments" ||
      location.pathname === "/tutor/levelsInfo" ||
      location.pathname === "/educator/selectMyMaterials" ||
      location.pathname === "/GroupGameFlow" ||
      location.pathname === "/StudentGameFlow" ||
      location.pathname.slice(0, 13) === "/PersonalTask" ||
      location.pathname === "/educator/selectNeitheduGames" ? (
        <div className="home-wrapper educator-headermain">
          <div className={`menuside ${breadMenuShow ? "showmenu" : ""}`}>
            <div className="sidemenubar-main">
              <div className="logobar-top">
                <div className="logoimg">
                  <Link to={redirectTo}>
                    <img
                      src={logo}
                      alt="logo"
                      style={{ width: "15rem" }}
                      className="img-fluid"
                    />
                  </Link>
                </div>
                <div className="close-sidebar">
                  <button
                    className="btnclose"
                    onClick={() => {
                      setBreadMenuShow(false);
                      // document.body.classList.remove("openheader-dashbd");
                    }}
                  >
                    <img src={crossicon} className="img-fluid" />
                  </button>
                </div>
              </div>

              <div className="sidemenubar-list">
                <ul className="list-sidemenu">
                  <li>
                    <Link
                      to="/educator"
                      className="educator-menu-link sidemenu-tex"
                    >
                      My students <span className="child-num">3</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/Mymaterials"
                      className="educator-menu-link sidemenu-tex"
                    >
                      Materials
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/GamesTabs"
                      className="educator-menu-link active sidemenu-tex"
                    >
                      Games
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      to="Messages"
                      className="educator-menu-link sidemenu-tex"
                    >
                      Messages <span className="child-num">3</span>
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>

          <div className="dashboard-inner-sidemenu">
            <div className="inner-page-topbar profile-topbar dashboard-topbarr">
              {/* sidemenu bar  */}
              <div className="custome-container">
                <div className="header-holder navbar-top">
                  <div className="lft-menu">
                    <ul className="header-btn-top profile-menubar">
                      <li>
                        {state.token ? (
                          <Navbar.Brand href={redirectTo}>
                            <img
                              src={logo}
                              alt="logo"
                              style={{ width: "15rem" }}
                              className="img-fluid"
                            />
                          </Navbar.Brand>
                        ) : (
                          <Navbar.Brand href="/">
                            <img
                              src={logo}
                              alt="logo"
                              style={{ width: "15rem" }}
                              className="img-fluid"
                            />
                          </Navbar.Brand>
                        )}
                      </li>
                    </ul>
                  </div>
                  <div className="center-navbar desktop-only-shw">
                    <ul className="educator-menubar">
                      <li>
                        <Link to="/educator" className="educator-menu-link">
                          My students<span className="child-num">3</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/Mymaterials" className="educator-menu-link">
                          Materials
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/GamesTabs"
                          className="educator-menu-link active"
                        >
                          Games
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <ul className="header-btn-top profile-menubar right-menu-dasboard">
                    <li className="btn-login-bx">
                      <Dropdown
                        className="dropdown-main dropmenu-custlistt"
                        autoclose="outside"
                      >
                        <Dropdown.Toggle
                          variant="success"
                          // id="dropdown-basic"
                          id="dropdown-autoclose-outside"
                          className="login-btn dropdown-btnn"
                          // onClick={() => setModalShow(true)}
                        >
                          <i className="fas fa-user"></i>
                        </Dropdown.Toggle>

                        {/* {visible === 0 && ( */}
                        <Dropdown.Menu className="dropdown-btn-item customdrop-listmenu">
                          <ul
                            style={{
                              cursor: "pointer",
                              backgroundColor: "#7973eb",
                              boxShadow: "0px 10px 30px rgb(0 0 0 / 8%)",
                              color: "#ffffff",
                              lineHeight: "20px",
                              fontSize: "14px",
                              marginBottom: "5px",
                              borderRadius: "12px",
                              padding: "12px 15px",
                              fontWeight: "700",
                            }}
                            onClick={() => setVisible((prev) => !prev)}
                          >
                            {visible ? (
                              <span className="rightarrow-icon">
                                <i
                                  style={{ color: "var(--orange)" }}
                                  className="fas fa-angle-up"
                                ></i>
                              </span>
                            ) : (
                              <span className="rightarrow-icon">
                                <i
                                  style={{ color: "var(--orange)" }}
                                  className="fas fa-angle-down"
                                ></i>
                              </span>
                            )}
                            <span style={{ marginLeft: "6px" }}>
                              English
                              <span
                                style={{ paddingLeft: "12px" }}
                                className="rightarrow-icon"
                              >
                                <img src={languages} alt="" />
                              </span>{" "}
                            </span>
                          </ul>

                          {visible && (
                            <>
                              <Dropdown.Item
                                style={{ justifyContent: "flex-start" }}
                              >
                                Hindi
                              </Dropdown.Item>
                              <Dropdown.Item>Bengali</Dropdown.Item>
                              <Dropdown.Item>Telugu</Dropdown.Item>
                              <Dropdown.Item>Punjabi</Dropdown.Item>
                              <Dropdown.Item>Tamil</Dropdown.Item>
                              <Dropdown.Item>Marathi</Dropdown.Item>
                            </>
                          )}
                          <Dropdown.Item
                            onClick={() => history.push("/Myprofile")}
                          >
                            <span className="rightarrow-icon">
                              <i className="fas fa-angle-right"></i>
                            </span>{" "}
                            My profile
                          </Dropdown.Item>

                          <Dropdown.Item href="" onClick={logOutUser}>
                            <span className="rightarrow-icon">
                              <i className="fas fa-angle-right"></i>
                            </span>{" "}
                            Logout
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>

                    <li className="btn-bx-get educator-mob-sidebar">
                      <Link
                        onClick={() => {
                          setBreadMenuShow(!breadMenuShow);
                          // document.body.classList.add("openheader-dashbd");
                        }}
                        to="#"
                        className="pro-bread"
                      >
                        <img src={breadmenu} className="img-fluid" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="header-holder navbar-top">
          <Navbar bg="light" expand="lg">
            {!state.token ? (
              <Navbar.Brand href="/">
                <img
                  src={logo}
                  alt="logo"
                  style={{ width: "15rem" }}
                  className="img-fluid"
                />
              </Navbar.Brand>
            ) : (
              <Navbar.Brand href={redirectTo}>
                <img
                  src={logo}
                  alt="logo"
                  style={{ width: "15rem" }}
                  className="img-fluid"
                />
              </Navbar.Brand>
            )}
            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <li>
                  {" "}
                  <Link to="/" className={link === "/" ? "active" : ""}>
                    Home
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link
                    to="/tutor"
                    className={link === "/tutor" ? "active" : ""}
                  >
                    Teachers
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link
                    to="/about"
                    className={link === "/about" ? "active" : ""}
                  >
                    About
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link to="/blog" className={link === "/blog" ? "active" : ""}>
                    Blog
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link
                    to="/contact"
                    className={link === "/contact" ? "active" : ""}
                  >
                    Contact
                  </Link>
                </li>
                <div className="mobileview-show btn-getstarted">
                  <div className="btn-bx-get">
                    <a href="#" className="get-started">
                      <span className="btntex">Get started</span>{" "}
                    </a>
                  </div>
                </div>
              </Nav>
            </Navbar.Collapse>

            <ul className="header-btn-top">
              <li className="btn-bx-get desktop-show">
                <a
                  href="#"
                  onClick={(value) => setModalSignupShow(value)}
                  className="get-started"
                >
                  <span className="btntex">Get started</span>{" "}
                </a>
              </li>
              <li className="btn-login-bx">
                {state.token ? (
                  <>
                    {" "}
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="login-btn"
                      >
                        <i className="fas fa-user"></i>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="/profile">Setting</Dropdown.Item>

                        <Dropdown.Item href="" onClick={logOutUser}>
                          Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
                ) : (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="login-btn"
                      onClick={() => setModalShow(true)}
                    >
                      <i className="fas fa-user"></i>
                    </Dropdown.Toggle>
                  </Dropdown>
                )}
              </li>
            </ul>
          </Navbar>
        </div>
      )}
      <LoginModal
        modalShow={modalShow}
        setModalShow={(value) => setModalShow(value)}
        setModalSignupShow={() => setModalSignupShow(true)}
      />
      <Signup
        modalSignupShow={modalSignupShow}
        setModalShow={(value) => setModalShow(value)}
        setModalSignupShow={(value) => setModalSignupShow(value)}
      />
    </>
  );
};

export default HeaderGames;
