import {
  Box,
  Divider,
  Grid,
  LinearProgress,
  Slider,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { CloseButton, StyledYellowButton } from "../../utils/Buttons";

export const Questions = ({
  isAllQuestionsAttempted,
  state,
  totalPages,
  questionsList,
  closeQuestionnaire,
  sliderValues,
  sliderHandler,
  sliderRange,
  changeScreen,
  screens,
  pageHandlers,
}) => {
  const [finishWarning, setfinishWarning] = useState(false);
  const getFontSize = (points) => {
    const p = Math.abs(parseInt(points));
    if (p === 0) return "0.8rem";
    else if (p === 1) return "0.83rem";
    else if (p === 2) return "0.89rem";
    else if (p === 3) return "0.93rem";
    else return "0.95rem";
  };
  const getFontWeight = (points) => {
    const p = Math.abs(parseInt(points));
    if (p === 0) return "500";
    else if (p === 1) return "550";
    else if (p === 2) return "600";
    else if (p === 3) return "700";
    else return "750";
  };
  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: "var(--white)",
        borderRadius: "10px",
        maxWidth: "50rem",
        m: "0rem auto 10rem auto",
        p: "2rem 1rem",
      }}
    >
      <>
        <CloseButton closeHandler={closeQuestionnaire} />

        {questionsList[state.page - 1].map((question, index) => {
          return (
            <Box
              sx={{
                mb: "0.7rem",
                textAlign: "center",
              }}
              key={question.question_no}
            >
              <Typography variant="h6" fontSize={"0.9rem"}>
                Question {question.question_no}
              </Typography>
              <Typography
                mb={"0.3rem"}
                fontSize={"1rem"}
                fontWeight={600}
                variant="h5"
              >
                {question.question}
              </Typography>
              <Grid
                mb={"0.3rem"}
                container
                alignItems={"center"}
                spacing={"1rem"}
              >
                <Grid fontSize="0.8rem" item xs={3}>
                  <Box
                    sx={{
                      height: "60px",
                    }}
                  >
                    {question.images ? (
                      <img
                        alt={question.question}
                        height={
                          state.questionPoints[question.question_no] < 0
                            ? `${
                                Math.round(
                                  Math.abs(
                                    state.questionPoints[question.question_no],
                                  ),
                                ) *
                                  10 +
                                60
                              }%`
                            : "60%"
                        }
                        src={question.images.left}
                      />
                    ) : (
                      <Typography
                        sx={{
                          fontSize:
                            state.questionPoints[question.question_no] < 0
                              ? getFontSize(
                                  state.questionPoints[question.question_no],
                                )
                              : "0.8rem",
                          fontWeight:
                            state.questionPoints[question.question_no] < 0
                              ? getFontWeight(
                                  state.questionPoints[question.question_no],
                                )
                              : "500",
                        }}
                      >
                        {question.answers.left}
                      </Typography>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Slider
                    track={false}
                    name={question.question_no}
                    onChange={(e, val) => {
                      sliderHandler(e.target.name, val);
                    }}
                    value={state.questionPoints[question.question_no]}
                    sx={{ color: "var(--purple)" }}
                    aria-label="Restricted values"
                    defaultValue={0}
                    valueLabelFormat={(e) => e.label}
                    step={null}
                    valueLabelDisplay="off"
                    marks={sliderValues}
                    max={sliderRange}
                    min={-sliderRange}
                  />
                </Grid>
                <Grid fontSize="0.8rem" item xs={3}>
                  <Box
                    sx={{
                      height: "60px",
                    }}
                  >
                    {question.images ? (
                      <img
                        height={
                          state.questionPoints[question.question_no] > 0
                            ? `${
                                Math.round(
                                  Math.abs(
                                    state.questionPoints[question.question_no],
                                  ),
                                ) *
                                  10 +
                                60
                              }%`
                            : "60%"
                        }
                        alt={question.question}
                        src={question.images.right}
                      />
                    ) : (
                      <Typography
                        sx={{
                          fontSize:
                            state.questionPoints[question.question_no] > 0
                              ? getFontSize(
                                  state.questionPoints[question.question_no],
                                )
                              : "0.8rem",
                          fontWeight:
                            state.questionPoints[question.question_no] > 0
                              ? getFontWeight(
                                  state.questionPoints[question.question_no],
                                )
                              : "500",
                        }}
                      >
                        {question.answers.right}
                      </Typography>
                    )}
                  </Box>
                </Grid>
              </Grid>
              {questionsList[state.page - 1].length - 1 !== index && (
                <Divider />
              )}
            </Box>
          );
        })}
      </>
      {finishWarning && (
        <Grid item xs={12}>
          <Typography sx={{ color: "var(--orange)", marginTop: "1rem" }}>
            Please attempt all questions.
          </Typography>
        </Grid>
      )}
      <Grid mt={"1.3rem"} alignItems={"end"} columnSpacing={"1.5rem"} container>
        <Grid item xs={3}>
          {state.page !== 1 && (
            <StyledYellowButton
              title={"Previous question"}
              btnHandler={() => {
                pageHandlers.previousPage();
                setfinishWarning(false);
              }}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <Typography textAlign={"center"} mb="0.5rem">
            {state.page}/{totalPages}
          </Typography>
          <LinearProgress
            variant="determinate"
            value={(state.page / totalPages) * 100}
          />
        </Grid>
        <Grid item xs={3}>
          {state.page === totalPages ? (
            <>
              <StyledYellowButton
                title={"Finish"}
                btnHandler={() => {
                  if (isAllQuestionsAttempted()) {
                    changeScreen();
                  } else {
                    setfinishWarning(true);
                  }
                }}
              />
            </>
          ) : (
            <Box width={"90%"}>
              <StyledYellowButton
                title={"Next questions"}
                btnHandler={() => {
                  pageHandlers.nextPage();
                  setfinishWarning(false);
                }}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
