import React, { useState } from "react";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import SignUpOptions from "../../SignUpOptions";

const Footer = ({ logo }) => {
  const [modalSignupShow, setModalSignupShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalEducatorShow, setEducatorModalShow] = React.useState(false);
  const [modalParentShow, setParentModalShow] = React.useState(false);
  const [modalStudentShow, setStudentModalShow] = React.useState(false);
  return (
    <div className="footer-main">
      <div className="custome-container">
        <div className="footer-section">
          <div className="footershape-bg"></div>
          <Row>
            <div className="col-md-5 one">
              <div className="foter-logo">
                <Link to="/">
                  <img
                    src="/neitheduLogo.png"
                    alt="logo"
                    style={{ width: "18rem" }}
                    className="img-fluid"
                  />
                </Link>
              </div>
            </div>
            <div className="col-md-7 two">
              <div className="foter-list">
                <div className="foter-listnew">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="#">How it works</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="#">Plans</Link>
                    </li>
                    <li>
                      <Link to="#">Games</Link>
                    </li>
                  </ul>
                  <ul>
                    <li
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        console.log("hello");
                        setModalSignupShow(true);
                      }}
                    >
                      <a to="#">For parents</a>
                    </li>
                    <li
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        console.log("hello");
                        setModalSignupShow(true);
                      }}
                    >
                      <a to="#">For teachers</a>
                    </li>
                    {modalSignupShow && (
                      <SignUpOptions
                        openLoginModal={() => setModalShow(true)}
                        modalSignupShow={modalSignupShow}
                        setEducatorModalShow={(value) =>
                          setEducatorModalShow(value)
                        }
                        setModalSignupShow={(value) =>
                          setModalSignupShow(value)
                        }
                        setParentModalShow={(value) =>
                          setParentModalShow(value)
                        }
                        setStudentModalShow={(value) =>
                          setStudentModalShow(value)
                        }
                      />
                    )}
                  </ul>
                  <ul>
                    <li>
                      <Link to="/blog">Blog</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contacts</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Row>
        </div>

        <div className="foter-copywrite">
          <ul>
            <li>1999-2021 Neithedu.</li>
            <li>All Rights Reserved</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
