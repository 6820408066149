import React, { useEffect, useState } from "react";
import "../../home.scss";
import "../../profile.scss";
import "../../media.scss";
import "../../games.scss";
import "../../loginModal/login.scss";
import "../../messages/messages.scss";
import "../../myactivities.scss";
import "../../gamestabs.scss";
import "../../gamesflowphotos.scss";
import "../../selectedgames.scss";
import "../../gamequiz.scss";
import { Container, Row, Button, Form, Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import backorange from "../../../assets/images/backorange.svg";

import { Link, useParams } from "react-router-dom";
import FooterEducator from "../../layout/Footer/FooterEducator";
import Selectstudent from "../../Selectstudent";
import { Box } from "@mui/material";
import {
  getSingalActivities,
  studentSingleGroupActivity,
} from "../../../service/apis/activities";
import { HeaderDashboard } from "../../layout/HeaderDashboard";
import { getSignalGames } from "../../../service/apis/games";
import { useAppState } from "../../../overmind";
import { LocalStorage } from "../../../service/LocalStorage";
import { getGameUrl } from "../../../utils/helperFunctions";

function GameDataViewStudent() {
  const info = useAppState();
  const taskId = useParams();
  const [task, setTask] = useState({});
  const [point, setPoint] = useState();
  const [title, settitle] = useState("");
  const [essence, setessence] = useState("");
  const [assignedById, setassignedById] = useState();
  const [deadline, setdeadline] = useState();
  const [gameData, setGameData] = useState();
  const { acId, id } = taskId;
  const [tokenID, setTokenId] = useState(info?.userDetail?.token?.value);
  const [formData, setFormdata] = useState({
    title: "",
    essence: "",
    assignedById: "",
    taskId: "",
    deadline,
    points: "",
    assignedToId: "",
  });

  useEffect(async () => {
    const fetchedToken = await LocalStorage.getAuthToken();
    setTokenId(fetchedToken);
  }, []);

  /* startsave gamed step1 */
  const [modalgamesavedstep1Show, setgamesavedstep1ModalShow] =
    React.useState(false);

  /* end save gamned step1 */

  /* end send revision popup */

  /* start select student */
  const [modalAddgroupstep2Show, setAddgroupstep2ModalShow] =
    React.useState(false);

  /* end select student */

  const gametypeFata = {
    Quiz: 1,
    Crossword: 2,
    Wordsearch: 3,
    Material: 4,
  };

  const gameType = (value) => {
    const res = gametypeFata[value];
    return res;
  };

  console.log(gameType("Quiz"));
  const isGameFlow = acId === "3" || acId === "4" ? true : false;
  useEffect(() => {
    const getDingal = async () => {
      const response = await getSingalActivities(acId, id, isGameFlow);
      console.log("gamedata1", response);
      setTask(response);
      setPoint(response.points);
      settitle(response.taskTitle);
      setessence(response.taskDescription);
      setassignedById(response.assignedById);
      setdeadline(response.deadline);
      setFormdata({
        ...formData,
        title: response.taskTitle,
        essence: response.taskDescription,
        assignedById: response.assignedById,
        deadline: response.deadline,
        points: response.points,
        assignedToId: response.assignedToId,
        taskId: response.taskId,
      });
    };
    getDingal();
  }, [acId, id]);

  const location = useLocation();

  console.log("task123", task);
  // activityTypeId is 5
  const facthGame = async () => {
    let _gameType =
      task?.activityTypeName === "My Material" ? 4 : gameType(task.gameType);
    const res = await getSignalGames(task.gameId, _gameType);
    console.log("gamedata", res);
    setGameData(res);
  };

  useEffect(() => {
    if (!task.gameId) return;
    facthGame();
  }, [task]);

  let gradeSuffix = "";
  if (task.grade === "1") {
    gradeSuffix = "st";
  } else if (task.grade === "2") {
    gradeSuffix = "nd";
  } else if (task.grade === "3") {
    gradeSuffix = "rd";
  } else {
    gradeSuffix = "th";
  }

  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderDashboard />
      {/* end header */}

      <div className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs">
        <div className="games-photosynths gamequiz-main">
          <Container>
            <div className="quiz-game-popup">
              <div className="current-lesson-main ">
                <div className="current-lesson-contain">
                  <span className="back-btndrop">
                    <Link to="/MyActivities" className="backbtn-lg">
                      <img src={backorange} />
                    </Link>
                  </span>
                  <div className="current-lesson-topbr">
                    <div
                      className="medm-tex orng-clr"
                      style={{
                        display: "flex",
                        gap: "2rem",
                        justifyContent: "center",
                      }}
                    >
                      <h4>
                        {task?.activityName || task?.activityTypeName}:{" "}
                        {task?.gameType}{" "}
                        {task?.activityTypeName === "My Material" && (
                          <>
                            {task?.noOfAudios > 0 && (
                              <span>
                                {task?.noOfAudios} audio
                                {task?.noOfAudios > 1 ? "s" : ""},{" "}
                              </span>
                            )}
                            {task?.noOfImages > 0 && (
                              <span>
                                {task?.noOfImages} image
                                {task?.noOfImages > 1 ? "s" : ""},{" "}
                              </span>
                            )}
                            {task?.noOfPdf > 0 && (
                              <span>
                                {task?.noOfPdf} pdf
                                {task?.noOfPdf > 1 ? "s" : ""},{" "}
                              </span>
                            )}
                            {task?.noOfVideos > 0 && (
                              <span>
                                {task?.noOfVideos} video
                                {task?.noOfVideos > 1 ? "s" : ""},{" "}
                              </span>
                            )}
                          </>
                        )}
                      </h4>
                      <h4>
                        {task.categoryName} {task?.grade && ","}
                        {task.grade}
                        {task?.grade && `${gradeSuffix} year`}
                      </h4>
                    </div>
                    <div>
                      {" "}
                      <h1>{task?.title}</h1>
                    </div>
                    <div
                      style={{
                        marginTop: "1rem",
                        padding: "2rem 2rem",
                        borderRadius: "20px",
                        backgroundColor: "rgba(255,255,255,0.1)",
                      }}
                    >
                      <div className="lesson-status-list quiz-gamelession">
                        <ul className="lesson-listing-curnt">
                          <li>
                            <div className="lesson-status-detail">
                              <span
                                className="medm-tex orng-clr"
                                style={{ color: "#fff" }}
                              >
                                Mode:
                              </span>
                              <span className="medm-tex orng-clr onelist">
                                {task.mode ? task.mode : "Active"}
                              </span>
                            </div>
                            {task?.status && (
                              <div className="lesson-status-detail">
                                <span
                                  className="medm-tex orng-clr "
                                  style={{ color: "#fff" }}
                                >
                                  Status:
                                </span>
                                <span className="medm-tex orng-clr onelist">
                                  {task?.status}
                                </span>
                              </div>
                            )}
                          </li>
                          <li>
                            <div className="lesson-status-detail">
                              <span
                                className="medm-tex orng-clr"
                                style={{ color: "#fff" }}
                              >
                                Created:
                              </span>
                              <span className="medm-tex orng-clr onelist">
                                {task?.creationDate?.slice(0, 10)}
                              </span>
                            </div>
                            <div className="lesson-status-detail">
                              <span
                                className="medm-tex orng-clr"
                                style={{ color: "#fff" }}
                              >
                                Deadline:
                              </span>
                              <span className="medm-tex orng-clr onelist">
                                {task?.deadline?.slice(0, 10)}
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className="lesson-status-detail">
                              {task?.activityTypeName !== "My Material" && (
                                <span
                                  className="medm-tex orng-clr"
                                  style={{ color: "#fff" }}
                                >
                                  Total points:
                                </span>
                              )}
                              <span className="medm-tex orng-clr onelist">
                                {task.totalPoints}
                              </span>
                            </div>
                            <div className="lesson-status-detail">
                              {task?.activityTypeName !== "My Material" && (
                                <span
                                  className="medm-tex orng-clr"
                                  style={{ color: "#fff" }}
                                >
                                  Earned points:
                                </span>
                              )}
                              <span className="medm-tex orng-clr onelist">
                                {task.earnedPoints}
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Box
                sx={{
                  backgroundColor: "rgba(255,255,255,0.1)",
                  borderRadius: "20px",
                  p: "2rem",
                  mt: "2rem",
                  position: "relative",
                  pt: "0.1rem",
                }}
              >
                <div
                  style={{
                    textAlign: "left",
                    width: "100%",
                    padding: "2rem 0rem",
                  }}
                >
                  <p className="medm-tex " style={{ color: "#fff" }}>
                    Description : {task.description}
                  </p>
                </div>

                <div
                  style={{ position: "absolute", bottom: "-15px", left: "30%" }}
                  className="send-revision"
                >
                  <ul className="send-revision-list">
                    <li>
                      <div className="btn-week">
                        <a
                          href={
                            isGameFlow
                              ? getGameUrl(task[0]?.gameURL, tokenID)
                              : getGameUrl(task?.gameURL, tokenID)
                          }
                          className="btnweak-bx"
                          style={{ cursor: "pointer" }}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className="btn-title">play game</span>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </Box>

              {task.status === "Done" && (
                <div
                  style={{ borderRadius: "2px", marginTop: "5rem" }}
                  className="question-scrlbar-bx"
                >
                  <div className="question-sheet-quiz">
                    <div className="questionbox-main beginer-box">
                      {task.quizQuestions?.map((item, i) => {
                        return (
                          <div
                            key={i}
                            className="iner-quest-report quizgame-bodytable"
                          >
                            <Row className="reportcard-box">
                              <div className="col-md-5 col-lg-4 one">
                                <div className="left-quedtions">
                                  <div className="Question-listbx">
                                    <div className="title">
                                      <span className="bold">Question</span>
                                      <span className="open-quest">
                                        {item.QuestionType}
                                      </span>
                                    </div>
                                    <div className="question-contwnt">
                                      <p>{item.QuestionText}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-7 col-lg-8 two">
                                <div className="answer-right">
                                  <div className="aswer-details">
                                    <div className="inner-quizgame-cont">
                                      <p>
                                        {item.QuestionType === "TrueFalse"
                                          ? item.Answer === "1"
                                            ? "true"
                                            : "false"
                                          : item.Answer}
                                      </p>
                                    </div>
                                    <div className="truefalse-quiz">
                                      <ul className="quiz-truebx">
                                        {item.IsCorrect === null ? (
                                          <li>
                                            <span className="true-icon-iner">
                                              {/* <i className="fas fa-times"></i> */}
                                            </span>
                                          </li>
                                        ) : item.IsCorrect ? (
                                          <li>
                                            <span className="true-icon-iner">
                                              <i className="fas fa-check"></i>
                                            </span>
                                          </li>
                                        ) : (
                                          <li>
                                            <span className="true-icon-iner">
                                              <i className="fas fa-times"></i>
                                            </span>
                                          </li>
                                        )}
                                        {/* <li>
                                        <span className="true-icon-iner">
                                          <i className="fas fa-times"></i>
                                        </span>
                                      </li> */}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Row>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Container>
        </div>

        {/* footer start */}
        <FooterEducator logo={logo} />
        {/* footer end */}
      </div>

      {/* start modal my student */}

      {/* start game save step1 */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        // {...props}
        show={modalgamesavedstep1Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2 mystudent-games">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>The game is saved</h4>
              <h5>Send the game to my students</h5>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setgamesavedstep1ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="serch-inner-group">
                    <div className="serchfiled-activities">
                      <Form.Control
                        type="text"
                        placeholder="Start typing name or year of study"
                        className="formbox"
                      />
                      <Button type="button" className="seachbtn">
                        <i className="fas fa-search"></i>
                      </Button>
                    </div>
                  </div>

                  <div className="newpupil-list-bx">
                    <Form.Group className="subsform-fild child-tex-fld height-max-flow">
                      <div className="child-boxnew">
                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Aaradhya Rayasam</h5>
                                  <h6>India, Mumbai 1 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Chandrashekhar Kalvakuntla</h5>
                                  <h6>India, Mumbai 3 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>7F Class</h5>
                                  <h6>Hamut Kapit, Kemal Mara and 12 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Additional group</h5>
                                  <h6>Laska Doe, Hamut Kapit and 4 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>8A Class for exams</h5>
                                  <h6>Hamut Kapit, Laska Doe and 8 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Chandrashekhar Kalvakuntla</h5>
                                  <h6>India, Mumbai 3 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </Form.Group>

                <div className="student-btn-savedgame">
                  <div className="btnlogin-main">
                    <button type="submit" className="btnlogin btnweak-bx">
                      Send to selected students
                    </button>
                  </div>
                </div>
                <div className="btnlogin-main">
                  <button type="submit" className="btnlogin btnweak-bx">
                    Later
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end Add a Member Group step2 */}

      {/* end modal my student */}

      {/* start select studen popup */}

      <Selectstudent
        modalAddgroupstep2Show={modalAddgroupstep2Show}
        setAddgroupstep2ModalShow={(value) => setAddgroupstep2ModalShow(value)}
      />

      {/* end select studen popup */}
    </div>
  );
}

export default GameDataViewStudent;
