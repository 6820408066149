import React, { useState, useEffect } from "react";
import "../home.scss";
import "../teachers.scss";
import "../media.scss";
import Skeleton from "@mui/material/Skeleton";
import leftarrow from "../../assets/images/leftarrow.png";
import rightarrow from "../../assets/images/rightarrow.png";
import slideC from "../../assets/images/gallery5.png";
import Slider from "react-slick";
import { getSubjects } from "../../service/apis/common";
import { Link } from "react-router-dom";
import TopicsList from "./TopicsList";
import Grid from "@mui/material/Grid";
import ActiveGames from "./ActiveGames";
import LastActivities from "./LastActivities";
// import { useAppState } from "../../overmind";
export const SubjectSlider = () => {
  const [subjects, setsubjects] = useState([]);
  const [selectedSubject, setselectedSubject] = useState(null);
  const [subsLoaded, setsubsLoaded] = useState(false);
  const [subsCount, setsubsCount] = useState(0);
  const [syllabusId, setSyllabusId] = useState(null);
  const currentData = JSON.parse(localStorage.getItem("user"));

  // const [activeSlide, setActiveSlide] = useState(0)
  // const sliderRef = useRef(null)

  // const slyder = useRef()

  // console.log(selectedSubject)
  // console.log(subjects)

  // slyder?.current?.Slider?.slickGoTo(3,true)

  // useEffect(()=>{
  //   slyder?.current?.Slider?.slickGoTo(3)
  // },[])

  // const syllabusId = userDetail?.applicationUser?.syllabusId;
  // console.log(currentData?.applicationUser.syllabusId, "syllabus id dashboard");
  useEffect(() => {
    setsubsLoaded(true);
    setSyllabusId(currentData?.applicationUser.syllabusId);
    setsubsLoaded(false);
  }, [currentData?.applicationUser.syllabusId]);
  // console.log(currentData.applicationUser.syllabusId, "ss");
  const getSlideNumber = (e) => {
    // this.setState({ activeSlide2: e})
    // this.slider.current.slickGoTo(1)
    setselectedSubject(subjects[e]);
  };
  let nearestOdd = (num) => {
    if (num % 2 === 0) {
      return num - 1;
    } else {
      if (num === 1) return 1;
      return num - 2;
    }
  };

  function SampleNextArrow2(props) {
    const { onClick } = props;
    return (
      <div onClick={onClick} className="previow__button2">
        <img src={rightarrow} className="img-fluid" alt="" />
      </div>
    );
  }

  function SamplePrevArrow2(props) {
    const { onClick } = props;
    return (
      <div
        // className={className}
        onClick={onClick}
        className="Next__button2"
      >
        <img src={leftarrow} className="img-fluid" alt="" />
      </div>
    );
  }

  const settingsnew = {
    className: "center",
    centerMode: true,
    infinite: true,
    lazyLoad: true,
    centerPadding: "10px",
    adaptiveHeight: true,
    focusOnSelect: true,
    afterChange: getSlideNumber,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow2 />,
    prevArrow: <SamplePrevArrow2 />,
    speed: 500,
    initialSlide: 2,
    slidesToShow: subsCount < 6 ? nearestOdd(subsCount) : 5,
    // beforeChange: (current, next) => this.setState({ activeSlide: next }),
    // afterChange: current => this.setState({ activeSlide2: current })
  };
  const settingsStandard = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "10px",
    slidesToShow: 5,
    nextArrow: <img src={rightarrow} className="img-fluid" alt="" />,
    prevArrow: <img src={leftarrow} className="img-fluid" alt="" />,
    speed: 500,
  };

  // useEffect(() => {

  //     sliderRef.current.slickGoTo(3)
  // }, [activeSlide])

  useEffect(() => {
    const fetchSubjects = async () => {
      if (syllabusId) {
        try {
          const res = await getSubjects(parseInt(syllabusId));
          // console.log(res, "subj");
          if (res.length > 0) {
            setsubjects(res);
            setsubsCount(res.length);
            setsubsLoaded(true);
            setselectedSubject(res[0]);
          } else {
            setsubsLoaded(false);
          }
        } catch (err) {
          setsubjects([]);
          setsubsLoaded(true);
          setsubsCount(0);
        }
      }
    };
    fetchSubjects(syllabusId);
    return () => {
      setsubjects([]);
      setsubsLoaded(true);
      setsubsCount(0);
    };
  }, [syllabusId]);

  return (
    <>
      <Grid
        container
        sx={{ my: "2rem", marginX: "auto", maxWidth: "100%" }}
        columnSpacing="2rem"
      >
        <ActiveGames />
        <LastActivities />
      </Grid>

      <div className="serching-testimonial">
        <Grid>
          <div className="serch-testi">
            <div className="testi-boxserch">
              {!subsLoaded ? (
                <Slider {...settingsStandard}>
                  {[1, 2, 3, 4, 5].map((e) => (
                    <div className="sliderbox" key={e}>
                      <div className="testi-mainsldr">
                        <div className="slider-cardbox">
                          <div className="slider-innercard">
                            <div className="serch-tutor-boxx">
                              <div className="cardbox-tutor">
                                <Skeleton
                                  variant="rounded"
                                  width={180}
                                  height={160}
                                />
                              </div>
                            </div>
                            <h5>
                              <Skeleton
                                variant="text"
                                sx={{ fontSize: "2rem" }}
                              />
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              ) : (
                <Slider {...settingsnew}>
                  {subjects.map((e) => (
                    <div className="sliderbox" key={e.id}>
                      <Link
                        to={{
                          pathname: "/games",
                          state: {
                            catId: e.id,
                            catName: e.name,
                            isTopic: false,
                          },
                        }}
                        className="gift-ico"
                      >
                        <div className="testi-mainsldr">
                          <div className="slider-cardbox">
                            <div className="slider-innercard">
                              <div className="serch-tutor-boxx">
                                <div className="cardbox-tutor">
                                  <img
                                    src={e.image ? e.image : slideC}
                                    alt="adsfa"
                                    style={{
                                      width: "180px",
                                      height: "160px",
                                      margin: "auto",
                                    }}
                                  />
                                </div>
                              </div>
                              <h5>{e.name}</h5>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </Slider>
              )}
            </div>
          </div>
        </Grid>
      </div>
      {selectedSubject != null ? (
        <div className="student-gallery">
          <div className="std-conatiner">
            <div className="std-gallerysection">
              <TopicsList topics={selectedSubject.topics} />
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
