const imageSrc = "/images/questionnaire";
export const questions = [
  {
    question_no: 1,
    question:
      "I better understand and remember lessons that are presented through:",
    answers: {
      left: "Meaningful bullet lists",
      right: "Charts and graphs",
    },
    letters: {
      left: "I",
      right: "A",
    },
    images: {
      left: `${imageSrc}/bulletpoints.png`,
      right: `${imageSrc}/chartgraph.png`,
    },
  },
  {
    question_no: 2,
    question: "My learning process can best be explained as:",
    answers: {
      left: "Creative chaos",
      right: "Patient accuracy",
    },
    letters: {
      left: "F",
      right: "N",
    },
    images: {
      left: `${imageSrc}/bulletpoints.png`,
      right: `${imageSrc}/chartgraph.png`,
    },
  },
  {
    question_no: 3,
    question: "In general, I prefer",
    letters: {
      left: "C",
      right: "K",
    },
    answers: {
      left: "Group dynamics",
      right: "Working alone",
    },
    images: {
      left: `${imageSrc}/group.jpg`,
      right: `${imageSrc}/individual.png`,
    },
  },
  {
    question_no: 4,
    question: "I quickly understand:",
    letters: {
      left: "S",
      right: "H",
    },
    answers: {
      left: "The essence of the material",
      right: "The details",
    },
    images: {
      left: `${imageSrc}/bulletpoints.png`,
      right: `${imageSrc}/chartgraph.png`,
    },
  },
  {
    question_no: 5,
    question: "I prefer:",
    letters: {
      left: "F",
      right: "E",
    },
    answers: {
      left: "Width of understanding and knowledge",
      right: "Precise execution and routine",
    },
  },
  {
    question_no: 6,
    question:
      "When a lesson leaves space for interpretations and alternative ways of understanding, I find it:",
    letters: {
      left: "E",
      right: "M",
    },
    answers: {
      left: "Exhausting",
      right: "Entertaining/Enjoyable",
    },
  },
  {
    question_no: 7,
    question: "I prefer being called",
    letters: {
      left: "F",
      right: "E",
    },
    answers: {
      left: "Innovative",
      right: "Creative",
    },
  },
  {
    question_no: 8,
    question: "When I am introduced to a new subject I:",
    letters: {
      left: "D",
      right: "C",
    },
    answers: {
      left: "Wait for someone to explain it better",
      right: "Immediately ask questions about it",
    },
  },
  {
    question_no: 9,
    question: "The easiest way for me to understand something is by:",
    letters: {
      left: "D",
      right: "L",
    },
    answers: {
      left: "Having some time to think about it",
      right: "Jumping right into it",
    },
  },
  {
    question_no: 10,
    question: "I am often seen as the one that:",
    letters: {
      left: "C",
      right: "D",
    },
    answers: {
      left: "Gets things done",
      right: "Thinks about everything",
    },
  },
  {
    question_no: 11,
    question: "When facing a challenge, I start by:",
    letters: {
      left: "K",
      right: "C",
    },
    answers: {
      left: "Trying to see things clearly as possible",
      right: "Testing out a bunch of possible solutions",
    },
  },
  {
    question_no: 12,
    question: "If it were up to me, I would mostly be learning:",
    letters: {
      left: "N",
      right: "F",
    },
    answers: {
      left: "Facts",
      right: "Theories",
    },
  },
  {
    question_no: 13,
    question: "I prefer materials that give me:",
    letters: {
      left: "M",
      right: "E",
    },
    answers: {
      left: "Something to think about",
      right: "Concrete advice",
    },
  },
  {
    question_no: 14,
    question: "I am usually better at:",
    letters: {
      left: "N",
      right: "F",
    },
    answers: {
      left: "Implementation",
      right: "Creation",
    },
  },
  {
    question_no: 15,
    question: "I am better at finding or creating:",
    letters: {
      left: "J",
      right: "B",
    },
    answers: {
      left: "Illustrations",
      right: "Synonyms",
    },
  },
  {
    question_no: 16,
    question: "I would say that:",
    letters: {
      left: "B",
      right: "A",
    },
    answers: {
      left: "I usually remember definitions and explanations better than images",
      right: 'Images get "stuck" in my head better than words or lessons',
    },
  },
  {
    question_no: 17,
    question: "Understanding graphs and diagrams:",
    letters: {
      left: "A",
      right: "I",
    },
    answers: {
      left: "Comes easy to me",
      right: "Requires me to think hard about what they represent",
    },
  },
  {
    question_no: 18,
    question: "I am somewhat better at remembering what people and things:",
    letters: {
      left: "I",
      right: "J",
    },
    answers: {
      left: "Are called",
      right: "Feel like",
    },
  },
  {
    question_no: 19,
    question: "For me, understanding how something works is:",
    letters: {
      left: "O",
      right: "G",
    },
    answers: {
      left: "More interesting than its purpose",
      right: "Less interesting than its purpose",
    },
  },
  {
    question_no: 20,
    question: "I prefer being shown:",
    letters: {
      left: "G",
      right: "H",
    },
    answers: {
      left: "The underlying theme of the material",
      right: "A step-by-step explanation",
    },
  },
  {
    question_no: 21,
    question: "When learning I:",
    letters: {
      left: "W",
      right: "P",
    },
    answers: {
      left: "Always start at the beginning",
      right: 'Prefer letting the material\'s sense "lead" me through it',
    },
  },
  {
    question_no: 22,
    question: "My learning process can best be described as:",
    letters: {
      left: "P",
      right: "H",
    },
    answers: {
      left: "Understanding the entirety, and later looking into what's it built from",
      right:
        'Understanding the elements and later connecting them into the "whole image"',
    },
  },
  {
    question_no: 23,
    question: "I often firstly:",
    letters: {
      left: "O",
      right: "S",
    },
    answers: {
      left: "Realize the basic elements of a concept",
      right: "Understand the concept as a whole",
    },
  },
  {
    question_no: 24,
    question: "I learn better by:",
    letters: {
      left: "L",
      right: "D",
    },
    answers: {
      left: "Watching and doing",
      right: "Listening and thinking",
    },
  },
  {
    question_no: 25,
    question: "Completely understanding something often includes me:",
    letters: {
      left: "C",
      right: "K",
    },
    answers: {
      left: "Experiencing it a lot",
      right: "Having enough time to really think about it",
    },
  },
  {
    question_no: 26,
    question:
      "It comes easy to me to represent materials from a lesson through:",
    letters: {
      left: "A",
      right: "I",
    },
    answers: {
      left: "Drawing it out",
      right: "Writing it down in my own words",
    },
  },
  {
    question_no: 27,
    question: "I often remember:",
    letters: {
      left: "B",
      right: "A",
    },
    answers: {
      left: "The lecture better",
      right: "The summary graph better",
    },
  },
  {
    question_no: 28,
    question: "When I start working on something:",
    letters: {
      left: "G",
      right: "O",
    },
    answers: {
      left: "I gloss over the entire material, looking for the most meaningful information",
      right:
        "I never go to the next part before completely understanding the previous one",
    },
  },
];
