import React, { useState, useCallback } from "react";
import { Form, Modal } from "react-bootstrap";
// import facebook from "../assets/images/facebook.svg";
// import linkedin from "../assets/images/linkedin.svg";
// import google from "../assets/images/google-plus.svg";
import { Link } from "react-router-dom";
// import { postDate } from "../../service/AllApi";
import { LocalStorage } from "../../service/LocalStorage";
import { roleBasedRedirect } from "../../service/commanFunction";
import { useHistory } from "react-router-dom";
import { useActions } from "../../overmind";
import { authenticate } from "../../service/apis/registration";
import { CircularProgress } from "@mui/material";
import { CustomSnackbar, useSnackState } from "../../utils/useSnackState";
// import { useNavigate } from "react-router-dom";
const LoginModal = ({
  setopenForgotPassword,
  setModalShow,
  modalShow,
  setModalSignupShow,
  setRedirectTo,
}) => {
  const actions = useActions();
  const [snackProps, open] = useSnackState();
  const history = useHistory();
  const [isLoading, setisLoading] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState({
    email: null,
    password: null,
  });

  const [viewPassword, setviewPassword] = useState("password");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setError({
      ...error,
      [name]: null,
    });
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleLogin = async () => {
    setisLoading(true);
    try {
      if (Object.values(values).filter((x) => x === "")?.length > 0) {
        setError({
          ...error,
          email: values.email === "" ? "Please enter email" : null,
          password: values.password === "" ? "Please enter password" : null,
        });
        setisLoading(false);
        return false;
      }
      const res = await authenticate(values.email, values.password);
      if (res.isSuccess) {
        console.log("authres", res);
        await LocalStorage.saveLogin(res);
        await actions.updateToken();
        await actions.updateRoleId();
        const redirectPage = roleBasedRedirect(res.role, res.isFirstTimeLogin);
        history.push(redirectPage);
        // if(redirectPage){
        //   history.push(`/${redirectPage}`);
        // }else{
        //   history.push("/dashboard");
        // }

        // navigate("/dashb")

        setRedirectTo(redirectPage);

        setModalShow(false);
        setisLoading(false);
      } else {
      }
    } catch (err) {
      if (err?.response?.data?.errors) {
        open(err.response.data.errors, "error");
      } else {
        open("Something went wrong", "error");
      }
      setisLoading(false);
    }
  };
  return (
    <>
      <Modal
        className="loginmodal"
        // {...props}
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <CustomSnackbar {...snackProps} />
        <div className="login-modal-mainbx">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Log in</h4>
              <p>Please, enter your email and password</p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                  />
                  {error.email && <span className="error">{error.email}</span>}
                </Form.Group>
                <Form.Group className="subsform-fild">
                  <Form.Label className="forg-texfield">
                    <span className="label-bx">Password</span>
                    <span className="forget-tex">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setModalShow(false);
                          setopenForgotPassword(true);
                        }}
                        className="title-med"
                      >
                        Forgot the password?
                      </div>
                    </span>
                  </Form.Label>

                  <div className="passwd-fild" style={{ position: "relative" }}>
                    <Form.Control
                      type={viewPassword}
                      placeholder="**********"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                    />
                    <span
                      onClick={() =>
                        setviewPassword((prevState) =>
                          prevState === "text" ? "password" : "text",
                        )
                      }
                      className="icon-eye"
                      style={{
                        position: "absolute",
                        top: "13px",
                        cursor: "pointer",
                        right: "10px",
                      }}
                    >
                      <i
                        className="fas fa-eye"
                        style={{
                          color:
                            viewPassword === "text" ? "var(--purple)" : "black",
                        }}
                      ></i>
                    </span>
                  </div>
                  {error.password && (
                    <span className="error">{error.password}</span>
                  )}
                </Form.Group>

                <div className="have-account">
                  <Link
                    to="#"
                    onClick={() => {
                      setModalSignupShow(true);
                      setModalShow(false);
                    }}
                    className="title-med"
                  >
                    I don’t have account yet
                  </Link>
                </div>

                <div className="btnlogin-main">
                  <button
                    disabled={isLoading}
                    onClick={handleLogin}
                    className="btnlogin btnweak-bx"
                  >
                    {isLoading ? (
                      <CircularProgress
                        size={"1.5rem"}
                        sx={{ color: "var(--purple)" }}
                      />
                    ) : (
                      "Log in"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        {/* <div className="socila-mediabx">
          <div className="social-media-detail">
            <h5>Or log in by social media</h5>
            <div className="social-media">
              <ul>
                <li>
                  <a>
                    <img src={google} className="img-fluid" />
                  </a>
                </li>
                <li>
                  <a>
                    <img src={facebook} className="img-fluid" />
                  </a>
                </li>
                <li>
                  <a>
                    <img src={linkedin} className="img-fluid" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
      </Modal>
    </>
  );
};

export default LoginModal;
