import {
  Box,
  LinearProgress,
  linearProgressClasses,
  makeStyles,
  Modal,
  Slider,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

import { Link } from "react-router-dom";
import ImageIcon from "@mui/icons-material/Image";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import IIcon from "../../assets/images/i_icon.svg";
import classNames from "classnames";
import PropTypes from "prop-types";
import {
  activeMilestones,
  highestMilestonePoints,
  sortArray,
  findTwoNumbers,
  greatestArray,
  revealedMilestonesArray,
  notRevealedMilestoneArray,
  updatedPopupMilestones,
  personalRewardnotRevealedMilestoneArray,
} from "../../utils/filterArray";
import { abbrNum } from "../../utils/roundNumber";
import { borderRadius } from "@mui/system";

// @material-ui/core components
// import

export const PersonalRewardsGiftsStepper = ({
  closeHandler,
  personalArray,
  personalRewardPoints,
}) => {
  var sortedArray = sortArray(personalArray);
  const [dataeeee, setDataeee] = useState({
    title: "Personal rewards system",
    rewardsData: sortedArray,
    personalRewardPoints: personalRewardPoints,
  });
  const score = personalRewardPoints;

  var activeArray = activeMilestones(sortedArray, personalRewardPoints);
  var totalPointsArr = [];
  sortedArray.map((i) => {
    totalPointsArr.push(i.pointsThresholdValue);
  });
  var pointsArr = [];
  var blurredMilestones = [];
  var notRevealedMilestone = [];

  activeArray.map((i, index) => {
    pointsArr.push(i.pointsThresholdValue);
  });

  if (personalRewardPoints >= pointsArr[pointsArr.length - 1]) {
    activeArray = updatedPopupMilestones(sortedArray, personalRewardPoints);
    activeArray.map((i) => {
      pointsArr.push(i.pointsThresholdValue);
    });
  }
  var a = highestMilestonePoints(sortedArray);
  const result = findTwoNumbers(pointsArr, personalRewardPoints);

  if (personalRewardPoints >= 0 && personalRewardPoints <= pointsArr[0]) {
    blurredMilestones = greatestArray(activeArray, result[0]);
    // notRevealedMilestone = notRevealedMilestoneArray(activeArray, result[0]);
    notRevealedMilestone = personalRewardnotRevealedMilestoneArray(
      activeArray,
      personalRewardPoints,
    );
  } else {
    blurredMilestones = greatestArray(activeArray, result[1]);
    notRevealedMilestone = notRevealedMilestoneArray(activeArray, result[1]);
  }

  const revealedMilestones = revealedMilestonesArray(
    activeArray,
    personalRewardPoints,
  );

  // if (personalRewardPoints >= totalPointsArr[2]) {
  //   notRevealedMilestone = notRevealedMilestone.concat(blurredMilestones);
  //   blurredMilestones = [];
  // }

  return (
    <Modal sx={{ overflowY: "scroll" }} open>
      <Box
        sx={{
          m: "1rem",
          minHeight: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            boxShadow: "0px 4px 10px rgba(0,0,0,0.15)",
            backgroundColor: "rgba(255,255,255, 0.5)",
            p: "0.5rem",
            borderRadius: "20px",
          }}
        >
          <Box
            sx={{
              fontFamily: "Poppins",
              opacity: "1",
              boxShadow: "0px 4px 10px rgba(0,0,0,0.15)",
              maxWidth: "50rem",
              position: "relative",
              width: "100%",
              p: "1rem",
              pt: "4rem",
              borderRadius: "12px",
              backgroundColor: "var(--purple-dark)",
            }}
          >
            <CloseIcon
              onClick={closeHandler}
              sx={{
                cursor: "pointer",
                color: "var(--orange)",
                position: "absolute",
                top: "10px",
                right: "10px",
              }}
            />
            <Box sx={{ display: "flex", marginBottom: "10px" }}>
              <Typography
                sx={{
                  marginTop: "-40px",
                  color: "var(--orange)",
                  fontWeight: "700",
                  fontFamily: "Mulish",
                }}
              >
                Personal Rewards
              </Typography>

              <Box sx={{ marginTop: "-40px", cursor: "pointer" }}>
                <Link
                  to={{
                    pathname: "/personalRewards",
                    state: { data: dataeeee },
                  }}
                >
                  <img
                    style={{ marginLeft: "6px", width: "21px", height: "21px" }}
                    src={IIcon}
                    alt=""
                  />
                </Link>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                position: "relative",
              }}
            >
              <Slider
                max={2000}
                min={0}
                value={score}
                sx={{
                  color: "var(--orange)",
                  width: "88%",
                  top: "5px",
                  left: "35px",
                  position: "absolute",

                  "& .MuiSlider-rail": {
                    color: "var(--purple)",
                  },
                }}
                size="small"
                defaultValue={70}
                aria-label="Small"
                valueLabelDisplay="auto"
              />
              {revealedMilestones.map((item, idx) => {
                // const isSelected = item.pointsThresholdValue <= score;
                const n = abbrNum(item.pointsThresholdValue, 0);

                return (
                  <Box
                    key={idx}
                    sx={{
                      zIndex: 1,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      position: "relative",
                      mx: "0.5rem",
                    }}
                  >
                    <Typography
                      sx={{
                        color:
                          // isSelected ?
                          "white",
                        // : "var(--purple-dark)",
                        display: "flex",
                        justifyContent: "center",
                        fontFamily: "Mulish",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "20px",
                        borderRadius: "50%",
                        padding: "8px 9px 12px 7px",
                        backgroundColor:
                          // isSelected
                          // ?
                          "var(--orange)",
                        // : "#AC88DE",
                        width: "40px",
                        height: "40px",
                      }}
                    >
                      {n}
                    </Typography>
                    <Box
                      sx={{
                        height: "78px",
                        width: "78px",
                        display: "flex",
                        p: "0.1rem",
                        fontFamily: "Mulish",

                        mt: "1rem",
                        borderRadius: "3px",
                        backgroundColor: "var(--orange)",
                        // `${
                        //   isSelected ? "var(--orange)" : "white"
                        // }`,
                        flexDirection: "column",
                        alignItems: "center",
                        ":before": {
                          position: "absolute",
                          content: '""',
                          display: "block",
                          width: 0,
                          height: 0,
                          left: "50%",
                          marginLeft: "-10px",
                          marginTop: "-20px",
                          border: "10px solid transparent",
                          borderBottom: `10px solid var(--orange)`,
                          // ${
                          //   isSelected ? "var(--orange)" : "white"
                          // }`,
                        },
                      }}
                    >
                      {/* <ImageIcon
                        sx={{
                          color: isSelected ? "white" : "var(--purple)",
                          fontSize: "3rem",
                        }}
                      /> */}
                      <img
                        style={{
                          marginTop: "5px",
                          width: "28px",
                          height: "28px",
                        }}
                        src={item.imageURL}
                        alt={item.title}
                      />
                      <Typography
                        sx={{
                          fontSize: "0.7rem",
                          color:
                            // isSelected ?
                            "white",
                          //  : "var(--purple)",
                        }}
                        textAlign={"center"}
                      >
                        {item.title}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}

              {notRevealedMilestone.map((item, idx) => {
                // const isSelected = item.pointsThresholdValue <= score;
                const n = abbrNum(item.pointsThresholdValue, 0);

                return (
                  <Box
                    key={idx}
                    sx={{
                      zIndex: 1,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      position: "relative",
                      mx: "0.5rem",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "var(--purple-dark)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "11px 9px 12px 9px",
                        fontFamily: "Mulish",
                        fontWeight: "700",
                        fontSize: "20px",
                        borderRadius: "50%",
                        backgroundColor: "#AC88DE",
                        width: "40px",
                        height: "40px",
                      }}
                    >
                      {n}
                    </Typography>
                    <Box
                      sx={{
                        height: "78px",
                        width: "78px",
                        display: "flex",
                        p: "0.1rem",
                        fontFamily: "Mulish",

                        mt: "1rem",
                        borderRadius: "3px",
                        backgroundColor: "white",
                        // `${
                        //   isSelected ? "var(--orange)" : "white"
                        // }`,
                        flexDirection: "column",
                        alignItems: "center",
                        ":before": {
                          position: "absolute",
                          content: '""',
                          display: "block",
                          width: 0,
                          height: 0,
                          left: "50%",
                          marginLeft: "-10px",
                          marginTop: "-20px",
                          border: "10px solid transparent",
                          borderBottom: `10px solid white`,
                          // ${
                          //   isSelected ? "var(--orange)" : "white"
                          // }`,
                        },
                      }}
                    >
                      {/* <ImageIcon
                        sx={{
                          color: isSelected ? "white" : "var(--purple)",
                          fontSize: "3rem",
                        }}
                      /> */}
                      <img
                        style={{
                          marginTop: "5px",
                          width: "28px",
                          height: "28px",
                        }}
                        src={item.imageURL}
                        alt={item.title}
                      />
                      <Typography
                        sx={{
                          fontSize: "0.7rem",
                          color: "var(--purple)",
                        }}
                        textAlign={"center"}
                      >
                        {item.title}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}

              {/* {blurredMilestones.map((item, idx) => {
                const n = abbrNum(item.pointsThresholdValue, 0);

                return (
                  <Box
                    key={idx}
                    sx={{
                      zIndex: 1,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      position: "relative",
                      mx: "0.5rem",
                    }}
                  >
                    <Skeleton variant="circular" width={40} height={40} />

                    <Skeleton
                      variant="rectangular"
                      width={78}
                      height={78}
                      sx={{
                        borderRadius: "3px",
                        mt: "1rem",
                        ":before": {
                          position: "absolute",
                          content: '""',
                          display: "block",
                          width: 0,
                          height: 0,
                          left: "50%",
                          marginLeft: "-10px",
                          marginTop: "-19px",
                          border: "10px solid transparent",
                          borderBottom: `10px solid rgba(0, 0, 0, 0.11)`,
                        },
                      }}
                    />
                  </Box>
                );
              })} */}
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
