import React, { useEffect, useState } from "react";
import "../home.scss";
import "../teachers.scss";
import "../media.scss";
import { Container, Row } from "react-bootstrap";
import Skeleton from "@mui/material/Skeleton";
import picsliderbg from "../../assets/images/bg-vector-icon.png";
import star from "../../assets/images/starbx.png";
import icon1 from "../../assets/images/icon1.png";
import icon2 from "../../assets/images/icon2.png";
import icon3 from "../../assets/images/icon3.png";
import icon4 from "../../assets/images/icon4.png";
import icon5 from "../../assets/images/icon5.png";
import icon6 from "../../assets/images/icon6.png";
import rokicon from "../../assets/images/icon-rock.png";
import paln1 from "../../assets/images/plan1.png";
import paln2 from "../../assets/images/plan2.png";
import paln3 from "../../assets/images/plan3.png";
import tick from "../../assets/images/tick.png";
import cross from "../../assets/images/cross.png";
import girl5 from "../../assets/images/girl5.png";
import leftarrow from "../../assets/images/leftarrow.png";
import rightarrow from "../../assets/images/rightarrow.png";
import kidpic2 from "../../assets/images/kidpic2.png";
import tutorail1 from "../../assets/images/tutorail1.png";
import slideA from "../../assets/images/slide-1.png";
import slideB from "../../assets/images/slide-2.png";
import slideC from "../../assets/images/slide-3.png";
import slideD from "../../assets/images/slide-4.png";
import slideE from "../../assets/images/slide-5.png";
import { useActions } from "../../overmind";
import Slider from "react-slick";
import { HeaderDashboard } from "../layout/HeaderDashboard";
import { Helmet } from "react-helmet";
import { HomeFooter } from "../layout/HomeFooter";
import { getSubjects } from "../../service/apis/common";
import { Link } from "react-router-dom";
import TopicsList from "../studentDashboard/TopicsList";
import SignUpEducator from "../SignUpEducator";
import { fetchData } from "../../service/AllApi";
export const SubjectSlider = ({ syllabusId }) => {
  const actions = useActions();
  // const state = useAppState();
  const [subjects, setsubjects] = useState([]);
  const [subsLoaded, setsubsLoaded] = useState(false);
  const [subsCount, setsubsCount] = useState(0);

  const getSlideNumber = (e) => {
    if (subjects.length > 0) {
      actions.setSelectedSubjectId(subjects[e].id);
    }
  };
  let nearestOdd = (num) => {
    // console.log(num);
    if (num % 2 === 0) {
      return num - 1;
    } else {
      if (num === 1) return 1;
      return num - 2;
    }
  };
  const settingsnew = {
    className: "center",
    centerMode: true,
    infinite: true,
    lazyLoad: true,
    centerPadding: "10px",
    adaptiveHeight: true,
    focusOnSelect: true,
    afterChange: getSlideNumber,
    slidesToShow: subsCount < 6 ? nearestOdd(subsCount) : 5,
    slidesToScroll: 1,
    nextArrow: <img src={rightarrow} className="img-fluid" alt="" />,
    prevArrow: <img src={leftarrow} className="img-fluid" alt="" />,
    speed: 500,
  };
  const settingsStandard = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "10px",
    slidesToShow: 5,
    nextArrow: <img src={rightarrow} className="img-fluid" alt="" />,
    prevArrow: <img src={leftarrow} className="img-fluid" alt="" />,
    speed: 500,
  };

  const fetchSubjects = async () => {
    try {
      // console.log(syllabusId, "syllabusId");
      const res = await getSubjects(syllabusId);
      if (res.length > 0) {
        // console.log(res.length, "res lenght");
        setsubjects(res);
        setsubsCount(res.length);
        setsubsLoaded(true);
      } else {
        setsubsLoaded(false);
      }
    } catch (err) {}
  };
  React.useEffect(() => {
    fetchSubjects();
  }, [subsLoaded, fetchSubjects]);
  return (
    <>
      <div className="serching-testimonial">
        <Container>
          <div className="serch-testi">
            <div className="testi-boxserch">
              {!subsLoaded ? (
                <Slider {...settingsStandard}>
                  {[1, 2, 3, 4, 5].map((e) => (
                    <div className="sliderbox" key={e}>
                      <div className="testi-mainsldr">
                        <div className="slider-cardbox">
                          <div className="slider-innercard">
                            <div className="serch-tutor-boxx">
                              <div className="cardbox-tutor">
                                <Skeleton
                                  variant="rounded"
                                  width={180}
                                  height={160}
                                />
                              </div>
                            </div>
                            <h5>
                              <Skeleton
                                variant="text"
                                sx={{ fontSize: "2rem" }}
                              />
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              ) : (
                <Slider {...settingsnew}>
                  {subjects.map((e) => (
                    <div className="sliderbox" key={e.id}>
                      <Link
                        to={{
                          pathname: "/games",
                          state: {
                            catId: e.id,
                            catName: e.name,
                            isTopic: false,
                          },
                        }}
                        className="gift-ico"
                      >
                        <div className="testi-mainsldr">
                          <div className="slider-cardbox">
                            <div className="slider-innercard">
                              <div className="serch-tutor-boxx">
                                <div className="cardbox-tutor">
                                  <img
                                    src={e.image || slideC}
                                    style={{
                                      width: "180px",
                                      height: "160px",
                                      margin: "auto",
                                    }}
                                    alt=""
                                  />
                                </div>
                              </div>
                              <h5>{e.name}</h5>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </Slider>
              )}
            </div>
          </div>
        </Container>
      </div>
      <div className="student-gallery">
        <div className="std-conatiner">
          <div className="std-gallerysection">
            <TopicsList />
          </div>
        </div>
      </div>
    </>
  );
};

function Teachers() {
  const [show, setShow] = React.useState("1");
  const [modalEducatorShow, setEducatorModalShow] = React.useState(false);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    fetchData("countries")
      .then((response) => {
        if (response && response.data && response.data.countryList) {
          setCountries(response.data?.countryList);
          console.log("countries list", response.data?.countryList);
        }
      })
      .catch((error) => {
        console.log("countries error", error);
      });
  }, []);

  useEffect(() => {
    // if window is scrolled, scroll to top
    window.scrollTo(0, 0);
  }, []);

  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <div onClick={onClick} className="previow__button">
        <img src={rightarrow} className="img-fluid" alt="" />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div
        // className={className}
        onClick={onClick}
        className="Next__button"
      >
        <img src={leftarrow} className="img-fluid" alt="" />
      </div>
    );
  }

  function SampleNextArrow2(props) {
    const { onClick } = props;
    return (
      <div onClick={onClick} className="previow__button2">
        <img src={rightarrow} className="img-fluid" alt="" />
      </div>
    );
  }

  function SamplePrevArrow2(props) {
    const { onClick } = props;
    return (
      <div
        // className={className}
        onClick={onClick}
        className="Next__button2"
      >
        <img src={leftarrow} className="img-fluid" alt="" />
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    slidesToScroll: 1,
  };

  const settingsnew = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "10px",
    slidesToShow: 5,
    nextArrow: <SampleNextArrow2 />,
    prevArrow: <SamplePrevArrow2 />,
    speed: 500,

    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  React.useEffect(() => {
    if (window.location.href.toString().search("teachers") === -1) {
      window.document.title = "Student management and learning app - Neithedu";
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          "Neithedu is the best learning and student management App. We have customized  features for Teachers, Students and Parents.",
        );
    }
    if (window.location.href.toString().search("teachers") !== -1) {
      window.document.title =
        "Customizable Assignment Tracking For Teachers - Neithedu";
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          "Neithedu is the best student assignment management and test tool for teachers. Teachers can assign and conduct the tests.",
        );
    }
  }, []);
  return (
    <div className="home-wrapper">
      <Helmet>
        <title>
          Online Teaching App for Teachers, Teaching Websites, Neithedu
        </title>
        <meta
          name="description"
          content="Online Teaching App for Teachers - Neithedu assists you with setting tests, homework tasks and exercises. We control the assessment process and allows you to keep a record of your students’ progress."
        />
      </Helmet>
      <div className="hero-box">
        <div className="custome-container">
          <HeaderDashboard />

          <div className="banner-holder teahers-slider">
            <Container>
              <div className="row item-center">
                <div className="col-md-6">
                  <div className="home-sliderbx">
                    <div className="slider-title">
                      <h1>The best tool to work with your students online </h1>
                      <p>
                        Teach students with Neithedu tools and you will see how
                        easy and convenient it can be
                      </p>
                      <div
                        style={{ cursor: "pointer" }}
                        className="btn-week"
                        onClick={() => setEducatorModalShow(true)}
                      >
                        <div className="btnweak-bx">
                          <span className="btn-title">Start for free</span>
                        </div>
                      </div>
                      <div className="first-month">
                        *First month’s subscription is free
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="right-box-slider">
                    <div className="pic1 zommnone">
                      <img src={kidpic2} className="img-fluid" alt="" />
                    </div>
                    <div className="pic1-vector">
                      <img
                        src={picsliderbg}
                        className="img-fluid scaleup-anm"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Container>

            <div className="kids-section-uper student-section">
              <Container>
                <div className="kids-better-box">
                  <span className="bgshpe"></span>
                  <div className="kids-main-bx">
                    <div className="custom-row">
                      <div className="left-pix-card">
                        <div className="pic-box">
                          <div className="kids-pic2">
                            <img src={star} className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="kids-right">
                        <div className="kids-content">
                          <h4>
                            Gather your students in one place and organise your
                            workspace with us
                          </h4>
                          <p>
                            There is no need to write down homework for your
                            students and remember all set assignments you sent.
                            Neithedu does that for you.
                          </p>
                          <p>
                            Neithedu assists you with setting tests, homework
                            tasks and exercises. Neithedu controls the
                            assessment process and allows you to keep a record
                            of your students’ progress.{" "}
                          </p>
                          <p>
                            There is a huge database of games and eLearning
                            materials for all subjects that you can set for your
                            students. You can even earn money yourself if you
                            want to share them with other teachers.{" "}
                          </p>
                          <p>
                            You are free to work with your students
                            individually. Or if you work at a school or
                            educational centre, it’s a great tool to organise
                            the learning process with the class.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
          </div>
        </div>
      </div>

      <div className="kids-better useful-tech">
        <Container>
          <div className="why-illumiya">
            <div className="section-card-">
              <div className="heading-top">
                <h4 className="title-medium">
                  Why Neithedu is so useful for <br></br>educators
                </h4>
              </div>
              <Row>
                <div className="col-md-6 one">
                  <div className="cardbx-left">
                    <div className="cardbx-inner">
                      <div className="card-row">
                        <div className="card-pic">
                          <img src={icon1} className="img-fluid" alt="" />
                        </div>
                        <div className="right-content-card">
                          <p>All teaching materials in one place </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 one">
                  <div className="cardbx-left">
                    <div className="cardbx-inner">
                      <div className="card-row">
                        <div className="card-pic">
                          <img src={icon2} className="img-fluid" alt="" />
                        </div>
                        <div className="right-content-card">
                          <p>Keep track of all students in one place </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 one">
                  <div className="cardbx-left">
                    <div className="cardbx-inner">
                      <div className="card-row">
                        <div className="card-pic">
                          <img src={icon3} className="img-fluid" alt="" />
                        </div>
                        <div className="right-content-card">
                          <p>Simple and easy to use the platform</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 one">
                  <div className="cardbx-left">
                    <div className="cardbx-inner">
                      <div className="card-row">
                        <div className="card-pic">
                          <img src={icon4} className="img-fluid" alt="" />
                        </div>
                        <div className="right-content-card">
                          <p>Personalise lessons for students </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 one">
                  <div className="cardbx-left">
                    <div className="cardbx-inner">
                      <div className="card-row">
                        <div className="card-pic">
                          <img src={icon5} className="img-fluid" alt="" />
                        </div>
                        <div className="right-content-card">
                          <p>No training needed to use platform </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 one">
                  <div className="cardbx-left">
                    <div className="cardbx-inner">
                      <div className="card-row">
                        <div className="card-pic">
                          <img src={icon6} className="img-fluid" alt="" />
                        </div>
                        <div className="right-content-card">
                          <p>Follow the learning process </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
            </div>
          </div>

          <div className="count-section">
            <div className="countbx">
              <div className="count-subj">
                <ul className="listing-count">
                  <li>
                    <div className="list-counter">
                      <div className="boxcountr">
                        <h4> 500+</h4>
                        <h6>games</h6>
                      </div>
                      <span className="shapebx-count one"></span>
                    </div>
                  </li>
                  <li>
                    <div className="list-counter">
                      <div className="boxcountr">
                        <h4> 300+</h4>
                        <h6>materials</h6>
                      </div>
                      <span className="shapebx-count one"></span>
                    </div>
                  </li>
                  <li>
                    <div className="list-counter">
                      <div className="boxcountr">
                        <h4> 250+</h4>
                        <h6>gameflows</h6>
                      </div>

                      <span className="shapebx-count one"></span>
                    </div>
                  </li>
                  <li>
                    <div className="list-counter">
                      <div className="boxcountr">
                        <h4> 20+</h4>
                        <h6>subjects</h6>
                      </div>
                      <span className="shapebx-count one"></span>
                    </div>
                  </li>
                  <li>
                    <div className="list-counter">
                      <div className="boxcountr">
                        <h4> 2500+</h4>
                        <h6>students</h6>
                      </div>
                      <span className="shapebx-count one"></span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="become-tutor">
            <div className="tutot-box">
              <div className="custom-row">
                <div className="tutor-leftbx">
                  <div className="left-boxpic">
                    <div className="girlpic">
                      <img src={girl5} className="img-fluid" alt="" />
                    </div>
                    <span className="shape-bx-pic"></span>
                  </div>
                </div>
                <div className="rightbx-tutor">
                  <div className="tuttor-content">
                    <h4>To become an Neithedu tutor </h4>
                    <p>
                      You have the opportunity to become a tutor and have live
                      lessons with students extra. In Neithedu you can find
                      students who need help with your subject. If you want to
                      start you need to push the button to become a tutor and
                      fill the form. We’ll review your application and after
                      confirmation you’ll be able to have live lessons. You can
                      publish materials and games that you created by yourself
                      and receive $20 for each relevant and interesting one.
                      <br></br>For every student that will subscribe you’ll
                      receive $5.
                    </p>
                    <h6>
                      Cost of lesson depends on your work experience and your
                      rating on the website and can be $15 - $25 for a lesson.{" "}
                    </h6>
                    <div className="btn-buy tutor-becm">
                      <div className="btn-week">
                        <a href="#" className="btnweak-bx">
                          <span className="btn-title">Became a tutor</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tutr-shape-outer">
                <div className="shape-tutor-right"></div>
              </div>
            </div>
          </div>
        </Container>
        <div className="rockt-icon">
          <img src={rokicon} className="img-fluid" alt="" />
        </div>
      </div>

      <div className="video-section serching-tutor-main">
        <div className="rocket-icsml">
          <img src={rokicon} className="img-fluid" alt="" />
        </div>
        <div className="videobx-main serching-tutor">
          <div className="serching-testimonial">
            <Container>
              <div className="serch-testi">
                <div className="heading-top">
                  <h4 className="title-medium">
                    We are searching educators of{" "}
                  </h4>
                </div>
                <div className="testi-boxserch">
                  <Slider {...settingsnew}>
                    <div className="sliderbox">
                      <div className="testi-mainsldr">
                        <div className="slider-cardbox">
                          <div className="slider-innercard">
                            <div className="serch-tutor-boxx">
                              <div className="cardbox-tutor">
                                {" "}
                                <img
                                  src={slideC}
                                  className="img-fluid"
                                  alt=""
                                />{" "}
                              </div>
                            </div>
                            <h5> English</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sliderbox">
                      <div className="testi-mainsldr">
                        <div className="slider-cardbox">
                          <div className="slider-innercard">
                            <div className="serch-tutor-boxx">
                              <div className="cardbox-tutor">
                                {" "}
                                <img
                                  src={slideD}
                                  className="img-fluid"
                                  alt=""
                                />{" "}
                              </div>
                            </div>
                            <h5>History </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sliderbox">
                      <div className="testi-mainsldr">
                        <div className="slider-cardbox">
                          <div className="slider-innercard">
                            <div className="serch-tutor-boxx">
                              <div className="cardbox-tutor">
                                {" "}
                                <img
                                  src={slideE}
                                  className="img-fluid"
                                  alt=""
                                />{" "}
                              </div>
                            </div>
                            <h5>Facultative</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sliderbox">
                      <div className="testi-mainsldr">
                        <div className="slider-cardbox">
                          <div className="slider-innercard">
                            <div className="serch-tutor-boxx">
                              <div className="cardbox-tutor">
                                {" "}
                                <img
                                  src={slideC}
                                  className="img-fluid"
                                  alt=""
                                />{" "}
                              </div>
                            </div>
                            <h5>English</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sliderbox">
                      <div className="testi-mainsldr">
                        <div className="slider-cardbox">
                          <div className="slider-innercard">
                            <div className="serch-tutor-boxx">
                              <div className="cardbox-tutor">
                                {" "}
                                <img
                                  src={slideA}
                                  className="img-fluid"
                                  alt=""
                                />{" "}
                              </div>
                            </div>
                            <h5>Maths </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sliderbox">
                      <div className="testi-mainsldr">
                        <div className="slider-cardbox">
                          <div className="slider-innercard">
                            <div className="serch-tutor-boxx">
                              <div className="cardbox-tutor">
                                {" "}
                                <img
                                  src={slideB}
                                  className="img-fluid"
                                  alt=""
                                />{" "}
                              </div>
                            </div>
                            <h5>Science </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </Container>
          </div>

          <div className="communty-pot">
            <Container>
              <div className="communty-box">
                <div className="communty-section">
                  <div className="heading-top">
                    <h4 className="title-medium">Comunity pot</h4>
                    <h6>We love our tutors and care about them</h6>
                  </div>
                  <div className="communtiy-content">
                    <p className="small-tex">
                      5% of all Neithedu subscriptions go to pot. Members of pot
                      receive equal part every quarter. To become a member of
                      community pot you should have 6 months of tutoring
                      service, 100 tutoring hours, 90% satisfaction from users.{" "}
                    </p>
                    <div className="btn-buy tutor-becm">
                      <div className="btn-week">
                        <a href="#" className="btnweak-bx">
                          <span className="btn-title">Became a tutor</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>

          <div className="choose-plan">
            <div className="choose-plan-bx plan-works">
              <Container>
                <div className="best-plan">
                  <div className="heading-top">
                    <h4 className="title-medium">
                      Choose a plan that works for you
                    </h4>
                  </div>
                  <div className="best-plan-card">
                    <Row>
                      <div className="col-md-4 one cardbxx">
                        <div className="plan-card">
                          <div className="plan-cardiner">
                            <div className="card-icon-plan">
                              <img src={paln1} className="img-fluid" alt="" />
                            </div>
                            <div className="title-plancard">Weekly</div>

                            <div className="card-descript-paln">
                              Duration: 7 days
                            </div>
                            <div className="card-list-plan work-plain-listt">
                              <ul>
                                <li>
                                  <span className="icontick">
                                    <img
                                      src={tick}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </span>
                                  <span className="list-detail-plan">
                                    Access to all the games <br></br>for your
                                    subject
                                  </span>
                                </li>
                                <li>
                                  <span className="icontick">
                                    <img
                                      src={tick}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </span>
                                  <span className="list-detail-plan">
                                    Teaching style assessment
                                  </span>
                                </li>
                                <li>
                                  <span className="icontick">
                                    <img
                                      src={tick}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </span>
                                  <span className="list-detail-plan">
                                    Create homework tasks for students
                                  </span>
                                </li>
                                <li>
                                  <span className="icontick">
                                    <img
                                      src={tick}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </span>
                                  <span className="list-detail-plan">
                                    Create personalised games
                                  </span>
                                </li>
                                <li>
                                  <span className="icontick">
                                    <img
                                      src={tick}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </span>
                                  <span className="list-detail-plan">
                                    Limit to students you can add
                                  </span>
                                </li>
                                <li>
                                  <span className="icontick">
                                    <img
                                      src={tick}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </span>
                                  <span className="list-detail-plan">
                                    Limit to 1 GB Workspace
                                  </span>
                                </li>
                                <li>
                                  <span className="icontick">
                                    <img
                                      src={cross}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </span>
                                  <span className="list-detail-plan">
                                    No homework tasks for groups
                                  </span>
                                </li>

                                <li>
                                  <span className="icontick">
                                    <img
                                      src={cross}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </span>
                                  <span className="list-detail-plan">
                                    No access to game templates
                                  </span>
                                </li>
                                <li>
                                  <span className="icontick">
                                    <img
                                      src={cross}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </span>
                                  <span className="list-detail-plan">
                                    No access to premium content
                                  </span>
                                </li>
                              </ul>
                            </div>

                            <div className="btn-buy">
                              <div className="btn-week">
                                <a href="#" className="btnweak-bx">
                                  <span className="btn-title">
                                    Buy for $7/week
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4 two cardbxx">
                        <div className="plan-card">
                          <div className="plan-cardiner">
                            <div className="card-icon-plan">
                              <img src={paln2} className="img-fluid" alt="" />
                            </div>
                            <div className="title-plancard">Monthly</div>

                            <div className="card-descript-paln">
                              Duration: 30 days
                            </div>
                            <div className="card-list-plan work-plain-listt">
                              <ul>
                                <li>
                                  <span className="icontick">
                                    <img
                                      src={tick}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </span>
                                  <span className="list-detail-plan">
                                    Access to all the games
                                  </span>
                                </li>
                                <li>
                                  <span className="icontick">
                                    <img
                                      src={tick}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </span>
                                  <span className="list-detail-plan">
                                    Teaching style Assessment
                                  </span>
                                </li>

                                <li>
                                  <span className="icontick">
                                    <img
                                      src={tick}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </span>
                                  <span className="list-detail-plan">
                                    Create Tasks for students
                                  </span>
                                </li>

                                <li>
                                  <span className="icontick">
                                    <img
                                      src={tick}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </span>
                                  <span className="list-detail-plan">
                                    Create homework tasks for groups
                                  </span>
                                </li>

                                <li>
                                  <span className="icontick">
                                    <img
                                      src={tick}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </span>
                                  <span className="list-detail-plan">
                                    Create personalised games
                                  </span>
                                </li>

                                <li>
                                  <span className="icontick">
                                    <img
                                      src={tick}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </span>
                                  <span className="list-detail-plan">
                                    Access to game templates
                                  </span>
                                </li>

                                <li>
                                  <span className="icontick">
                                    <img
                                      src={tick}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </span>
                                  <span className="list-detail-plan">
                                    Access to premium content
                                  </span>
                                </li>
                                <li>
                                  <span className="icontick">
                                    <img
                                      src={tick}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </span>
                                  <span className="list-detail-plan">
                                    Limit to students you can add
                                  </span>
                                </li>
                                <li>
                                  <span className="icontick">
                                    <img
                                      src={tick}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </span>
                                  <span className="list-detail-plan">
                                    Limit to 1 GB Workspace
                                  </span>
                                </li>
                              </ul>
                            </div>

                            <div className="btn-buy">
                              <div className="btn-week">
                                <a href="#" className="btnweak-bx">
                                  <span className="btn-title">
                                    Buy for $20/mnth
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4 three cardbxx">
                        <div className="plan-card">
                          <div className="plan-cardiner">
                            <div className="card-icon-plan">
                              <img src={paln3} className="img-fluid" alt="" />
                            </div>
                            <div className="title-plancard">Yearly</div>

                            <div className="card-descript-paln">
                              Duration: 365 days
                              <span className="descript-month">
                                For one month: $15
                              </span>
                            </div>
                            <div className="card-list-plan work-plain-listt">
                              <ul>
                                <li>
                                  <span className="icontick">
                                    <img
                                      src={tick}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </span>
                                  <span className="list-detail-plan">
                                    Access to all the games
                                  </span>
                                </li>
                                <li>
                                  <span className="icontick">
                                    <img
                                      src={tick}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </span>
                                  <span className="list-detail-plan">
                                    Teaching style Assessment
                                  </span>
                                </li>

                                <li>
                                  <span className="icontick">
                                    <img
                                      src={tick}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </span>
                                  <span className="list-detail-plan">
                                    Create Tasks for students
                                  </span>
                                </li>

                                <li>
                                  <span className="icontick">
                                    <img
                                      src={tick}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </span>
                                  <span className="list-detail-plan">
                                    Create homework tasks for groups
                                  </span>
                                </li>
                                <li>
                                  <span className="icontick">
                                    <img
                                      src={tick}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </span>
                                  <span className="list-detail-plan">
                                    Create personalised games
                                  </span>
                                </li>

                                <li>
                                  <span className="icontick">
                                    <img
                                      src={tick}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </span>
                                  <span className="list-detail-plan">
                                    Access to game templates
                                  </span>
                                </li>
                                <li>
                                  <span className="icontick">
                                    <img
                                      src={tick}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </span>
                                  <span className="list-detail-plan">
                                    Access to premium content
                                  </span>
                                </li>

                                <li>
                                  <span className="icontick">
                                    <img
                                      src={tick}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </span>
                                  <span className="list-detail-plan">
                                    Limit to students you can add
                                  </span>
                                </li>

                                <li>
                                  <span className="icontick">
                                    <img
                                      src={tick}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </span>
                                  <span className="list-detail-plan">
                                    Limit to 25 GB Workspace
                                  </span>
                                </li>

                                <li>
                                  <span className="icontick">
                                    <img
                                      src={tick}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </span>
                                  <span className="list-detail-plan">
                                    Ability to publish games
                                  </span>
                                </li>

                                <li>
                                  <span className="icontick">
                                    <img
                                      src={tick}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </span>
                                  <span className="list-detail-plan">
                                    Access to live lesson, community pot after
                                    opening tutor account
                                  </span>
                                </li>

                                <li>
                                  <span className="icontick">
                                    <img
                                      src={cross}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </span>
                                  <span className="list-detail-plan">
                                    No access to community pot
                                  </span>
                                </li>
                              </ul>
                            </div>

                            <div className="btn-buy">
                              <div className="btn-week">
                                <a href="#" className="btnweak-bx">
                                  <span className="btn-title">
                                    Buy for $180/year
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </div>
                </div>
              </Container>
            </div>
          </div>
        </div>
      </div>

      <div className="testimaonial-section testimonial-eduction">
        <div className="testimonial-bx">
          <Container>
            <div className="testi-main">
              <div className="heading-top">
                <h4 className="title-medium">Testimonials from educators</h4>
              </div>
              <div className="testimonial-contain">
                <div className="custom-row">
                  <div className="testim-left">
                    <div className="left-pic">
                      <img
                        src={girl5}
                        className="img-fluid scaleup-anm"
                        alt=""
                      />
                    </div>
                    <div className="right-box-testi">
                      <div className="testimonial-boxx">
                        <Slider {...settings}>
                          <div className="sliderbox">
                            <div className="testi-mainsldr">
                              <div className="slider-cardbox">
                                <div className="slider-innercard">
                                  <h4>That was really cool! I want more!!!</h4>
                                  <p>
                                    Eruditi maluisset suscipiantur te nec. Sed
                                    rebum iudico ut, indoctum theophrastus his
                                    te. Quo quodsi persecuti adipiscing id, sea
                                    commodo vocibus ex. Putent deseruisse te
                                    mei, eos ei porro periculis. Aeque
                                    scribentur eos te, per ullum inani vivendum
                                    an. An mazim consetetur eum, quo alia
                                    platonem urbanitas no. Aeque dolor mel cu,
                                    doming splendide vel ad.
                                  </p>
                                  <h5>English tutor, 3 years on Neithedu</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="sliderbox">
                            <div className="testi-mainsldr">
                              <div className="slider-cardbox">
                                <div className="slider-innercard">
                                  <h4>That was really cool! I want more!!!</h4>
                                  <p>
                                    Eruditi maluisset suscipiantur te nec. Sed
                                    rebum iudico ut, indoctum theophrastus his
                                    te. Quo quodsi persecuti adipiscing id, sea
                                    commodo vocibus ex. Putent deseruisse te
                                    mei, eos ei porro periculis. Aeque
                                    scribentur eos te, per ullum inani vivendum
                                    an. An mazim consetetur eum, quo alia
                                    platonem urbanitas no. Aeque dolor mel cu,
                                    doming splendide vel ad.
                                  </p>
                                  <h5>English tutor, 3 years on Neithedu</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="sliderbox">
                            <div className="testi-mainsldr">
                              <div className="slider-cardbox">
                                <div className="slider-innercard">
                                  <h4>That was really cool! I want more!!!</h4>
                                  <p>
                                    Eruditi maluisset suscipiantur te nec. Sed
                                    rebum iudico ut, indoctum theophrastus his
                                    te. Quo quodsi persecuti adipiscing id, sea
                                    commodo vocibus ex. Putent deseruisse te
                                    mei, eos ei porro periculis. Aeque
                                    scribentur eos te, per ullum inani vivendum
                                    an. An mazim consetetur eum, quo alia
                                    platonem urbanitas no. Aeque dolor mel cu,
                                    doming splendide vel ad.
                                  </p>
                                  <h5>English tutor, 3 years on Neithedu</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>

          <div className="faq-section">
            <Container>
              <div className="faq-box">
                <div className="heading-top">
                  <h4 className="title-medium">FAQ</h4>
                </div>
                <div className="faq-main">
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          onClick={() => setShow(show === "1" ? null : "1")}
                          className={`accordion-button ${
                            show === "1" ? "collapsed" : ""
                          }`}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded={Boolean(
                            `${show === "1" ? "true" : "false"}`,
                          )}
                          aria-controls="collapseOne"
                        >
                          <span className="faq-title">
                            {" "}
                            Can we personalize the lessons?
                          </span>
                          <div className="faq-shape">
                            <span className="shape-bx"></span>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className={`accordion-collapse collapse ${
                          show === "1" ? "show" : ""
                        }`}
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            Yes. The tutor or teacher can modify the games,
                            lessons, and add additional tasks for the student to
                            complete, making the learning experience completely
                            personalized and unique.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          onClick={() => setShow(show === "2" ? null : "2")}
                          className={`accordion-button ${
                            show === "2" ? "collapsed" : ""
                          }`}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded={Boolean(
                            `${show === "2" ? "true" : "false"}`,
                          )}
                          aria-controls="collapseTwo"
                        >
                          <span className="faq-title">
                            Do I need to be working in a school to register as
                            an educator{" "}
                          </span>
                          <div className="faq-shape">
                            <span className="shape-bx"></span>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className={`accordion-collapse collapse ${
                          show === "2" ? "show" : ""
                        }`}
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            No. Even if you work in an educational centre or
                            work for a tutoring agency you are welcome to join.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          onClick={() => setShow(show === "3" ? null : "3")}
                          className={`accordion-button ${
                            show === "3" ? "collapsed" : ""
                          }`}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded={Boolean(
                            `${show === "7" ? "true" : "false"}`,
                          )}
                          aria-controls="collapseThree"
                        >
                          <span className="faq-title">
                            Do I need to be really good with technology to use
                            the Neithedu platform?
                          </span>
                          <div className="faq-shape">
                            <span className="shape-bx"></span>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className={`accordion-collapse collapse ${
                          show === "7" ? "show" : ""
                        }`}
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            No. To use Neithedu platform you only need the basic
                            web browsing skills. It’s super intuitive to use.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFour">
                        <button
                          onClick={() => setShow(show === "7" ? null : "7")}
                          className={`accordion-button ${
                            show === "7" ? "collapsed" : ""
                          }`}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded={Boolean(
                            `${show === "7" ? "true" : "false"}`,
                          )}
                          aria-controls="collapseFour"
                        >
                          <span className="faq-title">
                            Can I change plans or cancel any time?
                          </span>
                          <div className="faq-shape">
                            <span className="shape-bx"></span>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        className={`accordion-collapse collapse ${
                          show === "7" ? "show" : ""
                        }`}
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            Yes, you can switch plans just by going to your{" "}
                            <strong>
                              Profile
                              {`>`} Settings {`>`} Upgrade
                            </strong>{" "}
                            at any time you want, no questions asked.
                          </p>
                          <p>
                            If you want to cancel your membership{" "}
                            <strong>
                              Profile {`>`} Settings {`>`}
                              Cancel
                            </strong>{" "}
                            at any time you want, no questions asked.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>

          <div className="intersting-you">
            <div className="custome-container">
              <div className="intersting-main">
                <div className="heading-top">
                  <h4 className="title-medium">
                    It might be interesting for you
                  </h4>
                </div>

                <div className="intersting-card">
                  <Row>
                    <div className="col-md-6 one">
                      <div className="cardsection-int">
                        <div className="custom-row">
                          <div className="left-cardint">
                            <div className="cardpic-int">
                              <img
                                src={tutorail1}
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="right-cardint">
                            <div className="inti-content">
                              <div className="tagbtn">
                                <span className="tagname">for Tutors</span>
                              </div>
                              <h4>Amazing title of the article in the blog</h4>
                              <p className="small-tex">
                                Short text in the beging of the main article
                                text to
                              </p>
                              <div className="date-inti">October 05, 2020</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 one">
                      <div className="cardsection-int">
                        <div className="custom-row">
                          <div className="left-cardint">
                            <div className="cardpic-int">
                              <img
                                src={tutorail1}
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="right-cardint">
                            <div className="inti-content">
                              <div className="tagbtn">
                                <span className="tagname">for Tutors</span>
                              </div>
                              <h4>Amazing title of the article in the blog</h4>
                              <p className="small-tex">
                                Short text in the beging of the main article
                                text to
                              </p>
                              <div className="date-inti">October 05, 2020</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>

        <HomeFooter />
      </div>
      <SignUpEducator
        setModalSignupShow={() => {}}
        modalEducatorShow={modalEducatorShow}
        setEducatorModalShow={(value) => setEducatorModalShow(value)}
        setModalShow={() => {}}
        country={countries}
      />
    </div>
  );
}

export default Teachers;
