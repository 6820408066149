import { Box, Typography } from "@mui/material";
import { CloseButton, StyledYellowButton } from "../../utils/Buttons";
import { DialogBox } from "../../utils/DialogBox";

const RefundApllicationSentDialog = ({ setModalType }) => {
  return (
    <DialogBox isOpen={true}>
      <CloseButton closeHandler={() => setModalType(null)} />
      <Box sx={{ textAlign: "center", m: "2rem", mb: "20px" }}>
        <Typography
          sx={{
            color: "var(--purple-dark)",
            fontWeight: "800",
            fontSize: "1.3rem",
            mb: "1rem",
          }}
        >
          Your application has been sent
        </Typography>
        <Typography sx={{ color: "var(--black)" }}>
          Money will be returned to your account, so you will be able to pay for
          another lesson.
        </Typography>

        <StyledYellowButton
          title={"Okay, I understand"}
          btnHandler={() => setModalType(null)}
          sx={{
            maxWidth: "300px",
            marginTop: "20px",
          }}
        />
      </Box>
    </DialogBox>
  );
};
export default RefundApllicationSentDialog;
