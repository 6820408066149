import React from "react";
import "../home.scss";
import "../profile.scss";
import "../media.scss";
import "../games.scss";
import "../loginModal/login.scss";
import "./messages.scss";
import "../myactivities.scss";
import { Container, Dropdown, Button, Form } from "react-bootstrap";
import { Tabs, TabPanel } from "react-tabs";
import { HubConnection, HubConnectionBuilder } from "@aspnet/signalr";

import logo from "../../assets/images/logo.png";
import chaticon from "../../assets/images/chat-icon.svg";
import batchorange from "../../assets/images/batch-orange.svg";
import palinblue from "../../assets/images/palin-blue.svg";
import mike from "../../assets/images/mike.svg";
import attachemtnblue from "../../assets/images/attachemtn-blue.svg";

import { Link } from "react-router-dom";
import FooterEducator from "../layout/Footer/FooterEducator";
import Addgroup from "../Addgroup";
import Addpupil from "../Addpupil";
import SendInvite from "../SendInvite";
import Groupmember from "../Groupmember";
import HeaderGames from "../layout/Header/HeaderGames";
import { useQuery } from "@tanstack/react-query";
import { getEducatorStuGrp } from "../../service/apis/educator";
import ChooseActivityBar from "../educator/activity/ChooseActivityBar";
import { getUserMessages } from "../../service/apis/messages";
import { backEnd } from "../../env";

function Messages() {
  const [modalAddgroupstep1Show, setAddgroupstep1ModalShow] =
    React.useState(false);

  /* start Eductor signup step1 */
  const [modalEducatorstep1Show, setEducatorstep1ModalShow] =
    React.useState(false);
  /* end Eductor signup step1 */

  /* start Send Invite step1 */
  const [modalinvitestep1Show, setinvitestep1ModalShow] = React.useState(false);
  /* end Send Invitestep1 */

  /* start group member step1 */
  const [modalgroupmemberstep1Show, setgroupmemberstep1ModalShow] =
    React.useState(false);
  /* end group member */

  /* end add pupil modal */

  const [querySearch, setquerySearch] = React.useState("");
  const [selectedStudentOrGroup, setSelectedStudentOrGroup] =
    React.useState(null);
  const [messages, setMessages] = React.useState([]);
  const [connectedUsers, setConnectedUsers] = React.useState([]);
  const [message, setMessage] = React.useState("");
  const [users, setUsers] = React.useState([]);

  // hub
  const [hubConnection, setHubConnection] = React.useState(null);

  var user = localStorage.getItem("user");
  var userInfo = JSON.parse(user);
  let userId = userInfo?.applicationUser?.id;

  const {
    data: eductorStudentsAndGroups,
    isLoading: educatorStudentsAndGroupsLoading,
    refetch: refetchStudentsAndGroups,
  } = useQuery({
    queryKey: ["educatorStudentsGroups"],
    queryFn: async () => await getEducatorStuGrp(0, 0),
  });
  const searchedStudentsAndGroups = eductorStudentsAndGroups?.filter(
    (stugrp) => {
      return stugrp.name
        ?.toLowerCase()
        .trim()
        .includes(querySearch.toLowerCase());
    },
  );

  React.useEffect(() => {
    const fetchMessages = async () => {
      const _messages = await getUserMessages(userId);
      setMessages(_messages);
      console.log("_messages123", _messages);
    };

    if (userId) fetchMessages();
  }, [userId]);

  React.useEffect(() => {
    const createHubConnection = async () => {
      const hubConnect = new HubConnectionBuilder()
        .withUrl("https://services.neithedu.com/chatHub")
        .build();

      setHubConnection(hubConnect);
    };
    try {
      createHubConnection();
    } catch (err) {
      console.log("err123", err);
    }
  }, []);

  React.useEffect(() => {
    if (hubConnection && userId) {
      hubConnection
        .start()
        .then(() => {
          hubConnection
            .invoke(
              "PublishUserOnConnect",
              userId,
              userInfo?.applicationUser?.firstName,
              userInfo?.applicationUser?.name,
            )
            .then(() => console.log("User published Successfully123"))
            .catch((err) => console.error("User Sent error123", err));
          hubConnection.on("BroadcastUserOnConnect", (Usrs) => {
            console.log("BroadcastUserOnConnect123", Usrs);
            // this.connectedUsers = Usrs;
            // this.makeItOnline();
          });
          hubConnection.on("BroadcastUserOnDisconnect", (Usrs) => {
            console.log("BroadcastUserOnDisconnect123", Usrs);
            // this.connectedUsers = Usrs;
            // this.users.forEach(item => {
            //   item.isOnline = false;
            // });
            // this.makeItOnline();
          });
        })
        .catch((err) => console.log("_err123", err));
    }
  }, [hubConnection, userId]);

  const SendDirectMessage = () => {
    if (message != "" && message.trim() != "") {
      var msg = {
        SenderId: userId,
        ReceiverId: selectedStudentOrGroup.id,
        Content: message,
        MessageDate: new Date(),
        IsNew: true,
        IsSenderDeleted: false,
        IsReceiverDeleted: false,
      };
      console.log("msg123", msg);
      try {
        if (hubConnection) {
          hubConnection
            .invoke("SendMessageToUser", msg)
            .then(() => {
              console.log("Message to user Sent Successfully123");
              setMessages([...messages, msg]);
              setMessage("");
            })
            .catch((err) => console.error("sendmessageerror123", err));
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderGames />
      {/* end header */}

      <div className="games-dashboard-main dashboard-bgnew blur-bgg educator-home">
        <div className="games-topbread">
          <Container>
            <div className="diversity-box">
              <div className="myactivites-main my-messagerm-chat">
                <div className="myactivites-contain">
                  <Tabs>
                    <div className="activies-row">
                      <div className="left-pannel">
                        <div className="leftbx-activites">
                          <div className="acti-left-detail">
                            <div className="puil-mainbx">
                              <div className="add-pupil">
                                <div className="pupil-dropdwn">
                                  <Dropdown className="dropdown-main dropmenu-custlistt">
                                    <Dropdown.Toggle
                                      variant="success"
                                      id="dropdown-basic"
                                      className="dropdown-btnn"
                                    >
                                      <i className="fas fa-plus"></i>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className="dropdown-btn-item customdrop-listmenu">
                                      <Dropdown.Item
                                        href="#"
                                        onClick={() => {
                                          setEducatorstep1ModalShow(true);
                                        }}
                                      >
                                        <span className="rightarrow-icon">
                                          <i className="fas fa-angle-right"></i>
                                        </span>{" "}
                                        Add a pupil
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        href="#"
                                        onClick={() => {
                                          setAddgroupstep1ModalShow(true);
                                        }}
                                      >
                                        <span className="rightarrow-icon">
                                          <i className="fas fa-angle-right"></i>
                                        </span>{" "}
                                        Add a new group
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        href="#"
                                        onClick={() => {
                                          setinvitestep1ModalShow(true);
                                        }}
                                      >
                                        <span className="rightarrow-icon">
                                          <i className="fas fa-angle-right"></i>
                                        </span>{" "}
                                        Invite to Neithedu
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                              <div className="serchfiled-activities">
                                <Form.Control
                                  value={querySearch}
                                  onChange={(e) =>
                                    setquerySearch(String(e.target.value))
                                  }
                                  type="text"
                                  placeholder="Search"
                                  className="formbox"
                                />
                                <Button type="button" className="seachbtn">
                                  <i className="fas fa-search"></i>
                                </Button>
                              </div>
                            </div>

                            <div className="activities-list-left">
                              <div className="tabslist-activiti">
                                {!educatorStudentsAndGroupsLoading &&
                                  searchedStudentsAndGroups?.length === 0 && (
                                    <p>No student or group found</p>
                                  )}
                                {!educatorStudentsAndGroupsLoading &&
                                  searchedStudentsAndGroups &&
                                  searchedStudentsAndGroups.map((stugrp, i) => (
                                    <ChooseActivityBar
                                      key={i}
                                      stugrp={stugrp}
                                      onSelection={async () => {
                                        setSelectedStudentOrGroup(stugrp);
                                        // await refetchActivities();
                                        // history.push(
                                        //   `/educator?studentId=${stugrp?.id}`,
                                        // );
                                      }}
                                    />
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="right-panel">
                        <div className="activities-rightbx">
                          <TabPanel className="tabslist-content-act">
                            <div className="myacti-listtabs educator-lsttabs-new">
                              <div className="chat-headerbar">
                                <div className="chat-topbar-list">
                                  <div className="activ-detail-list">
                                    <div className="active-detail-row">
                                      <div className="activiti-prof-icon">
                                        <span className="profile-icon-box">
                                          <i className="fas fa-user"></i>
                                        </span>
                                      </div>
                                      <div className="activiti-center-detail">
                                        <h5>Maxine Doe</h5>
                                        <span
                                          className="group-member grpmember-btn"
                                          onClick={() => {
                                            setgroupmemberstep1ModalShow(true);
                                          }}
                                        >
                                          22 members
                                        </span>
                                        <h6>3 activities in progress</h6>
                                      </div>
                                      <div className="activiti-count-mesg">
                                        <div className="chat-right-droplist">
                                          <ul className="list-chatbx">
                                            <li className="chat-dropright educ-acvt">
                                              <div className="droplist-right-btn">
                                                <Dropdown className="dropdown-main">
                                                  <Dropdown.Toggle
                                                    variant="success"
                                                    id="dropdown-basic"
                                                    className="dropdown-btnn addactv-btndrp"
                                                  >
                                                    <span className="actvty-icn">
                                                      <i className="fas fa-plus"></i>
                                                    </span>{" "}
                                                    Add an activity
                                                  </Dropdown.Toggle>

                                                  <Dropdown.Menu className="dropdown-btn-item">
                                                    <Dropdown.Item href="#">
                                                      <span className="rightarrow-icon">
                                                        <i className="fas fa-angle-right"></i>
                                                      </span>{" "}
                                                      My games
                                                    </Dropdown.Item>
                                                    <Dropdown.Item href="#">
                                                      <span className="rightarrow-icon">
                                                        <i className="fas fa-angle-right"></i>
                                                      </span>{" "}
                                                      Neithedu games
                                                    </Dropdown.Item>
                                                    <Dropdown.Item href="#">
                                                      <span className="rightarrow-icon">
                                                        <i className="fas fa-angle-right"></i>
                                                      </span>{" "}
                                                      Create new games
                                                    </Dropdown.Item>
                                                    <Dropdown.Item href="#">
                                                      <span className="rightarrow-icon">
                                                        <i className="fas fa-angle-right"></i>
                                                      </span>{" "}
                                                      Personal task
                                                    </Dropdown.Item>
                                                    <Dropdown.Item href="#">
                                                      <span className="rightarrow-icon">
                                                        <i className="fas fa-angle-right"></i>
                                                      </span>{" "}
                                                      Material
                                                    </Dropdown.Item>
                                                    <Dropdown.Item href="#">
                                                      <span className="rightarrow-icon">
                                                        <i className="fas fa-angle-right"></i>
                                                      </span>{" "}
                                                      Live Lesson
                                                    </Dropdown.Item>
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </div>
                                            </li>
                                            <li className="chat-iconn educ-chat-lst">
                                              <div className="chatbtn">
                                                <Link
                                                  to="#"
                                                  className="chatbtn-new"
                                                >
                                                  {" "}
                                                  <img
                                                    src={chaticon}
                                                    className="img-fluid"
                                                    alt=""
                                                  />
                                                </Link>
                                              </div>
                                            </li>
                                            <li className="chat-iconn edu-badg-lst">
                                              <div className="chatbtn">
                                                <Link
                                                  to="#"
                                                  className="chatbtn-new"
                                                >
                                                  {" "}
                                                  <img
                                                    src={batchorange}
                                                    className="img-fluid"
                                                    alt=""
                                                  />
                                                </Link>
                                              </div>
                                            </li>
                                            <li className="chat-dropright edu-lst-drp">
                                              <div className="droplist-right-btn">
                                                <Dropdown className="dropdown-main">
                                                  <Dropdown.Toggle
                                                    variant="success"
                                                    id="dropdown-basic"
                                                    className="dropdown-btnn"
                                                  >
                                                    <i className="fas fa-ellipsis-v"></i>
                                                  </Dropdown.Toggle>

                                                  <Dropdown.Menu className="dropdown-btn-item">
                                                    <Dropdown.Item href="#">
                                                      <span className="rightarrow-icon">
                                                        <i className="fas fa-angle-right"></i>
                                                      </span>{" "}
                                                      All files
                                                    </Dropdown.Item>
                                                    <Dropdown.Item href="#">
                                                      <span className="rightarrow-icon">
                                                        <i className="fas fa-angle-right"></i>
                                                      </span>{" "}
                                                      Notifications
                                                    </Dropdown.Item>
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="Message-chat-room">
                                <div className="chatbox-room">
                                  <div className="chat-innerbox">
                                    <div className="chat-upperbox">
                                      <div className="custom-row">
                                        {/* marked */}
                                        <div className="chatbox-inner chat-sender">
                                          <div className="message-rom-iner">
                                            <div className="message-time">
                                              <span className="time">
                                                11.05
                                              </span>
                                            </div>
                                            <div className="message-box">
                                              <div className="message-tex">
                                                You have new game “Lorem ipsum
                                                dolor sit amet, consectetur
                                                adipiscing elit, sed do eiusmod
                                                tempor incididunt ut labore et
                                                dolore magna aliqua. Ut enim ad
                                                minim veniam”
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        {/* marked */}
                                        <div className="chatbox-inner chat-receiver">
                                          <div className="message-rom-iner">
                                            <div className="message-box">
                                              <div className="message-tex">
                                                Lorem ipsum dolor sit amet,
                                                consectetur adipiscing elit, sed
                                                do eiusmod tempor incididunt ut
                                                labore et dolore magna aliqua.
                                                Ut enim ad minim veniam
                                              </div>
                                            </div>
                                            <div className="message-time">
                                              <span className="time">
                                                11.05
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="chat-footer">
                                  <div className="chat-footer-main">
                                    <div className="chat-footer-message">
                                      <div className="message-send-new">
                                        <Form.Control
                                          as="textarea"
                                          className="message-textarea"
                                          placeholder="Your message"
                                          value={message}
                                          onChange={(e) =>
                                            setMessage(e.target.value)
                                          }
                                        />
                                        <div className="right-chaticon">
                                          <ul>
                                            <li>
                                              <button
                                                className="sendme-btn"
                                                onClick={SendDirectMessage}
                                              >
                                                <img
                                                  src={palinblue}
                                                  className="img-fluid"
                                                  alt=""
                                                />
                                              </button>
                                            </li>
                                            <li>
                                              <button className="attachment-btn">
                                                <img
                                                  src={mike}
                                                  className="img-fluid"
                                                  alt=""
                                                />
                                              </button>
                                            </li>
                                            <li>
                                              <button className="attachment-btn">
                                                <img
                                                  src={attachemtnblue}
                                                  className="img-fluid"
                                                  alt=""
                                                />
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                        </div>
                      </div>
                    </div>
                  </Tabs>
                </div>
              </div>
            </div>
          </Container>
        </div>

        {/* footer start */}
        <FooterEducator logo={logo} />
        {/* footer end */}
      </div>

      {/* start add pupil modal */}

      <Addpupil
        modalEducatorstep1Show={modalEducatorstep1Show}
        setEducatorstep1ModalShow={(value) => setEducatorstep1ModalShow(value)}
      />

      {/* start Add a Group step1 */}

      <Addgroup
        modalAddgroupstep1Show={modalAddgroupstep1Show}
        setAddgroupstep1ModalShow={(value) => setAddgroupstep1ModalShow(value)}
      />

      {/* end Add a Group step1 */}

      {/* start Send Invite step1 */}

      <SendInvite
        modalinvitestep1Show={modalinvitestep1Show}
        setinvitestep1ModalShow={(value) => setinvitestep1ModalShow(value)}
      />

      {/* end Send Invite step1 */}

      {/* start Send Invite step1 */}
      <Groupmember
        modalgroupmemberstep1Show={modalgroupmemberstep1Show}
        setgroupmemberstep1ModalShow={(value) =>
          setgroupmemberstep1ModalShow(value)
        }
      />
      {/* end Send Invite step1 */}

      {/* end add pupil modal */}
    </div>
  );
}

export default Messages;

// import { HttpClient } from "@angular/common/http";
// import { Injectable } from "@angular/core";
// import { environment } from "src/environments/environment";
// @Injectable({
//     providedIn: 'root'
// })
// export class MessageService {
//     readonly BaseURI = environment.apiBaseUrl;
//     constructor(private http: HttpClient) {
//     }
//     getUserReceivedMessages(userId: string) {
//         return this.http.get(this.BaseURI + '/message');
//     }
//     deleteMessage(message) {
//         return this.http.post(this.BaseURI + '/message', message);
//     }
// }

// import { UserService } from '../service/user.service';
// import { Component, OnInit } from '@angular/core';
// import { Router } from '@angular/router';
// import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
// import { environment } from 'src/environments/environment';
// import { MessageService } from '../service/message.service';
// import { Guid } from 'guid-typescript';
// @Component({
//   selector: 'app-home',
//   templateUrl: './home.component.html',
//   styleUrls: ['./home.component.css']
// })
// export class HomeComponent implements OnInit {
//   loggedInUser = JSON.parse(localStorage.getItem("login-user"))
//   users:any;
//   chatUser:any;
//   messages: any[] = [];
//   displayMessages: any[] = []
//   message: string
//   hubConnection: HubConnection;

//   connectedUsers: any[] = []
//   constructor(private router: Router, private service: UserService, private messageService: MessageService) { }
//   ngOnInit() {
//      this.messageService.getUserReceivedMessages(this.loggedInUser.id).subscribe((item:any)=>{
//        if(item){
//          this.messages=item;
//          this.messages.forEach(x=>{
//           x.type=x.receiver===this.loggedInUser.id?'recieved':'sent';
//          })
//          console.log(this.messages);
//        }
//      })

//     this.service.getAll().subscribe(
//       (user:any) => {
//         if(user){
//         this.users=user.filter(x=>x.email!==this.loggedInUser.email);
//         this.users.forEach(item=>{
//           item['isActive']=false;
//         })
//         this.makeItOnline();
//         }
//       },
//       err => {
//         console.log(err);
//       },
//     );
//     this.message = ''
//     this.hubConnection = new HubConnectionBuilder().withUrl(environment.chatHubUrl).build();
//     const self = this
//     this.hubConnection.start()
//       .then(() => {
//         self.hubConnection.invoke("PublishUserOnConnect", this.loggedInUser.id, this.loggedInUser.firstName, this.loggedInUser.userName)
//           .then(() => console.log('User Sent Successfully'))
//           .catch(err => console.error(err));
//         this.hubConnection.on("BroadcastUserOnConnect", Usrs => {
//           this.connectedUsers = Usrs;
//           this.makeItOnline();
//         })
//         this.hubConnection.on("BroadcastUserOnDisconnect", Usrs => {
//           this.connectedUsers = Usrs;
//           this.users.forEach(item => {
//             item.isOnline = false;
//           });
//           this.makeItOnline();
//         })
//       })
//       .catch(err => console.log(err));
//     // this.hubConnection.on("UserConnected", (connectionId) => this.UserConnectionID = connectionId);
//     this.hubConnection.on('BroadCastDeleteMessage', (connectionId, message) => {
//      let deletedMessage=this.messages.find(x=>x.id===message.id);
//      if(deletedMessage){
//        deletedMessage.isReceiverDeleted=message.isReceiverDeleted;
//        deletedMessage.isSenderDeleted=message.isSenderDeleted;
//        if(deletedMessage.isReceiverDeleted && deletedMessage.receiver===this.chatUser.id){
//         this.displayMessages = this.messages.filter(x => (x.type === 'sent' && x.receiver === this.chatUser.id) || (x.type === 'recieved' && x.sender === this.chatUser.id));
//        }
//      }
//     })
//     this.hubConnection.on('ReceiveDM', (connectionId, message) => {
//       console.log(message);
//       message.type = 'recieved';
//       this.messages.push(message);
//       let curentUser = this.users.find(x => x.id === message.sender);
//       this.chatUser = curentUser;
//       this.users.forEach(item => {
//         item['isActive'] = false;
//       });
//       var user = this.users.find(x => x.id == this.chatUser.id);
//       user['isActive'] = true;
//       this.displayMessages = this.messages.filter(x => (x.type === 'sent' && x.receiver === this.chatUser.id) || (x.type === 'recieved' && x.sender === this.chatUser.id));
//     })
//   }
//   SendDirectMessage() {
//     if (this.message != '' && this.message.trim() != '') {
//       let guid=Guid.create();
//       var msg = {
//         id:guid.toString(),
//         sender: this.loggedInUser.id,
//         receiver: this.chatUser.id,
//         messageDate: new Date(),
//         type: 'sent',
//         content: this.message
//       };
//       this.messages.push(msg);
//       this.displayMessages = this.messages.filter(x => (x.type === 'sent' && x.receiver === this.chatUser.id) || (x.type === 'recieved' && x.sender === this.chatUser.id));
//       this.hubConnection.invoke('SendMessageToUser', msg)
//         .then(() => console.log('Message to user Sent Successfully'))
//         .catch(err => console.error(err));
//       this.message = '';
//     }
//   }
//   openChat(user) {
//     this.users.forEach(item => {
//       item['isActive'] = false;
//     });
//     user['isActive'] = true;
//     this.chatUser = user;
//     this.displayMessages = this.messages.filter(x => (x.type === 'sent' && x.receiver === this.chatUser.id) || (x.type === 'recieved' && x.sender === this.chatUser.id));;
//   }
//   makeItOnline() {
//     if (this.connectedUsers && this.users) {
//       this.connectedUsers.forEach(item => {
//         var u = this.users.find(x => x.userName == item.username);
//         if (u) {
//           u.isOnline = true;
//         }
//       })
//     }
//   }
//   deleteMessage(message,deleteType,isSender){
//     let deleteMessage={
//       'deleteType':deleteType,
//       'message':message,
//       'deletedUserId':this.loggedInUser.id
//     }
//     this.hubConnection.invoke('DeleteMessage', deleteMessage)
//         .then(() => console.log('publish delete request'))
//         .catch(err => console.error(err));
//     message.isSenderDeleted=isSender;
//     message.isReceiverDeleted=!isSender;
//   }
//   onLogout() {
//     this.hubConnection.invoke("RemoveOnlineUser", this.loggedInUser.id)
//       .then(() => {
//         this.messages.push('User Disconnected Successfully')
//       })
//       .catch(err => console.error(err));
//     localStorage.removeItem('token');
//     this.router.navigate(['/user/login']);
//   }

// }
