import React, { useEffect, useState } from "react";
import "../home.scss";
import "../profile.scss";
import "../media.scss";
import "../games.scss";
import "../loginModal/login.scss";
import "../messages/messages.scss";
import "../myactivities.scss";
import "../gamestabs.scss";
import "../gamesflowphotos.scss";
import "../selectedgames.scss";
import "../gamequiz.scss";
import {
  // Navbar,
  // Nav,
  // NavDropdown,
  Container,
  // Dropdown,
  // br,
  Row,
  // Accordion,
  // Card,
  Button,
  Form,
  Modal,
  // Select,
  // Sonnet,
} from "react-bootstrap";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import logo from "../../assets/images/logo.png";
// import gamesgalery1 from "../assets/images/games-galery1.jpg";
// import gamesgalery2 from "../assets/images/games-galery2.jpg";
// import chaticon from "../assets/images/chat-icon.svg";
// import batchorange from "../assets/images/batch-orange.svg";
// import palinblue from "../assets/images/palin-blue.svg";
// import mike from "../assets/images/mike.svg";
// import attachemtnblue from "../assets/images/attachemtn-blue.svg";
// import downarrowblue from "../assets/images/down-arrow-blue.svg";
// import dotthree from "../assets/images/dotthree.svg";
// import addbtnround from "../assets/images/add-btn.svg";
// import darkblueplan from "../assets/images/darkblue-plan.svg";
import backorange from "../../assets/images/backorange.svg";
import editorangelg from "../../assets/images/editlg-orange.svg";
import darkorangeplan from "../../assets/images/darkorange-plan.svg";

// import Slider from "react-slick";
// import SliderCenter from "../Slider";
import { Link, useParams } from "react-router-dom";
import FooterEducator from "../layout/Footer/FooterEducator";
// import Addgroup from "../Addgroup";
// import Addpupil from "../Addpupil";
// import SendInvite from "../SendInvite";
// import Groupmember from "../Groupmember";
import HeaderGames from "../layout/Header/HeaderGames";
import SendRevision from "../SendRevision";
import Selectstudent from "../Selectstudent";
import { HeaderDashboard } from "../layout/HeaderDashboard";
import { getSignalGames, sendAnswerQu } from "../../service/apis/games";
import { Box } from "@mui/material";
import { useHistory } from "react-router-dom";
import SuccessModal from "../dialogs/SuccessModal";


function GameQuizAnswer() {
  // const [show, setShow] = React.useState("1");

  // const [breadMenuShow, setBreadMenuShow] = React.useState(false);
  /* start modal */
  const [modalShow, setModalShow] = React.useState(false);
  /* end modal */

  /* startsave gamed step1 */
  const [modalgamesavedstep1Show, setgamesavedstep1ModalShow] =
    React.useState(false);

  /* end save gamned step1 */

  /* start send revision popup */
  const [modalpersonaltskstep1Show, setpersonaltskstep1ModalShow] =
    React.useState(false);

  /* end send revision popup */

  /* start select student */
  const [modalAddgroupstep2Show, setAddgroupstep2ModalShow] =
    React.useState(false);

  /* end select student */

  const questionTypes = ["sf","Discrete","MCQ","NonDiscrete","Optional","TrueFalse","Optional"]
  let history = useHistory();
  const parems = useParams();
  const [game,setGame]=useState()
  const [answer,setanswer] = useState("")
  const [trueFalse,setTrueFalse] = useState(1)
  const [showModal,setShowModal] = useState(false)
  console.log(parems)


  const sendAnswerQuiz = async(data,value)=>{
    const res = await sendAnswerQu(data,value)
    console.log(res)
    if(res){
      setShowModal(true)
      setanswer("")
    }
  }


  const faceGaneData = async()=>{
    const res = await getSignalGames(parems.id,parems.gametype)
    setGame(res)
  }

  console.log(game)

  useEffect(()=>{
    faceGaneData()
  },[parems.id,parems.gametype])




  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderDashboard />
      {/* end header */}

      <div className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs">
        <div className="games-photosynths gamequiz-main">
          <Container>
            <div className="quiz-game-popup">
              <div className="current-lesson-main ">
                <div className="current-lesson-contain">
                  <span className="back-btndrop">
                    <Link to="#" onClick={history.goBack} className="backbtn-lg">
                      <img src={backorange} />
                    </Link>
                  </span>
                  <div className="current-lesson-topbr">
                    <div className="medm-tex orng-clr">Neithedu Game: Quiz</div>
                    <h1>
                        {game?.title}
                    </h1>
                    <h3>{game?.description}</h3>
                    <div className="lesson-status-list quiz-gamelession">
                      {/* <ul className="lesson-listing-curnt">
                        <li>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Mode:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              Active{" "}
                            </span>
                          </div>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Status:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              Not checked{" "}
                            </span>
                          </div>
                        </li>
                        <li>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Deadline:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              2 Sep 2021
                            </span>
                          </div>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Created:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              16 Aug 2021
                            </span>
                          </div>
                        </li>
                        <li>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Points:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              10 for question
                            </span>
                          </div>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Total points:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              150 points
                            </span>
                          </div>
                        </li>
                      </ul> */}
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{ borderRadius: "2px" }}
                className="question-scrlbar-bx"
              >
                <div className="question-sheet-quiz">
                  <div className="questionbox-main beginer-box">
                   
                    {
                      game?.quizeGameQuestions.map((item,i)=>{
                        return(
                          <div key={i} className="iner-quest-report quizgame-bodytable">
                          <Row className="reportcard-box">
                            <div className="col-md-5 col-lg-4 one">
                              <div className="left-quedtions">
                                <div className="Question-listbx">
                                  <div className="title">
                                    <span className="bold">Question {i+1}</span>
                                    <span className="open-quest">
                                      {" "}
                                      {questionTypes[item.questionType]}
                                    </span>
                                  </div>
                                  <div className="question-contwnt">
                                    <p>
                                      {item.questionText}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-7 col-lg-8 two">
                              <div className="answer-right">
                                <div className="aswer-details">
                                  <div className="inner-quizgame-cont">
                                    <Box sx={{display:"flex", alignItems:"center",gap:"0.5rem"}}>
                                        {
                                          item.questionType === 1 || item.questionType === 3 &&
                                        <Form.Control
                                        as="textarea"
                                        placeholder="Write Answer..."
                                        onChange={(e)=>setanswer(e.target.value)}
                                        value={answer}
                                        />
                                        }
                                        {
                                          item.questionType === 5 &&
                                        <Form.Control
                                          as="select"
                                          value={trueFalse}
                                          onChange={(e)=>setTrueFalse(e.target.value)}
                                        >
                                        <option  value="1">
                                          True
                                        </option>
                                        <option  value="0">
                                          false
                                        </option>
                                        </Form.Control>
                                         }
                                      <div className="btn-week" style={{marginRight:"0.5rem"}}>
                                        <Button onClick={()=>sendAnswerQuiz(item,item.questionType === 1 || item.questionType === 3 ? answer : trueFalse)} className="btnweak-bx">
                                          <span className="btn-title">Send</span>
                                        </Button>
                                      </div>
                                    </Box>
                                  </div>
                                  {/* <div className="truefalse-quiz">
                                    <ul className="quiz-truebx">
                                      <li>
                                        <span className="true-icon-iner">
                                          <i className="fas fa-check"></i>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="true-icon-iner">
                                          <i className="fas fa-times"></i>
                                        </span>
                                      </li>
                                    </ul>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </Row>
                        </div>
                        )
                      })
                    }




                  </div>
                </div>
              </div>

              {/* <div
                style={{ borderRadius: "2px" }}
                className="question-scrlbar-bx quiz-btm-cards"
              >
                <div className="question-sheet-quiz">
                  <div className="questionbox-main beginer-box">
                    <div className="iner-quest-report">
                      <Row className="reportcard-box">
                        <div className="col-md-5 col-lg-4 one">
                          <div className="left-quedtions">
                            <div className="Question-listbx">
                              <div className="question-contwnt">
                                <p>
                                  I understand the difference between these and
                                  those
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7 col-lg-8 two">
                          <div className="answer-right">
                            <div className="aswer-details">
                              <p>
                                <strong>Yes</strong>
                              </p>
                              <p>Text of the comment that student received</p>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </div>

                    <div className="iner-quest-report">
                      <Row className="reportcard-box">
                        <div className="col-md-5 col-lg-4 one">
                          <div className="left-quedtions">
                            <div className="Question-listbx">
                              <div className="question-contwnt">
                                <p>
                                  I understand the difference between these and
                                  those
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7 col-lg-8 two">
                          <div className="answer-right">
                            <div className="aswer-details">
                              <p>
                                <strong>Yes</strong>
                              </p>
                              <p>Text of the comment that student received</p>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </div>

                    <div className="iner-quest-report">
                      <Row className="reportcard-box">
                        <div className="col-md-5 col-lg-4 one">
                          <div className="left-quedtions">
                            <div className="Question-listbx">
                              <div className="question-contwnt">
                                <p>
                                  I understand the difference between these and
                                  those
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7 col-lg-8 two">
                          <div className="answer-right">
                            <div className="aswer-details">
                              <p>
                                <strong>Yes</strong>
                              </p>
                              <p>Text of the comment that student received</p>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="send-revision">
                <ul className="send-revision-list">
                  <li>
                    <div className="btn-week">
                      <Link
                        to="#"
                        className="btnweak-bx"
                        onClick={() => setpersonaltskstep1ModalShow(true)}
                      >
                        <span className="btn-title">Send for revision</span>
                      </Link>
                    </div>

               

                    <SendRevision
                      modalpersonaltskstep1Show={modalpersonaltskstep1Show}
                      setpersonaltskstep1ModalShow={(value) =>
                        setpersonaltskstep1ModalShow(value)
                      }
                    />

                
                  </li>
                  <li>
                    <div className="btn-week">
                      <Link to="#" className="btnweak-bx">
                        <span className="btn-title">Complete</span>
                      </Link>
                    </div>
                  </li>
                </ul>
              </div> */}
            </div>
          </Container>
        </div>

        {/* footer start */}
        <FooterEducator logo={logo} />
        {/* footer end */}
      </div>

      {/* start modal my student */}
      {showModal && <SuccessModal modalTitle={"Quiz Answer Send"} onClose={setShowModal}/>}

      {/* start game save step1 */}

      {/* end Add a Member Group step2 */}

      {/* end modal my student */}

      {/* start select studen popup */}

      {/* end select studen popup */}
    </div>
  );
}

export default GameQuizAnswer;
