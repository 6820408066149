import { Box, Divider, Grid, Modal, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

const tabs = {
  FRIENDS: "FRIENDS",
  MUTUAL: "MUTUAL",
};
export const FriendsDialog = ({ onClose }) => {
  const [state, setstate] = useState({
    currentTab: tabs.FRIENDS,
  });
  const CustomTab = ({ tabName, currentTab, children }) => {
    const isSel = tabName === currentTab;
    return (
      <Box
        onClick={() =>
          setstate((prevState) => ({ ...prevState, currentTab: tabName }))
        }
        sx={{
          zIndex: "2",
          cursor: "pointer",
          minWidth: "8rem",
          display: "flex",
          justifyContent: "center",
          color: "var(--purple)",
          pb: "0.3rem",
          fontWeight: isSel ? "700" : "400",
          fontSize: "1.1rem",
          borderBottom: isSel ? "4px solid var(--orange)" : "none",
        }}
      >
        {children}
      </Box>
    );
  };
  return (
    <>
      <Modal
        sx={{ overflowY: "scroll" }}
        open={true}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100%",
            p: "1rem",
          }}
        >
          <Box
            sx={{
              backgroundColor: "white",
              maxWidth: "40rem",
              p: "1rem",
              borderRadius: "15px",
              position: "relative",
            }}
          >
            <CloseIcon
              onClick={onClose}
              sx={{
                cursor: "pointer",
                position: "absolute",
                top: "5px",
                right: "5px",
                color: "var(--purple-dark)",
              }}
            />
            <Stack flexDirection={"row"} justifyContent="center">
              <CustomTab currentTab={state.currentTab} tabName={tabs.FRIENDS}>
                <Typography fontWeight="inherit">Friends</Typography>
                <Typography
                  sx={{
                    color: "white",
                    backgroundColor: "var(--orange)",
                    borderRadius: "4px",
                    p: "0 0.2rem",
                    ml: "0.3rem",
                    fontWeight: "700",
                  }}
                >
                  2
                </Typography>
              </CustomTab>
              <CustomTab currentTab={state.currentTab} tabName={tabs.MUTUAL}>
                <Typography fontWeight="inherit">Mutual</Typography>
              </CustomTab>
            </Stack>
            <Divider sx={{ position: "relative", top: "-2px", mb: "1rem" }} />

            <Grid
              container
              columnSpacing={"2rem"}
              rowSpacing="1rem"
              sx={{ height: "20rem", overflowY: "scroll", pr: "0.5rem" }}
            >
              {Array.from(Array(20).keys()).map((_, idx) => {
                return (
                  <Grid item sm={2.4}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <img
                        width={"100%"}
                        src={"/images/profilepic.png"}
                        alt="profile pic"
                      />
                      <Typography
                        fontSize="0.9rem"
                        textAlign={"center"}
                        mt="0.2rem"
                      >
                        Aarush Khatri
                      </Typography>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
