import React, { useState } from "react";
import { Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAppState } from "../overmind";
import { sendInvitationToStudent } from "../service/apis/educator";
import "./loginModal/login.scss";

const SendInvite = ({ setinvitestep1ModalShow, modalinvitestep1Show }) => {
  /* start add pupil step2 */
  const [modalpupiltep2Show, setpupiltep2ModalShow] = useState(false);
  /* end add pupil  step2 */

  /* start send Invite step2 */
  const [modalinvitestep2Show, setinvitestep2ModalShow] = useState(false);
  /* end send Invite  step2 */
  const [email, setemail] = useState("");
  const appState = useAppState();
  const sendInvitation = async () => {
    await sendInvitationToStudent(email, appState.roleId);
  };
  return (
    <div className="Send-invite-mainbx">
      {/* start send invite step1 */}
      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new send-invite-modal1"
        // {...props}
        show={modalinvitestep1Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Send the invitation to Neithedu</h4>
              <p>
                Please, enter email of student and we will send the invitation.
                You will get $10 for every new student in Neithedu who will buy
                subscription
              </p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setinvitestep1ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <Form.Label>Pupil’s email</Form.Label>

                  <div className="livein-field">
                    <Form.Control
                      value={email}
                      onChange={(e) => setemail(e.target.value)}
                      type="email"
                      placeholder="example@email.com"
                    ></Form.Control>
                  </div>
                </Form.Group>

                <div className="btnlogin-main">
                  <button
                    type="submit"
                    className="btnlogin btnweak-bx"
                    onClick={async () => {
                      await sendInvitation();
                      setinvitestep2ModalShow(true);
                      setinvitestep1ModalShow(false);
                    }}
                  >
                    Send the invitation link
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end Send invite step1 */}

      {/* start send invite step2 */}
      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new send-invite-modal2"
        // {...props}
        show={modalinvitestep2Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header>
            <div className="btnback">
              <Link to="#" className="backbtn-icn">
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Invitation was sent</h4>
              <p>You will see your bonus when the student buys subscription</p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setinvitestep2ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
        </div>
      </Modal>

      {/* end Send invite step1 */}

      {/* End send invite popup */}
    </div>
  );
};

export default SendInvite;
