import React, { useState, useEffect, useCallback } from "react";
import { Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { postDate } from "../service/AllApi";
import { allSyllabus, AllWorkExperience, genders } from "../service/constant";
import { validateEmail } from "../service/commanFunction";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { Axios } from "../service/Axios";
import axios from "axios";
import { backEnd } from "../env";
import { ConfirmationDialog } from "./registration/ConfirmationDialog";
import { CustomSnackbar, useSnackState } from "../utils/useSnackState";
import { disabledBtnProps } from "../utils/util";

// range slider
function valueLabelFormat(value) {
  return `${value} y.o.`;
}

// end range slider
const SignUpEducator = ({
  setModalSignupShow,
  modalEducatorShow,
  setEducatorModalShow,
  setModalShow,
  country,
}) => {
  const [snackProps, snackOpen] = useSnackState();
  const [modalEducatorstep1Show, setEducatorstep1ModalShow] = useState(false);
  const [modalEducatorstep2Show, setEducatorstep2ModalShow] = useState(false);
  const [modalEducatorstep3Show, setEducatorstep3ModalShow] = useState(false);
  const [modalEducatorstep4Show, setEducatorstep4ModalShow] = useState(false);
  const [modalEducatorstep5Show, setEducatorstep5ModalShow] = useState(false);
  const [modalEducatorstep6Show, setEducatorstep6ModalShow] = useState(false);
  const [modalEducatorstep7Show, setEducatorstep7ModalShow] = useState(false);
  const [modalEducatorstep8Show, setEducatorstep8ModalShow] = useState(false);
  const [modalEducatorstep9Show, setEducatorstep9ModalShow] = useState(false);
  const [modalEducatorstep10Show, setEducatorstep10ModalShow] = useState(false);
  const [values, setValues] = useState({
    userId: null,
    fullName: "",
    userName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
    genderId: null,
    countryId: 101,
    stateId: null,
    cityId: "",
    yearsOfBirth: "",
    workExperience: 0,
    age: "",
    isAgreeWithTutorPolicy: true,
    syllabus: [],
  });

  const [name, setName] = useState({ first: "", last: "" });
  const [years, setYears] = useState([]);
  const [isPasswordVisible, setisPasswordVisible] = useState({
    create: false,
    repeat: false,
  });
  const [disabled, setDisabled] = useState(true);
  const [value, setValue] = useState([20, 37]);
  const [stepCount, setStepCount] = useState(1);
  const [states, setstates] = useState([]);
  const [cities, setcities] = useState([]);
  const [errors, setErrors] = useState({
    first: false,
    last: false,
    countryId: false,
    genderId: false,
    yearsOfBirth: false,
    syllabus: false,
    workExperience: false,
    email: false,
    phoneNumber: false,
    password: false,
    confirmPassword: false,
  });
  const [otp, setOTP] = useState(null);
  const [otpError, setOtpError] = useState(false);
  const [phoneVerify, setPhoneVerify] = useState(false);

  const handleChange = useCallback((event, newValue) => {
    setValue(newValue);
  });
  const fetchStates = async () => {
    try {
      const res = await Axios.get(`states/${values.countryId}`);
      setstates(res.data);
    } catch (err) {}
  };
  useEffect(() => {
    const year = new Date("12/12/1960").getFullYear();
    const yea = Array.from(new Array(60), (val, index) => index + year);
    setYears(yea);
    fetchStates();
  }, []);
  const updateOtp = useCallback((e) => {
    try {
      setOtpError(false);
      if (
        !e.target.value ||
        e.target.value === "" ||
        e.target.value === undefined
      ) {
        setOtpError(true);
      }
      setOTP(e.target.value);
    } catch (err) {
      //console.log("error in updateOtp:", err.message || err);
    }
  });
  const verify = async () => {
    try {
      // if (otpError) {
      //   return;
      // }
      if (!otp) {
        setOtpError(true);
        return;
      }
      const payload = {
        userId: values.userId,
        otp: otp,
      };
      const response = await postDate("auth/verify-otp", payload);
      setDisabled(false);
      setPhoneVerify(true);
    } catch (err) {
      setOtpError(true);
      //console.log("error :", err.message || err);
    }
  };
  const sendOtp = async (UserId) => {
    try {
      await axios.post(
        backEnd + "/auth/send-otp",
        {},
        {
          params: {
            PhoneNumber: values.phoneNumber,
            UserId,
          },
        },
      );
    } catch (err) {}
  };
  const verifyOtp = async () => {
    try {
      await postDate("/auth/verify-otp", { otp: otp, userId: values.userId });
    } catch (err) {
      setErrors((state) => ({ ...state }));
    }
  };
  const handleSignUp = useCallback(async () => {
    try {
      const payload = {
        firstName: name.first,
        lastName: name.last,
        userName: values.email,
        email: values.email,
        password: values.password,
        confirmPassword: values.confirmPassword,
        phoneNumber: values.phoneNumber,
        genderId: parseInt(values.genderId),
        countryId: parseInt(values.countryId),
        cityId: parseInt(values.cityId),
        yearsOfBirth: parseInt(values.yearsOfBirth),
        workExperience: parseInt(values.workExperience),
        age: new Date().getFullYear() - parseInt(values.yearsOfBirth),
        isAgreeWithTutorPolicy: true,
        syllabuses: values.syllabus,
      };
      const response = await postDate("register/educator", payload);
      setValues((state) => ({ ...state, userId: response.data.userId }));
      await sendOtp(response.data.userId);
    } catch (err) {
      //console.log("educator signup error:", err.message || err);
    }
  });

  const step1FetchData = useCallback(async () => {
    const haveErrors = {
      first: false,
      last: false,
    };
    if (!name.first || name.first === "" || name.first === undefined) {
      haveErrors.first = true;
    }
    if (!name.last || name.last === "" || name.last === undefined) {
      haveErrors.last = true;
    }
    if (haveErrors.first || haveErrors.last) {
      setErrors({
        ...errors,
        ...haveErrors,
      });
      return;
    }
    setValues({ ...values, fullName: name.first + " " + name.last });
    setEducatorstep1ModalShow(true);
    setEducatorModalShow(false);
    setStepCount(2);
  });
  const step2FetchData = useCallback(async () => {
    if (values.countryId == 0 || values.countryId === "") {
      setErrors({
        ...errors,
        countryId: true,
      });
      return false;
    }
    setEducatorstep2ModalShow(true);
    setEducatorstep1ModalShow(false);
    setStepCount(3);
  });

  const step3FetchData = useCallback(async () => {
    if (
      !values.yearsOfBirth ||
      values.yearsOfBirth === "" ||
      parseInt(values.yearsOfBirth) === 0
    ) {
      setErrors({
        ...errors,
        yearsOfBirth: true,
      });
      return false;
    }
    if (
      !values.genderId ||
      values.genderId === "" ||
      parseInt(values.genderId) === -1
    ) {
      setErrors({
        ...errors,
        genderId: true,
      });
      return false;
    }
    setEducatorstep3ModalShow(true);
    setEducatorstep2ModalShow(false);
    setStepCount(4);
  });

  const step4FetchData = useCallback(async () => {
    if (!values.syllabus || values.syllabus.length === 0) {
      setErrors({
        ...errors,
        syllabus: true,
      });
      return;
    }
    setEducatorstep4ModalShow(true);
    setEducatorstep3ModalShow(false);
    setStepCount(5);
  });
  const step5FetchData = useCallback(async () => {
    if (
      !values.workExperience ||
      values.workExperience === "" ||
      parseInt(values.workExperience) == 0
    ) {
      setErrors({
        ...errors,
        workExperience: true,
      });
      return false;
    }
    setEducatorstep5ModalShow(true);
    setEducatorstep4ModalShow(false);
    setStepCount(6);
  });

  const step6FetchData = useCallback(async () => {
    if (!values.email || values.email === undefined || values.email === "") {
      setErrors({
        ...errors,
        email: true,
      });
      return false;
    }
    const emailValid = await validateEmail(values.email);
    if (!emailValid) {
      setErrors({
        ...errors,
        email: true,
      });
      return;
    }
    setEducatorstep6ModalShow(true);
    setEducatorstep5ModalShow(false);
    setStepCount(7);
  });

  const step7FetchData = useCallback(() => {
    if (!values.password || values.password === "") {
      setErrors({
        ...errors,
        password: true,
      });
      return;
    }
    if (!values.confirmPassword || values.confirmPassword === "") {
      setErrors({
        ...errors,
        confirmPassword: true,
      });
      return;
    }
    if (values.confirmPassword !== values.password) {
      setErrors({
        ...errors,
        password: true,
        confirmPassword: true,
      });
      return;
    }
    if (values.password.length < 6) {
      snackOpen("Password should be atleast 6 characters", "error");
      return;
    }
    setEducatorstep7ModalShow(true);
    setEducatorstep6ModalShow(false);
    setStepCount(8);
  });

  const step8FetchData = useCallback(async () => {
    // if (otpError || !otp || !phoneVerify) {
    //   setOtpError(true);
    //   return;
    // }
    if (!values.phoneNumber || values.phoneNumber === "") {
      setErrors({
        ...errors,
        phoneNumber: true,
      });
      return false;
    }
    await handleSignUp();
    setEducatorstep8ModalShow(true);
    setEducatorstep7ModalShow(false);
    setStepCount(9);
  });
  const step9FetchData = useCallback(() => {
    if (!phoneVerify) {
      setOtpError(true);
      return;
    }
    setEducatorstep9ModalShow(true);
    setEducatorstep8ModalShow(false);
    setStepCount(10);
  });
  const updateValues = useCallback(async (e, name) => {
    try {
      const errorObject = {};
      if (name === "confirmPassword" || name === "password") {
        errorObject["password"] = false;
        errorObject["confirmPassword"] = false;
      } else {
        errorObject[name] = false;
      }
      if (
        !e.target.value ||
        e.target.value === "" ||
        e.target.value === undefined
      ) {
        errorObject[name] = true;
      }
      const updateObject = {};
      if (name === "stateId" || name === "cityId") {
        updateObject[name] = parseInt(e.target.value);
        if (name === "stateId") {
          const res = await Axios.get(
            `/cities/${values.countryId}/${e.target.value}`,
          );
          setcities(res.data);
        }
      } else {
        updateObject[name] = e.target.value;
      }
      setValues({
        ...values,
        ...updateObject,
      });
      setErrors({
        ...errors,
        ...errorObject,
      });
    } catch (err) {
      //console.log("\n updateValues error:", err.message || err);
    }
  });

  const selectSyllabus = useCallback(async (selectedSubject) => {
    setErrors({
      ...errors,
      syllabus: false,
    });
    const existingSyllabus = values.syllabus;
    if (existingSyllabus.includes(selectedSubject)) {
      const indexOfSubject = existingSyllabus.indexOf(selectedSubject);
      if (indexOfSubject > -1) {
        const newSyllabus = existingSyllabus.splice(indexOfSubject, 1);
        setValues({ ...values, syllabus: existingSyllabus });
      }
      return;
    }
    existingSyllabus.push(selectedSubject);
    setValues({ ...values, syllabus: existingSyllabus });
  });

  const changePasswordVisibility = (field) =>
    setisPasswordVisible((state) => ({ ...state, [field]: !state[field] }));
  const backClick = useCallback((stepCount) => {
    try {
      switch (stepCount) {
        case 1:
          setEducatorstep9ModalShow(false);
          setEducatorModalShow(false);
          setModalSignupShow(true);
          break;
        case 2:
          setEducatorstep1ModalShow(false);
          setEducatorModalShow(true);
          setStepCount(1);
          break;
        case 3:
          setEducatorstep2ModalShow(false);
          setEducatorstep1ModalShow(true);
          setStepCount(2);
          break;
        case 4:
          setEducatorstep3ModalShow(false);
          setEducatorstep2ModalShow(true);
          setStepCount(3);
          break;
        case 5:
          setEducatorstep4ModalShow(false);
          setEducatorstep3ModalShow(true);
          setStepCount(4);
          break;
        case 6:
          setEducatorstep5ModalShow(false);
          setEducatorstep4ModalShow(true);
          setStepCount(5);
          break;
        case 7:
          setEducatorstep6ModalShow(false);
          setEducatorstep5ModalShow(true);
          setStepCount(6);
          break;
        case 8:
          setEducatorstep7ModalShow(false);
          setEducatorstep6ModalShow(true);
          setStepCount(7);
          break;
        case 9:
          setEducatorstep8ModalShow(false);
          setEducatorstep7ModalShow(true);
          setStepCount(8);
          break;
        case 10:
          setEducatorstep9ModalShow(false);
          setEducatorstep8ModalShow(true);
          setStepCount(9);
          break;
        default:
          setEducatorModalShow(false);
          setEducatorstep1ModalShow(false);
          setEducatorstep2ModalShow(false);
          setEducatorstep3ModalShow(false);
          setEducatorstep4ModalShow(false);
          setEducatorstep5ModalShow(false);
          setEducatorstep6ModalShow(false);
          setEducatorstep7ModalShow(false);
          setEducatorstep8ModalShow(false);
          setEducatorstep9ModalShow(false);
          setEducatorstep10ModalShow(false);
          setModalSignupShow(true);
          break;
      }
    } catch (err) {
      //console.log("backClick:", err.message || err);
    }
  });

  return (
    <div className="signup-wrapper">
      {/* FIRST AND LAST NAME DIALOG */}
      <Modal
        className="loginmodal student-login-main educator-login"
        show={modalEducatorShow}
        onHide={backClick}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header closeButton>
            <div className="btnback">
              <Link to="#" onClick={backClick} className="backbtn-icn">
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Sign up as a Educator</h4>
              <p>We are Neithedu team!</p>
              <p>Please introduce yourself</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <Form.Label>Your first name</Form.Label>
                  <Form.Control
                    type="name"
                    value={name.first}
                    onChange={(e) => {
                      if (!/^[a-zA-Z]*$/g.test(e.target.value)) {
                        return false;
                      }
                      setErrors({ ...errors, first: false });
                      setName({ ...name, first: e.target.value });
                    }}
                    className={errors.first ? "error-box" : ""}
                    placeholder="Your first name"
                  />
                </Form.Group>

                <Form.Group className="subsform-fild">
                  <Form.Label>Your last name</Form.Label>
                  <Form.Control
                    type="name"
                    value={name.last}
                    onChange={(e) => {
                      if (!/^[a-zA-Z]*$/g.test(e.target.value)) {
                        return false;
                      }
                      setErrors({ ...errors, last: false });
                      setName({ ...name, last: e.target.value });
                    }}
                    className={errors.last ? "error-box" : ""}
                    placeholder="Your last name"
                  />
                </Form.Group>

                <div className="btnlogin-main">
                  <button
                    style={
                      name.first && name.last ? {} : { ...disabledBtnProps }
                    }
                    type="submit"
                    className="btnlogin btnweak-bx"
                    onClick={step1FetchData}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="count-barr">
            <div className="counting-barful edu-count-step1">
              <div className="linebar-tex">1/9</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>
      {/* STATE AND CITY DIALOG */}
      <Modal
        className="loginmodal student-login-main educator-signup-step2 "
        // {...props}
        show={modalEducatorstep1Show}
        onHide={backClick}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header closeButton>
            <div className="btnback">
              <Link to="#" onClick={() => backClick(2)} className="backbtn-icn">
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Sign up as a educator</h4>
              <p>Great!</p>
              <p>Where are you from?</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <Form.Label>I live in</Form.Label>
                  <Row>
                    <div className="col-md-6 one">
                      <div className="livein-field">
                        <Form.Control
                          value={values.stateId}
                          className={errors.stateId ? "error-box" : ""}
                          onChange={(e) => updateValues(e, "stateId")}
                          as="select"
                        >
                          <option value="0">Choose your state</option>
                          {states
                            .sort((a, b) => a?.name.localeCompare(b.name))
                            .map((state, index) => {
                              return (
                                <option key={index} value={state?.id}>
                                  {state?.name}
                                </option>
                              );
                            })}
                        </Form.Control>
                      </div>
                    </div>
                    <div className="col-md-6 one">
                      <div className="livein-field">
                        <Form.Control
                          as="select"
                          value={values.cityId}
                          className={errors.cityId ? "error-box" : ""}
                          onChange={(e) => updateValues(e, "cityId")}
                        >
                          <option value="0">Choose your city</option>
                          {cities.map((city, index) => {
                            return (
                              <option key={index} value={city?.id}>
                                {city?.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </div>
                    </div>
                  </Row>
                </Form.Group>

                <div className="btnlogin-main">
                  <button
                    type="submit"
                    style={
                      values.cityId && values.stateId
                        ? {}
                        : { ...disabledBtnProps }
                    }
                    className="btnlogin btnweak-bx"
                    onClick={step2FetchData}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="count-barr edu-count-step2">
            <div className="counting-barful">
              <div className="linebar-tex">2/9</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>

      {/* YEAR OF BIRTH DIALOG*/}

      <Modal
        className="loginmodal student-login-main educator-signup-step3 "
        show={modalEducatorstep2Show}
        onHide={backClick}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header closeButton>
            <div className="btnback">
              <Link to="#" onClick={() => backClick(3)} className="backbtn-icn">
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Sign up as a educator</h4>
              <p>Thanks!</p>
              <p>Some more questions about you</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <Row>
                    <div className="col-md-6 one">
                      <div className="livein-field">
                        <Form.Label>Year of my birth</Form.Label>
                        <Form.Control
                          value={values.yearsOfBirth}
                          className={errors.yearsOfBirth ? "error-box" : ""}
                          onChange={(e) => {
                            setErrors({
                              ...errors,
                              yearsOfBirth: false,
                            });
                            setValues({
                              ...values,
                              yearsOfBirth: e.target.value,
                            });
                          }}
                          as="select"
                        >
                          <option value="0">Choose your birth year</option>
                          {years.map((year, index) => {
                            return (
                              <option key={index} value={year}>
                                {year}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </div>
                    </div>
                    <div className="col-md-6 one">
                      <div className="livein-field">
                        <Form.Label>My gender</Form.Label>
                        <Form.Control
                          value={values.genderId}
                          className={errors.genderId ? "error-box" : ""}
                          onChange={(e) => {
                            setErrors({
                              ...errors,
                              genderId: false,
                            });
                            setValues({
                              ...values,
                              genderId: e.target.value,
                            });
                          }}
                          as="select"
                        >
                          <option value="-1">Choose Gender</option>
                          <option value={genders.MALE}>Male</option>
                          <option value={genders.FEMALE}>Female</option>
                        </Form.Control>
                      </div>
                    </div>
                  </Row>
                </Form.Group>

                <div className="btnlogin-main">
                  <button
                    type="submit"
                    style={
                      values.yearsOfBirth && values.genderId
                        ? {}
                        : { ...disabledBtnProps }
                    }
                    className="btnlogin btnweak-bx"
                    onClick={step3FetchData}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="count-barr edu-count-step3">
            <div className="counting-barful">
              <div className="linebar-tex">3/9</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>

      {/* SYLLABUS DIALOG */}

      <Modal
        className="loginmodal student-login-main educator-signup-step8 "
        // {...props}
        show={modalEducatorstep3Show}
        onHide={backClick}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header closeButton>
            <div className="btnback">
              <Link to="#" onClick={() => backClick(4)} className="backbtn-icn">
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Sign up as a educator</h4>
              <p>Great!</p>
              <p>Now, please, tell us about your professional area</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <div className="educator-subject-list">
                  <ul className="subj-list-box">
                    {allSyllabus.map((value, index) => {
                      return (
                        <li key={index}>
                          <label className="subject-checkk">
                            {value?.name}
                            <input
                              type="checkbox"
                              checked={values.syllabus.includes(value?.id)}
                              onChange={() => selectSyllabus(value?.id)}
                              name={value?.name}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                      );
                    })}
                  </ul>
                  {errors.syllabus && (
                    <div className="error">Select at least 1 profession</div>
                  )}
                </div>

                <div className="btnlogin-main">
                  <button
                    type="submit"
                    className="btnlogin btnweak-bx"
                    onClick={step4FetchData}
                    style={
                      values.syllabus.length > 0 ? {} : { ...disabledBtnProps }
                    }
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="count-barr edu-count-step4">
            <div className="counting-barful">
              <div className="linebar-tex">4/9</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>

      {/* start Eductor signup step8 */}

      {/* start Eductor signup step9 */}

      <Modal
        className="loginmodal student-login-main educator-signup-step9 "
        // {...props}
        show={modalEducatorstep4Show}
        onHide={backClick}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header closeButton>
            <div className="btnback">
              <Link to="#" onClick={() => backClick(5)} className="backbtn-icn">
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Sign up as a educator</h4>
              <p>
                Please, share your audience and work experience as a tutor or a
                teacher
              </p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <div className="age-slidermax">
                  <Form.Group className="subsform-fild">
                    <Form.Label>I work with children of age</Form.Label>
                    <div className="ageslider-maxx">
                      <Box>
                        <Slider
                          value={value}
                          min={5}
                          step={1}
                          max={30}
                          getAriaValueText={valueLabelFormat}
                          valueLabelFormat={valueLabelFormat}
                          onChange={handleChange}
                          valueLabelDisplay="auto"
                          aria-labelledby="non-linear-slider"
                        />
                      </Box>
                    </div>
                  </Form.Group>

                  <Form.Group className="subsform-fild">
                    <Form.Label>
                      My work experience as an educator is
                    </Form.Label>
                    <Form.Control
                      value={values.workExperience}
                      onChange={(e) => {
                        setErrors({
                          ...errors,
                          workExperience: false,
                        });
                        setValues({
                          ...values,
                          workExperience: e.target.value,
                        });
                      }}
                      className={errors.workExperience ? "error-box" : ""}
                      as="select"
                    >
                      <option value="">Select work experience</option>
                      {AllWorkExperience.map((value, index) => {
                        return (
                          <option key={index} value={value?.id}>
                            {value?.name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>

                  <div className="btnlogin-main">
                    <button
                      style={
                        values.workExperience ? {} : { ...disabledBtnProps }
                      }
                      type="submit"
                      className="btnlogin btnweak-bx"
                      onClick={step5FetchData}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="count-barr edu-count-step5">
            <div className="counting-barful">
              <div className="linebar-tex">5/9</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>

      {/* start Eductor signup step9 */}

      {/* start Eductor signup step3 */}

      <Modal
        className="loginmodal student-login-main educator-signup-step3"
        // {...props}
        show={modalEducatorstep5Show}
        onHide={backClick}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header closeButton>
            <div className="btnback">
              <Link to="#" onClick={() => backClick(6)} className="backbtn-icn">
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Sign up as a educator</h4>
              <p>
                Put your email. Don’t worry, we will send just the most
                important information!
              </p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <Form.Label>My email is</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="example@email.com"
                    value={values.email}
                    className={errors.email ? "error-box" : ""}
                    onChange={async (e) => {
                      const val = e.target.value;
                      if (!(await validateEmail(val))) {
                        setErrors({ ...errors, email: true });
                      } else
                        setErrors({
                          ...errors,
                          email: false,
                        });
                      setValues({
                        ...values,
                        email: val,
                      });
                    }}
                  />
                </Form.Group>

                <div className="btnlogin-main">
                  <button
                    style={values.email ? {} : { ...disabledBtnProps }}
                    type="submit"
                    className="btnlogin btnweak-bx"
                    onClick={step6FetchData}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="count-barr edu-count-step6">
            <div className="counting-barful">
              <div className="linebar-tex">6/9</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>

      {/* end Eductor signup step3 */}
      <Modal
        className="loginmodal student-login-main student-signup-step6 educator-signup-step6 "
        // {...props}
        show={modalEducatorstep6Show}
        onHide={backClick}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <CustomSnackbar {...snackProps} />
        <div className="login-modal-mainbx">
          <Modal.Header closeButton>
            <div className="btnback">
              <Link to="#" onClick={() => backClick(7)} className="backbtn-icn">
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Sign up as a educator</h4>
              <p>
                Almost done! Just create the password to enter to your account
                with at least 6 signs
              </p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <Form.Label>Create a password</Form.Label>
                  <div className="passwd-fild">
                    <Form.Control
                      type={isPasswordVisible.create ? "text" : "password"}
                      placeholder="**********"
                      onChange={(e) => {
                        setErrors({
                          ...errors,
                          password: false,
                          confirmPassword: false,
                        });
                        setValues({
                          ...values,
                          password: e.target.value,
                        });
                      }}
                      className={errors.password ? "error-box" : ""}
                      value={values.password}
                    />
                    <span
                      onClick={() => changePasswordVisibility("create")}
                      className="icon-eye cursor-pointer"
                    >
                      <i
                        className="fas fa-eye"
                        style={{
                          color: isPasswordVisible.create
                            ? "var(--purple)"
                            : "black",
                        }}
                      ></i>
                    </span>
                  </div>
                </Form.Group>

                <Form.Group className="subsform-fild">
                  <Form.Label>Repeat a password</Form.Label>
                  <div className="passwd-fild">
                    <Form.Control
                      type={isPasswordVisible.repeat ? "text" : "password"}
                      value={values.confirmPassword}
                      onChange={(e) => {
                        setErrors({
                          ...errors,
                          password: false,
                          confirmPassword: false,
                        });
                        setValues({
                          ...values,
                          confirmPassword: e.target.value,
                        });
                      }}
                      placeholder="**********"
                      className={errors.confirmPassword ? "error-box" : ""}
                    />
                    <span
                      onClick={() => changePasswordVisibility("repeat")}
                      className="icon-eye"
                    >
                      <i
                        className="fas fa-eye"
                        style={{
                          color: isPasswordVisible.repeat
                            ? "var(--purple)"
                            : "black",
                        }}
                      ></i>
                    </span>
                  </div>
                </Form.Group>

                <div className="btnlogin-main">
                  <button
                    style={
                      values.password && values.confirmPassword
                        ? {}
                        : { ...disabledBtnProps }
                    }
                    type="submit"
                    className="btnlogin btnweak-bx"
                    onClick={step7FetchData}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="count-barr edu-count-step8">
            <div className="counting-barful">
              <div className="linebar-tex">7/9</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>

      {/* start Eductor signup step4 */}

      <Modal
        className="loginmodal student-login-main student-signup-step4 educator-signup-step4"
        // {...props}
        show={modalEducatorstep7Show}
        onHide={backClick}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <CustomSnackbar {...snackProps} />

        <div className="login-modal-mainbx">
          <Modal.Header closeButton>
            <div className="btnback">
              <Link to="#" onClick={() => backClick(8)} className="backbtn-icn">
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Sign up as a educator</h4>
              <p>
                You should confirm your mobile phone in order to save your
                account from being hacked
              </p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <Form.Label>My mobile phone is</Form.Label>

                  <PhoneInput
                    international
                    defaultCountry="IN"
                    placeholder="+375 22 123 45 67"
                    value={values.phoneNumber}
                    onChange={(e) => {
                      setErrors({
                        ...errors,
                        phoneNumber: false,
                      });
                      setValues({
                        ...values,
                        phoneNumber: e,
                      });
                    }}
                    className={errors.phoneNumber ? "error-box" : ""}
                  />
                </Form.Group>

                <div className="btnlogin-main">
                  <button
                    style={values.phoneNumber ? {} : { ...disabledBtnProps }}
                    type="submit"
                    className="btnlogin btnweak-bx"
                    onClick={() => {
                      if (values.phoneNumber.substring(3).length !== 10) {
                        snackOpen("Mobile number should be 10 digits", "error");
                        return;
                      }
                      step8FetchData();
                    }}
                  >
                    Send a verification code
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="count-barr edu-count-step7">
            <div className="counting-barful">
              <div className="linebar-tex">8/9</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>

      {/* start Eductor signup step4 */}

      {/* start Eductor signup step5 */}

      <Modal
        className="loginmodal student-login-main student-signup-step5 educator-signup-step5"
        // {...props}
        show={modalEducatorstep8Show}
        onHide={backClick}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header closeButton>
            <div className="btnback">
              <Link to="#" onClick={() => backClick(9)} className="backbtn-icn">
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Sign up as a educator</h4>
              <p>
                You should confirm your mobile phone in order to save your
                account from being hacked
              </p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <Form.Label>My mobile phone is</Form.Label>

                  <div className="phone-bar-form phone-with-country">
                    <PhoneInput
                      international
                      defaultCountry="IN"
                      placeholder="+375 22 123 45 67"
                      value={values.phoneNumber}
                      readOnly={true}
                    />
                    <div className="sendagain-btn">
                      <Link onClick={() => sendOtp(values.userId)} to="#">
                        Send again
                      </Link>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className="subsform-fild">
                  <Form.Label>Verification code</Form.Label>
                  <Form.Control
                    value={otp}
                    onChange={(e) => updateOtp(e)}
                    className={otpError ? "error-box" : ""}
                    type="text"
                    placeholder="Enter Otp here"
                  />
                </Form.Group>

                <div className="btnlogin-main">
                  <button
                    style={otp ? {} : { ...disabledBtnProps }}
                    type="submit"
                    className="btnlogin btnweak-bx"
                    onClick={async () => {
                      await verify();
                      step9FetchData();
                    }}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="count-barr edu-count-step7">
            <div className="counting-barful">
              <div className="linebar-tex">9/9</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>

      {/* start Eductor signup step5 */}

      {/* start Eductor signup step6 */}

      {/* start Eductor signup step6 */}

      {/* start Eductor signup step10 */}

      <Modal
        className="loginmodal student-login-main student-signup-step6 educator-signup-step10"
        // {...props}
        show={modalEducatorstep9Show}
        onHide={backClick}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header closeButton>
            <div className="btnback">
              <Link
                to="#"
                onClick={() => backClick(10)}
                className="backbtn-icn"
              >
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Sign up as a educator</h4>
              <p>Almost done! </p>
              <p>Please, read Tutor Policy and confirm you agree</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <div className="educt-tutor-pdf">
                  <div className="have-account">
                    <Link to="#" className="title-med">
                      Tutor Policy.pdf
                    </Link>
                  </div>
                </div>

                <div className="btnlogin-main">
                  <button
                    onClick={() => {
                      setEducatorstep9ModalShow(false);
                      // setModalShow(true);
                      setEducatorstep10ModalShow(true);
                    }}
                    className="btnlogin btnweak-bx"
                  >
                    I agree with Tutor Policy
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="count-barr edu-count-step9">
            <div className="counting-barful">
              <div className="linebar-tex">9/9</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>
      <ConfirmationDialog
        isOpen={modalEducatorstep10Show}
        closeDialog={() => {
          backClick();
        }}
        signInScreenHandler={() => {
          setEducatorstep10ModalShow(false);
          setModalShow(true);
        }}
      />

      {/* start Eductor signup step10 */}
    </div>
  );
};

export default SignUpEducator;
