import React, { useEffect, useRef, useState } from "react";
import "./home.scss";
import "./profile.scss";
import "./media.scss";
import "./games.scss";
import "./loginModal/login.scss";
import "./messages/messages.scss";
import "./myactivities.scss";
import "./gamestabs.scss";
import "./gamesflowphotos.scss";
import "./selectedgames.scss";
import "./questionone.scss";
import { Container, Row, Form } from "react-bootstrap";
import logo from "../assets/images/logo.png";
import editorangelg from "../assets/images/editlg-orange.svg";
import { Link, useHistory, useLocation } from "react-router-dom";
import FooterEducator from "./layout/Footer/FooterEducator";
import HeaderGames from "./layout/Header/HeaderGames";
import {
  creactDiscreteWuestion,
  creactMCEquestion,
  creactMUltquestion,
  creactNonDiscreteWuestion,
  creactTrueFalsQuestion,
  getSignalGames,
} from "../service/apis/games";
import { Box, CircularProgress, Grid, Stack } from "@mui/material";
import { StyledYellowButton } from "./utils/Buttons";
import CloseIcon from "@mui/icons-material/Close";
import { cloneDeep } from "lodash";
import { CustomSnackbar, useSnackState } from "../utils/useSnackState";

const questionTypes = [
  {
    id: 1,
    text: "Discrete",
  },
  {
    id: 2,
    text: "MCQ",
  },
  {
    id: 3,
    text: "NonDiscrete",
  },
  {
    id: 4,
    text: "Optional",
  },
  {
    id: 5,
    text: "TrueFalse",
  },
];

const answer_types = ["Image", "Text", "Image & Text"];

const questionInitialData = {
  question_id: "",
  question: "",
  typeOfQuestion: "",
  typeOfAnswer: answer_types[0],
  options: [{ image_url: "", text: "", option_number: 0 }],
  correctOptions: [
    {
      option_number: 1,
    },
  ],
  tryUntilCorrect: false,
  correctRes: "",
  wrongRes: "",
  points: "",
  responseText: "",
  answer: "",
  times: "",
  truefalse: "1",
};

function Questionone() {
  const location = useLocation();
  const [lodding, setLoading] = useState(false);
  const [lodding1, setLoading1] = useState(false);
  const history = useHistory();
  const optionImgRef = useRef();
  const [snackProps, open] = useSnackState();

  /* end save gamned step1 */

  const [queType, setQueType] = useState(1);
  const [correctOptionIndex, setCorrectOptionIndex] = useState(1);
  const [state, setstate] = React.useState({
    game: {},
    currentQuestionNo: 1,
    questionsData: [questionInitialData],
  });
  const [questionsData, setquestionsData] = useState({
    ...questionInitialData,
  });
  const [correctAnswersForOptional, setCorrectAnswersForOptional] = useState([
    1,
  ]);
  console.log("correctAnswersForOptional", correctAnswersForOptional);

  const saveQuestion = async () => {
    if (queType === 1) {
      const res = await creactDiscreteWuestion(state, questionsData, queType);
      return res;
    }
    if (queType === 2) {
      console.log("res of sending media", questionsData);

      const res = await creactMCEquestion(
        state,
        questionsData,
        queType,
        correctOptionIndex,
      );
      return res;
    }
    if (queType === 3) {
      const res = await creactNonDiscreteWuestion(
        state,
        questionsData,
        queType,
      );
      return res;
    }
    if (queType === 4) {
      const res = await creactMUltquestion(
        state,
        questionsData,
        queType,
        correctAnswersForOptional,
      );
      return res;
    }
    if (queType === 5) {
      const res = await creactTrueFalsQuestion(state, questionsData, queType);
      return res;
    }
    if (queType === 6) {
      const res = await creactMUltquestion(state, questionsData, queType);
      return res;
    }
  };

  const changeQuestionHandler = {
    next: async () => {
      //console.log("next ques clicked");

      if (
        !questionsData.question ||
        !questionsData.wrongRes ||
        !questionsData.points ||
        !questionsData.correctRes
      ) {
        open("Fill all the data", "error");
        return;
      }

      console.log("questionsData", questionsData);

      if (queType === 1 && questionsData.answer.length > 40) {
        open("answer cannot be longer than 40 characters!", "error");
        return;
      }
      if (queType === 2 && questionsData.options.length < 3) {
        open("please add atleast 2 options", "error");
        return;
      }
      if (queType === 3) {
        //  may add checking on answer if needed
      }
      if (queType === 4) {
        if (questionsData.options.length < 3) {
          open("please add atleast 2 options", "error");
          return;
        }
        if (correctAnswersForOptional.length < 2) {
          open("please select atleast two correct answers", "error");
          return;
        }
      }
      setLoading(true);
      const res = await saveQuestion();
      //console.log("next que res", res);
      console.log(res);
      open("Question add success", "success");
      if (res) {
        setquestionsData({ ...questionInitialData });
        setLoading(false);
      }

      setstate((prevState) => {
        const questionsData = cloneDeep(prevState.questionsData);
        questionsData[prevState.currentQuestionNo - 1].question_id = res
          ? res.data?.id
          : "";

        return {
          ...prevState,
          questionsData: [...questionsData, questionInitialData],
          currentQuestionNo: prevState.currentQuestionNo + 1,
        };
      });
    },

    previous: () =>
      setstate((prevState) => ({
        ...prevState,
        currentQuestionNo: prevState.currentQuestionNo - 1,
      })),
  };

  const addOptionHandler = (image) => {
    switch (questionsData.typeOfAnswer.toLowerCase()) {
      case "text":
        setquestionsData((prevState) => {
          const newData = {
            image_url: "",
            text: "",
          };

          return { ...prevState, options: [...prevState.options, newData] };
        });
        break;

      case "image":
        if (!image) {
          optionImgRef.current.click();
          break;
        }
        setquestionsData((prevState) => {
          const newData = {
            image_url: URL.createObjectURL(image),
            image,
            text: "",
          };

          return { ...prevState, options: [...prevState.options, newData] };
        });
        break;
      case "image & text":
        if (!image) {
          optionImgRef.current.click();
          break;
        }
        setquestionsData((prevState) => {
          const newData = {
            image_url: URL.createObjectURL(image),
            image,
            text: "",
          };

          return { ...prevState, options: [...prevState.options, newData] };
        });
        break;
      default:
    }
  };

  const selectCorrectOptionHandler = (idx) => {
    setCorrectOptionIndex(idx);
    setstate((prevState) => {
      const questionsData = cloneDeep(prevState.questionsData);
      const crtOptions =
        questionsData[prevState.currentQuestionNo - 1].correctOptions;

      const foundIdx = crtOptions.findIndex((op) => {
        return op.option_number === idx;
      });
      //console.log("is option selected", foundIdx);
      if (foundIdx === -1) {
        crtOptions.push({
          option_number: idx,
        });
      } else {
        crtOptions.splice(foundIdx, 1);
      }
      //console.log("correct options", crtOptions);
      console.log("correct options", crtOptions);
      questionsData[prevState.currentQuestionNo - 1].correctOptions =
        crtOptions;
      console.log("is option selected", questionsData);

      return { ...prevState, questionsData };
    });
  };

  const optionTextHandler = (optionNumber, value) => {
    setquestionsData((prevState) => {
      const data = (prevState.options[optionNumber].text = value);

      return { ...prevState, data };
    });
  };

  const gameData = async (id, type) => {
    const res = await getSignalGames(id, type);
    setstate((prevState) => ({ ...prevState, game: res }));
  };

  const remoiveItem = (index) => {
    setquestionsData((pre) => {
      const data = pre.options.filter((data, i) => i !== index);
      console.log(data);
      return { ...pre, options: data };
    });
  };

  console.log(questionsData.options);

  const addQueType = (id) => {
    setQueType(id);
    setquestionsData({ ...questionInitialData });
  };

  useEffect(() => {
    if (!location.state) {
      history.push("/GamesTabs");
    }
    gameData(location.state[0], location.state[1]);
  }, [location.state]);

  return (
    <div className="home-wrapper">
      <CustomSnackbar {...snackProps} />
      {/* start header */}
      <HeaderGames />
      {/* end header */}

      <div className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs">
        <div className="games-photosynths">
          <Container>
            <div className="games-photosth-main gamesflows-twobx gamesflows-nwbox ">
              <div className="games-photsth-header">
                <h1>{state.game?.title}</h1>

                <Link to="#" className="editbtn-lg">
                  <img src={editorangelg} alt="" />
                </Link>
              </div>
              <div className="questionone-main">
                <div className="iner-questionbx">
                  <div className="question-heading">
                    <h3>Question No - {state.currentQuestionNo}</h3>
                  </div>
                  <div className="questionform">
                    <Form className="form-questionbx">
                      <div className="questionbx-frm">
                        <Form.Label className="labelbx-quest">
                          Choose type of a question
                        </Form.Label>
                        <div className="choose-learning-list">
                          <ul className="learner-listing-games">
                            {questionTypes.map((type) => {
                              return (
                                <li key={type.id}>
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      addQueType(type.id);
                                    }}
                                    className={
                                      "btn-learner-lsit" +
                                      (type.id === queType ? " active" : "")
                                    }
                                  >
                                    {type.text}
                                  </button>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>

                      {/* Question Type 1 , 3 ,4,5 */}
                      {queType === 1 || queType === 3 || queType === 5 ? (
                        <>
                          <div className="questionbx-frm">
                            <Form.Label className="labelbx-quest">
                              Text of the question
                            </Form.Label>
                            <Form.Control
                              onChange={(e) => {
                                setquestionsData((pre) => {
                                  return {
                                    ...pre,
                                    question: e.target.value,
                                  };
                                });
                              }}
                              value={questionsData.question}
                              as="textarea"
                              placeholder="Write the text of question, use clear simple words in order to make the questions understandable and correct"
                            />
                          </div>

                          <div className="questionbx-frm">
                            <Form.Label className="labelbx-quest">
                              Text of the {queType === 3 && "model"} answer
                            </Form.Label>

                            {queType === 5 && (
                              <Form.Control
                                as="select"
                                onChange={(e) => {
                                  setquestionsData((pre) => {
                                    return {
                                      ...pre,
                                      truefalse: e.target.value,
                                    };
                                  });
                                }}
                                value={questionsData.truefalse}
                              >
                                <option value="1">True</option>
                                <option value="0">false</option>
                              </Form.Control>
                            )}

                            {(queType === 1 || queType === 3) && (
                              <Form.Control
                                onChange={(e) => {
                                  setquestionsData((pre) => {
                                    return {
                                      ...pre,
                                      answer: e.target.value,
                                    };
                                  });
                                }}
                                value={questionsData.answer}
                                as="textarea"
                                placeholder="Write the text of question answer, use clear simple words in order to make the questions answer understandable and correct"
                              />
                            )}
                          </div>

                          {queType !== 1 ||
                            (queType !== 3 && (
                              <div className="questionbx-frm checkbx-try">
                                <label className="custm-check gameflow-check">
                                  <Form.Control
                                    type="checkbox"
                                    onChange={(e) => {
                                      setquestionsData((pre) => {
                                        return {
                                          ...pre,
                                          tryUntilCorrect: e.target.checked,
                                        };
                                      });
                                    }}
                                    value={questionsData.tryUntilCorrect}
                                  />
                                  <span className="checkmark"></span>
                                  <div className="inner-bodyquiz">
                                    Try until correct answer
                                  </div>
                                </label>
                              </div>
                            ))}

                          <Row className="btmfeld">
                            <div className="col-md-6 one">
                              <div className="questionbx-frm">
                                <Form.Label className="labelbx-quest">
                                  Text if answer is correct
                                </Form.Label>
                                <Form.Control
                                  onChange={(e) => {
                                    setquestionsData((pre) => {
                                      return {
                                        ...pre,
                                        correctRes: e.target.value,
                                      };
                                    });
                                  }}
                                  value={questionsData.correctRes}
                                  type="text"
                                  placeholder="Ex. Great! You are awesome!"
                                />
                              </div>
                            </div>
                            <div className="col-md-6 one">
                              <div className="questionbx-frm">
                                <Form.Label className="labelbx-quest">
                                  Text if answer is not correct
                                </Form.Label>
                                <Form.Control
                                  onChange={(e) => {
                                    setquestionsData((pre) => {
                                      return {
                                        ...pre,
                                        wrongRes: e.target.value,
                                      };
                                    });
                                  }}
                                  value={questionsData.wrongRes}
                                  type="text"
                                  placeholder="Ex. No, try again"
                                />
                              </div>
                            </div>
                          </Row>

                          <Row>
                            <div className="col-md-6 one">
                              <div className="questionbx-frm">
                                <Form.Label className="labelbx-quest">
                                  {queType === 3
                                    ? "Number of maximum points for the question"
                                    : "Number of points for the question"}
                                </Form.Label>
                                <Form.Control
                                  onChange={(e) => {
                                    setquestionsData((pre) => {
                                      return {
                                        ...pre,
                                        points: e.target.value,
                                      };
                                    });
                                  }}
                                  value={questionsData.points}
                                  type="text"
                                  placeholder="20"
                                />
                              </div>
                            </div>
                          </Row>
                        </>
                      ) : (
                        <></>
                      )}

                      {/* Question Type 2, 6 */}
                      {queType === 2 || queType === 4 ? (
                        <>
                          <div className="questionbx-frm">
                            <Form.Label className="labelbx-quest">
                              Choose type of an answer
                            </Form.Label>
                            <div className="choose-learning-list">
                              <ul className="learner-listing-games">
                                {answer_types.map((type) => {
                                  return (
                                    <li key={type}>
                                      <button
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setquestionsData((pre) => {
                                            return {
                                              ...pre,
                                              typeOfAnswer: type,
                                            };
                                          });
                                        }}
                                        className={
                                          "btn-learner-lsit" +
                                          (type === questionsData.typeOfAnswer
                                            ? " active"
                                            : "")
                                        }
                                      >
                                        {type}
                                      </button>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </div>

                          <div className="questionbx-frm">
                            <Form.Label className="labelbx-quest">
                              Text of the question
                            </Form.Label>
                            <Form.Control
                              onChange={(e) => {
                                setquestionsData((pre) => {
                                  return {
                                    ...pre,
                                    question: e.target.value,
                                  };
                                });
                              }}
                              value={questionsData.question}
                              as="textarea"
                              placeholder="Write the text of question, use clear simple words in order to make the questions understandable and correct"
                            />
                          </div>

                          <div className="questionbx-frm option-sml">
                            <Form.Label className="labelbx-quest">
                              Options
                            </Form.Label>
                            <Stack
                              flexDirection={"row"}
                              justifyContent="space-between"
                            >
                              <div className="smalltex-frm">
                                Double click to choose correct answer{" "}
                              </div>

                              {questionsData.options.length <= 4 ? (
                                <Box sx={{ width: "7rem" }}>
                                  <StyledYellowButton
                                    title={"Add Option"}
                                    btnHandler={() => {
                                      addOptionHandler();
                                    }}
                                  />
                                  <input
                                    type={"file"}
                                    ref={optionImgRef}
                                    onChange={(e) => {
                                      addOptionHandler(e.target.files[0]);
                                    }}
                                    style={{ display: "none" }}
                                  ></input>
                                </Box>
                              ) : null}
                            </Stack>
                            <Grid
                              rowSpacing={"1rem"}
                              columnSpacing={"1rem"}
                              container
                              sx={{ my: "1rem" }}
                            >
                              {questionsData.options.map((option, idx) => {
                                if (idx === 0) return null;
                                return (
                                  <Grid
                                    onClick={(e) => {
                                      switch (e.detail) {
                                        case 2:
                                          if (queType === 4) {
                                            if (
                                              correctAnswersForOptional.includes(
                                                idx,
                                              )
                                            ) {
                                              setCorrectAnswersForOptional(
                                                (pre) => {
                                                  return pre.filter(
                                                    (item) => item !== idx,
                                                  );
                                                },
                                              );
                                            } else {
                                              setCorrectAnswersForOptional(
                                                (pre) => {
                                                  return [...pre, idx];
                                                },
                                              );
                                            }
                                          } else {
                                            selectCorrectOptionHandler(idx);
                                          }
                                          break;
                                        default:
                                          return;
                                      }
                                    }}
                                    key={idx}
                                    item
                                    xs={12}
                                    sm={12}
                                    md={3}
                                  >
                                    <Box
                                      sx={{
                                        backgroundColor: "#E9EEF1",
                                        height: "15rem",
                                        width: "100%",
                                        borderRadius: "10px",
                                        border:
                                          queType !== 4 &&
                                          idx === correctOptionIndex
                                            ? "4px solid var(--orange)"
                                            : queType === 4 &&
                                              correctAnswersForOptional.includes(
                                                idx,
                                              )
                                            ? "4px solid var(--orange)"
                                            : "4px solid transparent",
                                        p: "0.5rem",
                                        position: "relative",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          position: "absolute",
                                          top: "6px",
                                          right: "9px",
                                          fontSize: "30px",
                                        }}
                                      >
                                        <CloseIcon
                                          sx={{
                                            cursor: "pointer",
                                            transition: "0.3s",
                                            "&:hover": {
                                              color: "red",
                                            },
                                          }}
                                          onClick={() => remoiveItem(idx)}
                                        />
                                      </Box>

                                      {questionsData.typeOfAnswer.toLowerCase() !==
                                        "text" && (
                                        <img
                                          style={{
                                            height: "70%",
                                            objectFit: "contain",
                                            marginBottom: "0.5rem",
                                          }}
                                          src={option.image_url}
                                          alt="option"
                                        />
                                      )}
                                      {questionsData.typeOfAnswer.toLowerCase() !==
                                        "image" && (
                                        <div className="questionbx-frm">
                                          <Form.Control
                                            value={option.text}
                                            onChange={(e) =>
                                              optionTextHandler(
                                                idx,
                                                e.target.value,
                                              )
                                            }
                                            type="text"
                                            placeholder="Text"
                                          />
                                        </div>
                                      )}
                                    </Box>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </div>

                          <div className="questionbx-frm checkbx-try">
                            <label className="custm-check gameflow-check">
                              <Form.Control
                                type="checkbox"
                                onChange={(e) => {
                                  setquestionsData((pre) => {
                                    return {
                                      ...pre,
                                      tryUntilCorrect: e.target.checked,
                                    };
                                  });
                                }}
                                value={questionsData.tryUntilCorrect}
                              />
                              <span className="checkmark"></span>
                              <div className="inner-bodyquiz">
                                Try until correct answer
                              </div>
                            </label>
                          </div>

                          <Row className="btmfeld">
                            <div className="col-md-6 one">
                              <div className="questionbx-frm">
                                <Form.Label className="labelbx-quest">
                                  Text of the question
                                </Form.Label>
                                <Form.Control
                                  onChange={(e) => {
                                    setquestionsData((pre) => {
                                      return {
                                        ...pre,
                                        correctRes: e.target.value,
                                      };
                                    });
                                  }}
                                  value={questionsData.correctRes}
                                  type="text"
                                  placeholder="Ex. Great! You are awesome!"
                                />
                              </div>
                            </div>
                            <div className="col-md-6 one">
                              <div className="questionbx-frm">
                                <Form.Label className="labelbx-quest">
                                  Text if answer is not correct
                                </Form.Label>
                                <Form.Control
                                  onChange={(e) => {
                                    setquestionsData((pre) => {
                                      return {
                                        ...pre,
                                        wrongRes: e.target.value,
                                      };
                                    });
                                  }}
                                  value={questionsData.wrongRes}
                                  type="text"
                                  placeholder="Ex. No, try again"
                                />
                              </div>
                            </div>
                          </Row>

                          <Row>
                            <div className="col-md-6 one">
                              <div className="questionbx-frm">
                                <Form.Label className="labelbx-quest">
                                  Number of points for the question
                                </Form.Label>
                                <Form.Control
                                  onChange={(e) => {
                                    setquestionsData((pre) => {
                                      return {
                                        ...pre,
                                        points: e.target.value,
                                      };
                                    });
                                  }}
                                  value={questionsData.points}
                                  type="text"
                                  placeholder="20"
                                />
                              </div>
                            </div>
                          </Row>
                        </>
                      ) : (
                        <></>
                      )}

                      <div className="question-btom-btn">
                        <ul>
                          <li>
                            <div className="btn-buy gamestart-btnn">
                              <div className="btn-week">
                                <div className="btnweak-bx">
                                  <span
                                    className="btn-title"
                                    onClick={async () => {
                                      if (
                                        !questionsData.question ||
                                        !questionsData.wrongRes ||
                                        !questionsData.points ||
                                        !questionsData.correctRes
                                      ) {
                                        open("Fill all the data", "error");
                                        return;
                                      }
                                      setLoading1(true);
                                      const res = await saveQuestion();
                                      if (res) {
                                        // setgamesavedstep1ModalShow(true);
                                        setLoading1(false);
                                        history.push(
                                          "/GamesTabs?tab=myMinigames",
                                        );
                                      }
                                    }}
                                  >
                                    {lodding1 ? (
                                      <CircularProgress
                                        size={"1.5rem"}
                                        sx={{ color: "var(--purple)" }}
                                      />
                                    ) : (
                                      "Save the game"
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li onClick={changeQuestionHandler.next}>
                            <div className="btn-buy gamestart-btnn">
                              <div className="btn-week">
                                <div className="btnweak-bx">
                                  <span className="btn-title">
                                    {lodding ? (
                                      <CircularProgress
                                        size={"1.5rem"}
                                        sx={{ color: "var(--purple)" }}
                                      />
                                    ) : (
                                      "Next question"
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <FooterEducator logo={logo} />
      </div>
    </div>
  );
}

export default Questionone;
