import React from "react";
import "./home.scss";
import "./profile.scss";
import "./media.scss";
import "./games.scss";
import "./loginModal/login.scss";
import "./profile.scss";
import "./messages/messages.scss";
import "./myactivities.scss";
import "./gamestabs.scss";
import "./gamesflowphotos.scss";
import "./selectedgames.scss";
import "./Myprofile.scss";

import { Container, Row, Button, Form, Modal } from "react-bootstrap";

import logo from "../assets/images/logo.png";
import backorange from "../assets/images/backorange.svg";

import { Link } from "react-router-dom";
import FooterEducator from "./layout/Footer/FooterEducator";
import HeaderGames from "./layout/Header/HeaderGames";
import Becometutornew from "./tutor/Becometutornew";

function Howearn() {
  // const [show, setShow] = React.useState("1");

  // const [breadMenuShow, setBreadMenuShow] = React.useState(false);
  /* start modal */
  const [modalShow, setModalShow] = React.useState(false);
  /* end modal */

  /* start becometutor */
  const [modalEducatorstep1Show, setEducatorstep1ModalShow] =
    React.useState(false);
  /* end becometutor */

  /* startsave gamed step1 */
  const [modalgamesavedstep1Show, setgamesavedstep1ModalShow] =
    React.useState(false);

  /* end save gamned step1 */

  /* start Tutor signup step6 */
  // const [modalTutorstep7Show, setTutorstep7ModalShow] = React.useState(false);
  /* end Tutor signup step6 */
  /* start Tutor signup step2 */
  // const [modalTutorstep2Show, setTutorstep2ModalShow] = React.useState(false);
  /* end Tutor signup step2 */

  /* start send Invite step2 */
  const [modalinvitestep2Show, setinvitestep2ModalShow] = React.useState(false);
  /* end send Invite  step2 */

  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderGames setModalShow={(value) => setModalShow(value)} />
      {/* end header */}

      <div className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs">
        <div className="myprofile-main howearn-mainbxx">
          <div className="howearn-main">
            <div className="current-lesson-main">
              <Container>
                <div className="current-lesson-contain">
                  <div className="backbtn">
                    <Link to="Myprofile" className="backbtnn-icnn">
                      <img src={backorange} />
                    </Link>
                  </div>
                  <div className="current-lesson-topbr">
                    <h1>How to earn money with Neithedu</h1>
                  </div>
                  <div className="earn-section">
                    <div className="earn-contentbx">
                      <div className="earn-innerbox">
                        <h3>Have live lessons with !</h3>
                        <Row>
                          <div className="col-md-7 col-lg-9 one">
                            <div className="earn-left">
                              <p>
                                You have apportunity to became a tutor and have
                                live lessons with students extra. If you want to
                                start, you need to push the button Become a
                                tutor and full the form. We will review your
                                application and after confirmation you will be
                                able to have live lessons. You can read about
                                live lessons{" "}
                                <span className="tex-underline">here</span>
                              </p>
                            </div>
                          </div>
                          <div className="col-md-5 col-lg-3 two">
                            <div className="earn-right">
                              <div className="earnbtn-right">
                                <div className="custm-wdth">
                                  <div className="btn-week lgbtn">
                                    <Link
                                      to="#"
                                      className="btnweak-bx"
                                      onClick={() => {
                                        setEducatorstep1ModalShow(true);
                                      }}
                                    >
                                      <span className="btn-title">
                                        Become a tutor
                                      </span>
                                    </Link>
                                  </div>
                                </div>
                                {/* start become button */}
                                <Becometutornew
                                  modalEducatorstep1Show={
                                    modalEducatorstep1Show
                                  }
                                  setEducatorstep1ModalShow={(value) =>
                                    setEducatorstep1ModalShow(value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </Row>
                      </div>

                      <div className="earn-innerbox">
                        <h3>Publish your own materials, games and gameflows</h3>
                        <Row>
                          <div className="col-md-7 col-lg-9 one">
                            <div className="earn-left">
                              <p>
                                Publish activities that you created by yourself.
                                You can publish materials, games and gameflows.
                                For publication you need just go to links in
                                right, click to three points on the card of the
                                activity and choose Apply to publish. Confirm
                                your application - and that’s done!
                              </p>
                              <p>
                                After application to publish the material will
                                be reviewed by our experts. If the material is
                                relevant and interesting, it will be posted on
                                the site for general use and you will receive
                                $20.
                              </p>
                              <p>
                                <strong>
                                  We review just unique materials, that were
                                  created by yourself!
                                </strong>
                              </p>
                            </div>
                          </div>
                          <div className="col-md-5 col-lg-3 two">
                            <div className="earn-right">
                              <div className="earnbtn-right matrial-earnwdh">
                                <Row className="custm-wdth">
                                  <div className="col-md-6 one">
                                    <div className="btn-week smbtn">
                                      <Link
                                        to="Mymaterials"
                                        className="btnweak-bx"
                                      >
                                        <span className="btn-title">
                                          Materials
                                        </span>
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="col-md-6 two">
                                    <div className="btn-week smbtn">
                                      <Link
                                        to="GamesTabs"
                                        className="btnweak-bx"
                                      >
                                        <span className="btn-title">Games</span>
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="col-md-12 three">
                                    <div className="btn-week lgbtn">
                                      <Link
                                        to="GamesTabs"
                                        className="btnweak-bx"
                                      >
                                        <span className="btn-title">
                                          Gameflows
                                        </span>
                                      </Link>
                                    </div>
                                  </div>
                                </Row>
                              </div>
                            </div>
                          </div>
                        </Row>
                      </div>

                      <div className="earn-innerbox">
                        <h3>Invite your students to Neithedu</h3>
                        <Row>
                          <div className="col-md-12 col-lg-5 one">
                            <div className="earn-left">
                              <p>
                                For every student that subscribes you will
                                receive $5. Enter email of student and we will
                                send the invitation.
                              </p>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-7 two">
                            <div className="earn-right">
                              <div className="earnbtn-right">
                                <Row className="invitefrm">
                                  <div className="col-md-8 col-lg-8 col-xl-9 one">
                                    <div className="frm-invite">
                                      <Form.Group className="subsform-fild">
                                        <Form.Control
                                          type="email"
                                          placeholder="example@email.com"
                                        />
                                      </Form.Group>
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-lg-4 col-xl-3 two">
                                    <div className="btn-week">
                                      <Link
                                        to="#"
                                        className="btnweak-bx"
                                        onClick={() =>
                                          setinvitestep2ModalShow(true)
                                        }
                                      >
                                        <span className="btn-title">
                                          Invite
                                        </span>
                                      </Link>
                                    </div>
                                  </div>
                                </Row>
                              </div>
                            </div>
                          </div>
                        </Row>
                      </div>

                      <div className="earn-innerbox">
                        <h3>Community pot </h3>
                        <Row>
                          <div className="col-md-7 col-lg-8 col-xl-9 one">
                            <div className="earn-left">
                              <h6 className="smltex-uppr">Only for tutors</h6>
                              <p>
                                5% of all Neithedu subscriptions goes to pot.
                                Members of pot receives equal part every
                                quarter.
                              </p>
                              <p>
                                <strong>
                                  In order to become a member of community pot,
                                  you should have:
                                </strong>
                              </p>
                              <ul className="month-serv-list">
                                <li className="month-inerlist">
                                  <span className="num-highlight">6</span>{" "}
                                  months tutoring service,
                                </li>
                                <li className="month-inerlist">
                                  <span className="num-highlight">100</span>{" "}
                                  tutoring hours
                                </li>
                                <li className="month-inerlist">
                                  <span className="num-highlight">90%</span>{" "}
                                  satisfaction from users
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-5 col-lg-4 col-xl-3 two">
                            <div className="earn-right">
                              <div className="earnbtn-right">
                                <div className="indicator-card">
                                  <div className="medm-subtiel">
                                    Your indicators now
                                  </div>
                                  <ul className="month-serv-list">
                                    <li className="month-inerlist">
                                      <span className="num-highlight">6</span>{" "}
                                      months tutoring service,
                                    </li>
                                    <li className="month-inerlist">
                                      <span className="num-highlight">100</span>{" "}
                                      tutoring hours
                                    </li>
                                    <li className="month-inerlist">
                                      <span className="num-highlight">90%</span>{" "}
                                      satisfaction from users
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
          </div>
        </div>

        {/* footer start */}
        <FooterEducator logo={logo} />
        {/* footer end */}
      </div>

      {/* start modal my student */}

      {/* start game save step1 */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        // {...props}
        show={modalgamesavedstep1Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2 mystudent-games">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>The game is saved</h4>
              <h5>Send the game to my students</h5>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setgamesavedstep1ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="serch-inner-group">
                    <div className="serchfiled-activities">
                      <Form.Control
                        type="text"
                        placeholder="Start typing name or year of study"
                        className="formbox"
                      />
                      <Button type="button" className="seachbtn">
                        <i className="fas fa-search"></i>
                      </Button>
                    </div>
                  </div>

                  <div className="newpupil-list-bx">
                    <Form.Group className="subsform-fild child-tex-fld height-max-flow">
                      <div className="child-boxnew">
                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Aaradhya Rayasam</h5>
                                  <h6>India, Mumbai 1 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Chandrashekhar Kalvakuntla</h5>
                                  <h6>India, Mumbai 3 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>7F Class</h5>
                                  <h6>Hamut Kapit, Kemal Mara and 12 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Additional group</h5>
                                  <h6>Laska Doe, Hamut Kapit and 4 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>8A Class for exams</h5>
                                  <h6>Hamut Kapit, Laska Doe and 8 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Chandrashekhar Kalvakuntla</h5>
                                  <h6>India, Mumbai 3 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </Form.Group>

                <div className="student-btn-savedgame">
                  <div className="btnlogin-main">
                    <button type="submit" className="btnlogin btnweak-bx">
                      Send to selected students
                    </button>
                  </div>
                </div>
                <div className="btnlogin-main">
                  <button type="submit" className="btnlogin btnweak-bx">
                    Later
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end Add a Member Group step2 */}

      {/* end modal my student */}

      {/* start send invite step2 */}
      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new send-invite-modal2"
        // {...props}
        show={modalinvitestep2Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header>
            <div className="btnback">
              <Link to="#" className="backbtn-icn">
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Invitation was sent</h4>
              <p>You will see your bonus when the student buys subscription</p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setinvitestep2ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
        </div>
      </Modal>

      {/* end Send invite step1 */}
    </div>
  );
}

export default Howearn;
