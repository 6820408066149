import React, { useEffect, useState, useCallback } from "react";
import { Navbar, Nav, Dropdown } from "react-bootstrap";
import "../home.scss";
import "../profile.scss";
import "../loginModal/login.scss";
import "../dashboard.scss";
import "../sidemenu.scss";
import "../media.scss";
import CheckIcon from "@mui/icons-material/Check";
import languageImg from "../../assets/images/languages.svg";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
// import logo from "../../assets/images/logo.png";
import bell from "../../assets/images/bell.svg";
import gift from "../../assets/images/gift.svg";
import plus from "../../assets/images/plus.svg";
import breadmenu from "../../assets/images/breadmenu.svg";
import count1 from "../../assets/images/count1.svg";
import count2 from "../../assets/images/count2.svg";
import { Link, useHistory, useLocation } from "react-router-dom";
import LoginModal from "../loginModal/Login";
import Signup from "../signup";
import crossicon from "../../assets/images/crossicon.svg";
import { CircularProgress } from "@mui/material";

// import {
//   roleBasedRedirect,
//   settingsRedirect,
// } from "../../service/commanFunction";
import { useAppState, useActions } from "../../overmind";
import Questionnaire from "../Questionnaire";
// import { DialogBox } from "../DialogBox";
import { Notifications } from "../dialogs/Notifications";
import { GiftsStepper } from "../dialogs/GiftsStepper";
import { HaveTutors } from "../dialogs/HaveTutors";
import { ForgotPassword } from "../dialogs/passwordRecovery/ForgotPassword";
import {
  getLanguageList,
  getMilestones,
  getPoints,
  getProfileDetails,
} from "../../service/apis/common";
import { PersonalRewardsGiftsStepper } from "../dialogs/PersonalRewardGiftStepper";
import {
  filterArray,
  // filterArray2,
  filterCityId,
  filterCountryId,
} from "../../utils/filterArray";
import { useMemo } from "react";
import { StudentAPI } from "../../service/apis/student";
import { fetchCities, fetchData } from "../../service/AllApi";
import { getStudentTask } from "../../service/apis/tasks";

const sideMenuButtons = [
  {
    menuTitle: "Home",
    path: "/dashboard",
  },
  // {
  //   menuTitle: "My tutors",
  //   path: "/tutorList",
  // },
  {
    menuTitle: "Games",
    path: "/games",
  },
  {
    menuTitle: "My activities",
    path: "/MyActivities",
  },
  // {
  //   menuTitle: "My connections",
  //   path: "/student/myConnections",
  // },
  // {
  //   menuTitle: "Messages",
  //   path: "#",
  // },
  {
    menuTitle: "Neithedu",
    path: "/",
  },
  {
    menuTitle: "Blog",
    path: "/blog",
  },
  // {
  //   menuTitle: "Contact",
  //   path: "#",
  // },
];

const sliderData = [
  {
    title: "1k",
    points: 1000,
    details: {
      text: "New topic of games",
      image: "",
    },
  },
  {
    title: "2k",
    points: 2000,
    details: {
      text: "New topic of games",
      image: "",
    },
  },
  {
    title: "3k",
    points: 3000,
    details: {
      text: "New topic of games",
      image: "",
    },
  },
  {
    title: "4k",
    points: 4000,
    details: {
      text: "New topic of games",
      image: "",
    },
  },
  {
    title: "5k",
    points: 5000,
    details: {
      text: "New topic of games",
      image: "",
    },
  },
  {
    title: "6k",
    points: 6000,
    details: {
      text: "New topic of games",
      image: "",
    },
  },
  {
    title: "7k",
    points: 7000,
    details: {
      text: "New topic of games",
      image: "",
    },
  },
];

const HeaderDashboardElement = ({ setLanguageUpdated, languageUpdated }) => {
  const [isLoading, setisLoading] = useState(false);
  // const [visible, setVisible] = useState(false);

  const state = useAppState();
  const actions = useActions();
  const [breadMenuShow, setBreadMenuShow] = useState(false);
  const [rewardPoints, setRewardPoints] = useState("");
  const [communityArray, setCommunityArray] = useState([]);
  const [personalArray, setPersonalArray] = useState([]);
  const [redirectTo, setRedirectTo] = useState(null);

  const history = useHistory();
  const location = useLocation();
  const [link, setLink] = useState(location?.pathname);
  const [modalShow, setModalShow] = useState(false);
  const [modalSignupShow, setModalSignupShow] = useState(false);
  const [openNotifications, setopenNotifications] = useState(false);
  const [openGifts, setopenGifts] = useState(false);
  const [personalOpenGifts, setPersonalOpenGifts] = useState(false);
  const [openTutors, setopenTutors] = useState(false);
  const [openForgotPassword, setopenForgotPassword] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [showSideMenu, setShowSideMenu] = useState(false);
  const [languages, setLanguage] = useState(null);
  const [languagename, setLanguagename] = useState(null);
  // const [languageId, setLanguageId] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchquery, setSearchQuery] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [langUpdating, setLangUpdating] = useState(false);
  // const [profileFetched, setProfileFetched] = useState(false);
  const [countryId, setCountryId] = useState(null);
  const [cityId, setCityId] = useState(null);
  // const currentUrl = location.pathname;
  const currentData = JSON.parse(localStorage.getItem("user"));

  const [profileState, setprofileState] = useState({
    lastName: "",
    id: 0,
    email: "",
    phoneNumber: "",
    grade: 0,
    city: 0,
    country: 0,
    languageOfAccountId: 0,
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  function handleSearchInputChange(event) {
    setSearchQuery(event.target.value);
    setSelectedLanguage(null);
  }
  useEffect(() => {
    if (currentData?.applicationUser.languageOfAccountId == 0) {
      currentData.applicationUser.languageOfAccountId = 13;
      localStorage.setItem("user", JSON.stringify(currentData));
    }
  }, []);
  const fetchProfileDetails = async () => {
    try {
      const res = await getProfileDetails();
      setprofileState({
        id: res.id,
        firstName: res.firstName,
        lastName: res.lastName,
        email: res.email,
        phoneNumber: res.phoneNumber,
        grade: res.grade,
        city: res.city,
        country: res.country,
        languageOfAccountId: res.languageOfAccountId
          ? res.languageOfAccountId
          : 13,
      });

      currentData.applicationUser.languageOfAccountId = res.languageOfAccountId;
      localStorage.setItem("user", JSON.stringify(currentData));
    } catch (err) {}
  };

  useEffect(() => {
    if (profileState.country != 0) {
      fetchCountries();
    }
  }, [profileState.country]);
  useEffect(() => {
    if (countryId != null) {
      fetchCitiesOnLoad();
    }
  }, [countryId]);
  const fetchCountries = async () => {
    try {
      const response = await fetchData("countries");
      if (response && response.data) {
        // setCountries(response.data);

        const filteredCountry = filterCountryId(
          response.data,
          profileState.country,
        );
        if (countryId === null) {
          filteredCountry.map((f) => {
            setCountryId(f.id);
          });
        }
      }
    } catch (error) {}
  };

  const fetchCitiesOnLoad = async () => {
    const res = await fetchCities("cities", countryId);
    // setCities(res.data);
    const cityIdArray = filterCityId(res.data, profileState.city);
    if (cityId === null) {
      cityIdArray.map((c) => {
        setCityId(c.id);
      });
    }
  };
  let content;
  useEffect(() => {
    if (state?.roleId === "Student") {
      fetchProfileDetails();
    }
  }, [
    profileState.languageOfAccountId,
    currentData?.applicationUser?.languageOfAccountId,
  ]);
  function handleLanguageClick(language) {
    // let code = "";
    // if (language.language === "French") {
    //   code = "fr";
    // } else if (language.language === "Arabic") {
    //   code = "ar";
    // } else if (language.language === "Assamese") {
    //   code = "as";
    // } else if (language.language === "Hindi") {
    //   code = "hi";
    // }
    // history.push(`${currentUrl}#googtrans(en|${code})}`);
    setSelectedLanguage(language);

    const updateProfile = async () => {
      setLangUpdating(true);

      const res = await StudentAPI.updateStudentProfile(
        profileState?.firstName,
        profileState?.lastName,
        parseInt(countryId),
        parseInt(cityId),
        1,
        parseInt(language.id) || parseInt(profileState.languageOfAccountId),
        parseInt(profileState.id),
        parseInt(profileState?.grade),
        parseInt(profileState?.grade),
      );
      // console.log(res, "up");
      // if()
      // setLanguageUpdated(true);

      setLangUpdating(false);
      setShowSideMenu(false);
      setSelectedLanguage(null);
      setSearchQuery("");
      fetchProfileDetails();
      history.go(0);
    };
    updateProfile();
  }

  const getPointsData = async () => {
    try {
      setisLoading(true);
      const response = await getPoints();

      setRewardPoints(response);
      setisLoading(false);
    } catch (error) {}
  };
  useEffect(() => {
    // if (profileState.languageOfAccountId != 0) {
    fetchLanguageData();
    // }
    // fetchProfileDetails();
  }, [profileState.languageOfAccountId]);

  useEffect(() => {
    if (profileState.languageOfAccountId === 0) {
      setLanguagename("English");
      setprofileState({ ...profileState, languageOfAccountId: 13 });
      fetchLanguageData();
    }
  }, []);

  const handleDropdownToggleClick = () => {
    setShowDropdown(!showDropdown);
  };

  const fetchLanguageData = async () => {
    const response = await getLanguageList();
    setLanguage(response);

    if (profileState.languageOfAccountId != 0) {
      const s = response.filter(
        (r) => r.id === profileState.languageOfAccountId,
      );
      s.map((e) => {
        setLanguagename(e.language);
      });
    }
  };
  const filteredLanguages = languages?.filter((language) => {
    return language?.language
      ?.toLowerCase()
      .includes(searchquery.toLowerCase());
  });
  if (selectedLanguage) {
    content = (
      <>
        <Dropdown.Item>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>{selectedLanguage.language}</div>
            <div>{selectedLanguage.localeName}</div>
          </div>
        </Dropdown.Item>
      </>
    );
  } else if (searchquery) {
    if (filteredLanguages.length === 0) {
      content = <Dropdown.Item>No languages found.</Dropdown.Item>;
    } else if (filteredLanguages.length === 1) {
      content = (
        <>
          <Dropdown.Item
            onClick={() => handleLanguageClick(filteredLanguages[0])}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                className={
                  profileState?.languageOfAccountId === filteredLanguages[0].id
                    ? "highlightLanguage"
                    : "blurLanguage"
                }
              >
                {" "}
                {filteredLanguages[0].language}
              </div>
              <div
                style={{ fontSize: "13px" }}
                className={
                  profileState?.languageOfAccountId === filteredLanguages[0].id
                    ? "highlightLanguage"
                    : "blurLanguage"
                }
              >
                {filteredLanguages[0]?.localeName}
              </div>
            </div>
          </Dropdown.Item>
        </>
      );
    } else {
      content = (
        <>
          {filteredLanguages?.map((language) => (
            <Dropdown.Item
              key={language.id}
              onClick={() => handleLanguageClick(language)}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span
                  className={
                    profileState?.languageOfAccountId === language.id
                      ? "highlightLanguage"
                      : "blurLanguage"
                  }
                >
                  {" "}
                  {language.language}
                </span>
                <span
                  style={{ fontSize: "13px" }}
                  className={
                    profileState?.languageOfAccountId === language.id
                      ? "highlightLanguage"
                      : "blurLanguage"
                  }
                >
                  {language?.localeName}
                </span>
              </div>
            </Dropdown.Item>
          ))}
        </>
      );
    }
  } else {
    content = (
      <>
        {languages
          ?.sort((a, b) => a?.language.localeCompare(b.language))
          .map((l, index) => {
            return (
              <Dropdown.Item
                key={index}
                value={l?.id}
                onClick={() => handleLanguageClick(l)}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span
                    className={
                      profileState?.languageOfAccountId === l.id
                        ? "highlightLanguage"
                        : "blurLanguage"
                    }
                    style={{
                      display: "flex",

                      alignItems: "center",
                    }}
                  >
                    {l?.language}
                    {profileState?.languageOfAccountId === l.id ? (
                      <CheckIcon sx={{ height: "18px" }} />
                    ) : (
                      ""
                    )}
                  </span>
                  <span
                    style={{ fontSize: "13px" }}
                    className={
                      profileState?.languageOfAccountId === l.id
                        ? "highlightLanguage"
                        : "blurLanguage"
                    }
                  >
                    {l?.localeName}
                  </span>
                </div>
              </Dropdown.Item>
            );
          })}
      </>
    );
  }

  const rewardPointsData = useMemo(() => rewardPoints, [rewardPoints]);

  const getMilestonesData = async () => {
    try {
      const response = await getMilestones();

      var communityArray1 = filterArray(response, 1);
      var persoanlArray1 = filterArray(response, 2);
      setPersonalArray(persoanlArray1);
      setCommunityArray(communityArray1);
    } catch (error) {}
  };

  const handleOpenGifts = (e) => {
    e.preventDefault();
    setopenGifts(true);
  };
  const handlePersonalGifts = (e) => {
    e.preventDefault();
    setPersonalOpenGifts(true);
  };

  useEffect(() => {
    if (state.roleId === "Student") {
      getMilestonesData();
      if (!isDataFetched) {
        getPointsData();
        setIsDataFetched(true);
      }
    }
  }, []);

  const openLoginModel = () => {
    if (!state.token) {
      setModalShow(true);
    }
  };
  const logOutUser = () => {
    actions.logOutUser();
    history.push("/");
  };
  return (
    <>
      {openNotifications && (
        <Notifications
          closeHandler={() => {
            setopenNotifications(false);
          }}
        />
      )}
      {openGifts && (
        <GiftsStepper
          communityArray={communityArray}
          // rewardPoints.totalPointsForAllStudents
          communityRewardPoints={rewardPointsData.totalPointsForAllStudents}
          // sliderData={sliderData}
          closeHandler={() => setopenGifts(false)}
        />
      )}
      {personalOpenGifts && (
        <PersonalRewardsGiftsStepper
          personalArray={personalArray}
          personalRewardPoints={rewardPointsData.totalPointsForLoggedInUser}
          // sliderData={sliderData}
          closeHandler={() => setPersonalOpenGifts(false)}
        />
      )}
      {openTutors && <HaveTutors onClose={() => setopenTutors(false)} />}

      {location.pathname === "/dashboard" ||
      location.pathname === "/profile" ||
      location.pathname.indexOf("/studentProfile") == 0 ||
      // location.pathname.contains("/student") === "/studentProfile/" ||
      location.pathname === "/games" ||
      location.pathname === "/MyActivities" ||
      location.pathname === "/Allfiles" ||
      location.pathname === "/Instruction" ||
      location.pathname === "/newlesson" ||
      location.pathname === "/Gameflow" ||
      location.pathname === "/Currentlesson" ||
      location.pathname === "/createLiveLesson" ||
      location.pathname === "/student/cancelledLiveLesson" ||
      location.pathname === "/student/confirmLiveLesson" ||
      location.pathname.includes("AcceptLiveLesson") ||
      location.pathname === "/student/tutorDetails" ||
      location.pathname === "/student/myConnections" ||
      location.pathname === "/student/friendDetails" ||
      location.pathname === "/tutor/profilePage" ||
      location.pathname === "/payment" ||
      location.pathname === "/personalRewards" ||
      location.pathname === "/communityRewards" ||
      location.pathname.slice(0, 13) === "/PersonalTask" ||
      location.pathname.slice(0, 15) === "/GameQuizAnswer" ||
      location.pathname.slice(0, 19) === "/PersonalGameAnswer" ||
      location.pathname === "/student-badges" ||
      location.pathname === "/tutorList" ? (
        <div className="home-wrapper">
          <div className="dashboard-inner-sidemenu">
            <div className="inner-page-topbar profile-topbar dashboard-topbarr">
              {/* sidemenu bar  */}

              <div className="custome-container">
                <div className={`menuside ${breadMenuShow ? "showmenu" : ""}`}>
                  <div className="sidemenubar-main">
                    <div className="logobar-top">
                      <div className="logoimg">
                        <Link to={redirectTo ? redirectTo : "#"}>
                          <img
                            src={"/neitheduLogo.png"}
                            style={{ width: "15rem" }}
                            alt="logo"
                            className="img-fluid"
                          />
                        </Link>
                      </div>
                      <div className="close-sidebar">
                        <button
                          className="btnclose"
                          onClick={() => {
                            setBreadMenuShow(!breadMenuShow);
                          }}
                        >
                          <img src={crossicon} className="img-fluid" />
                        </button>
                      </div>
                    </div>

                    <div className="sidemenubar-list">
                      <ul className="list-sidemenu">
                        {sideMenuButtons.map((menu, idx) => {
                          const isActive = location.pathname === menu.path;
                          return (
                            <li key={idx}>
                              <Link
                                to={menu?.path || "#"}
                                className={
                                  "sidemenu-tex" + (isActive ? " active" : "")
                                }
                              >
                                {menu.menuTitle}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="header-holder navbar-top">
                  <div className="lft-menu">
                    <ul className="header-btn-top profile-menubar">
                      <li className="btn-bx-get">
                        <div
                          onClick={() => setBreadMenuShow(!breadMenuShow)}
                          className="pro-bread"
                        >
                          <img src={breadmenu} className="img-fluid" />
                        </div>
                      </li>
                      <li>
                        {state.token ? (
                          <Navbar.Brand href={redirectTo}>
                            <img
                              src={"/neitheduLogo.png"}
                              style={{ width: "15rem" }}
                              alt="logo"
                              className="img-fluid"
                            />
                          </Navbar.Brand>
                        ) : (
                          <Navbar.Brand href="/">
                            <img
                              src={"/neitheduLogo.png"}
                              style={{ width: "15rem" }}
                              alt="logo"
                              className="img-fluid"
                            />
                          </Navbar.Brand>
                        )}
                      </li>
                      <li className="btn-bx-get desktop-show-only">
                        <div className="count-bar">
                          {isLoading ? (
                            <CircularProgress
                              size={"1.5rem"}
                              sx={{ color: "var(--purple)" }}
                            />
                          ) : (
                            rewardPointsData.totalPointsForAllStudents
                          )}
                        </div>
                      </li>
                      <li className="btn-bx-get desktop-show-only">
                        <div onClick={handleOpenGifts} className="gift-ico">
                          <img src={gift} className="img-fluid" />
                        </div>
                      </li>
                    </ul>
                  </div>

                  <ul className="header-btn-top profile-menubar right-menu-dasboard">
                    <li className="btn-bx-get desktop-show-only">
                      {/* */}

                      <div className="count-bar">
                        {isLoading ? (
                          <CircularProgress
                            size={"1.5rem"}
                            sx={{ color: "var(--purple)" }}
                          />
                        ) : (
                          rewardPointsData.totalPointsForLoggedInUser
                        )}
                      </div>
                    </li>
                    <li className="btn-bx-get desktop-show-only">
                      <Link to="#" className="gift-ico">
                        <img src={plus} className="img-fluid" />
                      </Link>
                    </li>
                    <li className="btn-bx-get desktop-show-only">
                      <div onClick={handlePersonalGifts} className="gift-ico">
                        <img src={gift} className="img-fluid" />
                      </div>
                    </li>
                    <li className="btn-bx-get desktop-show-only">
                      <div
                        onClick={() => setopenTutors(true)}
                        to="#"
                        className="gift-ico"
                      >
                        <img src={count1} className="img-fluid" />
                      </div>
                    </li>
                    <li className="btn-bx-get desktop-show-only">
                      <Link to="#" className="gift-ico">
                        <img src={count2} className="img-fluid" />
                      </Link>
                    </li>
                    {/* notification bell */}
                    <li
                      className="btn-bx-get"
                      onClick={() => {
                        setopenNotifications(true);
                      }}
                    >
                      <Link to="#" className="bell-ico">
                        <img src={bell} className="img-fluid" /> <span>2</span>
                      </Link>
                    </li>
                    <li>
                      {" "}
                      <ul className="header-btn-top profile-menubar right-menu-dasboard">
                        <li className="btn-login-bx">
                          <Dropdown
                            show={showDropdown}
                            className="dropdown-main dropmenu-custlistt"
                          >
                            <Dropdown.Toggle
                              onClick={handleDropdownToggleClick}
                              variant="success"
                              id="dropdown-autoclose-outside"
                              className="login-btn dropdown-btnn"
                            >
                              <i className="fas fa-user"></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="dropdown-btn-item customdrop-listmenu">
                              <ul
                                style={{
                                  cursor: "pointer",
                                  backgroundColor: "#7973eb",
                                  boxShadow: "0px 10px 30px rgb(0 0 0 / 8%)",
                                  color: "#ffffff",
                                  lineHeight: "20px",
                                  fontSize: "14px",
                                  marginBottom: "5px",
                                  borderRadius: "12px",
                                  padding: "12px 15px",
                                  fontWeight: "700",
                                }}
                                onClick={() => {
                                  setShowSideMenu(!showSideMenu);
                                }}
                              >
                                <span style={{ marginLeft: "6px" }}>
                                  {languagename}{" "}
                                  <span
                                    style={{
                                      paddingLeft: "12px",
                                    }}
                                    className="rightarrow-icon"
                                  >
                                    <img src={languageImg} alt="" />
                                  </span>{" "}
                                </span>
                              </ul>

                              <div
                                style={{
                                  height: "400px",
                                  overflowY: "scroll",
                                  overflowX: "hidden",
                                }}
                                className={
                                  showSideMenu ? "side-menu " : "side-menu show"
                                }
                              >
                                <Dropdown.Item>
                                  <SearchOutlinedIcon
                                    sx={{
                                      width: "20px",
                                      height: "20px",
                                      pr: "5px",
                                    }}
                                  />
                                  <input
                                    onChange={handleSearchInputChange}
                                    style={{
                                      backgroundColor: "transparent",
                                      outline: "none",
                                      border: "0px",
                                      color: "#ffffff",
                                    }}
                                    type="text"
                                    name=""
                                    id=""
                                    placeholder="Search"
                                    value={searchquery}
                                  />
                                </Dropdown.Item>
                                {content && content}
                              </div>

                              <Dropdown.Item
                                onClick={() => history.push("/studentProfile")}
                              >
                                <span className="rightarrow-icon">
                                  <i className="fas fa-angle-right"></i>
                                </span>{" "}
                                My profile
                              </Dropdown.Item>
                              <Dropdown.Item href="" onClick={logOutUser}>
                                <span className="rightarrow-icon">
                                  <i className="fas fa-angle-right"></i>
                                </span>{" "}
                                Logout
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="header-holder navbar-top">
          <Navbar bg="light" expand="lg">
            {!state.token ? (
              <Navbar.Brand href="/" className="logo-wrapper">
                <img
                  src={"/neitheduLogo.png"}
                  style={{ width: "15rem" }}
                  alt="logo"
                  className="img-fluid"
                />
                <p>BETA</p>
              </Navbar.Brand>
            ) : (
              <Navbar.Brand href={redirectTo}>
                <img
                  src={"/neitheduLogo.png"}
                  style={{ width: "15rem" }}
                  alt="logo"
                  className="img-fluid"
                />
              </Navbar.Brand>
            )}
            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <li>
                  {" "}
                  <Link to="/" className={link === "/" ? "active" : ""}>
                    Home
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link
                    to="/tutor"
                    className={link === "/tutor" ? "active" : ""}
                  >
                    Educators
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link
                    to="/about"
                    className={link === "/about" ? "active" : ""}
                  >
                    About
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link to="/blog" className={link === "/blog" ? "active" : ""}>
                    Blog
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link
                    to="/contact"
                    className={link === "/contact" ? "active" : ""}
                  >
                    Contact
                  </Link>
                </li>
                {!state.token && (
                  <div
                    className={`mobileview-show btn-getstarted ${
                      state.token ? "role-based-login-user" : ""
                    }`}
                  >
                    <div className="btn-bx-get">
                      <a
                        onClick={() => setModalSignupShow(true)}
                        href="#"
                        className="get-started"
                      >
                        <span className="btntex">Get started</span>{" "}
                      </a>
                    </div>
                  </div>
                )}
              </Nav>
            </Navbar.Collapse>

            <ul
              className={`header-btn-top ${
                state.token ? "role-based-login-user" : ""
              }`}
            >
              {!state.token && (
                <li className="btn-bx-get desktop-show">
                  <a
                    href="#"
                    onClick={() => setModalSignupShow(true)}
                    className="get-started"
                  >
                    <span className="btntex">Get started</span>{" "}
                  </a>
                </li>
              )}
              <li className="btn-login-bx">
                {/* className="btn-login-bx" */}
                {state.token ? (
                  <>
                    {" "}
                    <ul
                      style={{ marginLeft: "20px" }}
                      className="header-btn-top profile-menubar right-menu-dasboard"
                    >
                      <li className="btn-login-bx">
                        <Dropdown
                          show={showDropdown}
                          className="dropdown-main dropmenu-custlistt"
                        >
                          <Dropdown.Toggle
                            onClick={handleDropdownToggleClick}
                            variant="success"
                            id="dropdown-autoclose-outside"
                            className="login-btn dropdown-btnn"
                          >
                            <i className="fas fa-user"></i>
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="dropdown-btn-item customdrop-listmenu">
                            <ul
                              style={{
                                cursor: "pointer",
                                backgroundColor: "#7973eb",
                                boxShadow: "0px 10px 30px rgb(0 0 0 / 8%)",
                                color: "#ffffff",
                                lineHeight: "20px",
                                fontSize: "14px",
                                marginBottom: "5px",
                                borderRadius: "12px",
                                padding: "12px 15px",
                                fontWeight: "700",
                              }}
                              onClick={() => {
                                setShowSideMenu(!showSideMenu);
                              }}
                            >
                              <span style={{ marginLeft: "6px" }}>
                                {languagename}{" "}
                                <span
                                  style={{
                                    paddingLeft: "12px",
                                  }}
                                  className="rightarrow-icon"
                                >
                                  <img src={languageImg} alt="" />
                                </span>{" "}
                              </span>
                            </ul>

                            <div
                              style={{
                                height: "400px",
                                overflowY: "scroll",
                                overflowX: "hidden",
                              }}
                              className={
                                showSideMenu ? "side-menu " : "side-menu show"
                              }
                            >
                              <Dropdown.Item>
                                <SearchOutlinedIcon
                                  sx={{
                                    width: "20px",
                                    height: "20px",
                                    pr: "5px",
                                  }}
                                />
                                <input
                                  onChange={handleSearchInputChange}
                                  style={{
                                    backgroundColor: "transparent",
                                    outline: "none",
                                    border: "0px",
                                    color: "#ffffff",
                                  }}
                                  type="text"
                                  name=""
                                  id=""
                                  placeholder="Search"
                                  value={searchquery}
                                />
                              </Dropdown.Item>
                              {content && content}
                            </div>

                            <Dropdown.Item
                              onClick={() => history.push("/studentProfile")}
                            >
                              <span className="rightarrow-icon">
                                <i className="fas fa-angle-right"></i>
                              </span>{" "}
                              My profile
                            </Dropdown.Item>
                            <Dropdown.Item href="" onClick={logOutUser}>
                              <span className="rightarrow-icon">
                                <i className="fas fa-angle-right"></i>
                              </span>{" "}
                              Logout
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                    </ul>
                    {/* <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="login-btn"
                      >
                        <i className="fas fa-user"></i>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to="/studentProfile"
                          >
                            Settings
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item href="" onClick={logOutUser}>
                          Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown> */}
                  </>
                ) : (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="login-btn"
                      onClick={openLoginModel}
                    >
                      <i className="fas fa-user"></i>
                    </Dropdown.Toggle>
                  </Dropdown>
                )}
              </li>
            </ul>
          </Navbar>
        </div>
      )}
      <LoginModal
        setopenForgotPassword={setopenForgotPassword}
        modalShow={modalShow}
        setModalShow={(value) => setModalShow(value)}
        setModalSignupShow={() => setModalSignupShow(true)}
        setRedirectTo={(value) => setRedirectTo(value)}
      />

      <Signup
        modalSignupShow={modalSignupShow}
        setModalShow={(value) => setModalShow(value)}
        setModalSignupShow={(value) => setModalSignupShow(value)}
      />
      {openForgotPassword && (
        <ForgotPassword handleClose={() => setopenForgotPassword(false)} />
      )}
      <Questionnaire />
    </>
  );
};

export const HeaderDashboard = React.memo(HeaderDashboardElement);
