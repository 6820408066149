import "./App.css";
import { Redirect, Route } from "react-router";
import { useAppState } from "./overmind";

export const ProtectedRoute = ({ component: Component, auth, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        !auth ? <Redirect to="/" /> : <Component {...props} />
      }
    />
  );
};

export const RoleProtectedRoute = ({
  component: Component,
  matchRole,
  ...rest
}) => {
  const state = useAppState();
  console.log("state.roleId :::", state.roleId);
  console.log(state, "protected");
  console.log(state.roleId, "protec id");

  console.log(matchRole, "matchRole");
  return (
    <Route
      {...rest}
      render={(props) =>
        state.roleId === matchRole ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};
