import { Box, Typography } from "@mui/material";
import { DialogBox } from "../../utils/DialogBox";
import { StyledYellowButton } from "../../utils/Buttons";

const ConfirmCancel = ({ setModalType, role }) => {
  return (
    <DialogBox isOpen={true}>
      <Box
        sx={{
          display: "flex",

          flexDirection: "column",
          justifyContent: "center",
          rowGap: "1rem",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            color: "var(--purple-dark)",
            fontWeight: "800",
            fontSize: "1.3rem",
          }}
        >
          Live lesson was cancelled
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            color: "var(--black)",
            fontWeight: "500",
          }}
        >
          {role === "educator" ? "Student " : "Educator "}
          will be notified about cancellation of the lesson. You can discuss new
          lesson in chat
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <StyledYellowButton
            sx={{
              maxWidth: "300px",
            }}
            btnHandler={() => {
              setModalType(null);
            }}
            title="I understood"
          />
        </Box>
      </Box>
    </DialogBox>
  );
};

export default ConfirmCancel;
