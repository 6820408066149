import { Box, Modal, Typography } from "@mui/material";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import { CloseButton } from "../utils/Buttons";
import { color } from "@mui/system";
export const Notifications = ({ closeHandler }) => {
  const [openedItem, setopenedItem] = useState("notifications");
  const isNoti = () => openedItem === "notifications";
  const changeTab = {
    notifications: () => setopenedItem("notifications"),
    messages: () => setopenedItem("messages"),
  };

  const date = new Date();
  function getMonthName(monthNumber) {
    // const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString("en-US", { month: "long" });
  }
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let monthName = getMonthName(month);
  let year = date.getFullYear();
  let ShortMonthName = monthName.substring(0, 3);

  const msgData = [
    {
      title: "Augustino Rossa",
      heading:
        "Lorem ipsum dolor sit amet, consecue adipiscing elit, sed do eiusmod amet  ...",
      badgeCount: 1,
    },
    {
      title: "Class 7A",
      heading:
        "Lorem ipsum dolor sit amet, consecue adipiscing elit, sed do eiusmod amet  ...",
      badgeCount: 6,
    },
    {
      title: "Roman Soso",
      heading:
        "Lorem ipsum dolor sit amet, consecue adipiscing elit, sed do eiusmod amet  ...",
      badgeCount: 3,
    },
    {
      title: "Amerigo Vespucci",
      heading:
        "Lorem ipsum dolor sit amet, consecue adipiscing elit, sed do eiusmod amet  ...",
      badgeCount: 0,
    },
    {
      title: "Class High school",
      heading:
        "Lorem ipsum dolor sit amet, consecue adipiscing elit, sed do eiusmod amet  ...",
      badgeCount: 0,
    },
    {
      title: "Roman Soso",
      heading:
        "Lorem ipsum dolor sit amet, consecue adipiscing elit, sed do eiusmod amet  ...",
      badgeCount: 0,
    },
  ];

  const notiData = [
    {
      name: "Tutor name",
      title: " is waiting for you in lesson",
      heading: "Endangered Species Recovery",
      day: "",
      year: "",
      month: "",
      // time: Date.now(),
      specific: "Live Lesson",
      availability: "",
    },
    {
      name: "Tutor name",
      title: " edited the lesson",
      heading: "The Big Bang Theory ",
      day: "",
      year: "",
      month: "",
      // time: Date.now(),
      specific: "Live Lesson",
      availability: "Needed to confirm",
      confirm: true,
    },
    {
      title: "New task from Tutor name",
      heading: " Law basics. Beginner concepts",
      day: "",
      year: "",
      month: "",
      // time: Date.now(),
      specific: "Neithedu Games",
      availability: "Quiz",
    },
    {
      name: "Tutor name",
      title: " created new lesson",
      heading: "Cosmic microwave background",
      day: "",
      year: "",
      month: "",
      // time: Date.now(),
      specific: "Live Lesson",
      availability: "Needed to confirm",
      confirm: true,
    },
    {
      title: "You missed deadline",
      day: "",
      year: "",
      month: "",
      heading: "CRISPR and Genetic Engineering",
      // time: Date.now(),
      day: day,
      year: year,
      month: ShortMonthName,
      specific: "Game flow",
      availability: "Climate Change",
    },
    {
      title: "Your trial period ends in 2 days",
      heading: "Buy subscription",
      link: true,
      day: "",
      year: "",
      month: "",
      // time: Date.now(),
      time: "Yesterday",
      specific: "",
      availability: "",
    },
    {
      title: "New material from Tutor name",
      heading: "Epidemiology and Coronavirus, what we should know about it",

      day: "",
      year: "",
      month: "",
      time: "2d ago",

      specific: "Game flow",
      availability: "Climate Change",
    },
  ];
  return (
    <Modal open onClose={closeHandler}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Box
          sx={{
            position: "relative",
            maxWidth: "30rem",
            p: "1rem",
            pr: "0.3rem",
            pt: "1.5rem",
            backgroundColor: "white",
            borderRadius: "20px",
            boxShadow: "0px 4px 10px rgba(0,0,0,0.15)",
          }}
        >
          <CloseIcon
            onClick={closeHandler}
            sx={{
              cursor: "pointer",

              position: "absolute",
              top: "0.5rem",
              right: "0.5rem",
              color: "var(--orange)",
            }}
          />

          <Box
            sx={{
              display: "flex",
              columnGap: "3rem",
              justifyContent: "space-around",
            }}
          >
            <Box
              sx={{
                display: "flex",
                columnGap: "0.5rem",
                borderBottom: isNoti() ? "3px solid var(--yellow)" : "none",
              }}
            >
              <Typography
                onClick={changeTab.notifications}
                sx={{
                  cursor: "pointer",
                  color: isNoti() ? "var(--purple-dark)" : "#BDBDBD",
                  fontWeight: "700",
                  fontSize: "1.2rem",
                }}
              >
                Notifications
              </Typography>
              <Typography
                sx={{
                  fontSize: "1.2rem",
                  fontWeight: "700",

                  color: isNoti() ? "var(--orange)" : "#BDBDBD",
                }}
              >
                6
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                columnGap: "0.5rem",
                borderBottom: !isNoti() ? "3px solid var(--yellow)" : "none",
              }}
            >
              <Typography
                onClick={changeTab.messages}
                sx={{
                  cursor: "pointer",
                  color: !isNoti() ? "var(--purple-dark)" : "#BDBDBD",
                  fontWeight: "700",
                  fontSize: "1.2rem",
                }}
              >
                Messages
              </Typography>
              <Typography
                sx={{
                  fontSize: "1.2rem",
                  fontWeight: "700",

                  color: !isNoti() ? "var(--orange)" : "#BDBDBD",
                }}
              >
                8
              </Typography>
            </Box>
          </Box>

          {/* CONTENT */}

          {isNoti() ? (
            <Box
              sx={{
                height: "20rem",
                overflowY: "scroll",
                fontFamily: "Mulish",
              }}
            >
              {notiData.map((notification, idx) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      my: "0.5rem",
                      // m: "0.5rem 1rem 0.5rem 0.5rem",
                      columnGap: "1rem",
                      borderBottom: "1px solid #BDBDBD",
                    }}
                    key={idx}
                  >
                    <Box
                      sx={{
                        borderRadius: "50%",
                        width: "3rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "3rem",
                        p: "1rem",
                        mt: "15px",
                        backgroundColor: "var(--purple)",
                      }}
                    >
                      <PersonIcon sx={{ color: "white", fontSize: "2rem" }} />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        // height: "85px",
                        mt: "15px",
                      }}
                    >
                      <Box sx={{ width: "100%" }}>
                        <Box sx={{ display: "flex" }}>
                          <Typography sx={{ px: "3px" }}>
                            <span
                              style={{ fontWeight: "700", color: "#4F4F4F" }}
                            >
                              {notification.name}
                            </span>{" "}
                            {notification.title}
                          </Typography>
                          <Typography
                            sx={{ color: "#4F4F4F" }}
                            fontWeight={700}
                          >
                            {notification.month}
                            {notification.day}, {notification.year}
                          </Typography>
                        </Box>
                        <Box sx={{}}>
                          {notification.link === true ? (
                            <Typography sx={{ ml: "5px", fontWeight: "400" }}>
                              <a
                                className="underline"
                                href="#"
                                style={{ color: " #4F4F4F" }}
                              >
                                {" "}
                                {notification.heading}
                              </a>
                            </Typography>
                          ) : (
                            <Typography
                              sx={{
                                fontWeight: "700",
                                ml: "3px",
                                color: "#333333",
                                width: "80%",
                                fontSize: "15px",
                              }}
                            >
                              {notification.heading}
                            </Typography>
                          )}
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            columnGap: "1rem",
                            color: "gray",
                            mt: "11px",
                            mb: "9px",
                          }}
                        >
                          {notification.time ? (
                            <Typography
                              sx={{ ml: "5px", color: "#BDBDBD" }}
                              fontSize={"0.8rem"}
                            >
                              {notification.time}
                            </Typography>
                          ) : (
                            <Typography
                              sx={{ ml: "5px", color: "#BDBDBD" }}
                              fontSize={"0.8rem"}
                            >
                              23 min ago
                            </Typography>
                          )}

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              alignItems: "center",
                              mx: "3px",
                            }}
                          >
                            {notification.specific && (
                              <Box
                                sx={{
                                  backgroundColor: "#BDBDBD",
                                  width: "2px",
                                  borderRadius: "50%",
                                  ml: "-10px",
                                  height: "2px",
                                  alignItems: "center",
                                }}
                              ></Box>
                            )}
                            <Typography
                              sx={{ px: "9px", color: "#BDBDBD" }}
                              fontSize={"0.8rem"}
                            >
                              {notification.specific}
                            </Typography>
                            {notification.specific && (
                              <Box
                                sx={{
                                  backgroundColor: "#BDBDBD",
                                  width: "2px",
                                  borderRadius: "50%",
                                  height: "2px",
                                  alignItems: "center",
                                }}
                              ></Box>
                            )}
                            {notification.confirm ? (
                              <Typography
                                fontSize={"0.8rem"}
                                sx={{ ml: "10px" }}
                              >
                                {notification.availability}
                              </Typography>
                            ) : (
                              <Typography
                                sx={{ color: "#BDBDBD", ml: "10px" }}
                                fontSize={"0.8rem"}
                              >
                                {notification.availability}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      </Box>

                      <CloseIcon
                        sx={{
                          color: "var(--purple)",
                          width: "19px",
                          height: "19px",
                          mr: "10px",
                        }}
                      />
                    </Box>
                  </Box>
                );
              })}
            </Box>
          ) : (
            <>
              <Box sx={{ height: "20rem", overflowY: "scroll" }}>
                {msgData.map((message, idx) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        m: "0.5rem 1rem 0.5rem 0.5rem",
                        columnGap: "1rem",
                        borderBottom: "1px solid #BDBDBD",
                      }}
                      key={idx}
                    >
                      <Box
                        sx={{
                          borderRadius: "50%",
                          width: "3.2rem",
                          mt: "15px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "3rem",
                          p: "1rem",
                          backgroundColor: "var(--purple)",
                        }}
                      >
                        <PersonIcon sx={{ color: "white", fontSize: "2rem" }} />
                      </Box>
                      <Box sx={{ mt: "15px" }}>
                        <Typography>{message.title}</Typography>
                        <Typography sx={{ color: "#828282", mt: "3px" }}>
                          {message.heading}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            columnGap: "1rem",
                            color: "gray",
                          }}
                        >
                          <Typography
                            fontSize={"0.8rem"}
                            sx={{
                              mt: "3px",
                              mb: "9.57px",
                              color: "#BDBDBD",
                              fontFamily: "Mulish",
                            }}
                          >
                            23 min ago
                          </Typography>
                        </Box>
                      </Box>
                      {message.badgeCount > 0 && (
                        <Box
                          sx={{
                            color: "white",
                            backgroundColor: "#4FE7E7",
                            borderRadius: "4px",
                            textAlign: "center",
                            p: "0.3rem",
                            mt: "34px",
                            minWidth: "1.5rem",
                            lineHeight: "1rem",
                            height: "1.7rem",
                          }}
                        >
                          {message.badgeCount}
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
