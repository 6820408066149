import React from "react";
import { Form, Modal } from "react-bootstrap";

const DeleteConfirmationModal = ({
  open,
  toBeDeletedName,
  cancelHandler,
  deleteHandler,
  confirmationText,
}) => {
  return (
    <Modal
      className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
      show={open}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="login-modal-mainbx groupmbr-step2">
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>
              {confirmationText
                ? confirmationText
                : `Do you want to delete ${toBeDeletedName}?`}
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="login-modl-form">
            <div className="loginform-bx">
              <Form.Group className="subsform-fild">
                <div className="btn-delet-bx">
                  <ul>
                    <li>
                      <button
                        className="delet-ys btn-grup-class btnweak-bx cancel-btn"
                        type="submit"
                        onClick={cancelHandler}
                      >
                        Cancel
                      </button>
                    </li>
                    <li>
                      <button
                        className="delet-no btn-grup-class btnweak-bx delbtn"
                        type="submit"
                        onClick={deleteHandler}
                      >
                        Delete
                      </button>
                    </li>
                  </ul>
                </div>
              </Form.Group>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default DeleteConfirmationModal;
