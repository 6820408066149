import React, { useEffect } from "react";
import "../styles/videoControls.css";

import Mic from "../images/new/microphone.svg";
import Mute from "../images/new/Microphone_disabled.svg";
import Video from "../images/new/video.svg";
import VideoDisabled from "../images/new/video_disabled.svg";
import FullScreen from "../images/new/fullscreen.svg";
import FullScreenDisabled from "../images/new/fullscreen-collapse.svg";
import Stop from "../images/new/terminate.svg";
import Whiteboard from "../images/new/board.svg";
import WhiteboardDisabled from "../images/new/board_disabled.svg";
import Grid from "../images/new/grid.svg";
import GridDisabled from "../images/new/grid_disabled.svg";
import Whiteboard_min from "../images/new/board.svg";
import Whiteboard_max from "../images/new/board_expand.svg";
import Chat from "../images/new/message.svg";
import ChatDisabled from "../images/new/message disabled.svg";

const VideoChatControls = (props) => {
  const {
    localStream,
    setLocalStream,
    toggleWhiteboard,
    isWhiteBoardActive,
    whiteBoardFullScreen,
    setWhiteBoardFullScreen,
    toggleChatWindow,
    isChatWindowOpen,
    isGridActive,
    setIsGridActive,
    terminateMeeting,
  } = props;

  const [isAudioTrackEnabled, setIsAudioTrackEnabled] = React.useState(true);
  const [isVideoTrackEnabled, setIsVideoTrackEnabled] = React.useState(true);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  let is_teacher = params.get("isTeacher");

  useEffect(() => {
    let timeout;
    if (localStream) {
      timeout = setTimeout(() => {
        setIsAudioTrackEnabled(localStream?.getAudioTracks()[0].enabled);
        setIsVideoTrackEnabled(localStream?.getVideoTracks()[0].enabled);
      }, 1000);
    }
    return () => clearTimeout(timeout);
  }, [localStream]);

  const micToggle = () => {
    let _localStream = localStream;
    try {
      if (isAudioTrackEnabled) {
        _localStream.getAudioTracks()[0].enabled = false;
        setIsAudioTrackEnabled(false);
      } else {
        _localStream.getAudioTracks()[0].enabled = true;
        setIsAudioTrackEnabled(true);
      }
      setLocalStream(_localStream);
    } catch (e) {
      console.log("[SelfVideo:micToggle] exception " + e);
    }
  };

  const webCamToggle = () => {
    let _localStream = localStream;
    try {
      if (isVideoTrackEnabled) {
        _localStream.getVideoTracks()[0].enabled = false;
        setIsVideoTrackEnabled(false);
      } else {
        _localStream.getVideoTracks()[0].enabled = true;
        setIsVideoTrackEnabled(true);
      }
      setLocalStream(_localStream);
    } catch (e) {
      console.log("[SelfVideo:webCamToggle] exception " + e);
    }
  };

  const toggleGirdView = () => {
    if (!isGridActive) setIsGridActive(true);
    else setIsGridActive(false);
  };

  const toggleFullscreen = () => {
    try {
      if (
        document.fullscreenElement /* Standard syntax */ ||
        document.webkitFullscreenElement /* Safari and Opera syntax */ ||
        document.msFullscreenElement /* IE11 syntax */
      ) {
        closeFullscreen();
      } else {
        openFullscreen();
      }
    } catch (e) {
      console.log("[SelfVideo:toggleFullscreen] exception " + e);
    }
  };

  const toggleWhiteBoardFullScreen = () => {
    console.log("toggleWhiteBoardFullScreen");
    try {
      if (!whiteBoardFullScreen) {
        isChatWindowOpen && toggleChatWindow();
        setWhiteBoardFullScreen(true);
      } else {
        setWhiteBoardFullScreen(false);
      }
    } catch (e) {
      console.log("[SelfVideo:toggleFullscreen] exception " + e);
    }
  };

  const openFullscreen = () => {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      /* Safari */
      document.documentElement.webkitRequestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) {
      /* IE11 */
      document.documentElement.msRequestFullscreen();
    }
  };

  const closeFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
  };

  const hangup = () => {
    try {
      terminateMeeting();
    } catch (e) {
      console.log("[SelfVideo:hangup] exception " + e);
    }
  };

  const getUI = () => {
    let value = "" + is_teacher;
    if (value === "true") {
      return (
        <div
          className={
            isChatWindowOpen
              ? "video-controls-right-alignment-chat-active"
              : "video-controls-right-alignment"
          }
        >
          <div
            className={
              isWhiteBoardActive
                ? "video-controls-container-right"
                : "video-controls-container-right-whiteboard-inactive"
            }
          >
            <div onClick={toggleWhiteboard}>
              <img
                src={!isWhiteBoardActive ? WhiteboardDisabled : Whiteboard}
                className="video-controls-whiteboard"
                alt=""
              />
            </div>
            {!isWhiteBoardActive && (
              <div onClick={() => toggleGirdView()}>
                <img
                  src={!isGridActive ? GridDisabled : Grid}
                  className="video-controls-grid"
                  alt=""
                />
              </div>
            )}
            {isWhiteBoardActive && (
              <div onClick={() => toggleWhiteBoardFullScreen()}>
                <img
                  src={!whiteBoardFullScreen ? Whiteboard_max : Whiteboard_max}
                  className="video-controls-whiteboard-fullscreen"
                  alt=""
                />
              </div>
            )}
            <div onClick={() => toggleFullscreen()}>
              <img
                src={
                  !document.fullscreenElement /* Standard syntax */
                    ? FullScreen
                    : FullScreenDisabled
                }
                className="video-controls-fullscreen"
                alt=""
              />
            </div>
            <div onClick={() => hangup()}>
              <img src={Stop} className="video-controls-stop" alt="" />
            </div>

            <div onClick={!whiteBoardFullScreen ? toggleChatWindow : () => {}}>
              <img
                src={isChatWindowOpen ? Chat : ChatDisabled}
                className="video-controls-chat"
                alt=""
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={
            isChatWindowOpen
              ? "video-controls-right-alignment-chat-active"
              : "video-controls-right-alignment"
          }
        >
          <div className="video-controls-container-right-whiteboard-inactive-one">
            {!isWhiteBoardActive && (
              <div onClick={() => toggleGirdView()}>
                <img
                  src={isGridActive ? Grid : GridDisabled}
                  className="video-controls-grid"
                  alt=""
                />
              </div>
            )}
            {isWhiteBoardActive && (
              <div onClick={() => toggleWhiteBoardFullScreen()}>
                <img
                  src={!whiteBoardFullScreen ? Whiteboard_max : Whiteboard_min}
                  className="video-controls-whiteboard-fullscreen"
                  alt=""
                />
              </div>
            )}
            <div onClick={() => toggleFullscreen()}>
              <img
                src={FullScreen}
                className="video-controls-fullscreen"
                alt=""
              />
            </div>

            <div onClick={!whiteBoardFullScreen ? toggleChatWindow : () => {}}>
              <img
                src={isChatWindowOpen ? ChatDisabled : Chat}
                className="video-controls-chat"
                alt=""
              />
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div className="video-controls-left-alignment">
        <div className="video-controls-container-left">
          <div onClick={() => micToggle()}>
            <img
              src={isAudioTrackEnabled ? Mic : Mute}
              className="video-controls-mic"
              alt=""
            />
          </div>
          <div onClick={() => webCamToggle()}>
            <img
              src={isVideoTrackEnabled ? Video : VideoDisabled}
              className="video-controls-video"
              alt=""
            />
          </div>
        </div>
      </div>

      {getUI()}
    </>
  );
};

export default VideoChatControls;
