import { Axios } from "../Axios";

export const getCountries = async () => {
  return (await Axios.get("/countries")).data;
};
export const getCities = async (countryId = "") => {
  return (await Axios.get("/cities" + countryId)).data;
};
export const getProfileDetails = async () => {
  const res = await Axios.get("/profile");
  // console.log("getProfileDetais", res);
  return res.data;
};

export const searchMiniGames = async (
  word,
  topicId,
  categoryId,
  languageId,
) => {
  const res = await Axios.post("games/search-minigames", {
    searchTerm: word,
    topicId: topicId,
    categoryId: categoryId,
    languageId: languageId,
  });
  // console.log(res, "apoos");
  return res;
};

export const getPoints = async () => {
  const res = await Axios.get("/points");
  return res.data;
};
export const getMilestones = async () => {
  const res = await Axios.get("/milestones");
  return res.data;
};

export const getLanguageList = async () => {
  const res = await Axios.get("/profile/languages-list");
  if (res.status === 200) {
    return res.data;
  }
};

export const getPlansForStudents = async (role) => {
  const res = await Axios.get(`plans?role=${role}`);
  if (res.status === 200) {
    return res.data;
  }
};
export const getPlansForStudentsByPlanId = async (id) => {
  const res = await Axios.get(`plans/${id}`);
  if (res.status === 200) {
    return res.data;
  }
};

export const changePassword = async (currentPassword, newPassword) => {
  return (
    await Axios.post("profile/change-password", {
      currentPassword,
      newPassword,
      confirmPassword: newPassword,
    })
  ).data;
};

export const createSubscription = async (
  userId,
  planId,

  totalCount,
  quantity,
) => {
  return (
    await Axios.post("/subscriptions", {
      userId,
      planId,
      totalCount,
      quantity,
    })
  ).data;
};

export const updateStudentProfile = async (
  firstName,
  lastName,
  countryId,
  cityId,
  genderId,
  yearsOfStudy,
  gradeId,
) => {
  return (
    await Axios.post("profile/update/student", {
      firstName,
      lastName,
      countryId,
      cityId,
      genderId,
      yearsOfStudy,
      gradeId,
    })
  ).data;
};
export const getSubjects = async (syllabusId) =>
  (await Axios.get(`/student-categories/${syllabusId}`)).data;
export const getSyllabusesList = async () =>
  (await Axios.get(`/syllabuses`)).data;
export const getSyllabusesListByCountryId = async (countryID) =>
  (await Axios.get(`/syllabuses/country/${countryID}`)).data;
export const getTopicsByCategoryId = async (id) =>
  (await Axios.get(`/topics/list/${id}`)).data;
export const getGroupActivities = async (groupId) =>
  (await Axios.get("/activities/groups/" + groupId)).data;
export const getStudentActivities = async (studentId) =>
  (await Axios.get("/activities/students/" + studentId)).data;

export const tutorCities = async (countryId = "") => {
  return (await Axios.get("/tutors-cities/" + countryId)).data;
};
const searchTutor = async ({
  name = "",
  cityId = 0,
  subjectId = 0,
  countryId = 101,
  isFavorite = true,
  isCoach = true,
  genderId = 0,
  studentAge = 0,
  languageId = 0,
}) =>
  (
    await Axios.post("/tutors/search-tutor", {
      name,
      cityId,
      subjectId,
      countryId,
      isFavorite,
      isCoach,
      genderId,
      studentAge,
      languageId,
    })
  ).data;

const getAgencies = async () => (await Axios.get("/agencies")).data;
const createNewAgency = async ({
  id = 0,
  agencyName = "",
  countryId = 0,
  stateId = 0,
  cityId = 0,
  registeredTutors = 0,
  isActive = true,
}) =>
  (
    await Axios.post("/agencies", {
      id,
      agencyName,
      countryId,
      stateId,
      cityId,
      registeredTutors,
      isActive,
    })
  ).data;

const getLanguages = async () =>
  (await Axios.get("/profile/languages-list")).data;
export const API = {
  searchTutor,
  getAgencies,
  createNewAgency,
  getLanguages,
  getPoints,
  getMilestones,
};
