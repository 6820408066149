export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const disabledBtnProps = {
  background: "var(--disabled-btn-bg)",
  color: "var(--disabled-btn-color)",
};
