import {
  Box,
  // Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Rating,
  Select,
  // TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Container, Form, Modal, Row } from "react-bootstrap";
import PersonIcon from "@mui/icons-material/Person";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { HeaderDashboard } from "../layout/HeaderDashboard";
import WorkIcon from "@mui/icons-material/Work";
import CircleIcon from "@mui/icons-material/Circle";
import SchoolIcon from "@mui/icons-material/School";
import { StyledYellowButton } from "../utils/Buttons";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import logo from "../../assets/images/logo.png";

import Footer from "../layout/Footer/Footer";
import { useHistory } from "react-router";
import { API, getCountries, tutorCities } from "../../service/apis/common";

const tutorData = [
  {
    name: "Rajesh Koothrappali",
    subjects: ["Biology", "Astronomy"],
    no_reviews: 80,
    location: "India, Mumbai",
    cityName: "Mumbai",
    countryName: "India",
    userRating: "4.0",
  },
  {
    name: "Golla vamsi",
    subjects: ["Biology", "Astronomy"],
    no_reviews: 80,
    cityName: "Mumbai",
    countryName: "India",
    userRating: "4.0",
  },
  {
    name: "Rajesh Koothrappali",
    subjects: ["Biology", "Astronomy"],
    no_reviews: 80,
    cityName: "Mumbai",
    countryName: "India",
    userRating: "4.0",
  },
  {
    name: "Rajesh Koothrappali",
    subjects: ["Biology", "Astronomy"],
    no_reviews: 80,
    cityName: "Mumbai",
    countryName: "India",
    userRating: "4.0",
  },
  {
    name: "Rajesh Koothrappali",
    subjects: ["Biology", "Astronomy"],
    no_reviews: 80,
    cityName: "Mumbai",
    countryName: "India",
    userRating: "4.0",
  },
  {
    name: "Rajesh Koothrappali",
    subjects: ["Biology", "Astronomy"],
    no_reviews: 80,
    cityName: "Mumbai",
    countryName: "India",
    userRating: "4.0",
  },
  {
    name: "Rajesh Koothrappali",
    subjects: ["Biology", "Astronomy"],
    no_reviews: 80,
    cityName: "Mumbai",
    countryName: "India",
    userRating: "4.0",
  },
  {
    name: "Rajesh Koothrappali",
    subjects: ["Biology", "Astronomy"],
    no_reviews: 80,
    cityName: "Mumbai",
    countryName: "India",
    userRating: "4.0",
  },
  {
    name: "Rajesh Koothrappali",
    subjects: ["Biology", "Astronomy"],
    no_reviews: 80,
    cityName: "Mumbai",
    countryName: "India",
    userRating: "4.0",
  },
];

const subjects = [
  {
    name: "Maths",
    id: 0,
  },
  {
    name: "Physics",
    id: 1,
  },
  {
    name: "Literature",
    id: 2,
  },
  {
    name: "English",
    id: 3,
  },
  {
    name: "Chemistry",
    id: 4,
  },
  {
    name: "Geography",
    id: 5,
  },
];
export const ListOfTutors = () => {
  const history = useHistory();
  const [openFiler, setopenFiler] = useState(false);
  const [state, setstate] = useState({
    countries: [],
    cities: [],
    searchQuery: "",
    tutors: tutorData,
    selectedCountry: { id: 1, name: "" },
    selectedCity: { id: 1, name: "" },
    location: {
      open: false,
      value: "Location",
      anchor: null,
      width: null,
    },
    subject: {
      open: false,
      value: "All subjects",
      anchor: null,
      width: null,
    },
  });

  const dropDown = {
    onClose: {
      location() {
        setstate((prevState) => ({
          ...prevState,
          location: { ...prevState.location, open: false, anchor: null },
        }));
      },
      subject() {
        setstate((prevState) => ({
          ...prevState,
          subject: { ...prevState.subject, open: false, anchor: null },
        }));
      },
    },
    onOpen: {
      location(e) {
        setstate((prevState) => ({
          ...prevState,
          location: {
            ...prevState.location,
            open: true,
            anchor: e.currentTarget,
            width: e.currentTarget?.clientWidth,
          },
        }));
      },
      subject(e) {
        setstate((prevState) => ({
          ...prevState,
          subject: {
            ...prevState.subject,
            open: true,
            anchor: e.currentTarget,
            width: e.currentTarget?.clientWidth,
          },
        }));
      },
    },
  };

  const searchTutors = async (query) => {
    setstate((prevState) => ({ ...prevState, searchQuery: query }));
    const res = await API.searchTutor({ name: query });
    setstate((prevState) => ({
      ...prevState,

      tutors: res.rows,
    }));
  };

  const fetchCountries = async () => {
    try {
      const res = await getCountries();
      setstate((prevState) => ({ ...prevState, countries: res }));
    } catch (err) {}
  };

  const selectCountry = async (countryId) => {
    setstate((prevState) => ({
      ...prevState,
      selectedCountry: { id: countryId },
    }));
    try {
      const res = await tutorCities(countryId);
      setstate((prevState) => ({ ...prevState, cities: res }));
    } catch (err) {}
  };
  const selectCity = async (cityId) => {
    setstate((prevState) => ({
      ...prevState,
      selectedCity: { id: cityId },
    }));
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  return (
    <div className="home-wrapper">
      <HeaderDashboard />
      <div
        style={{ minHeight: "100vh" }}
        className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs"
      >
        <Container style={{ textAlign: "start" }}>
          <Box
            sx={{
              color: "white",
              display: "flex",
              columnGap: "0.5rem",
              alignItems: "center",
            }}
          >
            <Typography fontSize="0.7rem">Home</Typography>
            <CircleIcon sx={{ fontSize: "0.5rem" }} />
            <Typography fontSize="0.7rem">Tutors</Typography>
          </Box>

          <Typography
            sx={{
              color: "white",
              fontSize: "2rem",
              fontWeight: "700",
              mt: "2rem",
            }}
          >
            Tutors
          </Typography>
          <Grid columnSpacing={"1rem"} container>
            <Grid item md={4}>
              <input
                value={state.searchQuery}
                onChange={(e) => searchTutors(e.target.value)}
                placeholder="Start typing name"
                style={{
                  backgroundColor: "#EDEDFF",
                  borderRadius: "10px",
                  width: "100%",
                  border: "none",
                  padding: "0.5rem",
                }}
              />
            </Grid>
            <Grid item md={3}>
              <Box
                aria-controls={"basic-menu"}
                onClick={dropDown.onOpen.location}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  p: "0.5rem",
                  alignItems: "center",
                  width: "100%",
                  backgroundColor: "#EDEDFF",
                  borderRadius: "10px",
                }}
              >
                <Typography>{state.location.value}</Typography>
                <ArrowDropDownIcon
                  sx={{
                    transform: state.location.open && "rotate(180deg)",
                    transition: "transform 0.2s",
                  }}
                />
              </Box>
              <Menu
                anchorEl={state.location.anchor}
                open={state.location.open}
                onClose={dropDown.onClose.location}
              >
                <Box sx={{ width: state.location.width + "px", p: "0.5rem" }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Country
                    </InputLabel>

                    <Select
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: "10rem",
                          },
                        },
                      }}
                      sx={{ backgroundColor: "#EDEDFF" }}
                      label="Country"
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={state.selectedCountry.id}
                      onChange={(e) => {
                        selectCountry(e.target.value);
                      }}
                    >
                      {state.countries.map((country, idx) => {
                        return (
                          <MenuItem
                            key={idx}
                            name={country.name}
                            value={country.id}
                          >
                            {country.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth sx={{ mt: "1rem" }}>
                    <InputLabel id="demo-simple-select-label">City</InputLabel>
                    <Select
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: "10rem",
                          },
                        },
                      }}
                      sx={{ backgroundColor: "#EDEDFF" }}
                      label="City"
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={state.selectedCity.id}
                      onChange={(e) => {
                        selectCity(e.target.value);
                      }}
                    >
                      {state.cities.map((city, idx) => {
                        return (
                          <MenuItem
                            key={city.cityID}
                            name={city.citeName}
                            value={city.cityID}
                          >
                            {city.cityName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </Menu>
            </Grid>
            <Grid item md={3}>
              {/* All subjects */}
              <Box
                aria-controls={"basic-menu"}
                onClick={dropDown.onOpen.subject}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  p: "0.5rem",
                  alignItems: "center",
                  width: "100%",
                  backgroundColor: "#EDEDFF",
                  borderRadius: "10px",
                }}
              >
                <Typography>{state.subject.value}</Typography>
                <ArrowDropDownIcon
                  sx={{
                    transform: state.subject.open && "rotate(180deg)",
                    transition: "transform 0.2s",
                  }}
                />
              </Box>

              <Menu
                anchorEl={state.subject.anchor}
                open={state.subject.open}
                onClose={dropDown.onClose.subject}
              >
                <Box sx={{ width: state.subject.width + "px", p: "0.5rem" }}>
                  {subjects.map((sub) => {
                    return (
                      <Box
                        key={sub.id}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Checkbox />
                        <Typography>{sub.name}</Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Menu>
            </Grid>
            <Grid item md={2}>
              <Box>
                <StyledYellowButton
                  title={"More filters"}
                  btnHandler={() => {
                    setopenFiler(true);
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Box
            sx={{
              my: "2rem",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                color: "var(--orange)",
                fontSize: "1.4rem",
                fontWeight: "600",
              }}
            >
              892 tutors
            </Typography>

            <Box
              sx={{
                display: "flex",
                columnGap: "0.5rem",
                alignItems: "center",
              }}
            >
              <FavoriteBorderIcon
                sx={{
                  color: "var(--purple)",
                  backgroundColor: "var(--yellow)",
                  borderRadius: "7px",
                  fontSize: "1.5rem",
                  p: "0.2rem",
                }}
              />
              <Typography sx={{ color: "white", fontSize: "0.9rem" }}>
                Show only favorites
              </Typography>
            </Box>

            <Box sx={{ display: "flex", columnGap: "0.5rem" }}>
              <Typography
                sx={{
                  color: "var(--purple)",
                  backgroundColor: "var(--yellow)",
                  borderRadius: "7px",
                  fontWeight: "700",
                  p: "0.3rem",
                  height: "1.7rem",
                  fontSize: "0.7rem",
                }}
              >
                Coach
              </Typography>
              <Typography sx={{ color: "white", fontSize: "0.9rem" }}>
                Show only coaches
              </Typography>
            </Box>
            <Box>
              <select
                style={{
                  backgroundColor: "rgba(255,255,255,0.1)",
                  borderRadius: "10px",
                  color: "black",
                  padding: "0.5rem",
                  border: "none",
                }}
              >
                <option value="1">Sort by date (from the newest)</option>
                <option value="2">By raiting (from highest)</option>
                <option value="3">By level (from Newbie to Master)</option>
                <option value="4">By level (from Master to Newbie)</option>
              </select>
            </Box>
          </Box>
          <Box sx={{ paddingBottom: "5rem" }}>
            {state.tutors.map((tutor, idx) => {
              return (
                <Box
                  onClick={() => history.push("/student/tutorDetails")}
                  key={idx}
                  sx={{
                    cursor: "pointer",
                    p: "1.4rem",
                    mb: "1rem",
                    backgroundColor: "white",
                    borderRadius: "1.2rem",
                  }}
                >
                  <Grid
                    container
                    alignItems={"center"}
                    justifyContent="space-between"
                    columnSpacing={"1rem"}
                  >
                    <Grid item md={1}>
                      <PersonIcon
                        sx={{
                          color: "white",
                          backgroundColor: "var(--purple)",
                          borderRadius: "50%",
                          height: "3rem",
                          width: "3rem",
                          p: "0.3rem",
                        }}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <Box sx={{}}>
                        <Typography
                          sx={{ fontWeight: "800", fontSize: "1rem" }}
                        >
                          {tutor.name}
                        </Typography>
                        <Box sx={{ display: "flex", columnGap: "1rem" }}>
                          <Typography
                            sx={{
                              backgroundColor: "var(--orange)",
                              color: "white",
                              p: "0.2rem",
                              fontSize: "0.7rem",
                              fontWeight: "600",
                              borderRadius: "0.4rem",
                            }}
                          >
                            Coach
                          </Typography>
                          <Typography sx={{ color: "var(--purple)" }}>
                            {tutor.cityName + ", " + tutor.countryName}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item md={2}>
                      <Typography sx={{ fontSize: "0.9rem" }}>
                        {tutor?.subjects
                          ?.reduce((prev, curr, idx, arr) => {
                            return (
                              prev + curr + (arr.length - 1 === idx ? "" : ", ")
                            );
                          }, "")
                          .substring()}
                      </Typography>
                    </Grid>

                    <Grid item md={1}>
                      <WorkIcon sx={{ color: "var(--purple)" }}>74</WorkIcon>
                    </Grid>
                    <Grid item md={2}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "0.5rem",
                          justifyContent: "space-between",
                          height: "100%",
                        }}
                      >
                        <Box sx={{ display: "flex", columnGap: "0.5rem" }}>
                          <SchoolIcon sx={{ color: "var(--orange)" }} />
                          <Typography
                            sx={{
                              fontWeight: "700",
                              color: "var(--purple-dark)",
                            }}
                          >
                            Advanced
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex", columnGap: "0.5rem" }}>
                          <Typography
                            sx={{
                              height: "1.9rem",
                              width: "2.3rem",
                              p: "0.3rem 0.4rem",
                              fontSize: "0.9rem",
                              color: "var(--purple-dark)",
                              fontWeight: "800",
                              backgroundColor: "var(--yellow)",
                              borderRadius: "6px",
                            }}
                          >
                            {tutor.userRating}
                          </Typography>
                          <Rating readOnly value={Number(tutor.userRating)} />
                        </Box>
                        <Typography sx={{ color: "var(--orange)" }}>
                          80 reviews
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={2}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          columnGap: "0.7rem",
                        }}
                      >
                        <FavoriteBorderIcon
                          sx={{
                            backgroundColor: "var(--purple-dark)",
                            p: "0.4rem",
                            fontSize: "2rem",
                            color: "white",
                            borderRadius: "10px",
                          }}
                        />
                        <Box
                          sx={{
                            borderRadius: "10px",
                            p: "0.2rem",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "var(--yellow)",
                          }}
                        >
                          <img
                            style={{ height: "1.5rem" }}
                            alt="chat"
                            src="/images/icons/chat.png"
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
          </Box>
        </Container>

        <Modal
          className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
          // {...props}
          show={openFiler}
          onHide={() => {
            return false;
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="login-modal-mainbx groupmbr-step2 mystudent-games">
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                <h4>Filter</h4>
              </Modal.Title>
              <button
                type="button"
                onClick={() => setopenFiler(false)}
                className="close"
              >
                <span aria-hidden="true">×</span>
                <span className="sr-only">Close</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className="login-modl-form">
                <div className="loginform-bx" style={{ position: "relative" }}>
                  <Form.Group className="subsform-fild">
                    <div
                      className="newgames-createbx"
                      style={{ marginBottom: "1rem" }}
                    >
                      <Row>
                        <div className="col-md-12">
                          <Form.Label>Title of the game</Form.Label>
                          <Form.Control as="select">
                            <option value={1}>10-12 years old</option>
                          </Form.Control>
                        </div>
                        <div className="col-md-12">
                          <Form.Label>Type of the game</Form.Label>
                          <Form.Control as="select">
                            <option value={1}>5-8 years</option>
                          </Form.Control>
                        </div>
                        <div className="col-md-12">
                          <Form.Label>Subjects</Form.Label>
                          <Form.Control as="select">
                            <option value={1}>Less than a year</option>
                          </Form.Control>
                        </div>
                        <div className="col-md-12">
                          <Form.Label>Age</Form.Label>
                          <Form.Control as="select">
                            <option value={1}>Any</option>
                          </Form.Control>
                        </div>
                      </Row>
                    </div>
                  </Form.Group>

                  <Box
                    sx={{
                      position: "absolute",
                      left: "35%",
                      width: "10rem",
                      bottom: "-20px",
                    }}
                  >
                    <StyledYellowButton
                      title={"Filter"}
                      btnHandler={() => {}}
                    />
                  </Box>
                  <Box
                    sx={{ position: "absolute", left: "39%", bottom: "-90px" }}
                  >
                    <StyledYellowButton
                      title={"Refresh Filter"}
                      btnHandler={() => {}}
                    />
                  </Box>
                </div>
              </div>
            </Modal.Body>
          </div>
        </Modal>

        <Footer logo={logo} />
      </div>
    </div>
  );
};
