import { Box, Grid, Modal, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { StyledYellowButton } from "../utils/Buttons";
import { useHistory } from "react-router";

export const ReceiveMorePoints = ({ closeHandler }) => {
  const history = useHistory();
  return (
    <Modal sx={{ overflowY: "scroll" }} open>
      <Box
        sx={{
          m: "1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            boxShadow: "0px 4px 10px rgba(0,0,0,0.15)",
            backgroundColor: "rgba(255,255,255, 0.5)",
            p: "0.5rem",
            borderRadius: "20px",
          }}
        >
          <Box
            sx={{
              fontFamily: "Poppins",
              opacity: "1",
              boxShadow: "0px 4px 10px rgba(0,0,0,0.15)",
              maxWidth: "60rem",
              position: "relative",
              width: "100%",
              p: "1rem",
              pt: "2rem",
              borderRadius: "12px",
              backgroundColor: "var(--purple-dark)",
            }}
          >
            <CloseIcon
              onClick={closeHandler}
              sx={{
                cursor: "pointer",
                color: "var(--orange)",
                position: "absolute",
                top: "10px",
                right: "10px",
              }}
            />
            <Typography
              sx={{
                color: "var(--orange)",
                fontWeight: "700",
                fontSize: "1.4rem",
                letterSpacing: "0.04rem",
              }}
            >
              How to receive more points? Play the games!{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "0.9rem",
                fontWeight: "400",
                color: "white",
                letterSpacing: "0.04rem",
              }}
            >
              On the website you will find more that 1000{" "}
              <u
                style={{ cursor: "pointer" }}
                onClick={() => history.push("/games")}
              >
                educational games
              </u>
            </Typography>
            <Typography
              sx={{
                mt: "1.5rem",
                color: "var(--yellow)",
                fontWeight: "700",
                fontSize: "1.1rem",
              }}
            >
              Invite your friends to Neithedu
            </Typography>
            <Grid container>
              <Grid item sm={6}>
                <Typography
                  sx={{ color: "white", fontWeight: "400", fontSize: "0.9rem" }}
                >
                  For every registered friend you will receive 20 point. Enter
                  email of student and we will send the invitation.
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Box sx={{ display: "flex", columnGap: "1rem", mr: "1rem" }}>
                  <input
                    placeholder="example@email.com"
                    style={{
                      border: "none",
                      padding: "0.5rem",
                      backgroundColor: "#EDEDFF",
                      borderRadius: "10px",
                    }}
                  />
                  <StyledYellowButton title="Invite" btnHandler={() => {}} />
                </Box>
              </Grid>
            </Grid>

            <Typography
              sx={{
                my: "2rem",
                color: "var(--yellow)",
                fontWeight: "700",
                fontSize: "1.1rem",
                lineHeight: "24px",
              }}
            >
              Other ways to earn more points
            </Typography>

            <Grid container columnSpacing={"1rem"} rowSpacing="2rem">
              <Grid item sm={6} md={3}>
                <Box
                  sx={{
                    position: "relative",
                    mb: "2rem",
                    px: "2rem",
                    pt: "5rem",
                    pb: "4rem",
                    backgroundColor: "white",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                >
                  <img
                    style={{
                      width: "6rem",
                      position: "absolute",
                      top: "-2rem",
                      left: "35%",
                    }}
                    src="/images/sampleImage.png"
                    alt="sample"
                  />
                  <Typography
                    sx={{
                      color: "var(--orange)",
                      fontWeight: "700",
                      fontSize: "1.3rem",
                    }}
                  >
                    Quizflix
                  </Typography>
                  <Typography
                    sx={{
                      color: "var(--purple-dark)",
                      fontWeight: "700",
                      fontSize: "1.2rem",
                    }}
                  >
                    +50 to your points
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: "0.9rem",
                      fontWeight: "300",
                    }}
                  >
                    The coin rewards on the app will contribute to the total
                    points here!
                  </Typography>
                  <Box
                    sx={{ position: "absolute", bottom: "-1rem", left: "30%" }}
                  >
                    <StyledYellowButton
                      btnHandler={() => {}}
                      title={"Google play"}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item sm={6} md={3}>
                <Box
                  sx={{
                    position: "relative",
                    mb: "2rem",
                    px: "2rem",
                    pt: "5rem",
                    pb: "4rem",
                    backgroundColor: "white",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                >
                  <img
                    style={{
                      width: "6rem",
                      position: "absolute",
                      top: "-2rem",
                      left: "35%",
                    }}
                    src="/images/sampleImage.png"
                    alt="sample"
                  />
                  <Typography
                    sx={{
                      color: "var(--orange)",
                      fontWeight: "700",
                      fontSize: "1.3rem",
                    }}
                  >
                    Quizflix
                  </Typography>
                  <Typography
                    sx={{
                      color: "var(--purple-dark)",
                      fontWeight: "700",
                      fontSize: "1.2rem",
                    }}
                  >
                    +50 to your points
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: "0.9rem",
                      fontWeight: "300",
                    }}
                  >
                    The coin rewards on the app will contribute to the total
                    points here!
                  </Typography>
                  <Box
                    sx={{ position: "absolute", bottom: "-1rem", left: "30%" }}
                  >
                    <StyledYellowButton
                      btnHandler={() => {}}
                      title={"Google play"}
                    />
                  </Box>
                  {/* <Box
                    sx={{ position: "absolute", bottom: "-5rem", left: "30%" }}
                  >
                    <StyledYellowButton
                      btnHandler={() => {}}
                      title={"Apple store"}
                    />
                  </Box> */}
                </Box>
              </Grid>
              <Grid item sm={6} md={3}>
                <Box
                  sx={{
                    position: "relative",
                    mb: "2rem",
                    px: "2rem",
                    pt: "5rem",
                    pb: "4rem",
                    backgroundColor: "white",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                >
                  <img
                    style={{
                      width: "6rem",
                      position: "absolute",
                      top: "-2rem",
                      left: "35%",
                    }}
                    src="/images/sampleImage.png"
                    alt="sample"
                  />
                  <Typography
                    sx={{
                      color: "var(--orange)",
                      fontWeight: "700",
                      fontSize: "1.3rem",
                    }}
                  >
                    Quizflix
                  </Typography>
                  <Typography
                    sx={{
                      color: "var(--purple-dark)",
                      fontWeight: "700",
                      fontSize: "1.2rem",
                    }}
                  >
                    +50 to your points
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: "0.9rem",
                      fontWeight: "300",
                    }}
                  >
                    The coin rewards on the app will contribute to the total
                    points here!
                  </Typography>
                  <Box
                    sx={{ position: "absolute", bottom: "-1rem", left: "30%" }}
                  >
                    <StyledYellowButton
                      btnHandler={() => {}}
                      title={"Google play"}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item sm={6} md={3}>
                <Box
                  sx={{
                    position: "relative",
                    mb: "2rem",
                    px: "2rem",
                    pt: "5rem",
                    pb: "4rem",
                    backgroundColor: "white",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                >
                  <img
                    style={{
                      width: "6rem",
                      position: "absolute",
                      top: "-2rem",
                      left: "35%",
                    }}
                    src="/images/sampleImage.png"
                    alt="sample"
                  />
                  <Typography
                    sx={{
                      color: "var(--orange)",
                      fontWeight: "700",
                      fontSize: "1.3rem",
                    }}
                  >
                    Quizflix
                  </Typography>
                  <Typography
                    sx={{
                      color: "var(--purple-dark)",
                      fontWeight: "700",
                      fontSize: "1.2rem",
                    }}
                  >
                    +50 to your points
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: "0.9rem",
                      fontWeight: "300",
                    }}
                  >
                    The coin rewards on the app will contribute to the total
                    points here!
                  </Typography>
                  <Box
                    sx={{ position: "absolute", bottom: "-1rem", left: "30%" }}
                  >
                    <StyledYellowButton
                      btnHandler={() => {}}
                      title={"Google play"}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
