import { Box, Typography } from "@mui/material";
import React from "react";
import { Dropdown } from "react-bootstrap";
import AddIcon from "@mui/icons-material/Add";
import batchorange from "../../assets/images/batch-orange.svg";
import ActivityNotification from "./activity/ActivityNotification";

const ActivityPanel = ({
  eductorStudentsAndGroups,
  selectedStudentOrGroup,
  setgroupmemberstep1ModalShow,
  setAddgroupstep2ModalShow,
  studentsInGroup,
  activitiesDataToMap,
  history,
  setOpenDialog,
  dialogs,
  setdeleteStudentConfirmDialog,
  setdeleteGroupConfirmDialog,
  state,
  userRole,
}) => {
  console.log("selectedStudentOrGroup", selectedStudentOrGroup);
  return (
    <div className="right-panel">
      <div className="activities-rightbx">
        {/* activity detail */}
        {eductorStudentsAndGroups?.length > 0 && (
          <div className="tabslist-content-act tabpanelone">
            <div className="myacti-listtabs educator-lsttabs-new">
              <div className="chat-headerbar">
                <div className="chat-topbar-list">
                  <div className="activ-detail-list">
                    <div className="active-detail-row">
                      <div className="activiti-prof-icon">
                        {selectedStudentOrGroup?.isGroup ? (
                          <span className="profile-icon-box">
                            <i className="fa fa-users"></i>
                          </span>
                        ) : (
                          <span className="profile-icon-box">
                            <i className="fas fa-user"></i>
                          </span>
                        )}
                      </div>
                      <div className="activiti-center-detail">
                        <h5>{selectedStudentOrGroup?.name}</h5>
                        {selectedStudentOrGroup?.isGroup && (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span
                              className="group-member grpmember-btn"
                              onClick={() => {
                                setgroupmemberstep1ModalShow(true);
                              }}
                            >
                              {studentsInGroup?.length} members
                            </span>
                            <div className="member-bxnw">
                              <span className="addbtn-meber">
                                <button
                                  className="addmbr-btn-sml"
                                  onClick={() => {
                                    setAddgroupstep2ModalShow(true);
                                    setgroupmemberstep1ModalShow(false);
                                  }}
                                >
                                  <i className="fas fa-plus"></i>
                                </button>
                              </span>
                            </div>
                          </Box>
                        )}
                        <h6 style={{ marginTop: "0.5rem" }}>
                          {activitiesDataToMap?.length || 0} activities in
                          progress
                        </h6>
                      </div>
                      <div className="activiti-count-mesg">
                        <div className="chat-right-droplist">
                          <ul className="list-chatbx">
                            <li
                              style={{
                                paddingRight: "0px",
                                marginRight: "1rem",
                              }}
                              className="chat-dropright educ-acvt"
                            >
                              <div className="droplist-right-btn">
                                <Dropdown className="dropdown-main">
                                  <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic"
                                    className="dropdown-btnn addactv-btndrp"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        columnGap: "0.5rem",
                                        p: "0.7rem",
                                        color: "white",
                                        borderRadius: "10px",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <AddIcon />
                                      <Typography
                                        sx={{
                                          fontWeight: "700",
                                          fontSize: "1rem",
                                        }}
                                      >
                                        Add an activity
                                      </Typography>
                                    </Box>
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu
                                    style={{
                                      marginTop: "1rem",
                                    }}
                                    className="dropdown-btn-item"
                                  >
                                    <Dropdown.Item
                                      onClick={() => {
                                        history.push(
                                          `/educator/selectMyGames?${
                                            selectedStudentOrGroup?.isGroup
                                              ? "groupId"
                                              : "studentId"
                                          }=${selectedStudentOrGroup?.id}`,
                                        );
                                      }}
                                    >
                                      <span className="rightarrow-icon">
                                        <i className="fas fa-angle-right"></i>
                                      </span>{" "}
                                      Game
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                      onClick={() => {
                                        setOpenDialog(
                                          dialogs.CREATE_PERSONAL_TASK,
                                        );
                                      }}
                                    >
                                      <span className="rightarrow-icon">
                                        <i className="fas fa-angle-right"></i>
                                      </span>{" "}
                                      Personal task
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() =>
                                        history.push(
                                          `/educator/selectMyMaterials?${
                                            selectedStudentOrGroup?.isGroup
                                              ? "groupId"
                                              : "studentId"
                                          }=${selectedStudentOrGroup?.id}`,
                                        )
                                      }
                                    >
                                      <span className="rightarrow-icon">
                                        <i className="fas fa-angle-right"></i>
                                      </span>{" "}
                                      Material
                                    </Dropdown.Item>
                                    {state.roleId === userRole.TUTOR &&
                                      !selectedStudentOrGroup?.isGroup && (
                                        <Dropdown.Item>
                                          <div
                                            onClick={() =>
                                              setOpenDialog(dialogs.CREATE_LIVE)
                                            }
                                          >
                                            <span className="rightarrow-icon">
                                              <i className="fas fa-angle-right"></i>
                                            </span>{" "}
                                            Live Lesson
                                          </div>
                                        </Dropdown.Item>
                                      )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </li>

                            <li
                              style={{ cursor: "pointer" }}
                              className="chat-iconn edu-badg-lst"
                            >
                              <div className="chatbtn">
                                <div
                                  onClick={() => setOpenDialog(dialogs.BADGES)}
                                  className="chatbtn-new"
                                >
                                  {" "}
                                  <img
                                    alt="badge"
                                    style={{
                                      width: "60%",
                                      marginRight: "0.8rem",
                                    }}
                                    src={batchorange}
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                            </li>
                            <li className="chat-dropright edu-lst-drp">
                              <div className="droplist-right-btn">
                                <Dropdown className="dropdown-main">
                                  <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic"
                                    className="dropdown-btnn"
                                  >
                                    <i className="fas fa-ellipsis-v"></i>
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu className="dropdown-btn-item">
                                    <Dropdown.Item href="#">
                                      <span className="rightarrow-icon">
                                        <i className="fas fa-angle-right"></i>
                                      </span>{" "}
                                      All files
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#">
                                      <span className="rightarrow-icon">
                                        <i className="fas fa-angle-right"></i>
                                      </span>{" "}
                                      Notifications
                                    </Dropdown.Item>
                                    {!selectedStudentOrGroup?.isGroup ? (
                                      <Dropdown.Item
                                        onClick={() => {
                                          setdeleteStudentConfirmDialog(true);
                                        }}
                                      >
                                        <span className="rightarrow-icon">
                                          <i className="fas fa-angle-right"></i>
                                        </span>{" "}
                                        Delete Student
                                      </Dropdown.Item>
                                    ) : (
                                      <Dropdown.Item
                                        onClick={() => {
                                          setdeleteGroupConfirmDialog(true);
                                        }}
                                      >
                                        <span className="rightarrow-icon">
                                          <i className="fas fa-angle-right"></i>
                                        </span>{" "}
                                        Delete Group
                                      </Dropdown.Item>
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="live-lesson-listbtm">
                {console.log("activitiesData", activitiesDataToMap)}
                {activitiesDataToMap &&
                  activitiesDataToMap.map((activity, index) => {
                    let linkToRedirect = "";
                    if (!selectedStudentOrGroup?.isGroup) {
                      if (activity.activityTypeId === 7) {
                        linkToRedirect = `/PersonalTask/${activity.activityTypeId}/${activity.id}`;
                      } else if (activity.activityTypeId === 8) {
                        linkToRedirect = `/LiveLessonDetail/${activity.activityTypeId}/${activity.id}`;
                      } else {
                        linkToRedirect = `/educator/student-game-details/${activity.activityTypeId}/${activity.id}`;
                      }
                    } else {
                      linkToRedirect = `/educator/groupTaskDetails/${activity.assignedTo}/${activity.activityToPerformId}/${activity.activityTypeId}`;
                    }
                    return (
                      <ActivityNotification
                        activity={activity}
                        linkToRedirect={linkToRedirect}
                        isGroup={selectedStudentOrGroup?.isGroup}
                        key={index}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ActivityPanel;
