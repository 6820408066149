import { Grid } from "@mui/material";
import React from "react";
import { Form, Modal, Row } from "react-bootstrap";
import { API } from "../../service/apis/common";
import { tutorRegister } from "../../service/apis/registration";
import { CustomSnackbar, useSnackState } from "../../utils/useSnackState";
import "../loginModal/login.scss";

const LANGUAGE_LEVELS = ["-", "Basic", "Conversational", "Fluent", "Native"];
const Becometutornew = ({
  setEducatorstep1ModalShow,
  modalEducatorstep1Show,
}) => {
  /* start Tutor signup step2 */
  const [modalTutorstep2Show, setTutorstep2ModalShow] = React.useState(false);
  /* end Tutor signup step2 */

  /* start Tutor signup step3 */
  const [modalTutorstep3Show, setTutorstep3ModalShow] = React.useState(false);
  /* end Tutor signup step3 */
  /* start Tutor signup step4 */
  const [modalTutorstep4Show, setTutorstep4ModalShow] = React.useState(false);
  /* end Tutor signup step4 */

  /* start Tutor signup step5 */
  const [modalTutorstep5Show, setTutorstep5ModalShow] = React.useState(false);
  /* end Tutor signup step5 */

  /* start Tutor signup step6 */
  const [modalTutorstep6Show, setTutorstep6ModalShow] = React.useState(false);
  /* end Tutor signup step6 */

  /* start Tutor signup step6 */
  const [modalTutorstep7Show, setTutorstep7ModalShow] = React.useState(false);
  /* end Tutor signup step6 */

  const [modalLanguagesShow, setmodalLanguagesShow] = React.useState(false);

  const [state, setstate] = React.useState({
    agencies: [],
    languages: [],
    selectedAgencyId: "",
    answer1: "",
    answer2: "",
    answer3: "",
    languageOneLevel: "-",
    languageTwoLevel: "-",
    languageThreeLevel: "-",
    languageOneId: "",
    languageTwoId: "",
    languageThreeId: "",
  });

  const [snackProps, open] = useSnackState();

  const updateState = (key, value) => {
    setstate((prevState) => ({ ...prevState, [key]: value }));
  };

  const fetchAgencies = async () => {
    try {
      const res = await API.getAgencies();
      setstate((prevState) => ({ ...prevState, agencies: res.rows }));
    } catch (err) {}
  };
  const fetchLanguages = async () => {
    try {
      const res = await API.getLanguages();
      setstate((prevState) => ({
        ...prevState,
        languages: [{ id: 0, language: "-" }, ...res],
        languageOneId: String(0),
        languageTwoId: String(0),
        languageThreeId: String(0),
      }));
    } catch (err) {}
  };

  const handleTutorRegistration = async () => {
    try {
      const res = await tutorRegister({
        answer1: state.answer1,
        answer2: state.answer2,
        answer3: state.answer3,
        agencyId: Number(state.selectedAgencyId),
        languageOneLevel: state.languageOneLevel,
        languageTwoLevel: state.languageTwoLevel,
        languageThreeLevel: state.languageThreeLevel,
        languageOneId: Number(state.languageOneId),
        languageTwoId: Number(state.languageTwoId),
        languageThreeId: Number(state.languageThreeId),
      });
      setTutorstep6ModalShow(true);
      setTutorstep5ModalShow(false);
    } catch (err) {}
  };

  React.useEffect(() => {
    fetchAgencies();
    fetchLanguages();
  }, []);

  return (
    <div className="become-tutor-mn">
      {/* start become a tutor modal footer */}

      {/* start become a tutor step1 */}
      <Modal
        className="loginmodal student-login-main educator-signup-step2 become-popup-modal become-popup-modal1"
        // {...props}
        show={modalEducatorstep1Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Became an Neithedu tutor</h4>
              <p>
                Please, choose tutoring agency that you are already registered
                with. If you add agancy, you will go to Advanced level without
                trial period
              </p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setEducatorstep1ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <Form.Label>I am registered with</Form.Label>

                  <div className="livein-field">
                    <Form.Control
                      as="select"
                      value={state.selectedAgencyId}
                      onChange={(e) =>
                        updateState("selectedAgencyId", e.target.value)
                      }
                    >
                      {state.agencies.map((agency) => {
                        return (
                          <option key={agency.id} value={agency.id}>
                            {agency.agencyName}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </div>
                </Form.Group>

                <div className="btnlogin-main btnone-become">
                  <button
                    type="submit"
                    className="btnlogin btnweak-bx"
                    onClick={() => {
                      setTutorstep2ModalShow(true);
                      setEducatorstep1ModalShow(false);
                    }}
                  >
                    Continue
                  </button>
                </div>

                <div className="btnlogin-main">
                  <button
                    type="submit"
                    className="btnlogin btnweak-bx"
                    onClick={() => {
                      setTutorstep7ModalShow(true);
                      setEducatorstep1ModalShow(false);
                    }}
                  >
                    I don’t have registration in any agency
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="count-barr become-count-step1">
            <div className="counting-barful">
              <div className="linebar-tex">1/6</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>

      {/* end become a tutor step1 */}

      {/* start become a tutor step2 */}
      <Modal
        className="loginmodal student-login-main educator-signup-step2 become-popup-modal become-popup-modal2"
        // {...props}
        show={modalTutorstep2Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <CustomSnackbar {...snackProps} />

        <div className="login-modal-mainbx">
          <Modal.Header>
            <div className="btnback">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setTutorstep2ModalShow(false);
                  setEducatorstep1ModalShow(true);
                }}
                className="backbtn-icn"
              >
                <i className="fas fa-arrow-left"></i>
              </div>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Became an Neithedu tutor</h4>
              <p>
                Please, answer some questions in order to see your motivation
                and qualification
              </p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setTutorstep2ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <Form.Label>Text of questions #1 about something</Form.Label>

                  <div className="livein-field">
                    <Form.Control
                      value={state.answer1}
                      onChange={(e) =>
                        setstate((prevState) => ({
                          ...prevState,
                          answer1: e.target.value,
                        }))
                      }
                      as="textarea"
                      className="tutor-textarea"
                      placeholder="Write answer here"
                    />
                  </div>
                </Form.Group>

                <div className="btnlogin-main btnone-become">
                  <button
                    type="submit"
                    className="btnlogin btnweak-bx"
                    onClick={() => {
                      if (state.answer1.trim() === "") {
                        open("Please fill the details", "error");
                        return;
                      }
                      setTutorstep3ModalShow(true);
                      setTutorstep2ModalShow(false);
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="count-barr become-count-step2">
            <div className="counting-barful">
              <div className="linebar-tex">2/6</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>

      {/* end become a tutor step2 */}

      {/* start become a tutor step3 */}
      <Modal
        className="loginmodal student-login-main educator-signup-step2 become-popup-modal become-popup-modal2"
        // {...props}
        show={modalTutorstep3Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <CustomSnackbar {...snackProps} />

        <div className="login-modal-mainbx">
          <Modal.Header>
            <div className="btnback">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setTutorstep3ModalShow(false);
                  setTutorstep2ModalShow(true);
                }}
                className="backbtn-icn"
              >
                <i className="fas fa-arrow-left"></i>
              </div>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Became an Neithedu tutor</h4>
              <p>
                Please, answer some questions in order to see your motivation
                and qualification
              </p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setTutorstep3ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <Form.Label>About something Text of questions #2</Form.Label>

                  <div className="livein-field">
                    <Form.Control
                      value={state.answer2}
                      onChange={(e) =>
                        setstate((prevState) => ({
                          ...prevState,
                          answer2: e.target.value,
                        }))
                      }
                      as="textarea"
                      className="tutor-textarea"
                      placeholder="Write answer here"
                    />
                  </div>
                </Form.Group>

                <div className="btnlogin-main btnone-become">
                  <button
                    type="submit"
                    className="btnlogin btnweak-bx"
                    onClick={() => {
                      if (state.answer2.trim() === "") {
                        open("Please fill the details", "error");
                        return;
                      }
                      setTutorstep4ModalShow(true);
                      setTutorstep3ModalShow(false);
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="count-barr become-count-step3">
            <div className="counting-barful">
              <div className="linebar-tex">3/6</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>

      {/* end become a tutor step3 */}

      {/* start become a tutor step4 */}
      <Modal
        className="loginmodal student-login-main educator-signup-step2 become-popup-modal become-popup-modal2"
        // {...props}
        show={modalTutorstep4Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <CustomSnackbar {...snackProps} />

        <div className="login-modal-mainbx">
          <Modal.Header>
            <div className="btnback">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setTutorstep4ModalShow(false);
                  setTutorstep3ModalShow(true);
                }}
                className="backbtn-icn"
              >
                <i className="fas fa-arrow-left"></i>
              </div>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Became an Neithedu tutor</h4>
              <p>
                Please, answer some questions in order to see your motivation
                and qualification
              </p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setTutorstep4ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <Form.Label>Text of questions #3 about something</Form.Label>

                  <div className="livein-field">
                    <Form.Control
                      value={state.answer3}
                      onChange={(e) =>
                        setstate((prevState) => ({
                          ...prevState,
                          answer3: e.target.value,
                        }))
                      }
                      as="textarea"
                      className="tutor-textarea"
                      placeholder="Write answer here"
                    />
                  </div>
                </Form.Group>

                <div className="btnlogin-main btnone-become">
                  <button
                    type="submit"
                    className="btnlogin btnweak-bx"
                    onClick={() => {
                      if (state.answer3.trim() === "") {
                        open("Please fill the details", "error");
                        return;
                      }
                      setmodalLanguagesShow(true);
                      setTutorstep4ModalShow(false);
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="count-barr become-count-step4">
            <div className="counting-barful">
              <div className="linebar-tex">4/6</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        className="loginmodal student-login-main educator-signup-step2 become-popup-modal become-popup-modal2"
        // {...props}
        show={modalLanguagesShow}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <CustomSnackbar {...snackProps} />

        <div className="login-modal-mainbx">
          <Modal.Header>
            <div className="btnback">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setmodalLanguagesShow(false);
                  setTutorstep4ModalShow(true);
                }}
                className="backbtn-icn"
              >
                <i className="fas fa-arrow-left"></i>
              </div>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Became an Neithedu tutor</h4>
              <p>
                Please, add working languages that you can use in your live
                lessons. At least one is required.
              </p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setmodalLanguagesShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Grid container columnSpacing={2}>
                  <Grid item xs={6}>
                    <Form.Group className="subsform-fild">
                      <Form.Label>Language 1*</Form.Label>

                      <div className="livein-field">
                        <Form.Control
                          value={state.languageOneId}
                          onChange={(e) =>
                            updateState("languageOneId", e.target.value)
                          }
                          as="select"
                        >
                          {state.languages.map((lang) => (
                            <option key={lang.id} value={lang.id}>
                              {lang.language}
                            </option>
                          ))}
                        </Form.Control>
                      </div>
                    </Form.Group>
                  </Grid>
                  <Grid item xs={6}>
                    <Form.Group className="subsform-fild">
                      <Form.Label>Level</Form.Label>

                      <div className="livein-field">
                        <Form.Control
                          value={state.languageOneLevel}
                          onChange={(e) =>
                            updateState("languageOneLevel", e.target.value)
                          }
                          as="select"
                        >
                          {LANGUAGE_LEVELS.map((level) => (
                            <option value={level} key={level}>
                              {level}
                            </option>
                          ))}
                        </Form.Control>
                      </div>
                    </Form.Group>
                  </Grid>
                </Grid>
                <Grid container columnSpacing={2}>
                  <Grid item xs={6}>
                    <Form.Group className="subsform-fild">
                      <Form.Label>Language 2*</Form.Label>

                      <div className="livein-field">
                        <Form.Control
                          value={state.languageTwoId}
                          onChange={(e) =>
                            updateState("languageTwoId", e.target.value)
                          }
                          as="select"
                        >
                          {state.languages.map((lang) => (
                            <option key={lang.id} value={lang.id}>
                              {lang.language}
                            </option>
                          ))}
                        </Form.Control>
                      </div>
                    </Form.Group>
                  </Grid>
                  <Grid item xs={6}>
                    <Form.Group className="subsform-fild">
                      <Form.Label>Level</Form.Label>

                      <div className="livein-field">
                        <Form.Control
                          value={state.languageTwoLevel}
                          onChange={(e) =>
                            updateState("languageTwoLevel", e.target.value)
                          }
                          as="select"
                        >
                          {LANGUAGE_LEVELS.map((level) => (
                            <option value={level} key={level}>
                              {level}
                            </option>
                          ))}
                        </Form.Control>
                      </div>
                    </Form.Group>
                  </Grid>
                </Grid>
                <Grid container columnSpacing={2}>
                  <Grid item xs={6}>
                    <Form.Group className="subsform-fild">
                      <Form.Label>Language 3*</Form.Label>

                      <div className="livein-field">
                        <Form.Control
                          value={state.languageThreeId}
                          onChange={(e) =>
                            updateState("languageThreeId", e.target.value)
                          }
                          as="select"
                        >
                          {state.languages.map((lang) => (
                            <option key={lang.id} value={lang.id}>
                              {lang.language}
                            </option>
                          ))}
                        </Form.Control>
                      </div>
                    </Form.Group>
                  </Grid>
                  <Grid item xs={6}>
                    <Form.Group className="subsform-fild">
                      <Form.Label>Level</Form.Label>

                      <div className="livein-field">
                        <Form.Control
                          value={state.languageThreeLevel}
                          onChange={(e) =>
                            updateState("languageThreeLevel", e.target.value)
                          }
                          as="select"
                        >
                          {LANGUAGE_LEVELS.map((level) => (
                            <option value={level} key={level}>
                              {level}
                            </option>
                          ))}
                        </Form.Control>
                      </div>
                    </Form.Group>
                  </Grid>
                </Grid>

                <div className="btnlogin-main btnone-become">
                  <button
                    type="submit"
                    className="btnlogin btnweak-bx"
                    onClick={() => {
                      if (
                        String(state.languageOneId) === "0" ||
                        state.languageOneLevel === "-"
                      ) {
                        open("Language 1 must be selected", "error");
                        return;
                      }
                      setTutorstep5ModalShow(true);
                      setmodalLanguagesShow(false);
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="count-barr become-count-step4">
            <div className="counting-barful">
              <div className="linebar-tex">5/6</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>

      {/* end become a tutor step4 */}

      {/* start become a tutor step5 */}
      <Modal
        className="loginmodal student-login-main educator-signup-step2 become-popup-modal become-popup-modal5"
        // {...props}
        show={modalTutorstep5Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header>
            <div className="btnback">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setTutorstep5ModalShow(false);
                  setmodalLanguagesShow(true);
                }}
                className="backbtn-icn"
              >
                <i className="fas fa-arrow-left"></i>
              </div>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Became an Neithedu tutor</h4>
              <p>Almost done!</p>
              <p> Please, read Tutor Policy and confirm you agree</p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setTutorstep5ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <div className="have-account">
                  <a className="title-med">Tutor Policy.pdf</a>
                </div>

                <div className="btnlogin-main btnone-become">
                  <button
                    type="submit"
                    className="btnlogin btnweak-bx"
                    onClick={async () => {
                      await handleTutorRegistration();
                    }}
                  >
                    I agree with Tutor Policy
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="count-barr become-count-step5">
            <div className="counting-barful">
              <div className="linebar-tex">5/6</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>

      {/* end become a tutor step5 */}

      {/* start become a tutor step6 */}
      <Modal
        className="loginmodal student-login-main educator-signup-step2 become-popup-modal become-popup-modal6"
        // {...props}
        show={modalTutorstep6Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header>
            {/* <div className="btnback">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setTutorstep6ModalShow(false);
                  setTutorstep5ModalShow(true);
                }}
                className="backbtn-icn"
              >
                <i className="fas fa-arrow-left"></i>
              </div>
            </div> */}
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Thanks, your application is sent!</h4>
              <p>
                Your account is under review now. We will check your application
                and after confirmation you will able to have a lessons
              </p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setTutorstep6ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <div className="btnlogin-main btnone-become">
                  <button
                    onClick={() => setTutorstep6ModalShow(false)}
                    type="submit"
                    className="btnlogin btnweak-bx"
                  >
                    I undestood
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end become a tutor step6 */}

      {/* start become a tutor step7 */}
      <Modal
        className="loginmodal student-login-main educator-signup-step2 become-popup-modal become-popup-modal7"
        // {...props}
        show={modalTutorstep7Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header>
            <div className="btnback">
              <div
                onClick={() => {
                  setTutorstep7ModalShow(false);
                  setEducatorstep1ModalShow(true);
                }}
                style={{ cursor: "pointer" }}
                className="backbtn-icn"
              >
                <i className="fas fa-arrow-left"></i>
              </div>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Became an Neithedu tutor</h4>
              <p>
                Since you do not have registration with agencies, we need to be
                sure of your qualifications
              </p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setTutorstep7ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <div className="upload-certif-bx">
                  <Form.Group className="subsform-fild">
                    <Form.Label className="form-label">
                      My CRB/DBS certificate
                    </Form.Label>

                    <Row className="certific-bx">
                      <div className="col-md-8 one">
                        <div className="file-upldnw">
                          <Form.Control
                            type="text"
                            placeholder="Upload the file"
                          />
                        </div>
                      </div>
                      <div className="col-md-4 two">
                        <div className="file-upnew">
                          <Form.Control
                            type="file"
                            className="brower-btn"
                            id="input-file"
                          />
                          <label
                            for="input-file"
                            className="btnweak-bx btn-browse-file"
                          >
                            Browse
                          </label>
                        </div>
                      </div>
                    </Row>
                  </Form.Group>

                  <Form.Group className="subsform-fild">
                    <Form.Label className="form-label">
                      Highest qualification
                    </Form.Label>

                    <Row className="certific-bx">
                      <div className="col-md-8 one">
                        <div className="file-upldnw">
                          <Form.Control
                            type="text"
                            placeholder="Upload the file"
                          />
                        </div>
                      </div>
                      <div className="col-md-4 two">
                        <div className="file-upnew">
                          <Form.Control
                            type="file"
                            className="brower-btn"
                            id="input-file"
                          />
                          <label
                            for="input-file"
                            className="btnweak-bx btn-browse-file"
                          >
                            Browse
                          </label>
                        </div>
                      </div>
                    </Row>
                  </Form.Group>

                  <Form.Group className="subsform-fild">
                    <Form.Label className="form-label">
                      My PGCE/TEFL certificate
                    </Form.Label>

                    <Row className="certific-bx">
                      <div className="col-md-8 one">
                        <div className="file-upldnw">
                          <Form.Control
                            type="text"
                            placeholder="Upload the file"
                          />
                        </div>
                      </div>
                      <div className="col-md-4 two">
                        <div className="file-upnew">
                          <Form.Control
                            type="file"
                            className="brower-btn"
                            id="input-file"
                          />
                          <label
                            for="input-file"
                            className="btnweak-bx btn-browse-file"
                          >
                            Browse
                          </label>
                        </div>
                      </div>
                    </Row>
                  </Form.Group>
                </div>

                <div className="btnlogin-main btnone-become">
                  <button
                    type="submit"
                    className="btnlogin btnweak-bx"
                    onClick={() => {
                      setTutorstep2ModalShow(true);
                      setTutorstep7ModalShow(false);
                    }}
                  >
                    Upload files and continue
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end become a tutor step7 */}

      {/* End become a tutor modal footer */}
    </div>
  );
};

export default Becometutornew;
