import React, { useState } from "react";
import "./home.scss";
import "./profile.scss";
import "./media.scss";
import "./games.scss";
import "./loginModal/login.scss";
import "./messages/messages.scss";
import "./myactivities.scss";
import "./gamestabs.scss";
import "./gamesflowphotos.scss";
import "./selectedgames.scss";
import "./questionone.scss";
import "./creatingmaterial.scss";
import { Container, Row, Button, Form, Modal } from "react-bootstrap";

import CloseIcon from "@mui/icons-material/Close";
import logo from "../assets/images/logo.png";
import gleryfiles2 from "../assets/images/files-glery2.jpg";
import playvd from "../assets/images/play-vd.png";
import ClearIcon from "@mui/icons-material/Clear";
// import gamesgalery1 from "../assets/images/games-galery1.jpg";
// import gamesgalery2 from "../assets/images/games-galery2.jpg";
// import chaticon from "../assets/images/chat-icon.svg";
// import batchorange from "../assets/images/batch-orange.svg";
// import palinblue from "../assets/images/palin-blue.svg";
// import mike from "../assets/images/mike.svg";
// import attachemtnblue from "../assets/images/attachemtn-blue.svg";
// import downarrowblue from "../assets/images/down-arrow-blue.svg";
// import dotthree from "../assets/images/dotthree.svg";
// import addbtnround from "../assets/images/add-btn.svg";
// import darkblueplan from "../assets/images/darkblue-plan.svg";
// import backorange from "../assets/images/backorange.svg";
import editorangelg from "../assets/images/editlg-orange.svg";
// import placeholder from "../assets/images/placeholder.jpg";

// import Slider from "react-slick";
// import SliderCenter from "./Slider";
import { Link, useHistory, useLocation } from "react-router-dom";
import FooterEducator from "./layout/Footer/FooterEducator";
// import Addgroup from "./Addgroup";
// import Addpupil from "./Addpupil";
// import SendInvite from "./SendInvite";
// import Groupmember from "./Groupmember";
import HeaderGames from "./layout/Header/HeaderGames";
import FileUpload from "./FileUpload";
import { useEffect } from "react";
import {
  getMaterialliniList,
  getS3SignedUrl,
  postMaterialGame,
} from "../service/apis/materials";
import { Box, IconButton } from "@mui/material";
import { CustomSnackbar, useSnackState } from "../utils/useSnackState";
import { Label } from "@mui/icons-material";
import { StyledInput } from "./utils/InputFields";
import { getEducatorStuGrp } from "../service/apis/educator";
import DragDropMaterial from "./DragDropMaterial";
import DeadlineMpdal from "./dialogs/DeadlineMpdal";
import { getSingalActivities } from "../service/apis/activities";

function CreatingMaterialsModify() {
  const [snackProps, open] = useSnackState();
  const history = useHistory();
  const location = useLocation();
  const [files, setFiles] = useState([]);

  const [students, setStudent] = useState([]);
  const [selectStudents, setSelectStudents] = useState([]);
  const [querySearch, setquerySearch] = useState("");
  const [deadline, setdeadline] = useState(new Date());
  const [selectedGames, setselectedGames] = useState([]);
  const [videoShow, setViderShow] = useState(false);
  const [videolist, setvideoList] = useState([]);
  const [uploadedMaterials, setUploadedMaterials] = useState([]);
  /* start modal */
  const [modalShow, setModalShow] = React.useState(false);

  console.log("location.state", location.state);
  /* end modal */

  /* startsave gamed step1 */
  const [modalgamesavedstep1Show, setgamesavedstep1ModalShow] =
    React.useState(false);
  const [modalgamesavedstep2Show, setgamesavedstep2ModalShow] =
    React.useState(false);

  const submitData = async () => {
    if (uploadedMaterials.length === 0) {
      open("Add atleast 1 material to save", "error");
      return;
    }
    try {
      const updatedFormData = new FormData();
      console.log("uploadedMaterials", uploadedMaterials);

      let Materials = uploadedMaterials.filter((e) => e.materialType === "new");
      console.log("Materialsss", Materials);

      let illumiyaMaterisl = uploadedMaterials.filter(
        (e) => e.materialType === "illumiya",
      );
      console.log("Materialsss illumiya", illumiyaMaterisl);

      let dataToSave = {
        gameId: location.state.gameId,
        materials: [
          {
            position: 0,
            fileURL:
              "https://neith-materials.s3.ap-south-1.amazonaws.com/profile-detail-header-mb.png",
            fileName: "test1",
            prefix: "png",
          },
        ],
      };

      updatedFormData.append("GameId", location.state.gameId);
      Materials.forEach((m, i) => {
        updatedFormData.append(`Materials[${i}].position`, m.gamePosition);
        updatedFormData.append(`Materials[${i}].material`, "123.com");
      });

      illumiyaMaterisl.forEach((m, i) => {
        updatedFormData.append(
          `IllumiyaMaterials[${i}].position`,
          m.gamePosition,
        );
        updatedFormData.append(
          `IllumiyaMaterials[${i}].materialId`,
          m.fileData.id,
        );
      });

      const res = await postMaterialGame(dataToSave);
      console.log(res);
      if (res) {
        open("material saved successfully!", "success");
        setTimeout(() => history.push("/Mymaterials"), 3000);
      }
    } catch (e) {
      console.log("error in material", e);
      open("material failed to save", "error");
    }
  };

  const getVideoList = async () => {
    const res = await getMaterialliniList();
    console.log("getMaterialliniList", res.rows);
    setvideoList(res.rows);
  };

  console.log(videolist);

  const handleSelectGame = (game) => {
    console.log("uploadedMaterials", uploadedMaterials);

    const isSelected = Boolean(
      uploadedMaterials.find((m) => m?.fileData?.id === game.id),
    );
    console.log("isselected2", isSelected);

    if (isSelected) {
      setUploadedMaterials(
        uploadedMaterials.filter((item) => item?.fileData?.id !== game.id),
      );
    } else {
      setUploadedMaterials((pre) => [
        ...pre,
        {
          fileData: game,
          gamePosition: uploadedMaterials.length,
          materialType: "illumiya",
        },
      ]);
    }
  };

  const handeledRemoved = (game) => {
    setselectedGames(selectedGames.filter((item) => item.id !== game.id));
  };

  const searchedStudentsGroups = students.filter((stugrp) => {
    return stugrp.name
      ?.toLowerCase()
      .trim()
      .includes(querySearch.toLowerCase());
  });

  const selectStudent = (studentId) => {
    const isSelected = Boolean(
      selectStudents.find((grpStud) => grpStud?.fileData?.id === studentId.id),
    );
    if (isSelected) {
      setSelectStudents(
        selectStudents.filter((item) => item.id !== studentId.id),
      );
    } else {
      setSelectStudents((pre) => [...pre, studentId]);
    }
  };

  console.log(selectedGames);

  const sendActivite = () => {
    // const res = activitiesSendOneGame(currentUser.applicationUser.id,deadline,selectStudents,state.game)
    // if(res){
    //   history.push("/GamesTabs")
    //   open("activites send", "success")
    // }
  };

  const educatorStudentGroup = async () => {
    const res = await getEducatorStuGrp();
    setStudent(res);
  };

  const getMaterialUploadUrl = async () => {
    try {
      const response = await getSingalActivities(
        5,
        location.state.gameId,
        false,
      );

      console.log("getMaterialUploadUrl", response);
    } catch (e) {
      console.log("error in getMaterialUploadUrl", e);
    }
  };
  useEffect(() => {
    getMaterialUploadUrl();
  }, []);

  const handeledRemovedMaterial = (game) => {
    setFiles(files.filter((item) => item.name !== game.name));
  };

  console.log("locationstste", location.state);
  useEffect(() => {
    if (!location.state) {
      history.push("/Mymaterials");
    }
    getVideoList();
    educatorStudentGroup();
  }, []);

  console.log("filess", uploadedMaterials);

  const testFileUploadToS3 = async () => {
    try {
      const url = await getS3SignedUrl(uploadedMaterials[0].fileData.name);
      console.log("url", url);
      await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: uploadedMaterials[0].fileData,
      });
      const imageUrl = url.split("?")[0];
      console.log("imageUrl", imageUrl);
    } catch (e) {}
  };

  return (
    <div className="home-wrapper">
      {/* start header */}
      <CustomSnackbar {...snackProps} />
      <HeaderGames setModalShow={(value) => setModalShow(value)} />
      {/* end header */}

      <div className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs">
        <div className="games-photosynths">
          <Container>
            <div className="games-photosth-main gamesflows-twobx gamesflows-nwbox ">
              <div className="games-photsth-header">
                <h1>{location?.state?.title}</h1>

                {/* <Link to="#" className="editbtn-lg">
                  <img src={editorangelg} alt=""/>
                </Link> */}
              </div>
              <div className="questionone-main creating-material-main">
                <div className="iner-questionbx">
                  <div className="questionform">
                    <div className="form-questionbx">
                      <FileUpload
                        uploadedMaterials={uploadedMaterials}
                        setUploadedMaterials={setUploadedMaterials}
                      />
                      <DragDropMaterial
                        uploadedMaterials={uploadedMaterials}
                        setUploadedMaterials={setUploadedMaterials}
                      />
                      <div className="drag-file">
                        <div
                          className="drag-file"
                          style={{ textAlign: "left" }}
                        >
                          {/* <h5>{files?.name}</h5> */}
                          {/* <Form.Control
                            type="file"
                            multiple
                            id="uploadfile-drag"
                            onChange={handelFileChange}
                            style={{ display: "none" }}
                          />
                          <Form.Label
                            className="choosefile-now btn-week"
                            htmlFor="uploadfile-drag"
                          >
                            <div href="#" className="btnweak-bx">
                              <span className="btn-title"> choose files</span>
                            </div>
                          </Form.Label> */}
                        </div>
                      </div>

                      <Box>
                        <Row>
                          {files.map((item, i) => (
                            <div className="col-md-4" key={i}>
                              <Box
                                sx={{
                                  border: "1px solid gray",
                                  borderRadius: "1rem",
                                  py: "0.7rem",
                                  px: "0.8rem",
                                  position: "relative",
                                  display: "flex",
                                  alignItems: "flex-start",
                                  justifyContent: "flex-start",
                                  marginBottom: "1.5rem",
                                }}
                              >
                                <p
                                  style={{
                                    width: "90%",
                                    textAlign: "start",
                                    margin: "0",
                                    padding: "0",
                                  }}
                                >
                                  {item.name}
                                </p>

                                <IconButton
                                  sx={{
                                    position: "absolute",
                                    top: "4px",
                                    right: "5px",
                                  }}
                                  onClick={() => handeledRemovedMaterial(item)}
                                >
                                  <ClearIcon />
                                </IconButton>
                              </Box>
                            </div>
                          ))}
                        </Row>
                      </Box>

                      <Box>
                        <Row>
                          {selectedGames.map((item, i) => {
                            return (
                              <div
                                className="col-md-3"
                                style={{ transition: "0.4s" }}
                                key={i}
                              >
                                <div className="sliderbox">
                                  <div className="slider-video-material">
                                    <div className="slider-iner-videomatrl">
                                      <div className="innergalery-file">
                                        <img
                                          src={gleryfiles2}
                                          className="img-fluid"
                                          alt="gallery"
                                        />
                                      </div>
                                      <div className="plynow-btn">
                                        <span className="playvd-btn">
                                          <img
                                            src={playvd}
                                            className="img-fluid"
                                            alt="playvd"
                                          />
                                        </span>
                                      </div>
                                      <div className="resize-btn">
                                        <button
                                          onClick={() => handeledRemoved(item)}
                                          className="btn-resize-video"
                                        >
                                          <i className="fas fa-times"></i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Row>
                      </Box>

                      {
                        <div className="question-btom-btn material-video-btn">
                          <ul>
                            <li>
                              <div className="btn-buy gamestart-btnn">
                                <div className="btn-week">
                                  <button
                                    href="Videosmaterialadd"
                                    className="btnweak-bx"
                                    style={{ border: "none" }}
                                    onClick={() => setViderShow((pre) => !pre)}
                                  >
                                    <span className="btn-title">
                                      Add video from Neithedu materials
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="btn-buy gamestart-btnn">
                                <div className="btn-week">
                                  <a href="#" className="btnweak-bx">
                                    <span className="btn-title">
                                      Record video by camera
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      }

                      <div className="question-btom-btn">
                        <ul>
                          <li>
                            <div className="btn-buy gamestart-btnn">
                              <div className="btn-week">
                                <button
                                  onClick={() => submitData()}
                                  className="btnweak-bx"
                                  style={{ border: "none" }}
                                >
                                  <span className="btn-title">
                                    Save the material
                                  </span>
                                </button>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button onClick={testFileUploadToS3}>test material</button>
          </Container>
          <Box
            sx={{
              boxShadow: "2px 2px 5px 1px black",
              background: "#fff",
              height: "100%",
              position: "fixed",
              top: "0",
              width: "40%",
              zIndex: "999",
              transition: "0.4s",
            }}
            style={videoShow ? { right: "0px" } : { right: "-100%" }}
          >
            <Box
              sx={{
                position: "relative",
                padding: "2rem",
              }}
            >
              <IconButton
                sx={{
                  position: "absolute",
                  top: "20px",
                  left: "20px",
                }}
                onClick={() => setViderShow(false)}
              >
                <CloseIcon />
              </IconButton>
              <Box
                sx={{
                  width: "100%",
                  height: "80%",
                  background: "#fff",
                  marginTop: "30px",
                }}
              >
                <div
                  className="card-quizgames gamesflw-cardchek"
                  style={{ maxHeight: "900px" }}
                >
                  <Row>
                    {videolist?.map((item, i) => {
                      const isSelected = Boolean(
                        uploadedMaterials.find(
                          (m) => m?.fileData?.id === item.id,
                        ),
                      );
                      console.log("isSelected", isSelected);
                      return (
                        <div className="col-md-6" key={i}>
                          <div className="" style={{ marginBottom: "20px" }}>
                            <label className="custm-check gameflow-check">
                              <Form.Control
                                onChange={() => handleSelectGame(item)}
                                type="checkbox"
                                checked={isSelected}
                              />
                              <span className="checkmark"></span>
                              <Box
                                className="inner-bodyquiz"
                                sx={{
                                  borderRadius: "16px",
                                }}
                              >
                                {item.materialContentType === "Image" ? (
                                  <img
                                    src={gleryfiles2}
                                    className="img-fluid"
                                    alt="gallery"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      borderRadius: "10px",
                                    }}
                                  />
                                ) : (
                                  // <video width="100%" height="100%">
                                  // <source src="movie.mp4" type="video/mp4" />
                                  // </video>
                                  <img
                                    src={gleryfiles2}
                                    className="img-fluid"
                                    alt="gallery"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      borderRadius: "10px",
                                    }}
                                  />
                                )}

                                {item.materialContentType === "Image" ? (
                                  ""
                                ) : (
                                  <div className="plynow-btn">
                                    <span className="playvd-btn">
                                      <img
                                        src={playvd}
                                        className="img-fluid"
                                        alt="playvd"
                                        style={{
                                          width: "50%",
                                          height: "50%",
                                          marginTop: "20px",
                                        }}
                                      />
                                    </span>
                                  </div>
                                )}
                              </Box>
                            </label>
                          </div>
                        </div>
                      );
                    })}
                  </Row>
                </div>
              </Box>
            </Box>
          </Box>
        </div>

        {/* footer start */}
        <FooterEducator logo={logo} />
        {/* footer end */}
      </div>

      {/* start modal my student */}

      {/* start game save step1 */}

      {/* start game save step1 */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        // {...props}
        show={modalgamesavedstep1Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2 mystudent-games">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>The game is saved</h4>
              <h5>Send the game to my students</h5>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setgamesavedstep1ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="serch-inner-group">
                    <div className="serchfiled-activities">
                      <Form.Control
                        type="text"
                        placeholder="Start typing name or year of study"
                        className="formbox"
                        value={querySearch}
                        onChange={(e) => setquerySearch(e.target.value)}
                      />
                      <Button type="button" className="seachbtn">
                        <i className="fas fa-search"></i>
                      </Button>
                    </div>
                  </div>

                  <div className="newpupil-list-bx">
                    <Form.Group className="subsform-fild child-tex-fld height-max-flow">
                      <div className="child-boxnew">
                        {searchedStudentsGroups?.map((item, i) => {
                          const isSelected = Boolean(
                            selectStudents.find(
                              (grpStud) => grpStud.id === item.id,
                            ),
                          );

                          return (
                            <div className="child-account" key={i}>
                              <div className="activ-detail-list">
                                <label className="custm-check">
                                  <Form.Control
                                    type="checkbox"
                                    checked={isSelected}
                                    onChange={() => {
                                      selectStudent(item);
                                    }}
                                  />
                                  <span className="checkmark"></span>
                                  <div className="active-detail-row">
                                    <div className="activiti-prof-icon">
                                      <span className="profile-icon-box">
                                        {item.isGroup ? (
                                          <i className="fas fa-users"></i>
                                        ) : (
                                          <i className="fas fa-user"></i>
                                        )}
                                      </span>
                                    </div>
                                    <div className="activiti-center-detail">
                                      <h5>{item.name}</h5>
                                      {/* <h6>India, Mumbai 1 year of study</h6> */}
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </Form.Group>
                  </div>
                </Form.Group>

                <div className="student-btn-savedgame">
                  <div className="btnlogin-main">
                    <button
                      type="submit"
                      className="btnlogin btnweak-bx"
                      onClick={() => {
                        if (selectStudents.length === 0) {
                          open("select a item and try again", "error");
                        } else {
                          setgamesavedstep1ModalShow(false);
                          setgamesavedstep2ModalShow(true);
                        }
                      }}
                    >
                      Send to selected students
                    </button>
                  </div>
                </div>
                <div className="btnlogin-main">
                  <button
                    onClick={() => {
                      history.push("/Mymaterials");
                    }}
                    type="submit"
                    className="btnlogin btnweak-bx"
                  >
                    Later
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end Add a Member Group step2 */}

      <DeadlineMpdal
        open={modalgamesavedstep2Show}
        closeModal={() => {
          setgamesavedstep2ModalShow(false);
          setgamesavedstep1ModalShow(true);
        }}
        snackProps={snackProps}
        deadline={deadline}
        setdeadline={setdeadline}
        submitHandler={() => sendActivite()}
        openSnack={open}
      />

      {/* end modal my student */}

      {/* end Add a Member Group step2 */}

      {/* end modal my student */}
    </div>
  );
}

export default CreatingMaterialsModify;
