import { Box } from "@mui/material";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import "./FileUpload.scss";
import ReactPlayer from "react-player";
import fileImg from "../assets/images/file-material.png";

const DragDropMaterial = ({ uploadedMaterials, setUploadedMaterials }) => {
  const [dragItem, setdragItem] = useState(-1);

  const onDragStartHandler = (e) => {
    setdragItem(e.source.index);
  };

  const onDragEndHandler = async (e) => {
    if (!e.destination) return;
    setdragItem(-1);
    let _flowItems = [...uploadedMaterials];

    const temp = uploadedMaterials[e.source.index];
    _flowItems[e.source.index] = uploadedMaterials[e.destination.index];
    _flowItems[e.destination.index] = temp;

    _flowItems = _flowItems.map((item, index) => {
      console.log("item", item);
      return {
        ...item,
        gamePosition: index,
      };
    });
    console.log("_flowItems", _flowItems);

    setUploadedMaterials(_flowItems);
  };

  const handleRemoveItem = (idx) => {
    let _flowItems = [...uploadedMaterials];
    _flowItems.splice(idx, 1);
    // map and set gamePosition
    _flowItems = _flowItems.map((item, index) => {
      return {
        ...item,
        gamePosition: index,
      };
    });
    setUploadedMaterials(_flowItems);
  };

  console.log("uploadedMaterials", uploadedMaterials);

  return (
    <>
      {/* onClick={() => handleRemoveItem(idx)} */}

      {uploadedMaterials.length > 0 && (
        <div className="flowlisting-view">
          <DragDropContext
            onDragStart={onDragStartHandler}
            onDragEnd={onDragEndHandler}
          >
            <Droppable droppableId="gameflowcomp" direction="horizontal">
              {(provider) => (
                <ul
                  {...provider.droppableProps}
                  ref={provider.innerRef}
                  className="quizflow-list"
                >
                  {uploadedMaterials.map((game, idx, arr) => {
                    let itemToRender = null;
                    if (
                      game.materialType === "new" &&
                      game.fileData.type.includes("image")
                    ) {
                      itemToRender = (
                        <div
                          style={{
                            position: "relative",
                            maxWidth: "100px",
                          }}
                        >
                          <p
                            className="cross-icon"
                            onClick={() => handleRemoveItem(idx)}
                          >
                            <i className="fa fa-times"></i>
                          </p>
                          <img
                            className="lockquiz-icon drag-drop-material"
                            src={game.fileData.preview}
                          />
                        </div>
                      );
                    } else if (
                      game.materialType === "new" &&
                      game.fileData.type.includes("video")
                    ) {
                      itemToRender = (
                        <div className="drag-drop-react-player">
                          <div
                            style={{
                              position: "relative",
                              maxWidth: "100px",
                            }}
                          >
                            <p
                              className="cross-icon"
                              onClick={() => handleRemoveItem(idx)}
                            >
                              <i className="fa fa-times"></i>
                            </p>
                            <ReactPlayer
                              url={game.fileData.preview}
                              width={100}
                              height={100}
                              style={{ objectFit: "cover" }}
                              playing={true}
                              muted={true}
                              loop={true}
                            />
                          </div>
                          <div className="video-cover"></div>
                        </div>
                      );
                    } else {
                      if (game.materialType === "new") {
                        itemToRender = (
                          <div
                            style={{
                              position: "relative",
                              maxWidth: "100px",
                            }}
                          >
                            <p
                              className="cross-icon"
                              onClick={() => handleRemoveItem(idx)}
                            >
                              <i className="fa fa-times"></i>
                            </p>
                            <img
                              className="lockquiz-icon drag-drop-material"
                              style={{
                                backgroundColor: "white",
                              }}
                              src={fileImg}
                            />
                          </div>
                        );
                      }
                    }
                    if (game.materialType === "illumiya") {
                      if (game?.fileData?.materialContentType === "Image") {
                        itemToRender = (
                          <div
                            style={{
                              position: "relative",
                              maxWidth: "100px",
                            }}
                          >
                            <p
                              className="cross-icon"
                              onClick={() => handleRemoveItem(idx)}
                            >
                              <i className="fa fa-times"></i>
                            </p>
                            <img
                              className="lockquiz-icon drag-drop-material"
                              src={game.fileData.path}
                            />
                          </div>
                        );
                      } else if (
                        game?.fileData?.materialContentType === "Video"
                      ) {
                        itemToRender = (
                          <div className="drag-drop-react-player">
                            <div
                              style={{
                                position: "relative",
                                maxWidth: "100px",
                              }}
                            >
                              <p
                                className="cross-icon"
                                onClick={() => handleRemoveItem(idx)}
                              >
                                <i className="fa fa-times"></i>
                              </p>
                              <ReactPlayer
                                url={game.fileData.path}
                                width={100}
                                height={100}
                                style={{ objectFit: "cover" }}
                                playing={true}
                                muted={true}
                                loop={true}
                              />
                            </div>
                            <div className="video-cover"></div>
                          </div>
                        );
                      } else {
                        itemToRender = (
                          <div
                            style={{
                              position: "relative",
                              maxWidth: "100px",
                            }}
                          >
                            <p
                              className="cross-icon"
                              onClick={() => handleRemoveItem(idx)}
                            >
                              <i className="fa fa-times"></i>
                            </p>
                            <img
                              className="lockquiz-icon drag-drop-material"
                              style={{
                                backgroundColor: "white",
                              }}
                              src={fileImg}
                            />
                          </div>
                        );
                      }
                    }
                    return (
                      <Draggable
                        key={
                          game.materialType === "new"
                            ? `${game.fileData.name} + ${game.fileData.path} + ${game.fileData.type} + ${idx}`
                            : game.fileData.id.toString()
                        }
                        draggableId={
                          game.materialType === "new"
                            ? `${game.fileData.name} + ${game.fileData.path} + ${game.fileData.type} + ${idx}`
                            : game.fileData.id.toString()
                        }
                        index={idx}
                      >
                        {(provided) => (
                          <li
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            className="active"
                          >
                            <Box
                              sx={{
                                ":after": {
                                  backgroundColor:
                                    dragItem === idx
                                      ? "transparent !important"
                                      : "",
                                  top: "50px !important",
                                },
                              }}
                              className="listin-quizflow iconminus"
                            >
                              <div className="quixlok player-or-img-wrapper">
                                {itemToRender}
                              </div>
                              <div className="flw-quiz-content">
                                <div className="sml-tex">
                                  {game.materialType === "new"
                                    ? game.fileData.type
                                    : game.fileData.materialContentType}{" "}
                                  -{" "}
                                  {game.materialType === "new" &&
                                    game.fileData.size / 1000}
                                  KB
                                </div>
                                {game.materialType === "new" && (
                                  <div className="medm-tex">
                                    {game.fileData.name.slice(0, 7)}...
                                    {game.fileData.name.slice(-5)}
                                  </div>
                                )}
                              </div>
                            </Box>
                          </li>
                        )}
                      </Draggable>
                    );
                  })}
                  {provider.placeholder}

                  <li>
                    <div className="listin-quizflow">
                      <div className="quixlok"></div>
                    </div>
                  </li>
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
    </>
  );
};

export default DragDropMaterial;
