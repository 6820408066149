import { Alert, Portal, Snackbar } from "@mui/material";
import { useState } from "react";

export const useSnackState = () => {
  const [state, setstate] = useState({
    open: false,
    msg: "",
    variant: "warning",
  });

  const close = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setstate({ ...state, open: false });
  };
  const open = (msg, variant) => {
    setstate({ open: true, msg, variant });
  };

  const snackProps = {
    open: state.open,
    msg: state.msg,
    onClose: close,
    variant: state.variant,
  };

  return [snackProps, open];
};

export const CustomSnackbar = ({ msg, onClose, variant, open }) => {
  return (
    <Portal>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={3000}
        onClose={onClose}
      >
        <Alert onClose={onClose} severity={variant} sx={{ width: "100%" }}>
          {msg}
        </Alert>
      </Snackbar>
    </Portal>
  );
};
