import "./home.scss";
import "./profile.scss";
import "./media.scss";
import "./games.scss";
import "./myactivities.scss";
import { Container, Dropdown, Button, Form } from "react-bootstrap";
import { Tabs, TabList } from "react-tabs";

import logo from "../assets/images/logo.png";
import chaticon from "../assets/images/chat-icon.svg";

import { Link } from "react-router-dom";
import Footer from "./layout/Footer/Footer";
import { HeaderDashboard } from "./layout/HeaderDashboard";
import { useEffect, useState } from "react";
import {
  getAllActivitiesstudent,
  getGrpActivities,
  getStudentAllActivities,
} from "../service/apis/activities";
import { useQuery } from "@tanstack/react-query";
import ChooseActivityBar from "./educator/activity/ChooseActivityBar";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ActivityNotification from "./educator/activity/ActivityNotification";

function MyActivities() {
  const [querySearch, setquerySearch] = useState("");
  const [selectedStudentOrGroup, setSelectedStudentOrGroup] = useState(null);
  const history = useHistory();

  const {
    data: activitiesData,
    refetch: refetchActivities,
    isLoading: activitiesLoading,
  } = useQuery({
    queryKey: [
      "activities",
      selectedStudentOrGroup?.id,
      selectedStudentOrGroup?.educatorId || selectedStudentOrGroup?.studentId,
    ],
    queryFn: async () => {
      if (!selectedStudentOrGroup) return [];
      if (selectedStudentOrGroup?.isGroup) {
        console.log(
          "selectedStudentOrGroup before query",
          selectedStudentOrGroup,
        );
        return await getGrpActivities(
          selectedStudentOrGroup?.studentId, // studentId is passed as group id
          selectedStudentOrGroup?.id, // here, this is educator id
        );
      } else if (!selectedStudentOrGroup?.isGroup) {
        return await getAllActivitiesstudent(
          selectedStudentOrGroup?.studentId, // here it is student id
          selectedStudentOrGroup?.id, // this is educator id
        );
      }
    },
    enabled: !!selectedStudentOrGroup,
  });

  const { data: groupsAndStudentsList } = useQuery({
    queryKey: ["groupsAndStudentsList"],
    queryFn: async () => await getStudentAllActivities(),
  });

  const searchedStudentsGroups = groupsAndStudentsList
    ? groupsAndStudentsList?.filter((stugrp) => {
        return stugrp.name
          ?.toLowerCase()
          .trim()
          .includes(querySearch.toLowerCase());
      })
    : [];
  const location = useLocation();

  // when page loads, check if there is query param studentId, id and isGroup
  // if yes, then set selectedStudentOrGroup to that student or group
  // otherwise, set it to first student or group

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const studentId = params.get("studentId");
    const id = params.get("id");
    const isGroup = params.get("isGroup");
    if (!groupsAndStudentsList) return;

    if (studentId && id && isGroup) {
      const studentOrGroup = groupsAndStudentsList?.find(
        (stugrp) => +stugrp.studentId === +studentId && +stugrp.id === +id,
      );
      setSelectedStudentOrGroup(studentOrGroup);
    } else {
      setSelectedStudentOrGroup(groupsAndStudentsList?.[0]);
      history.push(
        `/MyActivities?studentId=${groupsAndStudentsList[0]?.studentId}&id=${groupsAndStudentsList[0]?.id}&isGroup=${groupsAndStudentsList[0]?.isGroup}`,
      );
    }
  }, [groupsAndStudentsList]);
  const params = new URLSearchParams(location?.search);
  const id = params?.get("id");

  // sort by creationDate. creation date has format DD-MM-YYYY
  let activitiesDataToMap = activitiesData?.sort((a, b) => {
    const dateA = a.creationDate.split("-").reverse().join("-");
    const dateB = b.creationDate.split("-").reverse().join("-");
    return new Date(dateB) - new Date(dateA);
  });
  console.log("activitiesdata", activitiesDataToMap);

  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderDashboard />
      {/* end header */}

      <div className="games-dashboard-main dashboard-bgnew blur-bgg">
        <div className="games-topbread">
          <Container>
            <nav aria-label="breadcrumb" className="breadcrumb-new">
              <ol className="breadcrumb common-navs">
                <li className="breadcrumb-item">
                  <Link to="#">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  My activities
                </li>
              </ol>
            </nav>

            <div className="diversity-box">
              <div className="title-box-diver">My activities</div>
              <div className="myactivites-main">
                <div className="myactivites-contain">
                  <Tabs>
                    <div className="activies-row">
                      <div className="left-pannel">
                        <div className="leftbx-activites">
                          <div className="acti-left-detail">
                            <div className="serchfiled-activities">
                              <Form.Control
                                type="text"
                                placeholder="Search"
                                className="formbox"
                                value={querySearch}
                                onChange={(e) =>
                                  setquerySearch(String(e.target.value))
                                }
                              />
                              <Button type="button" className="seachbtn">
                                <i className="fas fa-search"></i>
                              </Button>
                            </div>
                            <div className="activities-list-left">
                              <TabList className="tabslist-activiti">
                                {searchedStudentsGroups.map((stugrp, i) => (
                                  <ChooseActivityBar
                                    key={i}
                                    stugrp={stugrp}
                                    onSelection={() => {
                                      setSelectedStudentOrGroup(stugrp);
                                      history.push(
                                        `/MyActivities?studentId=${stugrp.studentId}&id=${stugrp.id}&isGroup=${stugrp.isGroup}`,
                                      );
                                    }}
                                  />
                                ))}
                                {searchedStudentsGroups.length === 0 && (
                                  <p>No data found</p>
                                )}
                              </TabList>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="right-panel">
                        <div className="activities-rightbx">
                          {
                            <div className="tabslist-content-act">
                              <div className="myacti-listtabs">
                                <div className="chat-headerbar">
                                  <div className="chat-topbar-list">
                                    <div className="activ-detail-list">
                                      <div className="active-detail-row">
                                        <div className="activiti-prof-icon">
                                          <span className="profile-icon-box">
                                            {selectedStudentOrGroup?.isGroup ? (
                                              <i className="fas fa-users"></i>
                                            ) : (
                                              <i className="fas fa-user"></i>
                                            )}
                                          </span>
                                        </div>
                                        <div className="activiti-center-detail">
                                          <h5>
                                            {selectedStudentOrGroup?.name}
                                          </h5>

                                          <h6>
                                            {
                                              selectedStudentOrGroup?.numberOfActivities
                                            }{" "}
                                            activities in progress
                                          </h6>
                                        </div>
                                        <div className="activiti-count-mesg">
                                          <div className="chat-right-droplist">
                                            <ul className="list-chatbx">
                                              <li className="chat-iconn">
                                                <div className="chatbtn">
                                                  <Link
                                                    to="#"
                                                    className="chatbtn-new"
                                                  >
                                                    {" "}
                                                    <img
                                                      src={chaticon}
                                                      className="img-fluid"
                                                    />
                                                  </Link>
                                                </div>
                                              </li>
                                              <li className="chat-dropright">
                                                <div className="droplist-right-btn">
                                                  <Dropdown className="dropdown-main">
                                                    <Dropdown.Toggle
                                                      variant="success"
                                                      id="dropdown-basic"
                                                      className="dropdown-btnn"
                                                    >
                                                      <i className="fas fa-ellipsis-v"></i>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu className="dropdown-btn-item">
                                                      <Dropdown.Item href="#">
                                                        <span className="rightarrow-icon">
                                                          <i className="fas fa-angle-right"></i>
                                                        </span>{" "}
                                                        All files
                                                      </Dropdown.Item>
                                                      <Dropdown.Item href="#">
                                                        <span className="rightarrow-icon">
                                                          <i className="fas fa-angle-right"></i>
                                                        </span>{" "}
                                                        Notifications
                                                      </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                  </Dropdown>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="live-lesson-listbtm">
                                  {console.log(
                                    "activitiesData",
                                    activitiesDataToMap,
                                  )}
                                  {console.log(
                                    "activitiesDataToMap",
                                    activitiesDataToMap,
                                  )}
                                  {activitiesDataToMap &&
                                    activitiesDataToMap.map((item, i) => {
                                      let linkToRedirect = "";
                                      if (!selectedStudentOrGroup.isGroup) {
                                        if (item.activityTypeId === 7) {
                                          linkToRedirect = `/PersonalTaskAnswer/${item.activityTypeId}/${item.id}`;
                                        } else if (item.activityTypeId === 8) {
                                          linkToRedirect = `student/AcceptLiveLesson/${item.activityTypeId}/${item.id}`;
                                        } else {
                                          linkToRedirect = `/PersonalGameAnswer/${item.activityTypeId}/${item.id}`;
                                        }
                                      } else {
                                        if (item.activityTypeId === 7) {
                                          linkToRedirect = `/PersonalTaskAnswer/${item.activityTypeId}/${item.id}`;
                                        } else if (item.activityTypeId === 8) {
                                          linkToRedirect = `student/AcceptLiveLesson/${item.activityTypeId}/${item.activityToPerformId}`;
                                        } else {
                                          linkToRedirect = `/PersonalGameAnswer/${item.activityTypeId}/${item.id}?isGroup=true`;

                                          // linkToRedirect = `/GroupGameAnswer/${item.activityTypeId}/${item.activityToPerformId}`;
                                        }
                                      }
                                      return (
                                        <ActivityNotification
                                          activity={item}
                                          linkToRedirect={linkToRedirect}
                                          isGroup={
                                            selectedStudentOrGroup.isGroup
                                          }
                                          key={i}
                                        />
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </Tabs>
                </div>
              </div>
            </div>
          </Container>
        </div>

        {/* footer start */}
        <Footer logo={logo} />
        {/* footer end */}
      </div>
    </div>
  );
}

export default MyActivities;
const TastStatus = {
  0: "Testing",
  1: "New",
  2: "Revision",
  3: "Done",
  4: "Late",
};
