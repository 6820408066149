import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CloseButton, StyledPurpleButton } from "../../utils/Buttons";
import { DialogBox } from "../../utils/DialogBox";
import DatePicker from "react-datepicker";
import {
  StyledSelectInputField,
  StyledTextField,
  StyledNumField,
} from "../../utils/InputFields";

export const PersonalTaskDialogs = ({
  closeHandler,
  name = "student name",
  sendHandler,
  type,
  _formData,
  _setformData,
}) => {
  const [newFormData, setNewFormData] = useState({
    title: "",
    essence: "",
    deadline: new Date(),
    points: 10,
    pointsStatus: "completed",
  });

  let formData;
  let setformData;
  if (type === "new") {
    formData = newFormData;
    setformData = setNewFormData;
  } else {
    formData = _formData;
    setformData = _setformData;
  }

  // convert MM-DD-YYYY to YYYY-MM-DD
  // let valueToPassToInputField = formData?.deadline?.split("-");
  // console.log("valueToPassToInputField", valueToPassToInputField);
  // if (
  //   valueToPassToInputField.length > 0 &&
  //   valueToPassToInputField[2]?.length === 4
  // ) {
  // }
  // valueToPassToInputField = `${valueToPassToInputField[2]}-${valueToPassToInputField[0]}-${valueToPassToInputField[1]}`;

  const Label = ({ children }) => {
    return (
      <Typography
        sx={{ fontWeight: "bold", mb: "0.5rem", color: "var(--black)" }}
      >
        {children}
      </Typography>
    );
  };
  return (
    <DialogBox isOpen={true}>
      <CloseButton closeHandler={closeHandler} />
      <Box
        sx={{
          my: "1rem",
          color: "var(--purple)",
        }}
      >
        {type === "new" ? (
          <>
            <Typography
              sx={{
                fontWeight: "800",
                fontSize: "1.3rem",
                textAlign: "center",
              }}
            >
              New personal task
            </Typography>
            <Typography
              sx={{
                fontWeight: "800",
                fontSize: "1.3rem",
                textAlign: "center",
              }}
            >
              to {name}
            </Typography>
          </>
        ) : (
          <Typography
            sx={{
              fontWeight: "800",
              fontSize: "1.3rem",
              textAlign: "center",
            }}
          >
            Edit Task
          </Typography>
        )}
      </Box>

      <Label>Title of the task</Label>
      <StyledTextField
        value={formData?.title}
        onChangeHandler={(e) =>
          setformData((state) => ({ ...state, title: e.target.value }))
        }
        placeholder={"Ex. Essay about animals"}
      />

      <Label>Essence of the task</Label>
      <StyledTextField
        value={formData?.essence}
        onChangeHandler={(e) =>
          setformData((state) => ({ ...state, essence: e.target.value }))
        }
        placeholder={
          "Write what exactly your student should do. Use simple words and clear explanation, give examples in order to make the task understandable"
        }
      />

      <Label>Deadline</Label>

      {/* <StyledInput
        props={{
          value: formData.deadline,
          onChange: dateChangeHandler,
          type: "date",
        }}
      /> */}
      <DatePicker
        selected={formData?.deadline}
        minDate={new Date() - 1}
        dateFormat="dd-MM-yyyy"
        className="dateformat"
        onChange={(date) =>
          setformData((state) => ({ ...state, deadline: date }))
        }
      />

      <Label>How many points student can earn for the task</Label>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <StyledNumField
          value={formData?.points}
          onChangeHandler={(e) =>
            setformData((state) => ({
              ...state,
              points: parseInt(e.target.value),
            }))
          }
          placeholder={20}
        />
        <Typography sx={{ width: "70%", textAlign: "center" }}>
          points if
        </Typography>
        <StyledSelectInputField
          value={formData?.pointsStatus}
          onChangeHandler={(e) =>
            setformData((state) => ({ ...state, pointsStatus: e.target.value }))
          }
          options={["Completed"]}
        />
      </Box>
      <Box
        sx={{
          mt: "1rem",
          width: "80%",
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
          rowGap: "1.5rem",
        }}
      >
        <StyledPurpleButton
          btnHandler={() => sendHandler(formData)}
          title={type === "new" ? `Send the task to ${name}` : "Update Task"}
        />
      </Box>
    </DialogBox>
  );
};

export const SelectStudents = ({
  studentsAndGroups = [],
  closeHandler,
  sendToSelectedStudentsHandler,
}) => {
  const [search, setSearch] = useState("");
  // const [selectStudent,setselectStudent]=useState([])
  const selectStudent = [];
  console.log(selectStudent);

  const selectStudentFun = (item) => {
    if (item.type === "group") {
      const exitingItem = selectStudent.find((it) => it.id === item.id);
      console.log(selectStudent);
      if (exitingItem) {
        const pppp = selectStudent.filter((i) => i.id !== item.id);
      } else {
        selectStudent.push(item);
      }
    } else {
      // const ddd = selectStudent.find(i=>i.studentId===item.studentId)
      // if(ddd){
      //   const pppp = selectStudent.find((i)=>i.studentId!==item.studentId)
      //   setselectStudent((state)=>({...state,pppp}))
      // }else{
      //   setselectStudent((state)=>({...state,item}))
      // }
    }
  };

  return (
    <Modal
      className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
      // {...props}
      show={true}
      onHide={() => {
        closeHandler();
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="login-modal-mainbx groupmbr-step2">
        <Modal.Header>
          <div className="btnback">
            <Link to="#" className="backbtn-icn">
              <i className="fas fa-arrow-left"></i>
            </Link>
          </div>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>{"The game is saved"}</h4>
            <p>Send the game to my students </p>
          </Modal.Title>
          <button type="button" onClick={closeHandler} className="close">
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="login-modl-form">
            <div className="loginform-bx">
              <Form.Group className="subsform-fild">
                <div className="serch-inner-group">
                  <div className="serchfiled-activities">
                    <Form.Control
                      type="text"
                      placeholder="Start typing name or year of study"
                      className="formbox"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <Button type="button" className="seachbtn">
                      <i className="fas fa-search"></i>
                    </Button>
                  </div>
                </div>

                <div className="newpupil-list-bx">
                  <Form.Group className="subsform-fild child-tex-fld height-max-flow">
                    <div className="child-boxnew">
                      {studentsAndGroups
                        .filter((item) => {
                          return search.toLocaleLowerCase() === ""
                            ? item
                            : item.name.toLocaleLowerCase().includes(search);
                        })
                        .map((item, i) => {
                          return (
                            <div className="child-account" key={i}>
                              <div className="activ-detail-list">
                                <label className="custm-check">
                                  <Form.Control
                                    onChange={() => selectStudentFun(item)}
                                    type="checkbox"
                                  />
                                  <span className="checkmark"></span>
                                  <div className="active-detail-row">
                                    <div className="activiti-prof-icon">
                                      <span className="profile-icon-box">
                                        {item.type === "student" ? (
                                          <i className="fas fa-user"></i>
                                        ) : (
                                          <i className="fas fa-users"></i>
                                        )}
                                      </span>
                                    </div>
                                    <div className="activiti-center-detail">
                                      <h5>{item.name}</h5>
                                      {/* <h6>{item.location}</h6> */}
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </Form.Group>
                </div>
              </Form.Group>

              <div className="btnlogin-main">
                <button
                  type="submit"
                  className="btnlogin btnweak-bx"
                  onClick={() => {
                    sendToSelectedStudentsHandler();
                  }}
                >
                  Add selected students
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};
