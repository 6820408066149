export const tastStatus = {
  0: "Testing",
  1: "New",
  2: "Revision",
  3: "Done",
  4: "Late",
  5: "checked",
  6: "InRevision",
  7: "Completed",
};

// 1: "Discrete",
// 2: "MCQ",
// 3: "NonDiscrete",
// 4: "Optional",
// 5: "TrueFalse",

// correct ones

// New=1,
// /// <summary>
// ///
// /// </summary>
// InProgress = 2,

// /// <summary>
// ///
// /// </summary>
// Done=3,

// /// <summary>
// ///
// /// </summary>
// Checking=4,

// /// <summary>
// ///
// /// </summary>
// Checked=5,
// InRevision=6,
// Completed=7,
// Cancelled=8,
// Timelapsed = 9,
// CancelledByStudent=10,
// CancelledByTutor = 11,
// AboutToStart = 12,

// /// <summary>
// ///
// /// </summary>
// Pending = 13,

// /// <summary>
// ///
// /// </summary>
// Late=14,
// /// <summary>
// ///
// /// </summary>
// TutorWaiting=15,
