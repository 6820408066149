import React from "react";
import "./home.scss";
import "./profile.scss";
import "./media.scss";
import "./games.scss";
import "./loginModal/login.scss";
import "./messages/messages.scss";
import "./myactivities.scss";
import "./gamestabs.scss";
import "./gamesflowphotos.scss";
import "./selectedgames.scss";
import "./questionone.scss";
import "./creatingmaterial.scss";
import "./Currentlesson.scss";
import { Container, Row, Button, Form, Modal } from "react-bootstrap";
import logo from "../assets/images/logo.png";
import backorange from "../assets/images/backorange.svg";
import lessonpic1 from "../assets/images/lesson-pic1.jpg";
import lessonpic2 from "../assets/images/lesson-pic2.jpg";
import attachment from "../assets/images/attachement.svg";
import plain from "../assets/images/plain.svg";
import playvd from "../assets/images/play-vd.png";
import { Link } from "react-router-dom";
import FooterEducator from "./layout/Footer/FooterEducator";
import HeaderGames from "./layout/Header/HeaderGames";

function Completedlessontwo() {
  const [modalShow, setModalShow] = React.useState(false);
  /* end modal */

  /* startsave gamed step1 */
  const [modalgamesavedstep1Show, setgamesavedstep1ModalShow] =
    React.useState(false);

  /* end save gamned step1 */

  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderGames setModalShow={(value) => setModalShow(value)} />
      {/* end header */}

      <div className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs">
        <div className="cureent-lession-bx completelession-main completelession-step2">
          <div className="current-lesson-main ">
            <Container>
              <div className="current-lesson-contain">
                <div className="backbtn">
                  <Link to="Newlivelessionthree" className="backbtnn-icnn">
                    <img src={backorange} alt="" />
                  </Link>
                </div>
                <div className="current-lesson-topbr">
                  <div className="medm-tex orng-clr">Live lesson</div>
                  <h1>
                    Work on mistakes of the test on the topic "Law and legal
                    aspects of business activities" from the work book, the
                    second part
                  </h1>
                  <div className="lesson-status-list">
                    <ul className="lesson-listing-curnt">
                      <li>
                        <div className="lesson-status-detail">
                          <span className="medm-tex orng-clr onelist">
                            Time:{" "}
                          </span>
                          <span className="medm-tex orng-clr twolist">
                            Oct 25, 11:00 am
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="lesson-status-detail">
                          <span className="medm-tex orng-clr onelist">
                            Status:{" "}
                          </span>
                          <span className="medm-tex orng-clr twolist">
                            Active
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="lesson-status-detail">
                          <span className="medm-tex orng-clr onelist">
                            Rate:{" "}
                          </span>
                          <span className="medm-tex orng-clr twolist">$15</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Container>
          </div>

          <div className="lesson-chatbx">
            <div className="custm-container">
              <div className="chatbox-main">
                <Row>
                  <div className="col-md-6 one">
                    <div className="leftvideo-box">
                      <div className="video-box-lessson">
                        <Row>
                          <div className="col-md-12 onepic">
                            <div className="video-glry">
                              <div className="videopic">
                                <img
                                  src={lessonpic1}
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                              <div className="plynow-btn">
                                <span className="playvd-btn">
                                  <img
                                    src={playvd}
                                    className="img-fluid"
                                    alt=""
                                  />
                                </span>
                              </div>
                              <div className="resize-btn">
                                <button className="btn-resize-video">
                                  <i className="fas fa-expand-alt"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 pictwo">
                            <div className="lesson-glrybx-new">
                              <div className="image-downl-sesion">
                                <img
                                  src={lessonpic2}
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 two">
                    <div className="chatbox-room">
                      <div className="chat-innerbox">
                        <div className="chat-upperbox">
                          <div className="custom-row">
                            <div className="chatbox-inner chat-sender">
                              <div className="message-box">
                                <div className="message-tex">
                                  Ut enim ad minim veniam, quis nostrud
                                  exercitation ullamco laboris nisi ut aliquip
                                  ex ea commodo consequat.
                                </div>
                                <div className="message-time">
                                  <span className="time">11.05</span>
                                </div>
                              </div>
                            </div>
                            <div className="chatbox-inner chat-receiver">
                              <div className="message-box">
                                <div className="message-tex">
                                  Ut enim ad minim veniam, quis nostrud
                                  exercitation ullamco laboris nisi ut aliquip
                                  ex ea commodo consequat.
                                </div>
                                <div className="message-time">
                                  <span className="time">11.05</span>
                                </div>
                              </div>
                            </div>
                            <div className="chatbox-inner chat-sender">
                              <div className="message-box">
                                <div className="message-tex">
                                  Ut enim ad minim veniam, quis nostrud
                                  exercitation ullamco laboris nisi ut aliquip
                                  ex ea commodo consequat.
                                </div>
                                <div className="message-time">
                                  <span className="time">11.05</span>
                                </div>
                              </div>
                            </div>
                            <div className="chatbox-inner chat-receiver">
                              <div className="message-box">
                                <div className="message-tex">
                                  Ut enim ad minim veniam, quis nostrud
                                  exercitation ullamco laboris nisi ut aliquip
                                  ex ea commodo consequat.
                                </div>
                                <div className="message-time">
                                  <span className="time">11.05</span>
                                </div>
                              </div>
                            </div>
                            <div className="chatbox-inner chat-sender">
                              <div className="message-box">
                                <div className="message-tex">
                                  Ut enim ad minim veniam, quis nostrud
                                  exercitation ullamco laboris nisi ut aliquip
                                  ex ea commodo consequat.
                                </div>
                                <div className="message-time">
                                  <span className="time">11.05</span>
                                </div>
                              </div>
                            </div>
                            <div className="chatbox-inner chat-receiver">
                              <div className="message-box">
                                <div className="message-tex">
                                  Ut enim ad minim veniam, quis nostrud
                                  exercitation ullamco laboris nisi ut aliquip
                                  ex ea commodo consequat.
                                </div>
                                <div className="message-time">
                                  <span className="time">11.05</span>
                                </div>
                              </div>
                            </div>
                            <div className="chatbox-inner chat-sender">
                              <div className="message-box">
                                <div className="message-tex">
                                  Ut enim ad minim veniam, quis nostrud
                                  exercitation ullamco laboris nisi ut aliquip
                                  ex ea commodo consequat.
                                </div>
                                <div className="message-time">
                                  <span className="time">11.05</span>
                                </div>
                              </div>
                            </div>
                            <div className="chatbox-inner chat-receiver">
                              <div className="message-box">
                                <div className="message-tex">
                                  Ut enim ad minim veniam, quis nostrud
                                  exercitation ullamco laboris nisi ut aliquip
                                  ex ea commodo consequat.
                                </div>
                                <div className="message-time">
                                  <span className="time">11.05</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="chat-footer">
                      <div className="chat-footer-main">
                        <div className="chat-footer-message">
                          <div className="message-send-new">
                            <Form.Control
                              as="textarea"
                              className="message-textarea"
                              placeholder="Your message"
                            />
                            <div className="right-chaticon">
                              <ul>
                                <li>
                                  <button className="sendme-btn ">
                                    <img
                                      src={plain}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </button>
                                </li>
                                <li>
                                  <button className="attachment-btn">
                                    <img
                                      src={attachment}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
            </div>
          </div>
        </div>

        {/* footer start */}
        <FooterEducator logo={logo} />
        {/* footer end */}
      </div>

      {/* start modal my student */}

      {/* start game save step1 */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        // {...props}
        show={modalgamesavedstep1Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2 mystudent-games">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>The game is saved</h4>
              <h5>Send the game to my students</h5>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setgamesavedstep1ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="serch-inner-group">
                    <div className="serchfiled-activities">
                      <Form.Control
                        type="text"
                        placeholder="Start typing name or year of study"
                        className="formbox"
                      />
                      <Button type="button" className="seachbtn">
                        <i className="fas fa-search"></i>
                      </Button>
                    </div>
                  </div>

                  <div className="newpupil-list-bx">
                    <Form.Group className="subsform-fild child-tex-fld height-max-flow">
                      <div className="child-boxnew">
                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Aaradhya Rayasam</h5>
                                  <h6>India, Mumbai 1 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Chandrashekhar Kalvakuntla</h5>
                                  <h6>India, Mumbai 3 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>7F Class</h5>
                                  <h6>Hamut Kapit, Kemal Mara and 12 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Additional group</h5>
                                  <h6>Laska Doe, Hamut Kapit and 4 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>8A Class for exams</h5>
                                  <h6>Hamut Kapit, Laska Doe and 8 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Chandrashekhar Kalvakuntla</h5>
                                  <h6>India, Mumbai 3 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </Form.Group>

                <div className="student-btn-savedgame">
                  <div className="btnlogin-main">
                    <button type="submit" className="btnlogin btnweak-bx">
                      Send to selected students
                    </button>
                  </div>
                </div>
                <div className="btnlogin-main">
                  <button type="submit" className="btnlogin btnweak-bx">
                    Later
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end Add a Member Group step2 */}

      {/* end modal my student */}
    </div>
  );
}

export default Completedlessontwo;
