import React from "react";
import "../../home.scss";
import "../../profile.scss";
import "../../media.scss";
import "../../games.scss";
import "../../myactivities.scss";
import "../../Gameflow.scss";
import logo from "../../../assets/images/logo.png";
import Footer from "../../layout/Footer/Footer";
import { HeaderDashboard } from "../../layout/HeaderDashboard";

function Gameflow() {
  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderDashboard />
      {/* end header */}

      <div className="games-dashboard-main dashboard-bgnew blur-bgg">
        <div className="games-topbread gamesflow-section">
          <div className="contain-custm">
            <div className="gamesflow-main">
              <div className="gameflow-card">
                <div className="close-iconbtn">
                  <button className="btnclose-glry"> &#x2716;</button>
                </div>
                <div className="gameflow-innercard">
                  <div className="title">ComputerFlow</div>
                  <div className="subtitle">max 50 points</div>
                  <div className="gameflow-content">
                    <p>
                      Description of the game lorem ipsum dolor sit amet,
                      consectetur adipiscing elit, sed do eiusmod tempor
                      incididunt ut labore et dolore magna aliqua. Ut enim ad
                      minim veniam, quis nostrud exercitation ullamco laboris
                      nisi ut aliquip ex ea commodo consequat. Duis aute irure
                      dolor in reprehenderit in voluptate velit esse cillum
                      dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                      cupidatat non proident, sunt in culpa qui officia deserunt
                      mollit anim id est laborum.
                    </p>
                  </div>
                </div>
                <div className="flow-listview">
                  <div className="flowlisting-view">
                    <ul className="quizflow-list">
                      <li className="active">
                        <div className="listin-quizflow">
                          <div className="quixlok">
                            <span className="lockquiz-icon">
                              <i className="fas fa-unlock"></i>
                            </span>
                          </div>
                          <div className="flw-quiz-content">
                            <div className="sml-tex">Quiz</div>
                            <div className="medm-tex">Single-celled</div>
                          </div>
                        </div>
                      </li>

                      <li>
                        <div className="listin-quizflow">
                          <div className="quixlok">
                            <span className="lockquiz-icon">
                              <i className="fas fa-lock"></i>
                            </span>
                          </div>
                          <div className="flw-quiz-content">
                            <div className="sml-tex">Crossword</div>
                            <div className="medm-tex">Creatures</div>
                          </div>
                        </div>
                      </li>

                      <li>
                        <div className="listin-quizflow">
                          <div className="quixlok">
                            <span className="lockquiz-icon">
                              <i className="fas fa-lock"></i>
                            </span>
                          </div>
                          <div className="flw-quiz-content">
                            <div className="sml-tex">Wordsearch</div>
                            <div className="medm-tex">Organism</div>
                          </div>
                        </div>
                      </li>

                      <li>
                        <div className="listin-quizflow">
                          <div className="quixlok">
                            <span className="lockquiz-icon">
                              <i className="fas fa-check"></i>
                            </span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="flowgame-btnn">
                    <div className="btn-buy gamestart-btnn">
                      <div className="btn-week">
                        <a href="#" className="btnweak-bx">
                          <span className="btn-title">Play the game</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* footer start */}
        <Footer logo={logo} />
        {/* footer end */}
      </div>
    </div>
  );
}

export default Gameflow;
