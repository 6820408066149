import React from "react";
import "../home.scss";
import "../profile.scss";
import "../media.scss";
import "../games.scss";
import "../loginModal/login.scss";
import "../myactivities.scss";
import "../Parentsaccount.scss";
import "../childrenprogress.scss";
import "../childrenprogressdetail.scss";
import "../Studentreport.scss";
import { Container, Row, Form, Modal } from "react-bootstrap";

import logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import Headerparents from "../layout/Header/Headerparents";
import FooterParent from "../layout/Footer/FooterParent";
import Addmychild from "../Addmychild";

function StudentReport() {
  /* start peroanl task*/
  const [modalShow, setModalShow] = React.useState(false);

  const [modalpersonaltskstep1Show, setpersonaltskstep1ModalShow] =
    React.useState(false);

  /* end persoanl task  */

  /* satrt add pupil modal */

  /* start Eductor signup step1 */
  const [modalAddmychildstep1Show, setAddmychildstep1ModalShow] =
    React.useState(false);
  /* end Eductor signup step1 */

  /* end add pupil modal */

  return (
    <div className="home-wrapper">
      {/* start header */}
      <Headerparents setModalShow={(value) => setModalShow(value)} />
      {/* end header */}

      <div className="games-dashboard-main dashboard-bgnew blur-bgg student-reports">
        <div className="games-topbread">
          <Container>
            <div className="student-report-popup">
              <div className="current-lesson-main ">
                <div className="current-lesson-contain">
                  <div className="close-flowgame">
                    <Link to="ChildProgressDetails" className="btnclose-flowgm">
                      <span aria-hidden="true">×</span>
                    </Link>
                  </div>
                  <div className="current-lesson-topbr">
                    <div className="medm-tex orng-clr">
                      Report from Neithedu Game: Quiz
                    </div>
                    <h1>Law basics. Beginner concepts</h1>
                    <div className="lesson-status-list">
                      <ul className="lesson-listing-curnt">
                        <li>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Correct answers:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              260/265{" "}
                            </span>
                          </div>
                        </li>
                        <li>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Time:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              4h 25min
                            </span>
                          </div>
                        </li>
                        <li>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Date of completion:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              Nov 16, 2021
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="question-scrlbar-bx">
                <div className="question-sheet">
                  <div className="questionbox-main beginer-box">
                    <div className="iner-quest-report">
                      <Row className="reportcard-box">
                        <div className="col-md-5 col-lg-4 one">
                          <div className="left-quedtions">
                            <div className="Question-listbx">
                              <div className="title">
                                <span className="bold">Question 1</span>
                                <span className="open-quest">
                                  Open question
                                </span>
                              </div>
                              <div className="question-contwnt">
                                <p>
                                  Question text Lorem ipsum dolor sit amet,
                                  consectetur adipiscing elit, sed do?
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7 col-lg-8 two">
                          <div className="answer-right">
                            <div className="aswer-details">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam
                              </p>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </div>

                    <div className="iner-quest-report">
                      <Row className="reportcard-box">
                        <div className="col-md-5 col-lg-4 one">
                          <div className="left-quedtions">
                            <div className="Question-listbx">
                              <div className="title">
                                <span className="bold">Question 2</span>
                                <span className="open-quest">True/False</span>
                              </div>
                              <div className="question-contwnt">
                                <p>
                                  Question text Lorem ipsum dolor sit amet,
                                  consectetur adipiscing elit, sed do?
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7 col-lg-8 two">
                          <div className="answer-right">
                            <div className="aswer-details">
                              <p>True</p>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </div>

                    <div className="iner-quest-report">
                      <Row className="reportcard-box">
                        <div className="col-md-5 col-lg-4 one">
                          <div className="left-quedtions">
                            <div className="Question-listbx">
                              <div className="title">
                                <span className="bold">Question 3</span>
                                <span className="open-quest">
                                  Open question
                                </span>
                              </div>
                              <div className="question-contwnt">
                                <p>
                                  Question text Lorem ipsum dolor sit amet,
                                  consectetur adipiscing elit, sed do?
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7 col-lg-8 two">
                          <div className="answer-right">
                            <div className="aswer-details">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam
                              </p>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </div>

                    <div className="iner-quest-report">
                      <Row className="reportcard-box">
                        <div className="col-md-5 col-lg-4 one">
                          <div className="left-quedtions">
                            <div className="Question-listbx">
                              <div className="title">
                                <span className="bold">Question 4</span>
                                <span className="open-quest">
                                  {" "}
                                  Multiple Choice
                                </span>
                              </div>
                              <div className="question-contwnt">
                                <p>
                                  Question text Lorem ipsum dolor sit amet,
                                  consectetur adipiscing elit, sed do?{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7 col-lg-8 two">
                          <div className="answer-right">
                            <div className="aswer-details">
                              <p>
                                b)consectetur adipiscing elit, sed do eiusmod
                                tempor
                              </p>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </div>

                    <div className="iner-quest-report">
                      <Row className="reportcard-box">
                        <div className="col-md-5 col-lg-4 one">
                          <div className="left-quedtions">
                            <div className="Question-listbx">
                              <div className="title">
                                <span className="bold">Question 5</span>
                                <span className="open-quest">
                                  Open question
                                </span>
                              </div>
                              <div className="question-contwnt">
                                <p>
                                  Question text Lorem ipsum dolor sit amet,
                                  consectetur adipiscing elit, sed do?
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7 col-lg-8 two">
                          <div className="answer-right">
                            <div className="aswer-details">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam
                              </p>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </div>

                    <div className="iner-quest-report incoret-match-box">
                      <Row className="reportcard-box">
                        <div className="col-md-5 col-lg-4 one">
                          <div className="left-quedtions">
                            <div className="Question-listbx">
                              <div className="title">
                                <span className="bold">Question 6</span>
                                <span className="open-quest">Matching</span>
                              </div>
                              <div className="question-contwnt">
                                <p>
                                  Question text Lorem ipsum dolor sit amet,
                                  consectetur adipiscing elit, sed do?
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7 col-lg-8 two">
                          <div className="answer-right">
                            <div className="aswer-details incoret-macth">
                              <div className="macthing-content">
                                <p>
                                  Lorem ipsum — dolor sit amet, Consectetur
                                  adipiscing elit — sed do eiusmod tempor
                                  Incididunt ut labore et dolore — magna aliqua
                                </p>
                              </div>
                              <div className="matching-btn">
                                <span className="matching-incoret">
                                  Incorrect
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </div>

                    <div className="iner-quest-report">
                      <Row className="reportcard-box">
                        <div className="col-md-5 col-lg-4 one">
                          <div className="left-quedtions">
                            <div className="Question-listbx">
                              <div className="title">
                                <span className="bold">Question 7</span>
                                <span className="open-quest">Matching</span>
                              </div>
                              <div className="question-contwnt">
                                <p>
                                  Question text Lorem ipsum dolor sit amet,
                                  consectetur adipiscing elit, sed do?
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7 col-lg-8 two">
                          <div className="answer-right">
                            <div className="aswer-details">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam
                              </p>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>

        {/* footer start */}
        <FooterParent logo={logo} />
        {/* footer end */}
      </div>

      {/* start add pupil modal */}

      <Addmychild
        modalAddmychildstep1Show={modalAddmychildstep1Show}
        setAddmychildstep1ModalShow={(value) =>
          setAddmychildstep1ModalShow(value)
        }
      />

      {/* end add pupil modal */}

      {/* start perosnal task aprent */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        // {...props}
        show={modalpersonaltskstep1Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2 mystudent-games persltask-form">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>
                New personal task to <br></br> Alexandra
              </h4>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setpersonaltskstep1ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="newgames-createbx">
                    <Row>
                      <div className="col-md-12">
                        <Form.Label>Title of the task</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Help your mother"
                        ></Form.Control>
                      </div>
                      <div className="col-md-12">
                        <Form.Label>Choose type of a task</Form.Label>
                        <div className="tasktype">
                          <ul className="learner-listing-games taskpurple">
                            <li>
                              <button className="btn-learner-lsit">
                                Do homework
                              </button>
                            </li>
                            <li>
                              <button className="btn-learner-lsit">
                                Do house work
                              </button>
                            </li>
                            <li>
                              <button className="btn-learner-lsit">Any</button>
                            </li>
                            <li>
                              <button className="btn-learner-lsit">
                                Another taskc
                              </button>
                            </li>
                            <li>
                              <button className="btn-learner-lsit active">
                                Custom task
                              </button>
                            </li>
                            <li>
                              <button className="btn-learner-lsit">More</button>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <Form.Label>Description </Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Describe your task"
                        />
                      </div>
                      <div className="col-md-12">
                        <Form.Label>Deadline</Form.Label>
                        <div className="datepick-frm datepicker-purple">
                          <Form.Group className="subsform-fild">
                            <Form.Control
                              type="date"
                              placeholder="From"
                              className="texblck"
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <Form.Label>
                          How many points child can earn for the task
                        </Form.Label>
                      </div>
                      <div className="col-md-4 std-point-frm">
                        <Form.Control
                          type="text"
                          placeholder="20"
                          className="texblck"
                        />
                      </div>
                      <div className="col-md-4 std-point-frm std-point-label">
                        <Form.Label>points if</Form.Label>
                      </div>
                      <div className="col-md-4 std-point-frm">
                        <Form.Control as="select" className="texblck">
                          <option value="2">completed</option>
                        </Form.Control>
                      </div>
                    </Row>
                  </div>
                </Form.Group>

                <div className="btnlogin-main taskbtn-uppr">
                  <Link
                    to="#"
                    className="btnlogin btnweak-bx"
                    onClick={() => setpersonaltskstep1ModalShow(false)}
                  >
                    Send the task to Alexandra
                  </Link>
                </div>

                <div className="btnlogin-main">
                  <Link to="#" className="btnlogin btnweak-bx">
                    Send to some children
                  </Link>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end personaltask */}
    </div>
  );
}

export default StudentReport;
