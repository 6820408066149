import React from "react";
import "./home.scss";
import "./profile.scss";
import "./media.scss";
import "./games.scss";
import "./loginModal/login.scss";
import "./messages/messages.scss";
import "./myactivities.scss";
import "./gamestabs.scss";
import "./gamesflowphotos.scss";
import {
  Container,
  Row,
  Button,
  Form,
  Modal,
} from "react-bootstrap";

import logo from "../assets/images/logo.png";
// import gamesgalery1 from "../assets/images/games-galery1.jpg";
// import gamesgalery2 from "../assets/images/games-galery2.jpg";
// import chaticon from "../assets/images/chat-icon.svg";
// import batchorange from "../assets/images/batch-orange.svg";
// import palinblue from "../assets/images/palin-blue.svg";
// import mike from "../assets/images/mike.svg";
// import attachemtnblue from "../assets/images/attachemtn-blue.svg";
// import downarrowblue from "../assets/images/down-arrow-blue.svg";
// import dotthree from "../assets/images/dotthree.svg";
// import addbtnround from "../assets/images/add-btn.svg";
// import darkblueplan from "../assets/images/darkblue-plan.svg";

// import Slider from "react-slick";
// import SliderCenter from "./Slider";
import { Link } from "react-router-dom";
import FooterEducator from "./layout/Footer/FooterEducator";
// import Addgroup from "./Addgroup";
// import Addpupil from "./Addpupil";
// import SendInvite from "./SendInvite";
// import Groupmember from "./Groupmember";
import HeaderGames from "./layout/Header/HeaderGames";

function Videosmaterialadd() {
  // const [show, setShow] = React.useState("1");

  
  // const [breadMenuShow, setBreadMenuShow] = React.useState(false);
  /* start modal */
  const [modalShow, setModalShow] = React.useState(false);
  /* end modal */

  /* start Add a Group step2 */
  const [modalAddgroupstep2Show, setAddgroupstep2ModalShow] =
    React.useState(false);

  /* end Add a Group step2 */

  /* start apply to publish */
  const [modalgroupmemberstep3Show, setgroupmemberstep3ModalShow] =
    React.useState(false);

  /* end apply to publish */

  /* start Thankx application*/
  const [modalthanksstep2Show, setthanksstep2ModalShow] = React.useState(false);

  /* end apply to publish */

  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderGames setModalShow={(value) => setModalShow(value)} />
      {/* end header */}

      <div className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs">
        <div className="gamestabs-main gamesflow-popup-bxx mymateial-main">
          <div className="flownew-gamesbx videomatrials-add">
            <Container>
              <div className="Minigames-bx addvideo-material">
                <div className="minigames-cards">
                  <div className="close-flowgame">
                    <Link to="Creatingmaterial" className="btnclose-flowgm">
                      <span aria-hidden="true">×</span>
                    </Link>
                  </div>
                  <div className="heading-top-flowgames">
                    <h4>Select Neithedu video materials to add </h4>
                  </div>
                  <div className="games-topserch-bar">
                    <Form>
                      <Row className="align-center">
                        <div className="col-md-3 one">
                          <div className="list-study">
                            <Form.Control as="select">
                              <option value="2">All years of study</option>
                            </Form.Control>
                          </div>
                        </div>
                        <div className="col-md-6 two">
                          <div className="serch-bx-games">
                            <div className="serchfiled-activities">
                              <Form.Control
                                type="text"
                                placeholder="Start typing title, subject or any other keyword "
                                className="formbox"
                              />
                              <Button type="button" className="seachbtn">
                                <i className="fas fa-search"></i>
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 three">
                          <div className="createnew-games">
                            <div className="game-opn-create">
                              <Link
                                to="Addmymaterials"
                                className="btnweak-bx gamestbs-btn"
                              >
                                Add to my material
                              </Link>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </Form>
                  </div>

                  <div className="card-quizgames gamesflw-cardchek">
                    <Row>
                      <div className="col-md-3 onecard">
                        <div className="inner-cardquizz">
                          <label className="custm-check gameflow-check">
                            <Form.Control type="checkbox" />
                            <span className="checkmark"></span>
                            <div className="inner-bodyquiz">
                              <div className="quiz-body-content">
                                <div className="quiz-bdy-content-bx">
                                  <h3>Title of the material</h3>
                                  <div className="type-material-iner">
                                    <p>Video</p>
                                  </div>
                                  <h5>Biology</h5>
                                  <h4>7th year of study</h4>
                                  <p>
                                    Small description about the game some words
                                  </p>
                                </div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>

                      <div className="col-md-3 onecard">
                        <div className="inner-cardquizz">
                          <label className="custm-check gameflow-check">
                            <Form.Control type="checkbox" />
                            <span className="checkmark"></span>
                            <div className="inner-bodyquiz">
                              <div className="quiz-body-content">
                                <div className="quiz-bdy-content-bx">
                                  <h3>Title of the material</h3>
                                  <div className="type-material-iner">
                                    <p>Video</p>
                                  </div>
                                  <h5>Biology</h5>
                                  <h4>7th year of study</h4>
                                  <p>
                                    Small description about the game some words
                                  </p>
                                </div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>

                      <div className="col-md-3 onecard">
                        <div className="inner-cardquizz">
                          <label className="custm-check gameflow-check">
                            <Form.Control type="checkbox" />
                            <span className="checkmark"></span>
                            <div className="inner-bodyquiz">
                              <div className="quiz-body-content">
                                <div className="quiz-bdy-content-bx">
                                  <h3>Title of the material</h3>
                                  <div className="type-material-iner">
                                    <p>10 Videos</p>
                                  </div>
                                  <h5>Biology</h5>
                                  <h4>7th year of study</h4>
                                  <p>
                                    Small description about the game some words
                                  </p>
                                </div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>

                      <div className="col-md-3 onecard">
                        <div className="inner-cardquizz">
                          <label className="custm-check gameflow-check">
                            <Form.Control type="checkbox" />
                            <span className="checkmark"></span>
                            <div className="inner-bodyquiz">
                              <div className="quiz-body-content">
                                <div className="quiz-bdy-content-bx">
                                  <h3>Title of the material</h3>
                                  <div className="type-material-iner">
                                    <p>Video</p>
                                  </div>
                                  <h5>Biology</h5>
                                  <h4>7th year of study</h4>
                                  <p>
                                    Small description about the game some words
                                  </p>
                                </div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>

                      <div className="col-md-3 onecard">
                        <div className="inner-cardquizz">
                          <label className="custm-check gameflow-check">
                            <Form.Control type="checkbox" />
                            <span className="checkmark"></span>
                            <div className="inner-bodyquiz">
                              <div className="quiz-body-content">
                                <div className="quiz-bdy-content-bx">
                                  <h3>Title of the material</h3>
                                  <div className="type-material-iner">
                                    <p>Video</p>
                                  </div>
                                  <h5>Biology</h5>
                                  <h4>7th year of study</h4>
                                  <p>
                                    Small description about the game some words
                                  </p>
                                </div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>

                      <div className="col-md-3 onecard">
                        <div className="inner-cardquizz">
                          <label className="custm-check gameflow-check">
                            <Form.Control type="checkbox" />
                            <span className="checkmark"></span>
                            <div className="inner-bodyquiz">
                              <div className="quiz-body-content">
                                <div className="quiz-bdy-content-bx">
                                  <h3>Title of the material</h3>
                                  <div className="type-material-iner">
                                    <p>Video</p>
                                  </div>
                                  <h5>Biology</h5>
                                  <h4>7th year of study</h4>
                                  <p>
                                    Small description about the game some words
                                  </p>
                                </div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>

                      <div className="col-md-3 onecard">
                        <div className="inner-cardquizz">
                          <label className="custm-check gameflow-check">
                            <Form.Control type="checkbox" />
                            <span className="checkmark"></span>
                            <div className="inner-bodyquiz">
                              <div className="quiz-body-content">
                                <div className="quiz-bdy-content-bx">
                                  <h3>Title of the material</h3>
                                  <div className="type-material-iner">
                                    <p>Video</p>
                                  </div>
                                  <h5>Biology</h5>
                                  <h4>7th year of study</h4>
                                  <p>
                                    Small description about the game some words
                                  </p>
                                </div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>

                      <div className="col-md-3 onecard">
                        <div className="inner-cardquizz">
                          <label className="custm-check gameflow-check">
                            <Form.Control type="checkbox" />
                            <span className="checkmark"></span>
                            <div className="inner-bodyquiz">
                              <div className="quiz-body-content">
                                <div className="quiz-bdy-content-bx">
                                  <h3>Title of the material</h3>
                                  <div className="type-material-iner">
                                    <p>2 Videos</p>
                                  </div>
                                  <h5>Biology</h5>
                                  <h4>7th year of study</h4>
                                  <p>
                                    Small description about the game some words
                                  </p>
                                </div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>

                      <div className="col-md-3 onecard">
                        <div className="inner-cardquizz">
                          <label className="custm-check gameflow-check">
                            <Form.Control type="checkbox" />
                            <span className="checkmark"></span>
                            <div className="inner-bodyquiz">
                              <div className="quiz-body-content">
                                <div className="quiz-bdy-content-bx">
                                  <h3>Title of the material</h3>
                                  <div className="type-material-iner">
                                    <p>Video</p>
                                  </div>
                                  <h5>Biology</h5>
                                  <h4>7th year of study</h4>
                                  <p>
                                    Small description about the game some words
                                  </p>
                                </div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>

                      <div className="col-md-3 onecard">
                        <div className="inner-cardquizz">
                          <label className="custm-check gameflow-check">
                            <Form.Control type="checkbox" />
                            <span className="checkmark"></span>
                            <div className="inner-bodyquiz">
                              <div className="quiz-body-content">
                                <div className="quiz-bdy-content-bx">
                                  <h3>Title of the material</h3>
                                  <div className="type-material-iner">
                                    <p>Video</p>
                                  </div>
                                  <h5>Biology</h5>
                                  <h4>7th year of study</h4>
                                  <p>
                                    Small description about the game some words
                                  </p>
                                </div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>

                      <div className="col-md-3 onecard">
                        <div className="inner-cardquizz">
                          <label className="custm-check gameflow-check">
                            <Form.Control type="checkbox" />
                            <span className="checkmark"></span>
                            <div className="inner-bodyquiz">
                              <div className="quiz-body-content">
                                <div className="quiz-bdy-content-bx">
                                  <h3>Title of the material</h3>
                                  <div className="type-material-iner">
                                    <p>Video</p>
                                  </div>
                                  <h5>Biology</h5>
                                  <h4>7th year of study</h4>
                                  <p>
                                    Small description about the game some words
                                  </p>
                                </div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>

                      <div className="col-md-3 onecard">
                        <div className="inner-cardquizz">
                          <label className="custm-check gameflow-check">
                            <Form.Control type="checkbox" />
                            <span className="checkmark"></span>
                            <div className="inner-bodyquiz">
                              <div className="quiz-body-content">
                                <div className="quiz-bdy-content-bx">
                                  <h3>Title of the material</h3>
                                  <div className="type-material-iner">
                                    <p>Video</p>
                                  </div>
                                  <h5>Biology</h5>
                                  <h4>7th year of study</h4>
                                  <p>
                                    Small description about the game some words
                                  </p>
                                </div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </Row>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>

        {/* footer start */}
        <FooterEducator logo={logo} />
        {/* footer end */}
      </div>

      {/* start modal my student */}

      {/* start Add a Groupmember step2 */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        // {...props}
        show={modalAddgroupstep2Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2 mystudent-games">
          <Modal.Header>
            <div className="btnback">
              <Link to="#" className="backbtn-icn">
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Title of the material</h4>
              <h5>Send to my students</h5>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setAddgroupstep2ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="serch-inner-group">
                    <div className="serchfiled-activities">
                      <Form.Control
                        type="text"
                        placeholder="Start typing name or year of study"
                        className="formbox"
                      />
                      <Button type="button" className="seachbtn">
                        <i className="fas fa-search"></i>
                      </Button>
                    </div>
                  </div>

                  <div className="newpupil-list-bx">
                    <Form.Group className="subsform-fild child-tex-fld height-max-flow">
                      <div className="child-boxnew">
                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Aaradhya Rayasam</h5>
                                  <h6>India, Mumbai 1 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Chandrashekhar Kalvakuntla</h5>
                                  <h6>India, Mumbai 3 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>7F Class</h5>
                                  <h6>Hamut Kapit, Kemal Mara and 12 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Additional group</h5>
                                  <h6>Laska Doe, Hamut Kapit and 4 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>8A Class for exams</h5>
                                  <h6>Hamut Kapit, Laska Doe and 8 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Chandrashekhar Kalvakuntla</h5>
                                  <h6>India, Mumbai 3 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </Form.Group>

                <div className="btnlogin-main">
                  <button type="submit" className="btnlogin btnweak-bx">
                    Send to selected students
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end Add a Member Group step2 */}

      {/* end modal my student */}

      {/* start apply to publish modal */}

      {/* start Add a Member Group step2 */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        // {...props}
        show={modalgroupmemberstep3Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2 publish-matrial">
          <Modal.Header>
            <div className="btnback">
              <Link to="#" className="backbtn-icn">
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Do you want to apply this material to publish?</h4>
              <p>
                The material will be reviewed by our experts. If the material is
                relevant and interesting, it will be posted on the site for
                general use and you will receive $20
              </p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setgroupmemberstep3ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="btn-delet-bx">
                    <ul>
                      <li>
                        <button
                          className="delet-ys btn-grup-class btnweak-bx cancel-btn"
                          type="submit"
                          onClick={() => {
                            setgroupmemberstep3ModalShow(false);
                            setthanksstep2ModalShow(true);
                          }}
                        >
                          Apply
                        </button>
                      </li>
                      <li>
                        <button
                          className="delet-no btn-grup-class btnweak-bx delbtn"
                          type="submit"
                          onClick={() => setgroupmemberstep3ModalShow(false)}
                        >
                          Cancel
                        </button>
                      </li>
                    </ul>
                  </div>
                </Form.Group>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end Add a Member Group step3 */}

      {/* mend apply to publish popup */}

      {/* start Thankas appliaction */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        // {...props}
        show={modalthanksstep2Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2 thanks-applictn">
          <Modal.Header>
            <div className="btnback">
              <Link to="#" className="backbtn-icn">
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Thanks! Your applications sent</h4>
              <p>You will receive notification with result of review</p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setthanksstep2ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="btn-delet-bx">
                    <button
                      className=" btnweak-bx btnlogin thanxbtn"
                      type="submit"
                      onClick={() => setthanksstep2ModalShow(false)}
                    >
                      Thanks, i understood
                    </button>
                  </div>
                </Form.Group>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end Thankas appliactionup */}
    </div>
  );
}

export default Videosmaterialadd;
