import React from "react";
import Footer from "../layout/Footer/Footer";
import { HeaderDashboard } from "../layout/HeaderDashboard";
import logo from "../../assets/images/logo.png";
import { Container } from "react-bootstrap";
// import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
// import BlockIcon from "@mui/icons-material/Block";
import { Box, Grid, Rating, Stack, Typography } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import SchoolIcon from "@mui/icons-material/School";
import PersonIcon from "@mui/icons-material/Person";
import WorkIcon from "@mui/icons-material/Work";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { StyledYellowButton } from "../utils/Buttons";
export const TutorProfilePage = ({ history }) => {
  const tutorDetails = {
    name: "Rajesh Koothrappali",
    gender: "Male",
    age: "36",
    location: "India, Mumbai", 
    about:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    subjects: ["Biology", "History", "English"],
    ageOfStudents: "10-16 y.o.",
    experience: 7,
    teachingHours: 74,
    noOfReviews: 80,
    overallRating: 4,
    reviews: [
      {
        name: "Asher Talbot",
        date: Date.now(),
        reviewMessage:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
        rating: 4,
        individualRating: {
          teachingSkills: 3,
          communication: 4,
          programQuality: 2,
        },
      },
      {
        name: "Asher Talbot",
        date: Date.now(),
        reviewMessage:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
        rating: 4,
        individualRating: {
          teachingSkills: 3,
          communication: 4,
          programQuality: 2,
        },
      },
    ],
  };

  const ReviewCard = ({ review }) => {
    return (
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "20px",
          width: "100%",
          p: "2rem",
          mb: "1rem",
        }}
      >
        <Grid justifyContent={"space-between"} container>
          <Grid item sm={7}>
            <Stack
              mb="1rem"
              direction="row"
              alignItems={"end"}
              columnGap={"2rem"}
            >
              <Typography
                sx={{
                  color: "var(--purple-dark)",
                  fontWeight: "700",
                  fontSize: "1.3rem",
                }}
              >
                {review.name}
              </Typography>
              <Typography sx={{ color: "var(--orange)" }}>
                14 Sep, 2021
              </Typography>
            </Stack>
            <Typography sx={{ lineHeight: "20px", color: "black" }}>
              {review.reviewMessage}
            </Typography>
          </Grid>
          <Grid item sm={3}>
            <Stack
              mb="1rem"
              alignItems="center"
              direction={"row"}
              columnGap="0.5rem"
            >
              <Typography
                sx={{
                  fontSize: "0.9rem",
                  color: "var(--purple-dark)",
                  fontWeight: "800",
                  borderRadius: "6px",
                }}
              >
                {Number(review.rating).toFixed(1)}
              </Typography>
              <Rating readOnly value={review.rating} />
            </Stack>
            <Stack rowGap={"0.7rem"}>
              <Stack direction="row" justifyContent={"space-between"}>
                <Typography sx={{ fontWeight: "700" }}>
                  Teaching skills
                </Typography>
                <Rating
                  readOnly
                  value={review.individualRating.teachingSkills}
                />
              </Stack>
              <Stack direction="row" justifyContent={"space-between"}>
                <Typography sx={{ fontWeight: "700" }}>
                  communication{" "}
                </Typography>
                <Rating
                  readOnly
                  value={review.individualRating.communication}
                />
              </Stack>
              <Stack direction="row" justifyContent={"space-between"}>
                <Typography sx={{ fontWeight: "700" }}>
                  Program quality
                </Typography>
                <Rating
                  readOnly
                  value={review.individualRating.programQuality}
                />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const PlainButton = ({ sx, children, onClick }) => (
    <Box
      sx={{
        cursor: "pointer",
        textAlign: "center",
        fontWeight: "700",
        color: "var(--purple-dark)",
        backgroundColor: "var(--yellow)",
        p: "0.3rem 0.5rem",
        borderRadius: "10px",
        width: "100%",
        ...sx,
      }}
      onClick={onClick}
    >
      {children}
    </Box>
  );
  return (
    <div className="home-wrapper">
      <HeaderDashboard />
      <div
        style={{ minHeight: "100vh" }}
        className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs"
      >
        <Container style={{ textAlign: "start" }}>
          <Box
            sx={{
              position: "relative",
              backgroundColor: "white",
              borderRadius: "1.2rem",
              p: "1rem",
            }}
          >
            <KeyboardBackspaceIcon
              onClick={() => history.goBack()}
              sx={{
                cursor: "pointer",
                position: "absolute",
                top: "5px",
                left: "10px",
                color: "var(--orange)",
                fontSize: "2rem",
              }}
            />
            <Grid container>
              <Grid item sm={3}>
                <Stack alignItems={"center"} justifyContent="center">
                  <img src="/images/profilepic.png" alt="profilepic" />
                </Stack>
              </Grid>

              <Grid item sm={6}>
                <Typography sx={{ fontWeight: "700", fontSize: "1.7rem" }}>
                  {tutorDetails.name}
                </Typography>
                <Grid container>
                  <Grid item xs={3}>
                    <Stack direction={"row"} columnGap="0.5rem">
                      <SchoolIcon sx={{ color: "var(--orange)" }} />{" "}
                      <Typography
                        sx={{
                          color: "black",
                          fontWeight: "800",
                          fontSize: "1.1rem",
                        }}
                      >
                        Advanced
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={3}>
                    <Stack direction={"row"} columnGap="0.5rem">
                      <LocationOnIcon sx={{ color: "var(--orange)" }} />
                      <Typography
                        sx={{
                          color: "black",
                          fontWeight: "600",
                          fontSize: "1.1rem",
                        }}
                      >
                        {tutorDetails.location}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={3}>
                    <Stack direction={"row"} columnGap="0.5rem">
                      <PersonIcon sx={{ color: "var(--orange)" }} />
                      <Typography
                        sx={{
                          color: "black",
                          fontWeight: "600",
                          fontSize: "1.1rem",
                        }}
                      >
                        {`${tutorDetails.gender}, ${tutorDetails.age} y.o.`}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>

                <Grid container mt="2rem">
                  <Grid item xs={6}>
                    <WorkIcon sx={{ color: "var(--purple)" }} />
                    <Typography>teaching hours on Neithedu</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: "700" }}>
                      Age of students
                    </Typography>
                    <Typography>{tutorDetails.experience}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <img
                      width={"20px"}
                      alt="book"
                      src="/images/icons/book.png"
                    />
                    <Typography>years of teaching experience</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: "700" }}>
                      Subjects:
                    </Typography>
                    <Typography>
                      {tutorDetails.subjects.reduce(
                        (init, val, idx, arr) =>
                          arr.length - 1 !== idx
                            ? init + val + ", "
                            : init + val,
                        "",
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={3}>
                <Stack
                  height={"100%"}
                  flexDirection={"column"}
                  alignItems="center"
                  justifyContent="center"
                >
                  <PlainButton sx={{}}>Fill my schedule</PlainButton>
                </Stack>
              </Grid>
            </Grid>
          </Box>

          <Stack
            direction={"row"}
            mt="2rem"
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Stack direction={"row"} columnGap="1rem" alignItems={"center"}>
              <Typography
                sx={{
                  color: "white",
                  fontWeight: "700",
                  fontSize: "1.3rem",
                }}
              >
                {tutorDetails.noOfReviews + " reviews"}
              </Typography>
              <Typography
                sx={{
                  height: "1.9rem",
                  width: "2.3rem",
                  p: "0.3rem 0.4rem",
                  fontSize: "0.9rem",
                  color: "var(--purple-dark)",
                  fontWeight: "800",
                  backgroundColor: "var(--yellow)",
                  borderRadius: "6px",
                }}
              >
                {"4.0"}
              </Typography>
              <Rating readOnly value={4} />
            </Stack>
            <Box>
              <select
                style={{
                  backgroundColor: "rgba(255,255,255,0.1)",
                  borderRadius: "10px",
                  color: "white",
                  padding: "0.5rem",
                  border: "none",
                }}
              >
                <option value="1">Sort by date (from the newest)</option>
              </select>
            </Box>
          </Stack>
          <Box mt={"2rem"}>
            {tutorDetails.reviews.map((review, idx) => {
              return <ReviewCard key={idx} review={review} />;
            })}
          </Box>

          <Stack direction="row" justifyContent={"center"}>
            <Box width={"10rem"} my="1rem">
              <StyledYellowButton title={"More reviews"} />
            </Box>
          </Stack>
        </Container>
        <Footer logo={logo} />
      </div>
    </div>
  );
};
