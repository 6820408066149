import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./home.scss";
import "./about/about.scss";
import "./educator.scss";
import { Box } from "@mui/material";
export const DialogBox = ({ children, isOpen, setclose }) => {
  return (
    <>
      <Modal
        className="loginmodal student-login-main"
        show={isOpen}
        onHide={() => {
          setclose();
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header closeButton></Modal.Header>
          {children}
        </div>
      </Modal>
    </>
  );
};
export const ConfirmationDialog = ({ setclose, open, buttonText, message }) => {
  return (
    <Modal
      className="loginmodal student-login-main"
      show={open}
      onHide={() => {
        setclose(false);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="login-modal-mainbx">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>{message}</h4>
            {/* <p>We are Neithedu team!</p>
            <p>Please introduce yourself</p> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="login-modl-form">
            <div className="loginform-bx">
              <div className="btnlogin-main">
                <button
                  type="submit"
                  className="btnlogin btnweak-bx"
                  onClick={() => {
                    setclose(false);
                  }}
                >
                  {buttonText}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};
