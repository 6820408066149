import React from "react";
import { Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../loginModal/login.scss";

const FooterEducator = ({ logo }) => {
  /* start Eductor signup step1 */
  const [modalEducatorstep1Show, setEducatorstep1ModalShow] =
    React.useState(false);
  /* end Eductor signup step1 */

  /* start Eductor signup step2 */
  const [modalEducatorstep2Show, setEducatorstep2ModalShow] =
    React.useState(false);
  /* end Eductor signup step2 */

  /* start Tutor signup step2 */
  const [modalTutorstep2Show, setTutorstep2ModalShow] = React.useState(false);
  /* end Tutor signup step2 */

  /* start Tutor signup step3 */
  const [modalTutorstep3Show, setTutorstep3ModalShow] = React.useState(false);
  /* end Tutor signup step3 */
  /* start Tutor signup step4 */
  const [modalTutorstep4Show, setTutorstep4ModalShow] = React.useState(false);
  /* end Tutor signup step4 */

  /* start Tutor signup step5 */
  const [modalTutorstep5Show, setTutorstep5ModalShow] = React.useState(false);
  /* end Tutor signup step5 */

  /* start Tutor signup step6 */
  const [modalTutorstep6Show, setTutorstep6ModalShow] = React.useState(false);
  /* end Tutor signup step6 */

  /* start Tutor signup step6 */
  const [modalTutorstep7Show, setTutorstep7ModalShow] = React.useState(false);
  /* end Tutor signup step6 */

  return (
    <div className="footer-main">
      <div className="custome-container">
        <div className="footer-section educator-footer">
          <div className="footershape-bg"></div>
          <Row>
            <div className="col-md-4 one">
              <div className="foter-logo">
                <Link to="/">
                  <img
                    src={"/neitheduLogo.png"}
                    alt="logo"
                    style={{ width: "18rem" }}
                    className="img-fluid"
                  />
                </Link>
              </div>
            </div>
            <div className="col-md-6 two">
              <div className="foter-list">
                <div className="foter-listnew">
                  <ul>
                    <li>
                      <Link to="#">My students</Link>
                    </li>
                    <li>
                      <Link to="#">My games</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="#">My connections</Link>
                    </li>
                    <li>
                      <Link to="#">Messages</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="#">Games</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contacts</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="#">Terms of Use</Link>
                    </li>
                    <li>
                      <Link to="#">Privacy</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-2 three">
              <div className="footer-become">
                <div className="btn-week">
                  <a
                    href="#"
                    className="btnweak-bx"
                    onClick={() => {
                      setEducatorstep1ModalShow(true);
                    }}
                  >
                    <span className="btn-title">Became a tutor</span>
                  </a>
                </div>
              </div>
            </div>
          </Row>
        </div>

        <div className="foter-copywrite">
          <ul>
            <li>1999-2021 Neithedu.</li>
            <li>All Rights Reserved</li>
          </ul>
        </div>
      </div>

      {/* start become a tutor modal footer */}

      {/* start become a tutor step1 */}
      <Modal
        className="loginmodal student-login-main educator-signup-step2 become-popup-modal become-popup-modal1"
        // {...props}
        show={modalEducatorstep1Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header>
            <div className="btnback">
              <Link to="#" className="backbtn-icn">
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Became an Neithedu tutor</h4>
              <p>
                Please, choose tutoring agency that you are already registered
                with. If you add agancy, you will go to Advanced level without
                trial period
              </p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setEducatorstep1ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <Form.Label>I am registered with</Form.Label>

                  <div className="livein-field">
                    <Form.Control as="select">
                      <option>Select the tutoring agency</option>
                    </Form.Control>
                  </div>
                </Form.Group>

                <div className="btnlogin-main btnone-become">
                  <button
                    type="submit"
                    className="btnlogin btnweak-bx"
                    onClick={() => {
                      setTutorstep2ModalShow(true);
                      setEducatorstep1ModalShow(false);
                    }}
                  >
                    Continue
                  </button>
                </div>

                <div className="btnlogin-main">
                  <button
                    type="submit"
                    className="btnlogin btnweak-bx"
                    onClick={() => {
                      setTutorstep7ModalShow(true);
                      setEducatorstep1ModalShow(false);
                    }}
                  >
                    I don’t have registration in any agency
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="count-barr become-count-step1">
            <div className="counting-barful">
              <div className="linebar-tex">1/5</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>

      {/* end become a tutor step1 */}

      {/* start become a tutor step2 */}
      <Modal
        className="loginmodal student-login-main educator-signup-step2 become-popup-modal become-popup-modal2"
        // {...props}
        show={modalTutorstep2Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header>
            <div className="btnback">
              <Link to="#" className="backbtn-icn">
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Became an Neithedu tutor</h4>
              <p>
                Please, answer some questions in order to see your motivation
                and qualification
              </p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setTutorstep2ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <Form.Label>Text of questions #1 about something</Form.Label>

                  <div className="livein-field">
                    <Form.Control
                      as="textarea"
                      className="tutor-textarea"
                      placeholder="Write answer here"
                    />
                  </div>
                </Form.Group>

                <div className="btnlogin-main btnone-become">
                  <button
                    type="submit"
                    className="btnlogin btnweak-bx"
                    onClick={() => {
                      setTutorstep3ModalShow(true);
                      setTutorstep2ModalShow(false);
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="count-barr become-count-step2">
            <div className="counting-barful">
              <div className="linebar-tex">2/5</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>

      {/* end become a tutor step2 */}

      {/* start become a tutor step3 */}
      <Modal
        className="loginmodal student-login-main educator-signup-step2 become-popup-modal become-popup-modal2"
        // {...props}
        show={modalTutorstep3Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header>
            <div className="btnback">
              <Link to="#" className="backbtn-icn">
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Became an Neithedu tutor</h4>
              <p>
                Please, answer some questions in order to see your motivation
                and qualification
              </p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setTutorstep3ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <Form.Label>About something Text of questions #2</Form.Label>

                  <div className="livein-field">
                    <Form.Control
                      as="textarea"
                      className="tutor-textarea"
                      placeholder="Write answer here"
                    />
                  </div>
                </Form.Group>

                <div className="btnlogin-main btnone-become">
                  <button
                    type="submit"
                    className="btnlogin btnweak-bx"
                    onClick={() => {
                      setTutorstep4ModalShow(true);
                      setTutorstep3ModalShow(false);
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="count-barr become-count-step3">
            <div className="counting-barful">
              <div className="linebar-tex">3/5</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>

      {/* end become a tutor step3 */}

      {/* start become a tutor step4 */}
      <Modal
        className="loginmodal student-login-main educator-signup-step2 become-popup-modal become-popup-modal2"
        // {...props}
        show={modalTutorstep4Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header>
            <div className="btnback">
              <Link to="#" className="backbtn-icn">
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Became an Neithedu tutor</h4>
              <p>
                Please, answer some questions in order to see your motivation
                and qualification
              </p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setTutorstep4ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <Form.Label>Text of questions #3 about something</Form.Label>

                  <div className="livein-field">
                    <Form.Control
                      as="textarea"
                      className="tutor-textarea"
                      placeholder="Write answer here"
                    />
                  </div>
                </Form.Group>

                <div className="btnlogin-main btnone-become">
                  <button
                    type="submit"
                    className="btnlogin btnweak-bx"
                    onClick={() => {
                      setTutorstep5ModalShow(true);
                      setTutorstep4ModalShow(false);
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="count-barr become-count-step4">
            <div className="counting-barful">
              <div className="linebar-tex">4/5</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>

      {/* end become a tutor step4 */}

      {/* start become a tutor step5 */}
      <Modal
        className="loginmodal student-login-main educator-signup-step2 become-popup-modal become-popup-modal5"
        // {...props}
        show={modalTutorstep5Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header>
            <div className="btnback">
              <Link to="#" className="backbtn-icn">
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Became an Neithedu tutor</h4>
              <p>Almost done!</p>
              <p> Please, read Tutor Policy and confirm you agree</p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setTutorstep5ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <div className="have-account">
                  <a className="title-med" href="#">
                    Tutor Policy.pdf
                  </a>
                </div>

                <div className="btnlogin-main btnone-become">
                  <button
                    type="submit"
                    className="btnlogin btnweak-bx"
                    onClick={() => {
                      setTutorstep6ModalShow(true);
                      setTutorstep5ModalShow(false);
                    }}
                  >
                    I agree with Tutor Policy
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="count-barr become-count-step5">
            <div className="counting-barful">
              <div className="linebar-tex">5/5</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>

      {/* end become a tutor step5 */}

      {/* start become a tutor step6 */}
      <Modal
        className="loginmodal student-login-main educator-signup-step2 become-popup-modal become-popup-modal6"
        // {...props}
        show={modalTutorstep6Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header>
            <div className="btnback">
              <Link to="#" className="backbtn-icn">
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Thanks, your application is sent!</h4>
              <p>
                Your account is under review now. We will check your application
                and after confirmation you will able to have a lessons
              </p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setTutorstep6ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <div className="btnlogin-main btnone-become">
                  <button type="submit" className="btnlogin btnweak-bx">
                    I undestood
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end become a tutor step6 */}

      {/* start become a tutor step7 */}
      <Modal
        className="loginmodal student-login-main educator-signup-step2 become-popup-modal become-popup-modal7"
        // {...props}
        show={modalTutorstep7Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header>
            <div className="btnback">
              <Link to="#" className="backbtn-icn">
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Became an Neithedu tutor</h4>
              <p>
                Since you do not have registration with agencies, we need to be
                sure of your qualifications
              </p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setTutorstep7ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <div className="upload-certif-bx">
                  <Form.Group className="subsform-fild">
                    <Form.Label className="form-label">
                      My CRB/DBS certificate
                    </Form.Label>

                    <Row className="certific-bx">
                      <div className="col-md-8 one">
                        <div className="file-upldnw">
                          <Form.Control
                            type="text"
                            placeholder="Upload the file"
                          />
                        </div>
                      </div>
                      <div className="col-md-4 two">
                        <div className="file-upnew">
                          <Form.Control
                            type="file"
                            className="brower-btn"
                            id="input-file"
                          />
                          <label
                            for="input-file"
                            className="btnweak-bx btn-browse-file"
                          >
                            Browse
                          </label>
                        </div>
                      </div>
                    </Row>
                  </Form.Group>

                  <Form.Group className="subsform-fild">
                    <Form.Label className="form-label">
                      Highest qualification
                    </Form.Label>

                    <Row className="certific-bx">
                      <div className="col-md-8 one">
                        <div className="file-upldnw">
                          <Form.Control
                            type="text"
                            placeholder="Upload the file"
                          />
                        </div>
                      </div>
                      <div className="col-md-4 two">
                        <div className="file-upnew">
                          <Form.Control
                            type="file"
                            className="brower-btn"
                            id="input-file"
                          />
                          <label
                            for="input-file"
                            className="btnweak-bx btn-browse-file"
                          >
                            Browse
                          </label>
                        </div>
                      </div>
                    </Row>
                  </Form.Group>

                  <Form.Group className="subsform-fild">
                    <Form.Label className="form-label">
                      My PGCE/TEFL certificate
                    </Form.Label>

                    <Row className="certific-bx">
                      <div className="col-md-8 one">
                        <div className="file-upldnw">
                          <Form.Control
                            type="text"
                            placeholder="Upload the file"
                          />
                        </div>
                      </div>
                      <div className="col-md-4 two">
                        <div className="file-upnew">
                          <Form.Control
                            type="file"
                            className="brower-btn"
                            id="input-file"
                          />
                          <label
                            for="input-file"
                            className="btnweak-bx btn-browse-file"
                          >
                            Browse
                          </label>
                        </div>
                      </div>
                    </Row>
                  </Form.Group>
                </div>

                <div className="btnlogin-main btnone-become">
                  <button
                    type="submit"
                    className="btnlogin btnweak-bx"
                    onClick={() => {
                      setTutorstep2ModalShow(true);
                      setTutorstep7ModalShow(false);
                    }}
                  >
                    Upload files and continue
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end become a tutor step7 */}

      {/* End become a tutor modal footer */}
    </div>
  );
};

export default FooterEducator;
