import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../layout/Footer/Footer";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import PersonIcon from "@mui/icons-material/Person";

import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import BlockIcon from "@mui/icons-material/Block";

import { HeaderDashboard } from "../layout/HeaderDashboard";
import logo from "../../assets/images/logo.png";
import spaceAdventure from "../../assets/images/spaceAdventure.png";
import spaceAstro from "../../assets/images/spaceAstro.png";
import { StyledYellowButton } from "../utils/Buttons";
import { useHistory } from "react-router";
const data = {
  name: "Lion Amari",
  withNeithedu: {
    years: 2,
    months: 7,
  },
  location: "India, Mumbai",
  yearsOfStudy: 7,
  subjects: ["Biology", "History", "English"],
  greatGame: "Level One",
  score: {
    points: "66 945",
    games: "5 237",
    rewardCards: 257,
    badges: 12,
    gifts: 2,
    friends: 58,
  },
  lastNews: [
    {
      name: "Aarush Khatri",
      date: new Date(),
      description: 'Game flow "Biology" is completed',
      img: "",
    },
    {
      name: "Aarush Khatri",
      date: new Date(),
      description:
        "Congratulations! Encouraging badge for Helping others is gotten!",
      img: "",
    },
  ],
};
export const FriendDetailPage = () => {
  const history = useHistory();
  const PlainButton = ({ sx, children, onClick }) => (
    <Box
      sx={{
        cursor: "pointer",
        textAlign: "center",
        fontWeight: "700",
        color: "var(--purple-dark)",
        backgroundColor: "var(--yellow)",
        p: "0.3rem 0.5rem",
        borderRadius: "10px",
        width: "100%",
        ...sx,
      }}
      onClick={onClick}
    >
      {children}
    </Box>
  );

  const PointCard = ({ points, pointName }) => {
    return (
      <Stack alignItems={"center"}>
        <Typography
          sx={{ fontSize: "2rem", color: "white", fontWeight: "700" }}
        >
          {points}
        </Typography>
        <Typography
          sx={{
            borderRadius: "10px",
            color: "var(--purple)",
            p: "0.4rem",
            backgroundColor: "var(--yellow)",
          }}
        >
          {pointName}
        </Typography>
      </Stack>
    );
  };
  return (
    <>
      <div className="home-wrapper">
        <HeaderDashboard />
        <div
          style={{ minHeight: "100vh" }}
          className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs"
        >
          <Container style={{ textAlign: "start" }}>
            <Grid
              container
              alignItems="center"
              columnSpacing="2rem"
              sx={{
                width: "100%",
                mx: "0",
                p: "1rem",
                position: "relative",
                backgroundColor: "white",
                borderRadius: "1.2rem",
              }}
            >
              <KeyboardBackspaceIcon
                onClick={() => history.push("/student/myConnections")}
                sx={{
                  cursor: "pointer",
                  color: "var(--orange)",
                  fontSize: "2rem",
                  position: "absolute",
                  top: "5px",
                  left: "5px",
                }}
              />
              <Grid item md={2}>
                <img width={"90%"} src="/images/profilepic.png" alt="profile" />
              </Grid>
              <Grid item md={6}>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "2rem",
                      fontWeight: "700",
                      color: "var(--purple-dark)",
                    }}
                  >
                    {data.name}
                  </Typography>
                  <Typography sx={{ mb: "1rem", color: "var(--purple)" }}>
                    {data.withNeithedu.years} years {data.withNeithedu.months}{" "}
                    with Neithedu
                  </Typography>
                  <Stack justifyContent={"space-between"} flexDirection={"row"}>
                    <Box>
                      <Typography fontWeight={"bold"}>
                        {data.location}
                      </Typography>
                      <Typography>
                        {data.yearsOfStudy} years of study
                      </Typography>
                    </Box>
                    <Box>
                      <Typography fontWeight={"bold"}>Subjects</Typography>
                      <Typography>
                        {data.subjects.reduce(
                          (prev, sub, idx, arr) =>
                            prev + sub + (idx !== arr.length - 1 ? ", " : ""),
                          "",
                        )}{" "}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography fontWeight={"bold"}>Great game:</Typography>
                      <Typography>{data.greatGame} </Typography>
                    </Box>
                  </Stack>
                </Box>
              </Grid>
              <Grid item md={4}>
                <Grid
                  alignItems={"center"}
                  container
                  mx={"auto"}
                  width={"60%"}
                  rowSpacing={"1rem"}
                >
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        columnGap: "1rem",
                        alignItems: "center",
                      }}
                    >
                      <FavoriteBorderIcon
                        sx={{
                          mx: "auto",
                          color: "white",
                          backgroundColor: "var(--purple)",
                          p: "0.3rem",
                          borderRadius: "6px",
                          fontSize: "2rem",
                        }}
                      />
                      <PlainButton
                        sx={{
                          backgroundColor: "var(--purple)",
                          color: "white",
                        }}
                      >
                        {" "}
                        Add to Chat
                      </PlainButton>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        columnGap: "1rem",
                        alignItems: "center",
                      }}
                    >
                      <BlockIcon
                        sx={{
                          color: "white",
                          backgroundColor: "var(--orange)",
                          mx: "auto",

                          p: "0.3rem",
                          borderRadius: "6px",
                          fontSize: "2rem",
                        }}
                      />

                      <PlainButton
                        sx={{
                          backgroundColor: "var(--orange)",
                          color: "white",
                        }}
                      >
                        Report
                      </PlainButton>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <PlainButton>Unfriend</PlainButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              sx={{
                width: "100%",
                mt: "2rem",
                backgroundColor: "var(--purple-dark)",
                borderRadius: "1.2rem",
                p: "1rem",
              }}
            >
              <Grid item md={2}>
                <PointCard pointName={"points"} points={data.score.points} />
              </Grid>
              <Grid item md={2}>
                <PointCard pointName={"games"} points={data.score.games} />
              </Grid>
              <Grid item md={2}>
                <PointCard
                  pointName={"reward cards"}
                  points={data.score.rewardCards}
                />
              </Grid>
              <Grid item md={2}>
                <PointCard pointName={"badges"} points={data.score.badges} />
              </Grid>
              <Grid item md={2}>
                <PointCard pointName={"gifts"} points={data.score.gifts} />
              </Grid>
              <Grid item md={2}>
                <PointCard pointName={"friends"} points={data.score.friends} />
              </Grid>
            </Grid>

            {/* LAST NEWS */}
            <Box
              sx={{
                mt: "1rem",
                p: "1rem",
                backgroundColor: "white",
                borderRadius: "1.2rem",
                position: "relative",
                mb: "3rem",
              }}
            >
              <Typography
                sx={{
                  color: "var(--orange)",
                  fontSize: "1.3rem",
                  fontWeight: "bold",
                  mb: "1rem",
                }}
              >
                Last news
              </Typography>
              <Box>
                <Stack flexDirection={"row"} columnGap="1rem">
                  <PersonIcon
                    sx={{
                      backgroundColor: "var(--purple)",
                      borderRadius: "50%",
                      color: "white",
                      p: "0.4rem",
                      fontSize: "3rem",
                    }}
                  />
                  <Stack>
                    <Typography sx={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                      Aarush Khatri
                    </Typography>
                    <Typography>21 Sep 2:34pm</Typography>
                  </Stack>
                </Stack>
                <Typography>
                  Congratulations! Encouraging badge for Helping others is
                  gotten!
                </Typography>
                <img src={spaceAdventure} width="50%" alt="space adventure" />
              </Box>
              <Divider sx={{ my: "1rem" }} />
              <Box>
                <Stack flexDirection={"row"} columnGap="1rem">
                  <PersonIcon
                    sx={{
                      backgroundColor: "var(--purple)",
                      borderRadius: "50%",
                      color: "white",
                      p: "0.4rem",
                      fontSize: "3rem",
                    }}
                  />
                  <Stack>
                    <Typography sx={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                      Aarush Khatri
                    </Typography>
                    <Typography>21 Sep 2:34pm</Typography>
                  </Stack>
                </Stack>
                <Typography>Game flow "Biology" is completed</Typography>
                <Grid container columnSpacing={"1rem"}>
                  <Grid item sm={6}>
                    <img
                      width={"100%"}
                      height="100%"
                      src={spaceAstro}
                      alt="space astro"
                      style={{ objectFit: "cover" }}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <Box
                      sx={{
                        backgroundColor: "var(--purple-dark)",
                        borderRadius: "10px",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Stack alignItems={"center"}>
                        <Typography sx={{ color: "white" }}>
                          Badge for
                        </Typography>
                        <Typography
                          sx={{
                            color: "var(--orange)",
                            fontSize: "1.4rem",
                            fontWeight: "700",
                          }}
                        >
                          Helping others
                        </Typography>
                        <Stack
                          columnGap={"2rem"}
                          sx={{ color: "white" }}
                          flexDirection="row"
                          textAlign={"center"}
                        >
                          <Box>
                            <Typography
                              sx={{
                                fontSize: "1.4rem",
                                fontWeight: "700",
                                color: "var(--yellow)",
                              }}
                            >
                              +30
                            </Typography>
                            <Typography>points to own</Typography>
                            <Typography>account</Typography>
                          </Box>
                          <Box>
                            <Typography
                              sx={{
                                fontSize: "1.4rem",
                                fontWeight: "700",
                                color: "var(--yellow)",
                              }}
                            >
                              +4
                            </Typography>
                            <Typography>power to</Typography>
                            <Typography>Science card</Typography>
                          </Box>
                        </Stack>
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Divider sx={{ my: "1rem" }} />
              <Box>
                <Stack flexDirection={"row"} columnGap="1rem">
                  <PersonIcon
                    sx={{
                      backgroundColor: "var(--purple)",
                      borderRadius: "50%",
                      color: "white",
                      p: "0.4rem",
                      fontSize: "3rem",
                    }}
                  />
                  <Stack>
                    <Typography sx={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                      Aarush Khatri
                    </Typography>
                    <Typography>21 Sep 2:34pm</Typography>
                  </Stack>
                </Stack>
                <Typography>Game flow "Biology" is completed</Typography>
              </Box>
              <Divider sx={{ my: "1rem" }} />
              <Box
                sx={{ position: "absolute", bottom: "-25px", left: "40%" }}
                width="10rem"
              >
                <StyledYellowButton btnHandler={() => {}} title="More news" />
              </Box>
            </Box>
          </Container>
          <Footer logo={logo} />
        </div>
      </div>
    </>
  );
};
