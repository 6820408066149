import React, { useEffect, useState } from "react";
import "./home.scss";
import "./profile.scss";
import "./media.scss";
import "./games.scss";
import "./loginModal/login.scss";
import SignUpOptions from "./SignUpOptions";
import StudentSignUp from "./StudentSignUp";
import ParentSignUp from "./parent/ParentAuth/ParentSignUp";
import SignUpEducator from "./SignUpEducator";
import { fetchData } from "../service/AllApi";
import { useLocation } from "react-router";

function Signup({ setModalSignupShow, modalSignupShow, setModalShow }) {
  const [referState, setreferState] = useState({
    role: null,
    referId: null,
  });
  /* start student login */
  const [modalStudentShow, setStudentModalShow] = React.useState(false);
  /* end student login */

  /* start student login */
  const [modalParentShow, setParentModalShow] = React.useState(false);
  /* end student login */

  /* start Educator login */
  const [modalEducatorShow, setEducatorModalShow] = React.useState(false);
  /* end Educator login */
  const [country, setCountries] = useState([]);
  const location = useLocation();
  useEffect(() => {
    const execAsync = async () => {
      try {
        const referId = new URLSearchParams(location.search).get("token");
        console.log("referId123", referId);
        console.log("location123", location.pathname);
        let role;
        if (location.pathname === "/register" && referId) {
          role = "student";
          setStudentModalShow(true);
          setModalSignupShow(false);
        } else if (location.pathname === "/referral/educator") {
          setEducatorModalShow(true);
          setModalSignupShow(false);
          role = "educator";
        }
        localStorage.setItem(
          "referral",
          JSON.stringify({
            referId,
            role,
          }),
        );
        setreferState({
          referId,
          role,
        });
        const response = await fetchData("countries");
        if (response && response.data && response.data.countryList) {
          setCountries(response.data?.countryList);
        }
      } catch (err) {
        //console.log("error :", err.message || err);
      }
    };
    execAsync();
  }, [location]);

  return (
    <>
      {/* start sign up */}
      <SignUpOptions
        openLoginModal={() => setModalShow(true)}
        modalSignupShow={modalSignupShow}
        setEducatorModalShow={(value) => setEducatorModalShow(value)}
        setModalSignupShow={(value) => setModalSignupShow(value)}
        setParentModalShow={(value) => setParentModalShow(value)}
        setStudentModalShow={(value) => setStudentModalShow(value)}
      />
      {/* end sign up */}

      {/* start student login*/}
      <StudentSignUp
        setModalSignupShow={(value) => setModalSignupShow(value)}
        modalStudentShow={modalStudentShow}
        setStudentModalShow={(value) => setStudentModalShow(value)}
        country={country}
        setModalShow={(value) => setModalShow(value)}
      />
      {/* end student login */}

      {/* start student parent*/}
      <ParentSignUp
        setModalSignupShow={(value) => setModalSignupShow(value)}
        modalParentShow={modalParentShow}
        setParentModalShow={(value) => setParentModalShow(value)}
        setModalShow={(value) => setModalShow(value)}
      />
      {/* end student parent */}
      <SignUpEducator
        setModalSignupShow={(value) => setModalSignupShow(value)}
        modalEducatorShow={modalEducatorShow}
        setEducatorModalShow={(value) => setEducatorModalShow(value)}
        setModalShow={(value) => setModalShow(value)}
        country={country}
      />
      {/* start Educator parent*/}
    </>
  );
}

export default Signup;
