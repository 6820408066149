import { Box, Modal } from "@mui/material";

export const DialogBox = ({ isOpen, close, children }) => {
  return (
    <Modal
      sx={{ overflowY: "scroll" }}
      open={isOpen}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          minHeight: "100vh",
          alignItems: "center",
          p: "1rem",
        }}
      >
        <Box
          sx={{
            backgroundColor: "var(--white)",
            position: "relative",
            maxWidth: "30rem",
            borderRadius: "15px",
            p: "1.5rem",
          }}
        >
          {children}
        </Box>
      </Box>
    </Modal>
  );
};
