import axios from "axios";
import { backEnd, gameBackEnd } from "../env";
import { setupInterceptorsTo } from "./interceptor";
// console.log(backEnd, "backend");
// open API's
const OpenAxios = axios.create({
  baseURL: backEnd,
  headers: {
    "Content-Type": "application/json",
  },
});

// multipart/form-data

// Authenticated
const Axios = setupInterceptorsTo(
  axios.create({
    baseURL: backEnd,
    headers: {
      "Content-Type": "application/json",
    },
  }),
);
// multipart/form-data

const AxiosM = setupInterceptorsTo(
  axios.create({
    baseURL: backEnd,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }),
);

const GamesAxios = setupInterceptorsTo(
  axios.create({
    baseURL: gameBackEnd,
    headers: {
      "Content-Type": "application/json",
    },
  }),
);

export { Axios, GamesAxios, OpenAxios, AxiosM };
