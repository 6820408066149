import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { Container } from "react-bootstrap";
import PersonIcon from "@mui/icons-material/Person";
import HeaderGames from "../../layout/Header/HeaderGames";
import FooterEducator from "../../layout/Footer/FooterEducator";
import logo from "../../../assets/images/logo.png";
import { useHistory } from "react-router";

const STATUS = {
  NOT_CHECKED: "NOT_CHECKED",
  LATE: "LATE",
  COMPLETED: "COMPLETED",
};
export const QuizStatus = () => {
  const history = useHistory();
  const [state, setstate] = useState({
    mode: "Active",
    status: "2 Completed 9 Not checked, 11 Late",
    deadline: "29 Nov 2021",
    created: "16 Nov 2021",
    points: 5,
    total_points: 50,
    studentDetails: [
      {
        name: "Maxine Doe",
        status: STATUS.NOT_CHECKED,
        count: 3,
        date: new Date(),
        isInRevision: false,
      },
      {
        name: "Maxine Doe",
        status: STATUS.NOT_CHECKED,
        count: 3,
        date: new Date(),
        isInRevision: false,
      },
      {
        name: "Maxine Doe",
        status: STATUS.NOT_CHECKED,
        count: 3,
        date: new Date(),
        isInRevision: true,
      },
      {
        name: "Maxine Doe",
        status: STATUS.NOT_CHECKED,
        count: 3,
        date: new Date(),
        isInRevision: false,
      },
      {
        name: "Maxine Doe",
        status: STATUS.LATE,
        date: new Date(),
        isInRevision: false,
      },
      {
        name: "Maxine Doe",
        status: STATUS.LATE,
        date: new Date(),
        isInRevision: true,
      },
      {
        name: "Maxine Doe",
        status: STATUS.LATE,
        date: new Date(),
        isInRevision: false,
      },
      {
        name: "Maxine Doe",
        status: STATUS.COMPLETED,
        date: new Date(),
        isInRevision: true,
        no_of_revisions: 0,
      },
      {
        name: "Maxine Doe",
        status: STATUS.COMPLETED,
        date: new Date(),
        isInRevision: true,
        no_of_revisions: 2,
      },
      {
        name: "Maxine Doe",
        status: STATUS.COMPLETED,
        date: new Date(),
        isInRevision: true,
        no_of_revisions: 1,
      },
    ],
  });

  const dateStatus = (status) => {
    switch (status) {
      case STATUS.COMPLETED:
        return "Sent";
      case STATUS.LATE:
        return "Deadline missed";
      default:
        return "";
    }
  };
  const StatusButton = ({ status }) => {
    if (status === STATUS.NOT_CHECKED)
      return (
        <Box
          onClick={() => history.push("/educator/quizNotChecked")}
          sx={{
            cursor: "pointer",
            p: "0.1rem",
            fontSize: "0.8rem",
            textAlign: "center",
            color: "white",
            borderRadius: "0.5rem",
            backgroundColor: "var(--sky)",
          }}
        >
          Not Checked
        </Box>
      );
    if (status === STATUS.LATE)
      return (
        <Box
          onClick={() => history.push("/educator/quizLate")}
          sx={{
            cursor: "pointer",
            p: "0.1rem",
            fontSize: "0.8rem",
            textAlign: "center",
            color: "white",
            borderRadius: "0.5rem",
            backgroundColor: "var(--orange)",
          }}
        >
          Late
        </Box>
      );
    return (
      <Box
        sx={{
          p: "0.1rem",
          fontSize: "0.8rem",
          textAlign: "center",
          color: "white",
          borderRadius: "0.5rem",
          backgroundColor: "var(--purple-dark)",
        }}
      >
        Completed
      </Box>
    );
  };
  return (
    <div className="home-wrapper">
      <HeaderGames />
      <div
        style={{ minHeight: "100vh" }}
        className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs"
      >
        <Container style={{ textAlign: "start" }}>
          <Box>
            <Stack alignItems="center">
              <Typography
                sx={{
                  color: "var(--orange)",
                  fontWeight: "700",
                  fontSize: "1.1rem",
                }}
                mb="1.5rem"
              >
                Neithedu Game: Quiz
              </Typography>
              <Typography
                sx={{ color: "white", fontSize: "1.5rem", fontWeight: "700" }}
              >
                Cellular Life and Genetics, Title of the
              </Typography>
              <Typography
                mb="2rem"
                sx={{ color: "white", fontSize: "1.5rem", fontWeight: "700" }}
              >
                {" "}
                game that is located in Neithedu
              </Typography>
              <Grid
                justifyContent={"space-between"}
                container
                sx={{
                  maxWidth: "50rem",
                  width: "100%",
                  color: "white",
                  p: "1rem",
                  backgroundColor: "rgba(255,255,255,0.1)",
                  borderRadius: "1.2rem",
                }}
              >
                <Grid item xs={3}>
                  <Typography>
                    Mode: <b style={{ color: "var(--orange)" }}>{state.mode}</b>
                  </Typography>
                  <Typography>
                    Status:{" "}
                    <b style={{ color: "var(--orange)" }}>{state.status}</b>
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Divider
                    orientation="vertical"
                    flexItem
                    style={{ height: "100%", color: "white", width: "2px" }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography>
                    Deadline:{" "}
                    <b style={{ color: "var(--orange)" }}>{state.deadline}</b>
                  </Typography>
                  <Typography>
                    Created:{" "}
                    <b style={{ color: "var(--orange)" }}>{state.created}</b>
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Divider
                    orientation="vertical"
                    flexItem
                    style={{ height: "100%", color: "white", width: "2px" }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography>
                    Points:{" "}
                    <b style={{ color: "var(--orange)" }}>{state.points}</b>
                  </Typography>
                  <Typography>
                    Total Points:{" "}
                    <b style={{ color: "var(--orange)" }}>
                      {state.total_points}
                    </b>
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
          </Box>
          <Box
            sx={{
              backgroundColor: "white",
              borderRadius: "1.2rem",
              p: "1.5rem",
              my: "2rem",
            }}
          >
            {state.studentDetails.map((stud, idx) => {
              return (
                <Box sx={{ mb: "1rem" }} key={idx}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent={"space-between"}
                  >
                    <Grid
                      item
                      xs={3}
                      sx={{
                        backgroundColor: "#EDEDFF",
                        borderRadius: "1rem",
                        m: "0.4rem",
                        p: "0.4rem",
                      }}
                    >
                      <Stack
                        alignItems="center"
                        flexDirection="row"
                        columnGap={"1rem"}
                        ml="1rem"
                      >
                        <PersonIcon
                          sx={{
                            borderRadius: "50%",
                            color: "white",
                            backgroundColor: "var(--purple)",
                            p: "0.5rem",
                            fontSize: "2.5rem",
                          }}
                        />
                        <Typography>{stud.name}</Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={2}>
                      <StatusButton status={stud.status} />
                    </Grid>
                    <Grid item xs={5}>
                      <Stack flexDirection={"row"}>
                        <Typography>
                          {dateStatus(stud.status) +
                            " " +
                            stud.date.toGMTString()}
                        </Typography>
                        {stud.isInRevision && stud.status !== STATUS.COMPLETED && (
                          <Typography
                            sx={{
                              ml: "1rem",
                              fontWeight: "600",
                              color: "var(--purple-dark)",
                            }}
                          >
                            In revision
                          </Typography>
                        )}
                        {stud.status === STATUS.COMPLETED && (
                          <Typography
                            sx={{
                              ml: "1rem",
                              fontWeight: "600",
                              color: "var(--purple-dark)",
                            }}
                          >
                            {stud.no_of_revisions + " revisions"}
                          </Typography>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={0.5}>
                      {stud.count && (
                        <Stack flexDirection={"row"} justifyContent="end">
                          <Box
                            sx={{
                              color: "white",
                              backgroundColor: "var(--sky)",
                              p: "0.3rem",
                              display: "inline-block",
                              borderRadius: "0.4rem",
                              textAlign: "center",
                            }}
                          >
                            {stud.count}
                          </Box>
                        </Stack>
                      )}
                    </Grid>
                  </Grid>
                  {idx !== state.studentDetails.length - 1 && <Divider />}
                </Box>
              );
            })}
          </Box>
        </Container>
        <FooterEducator logo={logo} />
      </div>
    </div>
  );
};
