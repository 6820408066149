import React, { useState, useCallback, useEffect, useContext } from "react";
import "./home.scss";
import "./profile.scss";
import "./media.scss";
import "./games.scss";
import "./loginModal/login.scss";
import "./messages/messages.scss";
import "./myactivities.scss";
import "./gamestabs.scss";
import "./Mymaterials.scss";

import { Container, Dropdown, Row, Button, Form, Modal } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import logo from "../assets/images/logo.png";
import dotthree from "../assets/images/dotthree.svg";
import addbtnround from "../assets/images/add-btn.svg";
import darkblueplan from "../assets/images/darkblue-plan.svg";

import { Link, useHistory } from "react-router-dom";
import FooterEducator from "./layout/Footer/FooterEducator";
import HeaderGames from "./layout/Header/HeaderGames";
import { materialType } from "../service/constant";
import {
  deleteMaterial,
  getMaterialGamesList,
} from "../service/apis/materials";
import { getGameCategories, getGameTopics } from "../service/apis/categories";
import { Box, Pagination } from "@mui/material";
import {
  createNewGame,
  editGameflowGames,
  getAllGamesList,
  getGameflowById,
} from "../service/apis/games";
import ActivitesSendModal from "./ActivitesSendModal";
import ConfirmationModal from "./dialogs/ConfirmationModal";
import { useQuery } from "@tanstack/react-query";
import Gamesflowphotos from "./games/GameFlow/Gamesflowphotos";
import { CustomSnackbar, useSnackState } from "../utils/useSnackState";
import GameFlowModal from "./games/GameFlow/GameFlowModal";
import { NotificationContext } from "../contexts/Notification";

function Mymaterials() {
  const [modalAddgroupstep2Show, setAddgroupstep2ModalShow] = useState(false);
  const [modalgroupmemberstep3Show, setgroupmemberstep3ModalShow] =
    useState(false);
  const [modalthanksstep2Show, setthanksstep2ModalShow] = useState(false);
  const [modalgamecreatestep1Show, setgamecreatestep1ModalShow] =
    useState(false);
  const [myMaterialType, setMyMaterialType] = useState(materialType.all);
  const [confirmationModal, setConfirmationModal] = useState({
    open: false,
    heading: "",
    detail: "",
    selectedId: "",
    successHandler: () => {},
    cancelHandler: () => {},
  });
  const [gameFlowSelection, setGameFlowSelection] = useState({
    open: false,
    selectedGame: {},
    dataToFilter: [],
  });

  const [snackProps, snackOpen] = useSnackState();
  const { showNotification } = useContext(NotificationContext);

  const history = useHistory();
  const GameTypes = [
    {
      id: 4,
      name: "Material",
    },
  ];

  const grades = (() => {
    const grades = [...Array(13).keys()];
    grades.splice(0, 1);

    return grades;
  })();

  const [allMaterials, setAllMaterials] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(8);
  const [filalData, setFinalData] = useState();
  const [data, setdata] = useState();
  const [gred, setGred] = useState("0");
  const [searchData, setSearchData] = useState([]);
  const [gameDetels, setGemdeteles] = useState({});
  const [tabcount, setTabecount] = useState(1);
  const [searchMyKeyword, setSearchMyKeyword] = useState("");
  const changeTab = useCallback((event) => {
    const clickedTab = event.target.attributes.getNamedItem("name").value;
    switch (clickedTab) {
      case "my_material":
        //setIllumiyaMaterialType(materialType.all)
        fetchMyMaterial();
        break;
      case "illumiya_material":
        //setMyMaterialType(materialType.all)
        fetchIllumiyaMaterial();
        break;
      default:
        break;
    }
  });

  // ****** Bikash Code Start  *******

  const [newGameFormData, setnewGameFormData] = useState({
    title: "",
    gameTypes: GameTypes,
    subjects: [],
    topics: [],
    selectedGameType: 4,
    selectedSubject: "",
    selectedTopic: "",
    studentGrade: 1,
    description: "",
  });

  const {
    isLoading: allGamesLoading,
    data: allGamesData,
    refetch: refetchGamesData,
  } = useQuery({
    queryKey: ["allGames"],
    queryFn: async () => await getAllGamesList(),
  });

  const fetchGameSubjects = async () => {
    try {
      const res = await getGameCategories();
      setnewGameFormData((prevState) => ({
        ...prevState,
        subjects: res.rows,
        selectedSubject: res.rows.length > 0 ? res.rows[0].id : "",
      }));
    } catch (e) {}
  };

  const fetchGameTopics = async (categoryId) => {
    try {
      const res = await getGameTopics(categoryId);
      setnewGameFormData((state) => ({
        ...state,
        topics: res.rows,
        selectedTopic: res.rows.length > 0 ? res.rows[0].id : "",
      }));
    } catch (e) {}
  };

  const saveGame = async (inputData) => {
    console.log("newGameFormData", inputData);
    console.log("allMaterials", data.myMaterials);

    try {
      // check if already exist
      const isExist = data.myMaterials.find(
        (item) =>
          item.title === inputData.title &&
          +item.studentGrade === +inputData.studentGrade &&
          item.description === inputData.description &&
          item.categoryName === inputData.selectedSubject?.name,
      );
      if (isExist) {
        snackOpen("Material already exist", "error");
        return;
      }

      // title: newGameFormData.title,
      // description: newGameFormData.description,
      // gameType: parseInt(newGameFormData.selectedGameType),
      // categoryId: parseInt(newGameFormData.selectedSubject),
      // topicId: parseInt(newGameFormData.selectedTopic),
      // studentGrade: parseInt(newGameFormData.studentGrade),

      let updatedData = {
        ...inputData,
        selectedGameType: inputData.selectedGameType?.id,
        selectedSubject: inputData.selectedSubject?.id,
        selectedTopic: inputData.topicId?.id,
      };
      const res = await createNewGame(updatedData);
      console.log("createdgame", res);

      // title: newGameFormData.title,
      // description: newGameFormData.description,
      // gameType: parseInt(newGameFormData.selectedGameType),
      // categoryId: parseInt(newGameFormData.selectedSubject),
      // topicId: parseInt(newGameFormData.selectedTopic),
      // studentGrade: parseInt(newGameFormData.studentGrade),

      if (res) {
        showNotification("Material created successfully");
        setTimeout(() => {
          history.push({
            pathname: "/Creatingmaterial",
            state: { gameId: res.data, title: newGameFormData.title },
          });
        }, 2000);
      }
    } catch (e) {
      console.log(e);
      snackOpen("Something went wrong", "error");
    }
  };

  // ****** Bikash Code End  *******

  const fetchMyMaterial = useCallback(() => {
    setAllMaterials(data?.myMaterials);
    setTabecount(1);
  });

  const fetchIllumiyaMaterial = useCallback(() => {
    setAllMaterials(data?.illumiyaMaterials);
    setTabecount(2);
  });

  const fetchAllMaterials = async () => {
    try {
      const res = await getMaterialGamesList();
      console.log("allmaterial", res);

      localStorage.setItem("materialsData", JSON.stringify(res));
      setdata(res);
      setAllMaterials(res?.myMaterials);
    } catch (e) {}
  };

  // SerchData here
  useEffect(() => {
    // apply filter on categoryName, description, title

    let greadsearchReselt = allMaterials;
    if (gred !== "0") {
      greadsearchReselt = greadsearchReselt?.filter(
        (m) => m.studentGrade === gred,
      );
    }

    const textSearchReseult = greadsearchReselt?.filter(
      (game) =>
        game.categoryName
          ?.toLowerCase()
          .trim()
          .includes(searchMyKeyword.toLowerCase()) ||
        game.title
          ?.toLowerCase()
          .trim()
          .includes(searchMyKeyword.toLowerCase()) ||
        game.description
          ?.toLowerCase()
          .trim()
          .includes(searchMyKeyword.toLowerCase()),
    );
    setSearchData(textSearchReseult);
    setCurrentPage(1);
  }, [allMaterials, gred, searchMyKeyword]);

  // pagenation facution

  useEffect(() => {
    const lastPostIndex = currentPage * postsPerPage;
    const firstPostIndex = lastPostIndex - postsPerPage;
    const currentPosts = searchData?.slice(firstPostIndex, lastPostIndex);
    setFinalData(currentPosts);
  }, [searchData, currentPage]);

  const handelChange = (e, p) => {
    setCurrentPage(p);
  };

  useEffect(() => {
    const materials = JSON.parse(localStorage.getItem("materialsData"));

    setdata(materials);
    fetchGameSubjects();
    if (newGameFormData.selectedSubject) {
      fetchGameTopics(newGameFormData.selectedSubject);
    }
    fetchAllMaterials();
  }, []);

  useEffect(() => {
    if (newGameFormData.selectedSubject) {
      fetchGameTopics(newGameFormData.selectedSubject);
    }
  }, [newGameFormData.selectedSubject]);

  const deleteMaterialHandler = async (material) => {
    setConfirmationModal({
      open: true,
      detail: "",
      heading: "Are you sure you want to delete the material?",
      selectedId: material.id,
      successHandler: async () => {
        try {
          const res = await deleteMaterial(material.id);
          console.log("delete res", res);
          if (res) {
            resetConfirmationState();
            showNotification("material deleted successfully");
            fetchAllMaterials();
          }
          if (!res) throw new Error("Something went wrong");
        } catch (e) {
          snackOpen("Something went wrong", "error");
          console.log(e);
        }
      },
    });
  };

  const resetConfirmationState = () =>
    setConfirmationModal({
      open: false,
      cancelHandler: () => {},
      confirmHandler: () => {},
      heading: "",
      selectedId: null,
      successHandler: () => {},
      detail: "",
    });

  const addGameToGameflowHandler = async (gameflowId, selectedMaterial) => {
    try {
      console.log("selectedMaterial", selectedMaterial);
      const gameflowDetails = await getGameflowById(gameflowId);
      console.log("gameflowDetails", gameflowDetails);
      console.log("selectedMaterial", selectedMaterial);
      console.log("gameflowDetails.games", gameflowDetails.games);

      let _games = gameflowDetails.games.map((item) => {
        return {
          gamePosition: item.gamePosition,
          gameType: item.gameType,
          gameId: item.gameId,
        };
      });

      let newGame = {
        gamePosition: _games.length,
        gameType: 4,
        gameId: selectedMaterial.id,
      };
      _games.push(newGame);

      console.log("_games", _games);

      const editResult = await editGameflowGames(+gameflowId, _games);
      console.log("editResult", editResult);
      await refetchGamesData();
      showNotification("Gameflow saved successfully");
      setTimeout(() => {
        setGameFlowSelection({ open: false, selectedGame: null });
      }, 2000);
    } catch (e) {
      console.log(
        "error in addGameToGameflowHandler in EducatorbaseGameFlow.js",
        e,
      );
      snackOpen("Error in adding game to gameflow", "error");
    }
  };

  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderGames />
      {/* end header */}
      <CustomSnackbar {...snackProps} />

      {!gameFlowSelection.open && (
        <div className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs">
          <div className="gamestabs-main mymateial-main">
            <Container>
              <div className="Minigames-bx">
                <Tabs>
                  <TabList className="tabslist-gamestp">
                    <Tab onClick={changeTab} name="my_material">
                      My materials{" "}
                    </Tab>
                    <Tab onClick={changeTab} name="illumiya_material">
                      Neithedu materials
                    </Tab>
                  </TabList>
                  <TabPanel>
                    <div className="minigames-cards">
                      <div className="games-topserch-bar">
                        <Form className="matertial-frm">
                          <Row className="align-center">
                            <div className="col-md-3 one">
                              <div className="list-study">
                                <Form.Control
                                  as="select"
                                  value={gred}
                                  onChange={(e) => setGred(e.target.value)}
                                >
                                  <option value="0">All years of study</option>
                                  {grades.map((val, index) => {
                                    return (
                                      <option
                                        value={val}
                                        key={index}
                                      >{`${val}th year of study`}</option>
                                    );
                                  })}
                                </Form.Control>
                              </div>
                            </div>
                            <div className="col-md-6 two">
                              <div className="serch-bx-games">
                                <div className="serchfiled-activities">
                                  <Form.Control
                                    type="text"
                                    placeholder="Start typing title, subject or any other keyword "
                                    className="formbox"
                                    value={searchMyKeyword}
                                    onChange={(event) =>
                                      setSearchMyKeyword(event.target.value)
                                    }
                                  />
                                  <Button type="button" className="seachbtn">
                                    <i className="fas fa-search"></i>
                                  </Button>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3 three">
                              <div className="createnew-games">
                                <div className="game-opn-create">
                                  <Link
                                    to="#"
                                    className="btnweak-bx gamestbs-btn"
                                    onClick={() =>
                                      setgamecreatestep1ModalShow(true)
                                    }
                                  >
                                    Create a new material
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </Row>
                        </Form>
                      </div>

                      <div className="heading-gamesbtm">
                        <h3>My Materials </h3>
                      </div>
                      <div className="choose-learning-list">
                        <ul className="learner-listing-games">
                          <li>
                            <button
                              className={`btn-learner-lsit  ${
                                myMaterialType === materialType.all
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() =>
                                setMyMaterialType(materialType.all)
                              }
                            >
                              All materials
                            </button>
                          </li>
                          <li>
                            <button
                              className={`btn-learner-lsit  ${
                                myMaterialType === materialType.video
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() =>
                                setMyMaterialType(materialType.video)
                              }
                            >
                              Video
                            </button>
                          </li>
                          <li>
                            <button
                              className={`btn-learner-lsit  ${
                                myMaterialType === materialType.text
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() =>
                                setMyMaterialType(materialType.text)
                              }
                            >
                              Text
                            </button>
                          </li>
                          <li>
                            <button
                              className={`btn-learner-lsit  ${
                                myMaterialType === materialType.document
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() =>
                                setMyMaterialType(materialType.document)
                              }
                            >
                              Document
                            </button>
                          </li>
                          <li>
                            <button
                              className={`btn-learner-lsit  ${
                                myMaterialType === materialType.audio
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() =>
                                setMyMaterialType(materialType.audio)
                              }
                            >
                              Audio
                            </button>
                          </li>
                          <li>
                            <button
                              className={`btn-learner-lsit  ${
                                myMaterialType === materialType.image
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() =>
                                setMyMaterialType(materialType.image)
                              }
                            >
                              Image
                            </button>
                          </li>
                          <li>
                            <button
                              className={`btn-learner-lsit  ${
                                myMaterialType === materialType.link
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() =>
                                setMyMaterialType(materialType.link)
                              }
                            >
                              Link
                            </button>
                          </li>
                        </ul>
                      </div>
                      {filalData?.length === 0 && (
                        <p style={{ color: "white" }}>No material found!</p>
                      )}
                      <div className="card-quizgames">
                        <Row>
                          {filalData?.map((material, index) => (
                            <div className="col-md-3 onecard" key={index}>
                              <div className="inner-cardquizz">
                                <div className="inner-bodyquiz">
                                  <div className="top-list-quiz">
                                    <div className="addlist-quiz">
                                      <div className="quiz-listng-iner quiz-leftcard">
                                        <div className="btn-listnew">
                                          <button
                                            className="attachment-quiz"
                                            onClick={() => {
                                              setAddgroupstep2ModalShow(true);
                                              setGemdeteles(material);
                                            }}
                                          >
                                            <img src={darkblueplan} />
                                          </button>
                                        </div>
                                        <div className="btn-listnew">
                                          <div
                                            onClick={() =>
                                              setGameFlowSelection({
                                                open: true,
                                                game: material,
                                                dataToFilter:
                                                  allGamesData.myGameflows,
                                              })
                                            }
                                            className="attachment-quiz cursor-pointer"
                                          >
                                            <img src={addbtnround} />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="quiz-listng-iner quiz-rightcard">
                                        <div className="btn-listnew">
                                          <Dropdown className="dropdown-main dropmenu-custlistt">
                                            <Dropdown.Toggle
                                              variant="success"
                                              id="dropdown-basic"
                                              className="dropdown-btnn"
                                            >
                                              <img src={dotthree} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className="dropdown-btn-item customdrop-listmenu">
                                              <Dropdown.Item
                                                onClick={() => {
                                                  deleteMaterialHandler(
                                                    material,
                                                  );
                                                }}
                                              >
                                                Delete
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                href="#"
                                                onClick={() =>
                                                  setgroupmemberstep3ModalShow(
                                                    true,
                                                  )
                                                }
                                              >
                                                {" "}
                                                Apply to publish
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="quiz-body-content">
                                    <div className="quiz-bdy-content-bx">
                                      <h3>{material.title}</h3>
                                      <div className="type-material-iner">
                                        {material.noOfVideos > 0 && (
                                          <p>{material.noOfVideos} videos</p>
                                        )}
                                        {material.noOfAudios > 0 && (
                                          <p>{material.noOfAudios} audios</p>
                                        )}

                                        {material.noOfImages > 0 && (
                                          <p>{material.noOfImages} images</p>
                                        )}

                                        {material.noOfPdf > 0 && (
                                          <p>{material.noOfPdf} pdf's</p>
                                        )}
                                      </div>
                                      <h5>{material.categoryName}</h5>
                                      <h4>
                                        {material.studentGrade}th year of study
                                      </h4>
                                      <p>{material.description}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Pagination
                              count={
                                Math?.ceil(searchData?.length / postsPerPage) ||
                                0
                              }
                              color="primary"
                              onChange={handelChange}
                              page={currentPage}
                            />
                          </Box>
                        </Row>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="minigames-cards">
                      <div className="games-topserch-bar">
                        <Form className="matertial-frm">
                          <Row className="align-center">
                            <div className="col-md-3 one">
                              <div className="list-study">
                                <Form.Control
                                  as="select"
                                  value={gred}
                                  onChange={(e) => setGred(e.target.value)}
                                >
                                  <option value="">All years of study</option>
                                  {grades.map((val, index) => {
                                    return (
                                      <option
                                        value={val}
                                        key={index}
                                      >{`${val}th year of study`}</option>
                                    );
                                  })}
                                </Form.Control>
                              </div>
                            </div>
                            <div className="col-md-6 two">
                              <div className="serch-bx-games">
                                <div className="serchfiled-activities">
                                  <Form.Control
                                    type="text"
                                    placeholder="Start typing title, subject or any other keyword "
                                    className="formbox"
                                    value={searchMyKeyword}
                                    onChange={(event) =>
                                      setSearchMyKeyword(event.target.value)
                                    }
                                  />
                                  <Button type="button" className="seachbtn">
                                    <i className="fas fa-search"></i>
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Row>
                        </Form>
                      </div>

                      <div className="heading-gamesbtm">
                        <h3>My Materials </h3>
                      </div>
                      <div className="choose-learning-list">
                        <ul className="learner-listing-games">
                          <li>
                            <button
                              className={`btn-learner-lsit  ${
                                myMaterialType === materialType.all
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() =>
                                setMyMaterialType(materialType.all)
                              }
                            >
                              All materials
                            </button>
                          </li>
                          <li>
                            <button
                              className={`btn-learner-lsit  ${
                                myMaterialType === materialType.video
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() =>
                                setMyMaterialType(materialType.video)
                              }
                            >
                              Video
                            </button>
                          </li>
                          <li>
                            <button
                              className={`btn-learner-lsit  ${
                                myMaterialType === materialType.text
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() =>
                                setMyMaterialType(materialType.text)
                              }
                            >
                              Text
                            </button>
                          </li>
                          <li>
                            <button
                              className={`btn-learner-lsit  ${
                                myMaterialType === materialType.document
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() =>
                                setMyMaterialType(materialType.document)
                              }
                            >
                              Document
                            </button>
                          </li>
                          <li>
                            <button
                              className={`btn-learner-lsit  ${
                                myMaterialType === materialType.audio
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() =>
                                setMyMaterialType(materialType.audio)
                              }
                            >
                              Audio
                            </button>
                          </li>
                          <li>
                            <button
                              className={`btn-learner-lsit  ${
                                myMaterialType === materialType.image
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() =>
                                setMyMaterialType(materialType.image)
                              }
                            >
                              Image
                            </button>
                          </li>
                          <li>
                            <button
                              className={`btn-learner-lsit  ${
                                myMaterialType === materialType.link
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() =>
                                setMyMaterialType(materialType.link)
                              }
                            >
                              Link
                            </button>
                          </li>
                        </ul>
                      </div>
                      {filalData?.length === 0 && (
                        <p style={{ color: "white" }}>No material found!</p>
                      )}
                      <div className="card-quizgames">
                        {console.log("filtereddata", filalData)}
                        <Row>
                          {filalData?.map((material, index) => (
                            <div className="col-md-3 onecard" key={index}>
                              <div className="inner-cardquizz">
                                <div className="inner-bodyquiz">
                                  <div className="top-list-quiz">
                                    <div className="addlist-quiz">
                                      <div className="quiz-listng-iner quiz-leftcard">
                                        <div className="btn-listnew">
                                          <button
                                            className="attachment-quiz"
                                            onClick={() => {
                                              setAddgroupstep2ModalShow(true);
                                              setGemdeteles(material);
                                            }}
                                          >
                                            <img src={darkblueplan} />
                                          </button>
                                        </div>
                                        <div className="btn-listnew">
                                          <div
                                            onClick={() =>
                                              setGameFlowSelection({
                                                open: true,
                                                game: material,
                                                dataToFilter:
                                                  allGamesData.myGameflows,
                                              })
                                            }
                                            className="attachment-quiz cursor-pointer"
                                          >
                                            <img src={addbtnround} />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="quiz-body-content">
                                    <div className="quiz-bdy-content-bx">
                                      <h3>{material.title}</h3>
                                      <div className="type-material-iner">
                                        <p>Video</p>
                                      </div>
                                      <h5>{material.categoryName}</h5>
                                      <h4>
                                        {material.studentGrade}th year of study
                                      </h4>
                                      <p>{material.description}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Pagination
                              count={
                                Math.ceil(searchData?.length / postsPerPage) ||
                                0
                              }
                              color="primary"
                              onChange={handelChange}
                              page={currentPage}
                            />
                          </Box>
                        </Row>
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
              {confirmationModal.open && (
                <ConfirmationModal
                  open={confirmationModal.open}
                  heading={confirmationModal.heading}
                  detail={confirmationModal.detail}
                  successHandler={confirmationModal.successHandler}
                  closeModal={resetConfirmationState}
                  cancelHandler={resetConfirmationState}
                />
              )}
            </Container>
          </div>

          {/* footer start */}
          <FooterEducator logo={logo} />
          {/* footer end */}
        </div>
      )}

      {gameFlowSelection.open && (
        <Gamesflowphotos
          type="material"
          closeModal={() =>
            setGameFlowSelection({ open: false, game: {}, dataToFilter: [] })
          }
          selectedGame={gameFlowSelection.game}
          data={gameFlowSelection.dataToFilter}
          submitHandler={addGameToGameflowHandler}
        />
      )}

      {/* start modal my student */}

      {/* start Add a Groupmember step2 */}

      <ActivitesSendModal
        game={gameDetels}
        modalgamesavedstep2Show={modalAddgroupstep2Show}
        activityTypeId={tabcount === 1 ? 5 : 6}
        setgamesavedstep2ModalShow={setAddgroupstep2ModalShow}
      />

      {/* start Add a Member Group step2 */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        // {...props}
        show={modalgroupmemberstep3Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2 publish-matrial">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Do you want to apply this material to publish?</h4>
              <p>
                The material will be reviewed by our experts. If the material is
                relevant and interesting, it will be posted on the site for
                general use and you will receive $20
              </p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setgroupmemberstep3ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="btn-delet-bx">
                    <ul>
                      <li>
                        <button
                          className="delet-ys btn-grup-class btnweak-bx cancel-btn"
                          type="submit"
                          onClick={() => {
                            setgroupmemberstep3ModalShow(false);
                            setthanksstep2ModalShow(true);
                          }}
                        >
                          Apply
                        </button>
                      </li>
                      <li>
                        <button
                          className="delet-no btn-grup-class btnweak-bx delbtn"
                          type="submit"
                          onClick={() => setgroupmemberstep3ModalShow(false)}
                        >
                          Cancel
                        </button>
                      </li>
                    </ul>
                  </div>
                </Form.Group>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end Add a Member Group step3 */}

      {/* mend apply to publish popup */}

      {/* start Thankas appliaction */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        // {...props}
        show={modalthanksstep2Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2 thanks-applictn">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Thanks! Your applications sent</h4>
              <p>You will receive notification with result of review</p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setthanksstep2ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="btn-delet-bx">
                    <button
                      className=" btnweak-bx btnlogin thanxbtn"
                      type="submit"
                      onClick={() => setthanksstep2ModalShow(false)}
                    >
                      Thanks, i understood
                    </button>
                  </div>
                </Form.Group>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end Thankas appliactionup */}

      {/* start create a new games */}

      {/* <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        // {...props}
        show={modalgamecreatestep1Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2 mystudent-games">
          <CustomSnackbar {...snackProps} />

          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>New Material</h4>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setgamecreatestep1ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="newgames-createbx">
                    <Row>
                      <div className="col-md-12">
                        <Form.Label>Title of the game</Form.Label>
                        <Form.Control
                          value={newGameFormData.title}
                          onChange={(e) =>
                            setnewGameFormData((state) => ({
                              ...state,
                              title: e.target.value,
                            }))
                          }
                          type="text"
                          placeholder="Help your mother"
                        ></Form.Control>
                      </div>
                      <div className="col-md-12">
                        <Form.Label>Type of the game</Form.Label>
                        <Form.Control
                          onChange={(e) => {
                            setnewGameFormData((prevState) => ({
                              ...prevState,
                              selectedGameType: e.target.value,
                            }));
                          }}
                          value={newGameFormData.selectedGameType}
                          as="select"
                        >
                          {newGameFormData.gameTypes.map((type) => {
                            return (
                              <option key={type.id} value={type.id}>
                                {type.name}
                              </option>
                            );
                          })}{" "}
                        </Form.Control>
                      </div>
                      <div className="col-md-12">
                        <Form.Label>Subject</Form.Label>
                        <Form.Control
                          onChange={(e) =>
                            setnewGameFormData((prevState) => ({
                              ...prevState,
                              selectedSubject: e.target.value,
                            }))
                          }
                          value={newGameFormData.selectedSubject}
                          as="select"
                        >
                          {newGameFormData.subjects.map((subject) => {
                            return (
                              <option key={subject.id} value={subject.id}>
                                {subject.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </div>
                      <div className="col-md-12">
                        <Form.Label>Topics</Form.Label>
                        <Form.Control
                          as="select"
                          onChange={(e) =>
                            setnewGameFormData((prevState) => ({
                              ...prevState,
                              selectedTopic: e.target.value,
                            }))
                          }
                          value={newGameFormData.selectedTopic}
                        >
                          {newGameFormData.topics.map((topic) => {
                            return (
                              <option key={topic.id} value={topic.id}>
                                {topic.topicName}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </div>
                      <div className="col-md-12">
                        <Form.Label>For children</Form.Label>
                        <Form.Control
                          as="select"
                          onChange={(e) =>
                            setnewGameFormData((prevState) => ({
                              ...prevState,
                              studentGrade: e.target.value,
                            }))
                          }
                          value={newGameFormData.studentGrade}
                        >
                          {grades.map((val, index) => {
                            return (
                              <option
                                value={val}
                                key={index}
                              >{`${val}th year of study`}</option>
                            );
                          })}
                        </Form.Control>
                      </div>
                      <div className="col-md-12">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          value={newGameFormData.description}
                          onChange={(e) =>
                            setnewGameFormData((state) => ({
                              ...state,
                              description: e.target.value,
                            }))
                          }
                          as="textarea"
                          placeholder="Add some text that only you will see with any notes, descriptions etc"
                        />
                      </div>
                    </Row>
                  </div>
                </Form.Group>

                <div className="btnlogin-main">
                  <Box onClick={saveGame} className="btnlogin btnweak-bx">
                    Save and go creating
                  </Box>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal> */}

      <GameFlowModal
        open={modalgamecreatestep1Show}
        closeModal={() => setgamecreatestep1ModalShow(false)}
        modalTitle="Create a new material"
        footerText="Save and go creating"
        submitHandler={saveGame}
        newGame={true}
        GameTypes={GameTypes}
      />
    </div>
  );
}

export default Mymaterials;
