import React from "react";

function Plan() {
  return (
    <div className="App">
      <p>Plan page Goes Here</p>
    </div>
  );
}

export default Plan;
