import React, { useState, useEffect } from "react";
import "../home.scss";
import "../profile.scss";
import "../loginModal/login.scss";
import "../dashboard.scss";
import "../sidemenu.scss";
import "../media.scss";
// import { useActions } from "../../overmind";

import logo from "../../assets/images/logo.png";
// import gift from "../../assets/images/gift.svg";
// import plus from "../../assets/images/plus.svg";
// import count1 from "../../assets/images/count1.svg";
// import count2 from "../../assets/images/count2.svg";
// import circle2 from "../../assets/images/circle2.png";
// import circle1 from "../../assets/images/circle1.png";
// import circle3 from "../../assets/images/circle3.png";
// import circle4 from "../../assets/images/circle4.png";
// import circle5 from "../../assets/images/circle5.png";
// import circleline from "../../assets/images/circle-ring.png";

// import gamepic1 from "../../assets/images/gamesoon-pic.png";

// import { Link } from "react-router-dom";
import Footer from "../layout/Footer/Footer";
import { HeaderDashboard } from "../layout/HeaderDashboard";

import { SubjectSlider } from "./CategoryList";
import { StudentTrial } from "../dialogs/StudentTrial";
import { ReceiveMorePoints } from "../dialogs/ReceiveMorePoints";
// import { FirstLogin } from "../student/FirstLogin";

// import { useAppState } from "../../overmind";
import { useHistory } from "react-router-dom";
// import Loading from "../Loader/index";
import { MobileViewHeader } from "../layout/MobileViewHeader";
import { USER_DETAILS } from "../../service/LocalStorage";
// import { HeaderStudent } from "../layout/Header/HeaderStudent";

function Dashbaord() {
  const [openEarnPointsDialog, setopenEarnPointsDialog] = useState(false);
  const [openTrial, setopenTrial] = useState(false);
  const history = useHistory();
  var user = localStorage.getItem(USER_DETAILS);
  var userInfo = JSON.parse(user);
  // const isNewUser = useAppState().isNewUser;
  // let userDetail = useAppState().userDetail;
  useEffect(() => {
    // if (!userDetail || !userDetail.applicationUser) {
    //   history.push("/");
    // }
    if (
      userInfo?.applicationUser.syllabusId === 0 ||
      userInfo?.applicationUser.syllabusId == null
    ) {
      history.push("/studentProfile");
    }
  }, []);
  // if (!userDetail) {
  //   return <Loading />;
  // }
  return (
    <div className="home-wrapper">
      {openTrial && <StudentTrial closeHandler={() => setopenTrial(false)} />}
      {openEarnPointsDialog && (
        <ReceiveMorePoints
          closeHandler={() => setopenEarnPointsDialog(false)}
        />
      )}
      {/* start new user bar top */}

      {/* <div className="top-annoucebar">
        <div className="user-topbar-contain">
          <div className="user-topbar-box">
            <div className="customrow">
              <div className="leftbox-user-box">
                <div className="user-educt-content">
                  <div className="title-boxx">
                    Your trial period of Personal education is active for 7 days
                  </div>
                </div>
              </div>

              <div className="center-user-box">
                <div className="timer-card">
                  <ul>
                    <li>
                      <div className="timer-counter-new">
                        <span className="timer-numm">05</span>
                        <span className="timer-day">days</span>
                      </div>
                    </li>
                    <li>
                      <div className="timer-counter-new">
                        <span className="timer-numm">14</span>
                        <span className="timer-day">hours</span>
                      </div>
                    </li>
                    <li>
                      <div className="timer-counter-new">
                        <span className="timer-numm">37</span>
                        <span className="timer-day">minutes</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="right-user-box">
                <div className="free-lesson">
                  <div className="free-lesson-content">
                    <div className="free-lesson-tex">
                      It is access <span className="texbld">to all games</span>{" "}
                      Free lesson with a tutor
                    </div>
                  </div>
                  <div className="btn-rightbx">
                    <div className="learn-more-bttn">
                      <Link
                        onClick={() => setopenTrial(true)}
                        to="#"
                        className="btn-learn-blue"
                      >
                        Learn more
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* en new user bar top */}

      {/* start header */}
      <HeaderDashboard />
      {/* <HeaderStudent /> */}

      {/* end header */}

      <div className="dasboard-iiner">
        {/* mobile view topbar filter */}
        <MobileViewHeader />

        {/* mobile view topbar filter end*/}
        {/* {isNewUser ? (
          <FirstLogin />
        ) : (
          //
          <div className="container-dashboard">
            <div className="game-soon">
              <div className="gamerow">
                <div className="gamesoon-mdd">
                  <div className="gamesoon-left">
                    <div className="toptex-game">
                      <h4>Create game soon!</h4>
                      <div className="texsml-total">
                        To open the game the total<br></br> number of points
                        must be 20,000
                      </div>
                    </div>
                    <div className="takepart-tex">
                      take part in community goal!
                    </div>
                  </div>
                </div>
                <div className="gamesoom-medium">
                  <div className="gamesoon-centerbx">
                    <h5>Remains to collect</h5>
                    <div className="gamesoon-count">
                      <ul>
                        <li>
                          <span className="number-remail">1</span>
                        </li>
                        <li>
                          <span className="number-remail">8</span>
                        </li>
                        <li>
                          <span className="number-remail">0</span>
                        </li>
                        <li>
                          <span className="number-remail">5</span>
                        </li>
                        <li>
                          <span className="number-remail">7</span>
                        </li>
                      </ul>
                    </div>

                    <div className="alluser">general points of all users</div>
                    <div className="eachpoint">
                      <Link
                        to="#"
                        onClick={() => setopenEarnPointsDialog(true)}
                        className="eachpoint-earn"
                      >
                        How to earn points
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="gamesoon-right">
                  <div className="gamesonn-pic">
                    <img src={gamepic1} className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )} */}
        {/* <div className="circl-box">
          <div className="circlbox-container">
            <div className="circlebx-list">
              <div className="back-circl-dot">
                <ul className="backdot-listt">
                  <li className="lg-dot">
                    <div className="dotcircl-bk">
                      <div className="dotcrl-icn"></div>
                    </div>
                  </li>
                  <li className="md-dot">
                    <div className="dotcircl-bk">
                      <div className="dotcrl-icn"></div>
                    </div>
                  </li>
                  <li className="sml-dot">
                    <div className="dotcircl-bk">
                      <div className="dotcrl-icn"></div>
                    </div>
                  </li>
                  <li className="md-dot">
                    <div className="dotcircl-bk">
                      <div className="dotcrl-icn"></div>
                    </div>
                  </li>
                  <li className="lg-dot">
                    <div className="dotcircl-bk">
                      <div className="dotcrl-icn"></div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="circl-game-box">
                <ul className="list-game-cardbd">
                  <li className="smalbox">
                    <div className="circllisting-game">
                      <div className="game-circle-card">
                        <div className="gamecircle-card-pic">
                          <img src={circle1} className="img-fluid" />
                        </div>
                        <div className="gamecircle-tex">
                          <h5>English</h5>
                          <h6>54 games</h6>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li className="medimbox">
                    <div className="circllisting-game">
                      <div className="game-circle-card">
                        <div className="gamecircle-card-pic">
                          <img src={circle2} className="img-fluid" />
                        </div>
                        <div className="gamecircle-tex">
                          <h5>History</h5>
                          <h6>62 games</h6>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li className="lginbox">
                    <div className="circllisting-game">
                      <div className="game-circle-card">
                        <div className="gamecircle-card-pic">
                          <img src={circle3} className="img-fluid" />
                        </div>
                        <div className="gamecircle-tex">
                          <h5>Science</h5>
                          <h6>89 games</h6>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li className="medimbox">
                    <div className="circllisting-game">
                      <div className="game-circle-card">
                        <div className="gamecircle-card-pic">
                          <img src={circle4} className="img-fluid" />
                        </div>
                        <div className="gamecircle-tex">
                          <h5>Maths</h5>
                          <h6>31 games</h6>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li className="smalbox">
                    <div className="circllisting-game">
                      <div className="game-circle-card">
                        <div className="gamecircle-card-pic">
                          <img src={circle5} className="img-fluid" />
                        </div>
                        <div className="gamecircle-tex">
                          <h5>Others</h5>
                          <h6>95 games</h6>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="circle-linebx">
                <img src={circleline} className="img-fluid" />
              </div>
            </div>
          </div>
        </div> */}
        <SubjectSlider />
      </div>

      <div className="testimaonial-section footer-dasboard">
        <Footer logo={logo} />
      </div>
    </div>
  );
}

export default Dashbaord;
