import React from "react";
import { Modal } from "react-bootstrap";

export const ConfirmationDialog = ({
  closeDialog,
  isOpen,
  signInScreenHandler,
}) => {
  return (
    <div>
      {" "}
      <Modal
        className="loginmodal student-login-main student-signup-step4 educator-signup-step4"
        show={isOpen}
        onHide={closeDialog}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header closeButton>
            <div className="btnback">
              {/* <Link to="#" onClick={() => backClick(8)} className="backbtn-icn">
                <i className="fas fa-arrow-left"></i>
              </Link> */}
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Registration successfully completed!</h4>
              {/* <p>
                You should confirm your mobile phone in order to save your
                account from being hacked
              </p> */}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <div className="btnlogin-main">
                  <button
                    type="submit"
                    className="btnlogin btnweak-bx"
                    onClick={signInScreenHandler}
                  >
                    Go to signin
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};
