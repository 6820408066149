import { Box, Grid, Modal, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { StyledPurpleButton } from "../../utils/Buttons";
import { useHistory } from "react-router";

const badges = [
  {
    img: "/images/badge2.png",
    title: "Custom Badge",

    qty: 0,
  },
  {
    img: "/images/badge2.png",
    title: "Working hard",

    qty: 0,
  },
  {
    img: "/images/badge2.png",
    title: "Helping others",

    qty: 0,
  },
  {
    img: "/images/badge2.png",
    title: "Working hard",

    qty: 0,
  },
  {
    img: "/images/badge1.png",
    title: "Helping others",

    qty: 0,
  },
  {
    img: "/images/badge1.png",
    title: "Working hard",

    qty: 0,
  },
  {
    img: "/images/badge1.png",
    title: "Working hard",

    qty: 0,
  },
  {
    img: "/images/badge1.png",
    title: "Working hard",

    qty: 0,
  },
  {
    img: "/images/badge1.png",
    title: "Working hard",

    qty: 0,
  },
];

export const BuyBadges = ({ closeHandler }) => {
  const history = useHistory();
  const [state, setstate] = useState({
    badges,
  });

  const totalQty = state.badges.reduce((prev, curr) => prev + curr.qty, 0);
  const totalPrice = state.badges.reduce(
    (prev, curr) => prev + curr.qty * 0.4,
    0,
  );

  const incrementBadge = (idx) => {
    setstate((prevState) => {
      const badges = [...prevState.badges];
      badges[idx] = { ...badges[idx], qty: badges[idx].qty + 1 };
      return { ...prevState, badges };
    });
  };
  const decrementBadge = (idx) => {
    setstate((prevState) => {
      const badges = [...prevState.badges];
      badges[idx] = {
        ...badges[idx],
        qty: badges[idx].qty === 0 ? 0 : badges[idx].qty - 1,
      };
      return { ...prevState, badges };
    });
  };
  const Badge = ({ imageSrc, badgeTitle, qty, increment, decrement }) => {
    return (
      <Box
        sx={{
          cursor: "pointer",
          height: "15rem",
          backgroundColor: "#EDEDFF",
          position: "relative",
          borderRadius: "10px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
        }}
      >
        <AddIcon
          onClick={increment}
          sx={{
            borderRadius: "50%",
            p: "0.2rem",
            fontSize: "1.5rem",
            top: "5px",
            right: "5px",
            position: "absolute",
            backgroundColor: "var(--sky)",
            color: "var(--purple-dark)",
          }}
        />
        <RemoveIcon
          onClick={decrement}
          sx={{
            position: "absolute",

            top: "5px",
            left: "5px",
            backgroundColor: "var(--orange)",
            p: "0.2rem",
            fontSize: "1.5rem",
            borderRadius: "50%",
            color: "white",
          }}
        />
        <Box sx={{ height: "45%" }}>
          <img
            src={imageSrc}
            style={{
              width: "100%",
              borderTopRightRadius: "10px",
              borderTopLeftRadius: "10px",
            }}
            alt={badgeTitle}
          />
        </Box>
        <Box
          sx={{
            height: "55%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            p: "1rem",
          }}
        >
          <Typography sx={{ color: "var(--purple-dark)", fontSize: "1.2rem" }}>
            Badge for
          </Typography>
          <Typography
            sx={{
              fontWeight: "700",
              color: "var(--orange)",
              fontSize: "1.3rem",
            }}
          >
            {badgeTitle}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              columnGap: "2rem",
            }}
          >
            <Typography>{qty} x $0.40</Typography>
            <Typography fontWeight={"700"}>
              ${(qty * 0.4).toFixed(2)}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Modal
      // sx={{ overflowY: "scroll" }}
      open={true}
      onClose={() => {}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100%",
          p: "1rem",
        }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            maxWidth: "70rem",
            width: "100%",
            p: "1rem",
            borderRadius: "15px",
            position: "relative",
          }}
        >
          <CloseIcon
            onClick={closeHandler}
            sx={{
              cursor: "pointer",
              color: "var(--purple-dark)",
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
          />
          <Box
            sx={{
              mr: "3rem",
              alignItems: "center",
              mb: "2rem",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                color: "var(--purple-dark)",
                fontSize: "1.3rem",
                fontWeight: "700",
              }}
            >
              Buy new badges
            </Typography>
            <Stack alignItems={"center"} flexDirection="row" rowGap={"2rem"}>
              <Typography>
                {totalQty} x ${"0.40"}
              </Typography>
              <Typography
                sx={{ fontWeight: "700", fontSize: "1.2rem" }}
                mx="3rem"
              >
                ${totalPrice.toFixed(2)}
              </Typography>
              <Box sx={{ width: "12rem" }}>
                <StyledPurpleButton
                  sx={{ fontSize: "0.8rem" }}
                  title={"Buy selected badges"}
                  btnHandler={() => {
                    history.push("/educator/badgePayments", {
                      badges: state.badges.filter((badge) => badge.qty !== 0),
                    });
                  }}
                />
              </Box>
            </Stack>
          </Box>
          <Box>
            <Grid
              sx={{ overflowY: "scroll", maxHeight: "30rem" }}
              container
              rowSpacing={"1rem"}
              columnSpacing={"1rem"}
            >
              {state.badges.map((badge, idx) => {
                return (
                  <Grid key={idx} item md={4} xs={4} lg={3}>
                    <Badge
                      badgeTitle={badge.title}
                      imageSrc={badge.img}
                      decrement={() => decrementBadge(idx)}
                      increment={() => incrementBadge(idx)}
                      key={idx}
                      qty={badge.qty}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
