import { Box, Typography } from "@mui/material";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

export const RearrangeAnswers = ({
  answersList = [],
  dragEndHandler,
  itemHeight = "48px",
}) => {
  return (
    <div>
      <DragDropContext
        onDragEnd={(result) => {
          if (!result.destination) {
            return;
          }

          dragEndHandler(result.source.index, result.destination.index);
        }}
      >
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {answersList.map((ans, idx) => {
                return (
                  <Draggable
                    key={ans + " " + idx}
                    draggableId={ans + " " + idx}
                    index={idx}
                  >
                    {(provided) => (
                      <Box
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        key={ans + " " + idx}
                        sx={{
                          mb: "0.5rem",
                          backgroundColor: "#EDEDFF",
                          transform: "rotate(0deg)",
                          borderRadius: "10px",
                          width: "100%",
                          height: itemHeight,
                          display: "flex",
                          alignItems: "center",
                          p: "1rem",
                        }}
                      >
                        <div style={{ display: "flex", columnGap: "0.5rem" }}>
                          <Typography
                            sx={{
                              color: "var(--purple-dark)",
                              fontWeight: "700",
                              fontSize: "0.8rem",
                            }}
                          >
                            {idx + 1}.
                          </Typography>
                          <Typography
                            sx={{ fontWeight: "500", fontSize: "0.8rem" }}
                          >
                            {ans}{" "}
                          </Typography>
                        </div>
                      </Box>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
