import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import React from "react";
import { DialogBox } from "../utils/DialogBox";

export const TempNotificationModal = ({ isOpen, title }) => {
  return (
    <DialogBox isOpen={isOpen}>
      <Box
        sx={{
          minHeight: "5rem",
          minWidth: "20rem",
          position: "relative",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            color: "var(--purple-dark)",
            fontWeight: "700",
            fontSize: "1.4rem",
          }}
        >
          {title}
        </Typography>
      </Box>
    </DialogBox>
  );
};
