export const StyledTextField = ({
  sx = {},
  placeholder,
  onChangeHandler,
  value,
}) => {
  return (
    <input
      placeholder={placeholder}
      onChange={onChangeHandler}
      value={value}
      type={"text"}
      style={{
        "::placeholder": { height: "10rem" },
        marginBottom: "15px",
        height: "52px",
        borderRadius: "13px",
        backgroundColor: "rgba(106, 100, 230, 0.1)",
        border: "none",
        padding: "5px 20px",
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: "20px",
        color: "var(--purple)",
        width: "100%",
        ...sx,
      }}
    />
  );
};

export const StyledNumField = ({
  sx = {},
  placeholder,
  onChangeHandler,
  value,
}) => {
  return (
    <input
      placeholder={placeholder}
      onChange={onChangeHandler}
      min={0}
      value={value}
      type={"number"}
      style={{
        "::placeholder": { height: "10rem" },
        marginBottom: "15px",
        height: "52px",
        borderRadius: "13px",
        backgroundColor: "rgba(106, 100, 230, 0.1)",
        border: "none",
        padding: "5px 20px",
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: "20px",
        color: "var(--purple)",
        width: "100%",
        ...sx,
      }}
    />
  );
};

export const StyledSelectInputField = ({
  onChangeHandler,
  value,
  options = [],
}) => {
  return (
    <select
      onChange={onChangeHandler}
      value={value}
      style={{
        marginBottom: "15px",
        height: "52px",
        borderRadius: "13px",
        backgroundColor: "rgba(106, 100, 230, 0.1)",
        border: "none",
        padding: "5px 20px",
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: "20px",
        color: "var(--purple)",
        width: "100%",
      }}
    >
      {options.map((option, idx) => {
        return (
          <option key={idx} value={option}>
            {option}
          </option>
        );
      })}
    </select>
  );
};

let todaysDateInInputFormat = new Date()
  .toLocaleDateString()
  .split("/")
  .reverse()
  .join("-");
export const StyledInput = ({ props, style }) => {
  let valueOfDate = props.value;
  if (valueOfDate) {
    let dateArr = props?.value?.split("-");
    if (dateArr[2].length === 4) {
      dateArr = dateArr.reverse();
      valueOfDate = dateArr.join("-");
    }
  }
  console.log("valueOfDate", valueOfDate);

  return (
    <input
      {...props}
      type="date"
      variant="plain"
      // only allow date in future
      min={props.type === "date" ? todaysDateInInputFormat : ""}
      value={valueOfDate}
      style={{
        "::placeholder": { height: "10rem" },
        marginBottom: "15px",
        height: "52px",
        borderRadius: "13px",
        backgroundColor: "rgba(106, 100, 230, 0.1)",
        border: "none",
        padding: "5px 20px",
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: "20px",
        color: "var(--purple)",
        width: "100%",
        ...style,
      }}
    />
  );
};
