import React from "react";
import "./home.scss";
import "./profile.scss";
import "./media.scss";
import "./games.scss";
import "./myactivities.scss";
import "./Currentlesson.scss";
import { Container, Row, Form } from "react-bootstrap";

import logo from "../assets/images/logo.png";
import playvd from "../assets/images/play-vd.png";
import lessonpic1 from "../assets/images/lesson-pic1.jpg";
import lessonpic2 from "../assets/images/lesson-pic2.jpg";
import lessonpic3 from "../assets/images/lesson-pic3.jpg";
import lessonpic4 from "../assets/images/lesson-pic4.jpg";
import lessonpic5 from "../assets/images/lesson-pic5.jpg";
import attachment from "../assets/images/attachement.svg";
import plain from "../assets/images/plain.svg";

import { Link } from "react-router-dom";
import Footer from "./layout/Footer/Footer";
import { HeaderDashboard } from "./layout/HeaderDashboard";

function Currentlesson() {
  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderDashboard />
      {/* end header */}

      <div className="games-dashboard-main dashboard-bgnew blur-bgg">
        <div className="current-lesson-main ">
          <Container>
            <div className="current-lesson-contain">
              <div className="backbtn">
                <Link to="#" className="backbtnn-icnn">
                  <i className="fas fa-arrow-left"></i>
                </Link>
              </div>
              <div className="current-lesson-topbr">
                <div className="medm-tex orng-clr">Live lesson</div>
                <h1>
                  Title of the lesson for useful searching something about
                  something
                </h1>
                <div className="lesson-status-list">
                  <ul className="lesson-listing-curnt">
                    <li>
                      <div className="lesson-status-detail">
                        <span className="medm-tex orng-clr onelist">
                          Time:{" "}
                        </span>
                        <span className="medm-tex orng-clr twolist">
                          Oct 25, 2020
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="lesson-status-detail">
                        <span className="medm-tex orng-clr onelist">
                          Status:{" "}
                        </span>
                        <span className="medm-tex orng-clr twolist">
                          Completed
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="lesson-status-detail">
                        <span className="medm-tex orng-clr onelist">
                          Received:{" "}
                        </span>
                        <span className="medm-tex orng-clr twolist">
                          30 points + Badge for hard work
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Container>
        </div>

        <div className="lesson-chatbx">
          <div className="custm-container">
            <div className="chatbox-main">
              <Row>
                <div className="col-md-6 one">
                  <div className="leftvideo-box">
                    <div className="video-box-lessson">
                      <Row>
                        <div className="col-md-12 onepic">
                          <div className="video-glry">
                            <div className="videopic">
                              <img src={lessonpic1} className="img-fluid" />
                            </div>
                            <div className="plynow-btn">
                              <span className="playvd-btn">
                                <img src={playvd} className="img-fluid" />
                              </span>
                            </div>
                            <div className="resize-btn">
                              <button className="btn-resize-video">
                                <i className="fas fa-expand-alt"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 pictwo">
                          <div className="lesson-glrybx-new">
                            <img src={lessonpic2} className="img-fluid" />
                          </div>
                        </div>
                        <div className="col-md-6 pictwo">
                          <div className="lesson-glrybx-new">
                            <img src={lessonpic3} className="img-fluid" />
                          </div>
                        </div>
                        <div className="col-md-6 pictwo">
                          <div className="lesson-glrybx-new">
                            <img src={lessonpic4} className="img-fluid" />
                          </div>
                        </div>
                        <div className="col-md-6 pictwo">
                          <div className="lesson-glrybx-new">
                            <img src={lessonpic5} className="img-fluid" />
                          </div>
                        </div>
                      </Row>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 two">
                  <div className="chatbox-room">
                    <div className="chat-innerbox">
                      <div className="chat-upperbox">
                        <div className="custom-row">
                          <div className="chatbox-inner chat-sender">
                            <div className="message-box">
                              <div className="message-tex">
                                Ut enim ad minim veniam, quis nostrud
                                exercitation ullamco laboris nisi ut aliquip ex
                                ea commodo consequat.
                              </div>
                              <div className="message-time">
                                <span className="time">11.05</span>
                              </div>
                            </div>
                          </div>
                          <div className="chatbox-inner chat-receiver">
                            <div className="message-box">
                              <div className="message-tex">
                                Ut enim ad minim veniam, quis nostrud
                                exercitation ullamco laboris nisi ut aliquip ex
                                ea commodo consequat.
                              </div>
                              <div className="message-time">
                                <span className="time">11.05</span>
                              </div>
                            </div>
                          </div>
                          <div className="chatbox-inner chat-sender">
                            <div className="message-box">
                              <div className="message-tex">
                                Ut enim ad minim veniam, quis nostrud
                                exercitation ullamco laboris nisi ut aliquip ex
                                ea commodo consequat.
                              </div>
                              <div className="message-time">
                                <span className="time">11.05</span>
                              </div>
                            </div>
                          </div>
                          <div className="chatbox-inner chat-receiver">
                            <div className="message-box">
                              <div className="message-tex">
                                Ut enim ad minim veniam, quis nostrud
                                exercitation ullamco laboris nisi ut aliquip ex
                                ea commodo consequat.
                              </div>
                              <div className="message-time">
                                <span className="time">11.05</span>
                              </div>
                            </div>
                          </div>
                          <div className="chatbox-inner chat-sender">
                            <div className="message-box">
                              <div className="message-tex">
                                Ut enim ad minim veniam, quis nostrud
                                exercitation ullamco laboris nisi ut aliquip ex
                                ea commodo consequat.
                              </div>
                              <div className="message-time">
                                <span className="time">11.05</span>
                              </div>
                            </div>
                          </div>
                          <div className="chatbox-inner chat-receiver">
                            <div className="message-box">
                              <div className="message-tex">
                                Ut enim ad minim veniam, quis nostrud
                                exercitation ullamco laboris nisi ut aliquip ex
                                ea commodo consequat.
                              </div>
                              <div className="message-time">
                                <span className="time">11.05</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="chat-footer">
                    <div className="chat-footer-main">
                      <div className="chat-footer-message">
                        <div className="message-send-new">
                          <Form.Control
                            as="textarea"
                            className="message-textarea"
                            placeholder="Your message"
                          />
                          <div className="right-chaticon">
                            <ul>
                              <li>
                                <button className="sendme-btn ">
                                  <img src={plain} className="img-fluid" />
                                </button>
                              </li>
                              <li>
                                <button className="attachment-btn">
                                  <img src={attachment} className="img-fluid" />
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
            </div>
          </div>
        </div>

        {/* footer start */}
        <Footer logo={logo} />
        {/* footer end */}
      </div>
    </div>
  );
}

export default Currentlesson;
