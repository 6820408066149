import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./App.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-tabs/style/react-tabs.css";
import { createOvermind } from "overmind";
import { Provider } from "overmind-react";
import { config } from "./overmind/index";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import ContextStore from "./contexts/ContextStore";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient();

const overmind = createOvermind(config);
const theme = createTheme({});
ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <ContextStore>
      {/* <React.StrictMode> */}
      <BrowserRouter>
        <Provider value={overmind}>
          <ThemeProvider theme={theme}>
            <App />
            <ReactQueryDevtools />
          </ThemeProvider>
        </Provider>
      </BrowserRouter>
      {/* </React.StrictMode> */}
    </ContextStore>
  </QueryClientProvider>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
