import React, { useContext, useEffect, useState } from "react";
import { getEducatorStuGrp } from "../service/apis/educator";
import { Button, Form, Modal } from "react-bootstrap";
import { activitiesSendOneGame } from "../service/apis/activities";
import { useSnackState } from "../utils/useSnackState";
import { useHistory } from "react-router-dom";
import DeadlineMpdal from "./dialogs/DeadlineMpdal";
import { convertDateToString } from "../utils/helperFunctions";
import { NotificationContext } from "../contexts/Notification";
import { useLocation } from "react-router";

const ActivitesSendModal = ({
  modalgamesavedstep2Show,
  setgamesavedstep2ModalShow,
  game,
  activityTypeId,
}) => {
  const [students, setStudent] = useState([]);
  const [selectStudents, setSelectStudents] = useState([]);
  const [deadline, setdeadline] = useState(new Date());
  const [snackProps, open] = useSnackState();
  const { onClose } = snackProps;
  const history = useHistory();
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [querySearch, setquerySearch] = useState("");
  const [modalgamesavedstep1Show, setgamesavedstep1ModalShow] =
    React.useState(false);

  const { showNotification } = useContext(NotificationContext);

  const location = useLocation();

  const educatorStudentGroup = async () => {
    if (!modalgamesavedstep2Show) return;
    let gameId = game.id;
    let activityId = activityTypeId || game.activityTypeId;
    const res = await getEducatorStuGrp(gameId, activityId);
    setStudent(res);
  };

  const searchedStudentsGroups = students.filter((stugrp) => {
    return stugrp.name
      ?.toLowerCase()
      .trim()
      .includes(querySearch.toLowerCase());
  });

  const selectStudent = (studentId) => {
    console.log("studentId", studentId);
    const isSelected = Boolean(
      selectStudents.find((grpStud) => grpStud.id === studentId.id),
    );
    if (isSelected) {
      setSelectStudents(
        selectStudents.filter((item) => item.id !== studentId.id),
      );
    } else {
      setSelectStudents((pre) => [...pre, studentId]);
    }
  };

  const sendActivite = async () => {
    try {
      if (!deadline) {
        open("Please select deadline", "error");
        return;
      }

      console.log(
        "sendingthisdata",
        currentUser.applicationUser.id,
        convertDateToString(deadline),
        selectStudents,
        game,
        activityTypeId,
      );
      const isMaterial = location.pathname === "/Mymaterials";

      const res = await activitiesSendOneGame(
        currentUser.applicationUser.id,
        convertDateToString(deadline),
        selectStudents,
        game,
        activityTypeId,
      );
      if (res) {
        console.log("res of sending activity", selectStudents);
        setgamesavedstep1ModalShow(false);
        setgamesavedstep2ModalShow(false);
        showNotification(
          isMaterial ? "Material sent successfully" : "Game sent successfully",
        );

        setTimeout(() => {
          onClose();
          setSelectStudents([]);
          setgamesavedstep1ModalShow(false);
          setgamesavedstep2ModalShow(false);
          history.push({
            pathname: "/educator",
            search: `?studentId=${selectStudents[0]?.id}`,
          });
        }, 2000);
      }
    } catch (err) {
      console.log(err);
      open("Something went wrong", "error");
    }
  };

  useEffect(() => {
    educatorStudentGroup();
  }, [modalgamesavedstep2Show, modalgamesavedstep2Show]);

  console.log("all students", students);
  return (
    <div>
      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        show={modalgamesavedstep2Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2 mystudent-games">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>{game.title || game.activityName}</h4>
              <h5>Send the game to selected students</h5>
            </Modal.Title>
            <button
              type="button"
              onClick={() => {
                setSelectStudents([]);
                setgamesavedstep2ModalShow(false);
              }}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="serch-inner-group">
                    <div className="serchfiled-activities">
                      <Form.Control
                        type="text"
                        placeholder="Start typing name or year of study"
                        className="formbox"
                        value={querySearch}
                        onChange={(e) => setquerySearch(e.target.value)}
                      />
                      <Button type="button" className="seachbtn">
                        <i className="fas fa-search"></i>
                      </Button>
                    </div>
                  </div>
                  {searchedStudentsGroups?.length === 0 && (
                    <p style={{ color: "#6d6d6d" }}>
                      No student or group found!
                    </p>
                  )}
                  <div className="newpupil-list-bx">
                    <Form.Group className="subsform-fild child-tex-fld height-max-flow">
                      <div className="child-boxnew">
                        {searchedStudentsGroups?.map((item, i) => {
                          const isSelected = Boolean(
                            selectStudents.find(
                              (grpStud) => grpStud.id === item.id,
                            ),
                          );

                          return (
                            <div className="child-account" key={i}>
                              <div className="activ-detail-list">
                                <label className="custm-check">
                                  <Form.Control
                                    type="checkbox"
                                    checked={isSelected}
                                    onChange={() => {
                                      selectStudent(item);
                                    }}
                                  />
                                  <span className="checkmark"></span>
                                  <div className="active-detail-row">
                                    <div className="activiti-prof-icon">
                                      <span className="profile-icon-box">
                                        {item.isGroup ? (
                                          <i className="fas fa-users"></i>
                                        ) : (
                                          <i className="fas fa-user"></i>
                                        )}
                                      </span>
                                    </div>
                                    <div className="activiti-center-detail">
                                      <h5>{item.name}</h5>
                                      {/* <h6>India, Mumbai 1 year of study</h6> */}
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </Form.Group>
                  </div>
                </Form.Group>

                <div className="student-btn-savedgame">
                  <div className="btnlogin-main">
                    <button
                      type="submit"
                      className="btnlogin btnweak-bx"
                      onClick={() => {
                        if (selectStudents.length > 0) {
                          console.log("selectStudents", selectStudents);
                          setgamesavedstep2ModalShow(false);
                          setgamesavedstep1ModalShow(true);
                        }
                      }}
                    >
                      Send to selected students
                    </button>
                  </div>
                </div>
                <div className="btnlogin-main">
                  <button
                    onClick={() => setgamesavedstep2ModalShow(false)}
                    type="submit"
                    className="btnlogin btnweak-bx"
                  >
                    Later
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end Add a Member Group step2 */}
      <DeadlineMpdal
        open={modalgamesavedstep1Show}
        closeModal={() => {
          setgamesavedstep1ModalShow(false);
          setgamesavedstep2ModalShow(false);
          setSelectStudents([]);
        }}
        snackProps={snackProps}
        deadline={deadline}
        setdeadline={setdeadline}
        submitHandler={() => sendActivite()}
        openSnack={open}
      />
    </div>
  );
};

export default ActivitesSendModal;
