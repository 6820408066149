import { Axios } from "../Axios";

export const getGameCategories = async () => {
    return (
        await Axios.get("/games-categories")
    ).data;
};

export const getGameTopics = async (categoryId) => {
    return (
        await Axios.get("/topics/list/" + categoryId)
    ).data;
};