import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import logo from "../assets/images/logo.png";
import backorange from "../assets/images/backorange.svg";
import editorangelg from "../assets/images/editlg-orange.svg";
import darkorangeplan from "../assets/images/darkorange-plan.svg";
import { Link } from "react-router-dom";
import FooterEducator from "./layout/Footer/FooterEducator";
import HeaderGames from "./layout/Header/HeaderGames";
import SendRevision from "./SendRevision";
import { useEffect } from "react";
import {
  markAsCompleteParentPersonalTask,
  markOrCompleteEduOrParentTask,
  sendForRevision,
  updateTask,
} from "../service/apis/tasks";
import { getSingalActivities } from "../service/apis/activities";
import { tastStatus } from "../constants/app";
import "./home.scss";
import "./profile.scss";
import "./media.scss";
import "./games.scss";
import "./loginModal/login.scss";
import "./messages/messages.scss";
import "./myactivities.scss";
import "./gamestabs.scss";
import "./gamesflowphotos.scss";
import "./selectedgames.scss";
import "./gamequiz.scss";
import { Container, Row, Button, Form, Modal } from "react-bootstrap";
import { PersonalTaskDialogs } from "./educator/personalTask/PersonalTaskDialogs";
import ActivitesSendModal from "./ActivitesSendModal";
import { SnackbarContext } from "../contexts/Snackbar";
import {
  convertDateToObject,
  convertDateToString,
} from "../utils/helperFunctions";
import Headerparents from "./layout/Header/Headerparents";
import { NotificationContext } from "../contexts/Notification";

function PersonalTask() {
  const taskId = useParams();
  const [task, setTask] = useState({});
  const [point, setPoint] = useState();
  const [title, settitle] = useState("");
  const [essence, setessence] = useState("");
  const [assignedById, setassignedById] = useState();
  const { acId, id } = taskId;
  const [modalShow, setModalShow] = useState(false);
  const [modalgamesavedstep1Show, setgamesavedstep1ModalShow] = useState(false);
  const [modalpersonaltskstep1Show, setpersonaltskstep1ModalShow] =
    useState(false);
  const [modalAddgroupstep2Show, setAddgroupstep2ModalShow] = useState(false);
  const [modalAddgroupstep3Show, setAddgroupstep3ModalShow] = useState(false);
  const [modalCofirmTaskCompleted, setModalCofirmTaskCompleted] =
    useState(false);
  const [formData, setFormdata] = useState({
    title: "",
    essence: "",
    assignedById: "",
    taskId: "",
    deadline: new Date(),
    points: "",
    assignedToId: "",
    id: "",
  });

  const { openSnackbar } = useContext(SnackbarContext);
  const { showNotification } = useContext(NotificationContext);

  const getDingal = async () => {
    const response = await getSingalActivities(acId, id, false);
    console.log("response, ", response);
    setTask(response);
    setPoint(response.points);
    settitle(response.taskTitle);
    setessence(response.taskDescription);
    setassignedById(response.assignedById);
    let updatedDate;
    if (response.deadline) {
      updatedDate = convertDateToObject(response.deadline);
    } else {
      updatedDate = new Date();
    }
    setFormdata({
      ...formData,
      title: response.taskTitle,
      essence: response.taskDescription,
      assignedById: response.assignedById,
      deadline: updatedDate,
      points: response.points,
      assignedToId: response.assignedToId,
      taskId: response.taskId,
      id: response.id,
    });
  };

  console.log(task);
  useEffect(() => {
    getDingal();
  }, [acId, id]);

  const updateTasked = async () => {
    try {
      let updatedData = {
        ...formData,
        deadline: convertDateToString(formData.deadline),
      };
      console.log("updatedData", updatedData);

      if (
        updatedData.title.length < 3 ||
        updatedData.essence.length < 3 ||
        updatedData.deadline === "" ||
        updatedData.points === ""
      ) {
        openSnackbar("Please fill all the fields!", "error");
        return;
      }

      if (updatedData.points < 0) {
        openSnackbar("Points cannot be negative!", "error");
        return;
      }

      const responced = await updateTask(updatedData);
      if (responced) {
        getDingal();
        setAddgroupstep3ModalShow(false);
        showNotification("Task Updated Successfully");
      }
    } catch (e) {
      console.log("error in updating task", e);
      openSnackbar("Something went wrong", "error");
    }
  };

  const markAsCompleteHandler = async () => {
    try {
      if (point > task.points) {
        openSnackbar(
          "Points should be less than or equal to total points",
          "error",
        );
        return;
      }
      if (point < 0) {
        openSnackbar("Points should be greater than or equal to zero", "error");
        return;
      }
      if (task.status !== "Done") {
        openSnackbar("Task is not done yet", "error");
        return;
      }

      const responce =
        task.assignedByRole === "Parent"
          ? await markAsCompleteParentPersonalTask(id)
          : await markOrCompleteEduOrParentTask(point, id);
      if (responce) {
        showNotification("Points Updated Successfully");
        getDingal();
      }
    } catch (e) {
      openSnackbar("Something went wrong", "error");
      console.log(e);
    }
  };

  const SendRevisionHandler = async (comment, deadline) => {
    try {
      const response = await sendForRevision(
        id,
        comment,
        task.points,
        convertDateToString(deadline),
      );
      if (response) {
        showNotification("Task Sent For Revision Successfully");
        setpersonaltskstep1ModalShow(false);
      }
      getDingal();
    } catch (e) {
      console.log(e);
      openSnackbar("Something went wrong", "error");
    }
  };

  return (
    <div className="home-wrapper">
      {task.assignedByRole === "Parent" ? (
        <Headerparents />
      ) : (
        <HeaderGames setModalShow={(value) => setModalShow(value)} />
      )}

      {/* end header */}
      <div className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs">
        <div className="games-photosynths gamequiz-main perosnoal-task">
          <Container>
            <div className="quiz-game-popup">
              <div className="current-lesson-main ">
                <div className="current-lesson-contain">
                  <span className="back-btndrop">
                    <Link
                      to={
                        task.assignedByRole === "Parent"
                          ? "/ParentAccount"
                          : "/educator"
                      }
                      className="backbtn-lg"
                    >
                      <img src={backorange} alt="back-icon" />
                    </Link>
                  </span>
                  <div className="right-iconbox-quizz">
                    <ul>
                      {task.assignedByRole !== "Parent" && (
                        <li>
                          <Link
                            to="#"
                            className="editbtn-lgnew"
                            onClick={() => setAddgroupstep2ModalShow(true)}
                          >
                            <img src={darkorangeplan} alt="" />
                          </Link>
                        </li>
                      )}
                      {task?.status === "New" && (
                        <li>
                          <Link
                            to="#"
                            className="editbtn-lgnew"
                            onClick={() => setAddgroupstep3ModalShow(true)}
                          >
                            <img src={editorangelg} />
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>

                  <div className="current-lesson-topbr">
                    <div className="medm-tex orng-clr">
                      {task?.activityName}
                    </div>
                    <h1>{task?.taskTitle}</h1>
                    <div className="lesson-status-list quiz-gamelession">
                      <ul className="lesson-listing-curnt">
                        <li>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Mode:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              {task.mode || "Active"}
                            </span>
                          </div>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Status:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              {task.status}
                            </span>
                          </div>
                        </li>
                        <li>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Created:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              {task?.creationDate?.slice(0, 10)}
                            </span>
                          </div>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Deadline:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              {task?.deadline?.slice(0, 10)}
                            </span>
                          </div>
                        </li>
                        <li>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Total Points:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              {task.points}
                            </span>
                          </div>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Earned points:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              {task.earnedPoints}
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="personal-tsk-main">
                <div className="persnl-taskiner">
                  <div className="task-content">
                    <p>
                      <strong>Description: </strong>
                      {task.taskDescription}
                    </p>
                  </div>

                  {task.comment && (
                    <div className="task-content">
                      <p>
                        <strong>Revision: </strong>
                        {task.comment}
                      </p>
                    </div>
                  )}

                  {task.assignedByRole !== "Parent" && (
                    <div className="task-content">
                      <p>
                        <strong>Answer: </strong>
                        {task.answer === null ? "Not Answer" : task.answer}
                      </p>
                    </div>
                  )}

                  {task.status !== "Completed" &&
                    task.status !== "InRevision" &&
                    task.status !== "InProgress" &&
                    task.assignedByRole !== "Parent" && (
                      <div className="number-points-frm">
                        <div className="points-iner-nw">
                          <h5>Number of points for the answer</h5>
                          <Row>
                            <div className="col-6 col-md-9 one">
                              <div className="points-num-bx">
                                <Form.Group className="subsform-fild">
                                  <Form.Control
                                    type="number"
                                    value={point}
                                    onChange={(e) => setPoint(e.target.value)}
                                    placeholder={task?.points}
                                    min={0}
                                    max={task.points}
                                    disabled={task.status !== "Done"}
                                    className="number-points"
                                  />
                                </Form.Group>
                              </div>
                            </div>
                          </Row>
                        </div>
                      </div>
                    )}
                </div>
              </div>

              <div className="send-revision">
                <ul className="send-revision-list">
                  {Object.keys(task).length > 0 &&
                    task.status === tastStatus[3] &&
                    task.activityName === "Personal Task" && (
                      <li>
                        <div className="btn-week">
                          <Link
                            to="#"
                            className="btnweak-bx"
                            onClick={() => setpersonaltskstep1ModalShow(true)}
                          >
                            <span className="btn-title">Send for revision</span>
                          </Link>
                        </div>
                        {/* start send revison popup */}
                        {modalpersonaltskstep1Show && (
                          <SendRevision
                            submit={SendRevisionHandler}
                            deadline={task.deadline}
                            closeModal={() =>
                              setpersonaltskstep1ModalShow(false)
                            }
                          />
                        )}
                        {/* end send revison popup */}
                      </li>
                    )}
                  {Object.keys(task).length > 0 &&
                    task.status === tastStatus[3] &&
                    task.activityName === "Personal Task" && (
                      <li>
                        <div className="btn-week">
                          <button
                            onClick={markAsCompleteHandler}
                            className="btnweak-bx"
                            style={{ border: "none" }}
                          >
                            <span className="btn-title">Complete</span>
                          </button>
                        </div>
                      </li>
                    )}
                </ul>
              </div>
            </div>
          </Container>
        </div>

        {/* footer start */}
        <FooterEducator logo={logo} />
        {/* footer end */}
      </div>

      <ActivitesSendModal
        game={task}
        modalgamesavedstep2Show={modalAddgroupstep2Show}
        activityTypeId={acId}
        setgamesavedstep2ModalShow={setAddgroupstep2ModalShow}
      />

      {modalAddgroupstep3Show && (
        <PersonalTaskDialogs
          _formData={formData}
          _setformData={setFormdata}
          closeHandler={() => setAddgroupstep3ModalShow(false)}
          sendHandler={(data) => updateTasked()}
          type="update"
        />
      )}
    </div>
  );
}

export default PersonalTask;
