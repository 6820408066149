import React from "react";
import "./home.scss";
import "./profile.scss";
import "./media.scss";
import "./games.scss";
import "./loginModal/login.scss";
import "./messages/messages.scss";
import "./myactivities.scss";
import "./gamestabs.scss";
import "./gamesflowphotos.scss";
import "./selectedgames.scss";
import "./questionone.scss";
import { Container, Button, Form, Modal } from "react-bootstrap";
import logo from "../assets/images/logo.png";
import backorange from "../assets/images/backorange.svg";
import editorangelg from "../assets/images/editlg-orange.svg";
import gleryfiles1 from "../assets/images/files-glery1.jpg";
import gleryfiles2 from "../assets/images/files-glery2.jpg";
import gleryfiles3 from "../assets/images/files-glery3.jpg";
import gleryfiles4 from "../assets/images/files-glery4.jpg";
import gleryfiles5 from "../assets/images/files-glery5.jpg";
import playvd from "../assets/images/play-vd.png";

import Slider from "react-slick";
import { Link } from "react-router-dom";
import FooterEducator from "./layout/Footer/FooterEducator";
import HeaderGames from "./layout/Header/HeaderGames";

function Addmymaterials() {
  // const [show, setShow] = React.useState("1");

  // slider

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img
        alt="loading"
        src={backorange}
        onClick={onClick}
        className={`${className} img-fluid`}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <img
        alt="loading"
        src={backorange}
        onClick={onClick}
        className={`${className} img-fluid`}
      />
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    slidesToShow: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // end slider

  // start slider images

  const settingsone = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    slidesToShow: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // end slider images

  /* start modal */
  const [modelShow, setModalShow] = React.useState(false);
  /* end modal */

  /* startsave gamed step1 */
  const [modalgamesavedstep1Show, setgamesavedstep1ModalShow] =
    React.useState(false);

  /* end save gamned step1 */

  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderGames setModalShow={(value) => setModalShow(value)} />
      {/* end header */}

      <div className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs">
        <div className="games-photosynths">
          <Container>
            <div className="games-photosth-main gamesflows-twobx gamesflows-nwbox ">
              <div className="games-photsth-header">
                <h1>Title of the material</h1>

                <Link to="#" className="editbtn-lg">
                  <img src={editorangelg} alt="edit orange lg" />
                </Link>
              </div>

              <div className="questionone-main">
                <div className="iner-questionbx">
                  <div className="materialvideo-section">
                    <div className="material-videobx">
                      <div className="material-addvideo">
                        <div className="btnclose-rightt">
                          <button className="closebtn-cardbx">
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>

                        <div className="heading-matrl">
                          <div className="section-hedgmatrl">
                            <span className="heading-top"> Videos</span>{" "}
                            <button className="addbtn-matrl">
                              <span className="btnadd-videos">
                                {" "}
                                <i className="fas fa-plus"></i>
                              </span>
                            </button>
                          </div>
                        </div>
                        <div className="videomaterial-listt">
                          <Slider {...settings}>
                            <div className="sliderbox">
                              <div className="slider-video-material">
                                <div className="slider-iner-videomatrl">
                                  <div className="innergalery-file">
                                    <img
                                      src={gleryfiles2}
                                      className="img-fluid"
                                      alt="gallery"
                                    />
                                  </div>
                                  <div className="plynow-btn">
                                    <span className="playvd-btn">
                                      <img
                                        src={playvd}
                                        className="img-fluid"
                                        alt="playvd"
                                      />
                                    </span>
                                  </div>
                                  <div className="resize-btn">
                                    <button className="btn-resize-video">
                                      <i className="fas fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="sliderbox">
                              <div className="slider-video-material">
                                <div className="slider-iner-videomatrl">
                                  <div className="innergalery-file">
                                    <img
                                      src={gleryfiles1}
                                      className="img-fluid"
                                      alt="gleryfiles1"
                                    />
                                  </div>
                                  <div className="plynow-btn">
                                    <span className="playvd-btn">
                                      <img
                                        src={playvd}
                                        className="img-fluid"
                                        alt="playvd"
                                      />
                                    </span>
                                  </div>
                                  <div className="resize-btn">
                                    <button className="btn-resize-video">
                                      <i className="fas fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="sliderbox">
                              <div className="slider-video-material">
                                <div className="slider-iner-videomatrl">
                                  <div className="innergalery-file">
                                    <img
                                      src={gleryfiles3}
                                      className="img-fluid"
                                      alt="gleryfiles3"
                                    />
                                  </div>
                                  <div className="plynow-btn">
                                    <span className="playvd-btn">
                                      <img
                                        src={playvd}
                                        className="img-fluid"
                                        alt="playvd"
                                      />
                                    </span>
                                  </div>
                                  <div className="resize-btn">
                                    <button className="btn-resize-video">
                                      <i className="fas fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="sliderbox">
                              <div className="slider-video-material">
                                <div className="slider-iner-videomatrl">
                                  <div className="innergalery-file">
                                    <img
                                      src={gleryfiles4}
                                      className="img-fluid"
                                      alt="gleryfiles4"
                                    />
                                  </div>
                                  <div className="plynow-btn">
                                    <span className="playvd-btn">
                                      <img
                                        src={playvd}
                                        className="img-fluid"
                                        alt="playvd"
                                      />
                                    </span>
                                  </div>
                                  <div className="resize-btn">
                                    <button className="btn-resize-video">
                                      <i className="fas fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Slider>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="materialvideo-section materialimages-section">
                    <div className="material-videobx">
                      <div className="material-addvideo">
                        <div className="btnclose-rightt">
                          <button className="closebtn-cardbx">
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>

                        <div className="heading-matrl">
                          <div className="section-hedgmatrl">
                            <span className="heading-top"> Images</span>{" "}
                            <button className="addbtn-matrl">
                              <span className="btnadd-videos">
                                {" "}
                                <i className="fas fa-plus"></i>
                              </span>
                            </button>
                          </div>
                        </div>
                        <div className="videomaterial-listt">
                          <Slider {...settingsone}>
                            <div className="sliderbox">
                              <div className="slider-video-material">
                                <div className="slider-iner-videomatrl">
                                  <div className="innergalery-file">
                                    <img
                                      alt="loading"
                                      src={gleryfiles5}
                                      className="img-fluid"
                                    />
                                  </div>

                                  <div className="resize-btn">
                                    <button className="btn-resize-video">
                                      <i className="fas fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="sliderbox">
                              <div className="slider-video-material">
                                <div className="slider-iner-videomatrl">
                                  <div className="innergalery-file">
                                    <img
                                      alt="loading"
                                      src={gleryfiles4}
                                      className="img-fluid"
                                    />
                                  </div>

                                  <div className="resize-btn">
                                    <button className="btn-resize-video">
                                      <i className="fas fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="sliderbox">
                              <div className="slider-video-material">
                                <div className="slider-iner-videomatrl">
                                  <div className="innergalery-file">
                                    <img
                                      alt="loading"
                                      src={gleryfiles1}
                                      className="img-fluid"
                                    />
                                  </div>

                                  <div className="resize-btn">
                                    <button className="btn-resize-video">
                                      <i className="fas fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="sliderbox">
                              <div className="slider-video-material">
                                <div className="slider-iner-videomatrl">
                                  <div className="innergalery-file">
                                    <img
                                      alt="loading"
                                      src={gleryfiles2}
                                      className="img-fluid"
                                    />
                                  </div>

                                  <div className="resize-btn">
                                    <button className="btn-resize-video">
                                      <i className="fas fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Slider>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="questionform">
                    <Form className="form-questionbx">
                      <div className="questionbx-frm">
                        <Form.Label className="labelbx-quest">
                          Choose type of materials
                        </Form.Label>
                        <div className="choose-learning-list">
                          <ul className="learner-listing-games">
                            <li>
                              <button className="btn-learner-lsit">
                                Video
                              </button>
                            </li>
                            <li>
                              <button className="btn-learner-lsit active">
                                Text
                              </button>
                            </li>
                            <li>
                              <button className="btn-learner-lsit">
                                Document
                              </button>
                            </li>
                            <li>
                              <button className="btn-learner-lsit">
                                Audio
                              </button>
                            </li>
                            <li>
                              <button className="btn-learner-lsit">
                                Image
                              </button>
                            </li>
                            <li>
                              <button className="btn-learner-lsit">Link</button>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="questionbx-frm">
                        <Form.Control
                          as="textarea"
                          placeholder="Write text here"
                        />
                      </div>
                      <div className="btnsave-matrial">
                        <div className="btn-buy ">
                          <div className="btn-week">
                            <a className="btnweak-bx">
                              <span className="btn-title">Save</span>
                            </a>
                          </div>
                        </div>
                      </div>

                      <div className="question-btom-btn">
                        <ul>
                          <li>
                            <div className="btn-buy gamestart-btnn">
                              <div className="btn-week">
                                <a className="btnweak-bx">
                                  <span className="btn-title">Previous</span>
                                </a>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="btn-buy gamestart-btnn">
                              <div className="btn-week">
                                <a className="btnweak-bx">
                                  <span
                                    className="btn-title"
                                    onClick={() =>
                                      setgamesavedstep1ModalShow(true)
                                    }
                                  >
                                    Save the material
                                  </span>
                                </a>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>

        {/* footer start */}
        <FooterEducator logo={logo} />
        {/* footer end */}
      </div>

      {/* start modal my student */}

      {/* start game save step1 */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        // {...props}
        show={modalgamesavedstep1Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2 mystudent-games">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>The material is saved</h4>
              <h5>Send the material to my students</h5>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setgamesavedstep1ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="serch-inner-group">
                    <div className="serchfiled-activities">
                      <Form.Control
                        type="text"
                        placeholder="Start typing name or year of study"
                        className="formbox"
                      />
                      <Button type="button" className="seachbtn">
                        <i className="fas fa-search"></i>
                      </Button>
                    </div>
                  </div>

                  <div className="newpupil-list-bx">
                    <Form.Group className="subsform-fild child-tex-fld height-max-flow">
                      <div className="child-boxnew">
                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Aaradhya Rayasam</h5>
                                  <h6>India, Mumbai 1 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Chandrashekhar Kalvakuntla</h5>
                                  <h6>India, Mumbai 3 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>7F Class</h5>
                                  <h6>Hamut Kapit, Kemal Mara and 12 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Additional group</h5>
                                  <h6>Laska Doe, Hamut Kapit and 4 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>8A Class for exams</h5>
                                  <h6>Hamut Kapit, Laska Doe and 8 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Chandrashekhar Kalvakuntla</h5>
                                  <h6>India, Mumbai 3 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </Form.Group>

                <div className="student-btn-savedgame">
                  <div className="btnlogin-main">
                    <button type="submit" className="btnlogin btnweak-bx">
                      Send to selected students
                    </button>
                  </div>
                </div>
                <div className="btnlogin-main">
                  <button type="submit" className="btnlogin btnweak-bx">
                    Later
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end Add a Member Group step2 */}

      {/* end modal my student */}
    </div>
  );
}

export default Addmymaterials;
