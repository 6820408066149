import { GamesAxios, Axios, AxiosM } from "../Axios";

export const typesOfGames = async () => {
  const res = await GamesAxios.post("/game_types/list");
  return res.data;
};

export const getGrades = () => {
  return new Promise((resolve, reject) => {
    const grades = [...Array(13).keys()];
    grades.splice(0, 1);
    resolve(grades);
  });
};

export const newGameSubjects = async () => {
  return (await GamesAxios.post("/categories/list")).data;
};

export const newGameTopics = async (categoryId) => {
  if (!categoryId) return (await GamesAxios.post("/topics/list", {})).data;
  return (
    await GamesAxios.post("/topics/list", {
      query: {
        category_id: categoryId,
      },
    })
  ).data;
};

export const createNewGame = async (newGameFormData) => {
  const res = Axios.post("/games/minigame", {
    title: newGameFormData.title,
    description: newGameFormData.description,
    gameType: parseInt(newGameFormData.selectedGameType),
    categoryId: parseInt(newGameFormData.selectedSubject),
    topicId: parseInt(newGameFormData.selectedTopic),
    studentGrade: parseInt(newGameFormData.studentGrade),
  });
  return res;
};

// export const createNewGame = async (
//   title,
//   description,
//   topic_id,
//   game_type,
// ) => {
//   return (
//     await Axios.post("/games/create-ws-minigame", {
//       title: title,
//       description: description,
//       gameType: 1,
//       categoryId: 0,
//       topicId: 0,
//       childrenCategory: "string",
//       deadlineDays: 0,
//       deadlineDate: "2023-03-08T23:47:49.649Z",
//       isDemo: true,
//       gameURL: "string",
//       image: "string",
//       templateId: 0,
//       points: 0,
//       flowId: 0,
//       prefix: "string",
//       bucketName: "string",
//       listOfQuestionIDs: "string",
//       questionIDs: [
//         0
//       ]
//     })
//   ).data;
// };

export const getGamesByTopicId = async (topic_id) => {
  return (await Axios.post(`/games/games-list-by-topic/${topic_id}`)).data;
};

export const getGamesByCatId = async (cat_id) => {
  return (await Axios.post(`/games/games-list-by-category/${cat_id}`)).data;
};

export const getAllGamesList = async (assignToId = 0, isGroup = false) => {
  return (
    await Axios.get(
      `/games/educator-minigames-illumiyagames/${assignToId}/${isGroup}`,
    )
  ).data;
};

export const getSignalGames = async (gameId, gameType) => {
  return (await Axios.get(`/games/${gameType}/${gameId}`)).data;
};

export const sendAnswerQu = async (data, value) => {
  const res = await Axios.post("/games/auto-question-marking", {
    gameId: data.gameId,
    questionId: data.questionId,
    questionType: data.questionType,
    answer: value,
    earnedPoints: data.points,
  });

  return res.data;
};

export const manualMarkQuestion = async (
  markedQuestionId,
  earnedPoints,
  isCorrect,
  questionType,
) => {
  const res = await Axios.post("/games/manual-question-marking", {
    markedQuestionId: markedQuestionId,
    EarnedPoints: earnedPoints,
    isCorrect: isCorrect,
    questionType: questionType,
  });
  return res.data;
};

export const creactTrueFalsQuestion = async (state, questionsData, queType) => {
  let data = {
    categoryId: state.game.categoryId,
    topicId: state.game.topicId,
    gameId: state.game.id,
    rightExplanation: questionsData.correctRes,
    wrongExplanation: questionsData.wrongRes,
    question: questionsData.question,
    points: questionsData.points,
    answerTypeId: queType,
    tryUntilCorrectAnswer: questionsData.tryUntilCorrect,
    isCreated: true,
    answer: queType === 5 ? questionsData.truefalse : questionsData.answer,
  };
  console.log("data to be submitted", data);
  return (
    await Axios.post(`/questions/save-educator-true-false-questions`, data)
  ).data;
};

export const creactDiscreteWuestion = async (state, questionsData, queType) => {
  console.log({
    categoryId: state.game.categoryId,
    topicId: state.game.topicId,
    gameId: state.game.id,
    rightExplanation: questionsData.correctRes,
    wrongExplanation: questionsData.wrongRes,
    question: questionsData.question,
    points: questionsData.points,
    // time: questionsData.times,
    answerTypeId: queType,
    tryUntilCorrectAnswer: questionsData.tryUntilCorrect,
    isCreated: true,
    answer: questionsData.answer,
  });

  return (
    await Axios.post(`/questions/save-educator-discrete-questions`, {
      categoryId: state.game.categoryId,
      topicId: state.game.topicId,
      gameId: state.game.id,
      rightExplanation: questionsData.correctRes,
      wrongExplanation: questionsData.wrongRes,
      question: questionsData.question,
      points: questionsData.points,
      answerTypeId: queType,
      tryUntilCorrectAnswer: questionsData.tryUntilCorrect,
      isCreated: true,
      answer: questionsData.answer,
    })
  ).data;
};

export const creactNonDiscreteWuestion = async (
  state,
  questionsData,
  queType,
) => {
  console.log("questiondata in api", questionsData);

  return (
    await Axios.post(`/questions/save-educator-non-discrete-questions`, {
      categoryId: state.game.categoryId,
      topicId: state.game.topicId,
      gameId: state.game.id,
      rightExplanation: questionsData.correctRes,
      wrongExplanation: questionsData.wrongRes,
      question: questionsData.question,
      points: questionsData.points,
      answerTypeId: queType,
      tryUntilCorrectAnswer: questionsData.tryUntilCorrect,
      isCreated: true,
      answer: questionsData.answer,
    })
  ).data;
};

export const creactMCEquestion = async (state, questionsData, queType, sss) => {
  console.log("questiondata in api", questionsData);
  const data = {
    Option1: questionsData.options[1]?.text
      ? questionsData.options[1]?.text
      : "",
    Option2: questionsData.options[2]?.text
      ? questionsData.options[2]?.text
      : "",
    Option3: questionsData.options[3]?.text
      ? questionsData.options[3]?.text
      : "",
    Option4: questionsData.options[4]?.text
      ? questionsData.options[4]?.text
      : "",
    Option1File: questionsData.options[1]?.image
      ? questionsData.options[1]?.image
      : "",
    Option2File: questionsData.options[2]?.image
      ? questionsData.options[2]?.image
      : "",
    Option3File: questionsData.options[3]?.image
      ? questionsData.options[3]?.image
      : "",
    Option4File: questionsData.options[4]?.image
      ? questionsData.options[4]?.image
      : "",
    categoryId: state.game.categoryId,
    topicId: state.game.topicId,
    gameId: state.game.id,
    rightExplanation: questionsData.correctRes,
    wrongExplanation: questionsData.wrongRes,
    question: questionsData.question,
    points: questionsData.points,
    answerTypeId: 1,
    tryUntilCorrectAnswer: questionsData.tryUntilCorrect,
    isCreated: true,
    answer: sss,
  };
  console.log("data to be submitted", data);
  const formData = new FormData();
  for (const item in data) {
    formData.append(item, data[item]);
  }
  // console.log(questionsData.options)
  console.log("axiosm", AxiosM);
  return (await AxiosM.post(`/questions/save-educator-mcq-questions`, formData))
    .data;
};

export const creactMUltquestion = async (
  state,
  questionsData,
  queType,
  correctAnswersForOptional,
) => {
  console.log("correctAnswersForOptionalll", correctAnswersForOptional);
  let answerType = 1;
  if (questionsData.options[1]?.text && questionsData.options[1]?.image) {
    answerType = 3;
  } else if (questionsData.options[1]?.text) {
    answerType = 2;
  } else if (questionsData.options[1]?.image) {
    answerType = 1;
  }
  const data = {
    Option1: questionsData.options[1]?.text
      ? questionsData.options[1]?.text
      : "",
    Option2: questionsData.options[2]?.text
      ? questionsData.options[2]?.text
      : "",
    Option3: questionsData.options[3]?.text
      ? questionsData.options[3]?.text
      : "",
    Option4: questionsData.options[4]?.text
      ? questionsData.options[4]?.text
      : "",
    Option1File: questionsData.options[1]?.image
      ? questionsData.options[1]?.image
      : "",
    Option2File: questionsData.options[2]?.image
      ? questionsData.options[2]?.image
      : "",
    Option3File: questionsData.options[3]?.image
      ? questionsData.options[3]?.image
      : "",
    Option4File: questionsData.options[4]?.image
      ? questionsData.options[4]?.image
      : "",
    categoryId: state.game.categoryId,
    topicId: state.game.topicId,
    gameId: state.game.id,
    rightExplanation: questionsData.correctRes,
    wrongExplanation: questionsData.wrongRes,
    question: questionsData.question,
    points: questionsData.points,
    answerTypeId: answerType,
    tryUntilCorrectAnswer: questionsData.tryUntilCorrect,
    isCreated: true,
    answer: correctAnswersForOptional?.join(","),
  };

  console.log("data to be submitted", data);
  const formData = new FormData();
  for (const item in data) {
    formData.append(item, data[item]);
  }

  return (
    await AxiosM.post(
      `/questions/save-educator-multiple-options-questions`,
      formData,
    )
  ).data;
};

// export const creactDiscreteWuestion = async (state,questionsData,queType)=>{
//   return(
//      await Axios.post(`/questions/save-educator-discrete-questions`,{
//       categoryId: state.game.categoryId,
//       topicId: state.game.topicId,
//       gameId: state.game.id,
//       rightExplanation: questionsData,
//       wrongExplanation: questionsData,
//       question: questionsData.question,
//       points: questionsData.points,
//       time: questionsData.times,
//       answerTypeId: queType,
//       tryUntilCorrectAnswer: questionsData,
//       isCreated: true,
//       answer: questionsData.answer
//      })
//   ).data
// };

export const createQuestion = async ({
  question,
  type,
  ans_type,
  options,
  correct_options,
  correct_res_text,
  wrong_res_text,
  points,
  response_text,
  game_id,
  qno,
}) => {
  return (
    await GamesAxios.post("/questions/create", {
      question,
      type,
      ans_type,
      options,
      correct_options,
      correct_res_text,
      wrong_res_text,
      points,
      response_text,
      game_id,
      qno,
    })
  ).data;
};

export const deleteMiniGame = async (game_id) => {
  return await Axios.delete(`/games/quiz/${+game_id}`);
};

export const deleteGameFlow = async (gameflow_id) => {
  return await Axios.delete(`/gameflows/${+gameflow_id}`);
};

export const deleteAllGame = async (game_id) => {
  return (await Axios.delete(`/games/minigames/${game_id}`)).data;
};

export const gameflowTemplateList = async () => {
  return (await Axios.get(`/gameflows/gameflow-template-list`)).data;
};

export const saveEducatorbaseGameFlow = async (formdata) => {
  return (await Axios.post(`/gameflows/save-educator-base-gameflow`, formdata))
    .data;
};

export const getGameflowByTemplate = async (gameflowTemplateId, gameflowId) => {
  return (
    await Axios.get(
      `/gameflows/get-gameflow-template/${gameflowTemplateId}/${gameflowId}`,
    )
  ).data;
};

export const editGameflowGames = async (gameflowId, games) => {
  return (
    await Axios.post(`/gameflows/save-educator-gameflow-games`, {
      gameflowId,
      games,
    })
  ).data;
};

export const getGameflowById = async (gameflowId) => {
  return (await Axios.get(`/gameflows/${gameflowId}`)).data;
};

export const editGameflowDetails = async ({ id, title, description }) => {
  const dataToSubmit = {
    id,
    title,
    description,
  };
  return (await Axios.post(`/gameflows/update-admin-gameflow`, dataToSubmit))
    .data;
};
