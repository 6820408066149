import { questions } from "./data";

export const calculateGraphResult = (userInputPoints) => {
  const letterPoints = {};
  let totalPoints = 0;

  for (let [questionNo, points] of Object.entries(userInputPoints)) {
    points = parseInt(points);
    totalPoints += Math.abs(points);
    const foundQue = questions.find(
      (que) => parseInt(questionNo) === que.question_no,
    );
    if (points > 0) {
      if (foundQue.letters.right in letterPoints)
        letterPoints[foundQue.letters.right] += points;
      else letterPoints[foundQue.letters.right] = points;
    } else {
      if (foundQue.letters.left in letterPoints)
        letterPoints[foundQue.letters.left] += Math.abs(points);
      else letterPoints[foundQue.letters.left] = Math.abs(points);
    }
  }
  const scanNum = (num) => {
    return isNaN(num) ? 0 : num;
  };
  const graph1 =
    scanNum(letterPoints["C"]) +
    scanNum(letterPoints["L"]) -
    (scanNum(letterPoints["D"]) + scanNum(letterPoints["K"]));
  const graph2 =
    scanNum(letterPoints["E"]) +
    scanNum(letterPoints["N"]) -
    (scanNum(letterPoints["F"]) + scanNum(letterPoints["M"]));
  const graph3 =
    scanNum(letterPoints["A"]) +
    scanNum(letterPoints["J"]) -
    (scanNum(letterPoints["B"]) + scanNum(letterPoints["I"]));
  const graph4 =
    scanNum(letterPoints["H"]) +
    scanNum(letterPoints["O"]) +
    scanNum(letterPoints["W"]) -
    (scanNum(letterPoints["G"]) +
      scanNum(letterPoints["P"]) +
      scanNum(letterPoints["S"]));
  const graphPoints = { graph1, graph2, graph3, graph4 };
  return [totalPoints, graphPoints, letterPoints];
};
