import React from "react";
import { Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./loginModal/login.scss";
import { Label } from "@mui/icons-material";
import DatePicker from "react-datepicker";
import { convertDateToObject } from "../utils/helperFunctions";

const SendRevision = ({ submit, deadline, closeModal }) => {
  const [form, setform] = React.useState({
    comment: "",
    deadline: deadline ? convertDateToObject(deadline) : new Date(),
  });
  console.log(form);

  const submitHandler = async () => {
    await submit(form.comment, form.deadline);
  };
  return (
    <div className="add-pupil-mainbx">
      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        show={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2 mystudent-games persltask-form">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Send to revision</h4>
            </Modal.Title>
            <button type="button" onClick={closeModal} className="close">
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="newgames-createbx">
                    <Row>
                      <div className="col-md-12">
                        <Form.Label>Comment </Form.Label>
                        <Form.Control
                          value={form.comment}
                          onChange={(e) =>
                            setform({ ...form, comment: e.target.value })
                          }
                          as="textarea"
                          placeholder="Write what should be fixed or any other notes"
                        />
                      </div>
                      <div className="col-md-12">
                        <Label>Deadline</Label>

                        <DatePicker
                          selected={form.deadline}
                          minDate={new Date() - 1}
                          dateFormat="dd-MM-yyyy"
                          className="dateformat"
                          onChange={(date) =>
                            setform({ ...form, deadline: date })
                          }
                        />
                      </div>
                    </Row>
                  </div>
                </Form.Group>

                <div className="btnlogin-main">
                  <Link
                    to="#"
                    className="btnlogin btnweak-bx"
                    onClick={submitHandler}
                  >
                    Send for Revision
                  </Link>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default SendRevision;
