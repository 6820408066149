import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const CloseButton = ({ closeHandler }) => {
  return (
    <Box
      onClick={closeHandler}
      sx={{
        cursor: "pointer",
        position: "absolute",
        top: "1rem",
        right: "1rem",
        zIndex: 10,
      }}
    >
      <CloseIcon sx={{ color: "var(--orange)" }} />
    </Box>
  );
};

export const StyledYellowButton = ({ title, btnHandler, sx }) => (
  <Box
    onClick={btnHandler}
    sx={{
      cursor: "pointer",
      width: "100%",
      textAlign: "center",
      fontSize: "0.9rem",
      padding: "0.8rem",
      transform: "perspective(100px) rotateY( -3deg)",
      ...sx,
    }}
    className="btnweak-bx"
  >
    {title}
  </Box>
);

export const StyledPurpleButton = ({ title, btnHandler, sx }) => (
  <Box
    onClick={btnHandler}
    sx={{
      cursor: "pointer",
      color: "var(--white)",
      backgroundColor: "var(--purple)",
      width: "100%",
      textAlign: "center",
      fontSize: "0.9rem",
      padding: "0.8rem",
      ...sx,
    }}
    className="btnweak-bx"
  >
    {title}
  </Box>
);
