import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { splitArray } from "../../utils/splitArray";
import { questions } from "./data";
import { useActions } from "../../overmind/index";
import { GoToQuestionnaire } from "./Dialogs/GoToQuestionnaire";
import { CloseConfirmationDialog } from "./Dialogs/CloseConfirmation";
import { useHistory } from "react-router";
import { Home } from "./slideScreens/Home";
import { Questions } from "./slideScreens/Questions";
import { Result } from "./slideScreens/Result";
import { calculateGraphResult } from "./utils";
import { ChartResult } from "./slideScreens/ChartResult";

const questionsInitialState = (() => {
  const state = {};
  for (let i = 1; i <= questions.length; i++) {
    state[i] = 0;
  }
  return state;
})();

const questionsPerPage = 4;
const totalPages = questions.length / questionsPerPage;
const questionsList = splitArray(questions, questionsPerPage);

const screens = {
  QUESTIONS: "QUESTIONS",
  HOME: "HOME",
  CHARTRESULT: "CHARTRESULT",
  RESULT: "RESULT",
};

const dialogs = {
  GO_TO_QUESTIONNAIRE_DIALOG: "GO_TO_QUESTIONNAIRE_DIALOG",
  CLOSE_CONFIRMATION_DIALOG: "CLOSE_CONFIRMATION_DIALOG",
};

const sliderRange = 4;
const sliderValues = (() => {
  const values = [];
  for (let i = -sliderRange; i <= sliderRange; i++) {
    values.push({ label: ` ${i ? Math.abs(i) : ""} `, value: i });
  }
  return values;
})();

export const Content = () => {
  const actions = useActions();
  const history = useHistory();
  const [state, setstate] = useState({
    page: 1,
    currentScreen: screens.HOME,
    currentDialog: null,
    questionPoints: questionsInitialState,
  });

  // Closes questionnnaire full screen dialog
  const closeQuestionnaire = () => {
    actions.openQuestionnaire(false);
  };

  const changeScreen = (screen) => {
    // Dialogs closed if opened while moving from screen to screen
    setstate((state) => ({
      ...state,
      currentScreen: screen,
      currentDialog: null,
    }));
  };

  const changeDialog = (dialog) => {
    // if dialog parameter is null - dialog closes
    setstate((state) => ({ ...state, currentDialog: dialog }));
  };

  const sliderHandler = (questionNo, points) => {
    setstate((state) => ({
      ...state,
      questionPoints: { ...state.questionPoints, [questionNo]: points },
    }));
  };

  const pageHandlers = {
    nextPage() {
      setstate((state) => ({ ...state, page: state.page + 1 }));
    },
    previousPage() {
      setstate((state) => ({ ...state, page: state.page - 1 }));
    },
  };

  const isAllQuestionsAttempted = () => {
    for (const [_key, value] of Object.entries(state.questionPoints)) {
      if (value === 0 || value === "0") return false;
    }
    return true;
  };
  return (
    <Box
      sx={{
        backgroundColor: "var(--purple)",
        minHeight: "100vh",
        p: "1rem 3rem",
      }}
    >
      {/* DIALOGS */}
      {state.currentDialog === dialogs.GO_TO_QUESTIONNAIRE_DIALOG && (
        <GoToQuestionnaire
          close={changeDialog}
          goToQuestionnaire={() => changeScreen(screens.QUESTIONS)}
          remindMeLater={() => {
            changeDialog(dialogs.CLOSE_CONFIRMATION_DIALOG);
          }}
        />
      )}

      {state.currentDialog === dialogs.CLOSE_CONFIRMATION_DIALOG && (
        <CloseConfirmationDialog
          close={changeDialog}
          continueBtn={() => changeDialog()}
          closeBtn={() => {
            actions.openQuestionnaire(false);
            history.push("/dashboard");
          }}
        />
      )}

      {/* SCREENS */}
      {state.currentScreen === screens.HOME && (
        <Home
          sliderRange={sliderRange}
          sliderValues={sliderValues}
          changeDialog={changeDialog}
          closeQuestionnaire={closeQuestionnaire}
          dialogs={dialogs}
        />
      )}

      {state.currentScreen === screens.QUESTIONS && (
        <Questions
          isAllQuestionsAttempted={isAllQuestionsAttempted}
          changeScreen={() => {
            changeScreen(screens.CHARTRESULT);
          }}
          state={state}
          closeQuestionnaire={closeQuestionnaire}
          pageHandlers={pageHandlers}
          questionsList={questionsList}
          sliderHandler={sliderHandler}
          sliderRange={sliderRange}
          sliderValues={sliderValues}
          totalPages={totalPages}
          screens={screens}
        />
      )}

      {state.currentScreen === screens.CHARTRESULT && (
        <ChartResult
          nextScreen={() => changeScreen(screens.RESULT)}
          questionnaireResult={calculateGraphResult(state.questionPoints)}
        />
      )}

      {state.currentScreen === screens.RESULT && (
        <Result closeQuestionnaire={closeQuestionnaire} />
      )}
    </Box>
  );
};
