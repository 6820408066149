import "./App.css";
import Home from "./component/home";
import About from "./component/about/about";
import Plan from "./component/plan";
import Contact from "./component/contact/contact";
import Teachers from "./component/teacher/teachers";
import Blog from "./component/blog/blog";
import Profile from "./component/profile";
import Games from "./component/games/games";
// import Games from "./component/games/games";
import Article from "./component/articles/article";
import Dashboard from "./component/studentDashboard/dashboard";
import MyActivities from "./component/myactivities";
import CommunityReward from "./component/rewardsDetails/CommunityReward";

import PersonalReward from "./component/rewardsDetails/PersonalReward";
import { Route, Switch, Redirect } from "react-router";
import { ProtectedRoute, RoleProtectedRoute } from "./Routes";
import Currentlesson from "./component/Currentlesson";
import Newlesson from "./component/newlesson";
import Allfiles from "./component/Allfiles";
import Instruction from "./component/Instruction";
import Gameflow from "./component/games/GameFlow/Gameflow";
import Educator from "./component/educator/educator";
import Tutor from "./component/tutor/tutor";
import Messages from "./component/messages/Messages";
import GamesTabs from "./component/games/GamesTabs";
import Gamesflowphotos from "./component/games/GameFlow/Gamesflowphotos";
import Selectedgames from "./component/Selectedgames";
import Gamesflowstwo from "./component/games/GameFlow/Gamesflowstwo";
import Gamesflowsnew from "./component/games/GameFlow/Gamesflowsnew";
import Questionone from "./component/Questionone";
import Mymaterials from "./component/Mymaterials";
import Creatingmaterial from "./component/Creatingmaterial";
import Videosmaterialadd from "./component/Videosmaterialadd";
import Addmymaterials from "./component/Addmymaterials";
import Completedlesson from "./component/Completedlesson";
import Newlivelession from "./component/Newlivelession";
import LiveLessonDetail from "./component/LiveLessonDetail";
import Newlivelessionthree from "./component/Newlivelessionthree";
import Completedlessontwo from "./component/Completedlessontwo";
// import Signup from "./component/signup";
import Studentchat from "./component/student/Studentchat";
import Myprofile from "./component/Myprofile";
import Becometutornew from "./component/tutor/Becometutornew";
import Subscription from "./component/Subscription";
import Myprofilesidebar from "./component/Myprofilesidebar";
import Earnings from "./component/Earnings";
import Security from "./component/Security";
import Notifications from "./component/Notifications";
import Support from "./component/Support";
import Howearn from "./component/Howearn";
import ParentAccount from "./component/parent/ParentAccount/ParentAccount";
import FooterParent from "./component/layout/Footer/FooterParent";
import Addmychild from "./component/Addmychild";
import ChildrenProgress from "./component/ChildrenProgress";
import ChildProgressDetails from "./component/ChildProgressDetails";
import StudentReport from "./component/student/StudentReport";
import GameQuiz from "./component/games/GameQuiz";
import SendRevision from "./component/SendRevision";
import Selectstudent from "./component/Selectstudent";
import PersonalTask from "./component/PersonalTask";
import MyGameFlow from "./component/MyGameFlow";
import GameflowNotchecked from "./component/games/GameFlow/GameflowNotchecked";
import { userRole } from "./service/constant";
import { useActions, useAppState } from "./overmind";
import { useEffect } from "react";
import StudentProfile from "./component/student/StudentProfile/StudentProfile";
import CreateLiveLesson from "./component/student/liveLesson/StudentLiveLesson";
import CancelledLiveLesson from "./component/student/liveLesson/CancelledLiveLesson";
import ConfirmLiveLesson from "./component/student/liveLesson/ConfirmLiveLesson";
import { SelectMyGames } from "./component/educator/games/SelectMyGames";
import { SelectNeitheduGames } from "./component/educator/games/NeitheduGamesDropdown";
import { ListOfTutors } from "./component/student/ListOfTutors";
import { TutorDetails } from "./component/student/TutorDetails";
import { TutorProfilePage } from "./component/tutor/TutorProfilePage";
import { QuizStatus } from "./component/educator/activity/QuizStatus";
import QuizNotChecked from "./component/educator/activity/QuizNotChecked";
import QuizLate from "./component/educator/activity/QuizLate";
import { MyConnections } from "./component/student/connections/MyConnections";
import { FriendDetailPage } from "./component/student/FriendDetailPage";
import TutorLevel from "./component/educator/TutorLevel";
import { BadgesPayments } from "./component/educator/badges/BadgesPayments";
import GroupGameFlow from "./component/GroupGameFlow";
import EducatorQuestionnaire from "./component/educator/questionnaire/EducatorQuestionnaire";
import { LevelsInfo } from "./component/tutor/LevelsInfo";
import Payment from "./component/studentDashboard/Payment";
import PersonalTaskAnswer from "./component/PersonalTaskAnswer";
import GameDataViewStudent from "./component/games/GameFlow/GameDataViewStudent";
import CreatingMaterialsModify from "./component/CreatingMaterialsModify";
import PersonalTaskAnswerGrop from "./component/PersonalTaskAnswerGrop";
import GameQuizAnswer from "./component/games/GameQuizAnswer";
import { SelectMyMaterail } from "./component/educator/games/SelectMyMaterail";
import TestGame from "./component/games/TestGame";
import StudentActivityDetails from "./component/games/GameFlow/StudentActivityDetails";
import GroupTaskDetails from "./component/educator/personalTask/GroupTaskDetails";
import AcceptLiveLesson from "./component/student/liveLesson/AcceptLiveLesson";
import "react-datepicker/dist/react-datepicker.css";
import FirstLogin from "./component/student/FirstLogin";
import QuesTest from "./component/educator/questionnaire/QuesTest";
import LiveLessonApp from "./component/lessonApp/LiveLessonApp";
import StudentBadge from "./component/badge/StudentBadge";

// import { useState } from "react";
function App() {
  const state = useAppState();
  // const [roleid, setRoleid] = useState(null);
  // console.log(state.roleId);
  // const currentData = JSON.parse(localStorage.getItem("user"));

  // console.log(state, "state");
  // console.log(state.roleId, "95");

  // useEffect(() => {
  //   console.log("ss");
  //   setRoleid(currentData.roleId);
  // }, []);
  // console.log(JSON.parse(localStorage.getItem("user")), "roleId");

  // console.log(state, "ap");
  const actions = useActions();
  useEffect(() => {
    actions.getBlogs();
    actions.getKeyValues();
  }, [actions]);

  return (
    <div className="App">
      <EducatorQuestionnaire />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/register" component={Home} />
        <Route exact path="/referral/educator" component={Home} />
        <Route exact path="/plan" component={Plan} />
        <Route exact path="/about" component={About} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/blog" component={Blog} />
        <Route exact path="/student-badges" component={StudentBadge} />
        <ProtectedRoute
          auth={state.token}
          exact
          path="/profile"
          component={Profile}
        />

        <Route exact path="/blog/article/:id" component={Article} />
        {/* <Route exact path="/games" component={Games} /> */}
        <Route exact path="/games" component={Games} />
        <Route exact path="/game" component={TestGame} />
        <Route exact path="/test-lesson" component={LiveLessonApp} />
        <Route exact path="/myactivities" component={MyActivities} />

        <Route exact path="/Currentlesson" component={Currentlesson} />
        <Route exact path="/newlesson" component={Newlesson} />
        <Route exact path="/Allfiles" component={Allfiles} />
        <Route exact path="/Instruction" component={Instruction} />
        <Route exact path="/Gameflow" component={Gameflow} />
        <Route exact path="/Messages" component={Messages} />
        <Route exact path="/GamesTabs" component={GamesTabs} />
        <Route exact path="/Gamesflowphotos" component={Gamesflowphotos} />
        <Route exact path="/Selectedgames" component={Selectedgames} />
        <Route exact path="/Gamesflowstwo" component={Gamesflowstwo} />
        <Route exact path="/Gamesflowsnew" component={Gamesflowsnew} />
        <Route exact path="/Questionone" component={Questionone} />
        <Route exact path="/Mymaterials" component={Mymaterials} />
        <Route
          exact
          path="/Creatingmaterial"
          component={CreatingMaterialsModify}
        />
        <Route exact path="/Videosmaterialadd" component={Videosmaterialadd} />
        <Route exact path="/Addmymaterials" component={Addmymaterials} />
        <Route exact path="/Completedlesson" component={Completedlesson} />
        <Route exact path="/Newlivelession" component={Newlivelession} />
        <Route
          exact
          path="/LiveLessonDetail/:activityTypeId/:activityId"
          component={LiveLessonDetail}
        />

        <Route exact path="/communityRewards" component={CommunityReward} />
        <Route exact path="/personalRewards" component={PersonalReward} />
        <Route
          exact
          path="/Newlivelessionthree"
          component={Newlivelessionthree}
        />
        <Route
          exact
          path="/Completedlessontwo"
          component={Completedlessontwo}
        />
        <Route exact path="/Myprofile" component={Myprofile} />
        <Route exact path="/Becometutornew" component={Becometutornew} />
        <Route exact path="/Subscription" component={Subscription} />
        <Route exact path="/Myprofilesidebar" component={Myprofilesidebar} />
        <Route exact path="/Earnings" component={Earnings} />
        <Route exact path="/Security" component={Security} />
        <Route exact path="/Notifications" component={Notifications} />
        <Route exact path="/Support" component={Support} />
        <Route exact path="/Howearn" component={Howearn} />
        {/* <Route exact path="/dStudent" component={Dashboard} /> */}

        <Route exact path="/FooterParent" component={FooterParent} />
        <Route exact path="/Addmychild" component={Addmychild} />
        <Route exact path="/ChildrenProgress" component={ChildrenProgress} />
        <Route
          exact
          path="/ChildProgressDetails"
          component={ChildProgressDetails}
        />
        <Route exact path="/GameQuiz" component={GameQuiz} />
        <Route
          exact
          path="/GameQuizAnswer/:gametype/:id"
          component={GameQuizAnswer}
        />
        <Route exact path="/SendRevision" component={SendRevision} />
        <Route exact path="/Selectstudent" component={Selectstudent} />
        <Route exact path="/PersonalTask/:acId/:id" component={PersonalTask} />
        <Route
          exact
          path="/PersonalTaskAnswer/:acId/:id"
          component={PersonalTaskAnswer}
        />
        <Route
          exact
          path="/PersonalTaskAnswergroup/:activityTypeId/:activityId"
          component={PersonalTaskAnswerGrop}
        />
        <Route
          exact
          path="/PersonalGameAnswer/:acId/:id"
          component={GameDataViewStudent}
        />
        <Route exact path="/GroupGameFlow" component={GroupGameFlow} />
        <Route
          exact
          path="/GameflowNotchecked"
          component={GameflowNotchecked}
        />
        {/* <Route exact path="/dashboard" component={Dashboard} /> */}
        <RoleProtectedRoute
          roleId={state.roleId}
          matchRole={userRole.STUDENT}
          exact
          path="/dashboard"
          component={Dashboard}
        />

        {/* <Route
          roleId={state.roleId}
          matchRole={userRole.STUDENT}
          exact
          path="/dashboard"
          component={Dashboard}
          // component={
          //   state.roleId === userRole.STUDENT ? Dashboard : <Redirect to="/" />
          // }
        /> */}
        <ProtectedRoute
          auth={state.token}
          exact
          path="/myactivities"
          component={MyActivities}
        />

        {/* PARENT */}
        <Route exact path="/dParent" component={ParentAccount} />

        <RoleProtectedRoute
          roleId={state.roleId}
          matchRole={userRole.PARENT}
          exact
          path="/ParentAccount"
          component={ParentAccount}
        />

        {/* EDUCATOR */}
        {(state.roleId === userRole.TUTOR ||
          state.roleId === userRole.EDUCATOR) && (
          <Route exact path="/educator" component={Educator} />
        )}
        <Route exact path="/educator/test" component={QuesTest} />
        <Route
          exact
          path="/student/AcceptLiveLesson/:activityTypeId/:activityId"
          component={AcceptLiveLesson}
        />
        {console.log(state.roleId, "roleid")}

        <Route
          exact
          path="/educator/badgePayments"
          component={BadgesPayments}
        />
        <Route exact path="/educator/selectMyGames" component={SelectMyGames} />
        <Route
          exact
          path="/educator/selectMyMaterials"
          component={SelectMyMaterail}
        />
        <Route
          exact
          path="/educator/selectNeitheduGames"
          component={SelectNeitheduGames}
        />
        <Route exact path="/educator/quizStatus" component={QuizStatus} />
        <Route
          exact
          path="/educator/quizNotChecked"
          component={QuizNotChecked}
        />
        <Route exact path="/educator/quizLate" component={QuizLate} />
        <Route
          exact
          path="/educator/groupTaskDetails/:acId/:id/:actyId"
          component={GroupTaskDetails}
        />
        <Route
          exact
          path="/educator/student-game-details/:activityTypeId/:activityId"
          component={StudentActivityDetails}
        />
        <Route exact path="/educator/tutorLevel" component={TutorLevel} />

        {/* STUDENT */}
        <Route exact path="/createLiveLesson" component={CreateLiveLesson} />
        <Route exact path="/tutorList" component={ListOfTutors} />

        <Route exact path="/student/tutorDetails" component={TutorDetails} />
        <Route exact path="/student/dStudent" component={Dashboard} />
        <Route exact path="/student/payment" component={Payment} />
        <Route exact path="/StudentReport" component={StudentReport} />
        <Route exact path="/StudentGameFlow" component={MyGameFlow} />
        <Route exact path="/Studentchat" component={Studentchat} />

        <Route
          exact
          path="/student/cancelledLiveLesson"
          component={CancelledLiveLesson}
        />
        <Route
          exact
          path="/student/confirmLiveLesson"
          component={ConfirmLiveLesson}
        />
        <Route exact path="/student/myConnections" component={MyConnections} />
        <Route
          exact
          path="/student/friendDetails"
          component={FriendDetailPage}
        />
        <Route exact path="/student-progress" component={FirstLogin} />

        {/* Protected Routes in Students */}
        {/* <ProtectedRoute
          auth={state.token}
          exact
          path="/studentProfile"
          component={StudentProfile}
        /> */}

        <RoleProtectedRoute
          roleId={state.roleId}
          matchRole={userRole.STUDENT}
          path="/studentProfile"
          component={StudentProfile}
        />

        {/* TUTOR */}
        <RoleProtectedRoute
          roleId={state.roleId}
          matchRole={userRole.TUTOR}
          exact
          path="/tutor"
          component={Tutor}
        />
        <Route exact path="/tutor/profilePage" component={TutorProfilePage} />
        <Route exact path="/tutor/levelsInfo" component={LevelsInfo} />
        <Route exact path="/tutor" component={Teachers} />
      </Switch>
    </div>
  );
}

export default App;
