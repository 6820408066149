import React, { useCallback, useEffect } from "react";
import "./home.scss";
import "./media.scss";
import { Container, Row } from "react-bootstrap";
import picslider from "../assets/images/girl1.png";
import picsliderbg from "../assets/images/bg-vector-icon.png";
import kidspic1 from "../assets/images/kidspic1.png";
import star from "../assets/images/starbx.png";
import icon1 from "../assets/images/icon1.png";
import icon2 from "../assets/images/icon2.png";
import icon3 from "../assets/images/icon3.png";
import icon4 from "../assets/images/icon4.png";
import icon5 from "../assets/images/icon5.png";
import icon6 from "../assets/images/icon6.png";
import work1 from "../assets/images/work1.png";
import work2 from "../assets/images/work2.png";
import work3 from "../assets/images/work3.png";
import work4 from "../assets/images/work4.png";
import rokicon from "../assets/images/icon-rock.png";
import playvd from "../assets/images/play-vd.png";
import girlvd from "../assets/images/girl4.png";
import games1 from "../assets/images/games1.png";
import games2 from "../assets/images/games2.png";
import games3 from "../assets/images/games3.png";
import games4 from "../assets/images/games4.png";
import gameFallback from "../assets/images/game-fallback.png";
import paln1 from "../assets/images/plan1.png";
import tick from "../assets/images/tick.png";
import cross from "../assets/images/cross.png";
import girl5 from "../assets/images/girl5.png";
import leftarrow from "../assets/images/leftarrow.png";
import rightarrow from "../assets/images/rightarrow.png";
import vdipic2 from "../assets/images/frame-pic2.png";
// "react-slick": "^0.28.1",

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { HeaderDashboard } from "./layout/HeaderDashboard";
import { useActions } from "../overmind";
import { Helmet } from "react-helmet";
import { HomeFooter } from "./layout/HomeFooter";
import { USER_DETAILS } from "../service/LocalStorage";
import { useState } from "react";
import { getPlansForStudents } from "../service/apis/common";
import { CircularProgress } from "@mui/material";
import Signup from "./signup";
import { fetchData } from "../service/AllApi";

function Home() {
  const [show, setShow] = React.useState("1");
  const [plans, setPlans] = useState([]);
  // const [setUserId] = useState(null);
  const actions = useActions();
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState(null);

  // modal states
  const [modalShow, setModalShow] = useState(false);
  const [modalSignupShow, setModalSignupShow] = useState(false);
  const [gamesData, setGamesData] = useState([]);

  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <div onClick={onClick} className="previow__button">
        <img src={rightarrow} className="img-fluid" alt="" />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div
        // className={className}
        onClick={onClick}
        className="Next__button"
      >
        <img src={leftarrow} className="img-fluid" alt="" />
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    // nextArrow: <img src={rightarrow} className="img-fluid" />,
    // prevArrow: <img src={leftarrow} className="img-fluid" />,
    slidesToScroll: 1,
  };
  const gamesSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    slidesToScroll: 1,
  };
  // console.log("home ");

  const planData = useCallback(async () => {
    setLoading(true);
    const res = await getPlansForStudents(role);
    setPlans(res.data);
    setLoading(false);
  }, [role]);

  React.useEffect(() => {
    if (window.location.href.toString().search("teachers") === -1) {
      window.document.title = "Student management and learning app - Neithedu";
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          "Neithedu is the best learning and student management App. We have customized  features for Teachers, Students and Parents.",
        );
    }
    if (window.location.href.toString().search("teachers") !== -1) {
      window.document.title =
        "Customizable Assignment Tracking For Teachers - Neithedu";
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          "Neithedu is the best student assignment management and test tool for teachers. Teachers can assign and conduct the tests.",
        );
    }
    if (localStorage.getItem(USER_DETAILS)) {
      var user = localStorage.getItem(USER_DETAILS);
      var userDetails = JSON.parse(user);
      setRole(userDetails.role);
      // setUserId(userDetails.applicationUser.id);
    } else {
      return;
    }

    planData();
  }, [planData]);

  // useEffect(())
  // const handlePayment = async (e) => {
  //   try {
  //     setLoading(true);
  //     e.preventDefault();
  //     console.log("hello");
  //     const res = await createSubscription(userId, e.target.value, 1, 1);
  //     console.log(res, "payment");
  //     // history.push(res.shortUrl)
  //     if (res.status === "created") {
  //       window.open(res.shortUrl, "_blank");
  //     }
  //   } catch (error) {
  //     console.log(error.response, "eeee");
  //   }
  // };
  // const planData = async () => {
  //   setLoading(true);
  //   const res = await getPlansForStudents(role);
  //   setPlans(res.data);
  //   setLoading(false);
  // };
  useEffect(() => {
    fetchData("games/get-demo-games-gameflows")
      .then((response) => {
        console.log("demo games", response.data);
        setGamesData(response.data);
      })
      .catch((error) => {
        console.log("demo games error", error);
      });
  }, []);

  return (
    <div className="home-wrapper">
      <Helmet>
        <title>
          Best Learning Apps for Class 6, 7, 10, Online Classes App in India,
          Neithedu Private Limited
        </title>
        <meta
          name="description"
          content="Neithedu, one of the best learning apps for class 6, 7, 10 students, online classes, teaching, and for children's education. Best learning and management App with customized features for Teachers, Students and Parents."
        />
      </Helmet>
      <div className="hero-box">
        <div className="custome-container">
          <HeaderDashboard />
          <div className="banner-holder">
            <Container>
              <div className="row item-center">
                <div className="col-md-6">
                  <div className="home-sliderbx">
                    <div className="slider-title">
                      <h1>Achieve education brilliance in just 20 minutes a day</h1>
                      <p>
                        We provide effective and personalised learning solutions for students of grades 6 to 10 to
        
                            make studying more enjoyable
                      and productive.
                      </p>
                      <div className="btn-playbx" style={{ cursor: "pointer" }}>
                        <a
                          href={
                            "https://games.neithedu.com/?flow=279&isGame=false&token=_token_"
                          }
                          rel="noopener noreferrer"
                          target="_blank"
                          className="playbtn"
                        >
                          <span className="btntex">Play Game</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="right-box-slider">
                    <div className="pic1">
                      <img
                        src={picslider}
                        className="img-fluid zommin-anm"
                        alt=""
                      />
                    </div>
                    <div className="pic1-vector">
                      <img
                        src={picsliderbg}
                        className="img-fluid scaleup-anm"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Container>

            <div className="kids-section-uper">
              <Container>
                <div className="kids-better-box">
                  <span className="bgshpe"></span>
                  <div className="kids-main-bx">
                    <div className="custom-row">
                      <div className="left-pix-card">
                        <div className="pic-box">
                          <div className="kids-pic2">
                            <img src={star} className="img-fluid" alt="" />
                          </div>
                          <div className="kids-pic1">
                            <img
                              src={kidspic1}
                              className="img-fluid pic1"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="kids-right">
                        <div className="kids-content">
                          <h4>Games that make kids better</h4>
                          <p>
                            While playing interesting games, the child receives
                            and consolidates knowledge of subjects. Our games
                            develop the child’s natural learning abilities in
                            the learning style that suits them best. Curriculum
                            aligned content covered for grades 6-10, and
                            personal interactive online lessons with the
                            educator.
                          </p>
                          <p>We teach a child to love learning</p>
                          <div className="btn-week">
                            <button
                              className="btnweak-bx"
                              style={{ border: "none" }}
                              onClick={() => setModalSignupShow(true)}
                            >
                              <span className="btn-title">
                                One week for free
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
          </div>
        </div>
      </div>

      <div className="kids-better">
        <Container>
          <div className="why-illumiya">
            <div className="section-card-">
              <div className="heading-top">
                <h4 className="title-medium">
                  Why Neithedu <br></br>is so useful
                </h4>
              </div>
              <Row>
                <div className="col-md-6 one">
                  <div className="cardbx-left">
                    <div className="cardbx-inner">
                      <div className="card-row">
                        <div className="card-pic">
                          <img src={icon1} className="img-fluid" alt="" />
                        </div>
                        <div className="right-content-card">
                          <p>
                            Achieve small victories through the game to reach
                            success in the future
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 one">
                  <div className="cardbx-left">
                    <div className="cardbx-inner">
                      <div className="card-row">
                        <div className="card-pic">
                          <img src={icon2} className="img-fluid" alt="" />
                        </div>
                        <div className="right-content-card">
                          <p>Fun way to know more</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 one">
                  <div className="cardbx-left">
                    <div className="cardbx-inner">
                      <div className="card-row">
                        <div className="card-pic">
                          <img src={icon3} className="img-fluid" alt="" />
                        </div>
                        <div className="right-content-card">
                          <p>You know if you answered right or not</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 one">
                  <div className="cardbx-left">
                    <div className="cardbx-inner">
                      <div className="card-row">
                        <div className="card-pic">
                          <img src={icon4} className="img-fluid" alt="" />
                        </div>
                        <div className="right-content-card">
                          <p>Dozens of game for every taste </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 one">
                  <div className="cardbx-left">
                    <div className="cardbx-inner">
                      <div className="card-row">
                        <div className="card-pic">
                          <img src={icon5} className="img-fluid" alt="" />
                        </div>
                        <div className="right-content-card">
                          <p>
                            To make mistakes as a part of the learning process
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 one">
                  <div className="cardbx-left">
                    <div className="cardbx-inner">
                      <div className="card-row">
                        <div className="card-pic">
                          <img src={icon6} className="img-fluid" alt="" />
                        </div>
                        <div className="right-content-card">
                          <p>
                            The key to victory — the knowledge of the subject
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
            </div>
          </div>

          <div className="it-works" id="how-it-works-section">
            <div className="works-mainbx">
              <div className="heading-top">
                <h4 className="title-medium">How does it work</h4>
              </div>
              <div className="works-card">
                <Row>
                  <div className="col-md-3 one">
                    <div className="cards-works-bx">
                      <div className="cardpic-works">
                        <img src={work1} className="img-fluid" alt="" />
                      </div>
                      <div className="card-body-works">
                        <div className="smal-title">Sign up</div>
                        <p>Registration requirements: name, year of class </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 two">
                    <div className="cards-works-bx">
                      <div className="cardpic-works">
                        <img src={work2} className="img-fluid" alt="" />
                      </div>
                      <div className="card-body-works">
                        <div className="smal-title">Enter the game</div>
                        <p>Choose the game to practice for</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 three">
                    <div className="cards-works-bx">
                      <div className="cardpic-works">
                        <img src={work3} className="img-fluid" alt="" />
                      </div>
                      <div className="card-body-works">
                        <div className="smal-title">Learn and play!</div>
                        <p>While playing learn the subject</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 four">
                    <div className="cards-works-bx">
                      <div className="cardpic-works">
                        <img src={work4} className="img-fluid" alt="" />
                      </div>
                      <div className="card-body-works">
                        <div className="smal-title">Pass the test</div>
                        <p>The easiest way to pass the exam</p>
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
            </div>
          </div>
        </Container>
        <div className="rockt-icon">
          <img src={rokicon} className="img-fluid" alt="" />
        </div>
      </div>

      <div className="video-section">
        <div className="rocket-icsml">
          <img src={rokicon} className="img-fluid" alt="" />
        </div>
        <div className="videobx-main">
          <Container>
            <div className="video-conatin">
              <div className="video-custom">
                <div className="video-left">
                  <img src={girlvd} className="img-fluid scaleup-anm" alt="" />
                </div>
                <div className="right-video-box">
                  <div className="innervideo-frame">
                    <div className="right-video-card">
                      <img src={vdipic2} className="img-fluid" alt="" />
                    </div>
                    <span className="playvd-btn">
                      <img src={playvd} className="img-fluid" alt="" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Container>

          <div className="top-games" id="games-section">
            <div className="top-games-box">
              <Container>
                <div className="games-main">
                  <div className="heading-top">
                    <h4 className="title-medium">Top games</h4>
                    <span className="subtitle-tex"> Try it</span>
                  </div>

                  <div
                    className="right-box-testi"
                    style={{ background: "transparent" }}
                  >
                    <Slider {...gamesSettings}>
                      <div
                        className="games-card"
                        style={{ background: "transparent" }}
                      >
                        <Row>
                          {gamesData.slice(0, 4).map((item, index) => {
                            return (
                              <div key={index} className="col-md-6 one">
                                <div className="games-card-bx">
                                  <div className="card-inner-games">
                                    <div className="card-pic">
                                      <div className="card-icon">
                                        <img
                                          src={
                                            item.imageURL
                                              ? item.imageURL
                                              : gameFallback
                                          }
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                    <div className="right-card-games">
                                      <div className="games-content-bx">
                                        <h5>{item.categoryName}</h5>
                                        <h4>{item.title}</h4>
                                        <p>{item.description}</p>
                                        <div className="btn-week">
                                          <a
                                            className="btnweak-bx"
                                            href={item.gameURL}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                          >
                                            <span className="btn-title">
                                              Play the game
                                            </span>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Row>
                      </div>
                      <div className="games-card">
                        <Row>
                          {gamesData.slice(4, 8).map((item, index) => {
                            return (
                              <div key={index} className="col-md-6 one">
                                <div className="games-card-bx">
                                  <div className="card-inner-games">
                                    <div className="card-pic">
                                      <div className="card-icon">
                                        <img
                                          src={
                                            item.imageURL
                                              ? item.imageURL
                                              : gameFallback
                                          }
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                    <div className="right-card-games">
                                      <div className="games-content-bx">
                                        <h5>{item.categoryName}</h5>
                                        <h4>{item.title}</h4>
                                        <p>{item.description}</p>
                                        <div className="btn-week">
                                          <a
                                            className="btnweak-bx"
                                            href={item.gameURL}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                          >
                                            <span className="btn-title">
                                              Play the game
                                            </span>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Row>
                      </div>
                    </Slider>
                  </div>
                </div>
              </Container>
            </div>
          </div>

          <div className="choose-plan" id="plan-section">
            <div className="choose-plan-bx">
              <Container>
                <div className="best-plan">
                  <div className="heading-top">
                    <h4 className="title-medium">Choose your best plan</h4>
                  </div>
                  <div className="best-plan-card">
                    <Row>
                      {loading ? (
                        <CircularProgress
                          size={"1.5rem"}
                          sx={{ color: "var(--purple)" }}
                        />
                      ) : (
                        plans &&
                        plans.map((plan, index) => {
                          return (
                            <div key={index} className="col-md-4 one cardbxx">
                              <div className="plan-card">
                                <div className="plan-cardiner">
                                  <div className="card-icon-plan">
                                    <img
                                      src={paln1}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </div>
                                  <div className="title-plancard">
                                    {plan?.item?.name}
                                  </div>
                                  <div className="card-descript-paln">
                                    {plan?.item?.description}
                                  </div>
                                  <div className="card-list-plan">
                                    <ul>
                                      <li>
                                        <span className="icontick">
                                          <img
                                            src={tick}
                                            className="img-fluid"
                                            alt=""
                                          />
                                        </span>
                                        <span className="list-detail-plan">
                                          All standard games for your year
                                        </span>
                                      </li>
                                      <li>
                                        <span className="icontick">
                                          <img
                                            src={cross}
                                            className="img-fluid"
                                            alt=""
                                          />
                                        </span>
                                        <span className="list-detail-plan">
                                          Connection with parents
                                        </span>
                                      </li>
                                      <li>
                                        <span className="icontick">
                                          <img
                                            src={cross}
                                            className="img-fluid"
                                            alt=""
                                          />
                                        </span>
                                        <span className="list-detail-plan">
                                          Possibility to get tasks from your
                                          teacher or tutor (educator)
                                        </span>
                                      </li>
                                      <li>
                                        <span className="icontick">
                                          <img
                                            src={cross}
                                            className="img-fluid"
                                            alt=""
                                          />
                                        </span>
                                        <span className="list-detail-plan">
                                          Study with Tutor Neithedu
                                        </span>
                                      </li>
                                      <li>
                                        <span className="icontick">
                                          <img
                                            src={cross}
                                            className="img-fluid"
                                            alt=""
                                          />
                                        </span>
                                        <span className="list-detail-plan">
                                          Free live lessons with educators
                                        </span>
                                      </li>
                                    </ul>
                                  </div>

                                  <div
                                    style={{ cursor: "pointer" }}
                                    className="btn-buy"
                                  >
                                    <div
                                      // onClick={handlePayment}
                                      className="btn-week "
                                    >
                                      {/* <div className="btnweak-bx"> */}
                                      <Link
                                        to={{
                                          pathname: "student/payment",
                                          state: plan.id,
                                        }}
                                        state={plan.id}
                                        className="btnweak-bx"
                                      >
                                        <span className="btn-title">
                                          Buy for{" "}
                                          {plan?.item?.currency === "USD"
                                            ? "$"
                                            : "₹"}{" "}
                                          {plan?.item?.amount}
                                        </span>
                                        {/* </div> */}
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      )}

                      {/* <div className="col-md-4 two cardbxx">
                        <div className="plan-card">
                          <div className="plan-cardiner">
                            <div className="card-icon-plan">
                              <img src={paln2} className="img-fluid" />
                            </div>
                            <div className="title-plancard">
                              Study with your teacher
                            </div>
                            <div className="card-descript-paln">
                              This plan will suit you if you are working with a
                              teacher
                            </div>
                            <div className="card-list-plan">
                              <ul>
                                <li>
                                  <span className="icontick">
                                    <img src={tick} className="img-fluid" />
                                  </span>
                                  <span className="list-detail-plan">
                                    All standard games for your year
                                  </span>
                                </li>
                                <li>
                                  <span className="icontick">
                                    <img src={tick} className="img-fluid" />
                                  </span>
                                  <span className="list-detail-plan">
                                    Connection with parents
                                  </span>
                                </li>
                                <li>
                                  <span className="icontick">
                                    <img src={tick} className="img-fluid" />
                                  </span>
                                  <span className="list-detail-plan">
                                    Possibility to get tasks from your teacher
                                    or tutor (educator)
                                  </span>
                                </li>
                                <li>
                                  <span className="icontick">
                                    <img src={cross} className="img-fluid" />
                                  </span>
                                  <span className="list-detail-plan">
                                    Study with Tutor Neithedu
                                  </span>
                                </li>
                                <li>
                                  <span className="icontick">
                                    <img src={cross} className="img-fluid" />
                                  </span>
                                  <span className="list-detail-plan">
                                    Free live lessons with educators
                                  </span>
                                </li>
                              </ul>
                            </div>

                            <div className="btn-buy">
                              <div className="btn-week">
                                <a href="#" className="btnweak-bx">
                                  <span className="btn-title">
                                    Buy for{" "}
                                    <span className="pricecutt-of">$70</span>{" "}
                                    $50/mnth
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}

                      {/* <div className="col-md-4 three cardbxx">
                        <div className="plan-card">
                          <div className="plan-cardiner">
                            <div className="card-icon-plan">
                              <img src={paln3} className="img-fluid" />
                            </div>
                            <div className="title-plancard">
                              Study with Neithedu tutor
                            </div>
                            <div className="card-descript-paln">
                              This plan will suit you if you want a personal
                              tutor
                            </div>
                            <div className="card-list-plan">
                              <ul>
                                <li>
                                  <span className="icontick">
                                    <img src={tick} className="img-fluid" />
                                  </span>
                                  <span className="list-detail-plan">
                                    All standard games for your year
                                  </span>
                                </li>

                                <li>
                                  <span className="icontick">
                                    <img src={tick} className="img-fluid" />
                                  </span>
                                  <span className="list-detail-plan">
                                    Possibility to get tasks from your teacher
                                    or tutor (educator)
                                  </span>
                                </li>
                                <li>
                                  <span className="icontick">
                                    <img src={tick} className="img-fluid" />
                                  </span>
                                  <span className="list-detail-plan">
                                    Study with Tutor Neithedu
                                  </span>
                                </li>
                                <li>
                                  <span className="icontick">
                                    <img src={tick} className="img-fluid" />
                                  </span>
                                  <span className="list-detail-plan">
                                    Connection with parents
                                  </span>
                                </li>

                                <li>
                                  <span className="icontick">
                                    <img src={tick} className="img-fluid" />
                                  </span>
                                  <span className="list-detail-plan">
                                    8 live lessons per month with educators
                                  </span>
                                </li>
                              </ul>
                            </div>

                            <div className="btn-buy">
                              <div className="btn-week">
                                <a href="#" className="btnweak-bx">
                                  <span className="btn-title">
                                    Buy for $100/mth
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </Row>
                  </div>
                </div>
              </Container>
            </div>
          </div>
        </div>
      </div>

      <div className="testimaonial-section">
        <div className="testimonial-bx">
          <Container>
            <div className="testi-main">
              <div className="heading-top">
                <h4 className="title-medium">Testimonials</h4>
              </div>
              <div className="testimonial-contain">
                <div className="custom-row">
                  <div className="testim-left">
                    <div className="left-pic">
                      <img
                        src={girl5}
                        className="img-fluid scaleup-anm"
                        alt=""
                      />
                    </div>
                    <div className="right-box-testi">
                      <div className="testimonial-boxx">
                        <Slider {...settings}>
                          <div className="sliderbox">
                            <div className="testi-mainsldr">
                              <div className="slider-cardbox">
                                <div className="slider-innercard">
                                  <h4>That was really cool! I want more!!!</h4>
                                  <p>
                                    Eruditi maluisset suscipiantur te nec. Sed
                                    rebum iudico ut, indoctum theophrastus his
                                    te. Quo quodsi persecuti adipiscing id, sea
                                    commodo vocibus ex. Putent deseruisse te
                                    mei, eos ei porro periculis. Aeque
                                    scribentur eos te, per ullum inani vivendum
                                    an. An mazim consetetur eum, quo alia
                                    platonem urbanitas no. Aeque dolor mel cu,
                                    doming splendide vel ad.
                                  </p>
                                  <h5>David</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="sliderbox">
                            <div className="testi-mainsldr">
                              <div className="slider-cardbox">
                                <div className="slider-innercard">
                                  <h4>That was really cool! I want more!!!</h4>
                                  <p>
                                    Eruditi maluisset suscipiantur te nec. Sed
                                    rebum iudico ut, indoctum theophrastus his
                                    te. Quo quodsi persecuti adipiscing id, sea
                                    commodo vocibus ex. Putent deseruisse te
                                    mei, eos ei porro periculis. Aeque
                                    scribentur eos te, per ullum inani vivendum
                                    an. An mazim consetetur eum, quo alia
                                    platonem urbanitas no. Aeque dolor mel cu,
                                    doming splendide vel ad.
                                  </p>
                                  <h5>David</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="sliderbox">
                            <div className="testi-mainsldr">
                              <div className="slider-cardbox">
                                <div className="slider-innercard">
                                  <h4>That was really cool! I want more!!!</h4>
                                  <p>
                                    Eruditi maluisset suscipiantur te nec. Sed
                                    rebum iudico ut, indoctum theophrastus his
                                    te. Quo quodsi persecuti adipiscing id, sea
                                    commodo vocibus ex. Putent deseruisse te
                                    mei, eos ei porro periculis. Aeque
                                    scribentur eos te, per ullum inani vivendum
                                    an. An mazim consetetur eum, quo alia
                                    platonem urbanitas no. Aeque dolor mel cu,
                                    doming splendide vel ad.
                                  </p>
                                  <h5>David</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Slider>

                        {/* <div className="testimonial-pixx">
                        
                        <img src={testi} className="img-fluid"/>

                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>

          <div className="faq-section">
            <Container>
              <div className="faq-box">
                <div className="heading-top">
                  <h4 className="title-medium">FAQ</h4>
                </div>
                <div className="faq-main">
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          onClick={() => setShow(show === "1" ? null : "1")}
                          className={`accordion-button ${
                            show === "1" ? "collapsed" : ""
                          }`}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded={Boolean(
                            `${show === "1" ? "true" : "false"}`,
                          )}
                          aria-controls="collapseOne"
                        >
                          <span className="faq-title">
                            {" "}
                            What curriculum do you follow?
                          </span>
                          <div className="faq-shape">
                            <span className="shape-bx"></span>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className={`accordion-collapse collapse ${
                          show === "1" ? "show" : ""
                        }`}
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            {" "}
                            All the main curriculums: CBSE, ICSE and State board
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button  onClick={()=>setShow(show=== "2"?null:"2")} className={`accordion-button ${show === "2"?"collapsed":""}`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded={`${show==="2"?"true":"false"}`} aria-controls="collapseTwo">
                <span className="faq-title">How do you personalize the lessons?</span>
                <div className="faq-shape"><span className="shape-bx"></span></div>
                </button>
              </h2>
              <div id="collapseTwo" className={`accordion-collapse collapse ${show==="2"?"show":""}`} aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                <p>Games themselves are personalized. </p>
                <p>One of the advantages is that the students can learn at their own pace. In the 
                  classroom, everyone has to go at the same speed no matter if the subject is very easy or hard for them. 
                  This can lead to boredom or frustration.</p>
                  <p>With games, students can go as fast as they want with the things that are easy for them while spending more time on
                     the things that are more difficult.</p>
                     <p>Even beyond that, the tutor or teacher can modify the games, lessons, and add additional tasks for the student to complete, making the learning experience completely personalized and unique.</p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button onClick={()=>setShow(show=== "3"?null:"3")}  className={`accordion-button ${show === "3"?"collapsed":""}`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded={`${show==="3"?"true":"false"}`} aria-controls="collapseThree">
                <span className="faq-title"> How can I measure progress and see learning improvements?</span>
                <div className="faq-shape"><span className="shape-bx"></span></div>
                </button>
              </h2>
              <div id="collapseThree" className={`accordion-collapse collapse ${show==="3"?"show":""}`} aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                <p>Each (topic) has 10 levels (some vary) The further you progress the more you have learned. </p>
                <p>There will also be milestone badges/certificates to signify the learning achievements. </p>
                </div>
              </div>
            </div>

              <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button onClick={()=>setShow(show=== "4"?null:"4")}  className={`accordion-button ${show === "4"?"collapsed":""}`} type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded={`${show==="4"?"true":"false"}`} aria-controls="collapsefour">
                <span className="faq-title">What happens during the 7-day free trial?</span>
                <div className="faq-shape"><span className="shape-bx"></span></div>
                </button>
              </h2>
              <div id="collapseThree" className={`accordion-collapse collapse ${show==="4"?"show":""}`} aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                <p>You have access to everything in the plan you select except for the <Link to="#">“In Depth Holistic Assessment.”</Link></p>
                <p>You do the initial assessment to know your level of skill, strengths, and weaknesses and also to discover your preferred and natural way of learning so we can personalize
                   the games and tasks for you. </p>
                   <p>After that, you can start playing the games and learning. </p>
                   <p>If you signed up for the <Link to="#">“Study With Your Teacher”</Link> or <Link to="#">“Study With A Certified Neithedu Tutor”</Link> plan you can start working with your teacher or tutor and personalize the 
                     lessons even more from the very beginning.</p>
                     <p>If for whatever reason you are not happy, you can cancel at any time before the trial expires and you will not be charged.</p>
                </div>
              </div>
            </div>  

             <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button onClick={()=>setShow(show=== "5"?null:"5")}  className={`accordion-button ${show === "5"?"collapsed":""}`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded={`${show==="5"?"true":"false"}`} aria-controls="collapseThree">
                <span className="faq-title">Is the “In Depth Holistic Assessment” included in the free trial?</span>
                <div className="faq-shape"><span className="shape-bx"></span></div>
                </button>
              </h2>
              <div id="collapseThree" className={`accordion-collapse collapse ${show==="5"?"show":""}`} aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                <p>No. It’s only included with the <Link to="#">“Study With A Certified Neithedu Tutor”</Link>  paid plan. But once your free trial ends, if decided to go with that plan, you won’t lose access
                   to the assessment and you’ll be able to do it at no additional cost. </p>
               
                </div>
              </div>
            </div>     */}

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          onClick={() => setShow(show === "6" ? null : "6")}
                          className={`accordion-button ${
                            show === "6" ? "collapsed" : ""
                          }`}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded={Boolean(
                            `${show === "6" ? "true" : "false"}`,
                          )}
                          aria-controls="collapseThree"
                        >
                          <span className="faq-title">
                            Do I need to enter my credit card information?
                          </span>
                          <div className="faq-shape">
                            <span className="shape-bx"></span>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className={`accordion-collapse collapse ${
                          show === "6" ? "show" : ""
                        }`}
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            Yes, we ask for credit card information to make sure
                            an adult is approving registration. We also need to
                            follow The Children's Online Privacy Protection Act
                            (COPPA).
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          onClick={() => setShow(show === "7" ? null : "7")}
                          className={`accordion-button ${
                            show === "7" ? "collapsed" : ""
                          }`}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded={Boolean(
                            `${show === "7" ? "true" : "false"}`,
                          )}
                          aria-controls="collapseThree"
                        >
                          <span className="faq-title">
                            Can I change plans or cancel any time?
                          </span>
                          <div className="faq-shape">
                            <span className="shape-bx"></span>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className={`accordion-collapse collapse ${
                          show === "7" ? "show" : ""
                        }`}
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            Yes, you can switch plans just by going to your{" "}
                            <strong>
                              Profile {`>`} Settings {`>`} Upgrade
                            </strong>{" "}
                            at any time you want, no questions asked.
                          </p>
                          <p>
                            If you want to cancel your membership{" "}
                            <strong>
                              Profile {`>`} Settings {`>`} Cancel
                            </strong>{" "}
                            at any time you want, no questions asked.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          onClick={() => setShow(show === "8" ? null : "8")}
                          className={`accordion-button ${
                            show === "8" ? "collapsed" : ""
                          }`}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded={Boolean(
                            `${show === "8" ? "true" : "false"}`,
                          )}
                          aria-controls="collapseThree"
                        >
                          <span className="faq-title">
                            Do you have a sibling discount?
                          </span>
                          <div className="faq-shape">
                            <span className="shape-bx"></span>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className={`accordion-collapse collapse ${
                          show === "8" ? "show" : ""
                        }`}
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            Yes, with both <i>“Study With Your Teacher”</i> and{" "}
                            <i>“Study With A Tutor”</i> plans you’ll have access
                            to sibling discounts.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>

          <div className="still-thinking">
            <Container>
              <div className="still-thnk-box">
                <div className="still-content">
                  <p>
                    Are You still thinking you aren’t “so smart” or it’s not
                    “your thing”? Stop doing it! Learn and play with Neithedu
                    games
                  </p>
                </div>
                <div className="week-free">
                  <div className="weekfre-box">
                    <div className="weekshape-bg"></div>
                    <div className="weekfre-card">
                      <p>
                        One week free of Neithedu games to make sure you can
                        reach the higher grades! Just try it!
                      </p>
                      <div className="btn-buy">
                        <div className="btn-week">
                          <button
                            className="btnweak-bx"
                            style={{ border: "none" }}
                            onClick={() => setModalSignupShow(true)}
                          >
                            <span className="btn-title">Start for free</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="rightpic-grl">
                  <img
                    src={picslider}
                    className="img-fluid scaleup-anm"
                    alt=""
                  />
                </div>
              </div>
            </Container>
          </div>
        </div>
        <HomeFooter />
      </div>
      <Signup
        modalSignupShow={modalSignupShow}
        setModalShow={(value) => setModalShow(value)}
        setModalSignupShow={(value) => setModalSignupShow(value)}
      />
    </div>
  );
}

export default Home;
