import React from "react";
import { useActions } from "../../../overmind";
import { GoToQuestionnaire } from "../../Questionnaire/Dialogs/GoToQuestionnaire";
import Questionnaire from "../../Questionnaire";

const QuesTest = () => {
  const actions = useActions();
  const btnClickHandler = () => {
    actions.openQuestionnaire(true);
  };
  return (
    <>
      <button onClick={btnClickHandler}>
        click here to open questionnaire
      </button>
      <Questionnaire />
    </>
  );
};

export default QuesTest;
