import React from "react";
import "../home.scss";
import "../teachers.scss";
import "./blog.scss";
import "../media.scss";
import { Container, Row, Button, Form, Spinner } from "react-bootstrap";
// import logo from "../../assets/images/logo.png";

import { useHistory } from "react-router-dom";

import { HeaderDashboard } from "../layout/HeaderDashboard";
import { useAppState } from "../../overmind";
import { HomeFooter } from "../layout/HomeFooter";

function Blog() {
  const blog = useAppState().blogs;
  const [blogSearchQuery, setblogSearchQuery] = React.useState("");
  const history = useHistory();
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(4);
  const [active, setActive] = React.useState(0);
  const blogCategories = useAppState()?.keyValues?.BlogCategory;
  const [selectedCategory, setselectedCategory] = React.useState(null);

  const handleBlogQuerySearch = (blog) => {
    if (
      blog.title.includes(blogSearchQuery) ||
      blog.description.includes(blogSearchQuery)
    )
      return true;
    else return false;
  };

  const getLenght = (array) => {
    let lenght = array?.length / 4;
    return Math.ceil(lenght);
  };
  return (
    <div className="home-wrapper">
      <div className="inner-page-topbar">
        <div className="custome-container">
          <HeaderDashboard />
        </div>
      </div>

      <div className="blogsection">
        <Container>
          <div className="blog-serchbar">
            <div className="serchbar-box">
              <div className="serchfiled">
                <Form.Control
                  value={blogSearchQuery}
                  onChange={(e) => setblogSearchQuery(e.target.value)}
                  type="text"
                  placeholder="Type what you are looking for"
                  className="formbox"
                />
                <Button type="button" className="seachbtn">
                  <i className="fas fa-search"></i>
                </Button>
              </div>
            </div>
          </div>

          <div className="tagsbox-list">
            <ul>
              {Array.isArray(blogCategories) &&
                blogCategories.map((blog) => {
                  return (
                    <li
                      onClick={() => setselectedCategory(blog.value)}
                      style={{ cursor: "pointer" }}
                      key={blog.value}
                    >
                      <span
                        className="tagbtnn"
                        style={{
                          maxWidth: "100%",
                        }}
                      >
                        {blog.text}
                      </span>
                    </li>
                  );
                })}
            </ul>
          </div>

          {
            <div className="blogcard">
              {/* var trimmedString = yourString.substr(0, maxLength);
              trimmedString = trimmedString.substr(0, Math.min(trimmedString.length,
              trimmedString.lastIndexOf(" "))) */}
              {blog
                ?.filter((b) => {
                  if (!selectedCategory) return true;
                  return String(b.category) === String(selectedCategory);
                })
                ?.filter(handleBlogQuerySearch)
                .slice(start, end)
                .map((item, idx) => {
                  return (
                    <div
                      style={{ cursor: "pointer" }}
                      key={idx}
                      className="card-blogmain"
                      onClick={() => {
                        return history.push(`/blog/article/${item?.id}`);
                      }}
                    >
                      <Row className="align-center">
                        <div className="col-md-4 one">
                          <div className="blog-leftpic">
                            <div className="picard">
                              <img src={item?.image} className="img-fluid" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-8 two">
                          <div className="blog-card-right">
                            <div className="content-card-inner">
                              <div className="title-main">{item?.title}</div>
                              <p
                                className="small-tex"
                                dangerouslySetInnerHTML={{
                                  __html: `${item?.description.substr(
                                    0,
                                    600,
                                  )}...`,
                                }}
                              ></p>

                              <div className="blog-date">
                                {new Date(item?.createdOn).toDateString()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </div>
                  );
                })}
              {/* 
            <div className="card-blogmain">
              <Row className="align-center">
                <div className="col-md-4 one">
                  <div className="blog-leftpic">
                    <div className="picard">
                      <img src={blg2} className="img-fluid" />
                    </div>
                  </div>
                </div>
                <div className="col-md-8 two">
                  <div className="blog-card-right">
                    <div className="content-card-inner">
                      <div className="title-main">Meliore evertitur his ut</div>
                      <p className="small-tex">
                        Small text about the main idea and why it works. It
                        should be not long text to catch the attention You can’t
                        be sure if you understand the lessons until the day of
                        the test, this is a problem. Games allow you to learn
                        through small victories that will boost your confidence
                        and eliminate fear and anxiety before a test.
                      </p>
                      <p className="small-tex">
                        {" "}
                        Small text about the main idea and why it works. It
                        should be not long text to catch the attention You can’t
                        be sure if you understand the lessons until the day of
                        the test, this is a problem. Games allow you to learn
                        through small victories that will boost your confidence
                        and eliminate fear and anxiety before a test.
                      </p>

                      <div className="blog-date">October 05, 2020</div>
                    </div>
                  </div>
                </div>
              </Row>
            </div>

            <div className="card-blogmain">
              <Row className="align-center">
                <div className="col-md-4 one">
                  <div className="blog-leftpic">
                    <div className="picard">
                      <img src={blg3} className="img-fluid" />
                    </div>
                  </div>
                </div>
                <div className="col-md-8 two">
                  <div className="blog-card-right">
                    <div className="content-card-inner">
                      <div className="title-main">Meliore evertitur his ut</div>
                      <p className="small-tex">
                        Small text about the main idea and why it works. It
                        should be not long text to catch the attention You can’t
                        be sure if you understand the lessons until the day of
                        the test, this is a problem. Games allow you to learn
                        through small victories that will boost your confidence
                        and eliminate fear and anxiety before a test.
                      </p>
                      <p className="small-tex">
                        {" "}
                        Small text about the main idea and why it works. It
                        should be not long text to catch the attention You can’t
                        be sure if you understand the lessons until the day of
                        the test, this is a problem. Games allow you to learn
                        through small victories that will boost your confidence
                        and eliminate fear and anxiety before a test.
                      </p>

                      <div className="blog-date">October 05, 2020</div>
                    </div>
                  </div>
                </div>
              </Row>
            </div>

            <div className="card-blogmain">
              <Row className="align-center">
                <div className="col-md-4 one">
                  <div className="blog-leftpic">
                    <div className="picard">
                      <img src={blg4} className="img-fluid" />
                    </div>
                  </div>
                </div>
                <div className="col-md-8 two">
                  <div className="blog-card-right">
                    <div className="content-card-inner">
                      <div className="title-main">Meliore evertitur his ut</div>
                      <p className="small-tex">
                        Small text about the main idea and why it works. It
                        should be not long text to catch the attention You can’t
                        be sure if you understand the lessons until the day of
                        the test, this is a problem. Games allow you to learn
                        through small victories that will boost your confidence
                        and eliminate fear and anxiety before a test.
                      </p>
                      <p className="small-tex">
                        {" "}
                        Small text about the main idea and why it works. It
                        should be not long text to catch the attention You can’t
                        be sure if you understand the lessons until the day of
                        the test, this is a problem. Games allow you to learn
                        through small victories that will boost your confidence
                        and eliminate fear and anxiety before a test.
                      </p>

                      <div className="blog-date">October 05, 2020</div>
                    </div>
                  </div>
                </div>
              </Row>
            </div> */}

              <div className="pagination-blog">
                <ul>
                  {blog
                    ?.slice(
                      0,
                      getLenght(
                        blog
                          ?.filter((b) => {
                            if (!selectedCategory) return true;
                            return (
                              String(b.category) === String(selectedCategory)
                            );
                          })
                          ?.filter(handleBlogQuerySearch),
                      ),
                    )
                    .map((item, index) => {
                      return (
                        <li key={index}>
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              setStart(index * 4);
                              setEnd(index + 4);
                              setActive(index);
                            }}
                            className={`pagination-name ${
                              active === index ? "active" : ""
                            }`}
                          >
                            {index + 1}
                          </a>
                        </li>
                      );
                    })}
                  {/* <li>
                  <a href="#" className="pagination-name">
                    1
                  </a>
                </li>
                <li>
                  <a href="#" className="pagination-name active">
                    2
                  </a>
                </li>
                <li>
                  <a href="#" className="pagination-name">
                    3
                  </a>
                </li>
                <li>
                  <a href="#" className="pagination-name">
                    4
                  </a>
                </li> */}
                </ul>
              </div>
            </div>
          }
        </Container>
      </div>

      <div className="testimaonial-section subscribe-blog">
        <div className="subs-blog">
          <Container>
            <div className="subscribe-bxx">
              <div className="communty-box">
                <div className="communty-section">
                  <div className="heading-top">
                    <h4 className="title-medium">Subscribe to our blog pot</h4>
                  </div>
                  <div className="communtiy-content">
                    <p className="small-tex">
                      We guarantee antispam! Only the most interesting materials
                      and news! And discounts!
                    </p>

                    <div className="subs-form">
                      <Form>
                        <div className="custom-row">
                          <div className="leftfiel-subs">
                            <div className="subsform">
                              <Form.Group
                                className="subsform-fild"
                                controlId="formBasicEmail"
                              >
                                <Form.Control
                                  type="email"
                                  placeholder="Enter email"
                                />
                              </Form.Group>
                            </div>
                          </div>

                          <div className="right-subbtn">
                            <div className="btn-buy tutor-becm">
                              <div className="btn-week">
                                <a href="#" className="btnweak-bx">
                                  <span className="btn-title">Subscribe</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>

        <HomeFooter />
      </div>
    </div>
  );
}

export default Blog;
