import { Box, Typography } from "@mui/material";
import React from "react";
import {
  CloseButton,
  StyledPurpleButton,
  StyledYellowButton,
} from "../../utils/Buttons";
import { DialogBox } from "../../utils/DialogBox";

export const CloseConfirmationDialog = ({ close, closeBtn, continueBtn }) => {
  return (
    <DialogBox isOpen={true}>
      <CloseButton closeHandler={close} />
      <Box sx={{ textAlign: "center" }}>
        <Typography
          sx={{
            mb: "1rem",
            color: "var(--purple-dark)",
            fontWeight: "800",
            fontSize: "1.3rem",
          }}
        >
          We didn't finish yet!
        </Typography>
        <Typography
          sx={{
            mb: "2rem",
            color: "var(--purple-dark)",
            fontWeight: "700",
            fontSize: "1.2rem",
          }}
        >
          Are you sure that you want to close the questionnaire?
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <StyledPurpleButton title={"Close"} btnHandler={closeBtn} />
          <StyledYellowButton title="Continue" btnHandler={continueBtn} />
        </Box>
      </Box>
    </DialogBox>
  );
};
