import React from "react";
import { Button, Form, Row } from "react-bootstrap";
import { getGrades } from "../../service/apis/games";
import { useQuery } from "@tanstack/react-query";

const TopSearchBar = ({
  selectedGrade,
  setSelectedGrade,
  textSearch,
  setTextSearch,
  btnText,
  btnClickHandler,
}) => {
  const { data: gradesData } = useQuery({
    queryKey: ["grades"],
    queryFn: async () => {
      const grades = await getGrades();
      return grades;
    },
  });

  return (
    <div className="games-topserch-bar">
      <Form>
        <Row className="align-center">
          <div className="col-md-3 one">
            <div className="list-study">
              <Form.Control
                as="select"
                value={selectedGrade}
                onChange={(e) => setSelectedGrade(e.target.value)}
              >
                <option value="0">All years of study</option>
                {gradesData &&
                  gradesData.map((val, index) => {
                    return (
                      <option
                        value={val}
                        key={index}
                      >{`${val}th year of study`}</option>
                    );
                  })}
              </Form.Control>
            </div>
          </div>
          <div className="col-md-6 two">
            <div className="serch-bx-games">
              <div className="serchfiled-activities">
                <Form.Control
                  type="text"
                  placeholder="Start typing title, subject or any other keyword "
                  className="formbox"
                  value={textSearch}
                  onChange={(e) => setTextSearch(String(e.target.value))}
                />
                <Button type="button" className="seachbtn">
                  <i className="fas fa-search"></i>
                </Button>
              </div>
            </div>
          </div>
          {btnText && (
            <div className="col-md-3 three">
              <div className="createnew-games">
                <div className="game-opn-create">
                  <div
                    className="btnweak-bx gamestbs-btn cursor-pointer"
                    onClick={btnClickHandler}
                  >
                    {btnText}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Row>
      </Form>
    </div>
  );
};

export default TopSearchBar;
