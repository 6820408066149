import React from "react";
import { Form, Modal } from "react-bootstrap";

const NotificationModal = ({ open, heading, detail, btnText, onClose }) => {
  return (
    <Modal
      className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
      show={open}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="login-modal-mainbx groupmbr-step2 thanks-applictn">
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>{heading}</h4>
            <p>{detail}</p>
          </Modal.Title>
          <button type="button" onClick={() => onClose()} className="close">
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="login-modl-form">
            <div className="loginform-bx">
              <Form.Group className="subsform-fild">
                <div className="btn-delet-bx">
                  <button
                    className=" btnweak-bx btnlogin thanxbtn"
                    type="submit"
                    onClick={() => onClose()}
                  >
                    {btnText}
                  </button>
                </div>
              </Form.Group>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default NotificationModal;
