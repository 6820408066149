import React from "react";
import { HeaderDashboard } from "../layout/HeaderDashboard";
import logo from "../../assets/images/logo.png";
import Footer from "../layout/Footer/Footer";
import { withRouter } from "react-router";
import "../home.scss";
import "../profile.scss";
import "../loginModal/login.scss";
import "../dashboard.scss";
import "../sidemenu.scss";
import "../media.scss";
import PersonalRewardInfo from "./PersonalRewardInfo";
import VerticalTimeline from "../VTimeline/VTimeline";

const PersonalReward = (props) => {
  return (
    <div className="home-wrapper " style={{ fontFamily: "Mulish" }}>
      <HeaderDashboard />
      <div
        className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs"
        style={{ minHeight: "100vh" }}
      >
        <div className="container">
          <PersonalRewardInfo />
          <VerticalTimeline />
        </div>
        <Footer logo={logo} />
      </div>
    </div>
  );
};
export default withRouter(PersonalReward);
