import React, { useEffect } from "react";
import { Container, Row, Form, Modal, Table, Col } from "react-bootstrap";
import { Fragment, useState } from "react";
import { Box } from "@mui/system";
import { CircularProgress } from "@mui/material";

import "../home.scss";
import "../teachers.scss";
import "../blog/blog.scss";
import "../profile.scss";
import "../loginModal/login.scss";
import "../media.scss";
import "../student/StudentProfile/studentProfile.scss";
import { updateStudentSyllabus } from "../../service/apis/student";
import { useActions, useAppState } from "../../overmind";
import {
  getProfileDetails,
  getSubjects,
  getSyllabusesListByCountryId,
} from "../../service/apis/common";
import { fetchData } from "../../service/AllApi";
import { filterCountryId } from "../../utils/filterArray";
import { useHistory } from "react-router";
import { CustomSnackbar, useSnackState } from "../../utils/useSnackState";

export const Syllabus = ({ setbackdrop, setsetbackdrop }) => {
  const state = useAppState();
  const currentData = JSON.parse(localStorage.getItem("user"));
  const history = useHistory();
  let userDetail = state.userDetail;
  const actions = useActions();
  const [syllabusUpdating, setSyllabusUpdating] = useState(false);
  //   const [setbackdrop, setsetbackdrop] = useState(false);
  const [syllabusLoading, setSyllabusLoading] = useState(false);
  const [selsyl, setselsyl] = useState(0);
  const [sylList, setsylList] = useState([]);
  const [countries, setCountries] = useState(null);
  const [countryId, setCountryId] = useState(null);
  const [snackProps, snackOpen] = useSnackState();

  const [syllabusId, setSyllabusId] = useState(
    currentData?.applicationUser.syllabusId,
  );

  useEffect(() => {
    if (setbackdrop === true) {
      fetchCountries();
    }
  }, []);

  useEffect(() => {
    if (countries != null) {
      fetchProfileDetails();
    }
  }, [countries]);

  useEffect(() => {
    if (countryId != null) {
      fetchSyllabuses();
    }
  }, [countryId]);
  const fetchProfileDetails = async () => {
    try {
      const res = await getProfileDetails();
      const countryArray = filterCountryId(countries, res.country);
      countryArray.map((c) => {
        setCountryId(c.id);
      });
    } catch (error) {}
  };
  const fetchCountries = async () => {
    try {
      setSyllabusLoading(true);
      const res = await fetchData("countries");
      if (res && res.data) {
        setCountries(res.data);
      }
    } catch (error) {}
  };

  // const getSubjectsData = async () => {
  //   try {
  //     const res = await getSubjects(syllabusId);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const fetchSyllabuses = async () => {
    try {
      const res = await getSyllabusesListByCountryId(countryId);
      setsylList(res);
      setSyllabusLoading(false);
    } catch (err) {}
  };
  // console.log(currentData, "sss");
  const handleUpdateSyllabus = async () => {
    try {
      console.log("syllabusid", syllabusId);
      console.log("currentData", currentData.applicationUser.id);
      if (syllabusId == 0 || !syllabusId) {
        snackOpen("Please select a syllabus", "error");
        return;
      }
      setSyllabusUpdating(true);
      const res = await updateStudentSyllabus(
        parseInt(syllabusId),
        currentData?.applicationUser.id,
      );
      if (res == currentData?.applicationUser.id) {
        currentData.applicationUser.syllabusId = parseInt(syllabusId);
        localStorage.setItem("user", JSON.stringify(currentData));

        await actions.setSelectedSubjectId(selsyl);
        setSyllabusUpdating(false);
        history.push("/dashboard");
      }

      setsetbackdrop(false);
    } catch (err) {
      console.log(err);
      setSyllabusUpdating(false);
    }
  };

  return (
    <div>
      <Modal
        className="loginmodal"
        // {...props}
        show={setbackdrop}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <CustomSnackbar {...snackProps} />

        <div className="login-modal-mainbx">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Select a syllabus</h4>
              <p>
                You can only select one syllabus. You will not be able to change
                it.
              </p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <Form.Label>syllabus</Form.Label>
                  {syllabusLoading ? (
                    <Box sx={{ textAlign: "center" }}>
                      <CircularProgress
                        size={"1.5rem"}
                        sx={{
                          color: "var(--purple)",
                        }}
                      />
                    </Box>
                  ) : (
                    <Form.Control
                      as="select"
                      value={selsyl}
                      onChange={(e) => {
                        setSyllabusId(e.target.value);
                        setselsyl(e.target.value);
                      }}
                    >
                      <option value="">Choose Syllabus</option>
                      {sylList.map((options) => (
                        <option value={options.id} key={options.id}>
                          {options.name}
                        </option>
                      ))}
                    </Form.Control>
                  )}
                </Form.Group>

                <div className="btnlogin-main">
                  <button
                    onClick={handleUpdateSyllabus}
                    className="btnlogin btnweak-bx"
                  >
                    {syllabusUpdating ? (
                      <CircularProgress
                        size={"1.5rem"}
                        sx={{ color: "var(--purple)" }}
                      />
                    ) : (
                      "Confirm"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};
