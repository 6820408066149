import { Box, Modal, Typography } from "@mui/material";
import React from "react";
import {
  CloseButton,
  StyledPurpleButton,
  StyledYellowButton,
} from "../../utils/Buttons";
import { DialogBox } from "../../utils/DialogBox";

export const GoToQuestionnaire = ({
  close,
  remindMeLater,
  goToQuestionnaire,
}) => {
  return (
    <>
      <DialogBox isOpen={true}>
        <CloseButton closeHandler={close} />
        <Box
          sx={{
            borderRadius: "20px",
            p: "1.5rem",
            textAlign: "center",
            minWidth: "25rem",
          }}
        >
          <Typography
            sx={{
              mb: "0.5rem",
              color: "var(--purple-dark)",
              fontSize: "1.3rem",
              fontWeight: "800",
            }}
          >
            Find out your learning styles!
          </Typography>
          <Typography
            sx={{ mb: "0.5rem", color: "var(--black)", fontWeight: "500" }}
          >
            Do you know there are 4 types of learning style? Knowing your type
            will help you learn subjects much faster and memorize more!
          </Typography>
          <Typography
            mb={"2rem"}
            sx={{
              color: "var(--purple-dark)",
              fontSize: "1.2rem",
              fontWeight: "700",
            }}
          >
            Questionnaire takes only a few minutes! And after passing it, you
            will receive +50 points!{" "}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            columnGap: "3rem",
            alignItems: "center",
            position: "absolute",
            bottom: "-1rem",
          }}
        >
          <StyledPurpleButton
            title={"Remind me later"}
            btnHandler={remindMeLater}
          />

          <StyledYellowButton
            title={"Go to the questionnaire!"}
            btnHandler={goToQuestionnaire}
          />
        </Box>
      </DialogBox>
    </>
  );
};
