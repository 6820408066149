import React from "react";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../loginModal/login.scss";

const FooterParent = ({ logo }) => {
  return (
    <div className="footer-main ">
      <div className="custome-container">
        <div className="footer-section footer-parents">
          <div className="footershape-bg"></div>
          <Row>
            <div className="col-md-4 one">
              <div className="foter-logo">
                <Link to="/">
                  <img src={logo} className="img-fluid" />
                </Link>
              </div>
            </div>
            <div className="col-md-8 two">
              <div className="foter-list">
                <div className="foter-listnew">
                  <ul>
                    <li>
                      <Link to="#">My children</Link>
                    </li>
                    <li>
                      <Link to="#">Children progress</Link>
                    </li>
                    <li>
                      <Link to="#">Tutors</Link>
                    </li>
                    <li>
                      <Link to="#">Contacts</Link>
                    </li>
                    <li>
                      <Link to="#">Terms of Use & Privacy</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Row>
        </div>

        <div className="foter-copywrite">
          <ul>
            <li>1999-2021 Neithedu.</li>
            <li>All Rights Reserved</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FooterParent;
