import React from "react";
import { Form, Modal } from "react-bootstrap";
import { CustomSnackbar } from "../../utils/useSnackState";
import { Label } from "@mui/icons-material";
import DatePicker from "react-datepicker";

const DeadlineMpdal = ({
  open,
  closeModal,
  snackProps,
  deadline,
  setdeadline,
  submitHandler,
  openSnack,
}) => {
  const onSubmit = () => {
    if (deadline === "") {
      openSnack("Please select a date", "error");
      return;
    }
    submitHandler();
  };
  return (
    <Modal
      className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
      show={open}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="login-modal-mainbx groupmbr-step2 mystudent-games">
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>Set Deadline</h4>
          </Modal.Title>

          <button type="button" onClick={closeModal} className="close">
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </Modal.Header>
        <CustomSnackbar {...snackProps} />
        <Modal.Body>
          <div className="login-modl-form">
            <div className="loginform-bx">
              <Form.Group className="subsform-fild">
                <div className="serch-inner-group">
                  <div className="serchfiled-activities">
                    <Label>Deadline</Label>
                    <DatePicker
                      selected={deadline}
                      minDate={new Date() - 1}
                      dateFormat="dd-MM-yyyy"
                      className="dateformat"
                      onChange={(date) => setdeadline(date)}
                    />
                  </div>
                </div>
              </Form.Group>
              <div className="btnlogin-main">
                <button
                  onClick={onSubmit}
                  type="submit"
                  className="btnlogin btnweak-bx"
                >
                  send
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default DeadlineMpdal;
