export const convertDateToObject = (date) => {
  // date is in dd-mm-yyyy format
  const dateArr = date.split("-");
  const dateObj = new Date(`${dateArr[1]}-${dateArr[0]}-${dateArr[2]}`);
  return dateObj;
};

export const convertDateToString = (date) => {
  return new Date(date).toLocaleDateString("en-GB").replaceAll("/", "-");
};

export const combineDateTime = (date, time) => {
  const dateObj = convertDateToObject(date);

  const hour = time.split(":")[0];
  const minute = time.split(":")[1].split(" ")[0];
  const ampm = time.slice(-2);
  const hour24 =
    ampm === "AM" ? hour : ampm === "PM" ? (parseInt(hour) % 12) + 12 : hour;
  return new Date(
    dateObj.getFullYear(),
    dateObj.getMonth(),
    dateObj.getDate(),
    hour24,
    minute,
  );
};

export function convertmsToDHM(milliseconds) {
  // Calculate the number of milliseconds in a day, hour, and minute
  const millisecondsInDay = 24 * 60 * 60 * 1000;
  const millisecondsInHour = 60 * 60 * 1000;
  const millisecondsInMinute = 60 * 1000;

  // Calculate the number of days, hours, and minutes
  const days = Math.floor(milliseconds / millisecondsInDay);
  milliseconds %= millisecondsInDay;
  const hours = Math.floor(milliseconds / millisecondsInHour);
  milliseconds %= millisecondsInHour;
  const minutes = Math.floor(milliseconds / millisecondsInMinute);

  return { days, hours, minutes };
}

export const convert12to24HrFormat = (time) => {
  const hour = time.split(":")[0];
  const minute = time.split(":")[1];
  const ampm = hour >= 12 ? "PM" : "AM";
  let hour12 = hour % 12 || 12;
  hour12 = hour12 < 10 ? `0${hour12}` : hour12;
  return `${hour12}:${minute}:00 ${ampm}`;
};

export const addOffsetToTime = (date) => {
  // get utc plus or minus, and add it to the  date
  const utc = -new Date().getTimezoneOffset() / 60;
  console.log("utc", utc);
  return new Date(date.getTime() + utc * 60 * 60 * 1000);
};

export const getGameUrl = (gameUrl, tokenID) => {
  if (!gameUrl || !tokenID) return "#";
  // https://neithedu.com/?flow=1390&isGame=true&GameType=Quiz&CrosswordLevel=&token=_token_&Role=Student&ActivityDetails=1-1262-0

  const flow = gameUrl?.split("flow=")[1]?.split("&")[0];
  let isGame = gameUrl?.split("isGame=")[1]?.split("&")[0];
  let gameType = gameUrl?.split("GameType=")[1]?.split("&")[0];
  let CrosswordLevel = gameUrl?.split("CrosswordLevel=")[1]?.split("&")[0];
  const role = gameUrl?.split("Role=")[1]?.split("&")[0];
  const ActivityDetails = gameUrl?.split("ActivityDetails=")[1]?.split("&")[0];

  if (gameType?.toLowerCase() === "wordsearch") {
    gameType = "WordSearch";
  } else if (gameType?.toLowerCase() === "crossword") {
    gameType = "Crossword";
  } else if (gameType?.toLowerCase() === "quiz") {
    gameType = "Quiz";
  } else if (gameType?.toLowerCase() === "material") {
    gameType = "Material";
  }

  isGame = isGame?.toLowerCase() === "true" ? true : false;

  const redirectTo =
    "/game?isGame=" +
    isGame +
    "&GameType=" +
    gameType +
    "&flow=" +
    flow +
    "&CrosswordLevel=" +
    CrosswordLevel +
    "&Role=" +
    role +
    "&ActivityDetails=" +
    ActivityDetails +
    "&Token=" +
    tokenID;

  return redirectTo;
};
