import React from "react";

import {
  // Navbar,
  // Nav,
  // NavDropdown,
  Container,
  // Dropdown,
  // br,
  // Row,
  // Accordion,
  // Card,
  Button,
  Form,
  Modal,
  // Select,
  // Sonnet,
} from "react-bootstrap";

import logo from "../../../assets/images/logo.png";
// import gamesgalery1 from "../../../assets/images/games-galery1.jpg";
// import gamesgalery2 from "../../../assets/images/games-galery2.jpg";
// import chaticon from "../../../assets/images/chat-icon.svg";
// import batchorange from "../../../assets/images/batch-orange.svg";
// import palinblue from "../../../assets/images/palin-blue.svg";
// import mike from "../../../assets/images/mike.svg";
// import attachemtnblue from "../../../assets/images/attachemtn-blue.svg";
// import downarrowblue from "../../../assets/images/down-arrow-blue.svg";
// import dotthree from "../../../assets/images/dotthree.svg";
// import addbtnround from "../../../assets/images/add-btn.svg";
// import darkblueplan from "../../../assets/images/darkblue-plan.svg";
import backorange from "../../../assets/images/backorange.svg";
// import editorangelg from "../../../assets/images/editlg-orange.svg";
// import darkorangeplan from "../../../assets/images/darkorange-plan.svg";

// import Slider from "react-slick";
// import SliderCenter from "../../Slider";
import { Link } from "react-router-dom";
import FooterEducator from "../../layout/Footer/FooterEducator";
// import Addgroup from "../../Addgroup";
// import Addpupil from "../../Addpupil";
// import SendInvite from "../../SendInvite";
// import Groupmember from "../../Groupmember";
import HeaderGames from "../../layout/Header/HeaderGames";
// import SendRevision from "../../SendRevision";
import Selectstudent from "../../Selectstudent";
import { Box, Divider, Grid, Typography } from "@mui/material";

function QuizLate() {
  const [state, setstate] = React.useState({
    mode: "Active",
    status: "Late",
    sent: "22 Nov, 2021 12:06am",
    autoChecked: 0,
    manualChecked: 0,
    needToCheck: 0,
  });
  // const [breadMenuShow, setBreadMenuShow] = React.useState(false);
  /* start modal */
  const [modalShow, setModalShow] = React.useState(false);
  /* end modal */

  /* startsave gamed step1 */
  const [modalgamesavedstep1Show, setgamesavedstep1ModalShow] =
    React.useState(false);

  /* end save gamned step1 */

  /* start send revision popup */
  // const [modalpersonaltskstep1Show, setpersonaltskstep1ModalShow] =
  //   React.useState(false);

  /* end send revision popup */

  /* start select student */
  const [modalAddgroupstep2Show, setAddgroupstep2ModalShow] =
    React.useState(false);

  /* end select student */

  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderGames setModalShow={(value) => setModalShow(value)} />
      {/* end header */}

      <div className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs">
        <div className="games-photosynths gamequiz-main">
          <Container>
            <div className="quiz-game-popup">
              <div className="current-lesson-main ">
                <div className="current-lesson-contain">
                  <span className="back-btndrop">
                    <Link to="/educator/quizStatus" className="backbtn-lg">
                      <img src={backorange} alt=""/>
                    </Link>
                  </span>
                </div>
              </div>

              <Box>
                <Typography
                  sx={{ color: "white", fontSize: "1.6rem", fontWeight: "700" }}
                >
                  Name of the student
                </Typography>
                <Grid
                  container
                  // justifyContent={"center"}
                  sx={{
                    mt: "2rem",
                    textAlign: "start",
                    mx: "auto",
                    maxWidth: "60rem",
                    p: "2rem",
                    color: "white",
                    borderRadius: "1.2rem",
                    backgroundColor: "rgba(255,255,255,0.1)",
                  }}
                >
                  <Grid item xs={5.5}>
                    <Typography lineHeight="2rem">
                      Mode:{" "}
                      <b style={{ color: "var(--orange)" }}>{state.mode}</b>
                    </Typography>
                    <Typography lineHeight="2rem">
                      Status:{" "}
                      <b style={{ color: "var(--orange)" }}>{state.status}</b>
                    </Typography>
                    <Typography lineHeight="2rem">
                      Sent:{" "}
                      <b style={{ color: "var(--orange)" }}>{state.sent}</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Divider
                      style={{ height: "100%", width: "3px" }}
                      orientation="vertical"
                      flexItem
                    />
                  </Grid>
                  <Grid item xs={5.5}>
                    <Typography lineHeight="2rem">
                      Automatically checked:{" "}
                      <b style={{ color: "var(--orange)" }}>
                        {state.autoChecked + " questions"}
                      </b>
                    </Typography>
                    <Typography lineHeight="2rem">
                      Manually checked:{" "}
                      <b style={{ color: "var(--orange)" }}>
                        {state.status + " questions"}
                      </b>
                    </Typography>
                    <Typography lineHeight="2rem">
                      Need to check:{" "}
                      <b style={{ color: "var(--orange)" }}>
                        {state.sent + " questions"}
                      </b>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </Container>
        </div>

        {/* footer start */}
        <FooterEducator logo={logo} />
        {/* footer end */}
      </div>

      {/* start modal my student */}

      {/* start game save step1 */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        // {...props}
        show={modalgamesavedstep1Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2 mystudent-games">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>The game is saved</h4>
              <h5>Send the game to my students</h5>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setgamesavedstep1ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="serch-inner-group">
                    <div className="serchfiled-activities">
                      <Form.Control
                        type="text"
                        placeholder="Start typing name or year of study"
                        className="formbox"
                      />
                      <Button type="button" className="seachbtn">
                        <i className="fas fa-search"></i>
                      </Button>
                    </div>
                  </div>

                  <div className="newpupil-list-bx">
                    <Form.Group className="subsform-fild child-tex-fld height-max-flow">
                      <div className="child-boxnew">
                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Aaradhya Rayasam</h5>
                                  <h6>India, Mumbai 1 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Chandrashekhar Kalvakuntla</h5>
                                  <h6>India, Mumbai 3 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>7F Class</h5>
                                  <h6>Hamut Kapit, Kemal Mara and 12 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Additional group</h5>
                                  <h6>Laska Doe, Hamut Kapit and 4 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>8A Class for exams</h5>
                                  <h6>Hamut Kapit, Laska Doe and 8 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Chandrashekhar Kalvakuntla</h5>
                                  <h6>India, Mumbai 3 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </Form.Group>

                <div className="student-btn-savedgame">
                  <div className="btnlogin-main">
                    <button type="submit" className="btnlogin btnweak-bx">
                      Send to selected students
                    </button>
                  </div>
                </div>
                <div className="btnlogin-main">
                  <button type="submit" className="btnlogin btnweak-bx">
                    Later
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end Add a Member Group step2 */}

      {/* end modal my student */}

      {/* start select studen popup */}

      <Selectstudent
        modalAddgroupstep2Show={modalAddgroupstep2Show}
        setAddgroupstep2ModalShow={(value) => setAddgroupstep2ModalShow(value)}
      />

      {/* end select studen popup */}
    </div>
  );
}

export default QuizLate;
