import React, { useState } from "react";
import "../../home.scss";
import "../../teachers.scss";
import "../../blog/blog.scss";
import "../../profile.scss";
import "../../loginModal/login.scss";
import "../../media.scss";
import "./studentProfile.scss";

import InfoIcon from "@mui/icons-material/Info";
import { Container, Row, Form, Modal, Table, Col } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import logo from "../../../assets/images/logo.png";
import placeholder from "../../../assets/images/placeholder.jpg";
import {
  StudentAPI,
  // updateStudentSyllabus,
} from "../../../service/apis/student";
import facebook from "../../../assets/images/facebook.svg";
import linkedin from "../../../assets/images/linkedin.svg";
import google from "../../../assets/images/google-plus.svg";
// import gift from "../../../assets/images/gift.svg";
// import plus from "../../../assets/images/plus.svg";
// import count1 from "../../../assets/images/count1.svg";
// import count2 from "../../../assets/images/count2.svg";
import flag from "../../../assets/images/flag.svg";

import profilepic from "../../../assets/images/profile-img.png";
import { Link, useHistory, useLocation } from "react-router-dom";
import { HeaderDashboard } from "../../layout/HeaderDashboard";
// import { LocalStorage } from "../../../service/LocalStorage";
import { DialogBox } from "../../DialogBox";
import { useFormik } from "formik";
// import { Box } from "@mui/system";
import { USER_DETAILS } from "../../../service/LocalStorage";

import {
  changePassword,
  getLanguageList,
  // getMilestones,
  // getPoints,
  getProfileDetails,
  getSyllabusesListByCountryId,
} from "../../../service/apis/common";
import { useAppState } from "../../../overmind";
import { useEffect } from "react";
import { fetchCities, fetchData } from "../../../service/AllApi";
import { CircularProgress } from "@mui/material";
import {
  // filterArray,
  filterCityId,
  filterCountryId,
  filterSyllabusId,
} from "../../../utils/filterArray";
// import { useMemo } from "react";
// import { useCallback } from "react";
import { Syllabus } from "../../dialogs/Syllabus";
// import { GiftsStepper } from "../../dialogs/GiftsStepper";
// import { PersonalRewardsGiftsStepper } from "../../dialogs/PersonalRewardGiftStepper";
import { MobileViewHeader } from "../../layout/MobileViewHeader";
// import Loading from "../../Loader";
// import { HeaderStudent } from "../../layout/Header/HeaderStudent";
import SignUpOptions from "../../SignUpOptions";

function StudentProfile() {
  const state = useAppState();
  const [modalShow, setModalShow] = useState(false);
  const [modalEducatorShow, setEducatorModalShow] = React.useState(false);
  const [modalParentShow, setParentModalShow] = React.useState(false);
  const [modalStudentShow, setStudentModalShow] = React.useState(false);

  const [modalSignupShow, setModalSignupShow] = useState(false);

  // let userDetail = state.userDetail;
  var user = localStorage.getItem(USER_DETAILS);
  var userInfo = JSON.parse(user);
  const history = useHistory();
  const location = useLocation();
  const [countries, setCountries] = useState([]);
  // const [stateId, setStateId] = useState(null);
  const [cities, setCities] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [userDetails, setUserDetails] = useState("");
  const [countryId, setCountryId] = useState();
  const [cityId, setCityId] = useState();
  const [isProfileLoading, setIsProfileLoading] = useState(false);
  const [setbackdrop, setsetbackdrop] = useState(false);
  const [syllabus, setSyllabus] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState(0);
  const [onPageLoad, setOnPageLoad] = useState(true);
  const [language, setLanguage] = useState("");
  const [countryChanged, setCountryChanged] = useState(false);
  const [languagename, setLanguagename] = useState("");
  const [languageUpdated, setLanguageUpdated] = useState(false);
  const [languageUpdateLoading, setLanguageUpdateLoading] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [syllabusId, setSyllabusId] = useState(
    userInfo?.applicationUser.syllabusId,
  );
  useEffect(() => {
    setSyllabusId(userInfo.applicationUser.syllabusId);
  }, [syllabusId]);
  const currentData = JSON.parse(localStorage.getItem("user"));

  // console.log(isProfileLoading)

  const [profileState, setprofileState] = useState({
    firstName:"",
    lastName: "",
    id: 0,
    email: "",
    phoneNumber: "",
    grade: 0,
    city: 0,
    country: 0,
    languageOfAccountId: 0,
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  // console.log(profileState.country)

  useEffect(() => {
    if (countryId != null) {
      // console.log("Syllabuses are calling");
      getSyllabusesData();
    }
  }, [countryId]);

  useEffect(() => {
    if (
      !userInfo?.applicationUser.syllabusId
      // userInfo.applicationUser.syllabusId === 0 ||
      // userInfo.applicationUser.syllabusId == null
    ) {
      setsetbackdrop(true);
    } else {
      setsetbackdrop(false);
    }
  }, [syllabusId]);
  useEffect(() => {
    fetchProfileDetails();
  }, [
    currentData?.applicationUser?.languageOfAccountId,
    profileState.languageOfAccountId,
  ]);

  useEffect(() => {
    if (userDetails != null) {
      fetchCountries();
    }
  }, [userDetails]);

  useEffect(() => {
    if (countryId != undefined && onPageLoad) {
      fetchCitiesOnLoad();
      setOnPageLoad(false);
    }

  }, [countryId]);

  const getSyllabusesData = async () => {
    // console.log("Syllabuses are fetching");

    const res = await getSyllabusesListByCountryId(countryId);
    const idArray = filterSyllabusId(res, syllabusId);

    idArray.map((i) => {
      setSyllabus(i.name);
    });
  };

  const fetchCitiesOnLoad = async () => {
    setIsProfileLoading(true);
    const res = await fetchCities("cities", countryId);
    setCities(res.data);
    
    const cityIdArray = filterCityId(res.data, userDetails?.city);
    // console.log(cityIdArray)
    if (cityId === undefined) {
      cityIdArray.map((c) => {
        setCityId(c.id);
      });
    }

    setIsProfileLoading(false);
  };

  const fetchProfileDetails = async () => {
    try {
      const res = await getProfileDetails();
      // console.log(res, "prof");
      setUserDetails(res);
      setprofileState({
        firstName: res.firstName,
        lastName: res.lastName,
        email: res.email,
        phoneNumber: res.phoneNumber,
        grade: res.grade,
        city: res.city,
        country: res.country,
        languageOfAccountId: res.languageOfAccountId,
      });
      // console.log(currentData.applicationUser, "c");
      currentData.applicationUser.languageOfAccountId = res.languageOfAccountId;
      localStorage.setItem("user", JSON.stringify(currentData));
    } catch (err) {}
  };

  // console.log("countryID" + countryId)

  const fetchCountries = async () => {
    try {
      const response = await fetchData("countries");
      if (response && response.data) {
        setCountries(response.data);

        const filteredCountry = filterCountryId(
          response.data,
          userDetails?.country,
        );

        

        if (countryId === undefined) {
          filteredCountry.map((f) => {
            setCountryId(f.id);
          });
        }
      }
    } catch (error) {}
  };

  const grades = (() => {
    const grades = [...Array(13).keys()];
    grades.splice(0, 1);

    return grades;
  })();
  useEffect(() => {
    // if (pro != null) {
    fetchLanguageData();
    setLanguageUpdated(true);

    // }
  }, [profileState.languageOfAccountId]);

  const fetchLanguageData = async () => {
    const response = await getLanguageList();

    setLanguages(response);
    // console.log(profileState);
    const s = response.filter((r) => r.id === profileState.languageOfAccountId);
    s.map((e) => {
      setLanguagename(e.language);
    });
  };

  const profileFormik = useFormik({
    enableReinitialize: true,
    initialValues: profileState,
  });

  const updateProfile = async () => {
    setisLoading(true);
    const v = profileFormik.values;

    const res = await StudentAPI.updateStudentProfile(
      v.firstName || userDetails?.firstName,
      v.lastName || userDetails?.lastName,
      parseInt(countryId),
      parseInt(cityId),
      1,
      parseInt(selectedLanguage) || parseInt(userDetails.languageOfAccountId),
      // || userDetails?.languageOfAccountId,
      userDetails?.id,
      parseInt(v.grade) || userDetails?.grade,
      parseInt(v.grade) || userDetails?.grade,
    );
    fetchProfileDetails();
    setisLoading(false);

  };
  const currentUrl = location.pathname;

  const updateLanguage = async () => {
    setLanguageUpdateLoading(true);
    const response = await getLanguageList();
    // console.log(response, "langauges header");
    setLanguage(response);
    // let code = "";

    // const s = response.filter((r) => r.id === parseInt(selectedLanguage));
    // console.log(s, "291");
    // s.map((e) => {
    //   if (e.language === "French") {
    //     code = "fr";
    //   } else if (e.language === "Arabic") {
    //     code = "ar";
    //   } else if (e.language === "Assamese") {
    //     code = "as";
    //   } else if (e.language === "Hindi") {
    //     code = "hi";
    //   } else if (e.language === "English") {
    //     code = "en";
    //   }
    // });

    // console.log(code, "code");
    // history.push(`${currentUrl}#googtrans(en|${code})}`);
    const v = profileFormik.values;

    try {
      const res = await StudentAPI.updateStudentProfile(
        v.firstName || userDetails?.firstName,
        v.lastName || userDetails?.lastName,
        parseInt(countryId),
        parseInt(cityId),
        1,
        parseInt(selectedLanguage) || parseInt(userDetails.languageOfAccountId),
        // || userDetails?.languageOfAccountId,
        userDetails?.id,
        parseInt(v.grade) || userDetails?.grade,
        parseInt(v.grade) || userDetails?.grade,
      );
      setLanguageUpdateLoading(false);
      fetchProfileDetails();
      history.go(0);
      // setisLoading(false);
    } catch (error) {}
  };

  const updatePassword = async (currentPassword, newPassword) => {
    setPasswordLoading(true);
    if (!currentPassword?.trim() || !newPassword?.trim()) return;
    try {
      await changePassword(currentPassword, newPassword);
      setPasswordLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const Security = () => {
    const securityFormik = useFormik({
      initialValues: {
        currentPassword: "",
        newPassword: "",
      },
    });
    return (
      <>
        <div className="tab-heading">Security</div>
        <div className="profile-form-section security-section">
          <form className="form-myprofile">
            <ul>
              <li>
                <label>My email is *</label>
                <Form.Group className="subsform-fild">
                  <Form.Control
                    disabled
                    readOnly
                    value={profileFormik.values.email}
                    type="email"
                    // placeholder="my_email@email.com"
                  />
                </Form.Group>
              </li>
              <li>
                <label>My mobile phone is *</label>
                <div className="phone-bar">
                  <div className="country-cd">
                    <img src={flag} className="img-fluid" alt=""/>
                  </div>
                  <div className="number-fld">
                    <Form.Group className="subsform-fild">
                      <Form.Control
                        disabled
                        value={profileFormik.values.phoneNumber}
                        type="text"
                        readOnly
                        // placeholder="+375 22 123 45 67"
                      />
                    </Form.Group>
                  </div>
                </div>
              </li>
              <div className="personal-info-headg">
                <h4>Change the password</h4>
              </div>

              <li>
                <label>Current password</label>
                <Form.Group className="subsform-fild">
                  <Form.Control
                    {...securityFormik.getFieldProps("currentPassword")}
                    type="password"
                    placeholder="Current password"
                  />
                </Form.Group>
              </li>
              <li>
                <label>New password</label>
                <Form.Group className="subsform-fild">
                  <Form.Control
                    type="password"
                    {...securityFormik.getFieldProps("newPassword")}
                    placeholder="New password"
                  />
                </Form.Group>
              </li>

              <li>
                <div className="btn-week">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={async () => {
                      await updatePassword(
                        securityFormik.values.currentPassword,
                        securityFormik.values.newPassword,
                      );
                      securityFormik.resetForm();
                    }}
                    className="btnweak-bx"
                  >
                    <span className="btn-title">
                      {passwordLoading ? (
                        <CircularProgress
                          size={"1.5rem"}
                          sx={{ color: "var(--purple)" }}
                        />
                      ) : (
                        "Save"
                      )}
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </form>
        </div>
      </>
    );
  };
  const Subscription = () => {
    const [dialogInfo, setdialogInfo] = useState({
      dialog: "",
      open: false,
    }); 
    const LiveLessons = () => {
      return (
        <div className="livelessons">
          <div className="tab-heading">Live lessons</div>

          <Container className="bg">
            <Row>
              <Col>
                <div>
                <p>There are no free lessons in your plan.</p>
                <p>
                  Buy additional lessons in order to have live lessons with
                  teachers
                </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <div>0 free lessons</div>
              </Col>
              <Col>
                <div>
                  <b>2</b>/5 <b>bought lessons left </b> till 3 Sep 2022
                  <InfoIcon />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Link to="/student/payment" className="buybtn btnweak-bx">
                  <div className="btn-price-space">
                    <div>Buy 1 lesson</div>
                    <div> $10</div>
                  </div>
                  {/* <span className="btn-title">Renew subscription $20</span> */}
                </Link>
              </Col>
              <Col>
                <a className="buybtn btnweak-bx" onClick={() => {}}>
                  <div className="btn-price-space">
                    <div>Buy 5 lesson</div>
                    <div> $40</div>
                  </div>
                </a>
              </Col>
              <Col>
                <a className="buybtn btnweak-bx" onClick={() => {}}>
                  <div className="btn-price-space">
                    <div>Buy 10 lesson</div>
                    <div> $80</div>
                  </div>
                </a>
              </Col>
            </Row>
          </Container>
        </div>
      );
    };
    return (
      <div className="subscription-tab">
        <DialogBox
          isOpen={dialogInfo.open && dialogInfo.dialog === "curator"}
          setclose={() => setdialogInfo((state) => ({ ...state, open: false }))}
        >
          <>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <div className="weekly-pic">
                  <img src={placeholder} alt="" />
                </div>
                <h4>Study with Your teachers</h4>
                <p>
                  Small description about the plan some words small description
                  about
                </p>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="login-modl-form">
                <div className="loginform-bx">
                  <Form.Group className="subsform-fild">
                    <Row>
                      <div className="">
                        <div className="dialog-points">
                          <p>
                            <span>&#10003; </span>
                            <span>All standart games for your year</span>
                          </p>

                          <p>
                            <span>&#10003; </span>
                            <span>Connection with parents </span>
                          </p>
                          <p>
                            <span>&#10003; </span>
                            <span>
                              Possibility to get tasks from any number of your
                              teachers or tutors{" "}
                            </span>
                          </p>
                          <p>
                            <span>X </span>
                            <span>Study with Tutor Neithedu </span>
                          </p>
                          <p>
                            <span>X </span>
                            <span>Free live lessons with educators</span>
                          </p>
                        </div>
                      </div>
                    </Row>
                  </Form.Group>

                  <div className="btnlogin-main">
                    <button
                      type="submit"
                      className="  btnweak-bx  dialog-buybtn"
                      
                    >
                      <span>Buy</span>
                      <del> $70</del>
                      <b>$50/month</b>
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </>
        </DialogBox>
        <DialogBox
          isOpen={dialogInfo.open && dialogInfo.dialog === "tutor"}
          setclose={() => setdialogInfo((state) => ({ ...state, open: false }))}
        >
          <>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <div className="weekly-pic">
                  <img src={placeholder} alt=""/>
                </div>
                <h4>Study with Neithedu Tutor</h4>
                <p>
                  Small description about the plan some words small description
                  about
                </p>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="login-modl-form">
                <div className="loginform-bx">
                  <Form.Group className="subsform-fild">
                    <Row>
                      <div className="">
                        <div className="dialog-points">
                          <p>
                            <span>&#10003; </span>
                            <span>All games for your</span>
                          </p>

                          <p>
                            <span>&#10003; </span>
                            <span>
                              Possibility to get tasks from any number of your
                              teachers or tutors{" "}
                            </span>
                          </p>
                          <p>
                            <span>&#10003; </span>
                            <span>Study with Tutor Neithedu </span>
                          </p>
                          <p>
                            <span>&#10003; </span>
                            <span>Connection with parents </span>
                          </p>
                          <p>
                            <span>X </span>
                            <span>
                              8 live lessons per a month with educators
                            </span>
                          </p>
                        </div>
                      </div>
                    </Row>
                  </Form.Group>

                  <div className="btnlogin-main">
                    <button
                      type="submit"
                      className="  btnweak-bx  dialog-buybtn"
                      // onClick={step3FetchData}
                    >
                      <span>Buy</span>
                      <b>$100/month</b>
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </>
        </DialogBox>
        <div className="myprofile-right">
          <div className="tab-heading">Subscription</div>
          <div className="Subscription-section">
            <div className="subscrip-plan">
              <div className="subs-cardpaln">
                <Row className="align-center">
                  <div className="col-lg-6 two">
                    <div className="curent-plan-left">
                      <h4>Your current plan is</h4>
                      <h3>Monthly</h3>
                    </div>
                    <div className="planactive">
                      <div className="lession-card-timebx">
                        <div className="lessontime-bottom">
                          <div className="lessonnew-cardbx">
                            <div className="newlesson-top">
                              <div className="bnewleson-heading">
                                Plan is active till 2 Sep, 11.36 pm
                              </div>
                            </div>
                            <div className="newlesson-clock">
                              <ul className="newlesson-listclk">
                                <li>
                                  <div className="clock-time">
                                    <div className="timenum">5</div>
                                    <div className="time-days">days</div>
                                  </div>
                                </li>
                                <li>
                                  <div className="clock-time">
                                    <div className="timenum">14</div>
                                    <div className="time-days">hours</div>
                                  </div>
                                </li>
                                <li>
                                  <div className="clock-time">
                                    <div className="timenum">37</div>
                                    <div className="time-days">minutes</div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div className="btn-list-lesson">
                              <ul className="btn-listing-lesson">
                                <li className="whitebtn-tx">
                                  <div className="btn-buy">
                                    <div className="btn-week">
                                      <a href="#" className="btnweak-bx">
                                        <span className="btn-title">
                                          Renew subscription $20
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div className="newlesson-shape"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 one">
                    <div className="subdesc">
                      <p>
                        Small description about the plan some words small
                        description about
                      </p>
                      <p>
                        <span>X </span>
                        All standart games for your year
                      </p>
                      <p>
                        <span>x </span>
                        All standart games for your year
                      </p>
                      <p>
                        <span>&#10003; </span>
                        All standart games for your year
                      </p>
                    </div>
                  </div>
                </Row>
              </div>
            </div>

            <div className="weekly-cardbx">
              <Row>
                <div className="col-lg-6 one">
                  <div className="Card-weekly">
                    <div className="student-weekly-cardiner">
                      <div className="quiz-body-content">
                        <div
                          onClick={() =>
                            setdialogInfo((state) => ({
                              ...state,
                              open: true,
                              dialog: "curator",
                            }))
                          }
                          className="quiz-bdy-content-bx"
                        >
                          <div className="weekly-pic">
                            <img src={placeholder} alt=""/>
                          </div>
                          <h3>Study with Curator</h3>

                          <p>
                            <div className="discountText">Discount -20%</div>
                            <div>valid until 2 Sep, 21</div>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 one">
                  <div className="Card-weekly">
                    <div className="student-weekly-cardiner">
                      <div className="quiz-body-content">
                        <div
                          onClick={() =>
                            setdialogInfo((state) => ({
                              ...state,
                              open: true,
                              dialog: "tutor",
                            }))
                          }
                          className="quiz-bdy-content-bx"
                        >
                          <div className="weekly-pic">
                            <img src={placeholder} alt=""/>
                          </div>
                          <h3>Study with Neithedu Tutor</h3>

                          <p>
                            Small description about the plan some words small
                            description about{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
            </div>
            <LiveLessons />
            <div className="table-main">
              <div className="table-history">
                <div className="tab-heading">History of payments</div>
                <div className="table-inermain">
                  <div className="tablebx-main">
                    <Table bordered hover responsive>
                      <tbody>
                        <tr>
                          <td>Plan Monthly</td>
                          <td className="date-frmt">22 Sep, 21</td>
                          <td className="date-frmt">11.56 pm</td>
                          <td>$20</td>
                          <td className="orange">Pending</td>
                        </tr>
                        <tr>
                          <td>Plan Monthly</td>
                          <td className="date-frmt">19 Aug, 21</td>
                          <td className="date-frmt">09.51 pm</td>
                          <td>$40</td>
                          <td className="sky">Complete</td>
                        </tr>
                        <tr>
                          <td>Plan Weekly</td>
                          <td className="date-frmt">29 May, 21</td>
                          <td className="date-frmt">11.34 pm</td>
                          <td>$20</td>
                          <td className="orange">Completeing</td>
                        </tr>
                        <tr>
                          <td>Plan Monthly</td>
                          <td className="date-frmt">25 Jan, 21</td>
                          <td className="date-frmt">10.47 am</td>
                          <td>$80</td>
                          <td className="purple">Refunded</td>
                        </tr>
                        <tr>
                          <td>Plan Weekly</td>
                          <td className="date-frmt">25 Jan, 21</td>
                          <td className="date-frmt">03.38 am</td>
                          <td>$80</td>
                          <td className="red">Failed</td>
                        </tr>
                        <tr>
                          <td>Plan Monthly</td>
                          <td className="date-frmt">09 Dec, 20</td>
                          <td className="date-frmt">06.02 pm</td>
                          <td>$20</td>
                          <td className="sky">Complete</td>
                        </tr>

                        <tr>
                          <td>Plan Monthly</td>
                          <td className="date-frmt">16 Nov, 20</td>
                          <td className="date-frmt">10.48 pm</td>
                          <td>$40</td>
                          <td className="sky">Complete</td>
                        </tr>
                        <tr>
                          <td>Plan Yearly</td>
                          <td className="date-frmt">27 Sep, 19</td>
                          <td className="date-frmt">11.59 pm</td>
                          <td>$20</td>
                          <td className="orange">Complete</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderDashboard
        setLanguageUpdated={setLanguageUpdated}
        languageUpdated={languageUpdated}
      />
      {/* <HeaderStudent /> */}
      {/* end header */}

      <div className="profilesection">
        {/* mobile view topbar filter */}
        <MobileViewHeader />

        {/* mobile view topbar filter end*/}
        <Container>
          <Tabs>
            <Row>
              <div className="col-lg-5">
                <div className="profile-tabs">
                  <div className="profileshape-bg"></div>
                  <div className="profile-pic">
                    <div className="profile-image">
                      <img src={profilepic} className="img-fluid" alt=""/>
                    </div>
                  </div>
                  <TabList>
                    <Tab>My profile</Tab>
                    <Tab>Subscription</Tab>
                    <Tab>Reports</Tab>
                    <Tab>Requests</Tab>
                    <Tab>Change the password</Tab>
                    <Tab>Notifications</Tab>
                    <Tab>Settings</Tab>
                  </TabList>
                </div>
              </div>
              <div className="col-lg-7">
                {/* {isProfileLoading ? (
                  <TabPanel>
                    <CircularProgress
                      size={"1.5rem"}
                      sx={{ color: "white", marginTop: "250px" }}
                    />
                  </TabPanel>
                ) : ( */}
                <TabPanel>
                  <div className="tab-heading">My profile</div>
                  <div className="profile-form-section">
                  <form>
                      <ul>
                        <li>
                          <label>First Name is *</label>
                          <Form.Group className="subsform-fild">
                            <Form.Control
                              type="text"
                              placeholder="First Name"
                              {...profileFormik.getFieldProps("firstName")}
                            />
                          </Form.Group>
                        </li>
                        <li>
                          <label>Last Name is *</label>
                          <Form.Group className="subsform-fild">
                            <Form.Control
                              type="text"
                              placeholder="Last Name"
                              {...profileFormik.getFieldProps("lastName")}
                            />
                          </Form.Group>
                        </li>
                        <li>
                          <label>My email is *</label>
                          <Form.Group className="subsform-fild">
                            <Form.Control
                              disabled
                              type="text"
                              placeholder="EMAIL"
                              {...profileFormik.getFieldProps("email")}
                              readOnly
                            />
                          </Form.Group>
                        </li>
                        <li>
                          <label>My Syllabus is *</label>
                          <Form.Group className="subsform-fild">
                            <Form.Control
                              disabled
                              type="text"
                              placeholder="syllabus"
                              value={syllabus}
                              readOnly
                            />
                          </Form.Group>
                        </li>
                        <li>
                          <label>My mobile phone is *</label>
                          <div className="phone-bar">
                            <div className="country-cd">
                              <img src={flag} className="img-fluid" alt=""/>
                            </div>
                            <div className="number-fld">
                              <Form.Group className="subsform-fild">
                                <Form.Control
                                  type="text"
                                  readOnly
                                  disabled
                                  placeholder="Phone Number"
                                  {...profileFormik.getFieldProps(
                                    "phoneNumber",
                                  )}
                                />
                              </Form.Group>
                            </div>
                          </div>
                        </li>
                        <li>
                          <label>My year of study *</label>
                          <Form.Control
                            as="select"
                            {...profileFormik.getFieldProps("grade")}
                          >
                            <option
                              value={profileState.grade}
                            >{`Grade ${profileState.grade}`}</option>
                            {grades.map((val, index) => {
                              return (
                                <option
                                  value={val}
                                  key={index}
                                >{`Grade ${val}`}</option>
                              );
                            })}
                          </Form.Control>
                        </li>
                        <li>
                          <label>I live in *</label>
                          <Row>
                            <div className="col-md-6">
                              <Form.Control
                                as="select"
                                onChange={(e) => {
                                  setCountryId(e.target.value);
                                  setCountryChanged(true);
                                  // setIsProfileLoading(true);
                                }}
                              >
                                <option value={countryId}>
                                  {profileState.country}
                                </option>

                                {countries
                                  .sort((a, b) => a?.name.localeCompare(b.name))
                                  .map((country, index) => {
                                    return (
                                      <option key={index} value={country?.id}>
                                        {country?.name}
                                      </option>
                                    );
                                  })}
                              </Form.Control>
                            </div>
                            <div className="col-md-6">
                              <Form.Control
                                as="select"
                                className="city-select"
                                onChange={(e) => {
                                  setCityId(e.target.value);
                                }}
                              >
                                {countryChanged ? null : (
                                  <option value={cityId}>
                                    {profileState.city}
                                  </option>
                                )}
                                {isProfileLoading ? (
                                  
                                  <option>
                                    Loading..
                                    
                                  </option>
                                ) : (
                                  cities.map((city, index) => {
                                    return (
                                      <option key={index} value={city?.id}>
                                        {city?.name}
                                      </option>
                                    );
                                  })
                                )}
                              </Form.Control>
                            </div>
                          </Row>
                        </li>
                        <li>
                          <div className="btn-week">
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => updateProfile()}
                              className="btnweak-bx"
                            >
                              <span className="btn-title">
                                {isLoading ? (
                                  <CircularProgress
                                    size={"1.5rem"}
                                    sx={{ color: "var(--purple)" }}
                                  />
                                ) : (
                                  "Save"
                                )}
                              </span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </form>
                  </div>
                </TabPanel>

                <TabPanel>
                  <Subscription />
                </TabPanel>
                <TabPanel>
                  <div className="tab-heading">Reports</div>
                </TabPanel>
                <TabPanel>
                  <div className="tab-heading">Requests</div>
                </TabPanel>
                <TabPanel>
                  <Security />
                </TabPanel>
                <TabPanel>
                  <div className="tab-heading">Notifications</div>
                </TabPanel>
                <TabPanel>
                  <div className="tab-heading">Settings</div>
                  <div className="profile-form-section">
                    <form>
                      <ul>
                        <li>
                          <label>Language of account</label>
                          <Form.Control
                            as="select"
                            onChange={(e) => {
                              setSelectedLanguage(e.target.value);
                            }}
                          >
                            {languagename ? (
                              <option value={userDetails?.languageOfAccountId}>
                                {languagename}
                              </option>
                            ) : (
                              ""
                            )}
                            {languages
                              .sort((a, b) =>
                                a?.language.localeCompare(b.language),
                              )
                              .map((l, index) => {
                                return (
                                  <option key={index} value={l?.id}>
                                    {l?.language} {"  "} {l?.localeName}
                                    {/* <span>{l?.language}</span> */}
                                    {/* <span>{l?.localeName}</span> */}
                                  </option>
                                );
                              })}
                          </Form.Control>
                        </li>
                        <li>
                          <label>Language of games</label>
                          <Form.Control as="select">
                            <option>English </option>
                          </Form.Control>
                        </li>
                        <li>
                          <label>Mother Language</label>
                          <Form.Control as="select">
                            <option>Urdu </option>
                          </Form.Control>
                        </li>
                        <li>
                          <div
                            onClick={() => updateLanguage()}
                            className="btn-week"
                          >
                            <div
                              style={{ cursor: "pointer" }}
                              className="btnweak-bx"
                            >
                              <span
                                style={{ cursor: "pointer" }}
                                className="btn-title"
                              >
                                {languageUpdateLoading ? (
                                  <CircularProgress
                                    size={"1.5rem"}
                                    sx={{ color: "var(--purple)" }}
                                  />
                                ) : (
                                  "   Save"
                                )}
                              </span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </form>
                  </div>
                </TabPanel>
              </div>
            </Row>
          </Tabs>
        </Container>
      </div>

      <div className="testimaonial-section subscribe-blog">
        <div className="subs-blog">
          <Container>
            <div className="subscribe-bxx">
              <div className="communty-box">
                <div className="communty-section">
                  <div className="heading-top">
                    <h4 className="title-medium">Linked social networks</h4>
                  </div>
                  <div className="communtiy-content">
                    <p className="small-tex">
                      Add your social media accounts. You will be able to log
                      into Neithedu using any of them.
                    </p>

                    <div className="social-media">
                      <ul>
                        <li>
                          <Link to="/studentProfile">
                            <img src={google} className="img-fluid" alt=""/>
                          </Link>
                        </li>
                        <li>
                          <Link to="/studentProfile">
                            <img src={facebook} className="img-fluid" alt=""/>
                          </Link>
                        </li>
                        <li>
                          <Link to="/studentProfile">
                            <img src={linkedin} className="img-fluid" alt=""/>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>

        <div className="footer-main">
          <div className="custome-container">
            <div className="footer-section">
              <div className="footershape-bg"></div>
              <Row>
                <div className="col-md-5 one">
                  <div className="foter-logo">
                    <Link to="/">
                      <img src={logo} className="img-fluid" alt=""/>
                    </Link>
                  </div>
                </div>
                <div className="col-md-7 two">
                  <div className="foter-list">
                    <div className="foter-listnew">
                      <ul>
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>
                          <Link to="/studentProfile">How it works</Link>
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <Link to="/studentProfile">Plans</Link>
                        </li>
                        <li>
                          <Link to="/studentProfile">Games</Link>
                        </li>
                      </ul>
                      <ul>
                        {/* <li>
                          <Link to="#">For parents</Link>
                        </li> */}
                        <li
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            console.log("hello");
                            setModalSignupShow(true);
                          }}
                        >
                          <a to="/studentProfile">For parents</a>
                        </li>
                        <li
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            console.log("hello");
                            setModalSignupShow(true);
                          }}
                        >
                          <a to="#">For teachers</a>
                        </li>
                        {modalSignupShow && (
                          <SignUpOptions
                            openLoginModal={() => setModalShow(true)}
                            modalSignupShow={modalSignupShow}
                            setEducatorModalShow={(value) =>
                              setEducatorModalShow(value)
                            }
                            setModalSignupShow={(value) =>
                              setModalSignupShow(value)
                            }
                            setParentModalShow={(value) =>
                              setParentModalShow(value)
                            }
                            setStudentModalShow={(value) =>
                              setStudentModalShow(value)
                            }
                          />
                        )}
                      </ul>
                      <ul>
                        <li>
                          <Link to="/blog">Blog</Link>
                        </li>
                        <li>
                          <Link to="/contact">Contacts</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Row>
            </div>

            <div className="foter-copywrite">
              <ul>
                <li>1999-2021 Neithedu.</li>
                <li>All Rights Reserved</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {setbackdrop && (
        <Syllabus setbackdrop={setbackdrop} setsetbackdrop={setsetbackdrop} />
      )}
      {/* <Button variant="primary" onClick={() => setModalShow(true)}>
       login
      </Button> */}
      <Modal
        className="loginmodal"
        // {...props}
        show={modalShow}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Log in</h4>
              <p>Please, enter your email and password</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <Form className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" />
                </Form.Group>

                <Form.Group className="subsform-fild">
                  <Form.Label className="forg-texfield">
                    <span className="label-bx">Password</span>
                    <span className="forget-tex">
                      <Link to="/studentProfile" className="title-med">
                        Forgot the password?
                      </Link>
                    </span>
                  </Form.Label>
                  <Form.Control type="password" />
                </Form.Group>

                <div className="have-account">
                  <Link to="/studentProfile" className="title-med">
                    I don’t have account yet
                  </Link>
                </div>

                <div className="btnlogin-main">
                  <button type="submit" className="btnlogin btnweak-bx">
                    Login in
                  </button>
                </div>
              </Form>
            </div>
          </Modal.Body>
        </div>

        <div className="socila-mediabx">
          <div className="social-media-detail">
            <h5>Or log in by social media</h5>
            <div className="social-media">
              <ul>
                <li>
                  <a>
                    <img src={google} className="img-fluid" alt=""/>
                  </a>
                </li>
                <li>
                  <a>
                    <img src={facebook} className="img-fluid" alt=""/>
                  </a>
                </li>
                <li>
                  <a>
                    <img src={linkedin} className="img-fluid" alt=""/>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default StudentProfile;
