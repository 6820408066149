import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import "./home.scss";
import "./profile.scss";
import "./media.scss";
import "./games.scss";
import "./loginModal/login.scss";
import "./messages/messages.scss";
import "./myactivities.scss";
import "./gamestabs.scss";
import "./gamesflowphotos.scss";
import "./selectedgames.scss";
import "./gamequiz.scss";
import { Container, Row, Form } from "react-bootstrap";
import logo from "../assets/images/logo.png";
import backorange from "../assets/images/backorange.svg";
import { Link } from "react-router-dom";
import FooterEducator from "./layout/Footer/FooterEducator";
import { useEffect } from "react";
import { getSingalActivities } from "../service/apis/activities";
import { sendAnswer } from "../service/apis/tasks";
import { HeaderDashboard } from "./layout/HeaderDashboard";
import PersonIcon from "@mui/icons-material/Person";
import SuccessModal from "./dialogs/SuccessModal";
import { Box, Grid, Stack, Typography } from "@mui/material";

function PersonalTaskAnswerGrop() {
  const params = useParams();
  const [task, setTask] = useState({});
  const [taskId, setTaskId] = useState();
  const [answer, setAnswer] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [personActiviteId, setPersonActiviteId] = useState([]);
  const { activityTypeId, activityId, gameflowGameId } = params;
  const currentUser = JSON.parse(localStorage.getItem("user"));

  /* end select student */

  const TastStatus = {
    0: "Testing",
    1: "New",
    2: "Revision",
    3: "Done",
    4: "Late",
  };

  const getDingal = async () => {
    // : `/PersonalTaskAnswergroup/${item.assignedToId}/${item.activityToBePerformedId}/${item.activityTypeId}`;
    const response = await getSingalActivities(activityTypeId, activityId);
    //  const response = await getSingalActivitiesGroup(acId, per, typId);
    console.log("response", response);
    setTask(response);
    setTaskId(response.taskId);
  };

  useEffect(() => {
    getDingal();
  }, [activityTypeId, activityId, gameflowGameId]);

  useEffect(() => {
    const data = task?.taskGroupDetails?.filter(
      (item) => item.studentId === currentUser.applicationUser.id,
    );
    setPersonActiviteId(data);
  }, [currentUser.applicationUser.id, task]);

  console.log(task);

  const sendAnswerd = async () => {
    if (answer.length === 0) return alert("Please Provide Your Answer");
    const response = await sendAnswer(answer, personActiviteId[0]?.activityId);
    console.log(response);
    if (response) {
      getDingal();
      setShowModal(true);
      setAnswer("");
    }
  };

  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderDashboard />

      {/* end header */}

      <div className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs">
        <div className="games-photosynths gamequiz-main perosnoal-task">
          <Container>
            <div className="quiz-game-popup">
              <div className="current-lesson-main ">
                <div className="current-lesson-contain">
                  <span className="back-btndrop">
                    <Link to="/MyActivities" className="backbtn-lg">
                      <img src={backorange} />
                    </Link>
                  </span>
                  <div className="right-iconbox-quizz">
                    <ul>
                      {/* <li>
                        <Link
                          to="#"
                          className="editbtn-lgnew"
                          onClick={() => setAddgroupstep2ModalShow(true)}
                        >
                          <img src={darkorangeplan} />
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="editbtn-lgnew"
                        onClick={() => setAddgroupstep3ModalShow(true)}
                        >
                          <img src={editorangelg} />
                        </Link>
                      </li>*/}
                    </ul>
                  </div>

                  <div className="current-lesson-topbr">
                    <div className="medm-tex orng-clr">
                      {task?.activityName}
                    </div>
                    <h1>{task?.taskTitle}</h1>
                    <div className="lesson-status-list quiz-gamelession">
                      <ul className="lesson-listing-curnt">
                        <li>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Mode:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              Active{" "}
                            </span>
                          </div>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Status:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              {task.status}
                              {personActiviteId &&
                                personActiviteId[0]?.taskStatus}
                            </span>
                          </div>
                        </li>
                        <li>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Deadline:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              {Date(task.deadline).slice(4, 16)}
                            </span>
                          </div>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Created:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              Aug 16 2021
                            </span>
                          </div>
                        </li>
                        <li>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Points:{" "}
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              {task.points} if completed
                            </span>
                          </div>
                          <div className="lesson-status-detail">
                            <span className="medm-tex orng-clr onelist">
                              Earned points:
                            </span>
                            <span className="medm-tex orng-clr twolist">
                              {task.earnedPoints}
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="personal-tsk-main">
                <div className="persnl-taskiner">
                  <div className="task-content">
                    <p>
                      <strong>Description: </strong>
                      {task.taskDescription}
                    </p>
                  </div>

                  {task.comment && (
                    <div className="task-content">
                      <p>
                        <strong>Revision: </strong>
                        {task.comment}
                      </p>
                    </div>
                  )}

                  <div className="task-content">
                    <p>
                      <strong>Answer: </strong>
                      {task.answer === null ? "Not Answer" : task.answer}
                    </p>
                  </div>

                  {personActiviteId && (
                    <div className="number-points-frm">
                      <div className="points-iner-nw">
                        <h5>Provide Your Answer</h5>
                        <Row>
                          <div className="col-6 col-md-9 one">
                            <div className="points-num-bx">
                              <Form.Group className="subsform-fild">
                                <Form.Control
                                  as="textarea"
                                  style={{
                                    height: "170px",
                                    paddingTop: "20px",
                                  }}
                                  value={answer}
                                  onChange={(e) => setAnswer(e.target.value)}
                                  placeholder="Provide Your Answer :"
                                />
                              </Form.Group>
                            </div>
                          </div>
                          <div className="col-6 col-md-3 two">
                            <div className="save-points">
                              <div className="btn-week">
                                <Link
                                  to="#"
                                  onClick={() => sendAnswerd()}
                                  className="btnweak-bx"
                                >
                                  <span className="btn-title">Save</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </Row>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <Box
                sx={{
                  backgroundColor: "white",
                  mt: "5rem",
                  p: "1rem",
                  borderRadius: "1.2rem",
                }}
              >
                {task?.taskGroupDetails?.map((stud, idx) => {
                  return (
                    <div key={idx}>
                      <Grid
                        container
                        columnSpacing={"1rem"}
                        key={idx}
                        alignItems="center"
                      >
                        <Grid
                          item
                          xs={4}
                          sx={{
                            backgroundColor: "#EDEDFF",
                            borderRadius: "1rem",
                            m: "0.4rem",
                            p: "0.4rem",
                          }}
                        >
                          <Stack
                            alignItems="center"
                            flexDirection="row"
                            columnGap={"1rem"}
                            ml="2rem"
                          >
                            <PersonIcon
                              sx={{
                                borderRadius: "50%",
                                color: "white",
                                backgroundColor: "var(--purple)",
                                p: "0.5rem",
                                fontSize: "2.5rem",
                              }}
                            />
                            <Typography>{stud.studentName}</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={2} justifySelf="center">
                          <Box
                            sx={{
                              fontWeight: "700",
                              maxWidth: "9rem",
                              color: "white",
                              backgroundColor: "var(--orange)",
                              borderRadius: "0.7rem",
                              p: "0.1rem",
                              fontSize: "0.9rem",
                            }}
                          >
                            {stud.taskStatus}
                          </Box>
                        </Grid>
                        <Grid item xs={5}>
                          <Typography textAlign="start">
                            Deadline missed {stud.deadline}
                          </Typography>
                        </Grid>
                      </Grid>
                      {/* {idx !== students.length - 1 && <Divider />} */}
                    </div>
                  );
                })}
              </Box>
            </div>
          </Container>
        </div>

        {/* footer start */}
        <FooterEducator logo={logo} />
        {/* footer end */}
      </div>

      {/* start modal my student */}
      {showModal && (
        <SuccessModal modalTitle={"Task Answer Send"} onClose={setShowModal} />
      )}
      {/* start game save step1 */}

      {/* end Add a Member Group step2 */}

      {/* end modal my student */}

      {/* start select studen popup */}

      {/* end select studen popup */}
    </div>
  );
}

export default PersonalTaskAnswerGrop;
