import { Box, Grid, Slider, styled, Typography } from "@mui/material";
import { CloseButton, StyledYellowButton } from "../../utils/Buttons";

import questionnaireHomeImage from "../../../assets/images/questionnaireHome.png";
import { RearrangeAnswers } from "./RearrangeAnswers";
import { useState } from "react";

const HomeImage = styled("img")({
  borderRadius: "0px 0px 0px 20px",
  width: "100%",
  height: "100%",
  objectFit: "contain",
});

export const Home = ({
  changeScreen,
  closeQuestionnaire,
  changeDialog,
  dialogs,
}) => {
  const [answersList, setanswersList] = useState([
    "Some seemingly insignificant statement",
    "Oh, this is really doesn’t appear for me!",
    "The sentence is too lorem ipsum",
    "The sentence is too lorem ipsum",
  ]);
  return (
    <Grid
      container
      sx={{
        position: "relative",
        backgroundColor: "var(--white)",
        borderRadius: "10px",
        maxWidth: "60rem",
        m: "3rem auto 10rem auto",
        p: "1rem",
      }}
    >
      <CloseButton closeHandler={closeQuestionnaire} />
      <Grid item md={6} xs={12}>
        <HomeImage src={questionnaireHomeImage} alt="studying" />
      </Grid>
      <Grid sx={{ position: "relative", p: "1.5rem" }} item md={6} xs={12}>
        <Typography
          mt="1rem"
          mb="0.5rem"
          sx={{ fontSize: "1.6rem", fontWeight: "800" }}
          color="var(--purple-dark)"
        >
          How to choose correct answer
        </Typography>
        <Typography
          mb={"0.5rem"}
          sx={{ color: "var(--black)", fontWeight: "500" }}
          fontSize="0.8rem"
          variant="body"
        >
          First read all four sentences/statements and choose (clock on) one of
          those that you see to be the closest. After thatYou see the question
          bellow you want to choose that you it suit you 100% you should push
          slider to the right. You will find a series of questions that might be
          used To describe your learning style. Presented in each item a bipolar
          and next text with explanation of the rule
        </Typography>
        <Typography
          my={"0.5rem"}
          variant="h6"
          fontWeight={600}
          color="var(--purple-dark)"
        >
          Let's start!
        </Typography>
        <Typography
          fontSize="0.8rem"
          sx={{ color: "var(--black)", fontWeight: "500" }}
          variant="body"
        >
          You see the question bellow you want to choose that you it suit you
          100% and what is not so close{" "}
        </Typography>

        <RearrangeAnswers
          answersList={answersList}
          dragEndHandler={(source, desti) => {
            const newans = [...answersList];
            const temp = newans[source];
            newans[source] = newans[desti];
            newans[desti] = temp;
            setanswersList(newans);
          }}
        />
        <Box
          sx={{
            fontSize: "0.9rem",
            padding: "0.8rem",
            position: "absolute",
            bottom: "-3rem",
            left: "15%",
          }}
        >
          <StyledYellowButton
            title={" Confirm the answer and continue!"}
            btnHandler={changeScreen}
            // btnHandler={() => changeDialog(dialogs.GO_TO_QUESTIONNAIRE_DIALOG)}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
