import { useContext, useEffect, useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import "./loginModal/login.scss";
import {
  addEducatorGroup,
  checkGroupExist,
  getEducatorStudents,
} from "../service/apis/educator";
import { CustomSnackbar, useSnackState } from "../utils/useSnackState";
import { NotificationContext } from "../contexts/Notification";
// import { Alert, Snackbar } from "@mui/material";

const Addgroup = ({
  refetchStudentsAndGroups,
  setAddgroupstep1ModalShow,
  modalAddgroupstep1Show,
  refetchGroupsDetails,
}) => {
  const [snackProps, snackOpen] = useSnackState();
  /* start Add a Group step2 */
  const [modalAddgroupstep2Show, setAddgroupstep2ModalShow] = useState(false);
  const { showNotification } = useContext(NotificationContext);
  /* end Add a Group step2 */
  const [state, setstate] = useState({
    students: [],
    selectedStudents: [],
    groupName: "",
    addMembersSearchQuery: "",
  });

  const fetchStudents = async () => {
    const res = await getEducatorStudents();
    setstate((state) => ({ ...state, students: res }));
  };
  const history = useHistory();
  const addGroups = async () => {
    try {
      const res = await addEducatorGroup(
        state.groupName,
        state.selectedStudents,
      );
      await refetchGroupsDetails();
      await refetchStudentsAndGroups();
      console.log("res", res);

      // close select student modal
      setAddgroupstep2ModalShow(false);

      showNotification("Group created successfully");
      setTimeout(() => {
        history.push(`educator?studentId=${res}`);
        setAddgroupstep2ModalShow(false);
      }, 2000);
    } catch (err) {
      snackOpen(err.response.message, "error");
    }
  };

  const selectStudent = (studentId) => {
    setstate((state) => {
      let selectedStudents;
      if (state.selectedStudents.findIndex((sId) => sId === studentId) === -1) {
        selectedStudents = [...state.selectedStudents, studentId];
      } else
        selectedStudents = state.selectedStudents.filter(
          (sId) => sId !== studentId,
        );

      return { ...state, selectedStudents };
    });
  };

  useEffect(() => {
    fetchStudents();
    return () => {
      // reset state
      setstate({
        selectedStudents: [],
        groupName: "",
        addMembersSearchQuery: "",
      });
    };
  }, []);

  return (
    <div className="add-group-main">
      {/* start Add a Group step1 */}
      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal1"
        // {...props}
        show={modalAddgroupstep1Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Add a new group</h4>
            </Modal.Title>
            <button
              type="button"
              onClick={() => {
                setstate((prevState) => ({
                  ...prevState,
                  selectedStudents: [],
                  groupName: "",
                  addMembersSearchQuery: "",
                }));
                setAddgroupstep1ModalShow(false);
              }}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
            <CustomSnackbar {...snackProps} />
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="upload-profilebx">
                    <div className="file-upnew">
                      <Form.Control
                        type="file"
                        className="brower-btn"
                        id="input-file"
                      />
                      <label for="input-file" className="profile-uplod-btn">
                        <i className="fas fa-camera"></i>
                      </label>
                    </div>
                  </div>
                  <Form.Label>Group name</Form.Label>
                  <div className="livein-field">
                    <Form.Control
                      value={state.groupName}
                      onChange={(e) =>
                        setstate((state) => ({
                          ...state,
                          groupName: e.target.value,
                        }))
                      }
                      type="text"
                      placeholder="Group Name"
                    />
                  </div>
                </Form.Group>

                <div className="btnlogin-main">
                  <button
                    type="submit"
                    style={{
                      backgroundColor:
                        state?.groupName?.trim()?.length < 3 &&
                        "var(--grey-light)",
                      color: state.groupName.trim()?.length < 3 && "white",
                    }}
                    className="btnlogin btnweak-bx"
                    onClick={async () => {
                      if (state?.groupName.trim()?.length < 3) {
                        snackOpen(
                          "Group name should be atleast 3 characters",
                          "error",
                        );
                        return;
                      }

                      if (await checkGroupExist(state.groupName)) {
                        snackOpen("Group already exists", "error");
                        return;
                      }
                      setAddgroupstep2ModalShow(true);
                      setstate((prevState) => ({
                        ...prevState,
                        selectedStudents: [],
                        groupName: state.groupName,
                        addMembersSearchQuery: "",
                      }));
                      setAddgroupstep1ModalShow(false);
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
        <div className="socila-mediabx">
          <div className="count-barr addgroup-count-step1">
            <div className="counting-barful">
              <div className="linebar-tex">1/2</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>

      {/* end Add a Group step1 */}

      {/* start Add a Group step2 */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        // {...props}
        show={modalAddgroupstep2Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx">
          <Modal.Header>
            <div className="btnback">
              <Link
                to="#"
                onClick={() => {
                  setAddgroupstep2ModalShow(false);
                  setAddgroupstep1ModalShow(true);
                  setstate((prevState) => ({
                    ...prevState,
                    selectedStudents: [],
                    groupName: "",
                    addMembersSearchQuery: "",
                  }));
                }}
                className="backbtn-icn"
              >
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Add members to the group</h4>
              <p>
                If you need to add a new pupil to this group, please add the
                pupil to My pupil list at first
              </p>
              <h5>
                <span className="num-pupil-active">
                  {state.selectedStudents?.length}
                </span>
                <span className="total-pupil-num">
                  / {state.students?.length} pupils
                </span>
              </h5>
            </Modal.Title>
            <button
              type="button"
              onClick={() => {
                setstate((prevState) => ({
                  ...prevState,
                  selectedStudents: [],
                  groupName: "",
                  addMembersSearchQuery: "",
                }));
                setAddgroupstep2ModalShow(false);
              }}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
            <CustomSnackbar {...snackProps} />
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="serch-inner-group">
                    <div className="serchfiled-activities">
                      <Form.Control
                        type="text"
                        value={state.addMembersSearchQuery}
                        onChange={(e) =>
                          setstate((prevState) => ({
                            ...prevState,
                            addMembersSearchQuery: e.target.value,
                          }))
                        }
                        placeholder="Start typing name or year of study"
                        className="formbox"
                      />
                      <Button type="button" className="seachbtn">
                        <i className="fas fa-search"></i>
                      </Button>
                    </div>
                  </div>

                  <div className="newpupil-list-bx">
                    <Form.Group className="subsform-fild child-tex-fld height-max-flow">
                      <div className="child-boxnew">
                        {state?.students &&
                          state?.students
                            .filter((stud) => {
                              if (
                                stud.name
                                  .toLowerCase()
                                  .includes(
                                    state.addMembersSearchQuery.toLowerCase(),
                                  )
                              )
                                return true;
                              return false;
                            })
                            .map((student) => {
                              const isSelected =
                                state.selectedStudents.findIndex(
                                  (stud) => stud === student.studentId,
                                ) !== -1;
                              return (
                                <div
                                  key={student.studentId}
                                  className="child-account"
                                >
                                  <div className="activ-detail-list">
                                    <label className="custm-check">
                                      <Form.Control
                                        checked={isSelected}
                                        type="checkbox"
                                        onClick={() =>
                                          selectStudent(student.studentId)
                                        }
                                      />
                                      <span className="checkmark"></span>
                                      <div className="active-detail-row">
                                        <div className="activiti-prof-icon">
                                          <span className="profile-icon-box">
                                            <i className="fas fa-user"></i>
                                          </span>
                                        </div>
                                        <div className="activiti-center-detail">
                                          <h5>{student.name}</h5>
                                          <h6>{student.location}</h6>
                                        </div>
                                      </div>
                                    </label>
                                  </div>
                                </div>
                              );
                            })}
                      </div>
                    </Form.Group>
                  </div>
                </Form.Group>

                <div className="btnlogin-main">
                  <button
                    onClick={() => {
                      if (state?.selectedStudents?.length <= 1) {
                        snackOpen("Select atleast 2 members", "error");
                      } else addGroups();
                    }}
                    style={{
                      backgroundColor:
                        !state.selectedStudents?.length > 1 &&
                        "var(--grey-light)",
                      color: !state.selectedStudents?.length > 1 && "white",
                    }}
                    type="submit"
                    className="btnlogin btnweak-bx"
                  >
                    Add selected students
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
        <div className="socila-mediabx">
          <div className="count-barr addgroup-count-step2">
            <div className="counting-barful">
              <div className="linebar-tex">2/2</div>
              <div className="linebar-box"></div>
            </div>
          </div>
        </div>
      </Modal>

      {/* end Add a Group step2 */}

      {/* End Add a group modal */}
    </div>
  );
};

export default Addgroup;
