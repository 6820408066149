import React from "react";
import "./home.scss";
import "./profile.scss";
import "./media.scss";
import "./games.scss";
import "./loginModal/login.scss";
import "./profile.scss";
import "./messages/messages.scss";
import "./myactivities.scss";
import "./gamestabs.scss";
import "./gamesflowphotos.scss";
import "./selectedgames.scss";
import "./Myprofile.scss";

import {
  // Navbar,
  // Nav,
  // NavDropdown,
  Container,
  // Dropdown,
  // br,
  Row,
  // Accordion,
  // Card,
  Button,
  Form,
  Modal,
  // Select,
  // Sonnet,
} from "react-bootstrap";
import { Tabs} from "react-tabs";

import logo from "../assets/images/logo.png";
// import gamesgalery1 from "../assets/images/games-galery1.jpg";
// import gamesgalery2 from "../assets/images/games-galery2.jpg";
// import chaticon from "../assets/images/chat-icon.svg";
// import batchorange from "../assets/images/batch-orange.svg";
// import palinblue from "../assets/images/palin-blue.svg";
// import mike from "../assets/images/mike.svg";
// import attachemtnblue from "../assets/images/attachemtn-blue.svg";
// import downarrowblue from "../assets/images/down-arrow-blue.svg";
// import dotthree from "../assets/images/dotthree.svg";
// import addbtnround from "../assets/images/add-btn.svg";
// import darkblueplan from "../assets/images/darkblue-plan.svg";
// import backorange from "../assets/images/backorange.svg";
// import editorangelg from "../assets/images/editlg-orange.svg";
// import flag from "../assets/images/flag.svg";
import profilepic from "../assets/images/profile-img.png";
// import facebook from "../assets/images/facebook.svg";
// import linkedin from "../assets/images/linkedin.svg";
// import google from "../assets/images/google-plus.svg";

// import SliderCenter from "./Slider";
import { Link } from "react-router-dom";
import FooterEducator from "./layout/Footer/FooterEducator";
// import Addgroup from "./Addgroup";
// import Addpupil from "./Addpupil";
// import SendInvite from "./SendInvite";
// import Groupmember from "./Groupmember";
import HeaderGames from "./layout/Header/HeaderGames";
import Becometutornew from "./tutor/Becometutornew";
import Myprofilesidebar from "./Myprofilesidebar";

function Notifications() {
  // const [show, setShow] = React.useState("1");

  // const [breadMenuShow, setBreadMenuShow] = React.useState(false);
  /* start modal */
  const [modalShow, setModalShow] = React.useState(false);
  /* end modal */

  /* start becometutor */
  const [modalEducatorstep1Show, setEducatorstep1ModalShow] =
    React.useState(false);
  /* end becometutor */

  /* startsave gamed step1 */
  const [modalgamesavedstep1Show, setgamesavedstep1ModalShow] =
    React.useState(false);

  /* end save gamned step1 */

  /* start Tutor signup step6 */
  // const [modalTutorstep7Show, setTutorstep7ModalShow] = React.useState(false);
  /* end Tutor signup step6 */
  /* start Tutor signup step2 */
  // const [modalTutorstep2Show, setTutorstep2ModalShow] = React.useState(false);
  /* end Tutor signup step2 */

  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderGames setModalShow={(value) => setModalShow(value)} />
      {/* end header */}

      <div className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs">
        <div className="myprofile-main">
          <div className="profilesection">
            <Container>
              <div className="profilemain-card">
                <Tabs>
                  <Row>
                    <div className="col-lg-5">
                      <div className="profile-tabs">
                        <div className="profileshape-bg"></div>
                        <div className="profile-pic">
                          <div className="profile-image">
                            {" "}
                            <img src={profilepic} className="img-fluid" />{" "}
                            <div className="file-upnew">
                              <Form.Control
                                type="file"
                                className="brower-btn"
                                id="input-file"
                              />
                              <label
                                htmlFor="input-file"
                                className="profile-uplod-btn"
                              >
                                <i className="fas fa-camera"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="profile-lftsidee">
                          {/* start my profile sidebar */}
                          <Myprofilesidebar />
                          {/* end  my profile sidebar */}

                          <div className="profile-footer">
                            <button
                              type="submit"
                              className="btnlogin btnweak-bx"
                              onClick={() => {
                                setEducatorstep1ModalShow(true);
                              }}
                            >
                              Became a tutor
                            </button>
                            <Becometutornew
                              modalEducatorstep1Show={modalEducatorstep1Show}
                              setEducatorstep1ModalShow={(value) =>
                                setEducatorstep1ModalShow(value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="myprofile-right">
                        <div className="tab-heading">Notifications</div>
                        <div className="Notifications-section">
                          <form className="form-myprofile">
                            <div className="notif-switch">
                              <ul className="switch-listt">
                                <li className="listswitch-li">
                                  <div className="switchbox">
                                    <span className="title-switch bold">
                                      New personal messages
                                    </span>
                                    <label className="switch" htmlFor="checkbox1">
                                      <input type="checkbox" id="checkbox1" />
                                      <div className="slider round"></div>
                                    </label>
                                  </div>
                                </li>

                                <li className="listswitch-li">
                                  <div className="switchbox">
                                    <span className="title-switch bold">
                                      Reminder about live lessons
                                    </span>
                                    <label className="switch" htmlFor="checkbox2">
                                      <input type="checkbox" id="checkbox2" />
                                      <div className="slider round"></div>
                                    </label>
                                  </div>

                                  <ul className="iner-switchlist">
                                    <li className="inner-listswitch">
                                      <div className="switchbox">
                                        <span className="title-switch">
                                          In a day
                                        </span>
                                        <label
                                          className="switch"
                                          htmlFor="checkbox3"
                                        >
                                          <input
                                            type="checkbox"
                                            id="checkbox3"
                                          />
                                          <div className="slider round"></div>
                                        </label>
                                      </div>
                                    </li>
                                    <li className="inner-listswitch">
                                      <div className="switchbox">
                                        <span className="title-switch">
                                          In an hour
                                        </span>
                                        <label
                                          className="switch"
                                          htmlFor="checkbox4"
                                        >
                                          <input
                                            type="checkbox"
                                            id="checkbox4"
                                          />
                                          <div className="slider round"></div>
                                        </label>
                                      </div>
                                    </li>

                                    <li className="inner-listswitch">
                                      <div className="switchbox">
                                        <span className="title-switch">
                                          In 15 minutes
                                        </span>
                                        <label
                                          className="switch"
                                          htmlFor="checkbox5"
                                        >
                                          <input
                                            type="checkbox"
                                            id="checkbox5"
                                          />
                                          <div className="slider round"></div>
                                        </label>
                                      </div>
                                    </li>
                                  </ul>
                                </li>

                                <li className="listswitch-li">
                                  <div className="switchbox">
                                    <span className="title-switch bold">
                                      Payments
                                    </span>
                                    <label className="switch" htmlFor="checkbox6">
                                      <input type="checkbox" id="checkbox6" />
                                      <div className="slider round"></div>
                                    </label>
                                  </div>
                                </li>

                                <li className="listswitch-li">
                                  <div className="switchbox">
                                    <span className="title-switch bold">
                                      Changes in live lesson
                                    </span>
                                    <label className="switch" htmlFor="checkbox7">
                                      <input type="checkbox" id="checkbox7" />
                                      <div className="slider round"></div>
                                    </label>
                                  </div>

                                  <ul className="iner-switchlist">
                                    <li className="inner-listswitch">
                                      <div className="switchbox">
                                        <span className="title-switch">
                                          Student canceled the lesson
                                        </span>
                                        <label
                                          className="switch"
                                          htmlFor="checkbox8"
                                        >
                                          <input
                                            type="checkbox"
                                            id="checkbox8"
                                          />
                                          <div className="slider round"></div>
                                        </label>
                                      </div>
                                    </li>
                                    <li className="inner-listswitch">
                                      <div className="switchbox">
                                        <span className="title-switch">
                                          Student confirm changes in the lesson
                                        </span>
                                        <label
                                          className="switch"
                                          htmlFor="checkbox9"
                                        >
                                          <input
                                            type="checkbox"
                                            id="checkbox9"
                                          />
                                          <div className="slider round"></div>
                                        </label>
                                      </div>
                                    </li>

                                    <li className="inner-listswitch">
                                      <div className="switchbox">
                                        <span className="title-switch">
                                          Student confrim new lesson
                                        </span>
                                        <label
                                          className="switch"
                                          htmlFor="checkbox10"
                                        >
                                          <input
                                            type="checkbox"
                                            id="checkbox10"
                                          />
                                          <div className="slider round"></div>
                                        </label>
                                      </div>
                                    </li>
                                  </ul>
                                </li>

                                <li className="listswitch-li">
                                  <div className="switchbox">
                                    <span className="title-switch bold">
                                      News in activities
                                    </span>
                                    <label className="switch" htmlFor="checkbox11">
                                      <input type="checkbox" id="checkbox11" />
                                      <div className="slider round"></div>
                                    </label>
                                  </div>

                                  <ul className="iner-switchlist">
                                    <li className="inner-listswitch">
                                      <div className="switchbox">
                                        <span className="title-switch">
                                          Student’s done the task
                                        </span>
                                        <label
                                          className="switch"
                                          htmlFor="checkbox12"
                                        >
                                          <input
                                            type="checkbox"
                                            id="checkbox12"
                                          />
                                          <div className="slider round"></div>
                                        </label>
                                      </div>
                                    </li>
                                    <li className="inner-listswitch">
                                      <div className="switchbox">
                                        <span className="title-switch">
                                          Student read the material
                                        </span>
                                        <label
                                          className="switch"
                                          htmlFor="checkbox13"
                                        >
                                          <input
                                            type="checkbox"
                                            id="checkbox13"
                                          />
                                          <div className="slider round"></div>
                                        </label>
                                      </div>
                                    </li>

                                    <li className="inner-listswitch">
                                      <div className="switchbox">
                                        <span className="title-switch">
                                          Missed deadline
                                        </span>
                                        <label
                                          className="switch"
                                          htmlFor="checkbox14"
                                        >
                                          <input
                                            type="checkbox"
                                            id="checkbox14"
                                          />
                                          <div className="slider round"></div>
                                        </label>
                                      </div>
                                    </li>
                                  </ul>
                                </li>

                                <li className="listswitch-li">
                                  <div className="switchbox">
                                    <span className="title-switch bold">
                                      Invitation from you to Neithedu was
                                      accepted
                                    </span>
                                    <label className="switch" htmlFor="checkbox15">
                                      <input type="checkbox" id="checkbox15" />
                                      <div className="slider round"></div>
                                    </label>
                                  </div>

                                  <ul className="iner-switchlist">
                                    <li className="inner-listswitch">
                                      <div className="switchbox">
                                        <span className="title-switch">
                                          New user was registered
                                        </span>
                                        <label
                                          className="switch"
                                          htmlFor="checkbox16"
                                        >
                                          <input
                                            type="checkbox"
                                            id="checkbox16"
                                          />
                                          <div className="slider round"></div>
                                        </label>
                                      </div>
                                    </li>
                                    <li className="inner-listswitch">
                                      <div className="switchbox">
                                        <span className="title-switch">
                                          New user was subscribed
                                        </span>
                                        <label
                                          className="switch"
                                          htmlFor="checkbox17"
                                        >
                                          <input
                                            type="checkbox"
                                            id="checkbox17"
                                          />
                                          <div className="slider round"></div>
                                        </label>
                                      </div>
                                    </li>
                                  </ul>
                                </li>

                                <li className="listswitch-li">
                                  <div className="switchbox">
                                    <span className="title-switch bold">
                                      Your material was published
                                    </span>
                                    <label className="switch" htmlFor="checkbox18">
                                      <input type="checkbox" id="checkbox18" />
                                      <div className="slider round"></div>
                                    </label>
                                  </div>
                                </li>

                                <li className="listswitch-li">
                                  <div className="switchbox">
                                    <span className="title-switch bold">
                                      Reminder about subscription
                                    </span>
                                    <label className="switch" htmlFor="checkbox19">
                                      <input type="checkbox" id="checkbox19" />
                                      <div className="slider round"></div>
                                    </label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div className="save-notify">
                              <div className="btn-week">
                                <Link to="" className="btnweak-bx">
                                  <span className="btn-title">Save</span>
                                </Link>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </Row>
                </Tabs>
              </div>
            </Container>
          </div>
        </div>

        {/* footer start */}
        <FooterEducator logo={logo} />
        {/* footer end */}
      </div>

      {/* start modal my student */}

      {/* start game save step1 */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        // {...props}
        show={modalgamesavedstep1Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2 mystudent-games">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>The game is saved</h4>
              <h5>Send the game to my students</h5>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setgamesavedstep1ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="serch-inner-group">
                    <div className="serchfiled-activities">
                      <Form.Control
                        type="text"
                        placeholder="Start typing name or year of study"
                        className="formbox"
                      />
                      <Button type="button" className="seachbtn">
                        <i className="fas fa-search"></i>
                      </Button>
                    </div>
                  </div>

                  <div className="newpupil-list-bx">
                    <Form.Group className="subsform-fild child-tex-fld height-max-flow">
                      <div className="child-boxnew">
                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Aaradhya Rayasam</h5>
                                  <h6>India, Mumbai 1 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Chandrashekhar Kalvakuntla</h5>
                                  <h6>India, Mumbai 3 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>7F Class</h5>
                                  <h6>Hamut Kapit, Kemal Mara and 12 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Additional group</h5>
                                  <h6>Laska Doe, Hamut Kapit and 4 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>8A Class for exams</h5>
                                  <h6>Hamut Kapit, Laska Doe and 8 more</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="child-account">
                          <div className="activ-detail-list">
                            <label className="custm-check">
                              <Form.Control type="checkbox" />
                              <span className="checkmark"></span>
                              <div className="active-detail-row">
                                <div className="activiti-prof-icon">
                                  <span className="profile-icon-box">
                                    <i className="fas fa-user-friends"></i>
                                  </span>
                                </div>
                                <div className="activiti-center-detail">
                                  <h5>Chandrashekhar Kalvakuntla</h5>
                                  <h6>India, Mumbai 3 year of study</h6>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </Form.Group>

                <div className="student-btn-savedgame">
                  <div className="btnlogin-main">
                    <button type="submit" className="btnlogin btnweak-bx">
                      Send to selected students
                    </button>
                  </div>
                </div>
                <div className="btnlogin-main">
                  <button type="submit" className="btnlogin btnweak-bx">
                    Later
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end Add a Member Group step2 */}

      {/* end modal my student */}
    </div>
  );
}

export default Notifications;
