import { Button, Container, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import FooterEducator from "../../layout/Footer/FooterEducator";
import HeaderGames from "../../layout/Header/HeaderGames";
import logo from "../../../assets/images/logo.png";
import { useState } from "react";
import { disabledBtnProps } from "../../../utils/util";
import { CustomSnackbar, useSnackState } from "../../../utils/useSnackState";
import { Box, Typography } from "@mui/material";
const gamesData = [
  {
    title: "Template 1",
    activities: [
      'Quiz "Flowers"',
      'Crossword "Photosynthesis and flat plants"',
      'Wordsearch "South Africa and Photosynthesis"',
      'Quiz "General text in topic Photosynthesis"',
      'Quiz "General text in topic Photosynthesis"',
    ],
    subject: "Biology",
    year: "7",
  },
  {
    title: "Flow Title",
    activities: [
      'Quiz "Flowers"',
      'Crossword "Photosynthesis and flat plants"',
      'Wordsearch "South Africa and Photosynthesis"',
      'Quiz "General text in topic Photosynthesis"',
      'Quiz "General text in topic Photosynthesis"',
    ],
    subject: "Biology",
    year: "7",
  },
  {
    title: "Synthesis Photo",
    activities: [
      'Quiz "Flowers"',
      'Crossword "Photosynthesis and flat plants"',
      'Wordsearch "South Africa and Photosynthesis"',
      'Quiz "General text in topic Photosynthesis"',
      'Quiz "General text in topic Photosynthesis"',
    ],
    subject: "Biology",
    year: "7",
  },
  {
    title: "Template 1",
    activities: [
      'Quiz "Flowers"',
      'Crossword "Photosynthesis and flat plants"',
      'Wordsearch "South Africa and Photosynthesis"',
      'Quiz "General text in topic Photosynthesis"',
      'Quiz "General text in topic Photosynthesis"',
    ],
    subject: "Biology",
    year: "7",
  },
  {
    title: "Flow Title",
    activities: [
      'Quiz "Flowers"',
      'Crossword "Photosynthesis and flat plants"',
      'Wordsearch "South Africa and Photosynthesis"',
      'Quiz "General text in topic Photosynthesis"',
      'Quiz "General text in topic Photosynthesis"',
    ],
    subject: "Biology",
    year: "7",
  },
  {
    title: "Synthesis Photo",
    activities: [
      'Quiz "Flowers"',
      'Crossword "Photosynthesis and flat plants"',
      'Wordsearch "South Africa and Photosynthesis"',
      'Quiz "General text in topic Photosynthesis"',
      'Quiz "General text in topic Photosynthesis"',
    ],
    subject: "Biology",
    year: "7",
  },
  {
    title: "Template 1",
    activities: [
      'Quiz "Flowers"',
      'Crossword "Photosynthesis and flat plants"',
      'Wordsearch "South Africa and Photosynthesis"',
      'Quiz "General text in topic Photosynthesis"',
      'Quiz "General text in topic Photosynthesis"',
    ],
    subject: "Biology",
    year: "7",
  },
  {
    title: "Flow Title",
    activities: [
      'Quiz "Flowers"',
      'Crossword "Photosynthesis and flat plants"',
      'Wordsearch "South Africa and Photosynthesis"',
      'Quiz "General text in topic Photosynthesis"',
      'Quiz "General text in topic Photosynthesis"',
    ],
    subject: "Biology",
    year: "7",
  },
  {
    title: "Synthesis Photo",
    activities: [
      'Quiz "Flowers"',
      'Crossword "Photosynthesis and flat plants"',
      'Wordsearch "South Africa and Photosynthesis"',
      'Quiz "General text in topic Photosynthesis"',
      'Quiz "General text in topic Photosynthesis"',
    ],
    subject: "Biology",
    year: "7",
  },
  {
    title: "Template 1",
    activities: [
      'Quiz "Flowers"',
      'Crossword "Photosynthesis and flat plants"',
      'Wordsearch "South Africa and Photosynthesis"',
      'Quiz "General text in topic Photosynthesis"',
      'Quiz "General text in topic Photosynthesis"',
    ],
    subject: "Biology",
    year: "7",
  },
  {
    title: "Flow Title",
    activities: [
      'Quiz "Flowers"',
      'Crossword "Photosynthesis and flat plants"',
      'Wordsearch "South Africa and Photosynthesis"',
      'Quiz "General text in topic Photosynthesis"',
      'Quiz "General text in topic Photosynthesis"',
    ],
    subject: "Biology",
    year: "7",
  },
  {
    title: "Synthesis Photo",
    activities: [
      'Quiz "Flowers"',
      'Crossword "Photosynthesis and flat plants"',
      'Wordsearch "South Africa and Photosynthesis"',
      'Quiz "General text in topic Photosynthesis"',
      'Quiz "General text in topic Photosynthesis"',
    ],
    subject: "Biology",
    year: "7",
  },
].filter(() => false);
export const SelectNeitheduGames = ({ history }) => {
  const [selectedGames, setselectedGames] = useState([]);
  const [snackProps, open] = useSnackState();
  const handleAddSelectedGames = () => {
    if (selectedGames.length === 0) {
      open("Select atleast 1 game", "error");
      return;
    }
    history.push({
      pathname: "/educator",
      state: {
        deadline: true,
      },
    });
  };
  const handleSelectGame = (idx) => {
    if (selectedGames.includes(idx)) {
      setselectedGames((prevState) =>
        prevState.filter((index) => index !== idx),
      );
    } else setselectedGames((prevState) => [...prevState, idx]);
  };
  return (
    <div className="home-wrapper">
      {/* start header */}
      <HeaderGames />
      {/* end header */}
      <CustomSnackbar {...snackProps} />
      <div className="games-dashboard-main dashboard-bgnew blur-bgg gamestabs">
        <div className="gamestabs-main gamesflow-popup-bxx">
          <div className="flownew-gamesbx">
            <Container>
              <div className="Minigames-bx mb_tab">
                <div className="minigames-cards">
                  <div className="close-flowgame">
                    <Link to="/educator" className="btnclose-flowgm">
                      <span aria-hidden="true">×</span>
                    </Link>
                  </div>
                  <div className="heading-top-flowgames">
                    <h4>
                      Select the minigames to add to gameflow Photo synthesis{" "}
                    </h4>
                  </div>
                  <div className="games-topserch-bar">
                    <Form>
                      <Row className="align-center">
                        <div className="col-md-3 one">
                          <div className="list-study">
                            <Form.Control as="select">
                              <option value="2">All years of study</option>
                            </Form.Control>
                          </div>
                        </div>
                        <div className="col-md-6 two">
                          <div className="serch-bx-games">
                            <div className="serchfiled-activities">
                              <Form.Control
                                type="text"
                                placeholder="Start typing title, subject or any other keyword "
                                className="formbox"
                              />
                              <Button type="button" className="seachbtn">
                                <i className="fas fa-search"></i>
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 three">
                          <div className="createnew-games">
                            <div className="game-opn-create">
                              {selectedGames.length === 0 ? (
                                <div
                                  onClick={handleAddSelectedGames}
                                  style={{
                                    ...disabledBtnProps,
                                  }}
                                  className="btnweak-bx gamestbs-btn"
                                >
                                  Add selected games
                                </div>
                              ) : (
                                <div
                                  onClick={() => handleAddSelectedGames()}
                                  className="btnweak-bx gamestbs-btn"
                                >
                                  Add selected games
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </Row>
                    </Form>
                  </div>

                  {gamesData.length === 0 ? (
                    <Container maxwidth="sm">
                      <Box
                        sx={{
                          mt: "1rem",
                          width: "100%",
                          backgroundColor: "white",
                          borderRadius: "20px",
                          p: "1rem",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "var(--purple-dark)",
                            fontWeight: "600",
                            fontSize: "1.5rem",
                            mb: "1.5rem",
                            mt: "1rem",
                          }}
                        >
                          You haven't created any games yet.{" "}
                          <span
                            onClick={() => history.push("/GamesTabs")}
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                          >
                            Click here to goto games tab
                          </span>
                        </Typography>
                      </Box>
                    </Container>
                  ) : (
                    <div className="card-quizgames gamesflw-cardchek">
                      <Row>
                        {gamesData.map((game, idx) => {
                          const isSelected = selectedGames.includes(idx);
                          return (
                            <div className="col-md-3 onecard">
                              <div className="inner-cardquizz">
                                <label className="custm-check gameflow-check">
                                  <Form.Control
                                    onChange={() => handleSelectGame(idx)}
                                    type="checkbox"
                                    checked={isSelected}
                                  />
                                  <span className="checkmark"></span>
                                  <div className="inner-bodyquiz">
                                    <div className="quiz-body-content gameflw-contbx">
                                      <div className="quiz-bdy-content-bx">
                                        <h3>{game.title}</h3>
                                        <div className="type-material-iner">
                                          {game.activities
                                            .slice(0, 4)
                                            .map((activity) => (
                                              <p> {activity} </p>
                                            ))}

                                          {game.activities.length > 4 && (
                                            <p>...</p>
                                          )}
                                        </div>
                                        <h5>Biology</h5>
                                        <h4>7th year of study</h4>
                                      </div>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </div>
                          );
                        })}
                      </Row>
                    </div>
                  )}
                </div>
              </div>
            </Container>
          </div>
        </div>

        {/* footer start */}
        <FooterEducator logo={logo} />
        {/* footer end */}
      </div>
    </div>
  );
};
