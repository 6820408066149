import React from "react";
import { DialogBox } from "../../utils/DialogBox";
import { Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const BadgesSentModal = ({ setOpenDialog }) => {
  return (
    <DialogBox isOpen={true}>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          py: "1rem",
        }}
      >
        <CloseIcon
          onClick={() => setOpenDialog(null)}
          sx={{
            color: "var(--orange)",
            top: "0px",
            right: "5px",
            cursor: "pointer",
            position: "absolute",
          }}
        />
        <Typography
          mb="1rem"
          sx={{
            color: "var(--purple-dark)",
            fontSize: "1.5rem",
            fontWeight: "700",
          }}
        >
          Badges are sent
        </Typography>
        <Typography>
          All selected students received the badges and 30 points{" "}
        </Typography>
      </Box>
    </DialogBox>
  );
};

export default BadgesSentModal;
