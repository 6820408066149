import {
  Box,
  CircularProgress,
  circularProgressClasses,
  Grid,
  LinearProgress,
  linearProgressClasses,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { StyledYellowButton } from "../utils/Buttons";
import curvedArrow from "../../assets/images/icons/curveArrow.png";
import gamesgalery1 from "../../assets/images/games-galery1.jpg";
import gamesgalery2 from "../../assets/images/games-galery2.jpg";
import { Link } from "react-router-dom";

export default function FirstLogin() {
  const [withMilestone, setWithMilestone] = useState(true);

  const ScoreProgress = ({ value, maxValue, title }) => {
    return (
      <Stack sx={{ alignItems: "center" }}>
        <CircularProgressWithLabel
          value={value}
          percent={(value / maxValue) * 100}
        />
        <Typography fontSize="0.8rem" sx={{ color: "white" }}>
          / {maxValue}
        </Typography>
        <Typography
          fontSize="0.9rem"
          sx={{ color: "white" }}
          textTransform={"capitalize"}
        >
          {title}
        </Typography>
      </Stack>
    );
  };
  const CircularProgressWithLabel = ({ percent, value }) => {
    return (
      <Box sx={{ position: "relative" }}>
        <Box sx={{ position: "relative" }}>
          <CircularProgress
            sx={{
              color: "var(--yellow)",
            }}
            variant="determinate"
            value={percent}
          />
          <CircularProgress
            sx={{
              color: "rgba(255, 255, 0,0.2)",
              position: "absolute",
              left: 0,
            }}
            variant="determinate"
            value={100}
          />
        </Box>
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontSize: "1.2rem",
              fontWeight: "700",
            }}
            variant="caption"
            component="div"
            color="text.secondary"
          >
            {value}
          </Typography>
        </Box>
      </Box>
    );
  };
  return (
    <Box
      sx={{
        my: "2rem",
        px: "2rem",
        maxWidth: "60rem",
        mx: "auto",
        background: "blue",
      }}
    >
      {withMilestone ? (
        <Box>
          <Grid columnSpacing={"2rem"} container>
            <Grid item sm={6}>
              <Box
                sx={{
                  position: "relative",
                  height: "100%",
                  p: "1rem",
                  zIndex: 9,
                  "::before": {
                    content: '""',
                    zIndex: -2,
                    top: "0px",
                    bottom: "0px",
                    left: "0px",
                    right: "0px",
                    position: "absolute",
                    background:
                      "radial-gradient(89.85% 73.57% at 84.21% 75.36%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0) 86.18%), radial-gradient(63.69% 56.22% at 25.75% 17.94%, rgba(255, 255, 255, 0.177) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%), #632CAF",
                    borderRadius: "30px",
                    width: "100%",
                    height: "100%",
                    transform: "rotateX(-16deg) rotateY(-2deg)",
                  },
                }}
              >
                <Stack flexDirection={"row"} justifyContent="space-between">
                  <Typography sx={{ color: "white" }}>
                    One is your current level
                  </Typography>
                  <Typography
                    sx={{ color: "white", textDecoration: "underline" }}
                  >
                    Levels
                  </Typography>
                </Stack>
                <LinearProgress
                  sx={{
                    my: "1rem",
                    [`& .${linearProgressClasses.bar}`]: {
                      backgroundColor: "var(--yellow)",
                    },
                    [`&.${linearProgressClasses.colorPrimary}`]: {
                      backgroundColor: "rgba(255, 255, 0,0.2)",
                    },
                  }}
                  variant="determinate"
                  value={20}
                />

                <Grid container>
                  <Grid item sm={3}>
                    <Stack
                      sx={{ color: "var(--orange)" }}
                      position={"relative"}
                      alignItems="start"
                    >
                      <Typography fontSize="0.8rem" fontWeight="600">
                        REACH
                      </Typography>
                      <Typography fontSize="0.8rem" fontWeight="600">
                        YOUR
                      </Typography>
                      <Typography fontSize="0.8rem" fontWeight="600">
                        NEXT
                      </Typography>
                      <Typography fontSize="0.8rem" fontWeight="600">
                        LEVEL
                      </Typography>
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: "-20px",
                          left: "70%",
                        }}
                      >
                        <img src={curvedArrow} alt="arrow" />
                      </Box>
                    </Stack>
                  </Grid>

                  <Grid item sm={3}>
                    <ScoreProgress maxValue={1000} value={12} title="points" />
                  </Grid>
                  <Grid item sm={3}>
                    <ScoreProgress
                      maxValue={30}
                      value={7}
                      title="REWARD CARDS"
                    />
                  </Grid>
                  <Grid item sm={3}>
                    <ScoreProgress maxValue={50} value={20} title="VICTORIES" />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item sm={6}>
              <Box
                sx={{
                  height: "100%",
                  // backgroundColor: "green",
                  p: "1rem",
                  display: "flex",
                  position: "relative",
                  flexDirection: "column",
                  alignItems: "start",
                  zIndex: 9,
                  "::before": {
                    content: '""',
                    zIndex: -2,
                    top: "0px",
                    bottom: "0px",
                    left: "0px",
                    right: "0px",
                    position: "absolute",
                    background:
                      "radial-gradient(89.85% 73.57% at 84.21% 75.36%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0) 86.18%), radial-gradient(63.69% 56.22% at 25.75% 17.94%, rgba(255, 255, 255, 0.177) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%), #632CAF",
                    borderRadius: "30px",
                    width: "100%",
                    height: "100%",
                    transform: "rotateX(-16deg) rotateY(2deg)",
                  },
                }}
              >
                <Typography
                  sx={{
                    color: "var(--orange)",
                    fontSize: "1.3rem",
                    fontWeight: "700",
                  }}
                >
                  PLAY THE GREAT GAME!
                </Typography>
                <Typography
                  sx={{
                    color: "white",
                    fontSize: "1rem",
                    textTransform: "capitalize",
                  }}
                >
                  The first level of the game is available
                </Typography>
                <Typography
                  sx={{
                    color: "white",
                    fontSize: "1rem",
                    textTransform: "capitalize",
                  }}
                >
                  for you, open the universe!{" "}
                </Typography>
                <Box width="30%" mt="1rem">
                  <StyledYellowButton btnHandler={() => {}} title="Play" />
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container sx={{ my: "2rem" }} columnSpacing="2rem">
            <Grid item xs={6}>
              <Typography
                textAlign={"start"}
                mb="1rem"
                sx={{ fontSize: "1.2rem", fontWeight: "700", color: "white" }}
              >
                Active games
              </Typography>
              <Grid
                container
                className="games-cards-diver"
                columnSpacing="1rem"
              >
                <Grid item xs={6}>
                  <div
                    className="game-outerbx-poup"
                    style={{ height: "25rem" }}
                  >
                    <div className="games-cardnew" style={{ height: "100%" }}>
                      <div className="cards-games-pic">
                        <div className="game-pic">
                          <img src={gamesgalery2} className="img-fluid" />
                        </div>
                        <div className="shape-gamesnew"></div>
                      </div>
                      <div className="games-content-box">
                        <h4>ComputerFlow</h4>
                        <p>Small description about the game some words</p>
                        <button className="points-btn skybtn">30 points</button>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div
                    className="game-outerbx-poup"
                    style={{ height: "25rem" }}
                  >
                    <div className="games-cardnew" style={{ height: "100%" }}>
                      <div className="cards-games-pic">
                        <div className="game-pic">
                          <img src={gamesgalery1} className="img-fluid" />
                        </div>
                        <div className="shape-gamesnew"></div>
                      </div>
                      <div className="games-content-box">
                        <h4>Sciences you will know</h4>
                        <p>Small description about the game some words</p>
                        <button className="points-btn skybtn">30 points</button>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Typography
                textAlign={"start"}
                mb="1rem"
                sx={{ fontSize: "1.2rem", fontWeight: "700", color: "white" }}
              >
                Last activites
              </Typography>

              <div
                className="live-lesson-listbtm"
                style={{
                  backgroundColor: "white",
                  padding: "0 0.5rem",
                  height: "25rem",
                  overflowY: "scroll",
                  borderRadius: "20px",
                }}
              >
                <div className="lesson-hovrbx" style={{ borderTop: "none" }}>
                  <Link to="/createLiveLesson" className="newlesson-card">
                    <div className="sesson-listmain">
                      <div className="live-lesson-main">
                        <div className="custom-row">
                          <div className="lesson-leftbx">
                            <span className="medm-tex purple-clr">
                              Live lesson
                            </span>
                          </div>
                          <div className="lesson-rightbx">
                            <span className="medm-tex date-bx">
                              Oct 05, 2020
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="title-main">
                        Work on mistakes of the test on the topic "Law and legal
                        aspects of business activities" from the work book
                      </div>
                      <div className="lesson-list-bottom">
                        <div className="custom-row">
                          <div className="left-boxleson">
                            <ul className="list-point-lesson">
                              <li>
                                <span className="medm-tex orng-clr">
                                  Oct 25, 2020
                                </span>
                              </li>
                              <li>
                                <span className="medm-tex sky-clr">
                                  Completed
                                </span>
                              </li>
                              <li>
                                <span className="medm-tex orng-clr">
                                  30 points + Badge for hard work{" "}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="lesson-hovrbx">
                  <Link to="#" className="newlesson-card">
                    <div className="sesson-listmain">
                      <div className="live-lesson-main">
                        <div className="custom-row">
                          <div className="lesson-leftbx">
                            <span className="medm-tex purple-clr">
                              Neithedu Game: Quiz
                            </span>
                          </div>
                          <div className="lesson-rightbx">
                            <span className="medm-tex date-bx">
                              Dec 16, 2020
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="title-main">
                        Law basics. Beginner concepts
                      </div>
                      <div className="lesson-list-bottom">
                        <div className="custom-row">
                          <div className="left-boxleson">
                            <ul className="list-point-lesson">
                              <li>
                                <span className="medm-tex orng-clr">
                                  Due March 29
                                </span>
                              </li>
                              <li>
                                <span className="medm-tex sky-clr">
                                  0/50 points
                                </span>
                              </li>
                              <li>
                                <span className="medm-tex orng-clr">Late</span>
                              </li>
                            </ul>
                          </div>
                          <div className="right-boxlesson">
                            <div className="count-numlist">
                              <span className="count-total-actvnum">2</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Grid container sx={{ my: "2rem" }} columnSpacing="2rem">
          <Grid item xs={6}>
            <Typography
              textAlign={"start"}
              mb="1rem"
              sx={{ fontSize: "1.2rem", fontWeight: "700", color: "white" }}
            >
              Active games
            </Typography>
            <Grid container className="games-cards-diver" columnSpacing="1rem">
              <Grid item xs={6}>
                <div className="game-outerbx-poup" style={{ height: "25rem" }}>
                  <div className="games-cardnew" style={{ height: "100%" }}>
                    <div className="cards-games-pic">
                      <div className="game-pic">
                        <img src={gamesgalery2} className="img-fluid" />
                      </div>
                      <div className="shape-gamesnew"></div>
                    </div>
                    <div className="games-content-box">
                      <h4>ComputerFlow</h4>
                      <p>Small description about the game some words</p>
                      <button className="points-btn skybtn">30 points</button>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="game-outerbx-poup" style={{ height: "25rem" }}>
                  <div className="games-cardnew" style={{ height: "100%" }}>
                    <div className="cards-games-pic">
                      <div className="game-pic">
                        <img src={gamesgalery1} className="img-fluid" />
                      </div>
                      <div className="shape-gamesnew"></div>
                    </div>
                    <div className="games-content-box">
                      <h4>Sciences you will know</h4>
                      <p>Small description about the game some words</p>
                      <button className="points-btn skybtn">30 points</button>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Typography
              textAlign={"start"}
              mb="1rem"
              sx={{ fontSize: "1.2rem", fontWeight: "700", color: "white" }}
            >
              Last activites
            </Typography>

            <div
              className="live-lesson-listbtm"
              style={{
                backgroundColor: "white",
                padding: "0 0.5rem",
                height: "25rem",
                overflowY: "scroll",
                borderRadius: "20px",
              }}
            >
              <div className="lesson-hovrbx" style={{ borderTop: "none" }}>
                <Link to="/createLiveLesson" className="newlesson-card">
                  <div className="sesson-listmain">
                    <div className="live-lesson-main">
                      <div className="custom-row">
                        <div className="lesson-leftbx">
                          <span className="medm-tex purple-clr">
                            Live lesson
                          </span>
                        </div>
                        <div className="lesson-rightbx">
                          <span className="medm-tex date-bx">Oct 05, 2020</span>
                        </div>
                      </div>
                    </div>
                    <div className="title-main">
                      Work on mistakes of the test on the topic "Law and legal
                      aspects of business activities" from the work book
                    </div>
                    <div className="lesson-list-bottom">
                      <div className="custom-row">
                        <div className="left-boxleson">
                          <ul className="list-point-lesson">
                            <li>
                              <span className="medm-tex orng-clr">
                                Oct 25, 2020
                              </span>
                            </li>
                            <li>
                              <span className="medm-tex sky-clr">
                                Completed
                              </span>
                            </li>
                            <li>
                              <span className="medm-tex orng-clr">
                                30 points + Badge for hard work{" "}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="lesson-hovrbx">
                <Link to="#" className="newlesson-card">
                  <div className="sesson-listmain">
                    <div className="live-lesson-main">
                      <div className="custom-row">
                        <div className="lesson-leftbx">
                          <span className="medm-tex purple-clr">
                            Neithedu Game: Quiz
                          </span>
                        </div>
                        <div className="lesson-rightbx">
                          <span className="medm-tex date-bx">Dec 16, 2020</span>
                        </div>
                      </div>
                    </div>
                    <div className="title-main">
                      Law basics. Beginner concepts
                    </div>
                    <div className="lesson-list-bottom">
                      <div className="custom-row">
                        <div className="left-boxleson">
                          <ul className="list-point-lesson">
                            <li>
                              <span className="medm-tex orng-clr">
                                Due March 29
                              </span>
                            </li>
                            <li>
                              <span className="medm-tex sky-clr">
                                0/50 points
                              </span>
                            </li>
                            <li>
                              <span className="medm-tex orng-clr">Late</span>
                            </li>
                          </ul>
                        </div>
                        <div className="right-boxlesson">
                          <div className="count-numlist">
                            <span className="count-total-actvnum">2</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
