import React, { useContext, useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { CustomSnackbar, useSnackState } from "../utils/useSnackState";
import "./loginModal/login.scss";
import { useQuery } from "@tanstack/react-query";
import { getEducatorStudents } from "../service/apis/educator";
import { NotificationContext } from "../contexts/Notification";

const Groupmember = ({
  addStusInGroup,
  openSnack,
  deleteStudentFromGroup,
  setAddgroupstep2ModalShow,
  modalAddgroupstep2Show,
  educatorStudents,
  selectedGroup,
  setgroupmemberstep1ModalShow,
  modalgroupmemberstep1Show,
  modalgroupmemberstep3Show,
  setgroupmemberstep3ModalShow,
  modalgroupmemberstep4Show,
  setgroupmemberstep4ModalShow,
  submitHandler,
  studentsAlreadyInGroup,
  refetchGroupsDetails,
}) => {
  // remove students already in group
  const { data: educatorSudentsData } = useQuery({
    queryKey: ["educatorSudentsData"],
    queryFn: async () => await getEducatorStudents(),
  });

  const studentsNotInGroup =
    educatorSudentsData?.filter(
      (stgr) =>
        studentsAlreadyInGroup?.findIndex(
          (stuInGrp) => stuInGrp.studentId === stgr.studentId,
        ) === -1,
    ) || [];

  const [selectedStudentsIds, setSelectedStudentsIds] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [snackProps, snackOpen] = useSnackState();

  const [state, setstate] = useState({
    selectStudentToDelete: { groupId: null, studentId: null, studentName: "" },
  });

  const { showNotification } = useContext(NotificationContext);

  const selectStudent = (studentId) => {
    if (selectedStudentsIds.includes(studentId)) {
      setSelectedStudentsIds((state) =>
        state.filter((stid) => stid !== studentId),
      );
    } else {
      setSelectedStudentsIds((state) => [...state, studentId]);
    }
  };

  const filteredStudents = studentsNotInGroup.filter((stud) =>
    stud?.name?.toLowerCase()?.includes(searchQuery.toLowerCase()),
  );

  return (
    <div className="add-group-main">
      {/* start Add a Group step1 */}
      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new mychild-frm child-bx-smll"
        show={modalgroupmemberstep1Show}
        onHide={() => {
          return false;
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <CustomSnackbar {...snackProps} />
        <div className="login-modal-mainbx groupmbr-step1">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>{selectedGroup?.name}</h4>
              <div className="member-bxnw">
                <span className="member-texsml">
                  {educatorStudents?.length} members
                </span>
                <span className="addbtn-meber">
                  <button
                    className="addmbr-btn-sml"
                    onClick={() => {
                      setAddgroupstep2ModalShow(true);
                      setgroupmemberstep1ModalShow(false);
                    }}
                  >
                    <i className="fas fa-plus"></i>
                  </button>
                </span>
              </div>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setgroupmemberstep1ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="newpupil-list-bx">
                    <Form.Group className="subsform-fild child-tex-fld height-max-flow">
                      <div className="child-boxnew">
                        {educatorStudents?.map((student) => {
                          return (
                            <div
                              key={student.studentId}
                              className="child-account"
                            >
                              <div className="activ-detail-list">
                                <div className="active-detail-row">
                                  <div className="activiti-prof-icon">
                                    <span className="profile-icon-box">
                                      <i className="fas fa-user"></i>
                                    </span>
                                  </div>
                                  <div className="activiti-center-detail">
                                    <h5>{student.studentName}</h5>
                                    {/* <h6>{student.location}</h6> */}
                                  </div>
                                  <div className="activiti-count-mesg">
                                    <button
                                      className="count-num-activiti"
                                      onClick={() => {
                                        if (educatorStudents?.length <= 2) {
                                          snackOpen(
                                            "Group must have minimum 2 students",
                                            "error",
                                          );
                                          return;
                                        }

                                        setstate({
                                          selectStudentToDelete: {
                                            studentId: student.studentId,
                                            groupId: student.groupId,
                                            studentName: student.studentName,
                                          },
                                        });
                                        setgroupmemberstep3ModalShow(true);

                                        setgroupmemberstep1ModalShow(false);
                                        // setgroupmemberstep1ModalShow(false);
                                      }}
                                    >
                                      <i className="fas fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </Form.Group>
                  </div>
                </Form.Group>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end Add a Group step1 */}

      {/* start Add a Groupmember step2 */}

      {/* add students to group */}
      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        show={modalAddgroupstep2Show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2">
          <Modal.Header>
            <div className="btnback">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setAddgroupstep2ModalShow(false);
                }}
                className="backbtn-icn"
              >
                <i className="fas fa-arrow-left"></i>
              </div>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>{selectedGroup?.name}</h4>
              <p>
                If you need to add a new pupil to this group, please add the
                pupil to My pupil list at first
              </p>
              <h5>
                <span className="num-pupil-active">
                  {selectedStudentsIds?.length}{" "}
                </span>
                <span className="total-pupil-num">
                  / {filteredStudents?.length} pupils
                </span>
              </h5>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setAddgroupstep2ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="serch-inner-group">
                    <div className="serchfiled-activities">
                      <Form.Control
                        type="text"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder="Start typing name or year of study"
                        className="formbox"
                      />
                      <Button type="button" className="seachbtn">
                        <i className="fas fa-search"></i>
                      </Button>
                    </div>
                  </div>

                  <div className="newpupil-list-bx">
                    <Form.Group className="subsform-fild child-tex-fld height-max-flow">
                      <div className="child-boxnew">
                        {filteredStudents.map((student, index) => {
                          const isSelected = Boolean(
                            selectedStudentsIds.find(
                              (grpStudId) => grpStudId === student.studentId,
                            ),
                          );

                          return (
                            <div className="child-account" key={index}>
                              <div className="activ-detail-list">
                                <label className="custm-check">
                                  <Form.Control
                                    checked={isSelected}
                                    onChange={() => {
                                      selectStudent(student.studentId);
                                    }}
                                    type="checkbox"
                                  />
                                  <span className="checkmark"></span>
                                  <div className="active-detail-row">
                                    <div className="activiti-prof-icon">
                                      <span className="profile-icon-box">
                                        <i className="fas fa-user"></i>
                                      </span>
                                    </div>
                                    <div className="activiti-center-detail">
                                      <h5>{student?.name}</h5>
                                      <h6>{student.location}</h6>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </div>
                          );
                        })}
                        {filteredStudents?.length === 0 && (
                          <p>Student does not exist</p>
                        )}
                      </div>
                    </Form.Group>
                  </div>
                </Form.Group>

                <div className="btnlogin-main">
                  <button
                    type="submit"
                    style={{
                      backgroundColor:
                        filteredStudents?.length !== 0 &&
                        selectedStudentsIds?.length === 0 &&
                        "var(--grey-light)",
                      color:
                        filteredStudents?.length !== 0 &&
                        selectedStudentsIds?.length === 0 &&
                        "white",
                    }}
                    className="btnlogin btnweak-bx"
                    onClick={async () => {
                      if (
                        filteredStudents?.length > 0 &&
                        selectedStudentsIds?.length === 0
                      ) {
                        return;
                      }
                      setgroupmemberstep4ModalShow(true);
                      setAddgroupstep2ModalShow(false);
                    }}
                  >
                    Add selected students
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end Add a Member Group step2 */}

      {/* start Add a Member Group step2 */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        show={modalgroupmemberstep3Show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>
                Do you want to delete {state.selectStudentToDelete.studentName}{" "}
                from the Group {selectedGroup?.name}?
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="btn-delet-bx">
                    <ul>
                      <li>
                        <button
                          className="delet-ys btn-grup-class btnweak-bx cancel-btn"
                          type="submit"
                          onClick={() => {
                            setgroupmemberstep3ModalShow(false);
                            setgroupmemberstep1ModalShow(true);
                          }}
                        >
                          Cancel
                        </button>
                      </li>
                      <li>
                        <button
                          className="delet-no btn-grup-class btnweak-bx delbtn"
                          type="submit"
                          onClick={async () => {
                            await deleteStudentFromGroup(
                              state.selectStudentToDelete.groupId,
                              state.selectStudentToDelete.studentId,
                            );

                            showNotification(
                              "Student deleted from group successfully",
                            );
                            setgroupmemberstep3ModalShow(false);
                            setgroupmemberstep1ModalShow(true);
                          }}
                        >
                          Delete
                        </button>
                      </li>
                    </ul>
                  </div>
                </Form.Group>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      {/* end Add a Member Group step3 */}

      {/* start Add a Member Group step4 */}

      <Modal
        className="loginmodal student-login-main educator-signup-step2 modal-pupil-new add-group-modal2 mychild-frm child-bx-smll"
        show={modalgroupmemberstep4Show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="login-modal-mainbx groupmbr-step2 add-selection-bx">
          <Modal.Header>
            <div className="btnback">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setgroupmemberstep4ModalShow(false);
                }}
                className="backbtn-icn"
              >
                <i className="fas fa-arrow-left"></i>
              </div>
            </div>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>{selectedGroup?.name}</h4>
              <p>
                {selectedStudentsIds?.length} new students will be added to the
                group. Send all active activities to all of new members?
              </p>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setgroupmemberstep4ModalShow(false)}
              className="close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="login-modl-form">
              <div className="loginform-bx">
                <Form.Group className="subsform-fild">
                  <div className="btn-delet-bx">
                    <ul>
                      <li>
                        <button
                          className="delet-ys btn-grup-class btnweak-bx"
                          type="submit"
                          onClick={async () => {
                            await addStusInGroup(
                              selectedGroup.id,
                              selectedStudentsIds,
                            );
                            showNotification("new students added to group");
                            setgroupmemberstep4ModalShow(false);
                            await refetchGroupsDetails();
                            submitHandler();
                          }}
                        >
                          Yes
                        </button>
                      </li>
                      <li>
                        <button
                          className="delet-no btn-grup-class btnweak-bx"
                          type="submit"
                          onClick={async () => {
                            await addStusInGroup(
                              selectedGroup.id,
                              selectedStudentsIds,
                            );
                            showNotification("new students added to group");
                            setgroupmemberstep4ModalShow(false);
                            await refetchGroupsDetails();
                            submitHandler();
                          }}
                        >
                          No
                        </button>
                      </li>
                    </ul>
                  </div>
                </Form.Group>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* end Add a Member Group step4 */}

      {/* End Add a Membergroup modal */}
    </div>
  );
};

export default Groupmember;
